import { ErrorIcon } from 'icons/ErrorIcon';
import { WarningIcon } from 'icons/WarningIcon';
import {
  ComponentPropsWithoutRef,
  MouseEventHandler,
  ReactNode,
  SVGProps,
  forwardRef,
} from 'react';
import { cn } from 'shared/utils/cn';
import { Button } from '../Button/Button';
import { Modal } from '../Modal/Modal';

const _variants = ['error', 'warning'] as const;

type Variant = (typeof _variants)[number];

const iconMap = {
  error: <ErrorIcon />,
  warning: <WarningIcon />,
} as {
  [key in Variant]: SVGProps<SVGSVGElement> | null;
};

type AlertProps = ComponentPropsWithoutRef<'div'> & {
  /** Whether the alert dialog is open or not */
  open: boolean;
  /** The alert message */
  children: ReactNode;
  /** The style of the alert */
  variant?: Variant;
  /** Whether the buttons should show a loading state. Also disables the buttons when `true`. Optional. */
  loading?: boolean;
  /** Sets a custom confirm label */
  confirmLabel?: string;
  /** Called when Cancel button is clicked */
  onCancel: MouseEventHandler<HTMLButtonElement>;
  /** Called when Confirm button is clicked */
  onConfirm: MouseEventHandler<HTMLButtonElement>;
};

/** An alert dialog is a modal dialog that interrupts the user's workflow to communicate an important message and acquire a response. */
export const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  {
    open,
    variant = 'warning',
    children,
    loading,
    confirmLabel,
    onCancel,
    onConfirm,
  },
  ref
) {
  return (
    <Modal
      ref={ref}
      open={open}
      role="alertdialog"
      aria-describedby="alertdialog-message"
      className="bg-neutral-900/50"
    >
      <Modal.Content className="h-full flex items-center justify-center">
        <div
          className={cn(
            'max-w-md flex flex-col gap-4 p-4 rounded-sm text-s shadow',
            variant === 'warning' && 'bg-yellow-100 text-yellow-800',
            variant === 'error' && 'bg-orange-100 text-red-500'
          )}
        >
          <div className="flex gap-2 justify-start" id="alertdialog-message">
            <i className="flex h-6 min-w-6 items-center justify-center">
              {iconMap[variant]}
            </i>
            {children}
          </div>
          <div className="w-full flex gap-2">
            <Button
              variant="inverted"
              onClick={onCancel}
              className="w-full"
              loading={loading}
            >
              Cancel
            </Button>
            <Button
              variant={variant}
              onClick={onConfirm}
              className="w-full"
              loading={loading}
            >
              {confirmLabel || 'Confirm'}
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
});
