import { useQuery } from '@tanstack/react-query';
import { useApi } from 'contexts/ApiProvider';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import {
  GetComputedMeasurementQuery,
  GetLimitedZoneMeasurementsQuery,
  GetMeasurementsByIdsQuery,
  useGetComputedMeasurementQuery,
  useGetEnumerationByCodeAndTypeQuery,
  useGetImageFeedMeasurementRunsQuery,
  useGetLimitedZoneMeasurementsQuery,
  useGetMeasurementRunsBetweenDatesByZoneQuery,
  useGetMeasurementsByIdsQuery,
  useGetMeasurementsByRunIdsAndPositionQuery,
  useGetMeasurementsOnGridByRunIdAndSensorTypeIdQuery,
  useGetUnlimitedZoneMeasurementsQuery,
} from 'graphql/generated/react_apollo';
import { useCurrentZone } from 'hooks/useCurrentZone';
import isNil from 'lodash.isnil';
import keyBy from 'lodash.keyby';
import { useMemo } from 'react';
import { EEnumerationTypes } from 'shared/interfaces/enumeration';
import { EImageTypes, ImageTypeToSensorCodeMap } from 'shared/interfaces/image';
import {
  EMeasurementStatisticsTypesV2,
  GetMeasurementTypeFunction,
  MeasurementAggregation,
  MeasurementSource,
  MeasurementTypeConfig,
  SignalMeasurements,
  SignalMeasurementsType,
  TCurrentZoneMeasurements,
} from 'shared/interfaces/measurement';
import { TMeasurementRun } from 'shared/interfaces/measurementRun';
import { useGetDailyHealthLabelsByZoneIdTypeCode } from './labels';

const { AIR_VPD, LEAF_VPD } = EMeasurementStatisticsTypesV2;

type TMeasurementById = ArrayElement<GetMeasurementsByIdsQuery['measurement']>;

export interface MeasurementRequestParams {
  signals: MeasurementTypeConfig[];
  zoneTimeZone: string;
  zoneId: number;
  start: Date;
  end: Date;
  zoneUid?: string;
  isContinuous?: boolean;
  aggregation?: MeasurementAggregation;
}

const useGetComputedMeasurement = ({
  zoneId,
  zoneTimeZone,
  start,
  end,
  signals,
  isContinuous,
}: MeasurementRequestParams) => {
  const requestSignals = useMemo(
    () =>
      !isContinuous
        ? []
        : signals.filter(
            ({ apis, computed }) =>
              apis.includes('gql-measurement-computed') && computed
          ),
    [isContinuous, signals]
  );

  const {
    previousData: previousRawData,
    data: rawData = previousRawData,
    ...result
  } = useGetComputedMeasurementQuery({
    skip: requestSignals.length === 0,
    variables: {
      zoneId,
      startTime: start,
      endTime: end,
      computedMetricTypeCodes: requestSignals.map(
        ({ statisticsKeyV2 }) => statisticsKeyV2
      ),
    },
  });

  const data = useMemo(() => {
    const extractValues = (signal: MeasurementTypeConfig) => {
      if (!rawData) {
        return [];
      }

      return rawData.computed_measurement.reduce<[number, number][]>(
        (values, { time, data }) => {
          const value = data[signal.statisticsKey];
          if (value) {
            values.push([
              utcToZonedTime(time, zoneTimeZone).valueOf(),
              signal.convertFromUnit(value),
            ]);
          }

          return values;
        },
        []
      );
    };

    const allValues = new SignalMeasurements();
    for (const signal of requestSignals) {
      allValues.set(signal, extractValues(signal));
    }

    return allValues;
  }, [rawData, requestSignals, zoneTimeZone]);

  return { data, ...result };
};

const useGetMeasurementRunsBetweenDatesByZone = ({
  zoneId,
  zoneTimeZone,
  start,
  end,
  signals,
  isContinuous,
}: MeasurementRequestParams) => {
  const requestSignals = useMemo(
    () =>
      isContinuous
        ? []
        : signals.filter(({ apis }) => apis.includes('gql-measurement-run')),
    [isContinuous, signals]
  );

  const {
    previousData: previousRawData,
    data: rawData = previousRawData,
    ...result
  } = useGetMeasurementRunsBetweenDatesByZoneQuery({
    skip: requestSignals.length === 0,
    variables: {
      zone_id: Number(zoneId),
      start,
      end,
    },
  });

  const data = useMemo<SignalMeasurementsType>(() => {
    const extractValues = (signal: MeasurementTypeConfig) => {
      if (!rawData) {
        return [];
      }

      return rawData.measurement_run.reduce<[number, number][]>(
        (values, measurementRun) => {
          const mean =
            measurementRun.metadata.statistics?.[signal.statisticsKey]?.mean;

          if (mean !== undefined) {
            const date = utcToZonedTime(
              measurementRun.start_time,
              zoneTimeZone
            ).valueOf();
            const value = signal.convertFromUnit(Number(mean));

            values.push([date, value]);
          }

          return values;
        },
        []
      );
    };

    const allValues = new SignalMeasurements();
    for (const signal of requestSignals) {
      allValues.set(signal, extractValues(signal));
    }

    return allValues;
  }, [rawData, requestSignals, zoneTimeZone]);

  return { data, ...result };
};

const useGetUnlimitedZoneMeasurements = ({
  zoneId,
  zoneTimeZone,
  start,
  end,
  signals,
  isContinuous,
}: MeasurementRequestParams) => {
  const requestSignals = useMemo(
    () =>
      !isContinuous
        ? []
        : signals.filter(
            ({ apis, computed }) =>
              apis.includes('gql-measurement-run') && !computed
          ),
    [isContinuous, signals]
  );

  const {
    previousData: previousRawData,
    data: rawData = previousRawData,
    ...result
  } = useGetUnlimitedZoneMeasurementsQuery({
    variables: {
      zone_id: zoneId,
      measurement_types: requestSignals.map(
        ({ statisticsKeyV2 }) => statisticsKeyV2
      ),
      start,
      end,
    },
    skip: requestSignals.length === 0,
  });

  const data = useMemo<SignalMeasurementsType>(() => {
    const extractValues = (signal: MeasurementTypeConfig) => {
      if (!rawData) {
        return [];
      }

      const signalValues = rawData.measurement_view.filter(
        ({ measurement_type: type }) => type === signal.statisticsKeyV2
      );

      return signalValues.map<[number, number]>(({ time, data }) => [
        utcToZonedTime(time, zoneTimeZone).valueOf(),
        signal.convertFromUnit(Number(data)),
      ]);
    };

    const allValues = new SignalMeasurements();
    for (const signal of requestSignals) {
      allValues.set(signal, extractValues(signal));
    }

    return allValues;
  }, [rawData, requestSignals, zoneTimeZone]);

  return { data, ...result };
};

export const useGetZoneMeasurements = ({
  zoneId,
  zoneTimeZone,
  start,
  end,
  getMeasurementType,
}: {
  zoneId: number;
  zoneTimeZone: string;
  start: Date;
  end: Date;
  getMeasurementType: GetMeasurementTypeFunction;
}) => {
  const measurementsResult = useGetLimitedZoneMeasurementsQuery({
    variables: {
      zone_id: zoneId,
      start,
      end,
      limit: 10,
    },
  });

  const airVpdResult = useGetComputedMeasurementQuery({
    variables: {
      zoneId,
      startTime: start,
      endTime: end,
      computedMetricTypeCodes: [AIR_VPD],
    },
  });

  const leafVpdResult = useGetComputedMeasurementQuery({
    variables: {
      zoneId,
      startTime: start,
      endTime: end,
      computedMetricTypeCodes: [LEAF_VPD],
    },
  });

  return useMemo(() => {
    const measurements = (measurementsResult.data?.measurement_view ?? []).map(
      (item) => ({
        ...item,
        time: utcToZonedTime(item.time, zoneTimeZone),
      })
    );
    // air vpd
    const firstAirVpd = airVpdResult.data?.computed_measurement[0];
    const airVpd = firstAirVpd && {
      ...firstAirVpd,
      time: utcToZonedTime(firstAirVpd.time, zoneTimeZone),
    };
    // leaf vpd
    const firstLeafVpd = leafVpdResult.data?.computed_measurement[0];
    const leafVpd = firstLeafVpd && {
      ...firstLeafVpd,
      time: utcToZonedTime(firstLeafVpd.time, zoneTimeZone),
    };

    return {
      currentZoneMeasurements: {
        ...getStatisticsFromMeasurementsView(getMeasurementType, measurements),
        ...getStatisticsFromComputedMeasurement(airVpd, AIR_VPD),
        ...getStatisticsFromComputedMeasurement(leafVpd, LEAF_VPD),
      },
      measurements,
      loading:
        measurementsResult.loading ||
        airVpdResult.loading ||
        leafVpdResult.loading,
    };
  }, [
    airVpdResult.data?.computed_measurement,
    airVpdResult.loading,
    getMeasurementType,
    leafVpdResult.data?.computed_measurement,
    leafVpdResult.loading,
    measurementsResult.data?.measurement_view,
    measurementsResult.loading,
    zoneTimeZone,
  ]);
};

/** */
function getStatisticsFromMeasurementsView(
  getMeasurementType: GetMeasurementTypeFunction,
  measurementsView: GetLimitedZoneMeasurementsQuery['measurement_view']
) {
  return measurementsView.reduce((zoneMeasurements, measurement) => {
    if (isNil(measurement.measurement_type)) {
      return zoneMeasurements;
    }

    const key = measurement.measurement_type;
    if (zoneMeasurements[key]) {
      return zoneMeasurements;
    }

    const { convertFromUnit } = getMeasurementType(
      measurement.measurement_type
    );

    const mean = convertFromUnit(Number(measurement.data));

    return { ...zoneMeasurements, [key]: { mean } };
  }, {} as TCurrentZoneMeasurements);
}

/** */
function getStatisticsFromComputedMeasurement(
  vpdMeasurement: Optional<
    ArrayElement<GetComputedMeasurementQuery['computed_measurement']>
  >,
  vpdCode: EMeasurementStatisticsTypesV2
) {
  if (!vpdMeasurement) return {};

  const measurement = vpdMeasurement!;
  const zoneMeasurements: TCurrentZoneMeasurements = {};

  if (!isNil(measurement.data)) {
    zoneMeasurements[vpdCode] = {
      mean: measurement.data[vpdCode.toLocaleLowerCase()],
    };
  }

  return zoneMeasurements;
}

export type GetMeasurementsOnGridByRunIdAndSensorTypeIdData = {
  measurementIds: Nullable<number>[];
  numberOfRows: number;
  numberOfColumns: number;
};

/** */
export function useGetMeasurementsOnGridByRunIdAndSensorTypeId({
  measurementRun,
  imageType,
  options,
}: {
  measurementRun?: Nullable<TMeasurementRun>;
  imageType: EImageTypes;
  options: {
    skip?: boolean;
    onCompleted?: (
      data: Optional<GetMeasurementsOnGridByRunIdAndSensorTypeIdData>
    ) => void;
  };
}) {
  const { data: enumerationData, loading: enumerationLoading } =
    useGetEnumerationByCodeAndTypeQuery({
      variables: {
        code: ImageTypeToSensorCodeMap[imageType],
        type: EEnumerationTypes.MEASUREMENT_TYPE,
      },
    });

  const {
    data: gridViewData,
    loading: gridViewLoading,
    ...result
  } = useGetMeasurementsOnGridByRunIdAndSensorTypeIdQuery({
    variables: {
      run_id: measurementRun?.id as number,
      sensor_type_id: enumerationData?.enumeration?.[0]?.id as number,
    },
    skip:
      !enumerationData?.enumeration?.length ||
      !measurementRun?.id ||
      options?.skip,
    onCompleted: (data) =>
      options?.onCompleted?.(
        data?.get_measurements_on_grid?.[0]
          ? {
              measurementIds:
                data.get_measurements_on_grid[0].grid_measurements,
              numberOfRows: data.get_measurements_on_grid[0].grid_shape_y || 0,
              numberOfColumns:
                data.get_measurements_on_grid[0].grid_shape_x || 0,
            }
          : undefined
      ),
  });

  const measurementIds = useMemo(
    () =>
      (gridViewData?.get_measurements_on_grid?.[0]
        ?.grid_measurements as Nullable<number>[]) || [],
    [gridViewData]
  );

  return {
    ...result,
    data: {
      measurementIds,
      numberOfRows: gridViewData?.get_measurements_on_grid?.[0]?.grid_shape_y,
      numberOfColumns:
        gridViewData?.get_measurements_on_grid?.[0]?.grid_shape_x,
    },
    loading: gridViewLoading || enumerationLoading,
  };
}

/** */
export function useGetMeasurementsByIds({
  measurementIds,
  options,
}: {
  measurementIds: Nullable<number>[];
  options?: {
    skip?: boolean;
  };
}) {
  const { zoneTimeZone } = useCurrentZone();
  const { data, ...result } = useGetMeasurementsByIdsQuery({
    variables: { ids: measurementIds.filter((id) => !isNil(id)) },
    skip: measurementIds.length === 0 || options?.skip,
  });

  const measurements = useMemo(
    () =>
      (data?.measurement &&
        data.measurement.map((measurement) => ({
          ...measurement,
          time: utcToZonedTime(measurement.time, zoneTimeZone),
        }))) ??
      [],
    [data, zoneTimeZone]
  );

  const measurementsById: Record<string, TMeasurementById> = useMemo(
    () => keyBy(measurements ?? {}, 'id'),
    [measurements]
  );

  return { measurements, measurementsById, ...result };
}

export const useGetMeasurementsByRunIdsAndPosition = ({
  measurementRunIds,
  position,
  gridSize,
  imageType,
}: {
  measurementRunIds: number[];
  position: TPosition;
  gridSize: TGridSize;
  imageType: EImageTypes;
}) => {
  const { data: enumerationData } = useGetEnumerationByCodeAndTypeQuery({
    variables: {
      code: ImageTypeToSensorCodeMap[imageType],
      type: EEnumerationTypes.MEASUREMENT_TYPE,
    },
  });
  const sensorTypeId = enumerationData?.enumeration?.[0]?.id as number;
  const { data: measurementData, ...result } =
    useGetMeasurementsByRunIdsAndPositionQuery({
      variables: {
        measurementRunIds,
        xIndex: position.x + 1,
        yIndex: gridSize.row - position.y,
        sensorTypeId,
      },
      skip: isNil(sensorTypeId),
    });

  const measurementIds = useMemo(() => {
    return (measurementData?.measurements_by_grid_index ?? []).map(
      (result) => result.measurement_id as Nullable<number>
    );
  }, [measurementData]);

  return { measurementIds, ...result };
};

export const useGetImageFeedMeasurementRuns = (
  zoneTimeZone: string,
  startTime?: number,
  endTime?: number,
  zoneId?: string
) => {
  const { data: measurementRunData, ...result } =
    useGetImageFeedMeasurementRunsQuery({
      variables: {
        end: endTime && zonedTimeToUtc(new Date(endTime), zoneTimeZone),
        start: startTime && zonedTimeToUtc(new Date(startTime), zoneTimeZone),
        zone_id: Number(zoneId),
      },
      skip: isNil(endTime) || isNil(startTime) || isNil(zoneId),
    });

  const measurementRuns = useMemo(() => {
    const runs = measurementRunData?.measurement_run || [];
    return runs
      .slice()
      .map((run) => ({
        ...run,
        start_time: utcToZonedTime(new Date(run.start_time), zoneTimeZone),
        end_time: utcToZonedTime(new Date(run.end_time), zoneTimeZone),
      }))
      .sort(
        (a, b) =>
          new Date(a.start_time).valueOf() - new Date(b.start_time).valueOf()
      );
  }, [measurementRunData, zoneTimeZone]);

  return { measurementRuns, ...result };
};

interface MeasurementsResponse {
  values: Record<
    MeasurementSource,
    Record<string, { timestamp: number; value: number }[]>
  >;
}

const useMeasurementsQuery = ({
  zoneUid,
  zoneTimeZone,
  start,
  end,
  signals,
  aggregation,
}: MeasurementRequestParams) => {
  const { apiUrl, httpClient } = useApi();
  const url = new URL('dashboard/v1/measurements', apiUrl).toString();
  const requestSignals = useMemo(
    () => signals.filter(({ apis }) => apis.includes('rest-measurements')),
    [signals]
  );
  const body = JSON.stringify({
    zoneUid,
    start: String(start.valueOf() / 1000),
    end: String(end.valueOf() / 1000),
    aggregation: aggregation ? aggregation.toLowerCase() : '',
    signalIds: requestSignals.reduce(
      (signalIds, { source, type }) => {
        if (signalIds[source]) {
          signalIds[source].push(type);
        } else {
          signalIds[source] = [type];
        }

        return signalIds;
      },
      {} as Record<MeasurementSource, string[]>
    ),
  });

  const { data: rawData, ...result } = useQuery<MeasurementsResponse, Error>({
    queryKey: ['signal-measurements', body],
    queryFn: () => httpClient.post(url, { body }).json<MeasurementsResponse>(),
    enabled:
      !!zoneUid &&
      !!start &&
      !!end &&
      !!aggregation &&
      requestSignals.length > 0,
  });

  const data = useMemo(() => {
    if (!rawData) {
      return;
    }

    const allValues = new SignalMeasurements();

    for (const signal of requestSignals) {
      const measurementValues =
        rawData.values[signal.source][signal.type] ?? [];
      const values = measurementValues.map<[number, number]>(
        ({ timestamp, value }) => [
          utcToZonedTime(timestamp * 1000, zoneTimeZone).valueOf(),
          signal.convertFromUnit(value),
        ]
      );

      allValues.set(signal, values);
    }

    return allValues;
  }, [rawData, requestSignals, zoneTimeZone]);

  return { data, ...result };
};

export const useGetMeasurementsByTypeAndTimeRange = ({
  signals,
  zoneUid,
  zoneId,
  zoneTimeZone,
  start,
  end,
  aggregation,
}: {
  signals: MeasurementTypeConfig[];
  zoneUid: string;
  zoneId: number;
  zoneTimeZone: string;
  start: Date;
  end: Date;
  aggregation: Optional<MeasurementAggregation>;
}) => {
  const params = useMemo(
    () => ({
      zoneTimeZone,
      zoneId,
      start: zonedTimeToUtc(start, zoneTimeZone),
      end: zonedTimeToUtc(end, zoneTimeZone),
      isContinuous: aggregation === MeasurementAggregation.CONTINUOUS,
      aggregation,
      signals,
      zoneUid,
    }),
    [aggregation, end, signals, start, zoneId, zoneTimeZone, zoneUid]
  );

  const environmental = useGetMeasurementRunsBetweenDatesByZone(params);

  const environmentalContinuous = useGetUnlimitedZoneMeasurements(params);

  const environmentalComputed = useGetComputedMeasurement(params);

  const labels = useGetDailyHealthLabelsByZoneIdTypeCode(params);

  const others = useMeasurementsQuery(params);

  const error =
    environmental.error ||
    environmentalContinuous.error ||
    environmentalComputed.error ||
    labels.error ||
    others.error;

  const loading =
    environmental.loading ||
    environmentalContinuous.loading ||
    environmentalComputed.loading ||
    labels.loading ||
    others.isFetching;

  const called =
    environmental.called ||
    environmentalContinuous.called ||
    environmentalComputed.called ||
    labels.called ||
    others.isFetched;

  const data = useMemo(() => {
    return new SignalMeasurements([
      ...environmental.data.entries(),
      ...environmentalContinuous.data.entries(),
      ...environmentalComputed.data.entries(),
      ...labels.data.entries(),
      ...(others.data ? others.data.entries() : []),
    ]);
  }, [
    environmental.data,
    environmentalContinuous.data,
    environmentalComputed.data,
    labels.data,
    others.data,
  ]);

  return {
    error,
    loading,
    called,
    data,
  };
};
