import { captureException } from '@sentry/core';
import memoize from 'lodash.memoize';
import { NO_LABEL_CODE_SELECTION } from 'shared/constants/image';
import { ERoutePath } from 'shared/constants/url';
import { EAggregationTypes, EGradientTypes } from 'shared/interfaces/heatmap';
import {
  EImageLabelCategory,
  EImageTypes,
  EImageViewTypes,
} from 'shared/interfaces/image';
import { MeasurementAggregation } from 'shared/interfaces/measurement';
import { EProfileTabs } from 'shared/interfaces/settings';
import { z, ZodArray, ZodOptional } from 'zod';

export const queryParamsSchemas = {
  [ERoutePath.ROOT]: z.object({}),
  [ERoutePath.RESET_PASSWORD]: z.object({
    username: z.string(),
  }),
  [ERoutePath.ZONE_DETAILS_PAGE]: z
    .object({
      'ts-end': z.coerce.number().int().positive(),
      'ts-start': z.coerce.number().int().positive(),
      'mr-ts': z.coerce.number().int().positive(),
      'discussion-uid': z.string(),
    })
    .partial(),
  [ERoutePath.SETTINGS_TAB]: z
    .object({
      organization: z.string(),
    })
    .partial(),
  [ERoutePath.SETTINGS_USERS]: z
    .object({
      organization: z.string(),
      page: z.coerce.number().int().positive(),
    })
    .partial(),
  [ERoutePath.SETTINGS_EDIT_USER]: z
    .object({
      organization: z.string(),
    })
    .partial(),
  [ERoutePath.SETTINGS_EDIT_PROFILE]: z
    .object({
      organization: z.string(),
      tab: z.nativeEnum(EProfileTabs),
    })
    .partial(),
  [ERoutePath.LINE_CHART]: z
    .object({
      'signal-ids': z.array(z.string()),
      'view-type': z.nativeEnum(MeasurementAggregation),
      'mr-ts': z.coerce.number().int().positive(),
      'ts-end': z.coerce.number().int().positive(),
      'ts-start': z.coerce.number().int().positive(),
      'show-comments': z.enum(['true', 'false']).transform((v) => v === 'true'),
      'show-summary': z.enum(['true', 'false']).transform((v) => v === 'true'),
      'discussion-uid': z.string(),
    })

    .partial(),
  [ERoutePath.HEAT_MAP]: z
    .object({
      'show-comments': z.enum(['true', 'false']).transform((v) => v === 'true'),
      'discussion-uid': z.string(),
      'aggregation-type': z.nativeEnum(EAggregationTypes),
      'gradient-type': z.nativeEnum(EGradientTypes),
      'signal-ids': z.array(z.string()),
      'mr-ts': z.coerce.number().int().positive(),
      'ts-end': z.coerce.number().int().positive(),
      'ts-start': z.coerce.number().int().positive(),
      range: z.array(z.coerce.number()).length(4),
    })
    .partial(),
  [ERoutePath.IMAGE_FEED]: z
    .object({
      'image-label-code': z
        .custom<EImageLabelCategory>((value) => {
          const labelCategories = Object.values(EImageLabelCategory);
          return (
            labelCategories.includes(value as EImageLabelCategory) ||
            labelCategories.some((category) =>
              new RegExp(`^${category}:(.+)$`).test(value as string)
            )
          );
        })
        .or(z.literal(NO_LABEL_CODE_SELECTION)),
      'image-type': z.nativeEnum(EImageTypes),
      'image-view-type': z.nativeEnum(EImageViewTypes),
      'image-location': z.string().regex(/^\d+-\d+$/),
      scale: z.coerce.number().positive(),
      'mr-ts': z.coerce.number().int().positive(),
      // Use hack to parse boolean until this lands: https://github.com/colinhacks/zod/pull/2989
      showGridInfo: z.enum(['true', 'false']).transform((v) => v === 'true'),
      'show-cultivars': z
        .enum(['true', 'false'])
        .transform((v) => v === 'true'),
      'show-comments': z.enum(['true', 'false']).transform((v) => v === 'true'),
      'edit-cultivars': z
        .enum(['true', 'false'])
        .transform((v) => v === 'true'),
      'ts-end': z.coerce.number().int().positive(),
      'ts-start': z.coerce.number().int().positive(),
      'discussion-uid': z.string(),
      x: z.coerce.number(),
      y: z.coerce.number(),
    })
    .partial(),
  [ERoutePath.GLOBAL_INSIGHTS]: z
    .object({
      'start-time': z.coerce.number().int().positive(),
    })
    .partial(),
  [ERoutePath.ZONE_INSIGHTS]: z
    .object({
      'start-time': z.coerce.number().int().positive(),
    })
    .partial(),
};

export type QueryParamsSchemasKey = keyof typeof queryParamsSchemas;

export type QueryParamsSchema<T extends QueryParamsSchemasKey> = z.infer<
  (typeof queryParamsSchemas)[T]
>;

/**
 * Retrieves and parses query parameters from a URLSearchParams object, using a specified Zod schema.
 * It handles query parameters that might appear multiple times (arrays) by accumulating their values.
 *
 * @template T - A key type extending from QueryParamsSchemasKey, used to specify the schema.
 * @param {URLSearchParams} searchParams - The URLSearchParams instance containing the query parameters.
 * @param {T} path - A key that corresponds to a specific schema in queryParamsSchemas.
 * @returns {QueryParamsSchema<T>} - The parsed query parameters, validated and parsed according to the schema.
 * @throws {Error} If a schema for the provided path is not found.
 */
export const getQueryParams = memoize(
  <T extends QueryParamsSchemasKey>(
    searchParams: URLSearchParams,
    path: T
  ): QueryParamsSchema<T> => {
    const schema = queryParamsSchemas[path];
    if (!schema) {
      const error = new Error(`Schema not found for path: ${path}`);
      captureException(error);
      throw error;
    }

    // Identify fields that are arrays in the schema
    const arrayFields = Object.entries(schema.shape)
      .filter(([, fieldSchema]) => {
        const unwrappedFieldSchema =
          fieldSchema instanceof ZodOptional
            ? fieldSchema.unwrap()
            : fieldSchema;
        return unwrappedFieldSchema instanceof ZodArray;
      })
      .map(([key]) => key);

    const params = [...searchParams.entries()].reduce<
      Record<string, string | string[]>
    >((acc, [key, value]) => {
      // Skip if value is 'undefined' or undefined
      if (['undefined', undefined].includes(value)) {
        return acc;
      }
      if (arrayFields.includes(key)) {
        // If the key is in the arrayFields, handle it as an array
        const existingValue = acc[key];
        if (existingValue && Array.isArray(existingValue)) {
          (acc[key] as string[]).push(value);
        } else {
          acc[key] = [value];
        }
      } else {
        // Set the key-value pair normally if key doesn't exist
        acc[key] = value;
      }

      return acc;
    }, {});

    return schema.parse(params) as QueryParamsSchema<T>;
  },
  (searchParams: URLSearchParams, path: string) =>
    `${path}-${searchParams.toString()}`
);
