import { AirTempIcon } from 'icons/AirTempIcon';
import { AirTempLeafTempIcon } from 'icons/AirTempLeafTempIcon';
import { AirVPDIcon } from 'icons/AirVPDIcon';
import { CO2Icon } from 'icons/CO2Icon';
import { HumidityIcon } from 'icons/HumidityIcon';
import { LeafTemperatureIcon } from 'icons/LeafTemperatureIcon';
import { LeafVpdIcon } from 'icons/LeafVpdIcon';
import { PlantHeightIcon } from 'icons/PlantHeightIcon';
import { PpfdIcon } from 'icons/PpfdIcon';
import clonedeep from 'lodash.clonedeep';
import { ReactNode, createContext, useContext } from 'react';
import {
  EHeatMapCodes,
  EMeasurementGroup,
  EMeasurementStatisticsTypes,
  EMeasurementStatisticsTypesV2,
  EMeasurementTypes,
  GetMeasurementTypeFunction,
  MeasurementTypeConfig,
  MeasurementUnit,
  TPreferredUnits,
} from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';
import {
  convertCelsiusToFahrenheit,
  convertDifferenceCelsiusToFahrenheit,
  convertFahrenheitToCelsius,
  convertMeterToFeet,
  convertMeterToInches,
  hashStringToIndex,
  noop,
} from 'shared/utils/converters';

const STYLES = (() => {
  const styles: MeasurementTypeConfig['style'][] = [];
  const colors = ['orange', 'blue', 'green', 'red'];
  const tokens = [300, 400, 500, 700, 800, 900];
  const blacklist = [
    'orange-400',
    'blue-600',
    'purple-600',
    'yellow-900',
    'blue-300',
    'yellow-600',
    'green-600',
    'orange-700',
    'green-800',
    'purple-700',
    'brown-700',
    'ash-700',
    'ocean-700',
    'sand-700',
  ];

  for (const color of colors) {
    for (const token of tokens) {
      const colorToken = `${color}-${token}`;
      if (!blacklist.includes(colorToken)) {
        styles.push({
          svg: `highcharts-${colorToken}`,
          visx: `!stroke-${colorToken}`,
          html: `bg-${colorToken}`,
        });
      }
    }
  }
  return styles;
})();

export function getRandomStyle(input?: string): MeasurementTypeConfig['style'] {
  const index = input
    ? hashStringToIndex(input, STYLES.length)
    : Math.floor(Math.random() * STYLES.length);
  return STYLES[index];
}

const {
  AirCo2,
  AirLeafTemperatureDifference,
  AirTemperature,
  AirVpd,
  Distance,
  CalculatedDistance,
  LeafTemperature,
  LeafVpd,
  Par,
  RelativeHumidity,
  CalculatedArea,
} = EMeasurementStatisticsTypes;

const {
  ABNORMAL_SHAPE_FOLDING,
  ABNORMAL_SHAPE_WILTED,
  AIR_CO2,
  AIR_LEAF_TEMPERATURE_DIFFERENCE,
  AIR_TEMPERATURE,
  AIR_VPD,
  CALCULATED_DISTANCE,
  INFRARED_MATRIX,
  LEAF_VPD,
  NECROSIS_GENERAL,
  OBJECT_BUD,
  OTHER_POWDER,
  PAR,
  RELATIVE_HUMIDITY,
  YELLOWING_EDGES,
  YELLOWING_GENERAL,
} = EMeasurementStatisticsTypesV2;

const baseLabelCountMeasurementTypeConfig: Partial<MeasurementTypeConfig> = {
  apis: ['gql-labels-count'],
  source: 'neatleaf',
  group: EMeasurementGroup.PlantHealth,
  statisticsKey: CalculatedArea,
  unit: MeasurementUnit.areaPercent,
  hasOptimalRange: false,
  Icon: <div />,
  unitHeatmapXY: MeasurementUnit.ft,
  convertHeatmapXY: convertMeterToFeet,
  convertFromUnit: noop,
  convertToUnit: noop,
  computed: false,
  hasMultipleAggregations: false,
};
const MEASUREMENT_TYPES: Record<EMeasurementTypes, MeasurementTypeConfig> = {
  [EMeasurementTypes.Temperature]: {
    apis: ['gql-measurement-run'],
    source: 'neatleaf',
    group: EMeasurementGroup.Environment,
    type: EMeasurementTypes.Temperature,
    statisticsKey: AirTemperature,
    statisticsKeyV2: AIR_TEMPERATURE,
    style: {
      svg: 'highcharts-orange-500',
      visx: '!stroke-orange-500',
      html: 'bg-orange-500',
    },
    unit: MeasurementUnit.FahrenheitDegree,
    label: 'Air temperature',
    computed: false,
    hasOptimalRange: true,
    unitHeatmapXY: MeasurementUnit.ft,
    Icon: <AirTempIcon />,
    preset: {
      order: 1,
      managed: true,
      readOnly: false,
      isAverage: false,
      contextualInfo: null,
    },
    convertHeatmapXY: convertMeterToFeet,
    convertFromUnit: convertCelsiusToFahrenheit,
    convertToUnit: convertFahrenheitToCelsius,
    heatMapCodes: {
      desktop: EHeatMapCodes.AIR_TEMPERATURE_DESKTOP_RESOLUTION,
      mobile: EHeatMapCodes.AIR_TEMPERATURE_MOBILE_RESOLUTION,
    },
    hasMultipleAggregations: true,
    order: 1,
    isDefault: true,
  },
  [EMeasurementTypes.Humidity]: {
    apis: ['gql-measurement-run'],
    source: 'neatleaf',
    group: EMeasurementGroup.Environment,
    type: EMeasurementTypes.Humidity,
    statisticsKey: RelativeHumidity,
    statisticsKeyV2: RELATIVE_HUMIDITY,
    style: {
      svg: 'highcharts-blue-600',
      visx: '!stroke-blue-600',
      html: 'bg-blue-600',
    },
    unit: MeasurementUnit.percent,
    label: 'Relative humidity',
    computed: false,
    hasOptimalRange: true,
    unitHeatmapXY: MeasurementUnit.ft,
    Icon: <HumidityIcon className={cn('h-4', 'w-4')} />,
    preset: {
      order: 3,
      managed: true,
      readOnly: false,
      isAverage: false,
      contextualInfo: null,
    },
    convertHeatmapXY: convertMeterToFeet,
    convertFromUnit: noop,
    convertToUnit: noop,
    heatMapCodes: {
      desktop: EHeatMapCodes.RELATIVE_HUMIDITY_DESKTOP_RESOLUTION,
      mobile: EHeatMapCodes.RELATIVE_HUMIDITY_MOBILE_RESOLUTION,
    },
    hasMultipleAggregations: true,
    order: 2,
  },
  [EMeasurementTypes.LeafVpd]: {
    apis: ['gql-measurement-run', 'gql-measurement-computed'],
    source: 'neatleaf',
    group: EMeasurementGroup.Environment,
    type: EMeasurementTypes.LeafVpd,
    statisticsKey: LeafVpd,
    statisticsKeyV2: LEAF_VPD,
    style: {
      svg: 'highcharts-purple-600',
      visx: '!stroke-purple-600',
      html: 'bg-purple-600',
    },
    unit: MeasurementUnit.KP,
    label: 'Leaf VPD',
    computed: true,
    hasOptimalRange: true,
    unitHeatmapXY: MeasurementUnit.ft,
    Icon: <LeafVpdIcon className={cn('h-4', 'w-4')} />,
    preset: {
      order: 7,
      managed: true,
      readOnly: true,
      isAverage: true,
      contextualInfo:
        'These set points are automatically computed from Air Temperature, Leaf Temperature and Relative Humidity parameters',
    },
    convertHeatmapXY: convertMeterToFeet,
    convertFromUnit: noop,
    convertToUnit: noop,
    heatMapCodes: {
      desktop: EHeatMapCodes.LEAF_VPD_DESKTOP_RESOLUTION,
      mobile: EHeatMapCodes.LEAF_VPD_MOBILE_RESOLUTION,
    },
    hasMultipleAggregations: true,
    order: 6,
  },
  [EMeasurementTypes.Vpd]: {
    apis: ['gql-measurement-run', 'gql-measurement-computed'],
    source: 'neatleaf',
    group: EMeasurementGroup.Environment,
    type: EMeasurementTypes.Vpd,
    statisticsKey: AirVpd,
    statisticsKeyV2: AIR_VPD,
    style: {
      svg: 'highcharts-yellow-900',
      visx: '!stroke-yellow-900',
      html: 'bg-yellow-900',
    },
    unit: MeasurementUnit.KP,
    label: 'Air VPD',
    computed: true,
    hasOptimalRange: true,
    unitHeatmapXY: MeasurementUnit.ft,
    Icon: <AirVPDIcon />,
    preset: {
      order: 6,
      managed: true,
      readOnly: true,
      isAverage: true,
      contextualInfo:
        'These set points are automatically computed from Air Temperature and Relative Humidity parameters',
    },
    convertHeatmapXY: convertMeterToFeet,
    convertFromUnit: noop,
    convertToUnit: noop,
    heatMapCodes: {
      desktop: EHeatMapCodes.AIR_VPD_DESKTOP_RESOLUTION,
      mobile: EHeatMapCodes.AIR_VPD_MOBILE_RESOLUTION,
    },
    hasMultipleAggregations: true,
    order: 5,
  },
  [EMeasurementTypes.Co2]: {
    apis: ['gql-measurement-run'],
    source: 'neatleaf',
    group: EMeasurementGroup.Environment,
    type: EMeasurementTypes.Co2,
    statisticsKey: AirCo2,
    statisticsKeyV2: AIR_CO2,
    style: {
      svg: 'highcharts-blue-300',
      visx: '!stroke-blue-300',
      html: 'bg-blue-300',
    },
    unit: MeasurementUnit.ppm,
    label: 'CO₂',
    computed: false,
    hasOptimalRange: true,
    unitHeatmapXY: MeasurementUnit.ft,
    Icon: <CO2Icon />,
    preset: {
      order: 4,
      managed: true,
      readOnly: false,
      isAverage: false,
      contextualInfo: null,
    },
    convertHeatmapXY: convertMeterToFeet,
    convertFromUnit: noop,
    convertToUnit: noop,
    heatMapCodes: {
      desktop: EHeatMapCodes.AIR_CO2_DESKTOP_RESOLUTION,
      mobile: EHeatMapCodes.AIR_CO2_MOBILE_RESOLUTION,
    },
    hasMultipleAggregations: true,
    order: 7,
  },
  [EMeasurementTypes.Ppfd]: {
    apis: ['gql-measurement-run'],
    source: 'neatleaf',
    group: EMeasurementGroup.Environment,
    type: EMeasurementTypes.Ppfd,
    statisticsKey: Par,
    statisticsKeyV2: PAR,
    style: {
      svg: 'highcharts-yellow-600',
      visx: '!stroke-yellow-600',
      html: 'bg-yellow-600',
    },
    unit: MeasurementUnit.μMol_m2_s,
    label: 'PPFD',
    computed: false,
    hasOptimalRange: true,
    unitHeatmapXY: MeasurementUnit.ft,
    Icon: <PpfdIcon className={cn('h-4', 'w-4')} />,
    preset: {
      order: 5,
      managed: true,
      readOnly: false,
      isAverage: false,
      contextualInfo: null,
    },
    convertHeatmapXY: convertMeterToFeet,
    convertFromUnit: noop,
    convertToUnit: noop,
    heatMapCodes: {
      desktop: EHeatMapCodes.PAR_DESKTOP_RESOLUTION,
      mobile: EHeatMapCodes.PAR_MOBILE_RESOLUTION,
    },
    hasMultipleAggregations: true,
    order: 8,
  },
  [EMeasurementTypes.LeafTemperature]: {
    apis: ['gql-measurement-run'],
    source: 'neatleaf',
    group: EMeasurementGroup.Environment,
    type: EMeasurementTypes.LeafTemperature,
    statisticsKey: LeafTemperature,
    statisticsKeyV2: INFRARED_MATRIX,
    style: {
      svg: 'highcharts-green-600',
      visx: '!stroke-green-600',
      html: 'bg-green-600',
    },
    unit: MeasurementUnit.FahrenheitDegree,
    label: 'Leaf temperature',
    computed: false,
    hasOptimalRange: true,
    unitHeatmapXY: MeasurementUnit.ft,
    Icon: <LeafTemperatureIcon className={cn('h-4', 'w-4')} />,
    preset: {
      order: 2,
      managed: true,
      cloneFrom: AIR_TEMPERATURE,
      readOnly: false,
      isAverage: false,
      contextualInfo: null,
    },
    convertHeatmapXY: convertMeterToFeet,
    convertFromUnit: convertCelsiusToFahrenheit,
    convertToUnit: convertFahrenheitToCelsius,
    heatMapCodes: {
      desktop: EHeatMapCodes.LEAF_TEMPERATURE_DESKTOP_RESOLUTION,
      mobile: EHeatMapCodes.LEAF_TEMPERATURE_MOBILE_RESOLUTION,
    },
    hasMultipleAggregations: true,
    order: 3,
  },
  [EMeasurementTypes.CalculatedDistance]: {
    apis: ['gql-measurement-run', 'gql-measurement-computed'],
    source: 'neatleaf',
    group: EMeasurementGroup.PlantHealth,
    type: EMeasurementTypes.CalculatedDistance,
    statisticsKey: CalculatedDistance,
    statisticsKeyV2: CALCULATED_DISTANCE,
    statisticsKeyHeatMap: Distance,
    style: {
      svg: 'highcharts-orange-700',
      visx: '!stroke-orange-700',
      html: 'bg-orange-700',
    },
    unit: MeasurementUnit.ft,
    label: 'Distance to canopy',
    computed: true,
    hasOptimalRange: false,
    unitHeatmapXY: MeasurementUnit.ft,
    Icon: <PlantHeightIcon className={cn('h-4', 'w-4')} />,
    convertHeatmapXY: convertMeterToFeet,
    convertFromUnit: convertMeterToFeet,
    convertToUnit: noop,
    heatMapCodes: {
      desktop: EHeatMapCodes.DISTANCE_DESKTOP_RESOLUTION,
      mobile: EHeatMapCodes.DISTANCE_MOBILE_RESOLUTION,
    },
    reversedYAxis: true,
    hideHeatmapSingle: true,
    hasMultipleAggregations: true,
    order: 2,
  },
  [EMeasurementTypes.AirLeafTemperatureDifference]: {
    apis: ['gql-measurement-run', 'gql-measurement-computed'],
    source: 'neatleaf',
    group: EMeasurementGroup.PlantHealth,
    type: EMeasurementTypes.AirLeafTemperatureDifference,
    statisticsKey: AirLeafTemperatureDifference,
    statisticsKeyV2: AIR_LEAF_TEMPERATURE_DIFFERENCE,
    style: {
      svg: 'highcharts-green-800',
      visx: '!stroke-green-800',
      html: 'bg-green-800',
    },
    unit: MeasurementUnit.delta_degree_fahrenheit,
    label: 'Air-leaf temperature',
    computed: true,
    hasOptimalRange: false,
    unitHeatmapXY: MeasurementUnit.ft,
    Icon: <AirTempLeafTempIcon />,
    convertHeatmapXY: convertMeterToFeet,
    convertFromUnit: convertDifferenceCelsiusToFahrenheit,
    convertToUnit: noop,
    heatMapCodes: {
      desktop: EHeatMapCodes.AIR_LEAF_TEMPERATURE_DIFFERENCE_DESKTOP_RESOLUTION,
      mobile: EHeatMapCodes.AIR_LEAF_TEMPERATURE_DIFFERENCE_MOBILE_RESOLUTION,
    },
    hasMultipleAggregations: true,
    order: 1,
  },

  [EMeasurementTypes.AbnormalShapeFolding]: {
    ...(baseLabelCountMeasurementTypeConfig as MeasurementTypeConfig),
    type: EMeasurementTypes.AbnormalShapeFolding,
    statisticsKeyV2: ABNORMAL_SHAPE_FOLDING,
    label: 'Abnormal shape (folding)',
    style: {
      svg: 'highcharts-purple-700',
      visx: '!stroke-purple-700',
      html: 'bg-purple-700',
    },
  },
  [EMeasurementTypes.AbnormalShapeWilted]: {
    ...(baseLabelCountMeasurementTypeConfig as MeasurementTypeConfig),
    group: undefined,
    type: EMeasurementTypes.AbnormalShapeWilted,
    statisticsKeyV2: ABNORMAL_SHAPE_WILTED,
    label: 'Abnormal shape (wilted)',
    style: getRandomStyle(),
  },
  [EMeasurementTypes.NecrosisGeneral]: {
    ...(baseLabelCountMeasurementTypeConfig as MeasurementTypeConfig),
    type: EMeasurementTypes.NecrosisGeneral,
    statisticsKeyV2: NECROSIS_GENERAL,
    label: 'Necrosis',
    style: {
      svg: 'highcharts-brown-700',
      visx: '!stroke-brown-700',
      html: 'bg-brown-700',
    },
  },
  [EMeasurementTypes.ObjectBud]: {
    ...(baseLabelCountMeasurementTypeConfig as MeasurementTypeConfig),
    type: EMeasurementTypes.ObjectBud,
    statisticsKeyV2: OBJECT_BUD,
    label: 'Bud count',
    style: {
      svg: 'highcharts-ash-700',
      visx: '!stroke-ash-700',
      html: 'bg-ash-700',
    },
  },
  [EMeasurementTypes.OtherPowder]: {
    ...(baseLabelCountMeasurementTypeConfig as MeasurementTypeConfig),
    type: EMeasurementTypes.OtherPowder,
    statisticsKeyV2: OTHER_POWDER,
    label: 'Powder',
    style: {
      svg: 'highcharts-ocean-700',
      visx: '!stroke-ocean-700',
      html: 'bg-ocean-700',
    },
  },
  [EMeasurementTypes.YellowingEdges]: {
    ...(baseLabelCountMeasurementTypeConfig as MeasurementTypeConfig),
    group: undefined,
    type: EMeasurementTypes.YellowingEdges,
    statisticsKeyV2: YELLOWING_EDGES,
    label: 'Yellowing (edges)',
    style: getRandomStyle(),
  },
  [EMeasurementTypes.YellowingGeneral]: {
    ...(baseLabelCountMeasurementTypeConfig as MeasurementTypeConfig),
    type: EMeasurementTypes.YellowingGeneral,
    statisticsKeyV2: YELLOWING_GENERAL,
    label: 'Yellowing (general)',
    style: {
      svg: 'highcharts-sand-700',
      visx: '!stroke-sand-700',
      html: 'bg-sand-700',
    },
  },
};

const MEASUREMENT_STATISTICS_V2_TO_TYPE: Record<
  EMeasurementStatisticsTypesV2,
  EMeasurementTypes
> = {
  [AIR_TEMPERATURE]: EMeasurementTypes.Temperature,
  [RELATIVE_HUMIDITY]: EMeasurementTypes.Humidity,
  [LEAF_VPD]: EMeasurementTypes.LeafVpd,
  [AIR_VPD]: EMeasurementTypes.Vpd,
  [AIR_CO2]: EMeasurementTypes.Co2,
  [PAR]: EMeasurementTypes.Ppfd,
  [INFRARED_MATRIX]: EMeasurementTypes.LeafTemperature,
  [AIR_LEAF_TEMPERATURE_DIFFERENCE]:
    EMeasurementTypes.AirLeafTemperatureDifference,
  [CALCULATED_DISTANCE]: EMeasurementTypes.CalculatedDistance,
  [OBJECT_BUD]: EMeasurementTypes.ObjectBud,
  [ABNORMAL_SHAPE_FOLDING]: EMeasurementTypes.AbnormalShapeFolding,
  [ABNORMAL_SHAPE_WILTED]: EMeasurementTypes.AbnormalShapeWilted,
  [NECROSIS_GENERAL]: EMeasurementTypes.NecrosisGeneral,
  [OTHER_POWDER]: EMeasurementTypes.OtherPowder,
  [YELLOWING_EDGES]: EMeasurementTypes.YellowingEdges,
  [YELLOWING_GENERAL]: EMeasurementTypes.YellowingGeneral,
};

const MEASUREMENT_STATISTICS_TO_TYPE: Record<
  EMeasurementStatisticsTypes,
  EMeasurementTypes
> = {
  [AirTemperature]: EMeasurementTypes.Temperature,
  [RelativeHumidity]: EMeasurementTypes.Humidity,
  [LeafVpd]: EMeasurementTypes.LeafVpd,
  [AirVpd]: EMeasurementTypes.Vpd,
  [AirCo2]: EMeasurementTypes.Co2,
  [Par]: EMeasurementTypes.Ppfd,
  [LeafTemperature]: EMeasurementTypes.LeafTemperature,
  [AirLeafTemperatureDifference]:
    EMeasurementTypes.AirLeafTemperatureDifference,
  [Distance]: EMeasurementTypes.CalculatedDistance,
  [CalculatedDistance]: EMeasurementTypes.CalculatedDistance,
  [CalculatedArea]: EMeasurementTypes.ObjectBud,
};

const getType =
  (types: typeof MEASUREMENT_TYPES): GetMeasurementTypeFunction =>
  (type) => {
    const typeBasedOnStatistics =
      types[
        MEASUREMENT_STATISTICS_TO_TYPE[type as EMeasurementStatisticsTypes]
      ];
    if (typeBasedOnStatistics) {
      return typeBasedOnStatistics;
    }

    const typeBasedOnStatisticsV2 =
      types[
        MEASUREMENT_STATISTICS_V2_TO_TYPE[type as EMeasurementStatisticsTypesV2]
      ];
    if (typeBasedOnStatisticsV2) {
      return typeBasedOnStatisticsV2;
    }

    return types[type as EMeasurementTypes];
  };

interface TypeConfigProviderState {
  measurementTypes: typeof MEASUREMENT_TYPES;
  staticSignals: MeasurementTypeConfig[];
  heatmapSignals: MeasurementTypeConfig[];
  presetTypes: MeasurementTypeConfig[];
  getMeasurementType: ReturnType<typeof getType>;
}

const TypeConfigContext = createContext<TypeConfigProviderState>({
  staticSignals: [],
  heatmapSignals: [],
  getMeasurementType: noop,
  measurementTypes: MEASUREMENT_TYPES,
  presetTypes: [],
});

interface TypeConfigProviderProps {
  children: ReactNode | ((state: TypeConfigProviderState) => ReactNode);
  preferredUnits?: Maybe<TPreferredUnits>;
}

export const TypeConfigProvider: React.FC<TypeConfigProviderProps> = ({
  children,
  preferredUnits,
}) => {
  const measurementTypes = clonedeep(MEASUREMENT_TYPES);

  if (preferredUnits) {
    for (const [typeV2, unit] of Object.entries(preferredUnits)) {
      const typeKey =
        MEASUREMENT_STATISTICS_V2_TO_TYPE[
          typeV2 as EMeasurementStatisticsTypesV2
        ];

      if (!typeKey) {
        // Unknown types are skipped
        continue;
      }

      if (unit === 'celsius') {
        measurementTypes[typeKey].unit = MeasurementUnit.degree_celsius;
        measurementTypes[typeKey].convertFromUnit = noop;
        measurementTypes[typeKey].convertToUnit = noop;
        measurementTypes.air_leaf_temperature.unit =
          MeasurementUnit.delta_degree_degree_celsius;
      }
      if (unit === 'inches') {
        measurementTypes[typeKey].unit = MeasurementUnit.inches;
        measurementTypes[typeKey].convertFromUnit = convertMeterToInches;
      }
      if (unit === 'meters') {
        measurementTypes[typeKey].unit = MeasurementUnit.meters;
        measurementTypes[typeKey].convertFromUnit = noop;
      }
    }

    if (preferredUnits.CALCULATED_DISTANCE === 'meters') {
      // When the measurement type exists in the user's preferred units
      // then display the heatmap room layout in meters
      for (const typeKey of Object.keys(measurementTypes)) {
        measurementTypes[typeKey as EMeasurementTypes].convertHeatmapXY = noop;
        measurementTypes[typeKey as EMeasurementTypes].unitHeatmapXY =
          MeasurementUnit.meters;
      }
    }
  }

  const presetTypes = Object.values(measurementTypes)
    .filter(({ preset }) => !!preset?.managed)
    .sort((a, b) => (a.preset?.order ?? 0) - (b.preset?.order ?? 0));

  const staticSignals = Object.values(
    measurementTypes
  ).map<MeasurementTypeConfig>((measurementType) => ({
    ...measurementType,
  }));

  const heatmapSignals = staticSignals.filter(
    ({ heatMapCodes }) => !!heatMapCodes
  );

  const value = {
    staticSignals,
    measurementTypes,
    heatmapSignals,
    presetTypes,
    getMeasurementType: getType(measurementTypes),
  };

  return (
    <TypeConfigContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </TypeConfigContext.Provider>
  );
};

export const useTypeConfig = () => useContext(TypeConfigContext);
