import {
  Dialog,
  Transition,
  type DialogProps,
  type DialogTitleProps,
} from '@headlessui/react';
import { CloseIcon } from 'icons/CloseIcon';
import {
  Children,
  ComponentPropsWithoutRef,
  Fragment,
  ReactElement,
  ReactNode,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { cn } from 'shared/utils/cn';
import { Button, type ButtonProps } from '../Button/Button';

/**
 * Prevents the document from scrolling when the modal is open.
 * Anything behind the modal should be considered inert.
 */
function useBlockDocumentScroll() {
  const [overflow] = useState(document.body.style.overflow);
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = overflow;
    };
  }, [overflow]);
}

const _modes = ['normal', 'fullscreen', 'drawer-left', 'drawer-right'] as const;

type Mode = (typeof _modes)[number];

type ModalProps = Omit<DialogProps<'div'>, 'onClose'> & {
  /**
   * The dialog either displays in `normal` mode which renders the modal full-screen
   * or `drawer` mode which renders the modal sticked to the right of the screen.
   * The entry and exit animations change according to the mode.
   * Defaults to `normal`.
   * */
  mode?: Mode;

  /**
   * Called when pressing Escape key or clicking outside of the Modal area.
   * To dismiss this behavior, set `dismissOnEscape` to `true` and
   * `onClose` shouldn't be provided.
   * */
  // onClose?: () => void;
} & Exclusive<
    {
      dismissOnEscape: true;
      onClose: () => void;
    },
    {
      dismissOnEscape?: never;
      onClose?: () => void;
    }
  >;

/**
 * The Modal component is a dialog window that is layered on top of the current page.
 */
const ModalRoot = forwardRef<HTMLDivElement, ModalProps>(function Modal(
  {
    open = false,
    mode = 'fullscreen',
    className,
    children,
    dismissOnEscape,
    ...props
  },
  ref
) {
  const onClose = useMemo(
    () => (dismissOnEscape ? props.onClose || (() => null) : () => null),
    [dismissOnEscape, props.onClose]
  );
  const isDrawer = mode.includes('drawer');
  const transitions = useMemo(
    () => ({
      enter: 'transition ease duration-300 transform',
      leave: 'transition ease duration-300 transform',
      enterFrom: 'translate-y-full',
      enterTo: 'translate-y-0',
      leaveFrom: 'translate-y-0',
      leaveTo: 'translate-y-full',
      ...(mode === 'drawer-right' && {
        enterFrom: 'translate-x-full',
        enterTo: 'translate-x-0',
        leaveFrom: 'translate-x-0',
        leaveTo: 'translate-x-full',
      }),
      ...(mode === 'drawer-left' && {
        enterFrom: '-translate-x-full',
        enterTo: 'translate-x-0',
        leaveFrom: 'translate-x-0',
        leaveTo: '-translate-x-full',
      }),
    }),
    [mode]
  );

  useBlockDocumentScroll();

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        ref={ref}
        {...props}
        onClose={onClose}
        unmount={undefined}
        className="h-svh"
      >
        <Transition.Child as={Fragment} {...transitions}>
          <div aria-hidden="true" className="fixed inset-0 bg-neutral-900/50" />
        </Transition.Child>

        <Transition.Child as={Fragment} {...transitions}>
          <div
            className={cn(
              'fixed inset-0 flex overflow-hidden z-20',
              mode === 'drawer-right' && 'justify-end',
              mode === 'drawer-left' && 'justify-start'
            )}
          >
            <Dialog.Panel
              className={cn(
                'flex h-full w-full flex-col bg-white',
                isDrawer && 'md:w-[320px]',
                mode === 'normal' && [
                  'max-w-[100vw] max-h-dvh m-auto',
                  'sm:h-fit sm:max-w-fit sm:max-h-[calc(100lvh-48px)]',
                  'sm:rounded sm:shadow',
                ],
                className
              )}
            >
              {children}
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
});

interface HeaderProps extends Omit<ComponentPropsWithoutRef<'div'>, 'title'> {
  /** Title to be displayed  */
  title?: ReactNode;
  /** Applies class to a component root element */
  className?: string;
  /** Leading element */
  leadingElement?: ReactElement<HTMLElement>;
  closeButtonProps?: Partial<ButtonProps>;
  dialogTitleProps?: Partial<DialogTitleProps<any>>;
}

/**
 * The header of the modal. It contains the title, subtitle and icon.
 */
const Header = forwardRef<HTMLDivElement, HeaderProps>(function Header(
  {
    title,
    className,
    leadingElement,
    closeButtonProps,
    dialogTitleProps,
    ...props
  },
  ref
) {
  return (
    <header
      ref={ref}
      {...props}
      className={cn(
        'flex items-center justify-between gap-3 p-4',
        !title && 'justify-end',
        className
      )}
    >
      {leadingElement}

      <Dialog.Title
        {...dialogTitleProps}
        className={cn(
          'flex w-full items-center justify-center gap-2',
          'truncate text-xs font-medium text-neutral-900',
          !leadingElement && 'pl-9',
          dialogTitleProps?.className
        )}
      >
        {title}
      </Dialog.Title>

      {closeButtonProps && (
        <Button
          variant="secondary"
          size="icon"
          aria-label="Close modal"
          {...closeButtonProps}
          className={cn('ml-auto', closeButtonProps.className)}
        >
          <CloseIcon />
        </Button>
      )}
    </header>
  );
});

interface ContentProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'children'> {
  children: ReactNode;
  /** Applies class to a component root element */
  className?: string;
}

/**
 * The main content of the modal.
 */
const Content = forwardRef<HTMLDivElement, ContentProps>(function ModalContent(
  { children, className, ...props },
  ref
) {
  return (
    <main
      ref={ref}
      {...props}
      className={cn(
        'flex w-full items-start justify-center overflow-y-auto px-4 text-base text-neutral-900 scrollbar-thin',
        className
      )}
    >
      {children}
    </main>
  );
});

interface ModalFooterProps extends ComponentPropsWithoutRef<'div'> {}

/**
 * The footer of the modal. It contains the primary and secondary buttons.
 */
const Footer = forwardRef<HTMLDivElement, ModalFooterProps>(
  function ModalFooter({ className, children, ...props }, ref) {
    const childrenCount = Children.toArray(children).length;

    return (
      <footer
        ref={ref}
        {...props}
        className={cn(
          'flex flex-col justify-end gap-3 p-4 sm:flex-row',
          childrenCount > 1 && 'justify-between',
          className
        )}
      >
        {children}
      </footer>
    );
  }
);

export const Modal = Object.assign(ModalRoot, {
  Header,
  Content,
  Footer,
});
