import { useEffect, useMemo } from 'react';
import { MeasurementTypeConfig } from 'shared/interfaces/measurement';

export function useChartHints({
  chart,
  showResetZoom,
  isDrawing,
  signals,
}: {
  chart: Maybe<Highcharts.Chart>;
  showResetZoom?: boolean;
  isDrawing?: boolean;
  signals?: MeasurementTypeConfig[];
}) {
  const canHover = window?.matchMedia?.('(hover: hover)').matches;
  const subtitle = useMemo<Highcharts.SubtitleOptions>(() => {
    let text = canHover ? 'Use pinch-to-zoom' : 'Zoom by selecting an area';
    if (showResetZoom) {
      text = canHover ? 'Use two fingers to pan' : 'Press Shift key to pan';
    }
    if (isDrawing) {
      text = canHover
        ? 'Tap twice to define the start and end dates'
        : 'Click once to select time range start and end dates';
    }
    if (signals && signals.length === 0) {
      text = '';
    }

    return {
      align: 'left',
      text,
      floating: true,
      x: 30,
      y: 50,
    };
  }, [canHover, isDrawing, signals, showResetZoom]);

  useEffect(() => {
    if (chart && chart.subtitle && chart.series.length > 0) {
      chart.setSubtitle(subtitle);
    }
  }, [chart, subtitle]);
}
