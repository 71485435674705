import { useGetInsights, useUpdateDiscussionStatus } from 'api/discussion';
import { Alert } from 'components/common/Alert/Alert';
import { Button } from 'components/common/Button/Button';
import { CenteredLoader } from 'components/common/CenteredLoader';
import { Disclosure } from 'components/common/Disclosure/Disclosure';
import { Menu, MenuProps } from 'components/common/Menu';
import { Modal } from 'components/common/Modal/Modal';
import * as toast from 'components/common/Toast/Toast';
import {
  DiscussionBoxContextProvider,
  DiscussionBoxContextProviderProps,
} from 'components/discussions/DiscussionBoxContext';
import { EditInsight } from 'components/discussions/EditInsight';
import { InsightContent } from 'components/discussions/InsightContent';
import { useNavigateToDiscussion } from 'components/discussions/hooks/useNavigateToDiscussion';
import { NoDataView } from 'components/growth_cycle/NoDataView';
import { useAuth } from 'contexts/AuthProvider';
import {
  compareAsc,
  compareDesc,
  endOfDay,
  endOfWeek,
  getWeek,
  startOfWeek,
  subWeeks,
} from 'date-fns';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useDisclosure } from 'hooks/useDisclosure';
import { usePermissions } from 'hooks/usePermissions';
import { usePrevious } from 'hooks/usePrevious';
import { EditIcon } from 'icons/EditIcon';
import { ExternalLinkIcon } from 'icons/ExternalLinkIcon';
import { GarbageIcon } from 'icons/GarbageIcon';
import { MoreVerticalIcon } from 'icons/MoreVerticalIcon';
import get from 'lodash.get';
import groupBy from 'lodash.groupby';
import pluralize from 'pluralize';
import {
  ComponentProps,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import { InView } from 'react-intersection-observer';
import {
  annotationTypes,
  InsightCommentContent,
  InsightSeverity,
  TAnnotationType,
  TDiscussion,
} from 'shared/interfaces/discussion';
import { TZone } from 'shared/interfaces/zone';
import { cn } from 'shared/utils/cn';
import { formatDateInMMMd, formatDateInMMMDD } from 'shared/utils/date';
import { InsightPreview } from './InsightPreview';

type GroupedDiscussion = TDiscussion & {
  weekEnd: Date;
  zoneId: number;
  zoneName: string;
  locationName: string;
  organizationCode: string;
};

const severityValues = Object.values<`${InsightSeverity}`>(InsightSeverity);
const severityOrderMap = new Map(
  severityValues.map((value, index) => [value, index])
);

const annotationTypeOrderMap = new Map<TAnnotationType, number>([
  ['time_range_annotation', 0],
  ['single_image_annotation', 1],
  ['heatmap_aggregate_annotation', 2],
  ['heatmap_annotation', 3],
]);

function insightsSort<T>(keys: { key: NestedKeys<T>; ascending?: boolean }[]) {
  return (a: T, b: T): number => {
    let comparison = 0;

    for (const { key, ascending } of keys) {
      const valueA = get(a, key);
      const valueB = get(b, key);
      const severityA = valueA as InsightSeverity;
      const severityB = valueB as InsightSeverity;
      const annotationTypeA = valueA as TAnnotationType;
      const annotationTypeB = valueB as TAnnotationType;

      if (
        annotationTypes.includes(annotationTypeA) &&
        annotationTypes.includes(annotationTypeB)
      ) {
        const aIndex = annotationTypeOrderMap.get(annotationTypeA) ?? -1;
        const bIndex = annotationTypeOrderMap.get(annotationTypeB) ?? -1;
        comparison =
          comparison || (ascending ? bIndex - aIndex : aIndex - bIndex);
      } else if (
        severityValues.includes(severityA) &&
        severityValues.includes(severityB)
      ) {
        const aIndex = severityOrderMap.get(severityA) ?? -1;
        const bIndex = severityOrderMap.get(severityB) ?? -1;
        comparison =
          comparison || (ascending ? bIndex - aIndex : aIndex - bIndex);
      } else if (valueA instanceof Date && valueB instanceof Date) {
        comparison =
          comparison ||
          (ascending
            ? compareAsc(valueA, valueB)
            : compareDesc(valueA, valueB));
      } else if (typeof valueA === 'string' && typeof valueB === 'string') {
        comparison =
          comparison ||
          (ascending
            ? valueB.localeCompare(valueA)
            : valueA.localeCompare(valueB));
      } else if (typeof valueA === 'number' && typeof valueB === 'number') {
        if (valueA < valueB) {
          comparison = comparison || (!ascending ? -1 : 1);
        }
        if (valueA > valueB) {
          comparison = comparison || (!ascending ? 1 : -1);
        }
      }
    }
    return comparison;
  };
}

const Actions = ({
  discussion,
  onUpdateStatus,
}: {
  discussion: TDiscussion;
  onUpdateStatus: (
    discussions: TDiscussion[],
    status: TDiscussion['status']
  ) => void;
}) => {
  const {
    insights: { canCreate },
  } = usePermissions();
  const editModal = useDisclosure();
  const confirmDismissal = useDisclosure();
  const confirmRemoval = useDisclosure();
  const hasActions = canCreate;
  const menuOptions = useMemo(() => {
    const options: MenuProps['items'] = [];
    if (canCreate) {
      options.push({
        children: 'Edit',
        variant: 'flat',
        leadingIcon: <EditIcon />,
        onClick: editModal.open,
      });

      options.push({
        children: 'Delete',
        variant: 'error',
        leadingIcon: <GarbageIcon />,
        onClick: confirmRemoval.open,
      });
    }
    return options;
  }, [canCreate, confirmRemoval, editModal]);
  const handleDelete = () => {
    onUpdateStatus([discussion], 'deleted');
    confirmRemoval.close();
  };
  const handleClose = (isDirty: Maybe<boolean>) => () => {
    if (isDirty) {
      confirmDismissal.open();
    } else {
      editModal.close();
    }
  };
  const handleSaveDiscussion: DiscussionBoxContextProviderProps['onSaveDiscussion'] =
    async (promise) => {
      try {
        await promise;
        editModal.close();
      } catch (_error) {
        toast.error(
          {
            content:
              'Something went wrong while saving the insight. Please try again.',
          },
          { toastId: 'edit-insight-save' }
        );
      }
    };

  return (
    <>
      {hasActions && (
        <Menu
          items={menuOptions}
          floatProps={{ flip: true }}
          button={
            <Button variant="flat" size="icon" aria-label="More">
              <MoreVerticalIcon />
            </Button>
          }
        />
      )}

      {confirmRemoval.isOpen && (
        <Alert
          open={confirmRemoval.isOpen}
          onCancel={confirmRemoval.close}
          onConfirm={handleDelete}
        >
          Are you sure you want to remove the insight? This action cannot be
          undone.
        </Alert>
      )}

      {editModal.isOpen && (
        <Modal
          mode="normal"
          open={editModal.isOpen}
          className="sm:min-w-[576px]"
        >
          <DiscussionBoxContextProvider
            selectedDiscussion={discussion}
            defaultMode="update"
            onClose={editModal.close}
            onSaveDiscussion={handleSaveDiscussion}
          >
            {({ editState, saveDiscussion }) => (
              <>
                <Modal.Header
                  title="Edit"
                  dialogTitleProps={{
                    className:
                      'pl-0 text-xl font-bold font-body justify-start capitalize',
                  }}
                  closeButtonProps={{
                    onClick: handleClose(editState?.isDirty),
                  }}
                />

                <Modal.Content>
                  <EditInsight
                    disabledFields={{ startTime: true, endTime: true }}
                  />
                </Modal.Content>

                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={handleClose(editState?.isDirty)}
                  >
                    Cancel
                  </Button>
                  <Button
                    loading={editState?.isSaving}
                    disabled={!editState?.isDirty || !editState?.isValid}
                    onClick={saveDiscussion}
                  >
                    Save
                  </Button>
                </Modal.Footer>

                {confirmDismissal.isOpen && (
                  <Alert
                    open={confirmDismissal.isOpen}
                    onCancel={confirmDismissal.close}
                    onConfirm={() => {
                      editModal.close();
                      confirmDismissal.close();
                    }}
                  >
                    Are you sure you want to exit?
                    <br />
                    Your changes will not be saved.
                  </Alert>
                )}
              </>
            )}
          </DiscussionBoxContextProvider>
        </Modal>
      )}
    </>
  );
};

const InsightTitle: FC<ComponentProps<'p'>> = ({
  children,
  className,
  ...props
}) => (
  <p
    {...props}
    role="heading"
    className={cn(
      'font-semibold hover:underline hover:cursor-pointer',
      className
    )}
  >
    {children}
  </p>
);

const InsightSkeleton = ({ title }: { title: string }) => (
  <div
    role="article"
    aria-label={title}
    aria-hidden={true}
    className="py-6 flex flex-col gap-2 border-t-[1px] border-neutral-400"
  >
    <InsightTitle className="w-full h-10">{title}</InsightTitle>
    <div className="w-full flex flex-col gap-4">
      <div className="w-full h-7 rounded-sm bg-neutral-200" />
      <div className="w-full h-24 rounded-sm bg-neutral-200" />
      <div className="w-full h-[300px] rounded-sm bg-neutral-200" />
      <div className="w-full h-24 rounded-sm bg-neutral-200" />
      <div className="w-full h-10 rounded-sm bg-neutral-200" />
    </div>
  </div>
);

export interface InsightsFeedProps {
  zones: TZone[];
  startTime: Date;
  onChangeStartTime: (startTime: Date) => void;
}

export const InsightsFeed = ({
  zones,
  startTime,
  onChangeStartTime,
}: InsightsFeedProps) => {
  const weekRefs = useRef<(HTMLDivElement | null)[]>([]);
  const navigate = useNavigateToDiscussion();
  const { user } = useAuth();
  const {
    insights: { canCreate, canPublish },
  } = usePermissions();
  const { currentTimeInCurrentZone, zoneTimeZone } = useCurrentZone();
  const { updateStatus } = useUpdateDiscussionStatus();
  const feedEndTime = useMemo(
    () => endOfDay(currentTimeInCurrentZone),
    [currentTimeInCurrentZone]
  );
  const query = useGetInsights({
    zones,
    zoneTimeZone,
    statuses: canCreate ? ['published', 'draft'] : ['published'],
    startTime,
    endTime: feedEndTime,
    lookupWeeks: 26,
    userId: user?.id!,
  });
  const discussions = useMemo(
    () =>
      query.discussions.length > 0
        ? query.discussions
        : query.previousDiscussions,
    [query.discussions, query.previousDiscussions]
  );
  const hasMoreWeeks =
    discussions.length < Math.max(query.totalCount, query.previousTotalCount);
  const noDiscussions =
    discussions.length === 0 && query.called && !query.loading;
  const sortedDiscussions = useMemo<GroupedDiscussion[]>(() => {
    return discussions
      .map((discussion) => {
        const {
          id: zoneId,
          label: zoneName,
          locationName,
          organizationCode,
        } = zones.find((z) => z.uid === discussion.zoneUid)!;

        return {
          ...discussion,
          weekEnd: endOfWeek(discussion.endTime, { weekStartsOn: 1 }),
          zoneId,
          zoneName,
          locationName,
          organizationCode,
        };
      })
      .sort(
        insightsSort([
          { key: 'weekEnd' },
          { key: 'zoneName', ascending: false },
          { key: 'locationName', ascending: false },
          { key: 'annotationType' },
          { key: 'firstComment.content.severity' },
        ])
      );
  }, [discussions, zones]);
  const publishableInsightsByLocation = useMemo(() => {
    return groupBy(
      sortedDiscussions.filter(({ status }) => status === 'draft'),
      (d) => `${d.weekEnd.valueOf()}-${d.locationName}`
    );
  }, [sortedDiscussions]);
  const inViewRoot = usePrevious(document.getElementById('main'));
  const insightHeight = 700;
  const inViewRootMargin = `${insightHeight + 0.5 * insightHeight}px 0px ${0.5 * insightHeight}px 0px`;
  const onUpdateStatus = useCallback(
    async (discussions: TDiscussion[], status: TDiscussion['status']) => {
      const insightLabel = pluralize('insight', discussions.length);

      try {
        await updateStatus(discussions, status);

        toast.success(
          {
            content: `${discussions.length} ${insightLabel} successfully ${status}`,
          },
          { toastId: 'update-insight-status-success', autoClose: 3000 }
        );
      } catch (_e) {
        toast.error(
          {
            content: `Something went wrong while updating the ${insightLabel} status. Please try again.`,
          },
          { toastId: 'update-insight-status-error' }
        );
      }
    },
    [updateStatus]
  );
  const handleLoadMore = useCallback(
    (lookupWeeks = 1) =>
      () => {
        onChangeStartTime(
          subWeeks(startOfWeek(startTime, { weekStartsOn: 1 }), lookupWeeks)
        );
      },
    [onChangeStartTime, startTime]
  );

  useEffect(() => {
    // Only scroll into the latest week after the initial load
    if (
      !query.loading &&
      query.called &&
      query.previousDiscussions.length !== 0 &&
      query.discussions.length !== query.previousDiscussions.length
    ) {
      const weekTitle = weekRefs.current.at(-1);
      weekTitle && weekTitle.scrollIntoView({ block: 'start' });
    }
  }, [
    query.called,
    query.discussions.length,
    query.loading,
    query.previousDiscussions.length,
  ]);

  return (
    <>
      <div
        role="feed"
        className={cn(
          'h-full flex flex-col items-center',
          noDiscussions && 'justify-center'
        )}
      >
        {query.loading && discussions.length === 0 && <CenteredLoader />}

        {noDiscussions && (
          <NoDataView
            label={`No insights available between ${formatDateInMMMDD(startTime)} and ${formatDateInMMMDD(feedEndTime)}`}
            message={
              hasMoreWeeks && (
                <Button variant="secondary" onClick={handleLoadMore(26)}>
                  Load the last 6 months
                </Button>
              )
            }
          />
        )}

        <div className="w-full max-w-3xl flex flex-col">
          {sortedDiscussions.map((discussion, index) => {
            const {
              uid,
              firstComment,
              endTime,
              zoneId,
              zoneUid,
              zoneName,
              locationName,
              weekEnd,
            } = discussion;
            const insight = firstComment.content as InsightCommentContent;
            const weekStart = startOfWeek(endTime, { weekStartsOn: 1 });
            const handleNavigateToDiscussion = () => {
              navigate(discussion);
            };
            const summary = (
              <>
                <div
                  className="mr-auto flex gap-2 items-center"
                  onClick={handleNavigateToDiscussion}
                >
                  <InsightTitle className="peer">{insight.title}</InsightTitle>

                  <sup className="hidden peer-hover:block">
                    <ExternalLinkIcon className="text-neutral-500 w-3" />
                  </sup>
                </div>

                <Actions
                  discussion={discussion}
                  onUpdateStatus={onUpdateStatus}
                />
              </>
            );
            const currentWeek = getWeek(endTime, { weekStartsOn: 1 });
            const showWeek =
              index < 1 ||
              getWeek(sortedDiscussions[index - 1]!.endTime, {
                weekStartsOn: 1,
              }) !== currentWeek;
            const showLocation =
              showWeek ||
              index < 1 ||
              sortedDiscussions[index - 1]!.locationName !== locationName;
            const showZone =
              zones.length > 1 &&
              (showWeek ||
                showLocation ||
                index < 1 ||
                sortedDiscussions[index - 1]!.zoneName !== zoneName);
            const insightsToPublish =
              publishableInsightsByLocation[
                `${weekEnd.valueOf()}-${locationName}`
              ] ?? [];

            return (
              <div key={uid}>
                {showWeek && (
                  <div
                    ref={(element) => {
                      element && weekRefs.current.push(element);
                    }}
                    className="text-neutral-500 text-lg font-bold text-center p-6"
                  >
                    {formatDateInMMMd(weekStart)} - {formatDateInMMMd(weekEnd)}
                  </div>
                )}
                {showLocation && (
                  <div className="text-xl font-semibold py-4 flex gap-2 justify-between">
                    {locationName}
                    {canPublish && insightsToPublish.length > 0 && (
                      <Button
                        variant="secondary"
                        aria-label={`Publish Insights from ${locationName}`}
                        onClick={() =>
                          onUpdateStatus(insightsToPublish, 'published')
                        }
                      >
                        Publish Insights
                      </Button>
                    )}
                  </div>
                )}
                {showZone && (
                  <div className="text-lg font-semibold pb-4 flex gap-2 justify-between">
                    {zoneName}
                  </div>
                )}
                <InView root={inViewRoot} rootMargin={inViewRootMargin}>
                  {({ ref, inView }) => (
                    <div ref={ref}>
                      {!inView && <InsightSkeleton title={insight.title} />}

                      {inView && (
                        <Disclosure
                          defaultOpen
                          role="article"
                          aria-label={insight.title}
                          key={firstComment.uid}
                          summary={summary}
                        >
                          <InsightContent
                            discussion={discussion}
                            showStatus={canPublish}
                            showTime
                            preview={
                              <InsightPreview
                                zoneId={zoneId}
                                zoneUid={zoneUid}
                                zoneTimeZone={zoneTimeZone}
                                discussion={discussion}
                                onClick={handleNavigateToDiscussion}
                              />
                            }
                          />
                        </Disclosure>
                      )}
                    </div>
                  )}
                </InView>
              </div>
            );
          })}
        </div>
      </div>

      {document.getElementById('footer') &&
        hasMoreWeeks &&
        createPortal(
          <div className="flex items-start justify-center">
            <Button
              variant="secondary"
              onClick={handleLoadMore()}
              loading={query.loading && discussions.length !== 0}
            >
              Load more insights
            </Button>
          </div>,
          document.getElementById('footer')!
        )}
    </>
  );
};
