import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const LightOffIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 32 32"
    aria-hidden="true"
    fill="none"
    className={cn('h-8 w-8', className)}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="9"
      y="9"
      width="9"
      height="9"
    >
      <path d="M9 9H18L13.5 13.5L9 18V9Z" fill={fill} />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M15 20.8359C13.8458 20.8359 12.7175 20.4936 11.7578 19.8524C10.7981 19.2111 10.0501 18.2997 9.60841 17.2334C9.16671 16.167 9.05115 14.9936 9.27632 13.8616C9.5015 12.7296 10.0573 11.6897 10.8735 10.8736C11.6896 10.0574 12.7294 9.50162 13.8615 9.27644C14.9935 9.05127 16.1669 9.16684 17.2332 9.60853C18.2996 10.0502 19.211 10.7982 19.8523 11.7579C20.4935 12.7176 20.8358 13.8459 20.8358 15.0001C20.8358 16.5478 20.2209 18.0322 19.1265 19.1266C18.0321 20.221 16.5477 20.8359 15 20.8359ZM15 10.8317C14.1755 10.8317 13.3696 11.0762 12.6841 11.5342C11.9986 11.9922 11.4644 12.6432 11.1489 13.4049C10.8334 14.1666 10.7508 15.0047 10.9117 15.8133C11.0725 16.6219 11.4695 17.3647 12.0525 17.9476C12.6354 18.5306 13.3782 18.9276 14.1868 19.0884C14.9954 19.2493 15.8335 19.1667 16.5952 18.8512C17.3568 18.5357 18.0079 18.0014 18.4659 17.3159C18.9239 16.6305 19.1684 15.8245 19.1684 15.0001C19.1684 13.8946 18.7292 12.8343 17.9475 12.0526C17.1658 11.2708 16.1055 10.8317 15 10.8317Z"
        fill="black"
      />
    </g>
    <mask
      id="mask1"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="12"
      y="12"
      width="9"
      height="9"
    >
      <path d="M21 21H12L16.5 16.5L21 12V21Z" fill={fill} />
    </mask>
    <g mask="url(#mask1)">
      <path
        d="M15 9.16411C16.1542 9.16411 17.2825 9.50637 18.2422 10.1476C19.2019 10.7889 19.9499 11.7003 20.3916 12.7666C20.8333 13.833 20.9489 15.0064 20.7237 16.1384C20.4985 17.2704 19.9427 18.3103 19.1265 19.1264C18.3104 19.9426 17.2706 20.4984 16.1385 20.7236C15.0065 20.9487 13.8331 20.8332 12.7668 20.3915C11.7004 19.9498 10.789 19.2018 10.1477 18.2421C9.5065 17.2824 9.16424 16.1541 9.16424 14.9999C9.16424 13.4522 9.77908 11.9678 10.8735 10.8734C11.9679 9.77895 13.4523 9.16411 15 9.16411ZM15 19.1683C15.8245 19.1683 16.6304 18.9238 17.3159 18.4658C18.0014 18.0078 18.5356 17.3568 18.8511 16.5951C19.1666 15.8334 19.2492 14.9953 19.0883 14.1867C18.9275 13.3781 18.5305 12.6353 17.9475 12.0524C17.3646 11.4694 16.6218 11.0724 15.8132 10.9116C15.0046 10.7507 14.1665 10.8333 13.4048 11.1488C12.6432 11.4643 11.9921 11.9986 11.5341 12.6841C11.0761 13.3695 10.8316 14.1755 10.8316 14.9999C10.8316 16.1054 11.2708 17.1657 12.0525 17.9474C12.8342 18.7292 13.8945 19.1683 15 19.1683Z"
        fill="black"
      />
    </g>
    <path
      d="M15 26.5091C14.7789 26.5091 14.5668 26.4212 14.4105 26.2649C14.2542 26.1086 14.1663 25.8965 14.1663 25.6754V24.1706C14.1663 23.9495 14.2542 23.7374 14.4105 23.5811C14.5668 23.4247 14.7789 23.3369 15 23.3369C15.2211 23.3369 15.4332 23.4247 15.5895 23.5811C15.7459 23.7374 15.8337 23.9495 15.8337 24.1706V25.6629C15.8353 25.7734 15.815 25.8832 15.7739 25.9858C15.7327 26.0884 15.6716 26.1818 15.594 26.2605C15.5164 26.3392 15.4239 26.4018 15.322 26.4444C15.22 26.4871 15.1105 26.5091 15 26.5091Z"
      fill="black"
    />
    <path
      d="M15 6.6631C14.7789 6.6631 14.5668 6.57527 14.4105 6.41892C14.2542 6.26258 14.1663 6.05053 14.1663 5.82942V4.16205C14.1663 3.94095 14.2542 3.7289 14.4105 3.57255C14.5668 3.4162 14.7789 3.32837 15 3.32837C15.2211 3.32837 15.4332 3.4162 15.5895 3.57255C15.7459 3.7289 15.8337 3.94095 15.8337 4.16205V5.82942C15.8337 6.05053 15.7459 6.26258 15.5895 6.41892C15.4332 6.57527 15.2211 6.6631 15 6.6631Z"
      fill="black"
    />
    <path
      d="M25.7545 15.8336H24.1705C23.9494 15.8336 23.7374 15.7458 23.581 15.5894C23.4247 15.4331 23.3368 15.2211 23.3368 14.9999C23.3368 14.7788 23.4247 14.5668 23.581 14.4104C23.7374 14.2541 23.9494 14.1663 24.1705 14.1663H25.7545C25.9756 14.1663 26.1877 14.2541 26.344 14.4104C26.5004 14.5668 26.5882 14.7788 26.5882 14.9999C26.5882 15.2211 26.5004 15.4331 26.344 15.5894C26.1877 15.7458 25.9756 15.8336 25.7545 15.8336Z"
      fill="black"
    />
    <path
      d="M5.8295 15.8336H4.25384C4.03273 15.8336 3.82068 15.7458 3.66434 15.5894C3.50799 15.4331 3.42015 15.2211 3.42015 14.9999C3.42015 14.7788 3.50799 14.5668 3.66434 14.4104C3.82068 14.2541 4.03273 14.1663 4.25384 14.1663H5.8295C6.05061 14.1663 6.26266 14.2541 6.419 14.4104C6.57535 14.5668 6.66319 14.7788 6.66319 14.9999C6.66319 15.2211 6.57535 15.4331 6.419 15.5894C6.26266 15.7458 6.05061 15.8336 5.8295 15.8336Z"
      fill="black"
    />
    <path
      d="M8.60983 9.43089C8.38908 9.42996 8.17771 9.34151 8.02208 9.18495L6.89661 8.05948C6.74017 7.90304 6.65229 7.69088 6.65229 7.46965C6.65229 7.24842 6.74017 7.03625 6.89661 6.87981C7.05304 6.72338 7.26521 6.6355 7.48644 6.6355C7.70767 6.6355 7.91984 6.72338 8.07627 6.87981L9.20175 8.00529C9.31932 8.1219 9.3995 8.27088 9.43208 8.43324C9.46465 8.59559 9.44813 8.76397 9.38463 8.91691C9.32113 9.06984 9.21353 9.2004 9.07554 9.29194C8.93755 9.38348 8.77542 9.43185 8.60983 9.43089Z"
      fill="black"
    />
    <path
      d="M22.5448 23.4036C22.3241 23.4026 22.1127 23.3142 21.9571 23.1576L20.8733 22.0863C20.7958 22.0089 20.7344 21.9169 20.6925 21.8157C20.6505 21.7145 20.629 21.606 20.629 21.4965C20.629 21.387 20.6505 21.2785 20.6925 21.1773C20.7344 21.0761 20.7958 20.9841 20.8733 20.9067C20.9507 20.8292 21.0427 20.7678 21.1439 20.7258C21.2451 20.6839 21.3536 20.6624 21.4631 20.6624C21.5727 20.6624 21.6811 20.6839 21.7823 20.7258C21.8835 20.7678 21.9755 20.8292 22.0529 20.9067L23.1367 21.9905C23.2501 22.1077 23.3265 22.2556 23.3567 22.4158C23.3868 22.5761 23.3693 22.7416 23.3063 22.892C23.2433 23.0424 23.1376 23.171 23.0022 23.2619C22.8669 23.3529 22.7079 23.4021 22.5448 23.4036Z"
      fill="black"
    />
    <rect
      x="5.896"
      y="22.8"
      width="24"
      height="1.75"
      rx="0.875"
      transform="rotate(-45 5.896 22.8)"
      fill="black"
    />
  </svg>
);

export { LightOffIcon };
