import {
  CreateUserNotificationSettingRequest,
  NotificationTarget,
  NotificationType,
  useBulkUpdateUserNotificationSettingsMutation,
  useCreateUserNotificationSettingMutation,
  useDeleteUserNotificationSettingMutation,
  useUserNotificationSettingsQuery,
} from 'api/user-notification-settings';
import { Alert } from 'components/common/Alert/Alert';
import { Button } from 'components/common/Button/Button';
import { CircularProgress } from 'components/common/CircularProgress/CircularProgress';
import { Link } from 'components/common/Link';
import { Switch } from 'components/common/Switch/Switch';
import { Tag } from 'components/common/Tag/Tag';
import { useDisclosure } from 'hooks/useDisclosure';
import { sortBy } from 'lodash';
import groupBy from 'lodash.groupby';
import mapValues from 'lodash.mapvalues';
import omit from 'lodash.omit';
import { BellIcon, SparklesIcon } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ERoutePath, PATH_PATTERNS } from 'shared/constants/url';
import { LocationIdAndName } from 'shared/interfaces/location';
import { TZone } from 'shared/interfaces/zone';
import { cn } from 'shared/utils/cn';
import {
  UserNotificationSettingContainer,
  UserNotificationSettingOperationResult,
} from './UserNotificationSettingContainer';
import { UserNotificationSettingPanel } from './UserNotificationSettingPanel';

export interface UserNotificationSettingsProps {
  userId: number;
  zones: TZone[];
  locations: LocationIdAndName[];
  organizationCode: string;
}

export const UserNotificationSettings = (
  props: UserNotificationSettingsProps
) => {
  const { data } = useUserNotificationSettingsQuery({
    userId: props.userId,
  });

  const discloseCollapseToGlobal = useDisclosure();
  const discloseCollapseToFacility = useDisclosure();
  const [isCollapseToGlobalLoading, setIsCollapseToGlobalLoading] =
    useState(false);
  const [isCollapseToFacilityLoading, setIsCollapseToFacilityLoading] =
    useState(false);

  const [facilityCollapseLocationId, setFacilityCollapseLocationId] = useState<
    string | null
  >(null);

  const { mutateAsync: bulkUpdateSettings } =
    useBulkUpdateUserNotificationSettingsMutation({ userId: props.userId });

  const { mutateAsync: createSetting } =
    useCreateUserNotificationSettingMutation();
  const { mutateAsync: deleteSetting } =
    useDeleteUserNotificationSettingMutation({
      userId: props.userId,
    });

  const zonesByLocationId = useMemo(
    () =>
      mapValues(
        groupBy(props.zones, (z) => z.locationId),
        (zones) => sortBy(zones, (z) => z.label)
      ),
    [props.zones]
  );

  const zoneUidsByLocationId = mapValues(zonesByLocationId, (zones) =>
    zones.map((z) => z.uid)
  );

  const container = useMemo(() => {
    return new UserNotificationSettingContainer(
      props.userId.toString(),
      props.organizationCode,
      zoneUidsByLocationId,
      data
    );
  }, [data, props.organizationCode, props.userId, zoneUidsByLocationId]);

  const organizationSettings = useMemo(
    () => container.getOrganizationSettings(),
    [container]
  );

  const hasOrganizationSettings = useMemo(() => {
    return container.hasOrganizationSettings();
  }, [container]);

  const [customizeFacilities, setCustomizeFacilities] = useState(
    !hasOrganizationSettings
  );

  const isCustomizeFacilitiesOpen = useMemo(() => {
    return customizeFacilities || !hasOrganizationSettings;
  }, [customizeFacilities, hasOrganizationSettings]);

  const [
    isExpandingOrCollapsingFacilities,
    setIsExpandingOrCollapsingFacilities,
  ] = useState(false);

  const [isExpandingOrCollapsingRooms, setIsExpandingOrCollapsingRooms] =
    useState(false);

  const [customizeRooms, setCustomizeRooms] = useState<
    Record<number, boolean | undefined>
  >({});

  const updateSettings = useCallback(
    async (result: UserNotificationSettingOperationResult) => {
      if (result.added.length || result.removed.length) {
        await bulkUpdateSettings({
          addedSettings: result.added,
          removedSettings: result.removed,
        });
      }
    },
    [bulkUpdateSettings]
  );

  const onCustomizeFacilityChanged = useCallback(
    async (checked: boolean) => {
      if (checked) {
        setIsExpandingOrCollapsingFacilities(true);
        const result = container.expandToFacilities();

        await updateSettings(result);

        setCustomizeFacilities(true);
        setCustomizeRooms({});
        setIsExpandingOrCollapsingFacilities(false);
      } else {
        if (!container.canCollapseToGlobal()) {
          discloseCollapseToGlobal.open();
        } else {
          setIsExpandingOrCollapsingFacilities(true);
          const result = container.collapseToGlobal();
          await updateSettings(result);
          setCustomizeFacilities(false);
          setCustomizeRooms({});
          setIsExpandingOrCollapsingFacilities(false);
        }
      }
    },
    [container, updateSettings, discloseCollapseToGlobal]
  );

  const onConfirmCollapseToGlobal = useCallback(async () => {
    setIsExpandingOrCollapsingFacilities(true);
    setIsCollapseToGlobalLoading(true);
    const result = container.collapseToGlobal();
    await updateSettings(result);
    setCustomizeFacilities(false);
    setCustomizeRooms({});
    setIsCollapseToGlobalLoading(false);
    setIsExpandingOrCollapsingFacilities(false);
    discloseCollapseToGlobal.close();
  }, [container, updateSettings, discloseCollapseToGlobal]);

  const onConfirmCollapseToFacility = useCallback(async () => {
    if (!facilityCollapseLocationId) {
      return;
    }
    setIsExpandingOrCollapsingRooms(true);
    setIsCollapseToFacilityLoading(true);
    const result = container.collapseToFacility(facilityCollapseLocationId);
    await updateSettings(result);
    setCustomizeRooms(
      omit(
        {
          ...customizeRooms,
        },
        [facilityCollapseLocationId]
      )
    );
    setIsCollapseToFacilityLoading(false);
    setIsExpandingOrCollapsingRooms(false);
    discloseCollapseToFacility.close();
    setFacilityCollapseLocationId(null);
  }, [
    container,
    customizeRooms,
    discloseCollapseToFacility,
    facilityCollapseLocationId,
    updateSettings,
  ]);

  const onCustomizeRoomChanged = useCallback(
    async (locationId: number, checked: boolean) => {
      if (checked) {
        setFacilityCollapseLocationId(locationId.toString());
        setIsExpandingOrCollapsingRooms(true);
        const result = container.expandToRooms(locationId.toString());
        await updateSettings(result);
        setCustomizeRooms({ ...customizeRooms, [locationId]: checked });
        setIsExpandingOrCollapsingRooms(false);
        setFacilityCollapseLocationId(null);
      } else {
        if (!container.canCollapseToFacility(locationId.toString())) {
          setFacilityCollapseLocationId(locationId.toString());
          discloseCollapseToFacility.open();
        } else {
          setFacilityCollapseLocationId(locationId.toString());
          setIsExpandingOrCollapsingRooms(true);
          const result = container.collapseToFacility(locationId.toString());
          await updateSettings(result);
          setCustomizeRooms({ ...customizeRooms, [locationId]: checked });
          setIsExpandingOrCollapsingRooms(false);
          setFacilityCollapseLocationId(null);
        }
      }
    },
    [container, customizeRooms, discloseCollapseToFacility, updateSettings]
  );

  const onOrganizationSettingAdded = useCallback(
    async (
      notificationType: NotificationType,
      notificationTarget: NotificationTarget
    ) => {
      const setting: CreateUserNotificationSettingRequest = {
        locationId: null,
        notificationType,
        organizationCode: props.organizationCode,
        target: notificationTarget,
        userId: props.userId.toString(),
        zoneUid: null,
      };
      await createSetting({
        setting,
        userId: props.userId.toString(),
      });
    },
    [createSetting, props.organizationCode, props.userId]
  );

  const onLocationSettingAdded = useCallback(
    async (
      notificationType: NotificationType,
      notificationTarget: NotificationTarget,
      locationId: string
    ) => {
      const setting: CreateUserNotificationSettingRequest = {
        locationId,
        notificationType,
        organizationCode: null,
        target: notificationTarget,
        userId: props.userId.toString(),
        zoneUid: null,
      };
      await createSetting({
        setting,
        userId: props.userId.toString(),
      });
    },
    [createSetting, props.userId]
  );

  const onZoneSettingAdded = useCallback(
    async (
      notificationType: NotificationType,
      notificationTarget: NotificationTarget,
      zoneUid: string
    ) => {
      const setting: CreateUserNotificationSettingRequest = {
        locationId: null,
        notificationType,
        organizationCode: null,
        target: notificationTarget,
        userId: props.userId.toString(),
        zoneUid,
      };
      await createSetting({
        setting,
        userId: props.userId.toString(),
      });
    },
    [createSetting, props.userId]
  );

  const onSettingRemoved = useCallback(
    async (uid: string) => {
      await deleteSetting({ uid });
    },
    [deleteSetting]
  );

  return (
    <>
      {discloseCollapseToGlobal.isOpen && (
        <Alert
          open={discloseCollapseToGlobal.isOpen}
          onCancel={discloseCollapseToGlobal.close}
          onConfirm={() => onConfirmCollapseToGlobal()}
          loading={isCollapseToGlobalLoading}
          variant="error"
          confirmLabel="Reset settings"
        >
          This will reset your customized notification settings. Are you sure?
        </Alert>
      )}
      {discloseCollapseToFacility.isOpen && (
        <Alert
          open={discloseCollapseToFacility.isOpen}
          onCancel={discloseCollapseToFacility.close}
          onConfirm={() => onConfirmCollapseToFacility()}
          loading={isCollapseToFacilityLoading}
          variant="error"
          confirmLabel="Reset settings"
        >
          This will reset your customized notification settings. Are you sure?
        </Alert>
      )}
      <InfoBox></InfoBox>
      <div className="flex flex-wrap flex-row gap-6 content-stretch items-stretch">
        <div
          className={cn(
            'flex sm:w-full md:max-w-[720px] md:min-w-[720px] flex-col items-start gap-[2px]'
          )}
        >
          {!isCustomizeFacilitiesOpen && (
            <>
              <div
                className={cn(
                  'flex pl-6 pr-6 pt-6 pb-4 self-stretch flex-col bg-neutral-200 rounded-t-lg'
                )}
              >
                <UserNotificationSettingPanel
                  label="Global"
                  settings={organizationSettings}
                  onSettingAdded={onOrganizationSettingAdded}
                  onSettingRemoved={onSettingRemoved}
                ></UserNotificationSettingPanel>
              </div>
              <div
                className={cn(
                  'flex pl-6 pr-6 pt-4 pb-4 self-stretch flex-col rounded-b-lg bg-neutral-200'
                )}
              >
                <div className="flex flex-row">
                  <Switch
                    label="Customize each facility"
                    checked={customizeFacilities}
                    onChange={(v) => onCustomizeFacilityChanged(v)}
                  ></Switch>
                  {isExpandingOrCollapsingFacilities && (
                    <CircularProgress size="sm"></CircularProgress>
                  )}
                </div>
              </div>
            </>
          )}
          {isCustomizeFacilitiesOpen && (
            <>
              <div
                className={cn(
                  'flex pl-6 pr-6 pt-6 pb-4 self-stretch bg-neutral-200 rounded-t-lg flex-col gap-3'
                )}
              >
                <div className="font-semibold">Global</div>
                <div className="flex flex-row">
                  <Switch
                    label="Customize each facility"
                    checked={isCustomizeFacilitiesOpen}
                    onChange={(v) => onCustomizeFacilityChanged(v)}
                  ></Switch>
                  {isExpandingOrCollapsingFacilities && (
                    <CircularProgress size="sm"></CircularProgress>
                  )}
                </div>
              </div>
              <>
                {props.locations.map((location, idx) => {
                  const customizeRoom =
                    customizeRooms[location.id] ||
                    container.hasZoneSettingsForLocation(
                      location.id.toString()
                    );
                  const showRoundedBorderForLocation =
                    idx === props.locations.length - 1;
                  const zones = zonesByLocationId[location.id]!;
                  const locationSettings = container.getLocationSettings(
                    location.id.toString()
                  );
                  return (
                    <div
                      key={location.id}
                      className={cn(
                        'flex pl-6 pr-6 pt-4 pb-4 flex-col gap-3 self-stretch bg-neutral-200',
                        showRoundedBorderForLocation ? 'rounded-b-lg' : ''
                      )}
                    >
                      {customizeRoom ? (
                        <div className="font-semibold">{location.name}</div>
                      ) : (
                        <UserNotificationSettingPanel
                          label={location.name}
                          settings={locationSettings}
                          onSettingRemoved={onSettingRemoved}
                          onSettingAdded={(
                            notificationType,
                            notificationTarget
                          ) =>
                            onLocationSettingAdded(
                              notificationType,
                              notificationTarget,
                              location.id.toString()
                            )
                          }
                        ></UserNotificationSettingPanel>
                      )}
                      <div className="flex flex-row">
                        <Switch
                          checked={customizeRoom}
                          label="Customize each room"
                          onChange={(v) =>
                            onCustomizeRoomChanged(location.id, v)
                          }
                        ></Switch>
                        {isExpandingOrCollapsingRooms &&
                          facilityCollapseLocationId ===
                            location.id.toString() && (
                            <CircularProgress size="sm"></CircularProgress>
                          )}
                      </div>
                      {customizeRoom &&
                        zones.map((zone) => {
                          const zoneSettings = container.getZoneSettings(
                            zone.uid
                          );

                          return (
                            <div
                              key={zone.uid}
                              className={cn(
                                'flex pb-4 flex-col gap-3 self-stretch bg-neutral-200'
                              )}
                            >
                              <UserNotificationSettingPanel
                                label={zone.label}
                                settings={zoneSettings}
                                onSettingRemoved={onSettingRemoved}
                                onSettingAdded={(
                                  notificationType,
                                  notificationTarget
                                ) =>
                                  onZoneSettingAdded(
                                    notificationType,
                                    notificationTarget,
                                    zone.uid
                                  )
                                }
                              ></UserNotificationSettingPanel>
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
              </>
            </>
          )}
        </div>
        <ReviewPhoneNumberBox></ReviewPhoneNumberBox>
      </div>
    </>
  );
};

const InfoBox = () => {
  return (
    <div
      className={cn(
        'flex py-4 px-3 items-center rounded-md gap-2 bg-neutral-200 sm:max-w-[720px]'
      )}
    >
      <div className="p-3">
        <BellIcon strokeWidth="1.5"></BellIcon>
      </div>
      <div className={cn('text-base')}>
        To receive environmental alerts whenever environment parameters go into
        a critical range, you also need to set up{' '}
        <Link
          text="Critical environment settings"
          className={cn('text-base')}
          to={PATH_PATTERNS[ERoutePath.SETTINGS_CRITICAL_ENV_SETTINGS]}
        ></Link>
        .
      </div>
    </div>
  );
};

const ReviewPhoneNumberBox = () => {
  const navigate = useNavigate();
  return (
    <div
      className={cn(
        'flex flex-col p-4 items-center rounded-md gap-2 bg-neutral-200 max-w-[720px] lg:max-w-[360px] self-start'
      )}
    >
      <div className="flex flex-row gap-4">
        <span className="font-semibold">Introducing SMS notifications</span>
        <Tag variant="tip900">
          <SparklesIcon strokeWidth="1.5"></SparklesIcon>New
        </Tag>
      </div>
      <div className={cn('text-sm')}>
        To make sure you don&apos;t miss any important updates about your
        cultivation, please double-check that your phone number is correct in
        your profile.
      </div>
      <div className="flex flex-row w-full justify-between">
        {/* <Button variant="outline" className="text-base">
          Hide
        </Button> */}
        <Button
          variant="primary"
          className="text-base"
          onClick={() => {
            navigate(PATH_PATTERNS[ERoutePath.SETTINGS_EDIT_PROFILE]);
          }}
        >
          Review phone number
        </Button>
      </div>
    </div>
  );
};
