import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const GhostIcon = ({
  stroke = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
    aria-hidden="true"
    className={cn('h-4 w-4', className)}
    {...props}
  >
    <path
      d="M12 13.3335H12.0133"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 13.3335H20.0133"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0007 2.6665C13.1717 2.6665 10.4586 3.79031 8.45818 5.7907C6.45779 7.79109 5.33398 10.5042 5.33398 13.3332V29.3332L9.33398 25.3332L12.6673 28.6665L16.0007 25.3332L19.334 28.6665L22.6673 25.3332L26.6673 29.3332V13.3332C26.6673 10.5042 25.5435 7.79109 23.5431 5.7907C21.5427 3.79031 18.8296 2.6665 16.0007 2.6665Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export { GhostIcon };
