/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  _int4: { input: any; output: any; }
  _int8: { input: any; output: any; }
  bigint: { input: any; output: any; }
  float8: { input: any; output: any; }
  geography: { input: any; output: any; }
  geometry: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  name: { input: any; output: any; }
  time: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  uuid: { input: any; output: any; }
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type FloatComparisonExp = {
  _eq?: InputMaybe<Scalars['Float']['input']>;
  _gt?: InputMaybe<Scalars['Float']['input']>;
  _gte?: InputMaybe<Scalars['Float']['input']>;
  _in?: InputMaybe<Array<Scalars['Float']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Float']['input']>;
  _lte?: InputMaybe<Scalars['Float']['input']>;
  _neq?: InputMaybe<Scalars['Float']['input']>;
  _nin?: InputMaybe<Array<Scalars['Float']['input']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "_int4". All fields are combined with logical 'AND'. */
export type Int4ComparisonExp = {
  _eq?: InputMaybe<Scalars['_int4']['input']>;
  _gt?: InputMaybe<Scalars['_int4']['input']>;
  _gte?: InputMaybe<Scalars['_int4']['input']>;
  _in?: InputMaybe<Array<Scalars['_int4']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['_int4']['input']>;
  _lte?: InputMaybe<Scalars['_int4']['input']>;
  _neq?: InputMaybe<Scalars['_int4']['input']>;
  _nin?: InputMaybe<Array<Scalars['_int4']['input']>>;
};

/** Boolean expression to compare columns of type "_int8". All fields are combined with logical 'AND'. */
export type Int8ComparisonExp = {
  _eq?: InputMaybe<Scalars['_int8']['input']>;
  _gt?: InputMaybe<Scalars['_int8']['input']>;
  _gte?: InputMaybe<Scalars['_int8']['input']>;
  _in?: InputMaybe<Array<Scalars['_int8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['_int8']['input']>;
  _lte?: InputMaybe<Scalars['_int8']['input']>;
  _neq?: InputMaybe<Scalars['_int8']['input']>;
  _nin?: InputMaybe<Array<Scalars['_int8']['input']>>;
};

/** columns and relationships of "address" */
export type Address = {
  __typename?: 'address';
  city_id: Scalars['Int']['output'];
  country_code_id: Scalars['Int']['output'];
  /** An object relationship */
  enumeration: Enumeration;
  /** An object relationship */
  enumerationByCountryCodeId: Enumeration;
  /** An object relationship */
  enumerationByRegionId: Enumeration;
  id: Scalars['Int']['output'];
  /** An array relationship */
  locations: Array<Location>;
  /** An aggregate relationship */
  locations_aggregate: LocationAggregate;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  /** An array relationship */
  organizations: Array<Organization>;
  /** An aggregate relationship */
  organizations_aggregate: OrganizationAggregate;
  postal_code?: Maybe<Scalars['String']['output']>;
  region_id: Scalars['Int']['output'];
  street?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "address" */
export type AddressLocationsArgs = {
  distinct_on?: InputMaybe<Array<LocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LocationOrderBy>>;
  where?: InputMaybe<LocationBoolExp>;
};


/** columns and relationships of "address" */
export type AddressLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LocationOrderBy>>;
  where?: InputMaybe<LocationBoolExp>;
};


/** columns and relationships of "address" */
export type AddressMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "address" */
export type AddressOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};


/** columns and relationships of "address" */
export type AddressOrganizationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};

/** aggregated selection of "address" */
export type AddressAggregate = {
  __typename?: 'address_aggregate';
  aggregate?: Maybe<AddressAggregateFields>;
  nodes: Array<Address>;
};

export type AddressAggregateBoolExp = {
  count?: InputMaybe<AddressAggregateBoolExpCount>;
};

export type AddressAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AddressSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AddressBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "address" */
export type AddressAggregateFields = {
  __typename?: 'address_aggregate_fields';
  avg?: Maybe<AddressAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<AddressMaxFields>;
  min?: Maybe<AddressMinFields>;
  stddev?: Maybe<AddressStddevFields>;
  stddev_pop?: Maybe<AddressStddevPopFields>;
  stddev_samp?: Maybe<AddressStddevSampFields>;
  sum?: Maybe<AddressSumFields>;
  var_pop?: Maybe<AddressVarPopFields>;
  var_samp?: Maybe<AddressVarSampFields>;
  variance?: Maybe<AddressVarianceFields>;
};


/** aggregate fields of "address" */
export type AddressAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AddressSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "address" */
export type AddressAggregateOrderBy = {
  avg?: InputMaybe<AddressAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AddressMaxOrderBy>;
  min?: InputMaybe<AddressMinOrderBy>;
  stddev?: InputMaybe<AddressStddevOrderBy>;
  stddev_pop?: InputMaybe<AddressStddevPopOrderBy>;
  stddev_samp?: InputMaybe<AddressStddevSampOrderBy>;
  sum?: InputMaybe<AddressSumOrderBy>;
  var_pop?: InputMaybe<AddressVarPopOrderBy>;
  var_samp?: InputMaybe<AddressVarSampOrderBy>;
  variance?: InputMaybe<AddressVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AddressAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "address" */
export type AddressArrRelInsertInput = {
  data: Array<AddressInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AddressOnConflict>;
};

/** aggregate avg on columns */
export type AddressAvgFields = {
  __typename?: 'address_avg_fields';
  city_id?: Maybe<Scalars['Float']['output']>;
  country_code_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  region_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "address" */
export type AddressAvgOrderBy = {
  city_id?: InputMaybe<OrderBy>;
  country_code_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  region_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "address". All fields are combined with a logical 'AND'. */
export type AddressBoolExp = {
  _and?: InputMaybe<Array<AddressBoolExp>>;
  _not?: InputMaybe<AddressBoolExp>;
  _or?: InputMaybe<Array<AddressBoolExp>>;
  city_id?: InputMaybe<IntComparisonExp>;
  country_code_id?: InputMaybe<IntComparisonExp>;
  enumeration?: InputMaybe<EnumerationBoolExp>;
  enumerationByCountryCodeId?: InputMaybe<EnumerationBoolExp>;
  enumerationByRegionId?: InputMaybe<EnumerationBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  locations?: InputMaybe<LocationBoolExp>;
  locations_aggregate?: InputMaybe<LocationAggregateBoolExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  organizations?: InputMaybe<OrganizationBoolExp>;
  organizations_aggregate?: InputMaybe<OrganizationAggregateBoolExp>;
  postal_code?: InputMaybe<StringComparisonExp>;
  region_id?: InputMaybe<IntComparisonExp>;
  street?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "address" */
export enum AddressConstraint {
  /** unique or primary key constraint on columns "id" */
  address_pkey = 'address_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AddressDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AddressDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AddressDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "address" */
export type AddressIncInput = {
  city_id?: InputMaybe<Scalars['Int']['input']>;
  country_code_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  region_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "address" */
export type AddressInsertInput = {
  city_id?: InputMaybe<Scalars['Int']['input']>;
  country_code_id?: InputMaybe<Scalars['Int']['input']>;
  enumeration?: InputMaybe<EnumerationObjRelInsertInput>;
  enumerationByCountryCodeId?: InputMaybe<EnumerationObjRelInsertInput>;
  enumerationByRegionId?: InputMaybe<EnumerationObjRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  locations?: InputMaybe<LocationArrRelInsertInput>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  organizations?: InputMaybe<OrganizationArrRelInsertInput>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
  region_id?: InputMaybe<Scalars['Int']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AddressMaxFields = {
  __typename?: 'address_max_fields';
  city_id?: Maybe<Scalars['Int']['output']>;
  country_code_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  region_id?: Maybe<Scalars['Int']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "address" */
export type AddressMaxOrderBy = {
  city_id?: InputMaybe<OrderBy>;
  country_code_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  postal_code?: InputMaybe<OrderBy>;
  region_id?: InputMaybe<OrderBy>;
  street?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AddressMinFields = {
  __typename?: 'address_min_fields';
  city_id?: Maybe<Scalars['Int']['output']>;
  country_code_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  region_id?: Maybe<Scalars['Int']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "address" */
export type AddressMinOrderBy = {
  city_id?: InputMaybe<OrderBy>;
  country_code_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  postal_code?: InputMaybe<OrderBy>;
  region_id?: InputMaybe<OrderBy>;
  street?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "address" */
export type AddressMutationResponse = {
  __typename?: 'address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Address>;
};

/** input type for inserting object relation for remote table "address" */
export type AddressObjRelInsertInput = {
  data: AddressInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AddressOnConflict>;
};

/** on_conflict condition type for table "address" */
export type AddressOnConflict = {
  constraint: AddressConstraint;
  update_columns?: Array<AddressUpdateColumn>;
  where?: InputMaybe<AddressBoolExp>;
};

/** Ordering options when selecting data from "address". */
export type AddressOrderBy = {
  city_id?: InputMaybe<OrderBy>;
  country_code_id?: InputMaybe<OrderBy>;
  enumeration?: InputMaybe<EnumerationOrderBy>;
  enumerationByCountryCodeId?: InputMaybe<EnumerationOrderBy>;
  enumerationByRegionId?: InputMaybe<EnumerationOrderBy>;
  id?: InputMaybe<OrderBy>;
  locations_aggregate?: InputMaybe<LocationAggregateOrderBy>;
  metadata?: InputMaybe<OrderBy>;
  organizations_aggregate?: InputMaybe<OrganizationAggregateOrderBy>;
  postal_code?: InputMaybe<OrderBy>;
  region_id?: InputMaybe<OrderBy>;
  street?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: address */
export type AddressPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AddressPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "address" */
export enum AddressSelectColumn {
  /** column name */
  city_id = 'city_id',
  /** column name */
  country_code_id = 'country_code_id',
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  postal_code = 'postal_code',
  /** column name */
  region_id = 'region_id',
  /** column name */
  street = 'street'
}

/** input type for updating data in table "address" */
export type AddressSetInput = {
  city_id?: InputMaybe<Scalars['Int']['input']>;
  country_code_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
  region_id?: InputMaybe<Scalars['Int']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type AddressStddevFields = {
  __typename?: 'address_stddev_fields';
  city_id?: Maybe<Scalars['Float']['output']>;
  country_code_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  region_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "address" */
export type AddressStddevOrderBy = {
  city_id?: InputMaybe<OrderBy>;
  country_code_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  region_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type AddressStddevPopFields = {
  __typename?: 'address_stddev_pop_fields';
  city_id?: Maybe<Scalars['Float']['output']>;
  country_code_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  region_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "address" */
export type AddressStddevPopOrderBy = {
  city_id?: InputMaybe<OrderBy>;
  country_code_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  region_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type AddressStddevSampFields = {
  __typename?: 'address_stddev_samp_fields';
  city_id?: Maybe<Scalars['Float']['output']>;
  country_code_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  region_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "address" */
export type AddressStddevSampOrderBy = {
  city_id?: InputMaybe<OrderBy>;
  country_code_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  region_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "address" */
export type AddressStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AddressStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AddressStreamCursorValueInput = {
  city_id?: InputMaybe<Scalars['Int']['input']>;
  country_code_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  postal_code?: InputMaybe<Scalars['String']['input']>;
  region_id?: InputMaybe<Scalars['Int']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type AddressSumFields = {
  __typename?: 'address_sum_fields';
  city_id?: Maybe<Scalars['Int']['output']>;
  country_code_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  region_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "address" */
export type AddressSumOrderBy = {
  city_id?: InputMaybe<OrderBy>;
  country_code_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  region_id?: InputMaybe<OrderBy>;
};

/** update columns of table "address" */
export enum AddressUpdateColumn {
  /** column name */
  city_id = 'city_id',
  /** column name */
  country_code_id = 'country_code_id',
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  postal_code = 'postal_code',
  /** column name */
  region_id = 'region_id',
  /** column name */
  street = 'street'
}

export type AddressUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AddressAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AddressDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AddressDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AddressDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AddressIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AddressPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AddressSetInput>;
  /** filter the rows which have to be updated */
  where: AddressBoolExp;
};

/** aggregate var_pop on columns */
export type AddressVarPopFields = {
  __typename?: 'address_var_pop_fields';
  city_id?: Maybe<Scalars['Float']['output']>;
  country_code_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  region_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "address" */
export type AddressVarPopOrderBy = {
  city_id?: InputMaybe<OrderBy>;
  country_code_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  region_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type AddressVarSampFields = {
  __typename?: 'address_var_samp_fields';
  city_id?: Maybe<Scalars['Float']['output']>;
  country_code_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  region_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "address" */
export type AddressVarSampOrderBy = {
  city_id?: InputMaybe<OrderBy>;
  country_code_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  region_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type AddressVarianceFields = {
  __typename?: 'address_variance_fields';
  city_id?: Maybe<Scalars['Float']['output']>;
  country_code_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  region_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "address" */
export type AddressVarianceOrderBy = {
  city_id?: InputMaybe<OrderBy>;
  country_code_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  region_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "aggregate_heatmap" */
export type AggregateHeatmap = {
  __typename?: 'aggregate_heatmap';
  data?: Maybe<Scalars['jsonb']['output']>;
  /** An array relationship */
  discussions: Array<Discussion>;
  /** An aggregate relationship */
  discussions_aggregate: DiscussionAggregate;
  end_time: Scalars['timestamptz']['output'];
  is_valid: Scalars['Boolean']['output'];
  lambda_source_id?: Maybe<Scalars['Int']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  start_time: Scalars['timestamptz']['output'];
  type_uid: Scalars['String']['output'];
  uid: Scalars['uuid']['output'];
  /** An object relationship */
  zone: Zone;
  zone_uid: Scalars['String']['output'];
};


/** columns and relationships of "aggregate_heatmap" */
export type AggregateHeatmapDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "aggregate_heatmap" */
export type AggregateHeatmapDiscussionsArgs = {
  distinct_on?: InputMaybe<Array<DiscussionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DiscussionOrderBy>>;
  where?: InputMaybe<DiscussionBoolExp>;
};


/** columns and relationships of "aggregate_heatmap" */
export type AggregateHeatmapDiscussionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DiscussionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DiscussionOrderBy>>;
  where?: InputMaybe<DiscussionBoolExp>;
};


/** columns and relationships of "aggregate_heatmap" */
export type AggregateHeatmapMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "aggregate_heatmap" */
export type AggregateHeatmapAggregate = {
  __typename?: 'aggregate_heatmap_aggregate';
  aggregate?: Maybe<AggregateHeatmapAggregateFields>;
  nodes: Array<AggregateHeatmap>;
};

export type AggregateHeatmapAggregateBoolExp = {
  bool_and?: InputMaybe<AggregateHeatmapAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<AggregateHeatmapAggregateBoolExpBoolOr>;
  count?: InputMaybe<AggregateHeatmapAggregateBoolExpCount>;
};

export type AggregateHeatmapAggregateBoolExpBoolAnd = {
  arguments: AggregateHeatmapSelectColumnAggregateHeatmapAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AggregateHeatmapBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AggregateHeatmapAggregateBoolExpBoolOr = {
  arguments: AggregateHeatmapSelectColumnAggregateHeatmapAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AggregateHeatmapBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AggregateHeatmapAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AggregateHeatmapSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AggregateHeatmapBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "aggregate_heatmap" */
export type AggregateHeatmapAggregateFields = {
  __typename?: 'aggregate_heatmap_aggregate_fields';
  avg?: Maybe<AggregateHeatmapAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<AggregateHeatmapMaxFields>;
  min?: Maybe<AggregateHeatmapMinFields>;
  stddev?: Maybe<AggregateHeatmapStddevFields>;
  stddev_pop?: Maybe<AggregateHeatmapStddevPopFields>;
  stddev_samp?: Maybe<AggregateHeatmapStddevSampFields>;
  sum?: Maybe<AggregateHeatmapSumFields>;
  var_pop?: Maybe<AggregateHeatmapVarPopFields>;
  var_samp?: Maybe<AggregateHeatmapVarSampFields>;
  variance?: Maybe<AggregateHeatmapVarianceFields>;
};


/** aggregate fields of "aggregate_heatmap" */
export type AggregateHeatmapAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AggregateHeatmapSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "aggregate_heatmap" */
export type AggregateHeatmapAggregateOrderBy = {
  avg?: InputMaybe<AggregateHeatmapAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AggregateHeatmapMaxOrderBy>;
  min?: InputMaybe<AggregateHeatmapMinOrderBy>;
  stddev?: InputMaybe<AggregateHeatmapStddevOrderBy>;
  stddev_pop?: InputMaybe<AggregateHeatmapStddevPopOrderBy>;
  stddev_samp?: InputMaybe<AggregateHeatmapStddevSampOrderBy>;
  sum?: InputMaybe<AggregateHeatmapSumOrderBy>;
  var_pop?: InputMaybe<AggregateHeatmapVarPopOrderBy>;
  var_samp?: InputMaybe<AggregateHeatmapVarSampOrderBy>;
  variance?: InputMaybe<AggregateHeatmapVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AggregateHeatmapAppendInput = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "aggregate_heatmap" */
export type AggregateHeatmapArrRelInsertInput = {
  data: Array<AggregateHeatmapInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AggregateHeatmapOnConflict>;
};

/** aggregate avg on columns */
export type AggregateHeatmapAvgFields = {
  __typename?: 'aggregate_heatmap_avg_fields';
  lambda_source_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "aggregate_heatmap" */
export type AggregateHeatmapAvgOrderBy = {
  lambda_source_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "aggregate_heatmap". All fields are combined with a logical 'AND'. */
export type AggregateHeatmapBoolExp = {
  _and?: InputMaybe<Array<AggregateHeatmapBoolExp>>;
  _not?: InputMaybe<AggregateHeatmapBoolExp>;
  _or?: InputMaybe<Array<AggregateHeatmapBoolExp>>;
  data?: InputMaybe<JsonbComparisonExp>;
  discussions?: InputMaybe<DiscussionBoolExp>;
  discussions_aggregate?: InputMaybe<DiscussionAggregateBoolExp>;
  end_time?: InputMaybe<TimestamptzComparisonExp>;
  is_valid?: InputMaybe<BooleanComparisonExp>;
  lambda_source_id?: InputMaybe<IntComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  start_time?: InputMaybe<TimestamptzComparisonExp>;
  type_uid?: InputMaybe<StringComparisonExp>;
  uid?: InputMaybe<UuidComparisonExp>;
  zone?: InputMaybe<ZoneBoolExp>;
  zone_uid?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "aggregate_heatmap" */
export enum AggregateHeatmapConstraint {
  /** unique or primary key constraint on columns "uid" */
  aggregate_heatmap_pkey = 'aggregate_heatmap_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AggregateHeatmapDeleteAtPathInput = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AggregateHeatmapDeleteElemInput = {
  data?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AggregateHeatmapDeleteKeyInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "aggregate_heatmap" */
export type AggregateHeatmapIncInput = {
  lambda_source_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "aggregate_heatmap" */
export type AggregateHeatmapInsertInput = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  discussions?: InputMaybe<DiscussionArrRelInsertInput>;
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  is_valid?: InputMaybe<Scalars['Boolean']['input']>;
  lambda_source_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
  type_uid?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['uuid']['input']>;
  zone?: InputMaybe<ZoneObjRelInsertInput>;
  zone_uid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AggregateHeatmapMaxFields = {
  __typename?: 'aggregate_heatmap_max_fields';
  end_time?: Maybe<Scalars['timestamptz']['output']>;
  lambda_source_id?: Maybe<Scalars['Int']['output']>;
  start_time?: Maybe<Scalars['timestamptz']['output']>;
  type_uid?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['uuid']['output']>;
  zone_uid?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "aggregate_heatmap" */
export type AggregateHeatmapMaxOrderBy = {
  end_time?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  start_time?: InputMaybe<OrderBy>;
  type_uid?: InputMaybe<OrderBy>;
  uid?: InputMaybe<OrderBy>;
  zone_uid?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AggregateHeatmapMinFields = {
  __typename?: 'aggregate_heatmap_min_fields';
  end_time?: Maybe<Scalars['timestamptz']['output']>;
  lambda_source_id?: Maybe<Scalars['Int']['output']>;
  start_time?: Maybe<Scalars['timestamptz']['output']>;
  type_uid?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['uuid']['output']>;
  zone_uid?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "aggregate_heatmap" */
export type AggregateHeatmapMinOrderBy = {
  end_time?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  start_time?: InputMaybe<OrderBy>;
  type_uid?: InputMaybe<OrderBy>;
  uid?: InputMaybe<OrderBy>;
  zone_uid?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "aggregate_heatmap" */
export type AggregateHeatmapMutationResponse = {
  __typename?: 'aggregate_heatmap_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AggregateHeatmap>;
};

/** input type for inserting object relation for remote table "aggregate_heatmap" */
export type AggregateHeatmapObjRelInsertInput = {
  data: AggregateHeatmapInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AggregateHeatmapOnConflict>;
};

/** on_conflict condition type for table "aggregate_heatmap" */
export type AggregateHeatmapOnConflict = {
  constraint: AggregateHeatmapConstraint;
  update_columns?: Array<AggregateHeatmapUpdateColumn>;
  where?: InputMaybe<AggregateHeatmapBoolExp>;
};

/** Ordering options when selecting data from "aggregate_heatmap". */
export type AggregateHeatmapOrderBy = {
  data?: InputMaybe<OrderBy>;
  discussions_aggregate?: InputMaybe<DiscussionAggregateOrderBy>;
  end_time?: InputMaybe<OrderBy>;
  is_valid?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  start_time?: InputMaybe<OrderBy>;
  type_uid?: InputMaybe<OrderBy>;
  uid?: InputMaybe<OrderBy>;
  zone?: InputMaybe<ZoneOrderBy>;
  zone_uid?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: aggregate_heatmap */
export type AggregateHeatmapPkColumnsInput = {
  uid: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AggregateHeatmapPrependInput = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "aggregate_heatmap" */
export enum AggregateHeatmapSelectColumn {
  /** column name */
  data = 'data',
  /** column name */
  end_time = 'end_time',
  /** column name */
  is_valid = 'is_valid',
  /** column name */
  lambda_source_id = 'lambda_source_id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  start_time = 'start_time',
  /** column name */
  type_uid = 'type_uid',
  /** column name */
  uid = 'uid',
  /** column name */
  zone_uid = 'zone_uid'
}

/** select "aggregate_heatmap_aggregate_bool_exp_bool_and_arguments_columns" columns of table "aggregate_heatmap" */
export enum AggregateHeatmapSelectColumnAggregateHeatmapAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  is_valid = 'is_valid'
}

/** select "aggregate_heatmap_aggregate_bool_exp_bool_or_arguments_columns" columns of table "aggregate_heatmap" */
export enum AggregateHeatmapSelectColumnAggregateHeatmapAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  is_valid = 'is_valid'
}

/** input type for updating data in table "aggregate_heatmap" */
export type AggregateHeatmapSetInput = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  is_valid?: InputMaybe<Scalars['Boolean']['input']>;
  lambda_source_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
  type_uid?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['uuid']['input']>;
  zone_uid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type AggregateHeatmapStddevFields = {
  __typename?: 'aggregate_heatmap_stddev_fields';
  lambda_source_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "aggregate_heatmap" */
export type AggregateHeatmapStddevOrderBy = {
  lambda_source_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type AggregateHeatmapStddevPopFields = {
  __typename?: 'aggregate_heatmap_stddev_pop_fields';
  lambda_source_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "aggregate_heatmap" */
export type AggregateHeatmapStddevPopOrderBy = {
  lambda_source_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type AggregateHeatmapStddevSampFields = {
  __typename?: 'aggregate_heatmap_stddev_samp_fields';
  lambda_source_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "aggregate_heatmap" */
export type AggregateHeatmapStddevSampOrderBy = {
  lambda_source_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "aggregate_heatmap" */
export type AggregateHeatmapStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AggregateHeatmapStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AggregateHeatmapStreamCursorValueInput = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  is_valid?: InputMaybe<Scalars['Boolean']['input']>;
  lambda_source_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
  type_uid?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['uuid']['input']>;
  zone_uid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type AggregateHeatmapSumFields = {
  __typename?: 'aggregate_heatmap_sum_fields';
  lambda_source_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "aggregate_heatmap" */
export type AggregateHeatmapSumOrderBy = {
  lambda_source_id?: InputMaybe<OrderBy>;
};

/** update columns of table "aggregate_heatmap" */
export enum AggregateHeatmapUpdateColumn {
  /** column name */
  data = 'data',
  /** column name */
  end_time = 'end_time',
  /** column name */
  is_valid = 'is_valid',
  /** column name */
  lambda_source_id = 'lambda_source_id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  start_time = 'start_time',
  /** column name */
  type_uid = 'type_uid',
  /** column name */
  uid = 'uid',
  /** column name */
  zone_uid = 'zone_uid'
}

export type AggregateHeatmapUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AggregateHeatmapAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AggregateHeatmapDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AggregateHeatmapDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AggregateHeatmapDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AggregateHeatmapIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AggregateHeatmapPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AggregateHeatmapSetInput>;
  /** filter the rows which have to be updated */
  where: AggregateHeatmapBoolExp;
};

/** aggregate var_pop on columns */
export type AggregateHeatmapVarPopFields = {
  __typename?: 'aggregate_heatmap_var_pop_fields';
  lambda_source_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "aggregate_heatmap" */
export type AggregateHeatmapVarPopOrderBy = {
  lambda_source_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type AggregateHeatmapVarSampFields = {
  __typename?: 'aggregate_heatmap_var_samp_fields';
  lambda_source_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "aggregate_heatmap" */
export type AggregateHeatmapVarSampOrderBy = {
  lambda_source_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type AggregateHeatmapVarianceFields = {
  __typename?: 'aggregate_heatmap_variance_fields';
  lambda_source_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "aggregate_heatmap" */
export type AggregateHeatmapVarianceOrderBy = {
  lambda_source_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "alembic_version" */
export type AlembicVersion = {
  __typename?: 'alembic_version';
  version_num: Scalars['String']['output'];
};

/** aggregated selection of "alembic_version" */
export type AlembicVersionAggregate = {
  __typename?: 'alembic_version_aggregate';
  aggregate?: Maybe<AlembicVersionAggregateFields>;
  nodes: Array<AlembicVersion>;
};

/** aggregate fields of "alembic_version" */
export type AlembicVersionAggregateFields = {
  __typename?: 'alembic_version_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AlembicVersionMaxFields>;
  min?: Maybe<AlembicVersionMinFields>;
};


/** aggregate fields of "alembic_version" */
export type AlembicVersionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AlembicVersionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "alembic_version". All fields are combined with a logical 'AND'. */
export type AlembicVersionBoolExp = {
  _and?: InputMaybe<Array<AlembicVersionBoolExp>>;
  _not?: InputMaybe<AlembicVersionBoolExp>;
  _or?: InputMaybe<Array<AlembicVersionBoolExp>>;
  version_num?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "alembic_version" */
export enum AlembicVersionConstraint {
  /** unique or primary key constraint on columns "version_num" */
  alembic_version_pkc = 'alembic_version_pkc'
}

/** input type for inserting data into table "alembic_version" */
export type AlembicVersionInsertInput = {
  version_num?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AlembicVersionMaxFields = {
  __typename?: 'alembic_version_max_fields';
  version_num?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AlembicVersionMinFields = {
  __typename?: 'alembic_version_min_fields';
  version_num?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "alembic_version" */
export type AlembicVersionMutationResponse = {
  __typename?: 'alembic_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AlembicVersion>;
};

/** on_conflict condition type for table "alembic_version" */
export type AlembicVersionOnConflict = {
  constraint: AlembicVersionConstraint;
  update_columns?: Array<AlembicVersionUpdateColumn>;
  where?: InputMaybe<AlembicVersionBoolExp>;
};

/** Ordering options when selecting data from "alembic_version". */
export type AlembicVersionOrderBy = {
  version_num?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: alembic_version */
export type AlembicVersionPkColumnsInput = {
  version_num: Scalars['String']['input'];
};

/** select columns of table "alembic_version" */
export enum AlembicVersionSelectColumn {
  /** column name */
  version_num = 'version_num'
}

/** input type for updating data in table "alembic_version" */
export type AlembicVersionSetInput = {
  version_num?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "alembic_version" */
export type AlembicVersionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AlembicVersionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AlembicVersionStreamCursorValueInput = {
  version_num?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "alembic_version" */
export enum AlembicVersionUpdateColumn {
  /** column name */
  version_num = 'version_num'
}

export type AlembicVersionUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AlembicVersionSetInput>;
  /** filter the rows which have to be updated */
  where: AlembicVersionBoolExp;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type BigintComparisonExp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** columns and relationships of "classification" */
export type Classification = {
  __typename?: 'classification';
  /** An array relationship */
  detections: Array<Detection>;
  /** An aggregate relationship */
  detections_aggregate: DetectionAggregate;
  /** An object relationship */
  enumeration: Enumeration;
  id: Scalars['Int']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  name_id: Scalars['Int']['output'];
  /** An array relationship */
  tracks: Array<Track>;
  /** An aggregate relationship */
  tracks_aggregate: TrackAggregate;
  /** An array relationship */
  user_labels: Array<UserLabel>;
  /** An aggregate relationship */
  user_labels_aggregate: UserLabelAggregate;
};


/** columns and relationships of "classification" */
export type ClassificationDetectionsArgs = {
  distinct_on?: InputMaybe<Array<DetectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectionOrderBy>>;
  where?: InputMaybe<DetectionBoolExp>;
};


/** columns and relationships of "classification" */
export type ClassificationDetectionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DetectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectionOrderBy>>;
  where?: InputMaybe<DetectionBoolExp>;
};


/** columns and relationships of "classification" */
export type ClassificationMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "classification" */
export type ClassificationTracksArgs = {
  distinct_on?: InputMaybe<Array<TrackSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TrackOrderBy>>;
  where?: InputMaybe<TrackBoolExp>;
};


/** columns and relationships of "classification" */
export type ClassificationTracksAggregateArgs = {
  distinct_on?: InputMaybe<Array<TrackSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TrackOrderBy>>;
  where?: InputMaybe<TrackBoolExp>;
};


/** columns and relationships of "classification" */
export type ClassificationUserLabelsArgs = {
  distinct_on?: InputMaybe<Array<UserLabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserLabelOrderBy>>;
  where?: InputMaybe<UserLabelBoolExp>;
};


/** columns and relationships of "classification" */
export type ClassificationUserLabelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserLabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserLabelOrderBy>>;
  where?: InputMaybe<UserLabelBoolExp>;
};

/** aggregated selection of "classification" */
export type ClassificationAggregate = {
  __typename?: 'classification_aggregate';
  aggregate?: Maybe<ClassificationAggregateFields>;
  nodes: Array<Classification>;
};

export type ClassificationAggregateBoolExp = {
  count?: InputMaybe<ClassificationAggregateBoolExpCount>;
};

export type ClassificationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ClassificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ClassificationBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "classification" */
export type ClassificationAggregateFields = {
  __typename?: 'classification_aggregate_fields';
  avg?: Maybe<ClassificationAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ClassificationMaxFields>;
  min?: Maybe<ClassificationMinFields>;
  stddev?: Maybe<ClassificationStddevFields>;
  stddev_pop?: Maybe<ClassificationStddevPopFields>;
  stddev_samp?: Maybe<ClassificationStddevSampFields>;
  sum?: Maybe<ClassificationSumFields>;
  var_pop?: Maybe<ClassificationVarPopFields>;
  var_samp?: Maybe<ClassificationVarSampFields>;
  variance?: Maybe<ClassificationVarianceFields>;
};


/** aggregate fields of "classification" */
export type ClassificationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ClassificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "classification" */
export type ClassificationAggregateOrderBy = {
  avg?: InputMaybe<ClassificationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ClassificationMaxOrderBy>;
  min?: InputMaybe<ClassificationMinOrderBy>;
  stddev?: InputMaybe<ClassificationStddevOrderBy>;
  stddev_pop?: InputMaybe<ClassificationStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ClassificationStddevSampOrderBy>;
  sum?: InputMaybe<ClassificationSumOrderBy>;
  var_pop?: InputMaybe<ClassificationVarPopOrderBy>;
  var_samp?: InputMaybe<ClassificationVarSampOrderBy>;
  variance?: InputMaybe<ClassificationVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ClassificationAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "classification" */
export type ClassificationArrRelInsertInput = {
  data: Array<ClassificationInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ClassificationOnConflict>;
};

/** aggregate avg on columns */
export type ClassificationAvgFields = {
  __typename?: 'classification_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "classification" */
export type ClassificationAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "classification". All fields are combined with a logical 'AND'. */
export type ClassificationBoolExp = {
  _and?: InputMaybe<Array<ClassificationBoolExp>>;
  _not?: InputMaybe<ClassificationBoolExp>;
  _or?: InputMaybe<Array<ClassificationBoolExp>>;
  detections?: InputMaybe<DetectionBoolExp>;
  detections_aggregate?: InputMaybe<DetectionAggregateBoolExp>;
  enumeration?: InputMaybe<EnumerationBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  name_id?: InputMaybe<IntComparisonExp>;
  tracks?: InputMaybe<TrackBoolExp>;
  tracks_aggregate?: InputMaybe<TrackAggregateBoolExp>;
  user_labels?: InputMaybe<UserLabelBoolExp>;
  user_labels_aggregate?: InputMaybe<UserLabelAggregateBoolExp>;
};

/** unique or primary key constraints on table "classification" */
export enum ClassificationConstraint {
  /** unique or primary key constraint on columns "id" */
  classification_pkey = 'classification_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ClassificationDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ClassificationDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ClassificationDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "classification" */
export type ClassificationIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "classification" */
export type ClassificationInsertInput = {
  detections?: InputMaybe<DetectionArrRelInsertInput>;
  enumeration?: InputMaybe<EnumerationObjRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
  tracks?: InputMaybe<TrackArrRelInsertInput>;
  user_labels?: InputMaybe<UserLabelArrRelInsertInput>;
};

/** aggregate max on columns */
export type ClassificationMaxFields = {
  __typename?: 'classification_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "classification" */
export type ClassificationMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ClassificationMinFields = {
  __typename?: 'classification_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "classification" */
export type ClassificationMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "classification" */
export type ClassificationMutationResponse = {
  __typename?: 'classification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Classification>;
};

/** input type for inserting object relation for remote table "classification" */
export type ClassificationObjRelInsertInput = {
  data: ClassificationInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ClassificationOnConflict>;
};

/** on_conflict condition type for table "classification" */
export type ClassificationOnConflict = {
  constraint: ClassificationConstraint;
  update_columns?: Array<ClassificationUpdateColumn>;
  where?: InputMaybe<ClassificationBoolExp>;
};

/** Ordering options when selecting data from "classification". */
export type ClassificationOrderBy = {
  detections_aggregate?: InputMaybe<DetectionAggregateOrderBy>;
  enumeration?: InputMaybe<EnumerationOrderBy>;
  id?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  tracks_aggregate?: InputMaybe<TrackAggregateOrderBy>;
  user_labels_aggregate?: InputMaybe<UserLabelAggregateOrderBy>;
};

/** primary key columns input for table: classification */
export type ClassificationPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ClassificationPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "classification" */
export enum ClassificationSelectColumn {
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  name_id = 'name_id'
}

/** input type for updating data in table "classification" */
export type ClassificationSetInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type ClassificationStddevFields = {
  __typename?: 'classification_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "classification" */
export type ClassificationStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ClassificationStddevPopFields = {
  __typename?: 'classification_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "classification" */
export type ClassificationStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ClassificationStddevSampFields = {
  __typename?: 'classification_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "classification" */
export type ClassificationStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "classification" */
export type ClassificationStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ClassificationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ClassificationStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type ClassificationSumFields = {
  __typename?: 'classification_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "classification" */
export type ClassificationSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
};

/** update columns of table "classification" */
export enum ClassificationUpdateColumn {
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  name_id = 'name_id'
}

export type ClassificationUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ClassificationAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ClassificationDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ClassificationDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ClassificationDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ClassificationIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ClassificationPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ClassificationSetInput>;
  /** filter the rows which have to be updated */
  where: ClassificationBoolExp;
};

/** aggregate var_pop on columns */
export type ClassificationVarPopFields = {
  __typename?: 'classification_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "classification" */
export type ClassificationVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ClassificationVarSampFields = {
  __typename?: 'classification_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "classification" */
export type ClassificationVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ClassificationVarianceFields = {
  __typename?: 'classification_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "classification" */
export type ClassificationVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "comment" */
export type Comment = {
  __typename?: 'comment';
  author_id: Scalars['Int']['output'];
  author_organization_code: Scalars['String']['output'];
  content: Scalars['jsonb']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  discussion: Discussion;
  discussion_uid: Scalars['uuid']['output'];
  first_comment_in_discussion: Scalars['Boolean']['output'];
  last_updated_at: Scalars['timestamptz']['output'];
  uid: Scalars['uuid']['output'];
  /** An object relationship */
  user: User;
  /** An array relationship */
  user_comments: Array<UserComment>;
  /** An aggregate relationship */
  user_comments_aggregate: UserCommentAggregate;
};


/** columns and relationships of "comment" */
export type CommentContentArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "comment" */
export type CommentUserCommentsArgs = {
  distinct_on?: InputMaybe<Array<UserCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserCommentOrderBy>>;
  where?: InputMaybe<UserCommentBoolExp>;
};


/** columns and relationships of "comment" */
export type CommentUserCommentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserCommentOrderBy>>;
  where?: InputMaybe<UserCommentBoolExp>;
};

/** aggregated selection of "comment" */
export type CommentAggregate = {
  __typename?: 'comment_aggregate';
  aggregate?: Maybe<CommentAggregateFields>;
  nodes: Array<Comment>;
};

export type CommentAggregateBoolExp = {
  bool_and?: InputMaybe<CommentAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<CommentAggregateBoolExpBoolOr>;
  count?: InputMaybe<CommentAggregateBoolExpCount>;
};

export type CommentAggregateBoolExpBoolAnd = {
  arguments: CommentSelectColumnCommentAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CommentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CommentAggregateBoolExpBoolOr = {
  arguments: CommentSelectColumnCommentAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CommentBoolExp>;
  predicate: BooleanComparisonExp;
};

export type CommentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CommentBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "comment" */
export type CommentAggregateFields = {
  __typename?: 'comment_aggregate_fields';
  avg?: Maybe<CommentAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CommentMaxFields>;
  min?: Maybe<CommentMinFields>;
  stddev?: Maybe<CommentStddevFields>;
  stddev_pop?: Maybe<CommentStddevPopFields>;
  stddev_samp?: Maybe<CommentStddevSampFields>;
  sum?: Maybe<CommentSumFields>;
  var_pop?: Maybe<CommentVarPopFields>;
  var_samp?: Maybe<CommentVarSampFields>;
  variance?: Maybe<CommentVarianceFields>;
};


/** aggregate fields of "comment" */
export type CommentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "comment" */
export type CommentAggregateOrderBy = {
  avg?: InputMaybe<CommentAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CommentMaxOrderBy>;
  min?: InputMaybe<CommentMinOrderBy>;
  stddev?: InputMaybe<CommentStddevOrderBy>;
  stddev_pop?: InputMaybe<CommentStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CommentStddevSampOrderBy>;
  sum?: InputMaybe<CommentSumOrderBy>;
  var_pop?: InputMaybe<CommentVarPopOrderBy>;
  var_samp?: InputMaybe<CommentVarSampOrderBy>;
  variance?: InputMaybe<CommentVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CommentAppendInput = {
  content?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "comment" */
export type CommentArrRelInsertInput = {
  data: Array<CommentInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CommentOnConflict>;
};

/** aggregate avg on columns */
export type CommentAvgFields = {
  __typename?: 'comment_avg_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "comment" */
export type CommentAvgOrderBy = {
  author_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "comment". All fields are combined with a logical 'AND'. */
export type CommentBoolExp = {
  _and?: InputMaybe<Array<CommentBoolExp>>;
  _not?: InputMaybe<CommentBoolExp>;
  _or?: InputMaybe<Array<CommentBoolExp>>;
  author_id?: InputMaybe<IntComparisonExp>;
  author_organization_code?: InputMaybe<StringComparisonExp>;
  content?: InputMaybe<JsonbComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  discussion?: InputMaybe<DiscussionBoolExp>;
  discussion_uid?: InputMaybe<UuidComparisonExp>;
  first_comment_in_discussion?: InputMaybe<BooleanComparisonExp>;
  last_updated_at?: InputMaybe<TimestamptzComparisonExp>;
  uid?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  user_comments?: InputMaybe<UserCommentBoolExp>;
  user_comments_aggregate?: InputMaybe<UserCommentAggregateBoolExp>;
};

/** unique or primary key constraints on table "comment" */
export enum CommentConstraint {
  /** unique or primary key constraint on columns "uid" */
  comment_pkey = 'comment_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CommentDeleteAtPathInput = {
  content?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CommentDeleteElemInput = {
  content?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CommentDeleteKeyInput = {
  content?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "comment" */
export type CommentIncInput = {
  author_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "comment" */
export type CommentInsertInput = {
  author_id?: InputMaybe<Scalars['Int']['input']>;
  author_organization_code?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  discussion?: InputMaybe<DiscussionObjRelInsertInput>;
  discussion_uid?: InputMaybe<Scalars['uuid']['input']>;
  first_comment_in_discussion?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  uid?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  user_comments?: InputMaybe<UserCommentArrRelInsertInput>;
};

/** aggregate max on columns */
export type CommentMaxFields = {
  __typename?: 'comment_max_fields';
  author_id?: Maybe<Scalars['Int']['output']>;
  author_organization_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  discussion_uid?: Maybe<Scalars['uuid']['output']>;
  last_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  uid?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "comment" */
export type CommentMaxOrderBy = {
  author_id?: InputMaybe<OrderBy>;
  author_organization_code?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  discussion_uid?: InputMaybe<OrderBy>;
  last_updated_at?: InputMaybe<OrderBy>;
  uid?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CommentMinFields = {
  __typename?: 'comment_min_fields';
  author_id?: Maybe<Scalars['Int']['output']>;
  author_organization_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  discussion_uid?: Maybe<Scalars['uuid']['output']>;
  last_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  uid?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "comment" */
export type CommentMinOrderBy = {
  author_id?: InputMaybe<OrderBy>;
  author_organization_code?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  discussion_uid?: InputMaybe<OrderBy>;
  last_updated_at?: InputMaybe<OrderBy>;
  uid?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "comment" */
export type CommentMutationResponse = {
  __typename?: 'comment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Comment>;
};

/** input type for inserting object relation for remote table "comment" */
export type CommentObjRelInsertInput = {
  data: CommentInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CommentOnConflict>;
};

/** on_conflict condition type for table "comment" */
export type CommentOnConflict = {
  constraint: CommentConstraint;
  update_columns?: Array<CommentUpdateColumn>;
  where?: InputMaybe<CommentBoolExp>;
};

/** Ordering options when selecting data from "comment". */
export type CommentOrderBy = {
  author_id?: InputMaybe<OrderBy>;
  author_organization_code?: InputMaybe<OrderBy>;
  content?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  discussion?: InputMaybe<DiscussionOrderBy>;
  discussion_uid?: InputMaybe<OrderBy>;
  first_comment_in_discussion?: InputMaybe<OrderBy>;
  last_updated_at?: InputMaybe<OrderBy>;
  uid?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  user_comments_aggregate?: InputMaybe<UserCommentAggregateOrderBy>;
};

/** primary key columns input for table: comment */
export type CommentPkColumnsInput = {
  uid: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CommentPrependInput = {
  content?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "comment" */
export enum CommentSelectColumn {
  /** column name */
  author_id = 'author_id',
  /** column name */
  author_organization_code = 'author_organization_code',
  /** column name */
  content = 'content',
  /** column name */
  created_at = 'created_at',
  /** column name */
  discussion_uid = 'discussion_uid',
  /** column name */
  first_comment_in_discussion = 'first_comment_in_discussion',
  /** column name */
  last_updated_at = 'last_updated_at',
  /** column name */
  uid = 'uid'
}

/** select "comment_aggregate_bool_exp_bool_and_arguments_columns" columns of table "comment" */
export enum CommentSelectColumnCommentAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  first_comment_in_discussion = 'first_comment_in_discussion'
}

/** select "comment_aggregate_bool_exp_bool_or_arguments_columns" columns of table "comment" */
export enum CommentSelectColumnCommentAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  first_comment_in_discussion = 'first_comment_in_discussion'
}

/** input type for updating data in table "comment" */
export type CommentSetInput = {
  author_id?: InputMaybe<Scalars['Int']['input']>;
  author_organization_code?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  discussion_uid?: InputMaybe<Scalars['uuid']['input']>;
  first_comment_in_discussion?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  uid?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type CommentStddevFields = {
  __typename?: 'comment_stddev_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "comment" */
export type CommentStddevOrderBy = {
  author_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CommentStddevPopFields = {
  __typename?: 'comment_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "comment" */
export type CommentStddevPopOrderBy = {
  author_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CommentStddevSampFields = {
  __typename?: 'comment_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "comment" */
export type CommentStddevSampOrderBy = {
  author_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "comment" */
export type CommentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CommentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CommentStreamCursorValueInput = {
  author_id?: InputMaybe<Scalars['Int']['input']>;
  author_organization_code?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  discussion_uid?: InputMaybe<Scalars['uuid']['input']>;
  first_comment_in_discussion?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  uid?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type CommentSumFields = {
  __typename?: 'comment_sum_fields';
  author_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "comment" */
export type CommentSumOrderBy = {
  author_id?: InputMaybe<OrderBy>;
};

/** update columns of table "comment" */
export enum CommentUpdateColumn {
  /** column name */
  author_id = 'author_id',
  /** column name */
  author_organization_code = 'author_organization_code',
  /** column name */
  content = 'content',
  /** column name */
  created_at = 'created_at',
  /** column name */
  discussion_uid = 'discussion_uid',
  /** column name */
  first_comment_in_discussion = 'first_comment_in_discussion',
  /** column name */
  last_updated_at = 'last_updated_at',
  /** column name */
  uid = 'uid'
}

export type CommentUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CommentAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CommentDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CommentDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CommentDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CommentIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CommentPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CommentSetInput>;
  /** filter the rows which have to be updated */
  where: CommentBoolExp;
};

/** aggregate var_pop on columns */
export type CommentVarPopFields = {
  __typename?: 'comment_var_pop_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "comment" */
export type CommentVarPopOrderBy = {
  author_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CommentVarSampFields = {
  __typename?: 'comment_var_samp_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "comment" */
export type CommentVarSampOrderBy = {
  author_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CommentVarianceFields = {
  __typename?: 'comment_variance_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "comment" */
export type CommentVarianceOrderBy = {
  author_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "compute" */
export type Compute = {
  __typename?: 'compute';
  /** An object relationship */
  enumeration: Enumeration;
  id: Scalars['Int']['output'];
  mac_address: Scalars['bigint']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['Int']['output'];
  /** An object relationship */
  system?: Maybe<System>;
  system_id?: Maybe<Scalars['Int']['output']>;
  type_id: Scalars['Int']['output'];
};


/** columns and relationships of "compute" */
export type ComputeMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "compute" */
export type ComputeAggregate = {
  __typename?: 'compute_aggregate';
  aggregate?: Maybe<ComputeAggregateFields>;
  nodes: Array<Compute>;
};

export type ComputeAggregateBoolExp = {
  count?: InputMaybe<ComputeAggregateBoolExpCount>;
};

export type ComputeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ComputeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ComputeBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "compute" */
export type ComputeAggregateFields = {
  __typename?: 'compute_aggregate_fields';
  avg?: Maybe<ComputeAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ComputeMaxFields>;
  min?: Maybe<ComputeMinFields>;
  stddev?: Maybe<ComputeStddevFields>;
  stddev_pop?: Maybe<ComputeStddevPopFields>;
  stddev_samp?: Maybe<ComputeStddevSampFields>;
  sum?: Maybe<ComputeSumFields>;
  var_pop?: Maybe<ComputeVarPopFields>;
  var_samp?: Maybe<ComputeVarSampFields>;
  variance?: Maybe<ComputeVarianceFields>;
};


/** aggregate fields of "compute" */
export type ComputeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ComputeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "compute" */
export type ComputeAggregateOrderBy = {
  avg?: InputMaybe<ComputeAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ComputeMaxOrderBy>;
  min?: InputMaybe<ComputeMinOrderBy>;
  stddev?: InputMaybe<ComputeStddevOrderBy>;
  stddev_pop?: InputMaybe<ComputeStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ComputeStddevSampOrderBy>;
  sum?: InputMaybe<ComputeSumOrderBy>;
  var_pop?: InputMaybe<ComputeVarPopOrderBy>;
  var_samp?: InputMaybe<ComputeVarSampOrderBy>;
  variance?: InputMaybe<ComputeVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ComputeAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "compute" */
export type ComputeArrRelInsertInput = {
  data: Array<ComputeInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ComputeOnConflict>;
};

/** aggregate avg on columns */
export type ComputeAvgFields = {
  __typename?: 'compute_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mac_address?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "compute" */
export type ComputeAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  mac_address?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "compute". All fields are combined with a logical 'AND'. */
export type ComputeBoolExp = {
  _and?: InputMaybe<Array<ComputeBoolExp>>;
  _not?: InputMaybe<ComputeBoolExp>;
  _or?: InputMaybe<Array<ComputeBoolExp>>;
  enumeration?: InputMaybe<EnumerationBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  mac_address?: InputMaybe<BigintComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organization_id?: InputMaybe<IntComparisonExp>;
  system?: InputMaybe<SystemBoolExp>;
  system_id?: InputMaybe<IntComparisonExp>;
  type_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "compute" */
export enum ComputeConstraint {
  /** unique or primary key constraint on columns "id" */
  compute_pkey = 'compute_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ComputeDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ComputeDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ComputeDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "compute" */
export type ComputeIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  mac_address?: InputMaybe<Scalars['bigint']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  system_id?: InputMaybe<Scalars['Int']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "compute" */
export type ComputeInsertInput = {
  enumeration?: InputMaybe<EnumerationObjRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mac_address?: InputMaybe<Scalars['bigint']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  system?: InputMaybe<SystemObjRelInsertInput>;
  system_id?: InputMaybe<Scalars['Int']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type ComputeMaxFields = {
  __typename?: 'compute_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  mac_address?: Maybe<Scalars['bigint']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  system_id?: Maybe<Scalars['Int']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "compute" */
export type ComputeMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  mac_address?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ComputeMinFields = {
  __typename?: 'compute_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  mac_address?: Maybe<Scalars['bigint']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  system_id?: Maybe<Scalars['Int']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "compute" */
export type ComputeMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  mac_address?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "compute" */
export type ComputeMutationResponse = {
  __typename?: 'compute_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Compute>;
};

/** on_conflict condition type for table "compute" */
export type ComputeOnConflict = {
  constraint: ComputeConstraint;
  update_columns?: Array<ComputeUpdateColumn>;
  where?: InputMaybe<ComputeBoolExp>;
};

/** Ordering options when selecting data from "compute". */
export type ComputeOrderBy = {
  enumeration?: InputMaybe<EnumerationOrderBy>;
  id?: InputMaybe<OrderBy>;
  mac_address?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  system?: InputMaybe<SystemOrderBy>;
  system_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: compute */
export type ComputePkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ComputePrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "compute" */
export enum ComputeSelectColumn {
  /** column name */
  id = 'id',
  /** column name */
  mac_address = 'mac_address',
  /** column name */
  metadata = 'metadata',
  /** column name */
  organization_id = 'organization_id',
  /** column name */
  system_id = 'system_id',
  /** column name */
  type_id = 'type_id'
}

/** input type for updating data in table "compute" */
export type ComputeSetInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  mac_address?: InputMaybe<Scalars['bigint']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  system_id?: InputMaybe<Scalars['Int']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type ComputeStddevFields = {
  __typename?: 'compute_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mac_address?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "compute" */
export type ComputeStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  mac_address?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ComputeStddevPopFields = {
  __typename?: 'compute_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mac_address?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "compute" */
export type ComputeStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  mac_address?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ComputeStddevSampFields = {
  __typename?: 'compute_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mac_address?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "compute" */
export type ComputeStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  mac_address?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "compute" */
export type ComputeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ComputeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ComputeStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  mac_address?: InputMaybe<Scalars['bigint']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  system_id?: InputMaybe<Scalars['Int']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type ComputeSumFields = {
  __typename?: 'compute_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  mac_address?: Maybe<Scalars['bigint']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  system_id?: Maybe<Scalars['Int']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "compute" */
export type ComputeSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  mac_address?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** update columns of table "compute" */
export enum ComputeUpdateColumn {
  /** column name */
  id = 'id',
  /** column name */
  mac_address = 'mac_address',
  /** column name */
  metadata = 'metadata',
  /** column name */
  organization_id = 'organization_id',
  /** column name */
  system_id = 'system_id',
  /** column name */
  type_id = 'type_id'
}

export type ComputeUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ComputeAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ComputeDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ComputeDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ComputeDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ComputeIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ComputePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ComputeSetInput>;
  /** filter the rows which have to be updated */
  where: ComputeBoolExp;
};

/** aggregate var_pop on columns */
export type ComputeVarPopFields = {
  __typename?: 'compute_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mac_address?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "compute" */
export type ComputeVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  mac_address?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ComputeVarSampFields = {
  __typename?: 'compute_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mac_address?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "compute" */
export type ComputeVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  mac_address?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ComputeVarianceFields = {
  __typename?: 'compute_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  mac_address?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "compute" */
export type ComputeVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  mac_address?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "computed_measurement" */
export type ComputedMeasurement = {
  __typename?: 'computed_measurement';
  /** An object relationship */
  computed_metric_type: Enumeration;
  computed_metric_type_id: Scalars['Int']['output'];
  data: Scalars['jsonb']['output'];
  id: Scalars['bigint']['output'];
  /** An object relationship */
  lambda_version: LambdaVersion;
  lambda_version_id: Scalars['Int']['output'];
  /** An object relationship */
  measurement_run?: Maybe<MeasurementRun>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  pose?: Maybe<Pose>;
  pose_id?: Maybe<Scalars['Int']['output']>;
  time: Scalars['timestamptz']['output'];
  /** An object relationship */
  type?: Maybe<Enumeration>;
  type_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  zone: Zone;
  zone_id: Scalars['Int']['output'];
};


/** columns and relationships of "computed_measurement" */
export type ComputedMeasurementDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "computed_measurement" */
export type ComputedMeasurementMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "computed_measurement" */
export type ComputedMeasurementAggregate = {
  __typename?: 'computed_measurement_aggregate';
  aggregate?: Maybe<ComputedMeasurementAggregateFields>;
  nodes: Array<ComputedMeasurement>;
};

export type ComputedMeasurementAggregateBoolExp = {
  count?: InputMaybe<ComputedMeasurementAggregateBoolExpCount>;
};

export type ComputedMeasurementAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ComputedMeasurementSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ComputedMeasurementBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "computed_measurement" */
export type ComputedMeasurementAggregateFields = {
  __typename?: 'computed_measurement_aggregate_fields';
  avg?: Maybe<ComputedMeasurementAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ComputedMeasurementMaxFields>;
  min?: Maybe<ComputedMeasurementMinFields>;
  stddev?: Maybe<ComputedMeasurementStddevFields>;
  stddev_pop?: Maybe<ComputedMeasurementStddevPopFields>;
  stddev_samp?: Maybe<ComputedMeasurementStddevSampFields>;
  sum?: Maybe<ComputedMeasurementSumFields>;
  var_pop?: Maybe<ComputedMeasurementVarPopFields>;
  var_samp?: Maybe<ComputedMeasurementVarSampFields>;
  variance?: Maybe<ComputedMeasurementVarianceFields>;
};


/** aggregate fields of "computed_measurement" */
export type ComputedMeasurementAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ComputedMeasurementSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "computed_measurement" */
export type ComputedMeasurementAggregateOrderBy = {
  avg?: InputMaybe<ComputedMeasurementAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ComputedMeasurementMaxOrderBy>;
  min?: InputMaybe<ComputedMeasurementMinOrderBy>;
  stddev?: InputMaybe<ComputedMeasurementStddevOrderBy>;
  stddev_pop?: InputMaybe<ComputedMeasurementStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ComputedMeasurementStddevSampOrderBy>;
  sum?: InputMaybe<ComputedMeasurementSumOrderBy>;
  var_pop?: InputMaybe<ComputedMeasurementVarPopOrderBy>;
  var_samp?: InputMaybe<ComputedMeasurementVarSampOrderBy>;
  variance?: InputMaybe<ComputedMeasurementVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ComputedMeasurementAppendInput = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "computed_measurement" */
export type ComputedMeasurementArrRelInsertInput = {
  data: Array<ComputedMeasurementInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ComputedMeasurementOnConflict>;
};

/** aggregate avg on columns */
export type ComputedMeasurementAvgFields = {
  __typename?: 'computed_measurement_avg_fields';
  computed_metric_type_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lambda_version_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  pose_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "computed_measurement" */
export type ComputedMeasurementAvgOrderBy = {
  computed_metric_type_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "computed_measurement". All fields are combined with a logical 'AND'. */
export type ComputedMeasurementBoolExp = {
  _and?: InputMaybe<Array<ComputedMeasurementBoolExp>>;
  _not?: InputMaybe<ComputedMeasurementBoolExp>;
  _or?: InputMaybe<Array<ComputedMeasurementBoolExp>>;
  computed_metric_type?: InputMaybe<EnumerationBoolExp>;
  computed_metric_type_id?: InputMaybe<IntComparisonExp>;
  data?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  lambda_version?: InputMaybe<LambdaVersionBoolExp>;
  lambda_version_id?: InputMaybe<IntComparisonExp>;
  measurement_run?: InputMaybe<MeasurementRunBoolExp>;
  measurement_run_id?: InputMaybe<IntComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  pose?: InputMaybe<PoseBoolExp>;
  pose_id?: InputMaybe<IntComparisonExp>;
  time?: InputMaybe<TimestamptzComparisonExp>;
  type?: InputMaybe<EnumerationBoolExp>;
  type_id?: InputMaybe<IntComparisonExp>;
  zone?: InputMaybe<ZoneBoolExp>;
  zone_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "computed_measurement" */
export enum ComputedMeasurementConstraint {
  /** unique or primary key constraint on columns "id" */
  computed_measurement_id_un = 'computed_measurement_id_un',
  /** unique or primary key constraint on columns "time", "id" */
  computed_measurement_pkey = 'computed_measurement_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ComputedMeasurementDeleteAtPathInput = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ComputedMeasurementDeleteElemInput = {
  data?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ComputedMeasurementDeleteKeyInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "computed_measurement" */
export type ComputedMeasurementIncInput = {
  computed_metric_type_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lambda_version_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
  pose_id?: InputMaybe<Scalars['Int']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "computed_measurement" */
export type ComputedMeasurementInsertInput = {
  computed_metric_type?: InputMaybe<EnumerationObjRelInsertInput>;
  computed_metric_type_id?: InputMaybe<Scalars['Int']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lambda_version?: InputMaybe<LambdaVersionObjRelInsertInput>;
  lambda_version_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_run?: InputMaybe<MeasurementRunObjRelInsertInput>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  pose?: InputMaybe<PoseObjRelInsertInput>;
  pose_id?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
  type?: InputMaybe<EnumerationObjRelInsertInput>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
  zone?: InputMaybe<ZoneObjRelInsertInput>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type ComputedMeasurementMaxFields = {
  __typename?: 'computed_measurement_max_fields';
  computed_metric_type_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lambda_version_id?: Maybe<Scalars['Int']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  pose_id?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "computed_measurement" */
export type ComputedMeasurementMaxOrderBy = {
  computed_metric_type_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  time?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ComputedMeasurementMinFields = {
  __typename?: 'computed_measurement_min_fields';
  computed_metric_type_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lambda_version_id?: Maybe<Scalars['Int']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  pose_id?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "computed_measurement" */
export type ComputedMeasurementMinOrderBy = {
  computed_metric_type_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  time?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "computed_measurement" */
export type ComputedMeasurementMutationResponse = {
  __typename?: 'computed_measurement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ComputedMeasurement>;
};

/** on_conflict condition type for table "computed_measurement" */
export type ComputedMeasurementOnConflict = {
  constraint: ComputedMeasurementConstraint;
  update_columns?: Array<ComputedMeasurementUpdateColumn>;
  where?: InputMaybe<ComputedMeasurementBoolExp>;
};

/** Ordering options when selecting data from "computed_measurement". */
export type ComputedMeasurementOrderBy = {
  computed_metric_type?: InputMaybe<EnumerationOrderBy>;
  computed_metric_type_id?: InputMaybe<OrderBy>;
  data?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_version?: InputMaybe<LambdaVersionOrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  measurement_run?: InputMaybe<MeasurementRunOrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  pose?: InputMaybe<PoseOrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  time?: InputMaybe<OrderBy>;
  type?: InputMaybe<EnumerationOrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone?: InputMaybe<ZoneOrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: computed_measurement */
export type ComputedMeasurementPkColumnsInput = {
  id: Scalars['bigint']['input'];
  time: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ComputedMeasurementPrependInput = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "computed_measurement" */
export enum ComputedMeasurementSelectColumn {
  /** column name */
  computed_metric_type_id = 'computed_metric_type_id',
  /** column name */
  data = 'data',
  /** column name */
  id = 'id',
  /** column name */
  lambda_version_id = 'lambda_version_id',
  /** column name */
  measurement_run_id = 'measurement_run_id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  pose_id = 'pose_id',
  /** column name */
  time = 'time',
  /** column name */
  type_id = 'type_id',
  /** column name */
  zone_id = 'zone_id'
}

/** input type for updating data in table "computed_measurement" */
export type ComputedMeasurementSetInput = {
  computed_metric_type_id?: InputMaybe<Scalars['Int']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lambda_version_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  pose_id?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type ComputedMeasurementStddevFields = {
  __typename?: 'computed_measurement_stddev_fields';
  computed_metric_type_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lambda_version_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  pose_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "computed_measurement" */
export type ComputedMeasurementStddevOrderBy = {
  computed_metric_type_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ComputedMeasurementStddevPopFields = {
  __typename?: 'computed_measurement_stddev_pop_fields';
  computed_metric_type_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lambda_version_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  pose_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "computed_measurement" */
export type ComputedMeasurementStddevPopOrderBy = {
  computed_metric_type_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ComputedMeasurementStddevSampFields = {
  __typename?: 'computed_measurement_stddev_samp_fields';
  computed_metric_type_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lambda_version_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  pose_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "computed_measurement" */
export type ComputedMeasurementStddevSampOrderBy = {
  computed_metric_type_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "computed_measurement" */
export type ComputedMeasurementStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ComputedMeasurementStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ComputedMeasurementStreamCursorValueInput = {
  computed_metric_type_id?: InputMaybe<Scalars['Int']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lambda_version_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  pose_id?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type ComputedMeasurementSumFields = {
  __typename?: 'computed_measurement_sum_fields';
  computed_metric_type_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lambda_version_id?: Maybe<Scalars['Int']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  pose_id?: Maybe<Scalars['Int']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "computed_measurement" */
export type ComputedMeasurementSumOrderBy = {
  computed_metric_type_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** update columns of table "computed_measurement" */
export enum ComputedMeasurementUpdateColumn {
  /** column name */
  computed_metric_type_id = 'computed_metric_type_id',
  /** column name */
  data = 'data',
  /** column name */
  id = 'id',
  /** column name */
  lambda_version_id = 'lambda_version_id',
  /** column name */
  measurement_run_id = 'measurement_run_id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  pose_id = 'pose_id',
  /** column name */
  time = 'time',
  /** column name */
  type_id = 'type_id',
  /** column name */
  zone_id = 'zone_id'
}

export type ComputedMeasurementUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ComputedMeasurementAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ComputedMeasurementDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ComputedMeasurementDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ComputedMeasurementDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ComputedMeasurementIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ComputedMeasurementPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ComputedMeasurementSetInput>;
  /** filter the rows which have to be updated */
  where: ComputedMeasurementBoolExp;
};

/** aggregate var_pop on columns */
export type ComputedMeasurementVarPopFields = {
  __typename?: 'computed_measurement_var_pop_fields';
  computed_metric_type_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lambda_version_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  pose_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "computed_measurement" */
export type ComputedMeasurementVarPopOrderBy = {
  computed_metric_type_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ComputedMeasurementVarSampFields = {
  __typename?: 'computed_measurement_var_samp_fields';
  computed_metric_type_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lambda_version_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  pose_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "computed_measurement" */
export type ComputedMeasurementVarSampOrderBy = {
  computed_metric_type_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ComputedMeasurementVarianceFields = {
  __typename?: 'computed_measurement_variance_fields';
  computed_metric_type_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lambda_version_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  pose_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "computed_measurement" */
export type ComputedMeasurementVarianceOrderBy = {
  computed_metric_type_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "config" */
export type Config = {
  __typename?: 'config';
  config?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['Int']['output'];
  is_active: Scalars['Boolean']['output'];
  /** An array relationship */
  measurement_runs: Array<MeasurementRun>;
  /** An aggregate relationship */
  measurement_runs_aggregate: MeasurementRunAggregate;
  /** An array relationship */
  measurements: Array<Measurement>;
  /** An aggregate relationship */
  measurements_aggregate: MeasurementAggregate;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  system: System;
  system_id: Scalars['Int']['output'];
  /** An object relationship */
  version: Version;
  version_id: Scalars['Int']['output'];
};


/** columns and relationships of "config" */
export type ConfigConfigArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "config" */
export type ConfigMeasurementRunsArgs = {
  distinct_on?: InputMaybe<Array<MeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementRunOrderBy>>;
  where?: InputMaybe<MeasurementRunBoolExp>;
};


/** columns and relationships of "config" */
export type ConfigMeasurementRunsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementRunOrderBy>>;
  where?: InputMaybe<MeasurementRunBoolExp>;
};


/** columns and relationships of "config" */
export type ConfigMeasurementsArgs = {
  distinct_on?: InputMaybe<Array<MeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementOrderBy>>;
  where?: InputMaybe<MeasurementBoolExp>;
};


/** columns and relationships of "config" */
export type ConfigMeasurementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementOrderBy>>;
  where?: InputMaybe<MeasurementBoolExp>;
};


/** columns and relationships of "config" */
export type ConfigMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "config" */
export type ConfigAggregate = {
  __typename?: 'config_aggregate';
  aggregate?: Maybe<ConfigAggregateFields>;
  nodes: Array<Config>;
};

export type ConfigAggregateBoolExp = {
  bool_and?: InputMaybe<ConfigAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<ConfigAggregateBoolExpBoolOr>;
  count?: InputMaybe<ConfigAggregateBoolExpCount>;
};

export type ConfigAggregateBoolExpBoolAnd = {
  arguments: ConfigSelectColumnConfigAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ConfigBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ConfigAggregateBoolExpBoolOr = {
  arguments: ConfigSelectColumnConfigAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ConfigBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ConfigAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ConfigSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ConfigBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "config" */
export type ConfigAggregateFields = {
  __typename?: 'config_aggregate_fields';
  avg?: Maybe<ConfigAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ConfigMaxFields>;
  min?: Maybe<ConfigMinFields>;
  stddev?: Maybe<ConfigStddevFields>;
  stddev_pop?: Maybe<ConfigStddevPopFields>;
  stddev_samp?: Maybe<ConfigStddevSampFields>;
  sum?: Maybe<ConfigSumFields>;
  var_pop?: Maybe<ConfigVarPopFields>;
  var_samp?: Maybe<ConfigVarSampFields>;
  variance?: Maybe<ConfigVarianceFields>;
};


/** aggregate fields of "config" */
export type ConfigAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ConfigSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "config" */
export type ConfigAggregateOrderBy = {
  avg?: InputMaybe<ConfigAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ConfigMaxOrderBy>;
  min?: InputMaybe<ConfigMinOrderBy>;
  stddev?: InputMaybe<ConfigStddevOrderBy>;
  stddev_pop?: InputMaybe<ConfigStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ConfigStddevSampOrderBy>;
  sum?: InputMaybe<ConfigSumOrderBy>;
  var_pop?: InputMaybe<ConfigVarPopOrderBy>;
  var_samp?: InputMaybe<ConfigVarSampOrderBy>;
  variance?: InputMaybe<ConfigVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ConfigAppendInput = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "config" */
export type ConfigArrRelInsertInput = {
  data: Array<ConfigInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ConfigOnConflict>;
};

/** aggregate avg on columns */
export type ConfigAvgFields = {
  __typename?: 'config_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "config" */
export type ConfigAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "config". All fields are combined with a logical 'AND'. */
export type ConfigBoolExp = {
  _and?: InputMaybe<Array<ConfigBoolExp>>;
  _not?: InputMaybe<ConfigBoolExp>;
  _or?: InputMaybe<Array<ConfigBoolExp>>;
  config?: InputMaybe<JsonbComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  is_active?: InputMaybe<BooleanComparisonExp>;
  measurement_runs?: InputMaybe<MeasurementRunBoolExp>;
  measurement_runs_aggregate?: InputMaybe<MeasurementRunAggregateBoolExp>;
  measurements?: InputMaybe<MeasurementBoolExp>;
  measurements_aggregate?: InputMaybe<MeasurementAggregateBoolExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  system?: InputMaybe<SystemBoolExp>;
  system_id?: InputMaybe<IntComparisonExp>;
  version?: InputMaybe<VersionBoolExp>;
  version_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "config" */
export enum ConfigConstraint {
  /** unique or primary key constraint on columns "id" */
  config_pkey = 'config_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ConfigDeleteAtPathInput = {
  config?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ConfigDeleteElemInput = {
  config?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ConfigDeleteKeyInput = {
  config?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "config" */
export type ConfigIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  system_id?: InputMaybe<Scalars['Int']['input']>;
  version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "config" */
export type ConfigInsertInput = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  measurement_runs?: InputMaybe<MeasurementRunArrRelInsertInput>;
  measurements?: InputMaybe<MeasurementArrRelInsertInput>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  system?: InputMaybe<SystemObjRelInsertInput>;
  system_id?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<VersionObjRelInsertInput>;
  version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type ConfigMaxFields = {
  __typename?: 'config_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  system_id?: Maybe<Scalars['Int']['output']>;
  version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "config" */
export type ConfigMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ConfigMinFields = {
  __typename?: 'config_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  system_id?: Maybe<Scalars['Int']['output']>;
  version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "config" */
export type ConfigMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "config" */
export type ConfigMutationResponse = {
  __typename?: 'config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Config>;
};

/** input type for inserting object relation for remote table "config" */
export type ConfigObjRelInsertInput = {
  data: ConfigInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ConfigOnConflict>;
};

/** on_conflict condition type for table "config" */
export type ConfigOnConflict = {
  constraint: ConfigConstraint;
  update_columns?: Array<ConfigUpdateColumn>;
  where?: InputMaybe<ConfigBoolExp>;
};

/** Ordering options when selecting data from "config". */
export type ConfigOrderBy = {
  config?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_active?: InputMaybe<OrderBy>;
  measurement_runs_aggregate?: InputMaybe<MeasurementRunAggregateOrderBy>;
  measurements_aggregate?: InputMaybe<MeasurementAggregateOrderBy>;
  metadata?: InputMaybe<OrderBy>;
  system?: InputMaybe<SystemOrderBy>;
  system_id?: InputMaybe<OrderBy>;
  version?: InputMaybe<VersionOrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: config */
export type ConfigPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ConfigPrependInput = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "config" */
export enum ConfigSelectColumn {
  /** column name */
  config = 'config',
  /** column name */
  id = 'id',
  /** column name */
  is_active = 'is_active',
  /** column name */
  metadata = 'metadata',
  /** column name */
  system_id = 'system_id',
  /** column name */
  version_id = 'version_id'
}

/** select "config_aggregate_bool_exp_bool_and_arguments_columns" columns of table "config" */
export enum ConfigSelectColumnConfigAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  is_active = 'is_active'
}

/** select "config_aggregate_bool_exp_bool_or_arguments_columns" columns of table "config" */
export enum ConfigSelectColumnConfigAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  is_active = 'is_active'
}

/** input type for updating data in table "config" */
export type ConfigSetInput = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  system_id?: InputMaybe<Scalars['Int']['input']>;
  version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type ConfigStddevFields = {
  __typename?: 'config_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "config" */
export type ConfigStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ConfigStddevPopFields = {
  __typename?: 'config_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "config" */
export type ConfigStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ConfigStddevSampFields = {
  __typename?: 'config_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "config" */
export type ConfigStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "config" */
export type ConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ConfigStreamCursorValueInput = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  system_id?: InputMaybe<Scalars['Int']['input']>;
  version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type ConfigSumFields = {
  __typename?: 'config_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  system_id?: Maybe<Scalars['Int']['output']>;
  version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "config" */
export type ConfigSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** update columns of table "config" */
export enum ConfigUpdateColumn {
  /** column name */
  config = 'config',
  /** column name */
  id = 'id',
  /** column name */
  is_active = 'is_active',
  /** column name */
  metadata = 'metadata',
  /** column name */
  system_id = 'system_id',
  /** column name */
  version_id = 'version_id'
}

export type ConfigUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ConfigAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ConfigDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ConfigDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ConfigDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ConfigIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ConfigPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ConfigSetInput>;
  /** filter the rows which have to be updated */
  where: ConfigBoolExp;
};

/** aggregate var_pop on columns */
export type ConfigVarPopFields = {
  __typename?: 'config_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "config" */
export type ConfigVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ConfigVarSampFields = {
  __typename?: 'config_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "config" */
export type ConfigVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ConfigVarianceFields = {
  __typename?: 'config_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "config" */
export type ConfigVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  ASC = 'ASC',
  /** descending ordering of the cursor */
  DESC = 'DESC'
}

/** columns and relationships of "detection" */
export type Detection = {
  __typename?: 'detection';
  /** An object relationship */
  classification: Classification;
  classification_confidence?: Maybe<Scalars['Float']['output']>;
  classification_distribution?: Maybe<Scalars['jsonb']['output']>;
  classification_id: Scalars['Int']['output'];
  crop_resource_path: Scalars['String']['output'];
  detection_confidence?: Maybe<Scalars['Float']['output']>;
  /** An object relationship */
  detection_run: DetectionRun;
  detection_run_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** An array relationship */
  label_tasks: Array<LabelTask>;
  /** An aggregate relationship */
  label_tasks_aggregate: LabelTaskAggregate;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  region_of_interest: Scalars['geometry']['output'];
  /** An array relationship */
  track_detections: Array<TrackDetections>;
  /** An aggregate relationship */
  track_detections_aggregate: TrackDetectionsAggregate;
  /** An array relationship */
  user_labels: Array<UserLabel>;
  /** An aggregate relationship */
  user_labels_aggregate: UserLabelAggregate;
};


/** columns and relationships of "detection" */
export type DetectionClassificationDistributionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "detection" */
export type DetectionLabelTasksArgs = {
  distinct_on?: InputMaybe<Array<LabelTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelTaskOrderBy>>;
  where?: InputMaybe<LabelTaskBoolExp>;
};


/** columns and relationships of "detection" */
export type DetectionLabelTasksAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelTaskOrderBy>>;
  where?: InputMaybe<LabelTaskBoolExp>;
};


/** columns and relationships of "detection" */
export type DetectionMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "detection" */
export type DetectionTrackDetectionsArgs = {
  distinct_on?: InputMaybe<Array<TrackDetectionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TrackDetectionsOrderBy>>;
  where?: InputMaybe<TrackDetectionsBoolExp>;
};


/** columns and relationships of "detection" */
export type DetectionTrackDetectionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TrackDetectionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TrackDetectionsOrderBy>>;
  where?: InputMaybe<TrackDetectionsBoolExp>;
};


/** columns and relationships of "detection" */
export type DetectionUserLabelsArgs = {
  distinct_on?: InputMaybe<Array<UserLabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserLabelOrderBy>>;
  where?: InputMaybe<UserLabelBoolExp>;
};


/** columns and relationships of "detection" */
export type DetectionUserLabelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserLabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserLabelOrderBy>>;
  where?: InputMaybe<UserLabelBoolExp>;
};

/** aggregated selection of "detection" */
export type DetectionAggregate = {
  __typename?: 'detection_aggregate';
  aggregate?: Maybe<DetectionAggregateFields>;
  nodes: Array<Detection>;
};

export type DetectionAggregateBoolExp = {
  count?: InputMaybe<DetectionAggregateBoolExpCount>;
};

export type DetectionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DetectionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<DetectionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "detection" */
export type DetectionAggregateFields = {
  __typename?: 'detection_aggregate_fields';
  avg?: Maybe<DetectionAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<DetectionMaxFields>;
  min?: Maybe<DetectionMinFields>;
  stddev?: Maybe<DetectionStddevFields>;
  stddev_pop?: Maybe<DetectionStddevPopFields>;
  stddev_samp?: Maybe<DetectionStddevSampFields>;
  sum?: Maybe<DetectionSumFields>;
  var_pop?: Maybe<DetectionVarPopFields>;
  var_samp?: Maybe<DetectionVarSampFields>;
  variance?: Maybe<DetectionVarianceFields>;
};


/** aggregate fields of "detection" */
export type DetectionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DetectionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "detection" */
export type DetectionAggregateOrderBy = {
  avg?: InputMaybe<DetectionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DetectionMaxOrderBy>;
  min?: InputMaybe<DetectionMinOrderBy>;
  stddev?: InputMaybe<DetectionStddevOrderBy>;
  stddev_pop?: InputMaybe<DetectionStddevPopOrderBy>;
  stddev_samp?: InputMaybe<DetectionStddevSampOrderBy>;
  sum?: InputMaybe<DetectionSumOrderBy>;
  var_pop?: InputMaybe<DetectionVarPopOrderBy>;
  var_samp?: InputMaybe<DetectionVarSampOrderBy>;
  variance?: InputMaybe<DetectionVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DetectionAppendInput = {
  classification_distribution?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "detection" */
export type DetectionArrRelInsertInput = {
  data: Array<DetectionInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<DetectionOnConflict>;
};

/** aggregate avg on columns */
export type DetectionAvgFields = {
  __typename?: 'detection_avg_fields';
  classification_confidence?: Maybe<Scalars['Float']['output']>;
  classification_id?: Maybe<Scalars['Float']['output']>;
  detection_confidence?: Maybe<Scalars['Float']['output']>;
  detection_run_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "detection" */
export type DetectionAvgOrderBy = {
  classification_confidence?: InputMaybe<OrderBy>;
  classification_id?: InputMaybe<OrderBy>;
  detection_confidence?: InputMaybe<OrderBy>;
  detection_run_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "detection". All fields are combined with a logical 'AND'. */
export type DetectionBoolExp = {
  _and?: InputMaybe<Array<DetectionBoolExp>>;
  _not?: InputMaybe<DetectionBoolExp>;
  _or?: InputMaybe<Array<DetectionBoolExp>>;
  classification?: InputMaybe<ClassificationBoolExp>;
  classification_confidence?: InputMaybe<FloatComparisonExp>;
  classification_distribution?: InputMaybe<JsonbComparisonExp>;
  classification_id?: InputMaybe<IntComparisonExp>;
  crop_resource_path?: InputMaybe<StringComparisonExp>;
  detection_confidence?: InputMaybe<FloatComparisonExp>;
  detection_run?: InputMaybe<DetectionRunBoolExp>;
  detection_run_id?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  label_tasks?: InputMaybe<LabelTaskBoolExp>;
  label_tasks_aggregate?: InputMaybe<LabelTaskAggregateBoolExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  region_of_interest?: InputMaybe<GeometryComparisonExp>;
  track_detections?: InputMaybe<TrackDetectionsBoolExp>;
  track_detections_aggregate?: InputMaybe<TrackDetectionsAggregateBoolExp>;
  user_labels?: InputMaybe<UserLabelBoolExp>;
  user_labels_aggregate?: InputMaybe<UserLabelAggregateBoolExp>;
};

/** unique or primary key constraints on table "detection" */
export enum DetectionConstraint {
  /** unique or primary key constraint on columns "id" */
  detection_pkey = 'detection_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DetectionDeleteAtPathInput = {
  classification_distribution?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DetectionDeleteElemInput = {
  classification_distribution?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DetectionDeleteKeyInput = {
  classification_distribution?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "detection" */
export type DetectionIncInput = {
  classification_confidence?: InputMaybe<Scalars['Float']['input']>;
  classification_id?: InputMaybe<Scalars['Int']['input']>;
  detection_confidence?: InputMaybe<Scalars['Float']['input']>;
  detection_run_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "detection" */
export type DetectionInsertInput = {
  classification?: InputMaybe<ClassificationObjRelInsertInput>;
  classification_confidence?: InputMaybe<Scalars['Float']['input']>;
  classification_distribution?: InputMaybe<Scalars['jsonb']['input']>;
  classification_id?: InputMaybe<Scalars['Int']['input']>;
  crop_resource_path?: InputMaybe<Scalars['String']['input']>;
  detection_confidence?: InputMaybe<Scalars['Float']['input']>;
  detection_run?: InputMaybe<DetectionRunObjRelInsertInput>;
  detection_run_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label_tasks?: InputMaybe<LabelTaskArrRelInsertInput>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  region_of_interest?: InputMaybe<Scalars['geometry']['input']>;
  track_detections?: InputMaybe<TrackDetectionsArrRelInsertInput>;
  user_labels?: InputMaybe<UserLabelArrRelInsertInput>;
};

/** aggregate max on columns */
export type DetectionMaxFields = {
  __typename?: 'detection_max_fields';
  classification_confidence?: Maybe<Scalars['Float']['output']>;
  classification_id?: Maybe<Scalars['Int']['output']>;
  crop_resource_path?: Maybe<Scalars['String']['output']>;
  detection_confidence?: Maybe<Scalars['Float']['output']>;
  detection_run_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "detection" */
export type DetectionMaxOrderBy = {
  classification_confidence?: InputMaybe<OrderBy>;
  classification_id?: InputMaybe<OrderBy>;
  crop_resource_path?: InputMaybe<OrderBy>;
  detection_confidence?: InputMaybe<OrderBy>;
  detection_run_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DetectionMinFields = {
  __typename?: 'detection_min_fields';
  classification_confidence?: Maybe<Scalars['Float']['output']>;
  classification_id?: Maybe<Scalars['Int']['output']>;
  crop_resource_path?: Maybe<Scalars['String']['output']>;
  detection_confidence?: Maybe<Scalars['Float']['output']>;
  detection_run_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "detection" */
export type DetectionMinOrderBy = {
  classification_confidence?: InputMaybe<OrderBy>;
  classification_id?: InputMaybe<OrderBy>;
  crop_resource_path?: InputMaybe<OrderBy>;
  detection_confidence?: InputMaybe<OrderBy>;
  detection_run_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "detection" */
export type DetectionMutationResponse = {
  __typename?: 'detection_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Detection>;
};

/** input type for inserting object relation for remote table "detection" */
export type DetectionObjRelInsertInput = {
  data: DetectionInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<DetectionOnConflict>;
};

/** on_conflict condition type for table "detection" */
export type DetectionOnConflict = {
  constraint: DetectionConstraint;
  update_columns?: Array<DetectionUpdateColumn>;
  where?: InputMaybe<DetectionBoolExp>;
};

/** Ordering options when selecting data from "detection". */
export type DetectionOrderBy = {
  classification?: InputMaybe<ClassificationOrderBy>;
  classification_confidence?: InputMaybe<OrderBy>;
  classification_distribution?: InputMaybe<OrderBy>;
  classification_id?: InputMaybe<OrderBy>;
  crop_resource_path?: InputMaybe<OrderBy>;
  detection_confidence?: InputMaybe<OrderBy>;
  detection_run?: InputMaybe<DetectionRunOrderBy>;
  detection_run_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label_tasks_aggregate?: InputMaybe<LabelTaskAggregateOrderBy>;
  metadata?: InputMaybe<OrderBy>;
  region_of_interest?: InputMaybe<OrderBy>;
  track_detections_aggregate?: InputMaybe<TrackDetectionsAggregateOrderBy>;
  user_labels_aggregate?: InputMaybe<UserLabelAggregateOrderBy>;
};

/** primary key columns input for table: detection */
export type DetectionPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DetectionPrependInput = {
  classification_distribution?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "detection_run" */
export type DetectionRun = {
  __typename?: 'detection_run';
  create_timestamp: Scalars['timestamptz']['output'];
  /** An array relationship */
  detections: Array<Detection>;
  /** An aggregate relationship */
  detections_aggregate: DetectionAggregate;
  /** An object relationship */
  detector?: Maybe<Detector>;
  detector_id?: Maybe<Scalars['Int']['output']>;
  duration_milliseconds?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  enumeration: Enumeration;
  id: Scalars['Int']['output'];
  /** An object relationship */
  measurement?: Maybe<Measurement>;
  measurement_id: Scalars['bigint']['output'];
  measurement_time: Scalars['timestamptz']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  start_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  state_id: Scalars['Int']['output'];
};


/** columns and relationships of "detection_run" */
export type DetectionRunDetectionsArgs = {
  distinct_on?: InputMaybe<Array<DetectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectionOrderBy>>;
  where?: InputMaybe<DetectionBoolExp>;
};


/** columns and relationships of "detection_run" */
export type DetectionRunDetectionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DetectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectionOrderBy>>;
  where?: InputMaybe<DetectionBoolExp>;
};


/** columns and relationships of "detection_run" */
export type DetectionRunMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "detection_run" */
export type DetectionRunAggregate = {
  __typename?: 'detection_run_aggregate';
  aggregate?: Maybe<DetectionRunAggregateFields>;
  nodes: Array<DetectionRun>;
};

export type DetectionRunAggregateBoolExp = {
  count?: InputMaybe<DetectionRunAggregateBoolExpCount>;
};

export type DetectionRunAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DetectionRunSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<DetectionRunBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "detection_run" */
export type DetectionRunAggregateFields = {
  __typename?: 'detection_run_aggregate_fields';
  avg?: Maybe<DetectionRunAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<DetectionRunMaxFields>;
  min?: Maybe<DetectionRunMinFields>;
  stddev?: Maybe<DetectionRunStddevFields>;
  stddev_pop?: Maybe<DetectionRunStddevPopFields>;
  stddev_samp?: Maybe<DetectionRunStddevSampFields>;
  sum?: Maybe<DetectionRunSumFields>;
  var_pop?: Maybe<DetectionRunVarPopFields>;
  var_samp?: Maybe<DetectionRunVarSampFields>;
  variance?: Maybe<DetectionRunVarianceFields>;
};


/** aggregate fields of "detection_run" */
export type DetectionRunAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DetectionRunSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "detection_run" */
export type DetectionRunAggregateOrderBy = {
  avg?: InputMaybe<DetectionRunAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DetectionRunMaxOrderBy>;
  min?: InputMaybe<DetectionRunMinOrderBy>;
  stddev?: InputMaybe<DetectionRunStddevOrderBy>;
  stddev_pop?: InputMaybe<DetectionRunStddevPopOrderBy>;
  stddev_samp?: InputMaybe<DetectionRunStddevSampOrderBy>;
  sum?: InputMaybe<DetectionRunSumOrderBy>;
  var_pop?: InputMaybe<DetectionRunVarPopOrderBy>;
  var_samp?: InputMaybe<DetectionRunVarSampOrderBy>;
  variance?: InputMaybe<DetectionRunVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DetectionRunAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "detection_run" */
export type DetectionRunArrRelInsertInput = {
  data: Array<DetectionRunInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<DetectionRunOnConflict>;
};

/** aggregate avg on columns */
export type DetectionRunAvgFields = {
  __typename?: 'detection_run_avg_fields';
  detector_id?: Maybe<Scalars['Float']['output']>;
  duration_milliseconds?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  state_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "detection_run" */
export type DetectionRunAvgOrderBy = {
  detector_id?: InputMaybe<OrderBy>;
  duration_milliseconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  state_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "detection_run". All fields are combined with a logical 'AND'. */
export type DetectionRunBoolExp = {
  _and?: InputMaybe<Array<DetectionRunBoolExp>>;
  _not?: InputMaybe<DetectionRunBoolExp>;
  _or?: InputMaybe<Array<DetectionRunBoolExp>>;
  create_timestamp?: InputMaybe<TimestamptzComparisonExp>;
  detections?: InputMaybe<DetectionBoolExp>;
  detections_aggregate?: InputMaybe<DetectionAggregateBoolExp>;
  detector?: InputMaybe<DetectorBoolExp>;
  detector_id?: InputMaybe<IntComparisonExp>;
  duration_milliseconds?: InputMaybe<BigintComparisonExp>;
  enumeration?: InputMaybe<EnumerationBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  measurement?: InputMaybe<MeasurementBoolExp>;
  measurement_id?: InputMaybe<BigintComparisonExp>;
  measurement_time?: InputMaybe<TimestamptzComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  start_timestamp?: InputMaybe<TimestamptzComparisonExp>;
  state_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "detection_run" */
export enum DetectionRunConstraint {
  /** unique or primary key constraint on columns "id" */
  detection_run_pkey = 'detection_run_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DetectionRunDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DetectionRunDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DetectionRunDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "detection_run" */
export type DetectionRunIncInput = {
  detector_id?: InputMaybe<Scalars['Int']['input']>;
  duration_milliseconds?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  measurement_id?: InputMaybe<Scalars['bigint']['input']>;
  state_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "detection_run" */
export type DetectionRunInsertInput = {
  create_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  detections?: InputMaybe<DetectionArrRelInsertInput>;
  detector?: InputMaybe<DetectorObjRelInsertInput>;
  detector_id?: InputMaybe<Scalars['Int']['input']>;
  duration_milliseconds?: InputMaybe<Scalars['bigint']['input']>;
  enumeration?: InputMaybe<EnumerationObjRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  measurement?: InputMaybe<MeasurementObjRelInsertInput>;
  measurement_id?: InputMaybe<Scalars['bigint']['input']>;
  measurement_time?: InputMaybe<Scalars['timestamptz']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  start_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  state_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type DetectionRunMaxFields = {
  __typename?: 'detection_run_max_fields';
  create_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  detector_id?: Maybe<Scalars['Int']['output']>;
  duration_milliseconds?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  measurement_time?: Maybe<Scalars['timestamptz']['output']>;
  start_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  state_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "detection_run" */
export type DetectionRunMaxOrderBy = {
  create_timestamp?: InputMaybe<OrderBy>;
  detector_id?: InputMaybe<OrderBy>;
  duration_milliseconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  measurement_time?: InputMaybe<OrderBy>;
  start_timestamp?: InputMaybe<OrderBy>;
  state_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DetectionRunMinFields = {
  __typename?: 'detection_run_min_fields';
  create_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  detector_id?: Maybe<Scalars['Int']['output']>;
  duration_milliseconds?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  measurement_time?: Maybe<Scalars['timestamptz']['output']>;
  start_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  state_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "detection_run" */
export type DetectionRunMinOrderBy = {
  create_timestamp?: InputMaybe<OrderBy>;
  detector_id?: InputMaybe<OrderBy>;
  duration_milliseconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  measurement_time?: InputMaybe<OrderBy>;
  start_timestamp?: InputMaybe<OrderBy>;
  state_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "detection_run" */
export type DetectionRunMutationResponse = {
  __typename?: 'detection_run_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DetectionRun>;
};

/** input type for inserting object relation for remote table "detection_run" */
export type DetectionRunObjRelInsertInput = {
  data: DetectionRunInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<DetectionRunOnConflict>;
};

/** on_conflict condition type for table "detection_run" */
export type DetectionRunOnConflict = {
  constraint: DetectionRunConstraint;
  update_columns?: Array<DetectionRunUpdateColumn>;
  where?: InputMaybe<DetectionRunBoolExp>;
};

/** Ordering options when selecting data from "detection_run". */
export type DetectionRunOrderBy = {
  create_timestamp?: InputMaybe<OrderBy>;
  detections_aggregate?: InputMaybe<DetectionAggregateOrderBy>;
  detector?: InputMaybe<DetectorOrderBy>;
  detector_id?: InputMaybe<OrderBy>;
  duration_milliseconds?: InputMaybe<OrderBy>;
  enumeration?: InputMaybe<EnumerationOrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement?: InputMaybe<MeasurementOrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  measurement_time?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  start_timestamp?: InputMaybe<OrderBy>;
  state_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: detection_run */
export type DetectionRunPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DetectionRunPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "detection_run" */
export enum DetectionRunSelectColumn {
  /** column name */
  create_timestamp = 'create_timestamp',
  /** column name */
  detector_id = 'detector_id',
  /** column name */
  duration_milliseconds = 'duration_milliseconds',
  /** column name */
  id = 'id',
  /** column name */
  measurement_id = 'measurement_id',
  /** column name */
  measurement_time = 'measurement_time',
  /** column name */
  metadata = 'metadata',
  /** column name */
  start_timestamp = 'start_timestamp',
  /** column name */
  state_id = 'state_id'
}

/** input type for updating data in table "detection_run" */
export type DetectionRunSetInput = {
  create_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  detector_id?: InputMaybe<Scalars['Int']['input']>;
  duration_milliseconds?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  measurement_id?: InputMaybe<Scalars['bigint']['input']>;
  measurement_time?: InputMaybe<Scalars['timestamptz']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  start_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  state_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type DetectionRunStddevFields = {
  __typename?: 'detection_run_stddev_fields';
  detector_id?: Maybe<Scalars['Float']['output']>;
  duration_milliseconds?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  state_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "detection_run" */
export type DetectionRunStddevOrderBy = {
  detector_id?: InputMaybe<OrderBy>;
  duration_milliseconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  state_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DetectionRunStddevPopFields = {
  __typename?: 'detection_run_stddev_pop_fields';
  detector_id?: Maybe<Scalars['Float']['output']>;
  duration_milliseconds?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  state_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "detection_run" */
export type DetectionRunStddevPopOrderBy = {
  detector_id?: InputMaybe<OrderBy>;
  duration_milliseconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  state_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DetectionRunStddevSampFields = {
  __typename?: 'detection_run_stddev_samp_fields';
  detector_id?: Maybe<Scalars['Float']['output']>;
  duration_milliseconds?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  state_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "detection_run" */
export type DetectionRunStddevSampOrderBy = {
  detector_id?: InputMaybe<OrderBy>;
  duration_milliseconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  state_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "detection_run" */
export type DetectionRunStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DetectionRunStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DetectionRunStreamCursorValueInput = {
  create_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  detector_id?: InputMaybe<Scalars['Int']['input']>;
  duration_milliseconds?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  measurement_id?: InputMaybe<Scalars['bigint']['input']>;
  measurement_time?: InputMaybe<Scalars['timestamptz']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  start_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  state_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type DetectionRunSumFields = {
  __typename?: 'detection_run_sum_fields';
  detector_id?: Maybe<Scalars['Int']['output']>;
  duration_milliseconds?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  state_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "detection_run" */
export type DetectionRunSumOrderBy = {
  detector_id?: InputMaybe<OrderBy>;
  duration_milliseconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  state_id?: InputMaybe<OrderBy>;
};

/** update columns of table "detection_run" */
export enum DetectionRunUpdateColumn {
  /** column name */
  create_timestamp = 'create_timestamp',
  /** column name */
  detector_id = 'detector_id',
  /** column name */
  duration_milliseconds = 'duration_milliseconds',
  /** column name */
  id = 'id',
  /** column name */
  measurement_id = 'measurement_id',
  /** column name */
  measurement_time = 'measurement_time',
  /** column name */
  metadata = 'metadata',
  /** column name */
  start_timestamp = 'start_timestamp',
  /** column name */
  state_id = 'state_id'
}

export type DetectionRunUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DetectionRunAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<DetectionRunDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<DetectionRunDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<DetectionRunDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DetectionRunIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DetectionRunPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DetectionRunSetInput>;
  /** filter the rows which have to be updated */
  where: DetectionRunBoolExp;
};

/** aggregate var_pop on columns */
export type DetectionRunVarPopFields = {
  __typename?: 'detection_run_var_pop_fields';
  detector_id?: Maybe<Scalars['Float']['output']>;
  duration_milliseconds?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  state_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "detection_run" */
export type DetectionRunVarPopOrderBy = {
  detector_id?: InputMaybe<OrderBy>;
  duration_milliseconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  state_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DetectionRunVarSampFields = {
  __typename?: 'detection_run_var_samp_fields';
  detector_id?: Maybe<Scalars['Float']['output']>;
  duration_milliseconds?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  state_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "detection_run" */
export type DetectionRunVarSampOrderBy = {
  detector_id?: InputMaybe<OrderBy>;
  duration_milliseconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  state_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DetectionRunVarianceFields = {
  __typename?: 'detection_run_variance_fields';
  detector_id?: Maybe<Scalars['Float']['output']>;
  duration_milliseconds?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  state_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "detection_run" */
export type DetectionRunVarianceOrderBy = {
  detector_id?: InputMaybe<OrderBy>;
  duration_milliseconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  state_id?: InputMaybe<OrderBy>;
};

/** select columns of table "detection" */
export enum DetectionSelectColumn {
  /** column name */
  classification_confidence = 'classification_confidence',
  /** column name */
  classification_distribution = 'classification_distribution',
  /** column name */
  classification_id = 'classification_id',
  /** column name */
  crop_resource_path = 'crop_resource_path',
  /** column name */
  detection_confidence = 'detection_confidence',
  /** column name */
  detection_run_id = 'detection_run_id',
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  region_of_interest = 'region_of_interest'
}

/** input type for updating data in table "detection" */
export type DetectionSetInput = {
  classification_confidence?: InputMaybe<Scalars['Float']['input']>;
  classification_distribution?: InputMaybe<Scalars['jsonb']['input']>;
  classification_id?: InputMaybe<Scalars['Int']['input']>;
  crop_resource_path?: InputMaybe<Scalars['String']['input']>;
  detection_confidence?: InputMaybe<Scalars['Float']['input']>;
  detection_run_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  region_of_interest?: InputMaybe<Scalars['geometry']['input']>;
};

/** aggregate stddev on columns */
export type DetectionStddevFields = {
  __typename?: 'detection_stddev_fields';
  classification_confidence?: Maybe<Scalars['Float']['output']>;
  classification_id?: Maybe<Scalars['Float']['output']>;
  detection_confidence?: Maybe<Scalars['Float']['output']>;
  detection_run_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "detection" */
export type DetectionStddevOrderBy = {
  classification_confidence?: InputMaybe<OrderBy>;
  classification_id?: InputMaybe<OrderBy>;
  detection_confidence?: InputMaybe<OrderBy>;
  detection_run_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DetectionStddevPopFields = {
  __typename?: 'detection_stddev_pop_fields';
  classification_confidence?: Maybe<Scalars['Float']['output']>;
  classification_id?: Maybe<Scalars['Float']['output']>;
  detection_confidence?: Maybe<Scalars['Float']['output']>;
  detection_run_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "detection" */
export type DetectionStddevPopOrderBy = {
  classification_confidence?: InputMaybe<OrderBy>;
  classification_id?: InputMaybe<OrderBy>;
  detection_confidence?: InputMaybe<OrderBy>;
  detection_run_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DetectionStddevSampFields = {
  __typename?: 'detection_stddev_samp_fields';
  classification_confidence?: Maybe<Scalars['Float']['output']>;
  classification_id?: Maybe<Scalars['Float']['output']>;
  detection_confidence?: Maybe<Scalars['Float']['output']>;
  detection_run_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "detection" */
export type DetectionStddevSampOrderBy = {
  classification_confidence?: InputMaybe<OrderBy>;
  classification_id?: InputMaybe<OrderBy>;
  detection_confidence?: InputMaybe<OrderBy>;
  detection_run_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "detection" */
export type DetectionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DetectionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DetectionStreamCursorValueInput = {
  classification_confidence?: InputMaybe<Scalars['Float']['input']>;
  classification_distribution?: InputMaybe<Scalars['jsonb']['input']>;
  classification_id?: InputMaybe<Scalars['Int']['input']>;
  crop_resource_path?: InputMaybe<Scalars['String']['input']>;
  detection_confidence?: InputMaybe<Scalars['Float']['input']>;
  detection_run_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  region_of_interest?: InputMaybe<Scalars['geometry']['input']>;
};

/** aggregate sum on columns */
export type DetectionSumFields = {
  __typename?: 'detection_sum_fields';
  classification_confidence?: Maybe<Scalars['Float']['output']>;
  classification_id?: Maybe<Scalars['Int']['output']>;
  detection_confidence?: Maybe<Scalars['Float']['output']>;
  detection_run_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "detection" */
export type DetectionSumOrderBy = {
  classification_confidence?: InputMaybe<OrderBy>;
  classification_id?: InputMaybe<OrderBy>;
  detection_confidence?: InputMaybe<OrderBy>;
  detection_run_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** update columns of table "detection" */
export enum DetectionUpdateColumn {
  /** column name */
  classification_confidence = 'classification_confidence',
  /** column name */
  classification_distribution = 'classification_distribution',
  /** column name */
  classification_id = 'classification_id',
  /** column name */
  crop_resource_path = 'crop_resource_path',
  /** column name */
  detection_confidence = 'detection_confidence',
  /** column name */
  detection_run_id = 'detection_run_id',
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  region_of_interest = 'region_of_interest'
}

export type DetectionUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DetectionAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<DetectionDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<DetectionDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<DetectionDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DetectionIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DetectionPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DetectionSetInput>;
  /** filter the rows which have to be updated */
  where: DetectionBoolExp;
};

/** aggregate var_pop on columns */
export type DetectionVarPopFields = {
  __typename?: 'detection_var_pop_fields';
  classification_confidence?: Maybe<Scalars['Float']['output']>;
  classification_id?: Maybe<Scalars['Float']['output']>;
  detection_confidence?: Maybe<Scalars['Float']['output']>;
  detection_run_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "detection" */
export type DetectionVarPopOrderBy = {
  classification_confidence?: InputMaybe<OrderBy>;
  classification_id?: InputMaybe<OrderBy>;
  detection_confidence?: InputMaybe<OrderBy>;
  detection_run_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DetectionVarSampFields = {
  __typename?: 'detection_var_samp_fields';
  classification_confidence?: Maybe<Scalars['Float']['output']>;
  classification_id?: Maybe<Scalars['Float']['output']>;
  detection_confidence?: Maybe<Scalars['Float']['output']>;
  detection_run_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "detection" */
export type DetectionVarSampOrderBy = {
  classification_confidence?: InputMaybe<OrderBy>;
  classification_id?: InputMaybe<OrderBy>;
  detection_confidence?: InputMaybe<OrderBy>;
  detection_run_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DetectionVarianceFields = {
  __typename?: 'detection_variance_fields';
  classification_confidence?: Maybe<Scalars['Float']['output']>;
  classification_id?: Maybe<Scalars['Float']['output']>;
  detection_confidence?: Maybe<Scalars['Float']['output']>;
  detection_run_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "detection" */
export type DetectionVarianceOrderBy = {
  classification_confidence?: InputMaybe<OrderBy>;
  classification_id?: InputMaybe<OrderBy>;
  detection_confidence?: InputMaybe<OrderBy>;
  detection_run_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "detector" */
export type Detector = {
  __typename?: 'detector';
  /** An array relationship */
  detection_runs: Array<DetectionRun>;
  /** An aggregate relationship */
  detection_runs_aggregate: DetectionRunAggregate;
  id: Scalars['Int']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  name: Scalars['String']['output'];
  /** An object relationship */
  version: Version;
  version_id: Scalars['Int']['output'];
};


/** columns and relationships of "detector" */
export type DetectorDetectionRunsArgs = {
  distinct_on?: InputMaybe<Array<DetectionRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectionRunOrderBy>>;
  where?: InputMaybe<DetectionRunBoolExp>;
};


/** columns and relationships of "detector" */
export type DetectorDetectionRunsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DetectionRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectionRunOrderBy>>;
  where?: InputMaybe<DetectionRunBoolExp>;
};


/** columns and relationships of "detector" */
export type DetectorMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "detector" */
export type DetectorAggregate = {
  __typename?: 'detector_aggregate';
  aggregate?: Maybe<DetectorAggregateFields>;
  nodes: Array<Detector>;
};

export type DetectorAggregateBoolExp = {
  count?: InputMaybe<DetectorAggregateBoolExpCount>;
};

export type DetectorAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DetectorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<DetectorBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "detector" */
export type DetectorAggregateFields = {
  __typename?: 'detector_aggregate_fields';
  avg?: Maybe<DetectorAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<DetectorMaxFields>;
  min?: Maybe<DetectorMinFields>;
  stddev?: Maybe<DetectorStddevFields>;
  stddev_pop?: Maybe<DetectorStddevPopFields>;
  stddev_samp?: Maybe<DetectorStddevSampFields>;
  sum?: Maybe<DetectorSumFields>;
  var_pop?: Maybe<DetectorVarPopFields>;
  var_samp?: Maybe<DetectorVarSampFields>;
  variance?: Maybe<DetectorVarianceFields>;
};


/** aggregate fields of "detector" */
export type DetectorAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DetectorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "detector" */
export type DetectorAggregateOrderBy = {
  avg?: InputMaybe<DetectorAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DetectorMaxOrderBy>;
  min?: InputMaybe<DetectorMinOrderBy>;
  stddev?: InputMaybe<DetectorStddevOrderBy>;
  stddev_pop?: InputMaybe<DetectorStddevPopOrderBy>;
  stddev_samp?: InputMaybe<DetectorStddevSampOrderBy>;
  sum?: InputMaybe<DetectorSumOrderBy>;
  var_pop?: InputMaybe<DetectorVarPopOrderBy>;
  var_samp?: InputMaybe<DetectorVarSampOrderBy>;
  variance?: InputMaybe<DetectorVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DetectorAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "detector" */
export type DetectorArrRelInsertInput = {
  data: Array<DetectorInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<DetectorOnConflict>;
};

/** aggregate avg on columns */
export type DetectorAvgFields = {
  __typename?: 'detector_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "detector" */
export type DetectorAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "detector". All fields are combined with a logical 'AND'. */
export type DetectorBoolExp = {
  _and?: InputMaybe<Array<DetectorBoolExp>>;
  _not?: InputMaybe<DetectorBoolExp>;
  _or?: InputMaybe<Array<DetectorBoolExp>>;
  detection_runs?: InputMaybe<DetectionRunBoolExp>;
  detection_runs_aggregate?: InputMaybe<DetectionRunAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  version?: InputMaybe<VersionBoolExp>;
  version_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "detector" */
export enum DetectorConstraint {
  /** unique or primary key constraint on columns "id" */
  detector_pkey = 'detector_pkey',
  /** unique or primary key constraint on columns "name", "version_id" */
  name_version_un = 'name_version_un'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DetectorDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DetectorDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DetectorDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "detector" */
export type DetectorIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "detector" */
export type DetectorInsertInput = {
  detection_runs?: InputMaybe<DetectionRunArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<VersionObjRelInsertInput>;
  version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type DetectorMaxFields = {
  __typename?: 'detector_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "detector" */
export type DetectorMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DetectorMinFields = {
  __typename?: 'detector_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "detector" */
export type DetectorMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "detector" */
export type DetectorMutationResponse = {
  __typename?: 'detector_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Detector>;
};

/** input type for inserting object relation for remote table "detector" */
export type DetectorObjRelInsertInput = {
  data: DetectorInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<DetectorOnConflict>;
};

/** on_conflict condition type for table "detector" */
export type DetectorOnConflict = {
  constraint: DetectorConstraint;
  update_columns?: Array<DetectorUpdateColumn>;
  where?: InputMaybe<DetectorBoolExp>;
};

/** Ordering options when selecting data from "detector". */
export type DetectorOrderBy = {
  detection_runs_aggregate?: InputMaybe<DetectionRunAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  version?: InputMaybe<VersionOrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: detector */
export type DetectorPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DetectorPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "detector" */
export enum DetectorSelectColumn {
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  name = 'name',
  /** column name */
  version_id = 'version_id'
}

/** input type for updating data in table "detector" */
export type DetectorSetInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type DetectorStddevFields = {
  __typename?: 'detector_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "detector" */
export type DetectorStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DetectorStddevPopFields = {
  __typename?: 'detector_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "detector" */
export type DetectorStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DetectorStddevSampFields = {
  __typename?: 'detector_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "detector" */
export type DetectorStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "detector" */
export type DetectorStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DetectorStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DetectorStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type DetectorSumFields = {
  __typename?: 'detector_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "detector" */
export type DetectorSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** update columns of table "detector" */
export enum DetectorUpdateColumn {
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  name = 'name',
  /** column name */
  version_id = 'version_id'
}

export type DetectorUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DetectorAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<DetectorDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<DetectorDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<DetectorDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DetectorIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DetectorPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DetectorSetInput>;
  /** filter the rows which have to be updated */
  where: DetectorBoolExp;
};

/** aggregate var_pop on columns */
export type DetectorVarPopFields = {
  __typename?: 'detector_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "detector" */
export type DetectorVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DetectorVarSampFields = {
  __typename?: 'detector_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "detector" */
export type DetectorVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DetectorVarianceFields = {
  __typename?: 'detector_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "detector" */
export type DetectorVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  version_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "device_datum" */
export type DeviceDatum = {
  __typename?: 'device_datum';
  component_id?: Maybe<Scalars['uuid']['output']>;
  created_time?: Maybe<Scalars['timestamptz']['output']>;
  data?: Maybe<Scalars['jsonb']['output']>;
  data_type?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  s3_bucket_name?: Maybe<Scalars['String']['output']>;
  s3_object_key?: Maybe<Scalars['String']['output']>;
  test_bench_hardware_version?: Maybe<Scalars['String']['output']>;
  test_bench_software_version?: Maybe<Scalars['String']['output']>;
  user_email?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "device_datum" */
export type DeviceDatumDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "device_datum" */
export type DeviceDatumAggregate = {
  __typename?: 'device_datum_aggregate';
  aggregate?: Maybe<DeviceDatumAggregateFields>;
  nodes: Array<DeviceDatum>;
};

/** aggregate fields of "device_datum" */
export type DeviceDatumAggregateFields = {
  __typename?: 'device_datum_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DeviceDatumMaxFields>;
  min?: Maybe<DeviceDatumMinFields>;
};


/** aggregate fields of "device_datum" */
export type DeviceDatumAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DeviceDatumSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DeviceDatumAppendInput = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "device_datum". All fields are combined with a logical 'AND'. */
export type DeviceDatumBoolExp = {
  _and?: InputMaybe<Array<DeviceDatumBoolExp>>;
  _not?: InputMaybe<DeviceDatumBoolExp>;
  _or?: InputMaybe<Array<DeviceDatumBoolExp>>;
  component_id?: InputMaybe<UuidComparisonExp>;
  created_time?: InputMaybe<TimestamptzComparisonExp>;
  data?: InputMaybe<JsonbComparisonExp>;
  data_type?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  s3_bucket_name?: InputMaybe<StringComparisonExp>;
  s3_object_key?: InputMaybe<StringComparisonExp>;
  test_bench_hardware_version?: InputMaybe<StringComparisonExp>;
  test_bench_software_version?: InputMaybe<StringComparisonExp>;
  user_email?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "device_datum" */
export enum DeviceDatumConstraint {
  /** unique or primary key constraint on columns "id" */
  device_datum_pkey = 'device_datum_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DeviceDatumDeleteAtPathInput = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DeviceDatumDeleteElemInput = {
  data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DeviceDatumDeleteKeyInput = {
  data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "device_datum" */
export type DeviceDatumInsertInput = {
  component_id?: InputMaybe<Scalars['uuid']['input']>;
  created_time?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  data_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  s3_bucket_name?: InputMaybe<Scalars['String']['input']>;
  s3_object_key?: InputMaybe<Scalars['String']['input']>;
  test_bench_hardware_version?: InputMaybe<Scalars['String']['input']>;
  test_bench_software_version?: InputMaybe<Scalars['String']['input']>;
  user_email?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type DeviceDatumMaxFields = {
  __typename?: 'device_datum_max_fields';
  component_id?: Maybe<Scalars['uuid']['output']>;
  created_time?: Maybe<Scalars['timestamptz']['output']>;
  data_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  s3_bucket_name?: Maybe<Scalars['String']['output']>;
  s3_object_key?: Maybe<Scalars['String']['output']>;
  test_bench_hardware_version?: Maybe<Scalars['String']['output']>;
  test_bench_software_version?: Maybe<Scalars['String']['output']>;
  user_email?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type DeviceDatumMinFields = {
  __typename?: 'device_datum_min_fields';
  component_id?: Maybe<Scalars['uuid']['output']>;
  created_time?: Maybe<Scalars['timestamptz']['output']>;
  data_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  s3_bucket_name?: Maybe<Scalars['String']['output']>;
  s3_object_key?: Maybe<Scalars['String']['output']>;
  test_bench_hardware_version?: Maybe<Scalars['String']['output']>;
  test_bench_software_version?: Maybe<Scalars['String']['output']>;
  user_email?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "device_datum" */
export type DeviceDatumMutationResponse = {
  __typename?: 'device_datum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DeviceDatum>;
};

/** on_conflict condition type for table "device_datum" */
export type DeviceDatumOnConflict = {
  constraint: DeviceDatumConstraint;
  update_columns?: Array<DeviceDatumUpdateColumn>;
  where?: InputMaybe<DeviceDatumBoolExp>;
};

/** Ordering options when selecting data from "device_datum". */
export type DeviceDatumOrderBy = {
  component_id?: InputMaybe<OrderBy>;
  created_time?: InputMaybe<OrderBy>;
  data?: InputMaybe<OrderBy>;
  data_type?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  s3_bucket_name?: InputMaybe<OrderBy>;
  s3_object_key?: InputMaybe<OrderBy>;
  test_bench_hardware_version?: InputMaybe<OrderBy>;
  test_bench_software_version?: InputMaybe<OrderBy>;
  user_email?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: device_datum */
export type DeviceDatumPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DeviceDatumPrependInput = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "device_datum" */
export enum DeviceDatumSelectColumn {
  /** column name */
  component_id = 'component_id',
  /** column name */
  created_time = 'created_time',
  /** column name */
  data = 'data',
  /** column name */
  data_type = 'data_type',
  /** column name */
  id = 'id',
  /** column name */
  s3_bucket_name = 's3_bucket_name',
  /** column name */
  s3_object_key = 's3_object_key',
  /** column name */
  test_bench_hardware_version = 'test_bench_hardware_version',
  /** column name */
  test_bench_software_version = 'test_bench_software_version',
  /** column name */
  user_email = 'user_email'
}

/** input type for updating data in table "device_datum" */
export type DeviceDatumSetInput = {
  component_id?: InputMaybe<Scalars['uuid']['input']>;
  created_time?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  data_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  s3_bucket_name?: InputMaybe<Scalars['String']['input']>;
  s3_object_key?: InputMaybe<Scalars['String']['input']>;
  test_bench_hardware_version?: InputMaybe<Scalars['String']['input']>;
  test_bench_software_version?: InputMaybe<Scalars['String']['input']>;
  user_email?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "device_datum" */
export type DeviceDatumStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DeviceDatumStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DeviceDatumStreamCursorValueInput = {
  component_id?: InputMaybe<Scalars['uuid']['input']>;
  created_time?: InputMaybe<Scalars['timestamptz']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  data_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  s3_bucket_name?: InputMaybe<Scalars['String']['input']>;
  s3_object_key?: InputMaybe<Scalars['String']['input']>;
  test_bench_hardware_version?: InputMaybe<Scalars['String']['input']>;
  test_bench_software_version?: InputMaybe<Scalars['String']['input']>;
  user_email?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "device_datum" */
export enum DeviceDatumUpdateColumn {
  /** column name */
  component_id = 'component_id',
  /** column name */
  created_time = 'created_time',
  /** column name */
  data = 'data',
  /** column name */
  data_type = 'data_type',
  /** column name */
  id = 'id',
  /** column name */
  s3_bucket_name = 's3_bucket_name',
  /** column name */
  s3_object_key = 's3_object_key',
  /** column name */
  test_bench_hardware_version = 'test_bench_hardware_version',
  /** column name */
  test_bench_software_version = 'test_bench_software_version',
  /** column name */
  user_email = 'user_email'
}

export type DeviceDatumUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DeviceDatumAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<DeviceDatumDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<DeviceDatumDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<DeviceDatumDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DeviceDatumPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DeviceDatumSetInput>;
  /** filter the rows which have to be updated */
  where: DeviceDatumBoolExp;
};

/** columns and relationships of "device_metadata" */
export type DeviceMetadata = {
  __typename?: 'device_metadata';
  component_type: Scalars['String']['output'];
  component_version: Scalars['String']['output'];
  created_time?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  device_metadata?: Maybe<DeviceMetadata>;
  /** An array relationship */
  device_metadatas: Array<DeviceMetadata>;
  /** An aggregate relationship */
  device_metadatas_aggregate: DeviceMetadataAggregate;
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  parent_component_id?: Maybe<Scalars['uuid']['output']>;
};


/** columns and relationships of "device_metadata" */
export type DeviceMetadataDeviceMetadatasArgs = {
  distinct_on?: InputMaybe<Array<DeviceMetadataSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeviceMetadataOrderBy>>;
  where?: InputMaybe<DeviceMetadataBoolExp>;
};


/** columns and relationships of "device_metadata" */
export type DeviceMetadataDeviceMetadatasAggregateArgs = {
  distinct_on?: InputMaybe<Array<DeviceMetadataSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeviceMetadataOrderBy>>;
  where?: InputMaybe<DeviceMetadataBoolExp>;
};


/** columns and relationships of "device_metadata" */
export type DeviceMetadataMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "device_metadata" */
export type DeviceMetadataAggregate = {
  __typename?: 'device_metadata_aggregate';
  aggregate?: Maybe<DeviceMetadataAggregateFields>;
  nodes: Array<DeviceMetadata>;
};

export type DeviceMetadataAggregateBoolExp = {
  count?: InputMaybe<DeviceMetadataAggregateBoolExpCount>;
};

export type DeviceMetadataAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DeviceMetadataSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<DeviceMetadataBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "device_metadata" */
export type DeviceMetadataAggregateFields = {
  __typename?: 'device_metadata_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DeviceMetadataMaxFields>;
  min?: Maybe<DeviceMetadataMinFields>;
};


/** aggregate fields of "device_metadata" */
export type DeviceMetadataAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DeviceMetadataSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "device_metadata" */
export type DeviceMetadataAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DeviceMetadataMaxOrderBy>;
  min?: InputMaybe<DeviceMetadataMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DeviceMetadataAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "device_metadata" */
export type DeviceMetadataArrRelInsertInput = {
  data: Array<DeviceMetadataInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<DeviceMetadataOnConflict>;
};

/** Boolean expression to filter rows from the table "device_metadata". All fields are combined with a logical 'AND'. */
export type DeviceMetadataBoolExp = {
  _and?: InputMaybe<Array<DeviceMetadataBoolExp>>;
  _not?: InputMaybe<DeviceMetadataBoolExp>;
  _or?: InputMaybe<Array<DeviceMetadataBoolExp>>;
  component_type?: InputMaybe<StringComparisonExp>;
  component_version?: InputMaybe<StringComparisonExp>;
  created_time?: InputMaybe<TimestamptzComparisonExp>;
  device_metadata?: InputMaybe<DeviceMetadataBoolExp>;
  device_metadatas?: InputMaybe<DeviceMetadataBoolExp>;
  device_metadatas_aggregate?: InputMaybe<DeviceMetadataAggregateBoolExp>;
  id?: InputMaybe<UuidComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  parent_component_id?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "device_metadata" */
export enum DeviceMetadataConstraint {
  /** unique or primary key constraint on columns "id" */
  device_metadata_pkey = 'device_metadata_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DeviceMetadataDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DeviceMetadataDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DeviceMetadataDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "device_metadata" */
export type DeviceMetadataInsertInput = {
  component_type?: InputMaybe<Scalars['String']['input']>;
  component_version?: InputMaybe<Scalars['String']['input']>;
  created_time?: InputMaybe<Scalars['timestamptz']['input']>;
  device_metadata?: InputMaybe<DeviceMetadataObjRelInsertInput>;
  device_metadatas?: InputMaybe<DeviceMetadataArrRelInsertInput>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  parent_component_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type DeviceMetadataMaxFields = {
  __typename?: 'device_metadata_max_fields';
  component_type?: Maybe<Scalars['String']['output']>;
  component_version?: Maybe<Scalars['String']['output']>;
  created_time?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  parent_component_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "device_metadata" */
export type DeviceMetadataMaxOrderBy = {
  component_type?: InputMaybe<OrderBy>;
  component_version?: InputMaybe<OrderBy>;
  created_time?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parent_component_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DeviceMetadataMinFields = {
  __typename?: 'device_metadata_min_fields';
  component_type?: Maybe<Scalars['String']['output']>;
  component_version?: Maybe<Scalars['String']['output']>;
  created_time?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  parent_component_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "device_metadata" */
export type DeviceMetadataMinOrderBy = {
  component_type?: InputMaybe<OrderBy>;
  component_version?: InputMaybe<OrderBy>;
  created_time?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parent_component_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "device_metadata" */
export type DeviceMetadataMutationResponse = {
  __typename?: 'device_metadata_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DeviceMetadata>;
};

/** input type for inserting object relation for remote table "device_metadata" */
export type DeviceMetadataObjRelInsertInput = {
  data: DeviceMetadataInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<DeviceMetadataOnConflict>;
};

/** on_conflict condition type for table "device_metadata" */
export type DeviceMetadataOnConflict = {
  constraint: DeviceMetadataConstraint;
  update_columns?: Array<DeviceMetadataUpdateColumn>;
  where?: InputMaybe<DeviceMetadataBoolExp>;
};

/** Ordering options when selecting data from "device_metadata". */
export type DeviceMetadataOrderBy = {
  component_type?: InputMaybe<OrderBy>;
  component_version?: InputMaybe<OrderBy>;
  created_time?: InputMaybe<OrderBy>;
  device_metadata?: InputMaybe<DeviceMetadataOrderBy>;
  device_metadatas_aggregate?: InputMaybe<DeviceMetadataAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  parent_component_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: device_metadata */
export type DeviceMetadataPkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DeviceMetadataPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "device_metadata" */
export enum DeviceMetadataSelectColumn {
  /** column name */
  component_type = 'component_type',
  /** column name */
  component_version = 'component_version',
  /** column name */
  created_time = 'created_time',
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  parent_component_id = 'parent_component_id'
}

/** input type for updating data in table "device_metadata" */
export type DeviceMetadataSetInput = {
  component_type?: InputMaybe<Scalars['String']['input']>;
  component_version?: InputMaybe<Scalars['String']['input']>;
  created_time?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  parent_component_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "device_metadata" */
export type DeviceMetadataStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DeviceMetadataStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DeviceMetadataStreamCursorValueInput = {
  component_type?: InputMaybe<Scalars['String']['input']>;
  component_version?: InputMaybe<Scalars['String']['input']>;
  created_time?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  parent_component_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "device_metadata" */
export enum DeviceMetadataUpdateColumn {
  /** column name */
  component_type = 'component_type',
  /** column name */
  component_version = 'component_version',
  /** column name */
  created_time = 'created_time',
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  parent_component_id = 'parent_component_id'
}

export type DeviceMetadataUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DeviceMetadataAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<DeviceMetadataDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<DeviceMetadataDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<DeviceMetadataDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DeviceMetadataPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DeviceMetadataSetInput>;
  /** filter the rows which have to be updated */
  where: DeviceMetadataBoolExp;
};

/** columns and relationships of "discussion" */
export type Discussion = {
  __typename?: 'discussion';
  /** An object relationship */
  aggregate_heatmap?: Maybe<AggregateHeatmap>;
  annotation_type: Scalars['String']['output'];
  area?: Maybe<Scalars['jsonb']['output']>;
  author_id: Scalars['Int']['output'];
  author_organization_code: Scalars['String']['output'];
  category: Scalars['String']['output'];
  /** An array relationship */
  comments: Array<Comment>;
  /** An aggregate relationship */
  comments_aggregate: CommentAggregate;
  created_at: Scalars['timestamptz']['output'];
  end_time: Scalars['timestamptz']['output'];
  /** An object relationship */
  heat_map?: Maybe<HeatMap>;
  heat_map_aggregate_uid?: Maybe<Scalars['uuid']['output']>;
  heat_map_id?: Maybe<Scalars['Int']['output']>;
  last_updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  measurement?: Maybe<Measurement>;
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  start_time: Scalars['timestamptz']['output'];
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
  uid: Scalars['uuid']['output'];
  /** An object relationship */
  user: User;
  /** An array relationship */
  user_comments: Array<UserComment>;
  /** An aggregate relationship */
  user_comments_aggregate: UserCommentAggregate;
  /** An object relationship */
  zone: Zone;
  zone_uid: Scalars['String']['output'];
};


/** columns and relationships of "discussion" */
export type DiscussionAreaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "discussion" */
export type DiscussionCommentsArgs = {
  distinct_on?: InputMaybe<Array<CommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CommentOrderBy>>;
  where?: InputMaybe<CommentBoolExp>;
};


/** columns and relationships of "discussion" */
export type DiscussionCommentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CommentOrderBy>>;
  where?: InputMaybe<CommentBoolExp>;
};


/** columns and relationships of "discussion" */
export type DiscussionUserCommentsArgs = {
  distinct_on?: InputMaybe<Array<UserCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserCommentOrderBy>>;
  where?: InputMaybe<UserCommentBoolExp>;
};


/** columns and relationships of "discussion" */
export type DiscussionUserCommentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserCommentOrderBy>>;
  where?: InputMaybe<UserCommentBoolExp>;
};

/** aggregated selection of "discussion" */
export type DiscussionAggregate = {
  __typename?: 'discussion_aggregate';
  aggregate?: Maybe<DiscussionAggregateFields>;
  nodes: Array<Discussion>;
};

export type DiscussionAggregateBoolExp = {
  count?: InputMaybe<DiscussionAggregateBoolExpCount>;
};

export type DiscussionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DiscussionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<DiscussionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "discussion" */
export type DiscussionAggregateFields = {
  __typename?: 'discussion_aggregate_fields';
  avg?: Maybe<DiscussionAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<DiscussionMaxFields>;
  min?: Maybe<DiscussionMinFields>;
  stddev?: Maybe<DiscussionStddevFields>;
  stddev_pop?: Maybe<DiscussionStddevPopFields>;
  stddev_samp?: Maybe<DiscussionStddevSampFields>;
  sum?: Maybe<DiscussionSumFields>;
  var_pop?: Maybe<DiscussionVarPopFields>;
  var_samp?: Maybe<DiscussionVarSampFields>;
  variance?: Maybe<DiscussionVarianceFields>;
};


/** aggregate fields of "discussion" */
export type DiscussionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DiscussionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "discussion" */
export type DiscussionAggregateOrderBy = {
  avg?: InputMaybe<DiscussionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DiscussionMaxOrderBy>;
  min?: InputMaybe<DiscussionMinOrderBy>;
  stddev?: InputMaybe<DiscussionStddevOrderBy>;
  stddev_pop?: InputMaybe<DiscussionStddevPopOrderBy>;
  stddev_samp?: InputMaybe<DiscussionStddevSampOrderBy>;
  sum?: InputMaybe<DiscussionSumOrderBy>;
  var_pop?: InputMaybe<DiscussionVarPopOrderBy>;
  var_samp?: InputMaybe<DiscussionVarSampOrderBy>;
  variance?: InputMaybe<DiscussionVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DiscussionAppendInput = {
  area?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "discussion" */
export type DiscussionArrRelInsertInput = {
  data: Array<DiscussionInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<DiscussionOnConflict>;
};

/** aggregate avg on columns */
export type DiscussionAvgFields = {
  __typename?: 'discussion_avg_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
  heat_map_id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "discussion" */
export type DiscussionAvgOrderBy = {
  author_id?: InputMaybe<OrderBy>;
  heat_map_id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "discussion". All fields are combined with a logical 'AND'. */
export type DiscussionBoolExp = {
  _and?: InputMaybe<Array<DiscussionBoolExp>>;
  _not?: InputMaybe<DiscussionBoolExp>;
  _or?: InputMaybe<Array<DiscussionBoolExp>>;
  aggregate_heatmap?: InputMaybe<AggregateHeatmapBoolExp>;
  annotation_type?: InputMaybe<StringComparisonExp>;
  area?: InputMaybe<JsonbComparisonExp>;
  author_id?: InputMaybe<IntComparisonExp>;
  author_organization_code?: InputMaybe<StringComparisonExp>;
  category?: InputMaybe<StringComparisonExp>;
  comments?: InputMaybe<CommentBoolExp>;
  comments_aggregate?: InputMaybe<CommentAggregateBoolExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  end_time?: InputMaybe<TimestamptzComparisonExp>;
  heat_map?: InputMaybe<HeatMapBoolExp>;
  heat_map_aggregate_uid?: InputMaybe<UuidComparisonExp>;
  heat_map_id?: InputMaybe<IntComparisonExp>;
  last_updated_at?: InputMaybe<TimestamptzComparisonExp>;
  measurement?: InputMaybe<MeasurementBoolExp>;
  measurement_id?: InputMaybe<BigintComparisonExp>;
  start_time?: InputMaybe<TimestamptzComparisonExp>;
  status?: InputMaybe<StringComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
  uid?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  user_comments?: InputMaybe<UserCommentBoolExp>;
  user_comments_aggregate?: InputMaybe<UserCommentAggregateBoolExp>;
  zone?: InputMaybe<ZoneBoolExp>;
  zone_uid?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "discussion" */
export enum DiscussionConstraint {
  /** unique or primary key constraint on columns "uid" */
  discussion_pkey = 'discussion_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DiscussionDeleteAtPathInput = {
  area?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DiscussionDeleteElemInput = {
  area?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DiscussionDeleteKeyInput = {
  area?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "discussion" */
export type DiscussionIncInput = {
  author_id?: InputMaybe<Scalars['Int']['input']>;
  heat_map_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "discussion" */
export type DiscussionInsertInput = {
  aggregate_heatmap?: InputMaybe<AggregateHeatmapObjRelInsertInput>;
  annotation_type?: InputMaybe<Scalars['String']['input']>;
  area?: InputMaybe<Scalars['jsonb']['input']>;
  author_id?: InputMaybe<Scalars['Int']['input']>;
  author_organization_code?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<CommentArrRelInsertInput>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  heat_map?: InputMaybe<HeatMapObjRelInsertInput>;
  heat_map_aggregate_uid?: InputMaybe<Scalars['uuid']['input']>;
  heat_map_id?: InputMaybe<Scalars['Int']['input']>;
  last_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  measurement?: InputMaybe<MeasurementObjRelInsertInput>;
  measurement_id?: InputMaybe<Scalars['bigint']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  user_comments?: InputMaybe<UserCommentArrRelInsertInput>;
  zone?: InputMaybe<ZoneObjRelInsertInput>;
  zone_uid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type DiscussionMaxFields = {
  __typename?: 'discussion_max_fields';
  annotation_type?: Maybe<Scalars['String']['output']>;
  author_id?: Maybe<Scalars['Int']['output']>;
  author_organization_code?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  end_time?: Maybe<Scalars['timestamptz']['output']>;
  heat_map_aggregate_uid?: Maybe<Scalars['uuid']['output']>;
  heat_map_id?: Maybe<Scalars['Int']['output']>;
  last_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  start_time?: Maybe<Scalars['timestamptz']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['uuid']['output']>;
  zone_uid?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "discussion" */
export type DiscussionMaxOrderBy = {
  annotation_type?: InputMaybe<OrderBy>;
  author_id?: InputMaybe<OrderBy>;
  author_organization_code?: InputMaybe<OrderBy>;
  category?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  end_time?: InputMaybe<OrderBy>;
  heat_map_aggregate_uid?: InputMaybe<OrderBy>;
  heat_map_id?: InputMaybe<OrderBy>;
  last_updated_at?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  start_time?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  uid?: InputMaybe<OrderBy>;
  zone_uid?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DiscussionMinFields = {
  __typename?: 'discussion_min_fields';
  annotation_type?: Maybe<Scalars['String']['output']>;
  author_id?: Maybe<Scalars['Int']['output']>;
  author_organization_code?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  end_time?: Maybe<Scalars['timestamptz']['output']>;
  heat_map_aggregate_uid?: Maybe<Scalars['uuid']['output']>;
  heat_map_id?: Maybe<Scalars['Int']['output']>;
  last_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  start_time?: Maybe<Scalars['timestamptz']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['uuid']['output']>;
  zone_uid?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "discussion" */
export type DiscussionMinOrderBy = {
  annotation_type?: InputMaybe<OrderBy>;
  author_id?: InputMaybe<OrderBy>;
  author_organization_code?: InputMaybe<OrderBy>;
  category?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  end_time?: InputMaybe<OrderBy>;
  heat_map_aggregate_uid?: InputMaybe<OrderBy>;
  heat_map_id?: InputMaybe<OrderBy>;
  last_updated_at?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  start_time?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  uid?: InputMaybe<OrderBy>;
  zone_uid?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "discussion" */
export type DiscussionMutationResponse = {
  __typename?: 'discussion_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Discussion>;
};

/** input type for inserting object relation for remote table "discussion" */
export type DiscussionObjRelInsertInput = {
  data: DiscussionInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<DiscussionOnConflict>;
};

/** on_conflict condition type for table "discussion" */
export type DiscussionOnConflict = {
  constraint: DiscussionConstraint;
  update_columns?: Array<DiscussionUpdateColumn>;
  where?: InputMaybe<DiscussionBoolExp>;
};

/** Ordering options when selecting data from "discussion". */
export type DiscussionOrderBy = {
  aggregate_heatmap?: InputMaybe<AggregateHeatmapOrderBy>;
  annotation_type?: InputMaybe<OrderBy>;
  area?: InputMaybe<OrderBy>;
  author_id?: InputMaybe<OrderBy>;
  author_organization_code?: InputMaybe<OrderBy>;
  category?: InputMaybe<OrderBy>;
  comments_aggregate?: InputMaybe<CommentAggregateOrderBy>;
  created_at?: InputMaybe<OrderBy>;
  end_time?: InputMaybe<OrderBy>;
  heat_map?: InputMaybe<HeatMapOrderBy>;
  heat_map_aggregate_uid?: InputMaybe<OrderBy>;
  heat_map_id?: InputMaybe<OrderBy>;
  last_updated_at?: InputMaybe<OrderBy>;
  measurement?: InputMaybe<MeasurementOrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  start_time?: InputMaybe<OrderBy>;
  status?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
  uid?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  user_comments_aggregate?: InputMaybe<UserCommentAggregateOrderBy>;
  zone?: InputMaybe<ZoneOrderBy>;
  zone_uid?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: discussion */
export type DiscussionPkColumnsInput = {
  uid: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DiscussionPrependInput = {
  area?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "discussion" */
export enum DiscussionSelectColumn {
  /** column name */
  annotation_type = 'annotation_type',
  /** column name */
  area = 'area',
  /** column name */
  author_id = 'author_id',
  /** column name */
  author_organization_code = 'author_organization_code',
  /** column name */
  category = 'category',
  /** column name */
  created_at = 'created_at',
  /** column name */
  end_time = 'end_time',
  /** column name */
  heat_map_aggregate_uid = 'heat_map_aggregate_uid',
  /** column name */
  heat_map_id = 'heat_map_id',
  /** column name */
  last_updated_at = 'last_updated_at',
  /** column name */
  measurement_id = 'measurement_id',
  /** column name */
  start_time = 'start_time',
  /** column name */
  status = 'status',
  /** column name */
  type = 'type',
  /** column name */
  uid = 'uid',
  /** column name */
  zone_uid = 'zone_uid'
}

/** input type for updating data in table "discussion" */
export type DiscussionSetInput = {
  annotation_type?: InputMaybe<Scalars['String']['input']>;
  area?: InputMaybe<Scalars['jsonb']['input']>;
  author_id?: InputMaybe<Scalars['Int']['input']>;
  author_organization_code?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  heat_map_aggregate_uid?: InputMaybe<Scalars['uuid']['input']>;
  heat_map_id?: InputMaybe<Scalars['Int']['input']>;
  last_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  measurement_id?: InputMaybe<Scalars['bigint']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['uuid']['input']>;
  zone_uid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type DiscussionStddevFields = {
  __typename?: 'discussion_stddev_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
  heat_map_id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "discussion" */
export type DiscussionStddevOrderBy = {
  author_id?: InputMaybe<OrderBy>;
  heat_map_id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type DiscussionStddevPopFields = {
  __typename?: 'discussion_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
  heat_map_id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "discussion" */
export type DiscussionStddevPopOrderBy = {
  author_id?: InputMaybe<OrderBy>;
  heat_map_id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type DiscussionStddevSampFields = {
  __typename?: 'discussion_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
  heat_map_id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "discussion" */
export type DiscussionStddevSampOrderBy = {
  author_id?: InputMaybe<OrderBy>;
  heat_map_id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "discussion" */
export type DiscussionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DiscussionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DiscussionStreamCursorValueInput = {
  annotation_type?: InputMaybe<Scalars['String']['input']>;
  area?: InputMaybe<Scalars['jsonb']['input']>;
  author_id?: InputMaybe<Scalars['Int']['input']>;
  author_organization_code?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  heat_map_aggregate_uid?: InputMaybe<Scalars['uuid']['input']>;
  heat_map_id?: InputMaybe<Scalars['Int']['input']>;
  last_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  measurement_id?: InputMaybe<Scalars['bigint']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['uuid']['input']>;
  zone_uid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type DiscussionSumFields = {
  __typename?: 'discussion_sum_fields';
  author_id?: Maybe<Scalars['Int']['output']>;
  heat_map_id?: Maybe<Scalars['Int']['output']>;
  measurement_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "discussion" */
export type DiscussionSumOrderBy = {
  author_id?: InputMaybe<OrderBy>;
  heat_map_id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
};

/** update columns of table "discussion" */
export enum DiscussionUpdateColumn {
  /** column name */
  annotation_type = 'annotation_type',
  /** column name */
  area = 'area',
  /** column name */
  author_id = 'author_id',
  /** column name */
  author_organization_code = 'author_organization_code',
  /** column name */
  category = 'category',
  /** column name */
  created_at = 'created_at',
  /** column name */
  end_time = 'end_time',
  /** column name */
  heat_map_aggregate_uid = 'heat_map_aggregate_uid',
  /** column name */
  heat_map_id = 'heat_map_id',
  /** column name */
  last_updated_at = 'last_updated_at',
  /** column name */
  measurement_id = 'measurement_id',
  /** column name */
  start_time = 'start_time',
  /** column name */
  status = 'status',
  /** column name */
  type = 'type',
  /** column name */
  uid = 'uid',
  /** column name */
  zone_uid = 'zone_uid'
}

export type DiscussionUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DiscussionAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<DiscussionDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<DiscussionDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<DiscussionDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DiscussionIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DiscussionPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DiscussionSetInput>;
  /** filter the rows which have to be updated */
  where: DiscussionBoolExp;
};

/** aggregate var_pop on columns */
export type DiscussionVarPopFields = {
  __typename?: 'discussion_var_pop_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
  heat_map_id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "discussion" */
export type DiscussionVarPopOrderBy = {
  author_id?: InputMaybe<OrderBy>;
  heat_map_id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type DiscussionVarSampFields = {
  __typename?: 'discussion_var_samp_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
  heat_map_id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "discussion" */
export type DiscussionVarSampOrderBy = {
  author_id?: InputMaybe<OrderBy>;
  heat_map_id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type DiscussionVarianceFields = {
  __typename?: 'discussion_variance_fields';
  author_id?: Maybe<Scalars['Float']['output']>;
  heat_map_id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "discussion" */
export type DiscussionVarianceOrderBy = {
  author_id?: InputMaybe<OrderBy>;
  heat_map_id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "enumeration" */
export type Enumeration = {
  __typename?: 'enumeration';
  /** An array relationship */
  addresses: Array<Address>;
  /** An array relationship */
  addressesByCountryCodeId: Array<Address>;
  /** An aggregate relationship */
  addressesByCountryCodeId_aggregate: AddressAggregate;
  /** An array relationship */
  addressesByRegionId: Array<Address>;
  /** An aggregate relationship */
  addressesByRegionId_aggregate: AddressAggregate;
  /** An aggregate relationship */
  addresses_aggregate: AddressAggregate;
  /** An array relationship */
  classifications: Array<Classification>;
  /** An aggregate relationship */
  classifications_aggregate: ClassificationAggregate;
  code: Scalars['String']['output'];
  /** An array relationship */
  computedMeasurementsByComputedMetricTypeId: Array<ComputedMeasurement>;
  /** An aggregate relationship */
  computedMeasurementsByComputedMetricTypeId_aggregate: ComputedMeasurementAggregate;
  /** An array relationship */
  computed_measurements: Array<ComputedMeasurement>;
  /** An aggregate relationship */
  computed_measurements_aggregate: ComputedMeasurementAggregate;
  /** An array relationship */
  computes: Array<Compute>;
  /** An aggregate relationship */
  computes_aggregate: ComputeAggregate;
  description: Scalars['String']['output'];
  /** An array relationship */
  detection_runs: Array<DetectionRun>;
  /** An aggregate relationship */
  detection_runs_aggregate: DetectionRunAggregate;
  /** An array relationship */
  environment_recipe_entrys: Array<EnvironmentRecipeEntry>;
  /** An aggregate relationship */
  environment_recipe_entrys_aggregate: EnvironmentRecipeEntryAggregate;
  /** An array relationship */
  heat_maps: Array<HeatMap>;
  /** An aggregate relationship */
  heat_maps_aggregate: HeatMapAggregate;
  id: Scalars['Int']['output'];
  /** An array relationship */
  labelTasksByPriorityId: Array<LabelTask>;
  /** An aggregate relationship */
  labelTasksByPriorityId_aggregate: LabelTaskAggregate;
  /** An array relationship */
  labelTasksByStatusId: Array<LabelTask>;
  /** An aggregate relationship */
  labelTasksByStatusId_aggregate: LabelTaskAggregate;
  /** An array relationship */
  label_categorys: Array<LabelCategory>;
  /** An aggregate relationship */
  label_categorys_aggregate: LabelCategoryAggregate;
  /** An array relationship */
  label_tasks: Array<LabelTask>;
  /** An aggregate relationship */
  label_tasks_aggregate: LabelTaskAggregate;
  /** An array relationship */
  labels: Array<Label>;
  /** An array relationship */
  labelsByLabelNameId: Array<Label>;
  /** An aggregate relationship */
  labelsByLabelNameId_aggregate: LabelAggregate;
  /** An array relationship */
  labelsByLabelTypeId: Array<Label>;
  /** An aggregate relationship */
  labelsByLabelTypeId_aggregate: LabelAggregate;
  /** An array relationship */
  labelsByStatusId: Array<Label>;
  /** An aggregate relationship */
  labelsByStatusId_aggregate: LabelAggregate;
  /** An aggregate relationship */
  labels_aggregate: LabelAggregate;
  /** An array relationship */
  locations: Array<Location>;
  /** An aggregate relationship */
  locations_aggregate: LocationAggregate;
  /** An array relationship */
  measurement_runs: Array<MeasurementRun>;
  /** An aggregate relationship */
  measurement_runs_aggregate: MeasurementRunAggregate;
  /** An array relationship */
  measurements: Array<Measurement>;
  /** An array relationship */
  measurementsByTypeId: Array<Measurement>;
  /** An aggregate relationship */
  measurementsByTypeId_aggregate: MeasurementAggregate;
  /** An aggregate relationship */
  measurements_aggregate: MeasurementAggregate;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  /** An array relationship */
  organizations: Array<Organization>;
  /** An aggregate relationship */
  organizations_aggregate: OrganizationAggregate;
  /** An array relationship */
  parameters: Array<Parameters>;
  /** An aggregate relationship */
  parameters_aggregate: ParametersAggregate;
  /** An array relationship */
  rolePermissionsByRoleId: Array<RolePermission>;
  /** An aggregate relationship */
  rolePermissionsByRoleId_aggregate: RolePermissionAggregate;
  /** An array relationship */
  role_permissions: Array<RolePermission>;
  /** An aggregate relationship */
  role_permissions_aggregate: RolePermissionAggregate;
  type: Scalars['String']['output'];
  /** An array relationship */
  user_labels: Array<UserLabel>;
  /** An aggregate relationship */
  user_labels_aggregate: UserLabelAggregate;
  /** An array relationship */
  user_organization_roles: Array<UserOrganizationRole>;
  /** An aggregate relationship */
  user_organization_roles_aggregate: UserOrganizationRoleAggregate;
  /** An array relationship */
  zones: Array<Zone>;
  /** An aggregate relationship */
  zones_aggregate: ZoneAggregate;
};


/** columns and relationships of "enumeration" */
export type EnumerationAddressesArgs = {
  distinct_on?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationAddressesByCountryCodeIdArgs = {
  distinct_on?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationAddressesByCountryCodeIdAggregateArgs = {
  distinct_on?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationAddressesByRegionIdArgs = {
  distinct_on?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationAddressesByRegionIdAggregateArgs = {
  distinct_on?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationAddressesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationClassificationsArgs = {
  distinct_on?: InputMaybe<Array<ClassificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ClassificationOrderBy>>;
  where?: InputMaybe<ClassificationBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationClassificationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ClassificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ClassificationOrderBy>>;
  where?: InputMaybe<ClassificationBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationComputedMeasurementsByComputedMetricTypeIdArgs = {
  distinct_on?: InputMaybe<Array<ComputedMeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputedMeasurementOrderBy>>;
  where?: InputMaybe<ComputedMeasurementBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationComputedMeasurementsByComputedMetricTypeIdAggregateArgs = {
  distinct_on?: InputMaybe<Array<ComputedMeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputedMeasurementOrderBy>>;
  where?: InputMaybe<ComputedMeasurementBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationComputedMeasurementsArgs = {
  distinct_on?: InputMaybe<Array<ComputedMeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputedMeasurementOrderBy>>;
  where?: InputMaybe<ComputedMeasurementBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationComputedMeasurementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ComputedMeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputedMeasurementOrderBy>>;
  where?: InputMaybe<ComputedMeasurementBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationComputesArgs = {
  distinct_on?: InputMaybe<Array<ComputeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputeOrderBy>>;
  where?: InputMaybe<ComputeBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationComputesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ComputeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputeOrderBy>>;
  where?: InputMaybe<ComputeBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationDetectionRunsArgs = {
  distinct_on?: InputMaybe<Array<DetectionRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectionRunOrderBy>>;
  where?: InputMaybe<DetectionRunBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationDetectionRunsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DetectionRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectionRunOrderBy>>;
  where?: InputMaybe<DetectionRunBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationEnvironmentRecipeEntrysArgs = {
  distinct_on?: InputMaybe<Array<EnvironmentRecipeEntrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EnvironmentRecipeEntryOrderBy>>;
  where?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationEnvironmentRecipeEntrysAggregateArgs = {
  distinct_on?: InputMaybe<Array<EnvironmentRecipeEntrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EnvironmentRecipeEntryOrderBy>>;
  where?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationHeatMapsArgs = {
  distinct_on?: InputMaybe<Array<HeatMapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeatMapOrderBy>>;
  where?: InputMaybe<HeatMapBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationHeatMapsAggregateArgs = {
  distinct_on?: InputMaybe<Array<HeatMapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeatMapOrderBy>>;
  where?: InputMaybe<HeatMapBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationLabelTasksByPriorityIdArgs = {
  distinct_on?: InputMaybe<Array<LabelTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelTaskOrderBy>>;
  where?: InputMaybe<LabelTaskBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationLabelTasksByPriorityIdAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelTaskOrderBy>>;
  where?: InputMaybe<LabelTaskBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationLabelTasksByStatusIdArgs = {
  distinct_on?: InputMaybe<Array<LabelTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelTaskOrderBy>>;
  where?: InputMaybe<LabelTaskBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationLabelTasksByStatusIdAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelTaskOrderBy>>;
  where?: InputMaybe<LabelTaskBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationLabelCategorysArgs = {
  distinct_on?: InputMaybe<Array<LabelCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelCategoryOrderBy>>;
  where?: InputMaybe<LabelCategoryBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationLabelCategorysAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelCategoryOrderBy>>;
  where?: InputMaybe<LabelCategoryBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationLabelTasksArgs = {
  distinct_on?: InputMaybe<Array<LabelTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelTaskOrderBy>>;
  where?: InputMaybe<LabelTaskBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationLabelTasksAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelTaskOrderBy>>;
  where?: InputMaybe<LabelTaskBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationLabelsArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationLabelsByLabelNameIdArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationLabelsByLabelNameIdAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationLabelsByLabelTypeIdArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationLabelsByLabelTypeIdAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationLabelsByStatusIdArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationLabelsByStatusIdAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationLabelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationLocationsArgs = {
  distinct_on?: InputMaybe<Array<LocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LocationOrderBy>>;
  where?: InputMaybe<LocationBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LocationOrderBy>>;
  where?: InputMaybe<LocationBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationMeasurementRunsArgs = {
  distinct_on?: InputMaybe<Array<MeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementRunOrderBy>>;
  where?: InputMaybe<MeasurementRunBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationMeasurementRunsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementRunOrderBy>>;
  where?: InputMaybe<MeasurementRunBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationMeasurementsArgs = {
  distinct_on?: InputMaybe<Array<MeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementOrderBy>>;
  where?: InputMaybe<MeasurementBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationMeasurementsByTypeIdArgs = {
  distinct_on?: InputMaybe<Array<MeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementOrderBy>>;
  where?: InputMaybe<MeasurementBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationMeasurementsByTypeIdAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementOrderBy>>;
  where?: InputMaybe<MeasurementBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationMeasurementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementOrderBy>>;
  where?: InputMaybe<MeasurementBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "enumeration" */
export type EnumerationOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationOrganizationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationParametersArgs = {
  distinct_on?: InputMaybe<Array<ParametersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ParametersOrderBy>>;
  where?: InputMaybe<ParametersBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationParametersAggregateArgs = {
  distinct_on?: InputMaybe<Array<ParametersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ParametersOrderBy>>;
  where?: InputMaybe<ParametersBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationRolePermissionsByRoleIdArgs = {
  distinct_on?: InputMaybe<Array<RolePermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RolePermissionOrderBy>>;
  where?: InputMaybe<RolePermissionBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationRolePermissionsByRoleIdAggregateArgs = {
  distinct_on?: InputMaybe<Array<RolePermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RolePermissionOrderBy>>;
  where?: InputMaybe<RolePermissionBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationRolePermissionsArgs = {
  distinct_on?: InputMaybe<Array<RolePermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RolePermissionOrderBy>>;
  where?: InputMaybe<RolePermissionBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationRolePermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RolePermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RolePermissionOrderBy>>;
  where?: InputMaybe<RolePermissionBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationUserLabelsArgs = {
  distinct_on?: InputMaybe<Array<UserLabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserLabelOrderBy>>;
  where?: InputMaybe<UserLabelBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationUserLabelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserLabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserLabelOrderBy>>;
  where?: InputMaybe<UserLabelBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationUserOrganizationRolesArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizationRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrganizationRoleOrderBy>>;
  where?: InputMaybe<UserOrganizationRoleBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationUserOrganizationRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizationRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrganizationRoleOrderBy>>;
  where?: InputMaybe<UserOrganizationRoleBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationZonesArgs = {
  distinct_on?: InputMaybe<Array<ZoneSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ZoneOrderBy>>;
  where?: InputMaybe<ZoneBoolExp>;
};


/** columns and relationships of "enumeration" */
export type EnumerationZonesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoneSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ZoneOrderBy>>;
  where?: InputMaybe<ZoneBoolExp>;
};

/** aggregated selection of "enumeration" */
export type EnumerationAggregate = {
  __typename?: 'enumeration_aggregate';
  aggregate?: Maybe<EnumerationAggregateFields>;
  nodes: Array<Enumeration>;
};

/** aggregate fields of "enumeration" */
export type EnumerationAggregateFields = {
  __typename?: 'enumeration_aggregate_fields';
  avg?: Maybe<EnumerationAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EnumerationMaxFields>;
  min?: Maybe<EnumerationMinFields>;
  stddev?: Maybe<EnumerationStddevFields>;
  stddev_pop?: Maybe<EnumerationStddevPopFields>;
  stddev_samp?: Maybe<EnumerationStddevSampFields>;
  sum?: Maybe<EnumerationSumFields>;
  var_pop?: Maybe<EnumerationVarPopFields>;
  var_samp?: Maybe<EnumerationVarSampFields>;
  variance?: Maybe<EnumerationVarianceFields>;
};


/** aggregate fields of "enumeration" */
export type EnumerationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EnumerationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type EnumerationAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type EnumerationAvgFields = {
  __typename?: 'enumeration_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "enumeration". All fields are combined with a logical 'AND'. */
export type EnumerationBoolExp = {
  _and?: InputMaybe<Array<EnumerationBoolExp>>;
  _not?: InputMaybe<EnumerationBoolExp>;
  _or?: InputMaybe<Array<EnumerationBoolExp>>;
  addresses?: InputMaybe<AddressBoolExp>;
  addressesByCountryCodeId?: InputMaybe<AddressBoolExp>;
  addressesByCountryCodeId_aggregate?: InputMaybe<AddressAggregateBoolExp>;
  addressesByRegionId?: InputMaybe<AddressBoolExp>;
  addressesByRegionId_aggregate?: InputMaybe<AddressAggregateBoolExp>;
  addresses_aggregate?: InputMaybe<AddressAggregateBoolExp>;
  classifications?: InputMaybe<ClassificationBoolExp>;
  classifications_aggregate?: InputMaybe<ClassificationAggregateBoolExp>;
  code?: InputMaybe<StringComparisonExp>;
  computedMeasurementsByComputedMetricTypeId?: InputMaybe<ComputedMeasurementBoolExp>;
  computedMeasurementsByComputedMetricTypeId_aggregate?: InputMaybe<ComputedMeasurementAggregateBoolExp>;
  computed_measurements?: InputMaybe<ComputedMeasurementBoolExp>;
  computed_measurements_aggregate?: InputMaybe<ComputedMeasurementAggregateBoolExp>;
  computes?: InputMaybe<ComputeBoolExp>;
  computes_aggregate?: InputMaybe<ComputeAggregateBoolExp>;
  description?: InputMaybe<StringComparisonExp>;
  detection_runs?: InputMaybe<DetectionRunBoolExp>;
  detection_runs_aggregate?: InputMaybe<DetectionRunAggregateBoolExp>;
  environment_recipe_entrys?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
  environment_recipe_entrys_aggregate?: InputMaybe<EnvironmentRecipeEntryAggregateBoolExp>;
  heat_maps?: InputMaybe<HeatMapBoolExp>;
  heat_maps_aggregate?: InputMaybe<HeatMapAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  labelTasksByPriorityId?: InputMaybe<LabelTaskBoolExp>;
  labelTasksByPriorityId_aggregate?: InputMaybe<LabelTaskAggregateBoolExp>;
  labelTasksByStatusId?: InputMaybe<LabelTaskBoolExp>;
  labelTasksByStatusId_aggregate?: InputMaybe<LabelTaskAggregateBoolExp>;
  label_categorys?: InputMaybe<LabelCategoryBoolExp>;
  label_categorys_aggregate?: InputMaybe<LabelCategoryAggregateBoolExp>;
  label_tasks?: InputMaybe<LabelTaskBoolExp>;
  label_tasks_aggregate?: InputMaybe<LabelTaskAggregateBoolExp>;
  labels?: InputMaybe<LabelBoolExp>;
  labelsByLabelNameId?: InputMaybe<LabelBoolExp>;
  labelsByLabelNameId_aggregate?: InputMaybe<LabelAggregateBoolExp>;
  labelsByLabelTypeId?: InputMaybe<LabelBoolExp>;
  labelsByLabelTypeId_aggregate?: InputMaybe<LabelAggregateBoolExp>;
  labelsByStatusId?: InputMaybe<LabelBoolExp>;
  labelsByStatusId_aggregate?: InputMaybe<LabelAggregateBoolExp>;
  labels_aggregate?: InputMaybe<LabelAggregateBoolExp>;
  locations?: InputMaybe<LocationBoolExp>;
  locations_aggregate?: InputMaybe<LocationAggregateBoolExp>;
  measurement_runs?: InputMaybe<MeasurementRunBoolExp>;
  measurement_runs_aggregate?: InputMaybe<MeasurementRunAggregateBoolExp>;
  measurements?: InputMaybe<MeasurementBoolExp>;
  measurementsByTypeId?: InputMaybe<MeasurementBoolExp>;
  measurementsByTypeId_aggregate?: InputMaybe<MeasurementAggregateBoolExp>;
  measurements_aggregate?: InputMaybe<MeasurementAggregateBoolExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  organizations?: InputMaybe<OrganizationBoolExp>;
  organizations_aggregate?: InputMaybe<OrganizationAggregateBoolExp>;
  parameters?: InputMaybe<ParametersBoolExp>;
  parameters_aggregate?: InputMaybe<ParametersAggregateBoolExp>;
  rolePermissionsByRoleId?: InputMaybe<RolePermissionBoolExp>;
  rolePermissionsByRoleId_aggregate?: InputMaybe<RolePermissionAggregateBoolExp>;
  role_permissions?: InputMaybe<RolePermissionBoolExp>;
  role_permissions_aggregate?: InputMaybe<RolePermissionAggregateBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
  user_labels?: InputMaybe<UserLabelBoolExp>;
  user_labels_aggregate?: InputMaybe<UserLabelAggregateBoolExp>;
  user_organization_roles?: InputMaybe<UserOrganizationRoleBoolExp>;
  user_organization_roles_aggregate?: InputMaybe<UserOrganizationRoleAggregateBoolExp>;
  zones?: InputMaybe<ZoneBoolExp>;
  zones_aggregate?: InputMaybe<ZoneAggregateBoolExp>;
};

/** unique or primary key constraints on table "enumeration" */
export enum EnumerationConstraint {
  /** unique or primary key constraint on columns "id" */
  enumeration_pkey = 'enumeration_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type EnumerationDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type EnumerationDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type EnumerationDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "enumeration" */
export type EnumerationIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "enumeration" */
export type EnumerationInsertInput = {
  addresses?: InputMaybe<AddressArrRelInsertInput>;
  addressesByCountryCodeId?: InputMaybe<AddressArrRelInsertInput>;
  addressesByRegionId?: InputMaybe<AddressArrRelInsertInput>;
  classifications?: InputMaybe<ClassificationArrRelInsertInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  computedMeasurementsByComputedMetricTypeId?: InputMaybe<ComputedMeasurementArrRelInsertInput>;
  computed_measurements?: InputMaybe<ComputedMeasurementArrRelInsertInput>;
  computes?: InputMaybe<ComputeArrRelInsertInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  detection_runs?: InputMaybe<DetectionRunArrRelInsertInput>;
  environment_recipe_entrys?: InputMaybe<EnvironmentRecipeEntryArrRelInsertInput>;
  heat_maps?: InputMaybe<HeatMapArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  labelTasksByPriorityId?: InputMaybe<LabelTaskArrRelInsertInput>;
  labelTasksByStatusId?: InputMaybe<LabelTaskArrRelInsertInput>;
  label_categorys?: InputMaybe<LabelCategoryArrRelInsertInput>;
  label_tasks?: InputMaybe<LabelTaskArrRelInsertInput>;
  labels?: InputMaybe<LabelArrRelInsertInput>;
  labelsByLabelNameId?: InputMaybe<LabelArrRelInsertInput>;
  labelsByLabelTypeId?: InputMaybe<LabelArrRelInsertInput>;
  labelsByStatusId?: InputMaybe<LabelArrRelInsertInput>;
  locations?: InputMaybe<LocationArrRelInsertInput>;
  measurement_runs?: InputMaybe<MeasurementRunArrRelInsertInput>;
  measurements?: InputMaybe<MeasurementArrRelInsertInput>;
  measurementsByTypeId?: InputMaybe<MeasurementArrRelInsertInput>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  organizations?: InputMaybe<OrganizationArrRelInsertInput>;
  parameters?: InputMaybe<ParametersArrRelInsertInput>;
  rolePermissionsByRoleId?: InputMaybe<RolePermissionArrRelInsertInput>;
  role_permissions?: InputMaybe<RolePermissionArrRelInsertInput>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_labels?: InputMaybe<UserLabelArrRelInsertInput>;
  user_organization_roles?: InputMaybe<UserOrganizationRoleArrRelInsertInput>;
  zones?: InputMaybe<ZoneArrRelInsertInput>;
};

/** aggregate max on columns */
export type EnumerationMaxFields = {
  __typename?: 'enumeration_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type EnumerationMinFields = {
  __typename?: 'enumeration_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "enumeration" */
export type EnumerationMutationResponse = {
  __typename?: 'enumeration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enumeration>;
};

/** input type for inserting object relation for remote table "enumeration" */
export type EnumerationObjRelInsertInput = {
  data: EnumerationInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EnumerationOnConflict>;
};

/** on_conflict condition type for table "enumeration" */
export type EnumerationOnConflict = {
  constraint: EnumerationConstraint;
  update_columns?: Array<EnumerationUpdateColumn>;
  where?: InputMaybe<EnumerationBoolExp>;
};

/** Ordering options when selecting data from "enumeration". */
export type EnumerationOrderBy = {
  addressesByCountryCodeId_aggregate?: InputMaybe<AddressAggregateOrderBy>;
  addressesByRegionId_aggregate?: InputMaybe<AddressAggregateOrderBy>;
  addresses_aggregate?: InputMaybe<AddressAggregateOrderBy>;
  classifications_aggregate?: InputMaybe<ClassificationAggregateOrderBy>;
  code?: InputMaybe<OrderBy>;
  computedMeasurementsByComputedMetricTypeId_aggregate?: InputMaybe<ComputedMeasurementAggregateOrderBy>;
  computed_measurements_aggregate?: InputMaybe<ComputedMeasurementAggregateOrderBy>;
  computes_aggregate?: InputMaybe<ComputeAggregateOrderBy>;
  description?: InputMaybe<OrderBy>;
  detection_runs_aggregate?: InputMaybe<DetectionRunAggregateOrderBy>;
  environment_recipe_entrys_aggregate?: InputMaybe<EnvironmentRecipeEntryAggregateOrderBy>;
  heat_maps_aggregate?: InputMaybe<HeatMapAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  labelTasksByPriorityId_aggregate?: InputMaybe<LabelTaskAggregateOrderBy>;
  labelTasksByStatusId_aggregate?: InputMaybe<LabelTaskAggregateOrderBy>;
  label_categorys_aggregate?: InputMaybe<LabelCategoryAggregateOrderBy>;
  label_tasks_aggregate?: InputMaybe<LabelTaskAggregateOrderBy>;
  labelsByLabelNameId_aggregate?: InputMaybe<LabelAggregateOrderBy>;
  labelsByLabelTypeId_aggregate?: InputMaybe<LabelAggregateOrderBy>;
  labelsByStatusId_aggregate?: InputMaybe<LabelAggregateOrderBy>;
  labels_aggregate?: InputMaybe<LabelAggregateOrderBy>;
  locations_aggregate?: InputMaybe<LocationAggregateOrderBy>;
  measurement_runs_aggregate?: InputMaybe<MeasurementRunAggregateOrderBy>;
  measurementsByTypeId_aggregate?: InputMaybe<MeasurementAggregateOrderBy>;
  measurements_aggregate?: InputMaybe<MeasurementAggregateOrderBy>;
  metadata?: InputMaybe<OrderBy>;
  organizations_aggregate?: InputMaybe<OrganizationAggregateOrderBy>;
  parameters_aggregate?: InputMaybe<ParametersAggregateOrderBy>;
  rolePermissionsByRoleId_aggregate?: InputMaybe<RolePermissionAggregateOrderBy>;
  role_permissions_aggregate?: InputMaybe<RolePermissionAggregateOrderBy>;
  type?: InputMaybe<OrderBy>;
  user_labels_aggregate?: InputMaybe<UserLabelAggregateOrderBy>;
  user_organization_roles_aggregate?: InputMaybe<UserOrganizationRoleAggregateOrderBy>;
  zones_aggregate?: InputMaybe<ZoneAggregateOrderBy>;
};

/** primary key columns input for table: enumeration */
export type EnumerationPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type EnumerationPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "enumeration" */
export enum EnumerationSelectColumn {
  /** column name */
  code = 'code',
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  type = 'type'
}

/** input type for updating data in table "enumeration" */
export type EnumerationSetInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type EnumerationStddevFields = {
  __typename?: 'enumeration_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type EnumerationStddevPopFields = {
  __typename?: 'enumeration_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type EnumerationStddevSampFields = {
  __typename?: 'enumeration_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "enumeration" */
export type EnumerationStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: EnumerationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnumerationStreamCursorValueInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type EnumerationSumFields = {
  __typename?: 'enumeration_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "enumeration" */
export enum EnumerationUpdateColumn {
  /** column name */
  code = 'code',
  /** column name */
  description = 'description',
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  type = 'type'
}

export type EnumerationUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<EnumerationAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<EnumerationDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<EnumerationDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<EnumerationDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EnumerationIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<EnumerationPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EnumerationSetInput>;
  /** filter the rows which have to be updated */
  where: EnumerationBoolExp;
};

/** aggregate var_pop on columns */
export type EnumerationVarPopFields = {
  __typename?: 'enumeration_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type EnumerationVarSampFields = {
  __typename?: 'enumeration_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type EnumerationVarianceFields = {
  __typename?: 'enumeration_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "environment_recipe" */
export type EnvironmentRecipe = {
  __typename?: 'environment_recipe';
  /** An array relationship */
  environment_recipe_entrys: Array<EnvironmentRecipeEntry>;
  /** An aggregate relationship */
  environment_recipe_entrys_aggregate: EnvironmentRecipeEntryAggregate;
  /** An array relationship */
  growth_cycles: Array<GrowthCycle>;
  /** An aggregate relationship */
  growth_cycles_aggregate: GrowthCycleAggregate;
  id: Scalars['Int']['output'];
  is_active: Scalars['Boolean']['output'];
  last_updated: Scalars['timestamptz']['output'];
  /** An object relationship */
  location?: Maybe<Location>;
  location_id?: Maybe<Scalars['Int']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  name: Scalars['String']['output'];
  /** An object relationship */
  organization?: Maybe<Organization>;
  organization_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "environment_recipe" */
export type EnvironmentRecipeEnvironmentRecipeEntrysArgs = {
  distinct_on?: InputMaybe<Array<EnvironmentRecipeEntrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EnvironmentRecipeEntryOrderBy>>;
  where?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
};


/** columns and relationships of "environment_recipe" */
export type EnvironmentRecipeEnvironmentRecipeEntrysAggregateArgs = {
  distinct_on?: InputMaybe<Array<EnvironmentRecipeEntrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EnvironmentRecipeEntryOrderBy>>;
  where?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
};


/** columns and relationships of "environment_recipe" */
export type EnvironmentRecipeGrowthCyclesArgs = {
  distinct_on?: InputMaybe<Array<GrowthCycleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GrowthCycleOrderBy>>;
  where?: InputMaybe<GrowthCycleBoolExp>;
};


/** columns and relationships of "environment_recipe" */
export type EnvironmentRecipeGrowthCyclesAggregateArgs = {
  distinct_on?: InputMaybe<Array<GrowthCycleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GrowthCycleOrderBy>>;
  where?: InputMaybe<GrowthCycleBoolExp>;
};


/** columns and relationships of "environment_recipe" */
export type EnvironmentRecipeMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "environment_recipe" */
export type EnvironmentRecipeAggregate = {
  __typename?: 'environment_recipe_aggregate';
  aggregate?: Maybe<EnvironmentRecipeAggregateFields>;
  nodes: Array<EnvironmentRecipe>;
};

export type EnvironmentRecipeAggregateBoolExp = {
  bool_and?: InputMaybe<EnvironmentRecipeAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EnvironmentRecipeAggregateBoolExpBoolOr>;
  count?: InputMaybe<EnvironmentRecipeAggregateBoolExpCount>;
};

export type EnvironmentRecipeAggregateBoolExpBoolAnd = {
  arguments: EnvironmentRecipeSelectColumnEnvironmentRecipeAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EnvironmentRecipeBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EnvironmentRecipeAggregateBoolExpBoolOr = {
  arguments: EnvironmentRecipeSelectColumnEnvironmentRecipeAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EnvironmentRecipeBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EnvironmentRecipeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EnvironmentRecipeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EnvironmentRecipeBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "environment_recipe" */
export type EnvironmentRecipeAggregateFields = {
  __typename?: 'environment_recipe_aggregate_fields';
  avg?: Maybe<EnvironmentRecipeAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EnvironmentRecipeMaxFields>;
  min?: Maybe<EnvironmentRecipeMinFields>;
  stddev?: Maybe<EnvironmentRecipeStddevFields>;
  stddev_pop?: Maybe<EnvironmentRecipeStddevPopFields>;
  stddev_samp?: Maybe<EnvironmentRecipeStddevSampFields>;
  sum?: Maybe<EnvironmentRecipeSumFields>;
  var_pop?: Maybe<EnvironmentRecipeVarPopFields>;
  var_samp?: Maybe<EnvironmentRecipeVarSampFields>;
  variance?: Maybe<EnvironmentRecipeVarianceFields>;
};


/** aggregate fields of "environment_recipe" */
export type EnvironmentRecipeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EnvironmentRecipeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "environment_recipe" */
export type EnvironmentRecipeAggregateOrderBy = {
  avg?: InputMaybe<EnvironmentRecipeAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EnvironmentRecipeMaxOrderBy>;
  min?: InputMaybe<EnvironmentRecipeMinOrderBy>;
  stddev?: InputMaybe<EnvironmentRecipeStddevOrderBy>;
  stddev_pop?: InputMaybe<EnvironmentRecipeStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EnvironmentRecipeStddevSampOrderBy>;
  sum?: InputMaybe<EnvironmentRecipeSumOrderBy>;
  var_pop?: InputMaybe<EnvironmentRecipeVarPopOrderBy>;
  var_samp?: InputMaybe<EnvironmentRecipeVarSampOrderBy>;
  variance?: InputMaybe<EnvironmentRecipeVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type EnvironmentRecipeAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "environment_recipe" */
export type EnvironmentRecipeArrRelInsertInput = {
  data: Array<EnvironmentRecipeInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EnvironmentRecipeOnConflict>;
};

/** aggregate avg on columns */
export type EnvironmentRecipeAvgFields = {
  __typename?: 'environment_recipe_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "environment_recipe" */
export type EnvironmentRecipeAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "environment_recipe". All fields are combined with a logical 'AND'. */
export type EnvironmentRecipeBoolExp = {
  _and?: InputMaybe<Array<EnvironmentRecipeBoolExp>>;
  _not?: InputMaybe<EnvironmentRecipeBoolExp>;
  _or?: InputMaybe<Array<EnvironmentRecipeBoolExp>>;
  environment_recipe_entrys?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
  environment_recipe_entrys_aggregate?: InputMaybe<EnvironmentRecipeEntryAggregateBoolExp>;
  growth_cycles?: InputMaybe<GrowthCycleBoolExp>;
  growth_cycles_aggregate?: InputMaybe<GrowthCycleAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  is_active?: InputMaybe<BooleanComparisonExp>;
  last_updated?: InputMaybe<TimestamptzComparisonExp>;
  location?: InputMaybe<LocationBoolExp>;
  location_id?: InputMaybe<IntComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organization_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "environment_recipe" */
export enum EnvironmentRecipeConstraint {
  /** unique or primary key constraint on columns "id" */
  environment_recipe_pkey = 'environment_recipe_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type EnvironmentRecipeDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type EnvironmentRecipeDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type EnvironmentRecipeDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "environment_recipe_entry" */
export type EnvironmentRecipeEntry = {
  __typename?: 'environment_recipe_entry';
  critical_lower?: Maybe<Scalars['float8']['output']>;
  critical_upper?: Maybe<Scalars['float8']['output']>;
  day: Scalars['Int']['output'];
  /** An object relationship */
  enumeration: Enumeration;
  /** An object relationship */
  environment_recipe: EnvironmentRecipe;
  id: Scalars['Int']['output'];
  ideal_lower?: Maybe<Scalars['float8']['output']>;
  ideal_upper?: Maybe<Scalars['float8']['output']>;
  lights_on: Scalars['Boolean']['output'];
  recipe_id: Scalars['Int']['output'];
  set_point?: Maybe<Scalars['float8']['output']>;
  type_id: Scalars['Int']['output'];
};

/** aggregated selection of "environment_recipe_entry" */
export type EnvironmentRecipeEntryAggregate = {
  __typename?: 'environment_recipe_entry_aggregate';
  aggregate?: Maybe<EnvironmentRecipeEntryAggregateFields>;
  nodes: Array<EnvironmentRecipeEntry>;
};

export type EnvironmentRecipeEntryAggregateBoolExp = {
  avg?: InputMaybe<EnvironmentRecipeEntryAggregateBoolExpAvg>;
  bool_and?: InputMaybe<EnvironmentRecipeEntryAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<EnvironmentRecipeEntryAggregateBoolExpBoolOr>;
  corr?: InputMaybe<EnvironmentRecipeEntryAggregateBoolExpCorr>;
  count?: InputMaybe<EnvironmentRecipeEntryAggregateBoolExpCount>;
  covar_samp?: InputMaybe<EnvironmentRecipeEntryAggregateBoolExpCovarSamp>;
  max?: InputMaybe<EnvironmentRecipeEntryAggregateBoolExpMax>;
  min?: InputMaybe<EnvironmentRecipeEntryAggregateBoolExpMin>;
  stddev_samp?: InputMaybe<EnvironmentRecipeEntryAggregateBoolExpStddevSamp>;
  sum?: InputMaybe<EnvironmentRecipeEntryAggregateBoolExpSum>;
  var_samp?: InputMaybe<EnvironmentRecipeEntryAggregateBoolExpVarSamp>;
};

export type EnvironmentRecipeEntryAggregateBoolExpAvg = {
  arguments: EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpAvgArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
  predicate: Float8ComparisonExp;
};

export type EnvironmentRecipeEntryAggregateBoolExpBoolAnd = {
  arguments: EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EnvironmentRecipeEntryAggregateBoolExpBoolOr = {
  arguments: EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
  predicate: BooleanComparisonExp;
};

export type EnvironmentRecipeEntryAggregateBoolExpCorr = {
  arguments: EnvironmentRecipeEntryAggregateBoolExpCorrArguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
  predicate: Float8ComparisonExp;
};

export type EnvironmentRecipeEntryAggregateBoolExpCorrArguments = {
  X: EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpCorrArgumentsColumns;
  Y: EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpCorrArgumentsColumns;
};

export type EnvironmentRecipeEntryAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<EnvironmentRecipeEntrySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
  predicate: IntComparisonExp;
};

export type EnvironmentRecipeEntryAggregateBoolExpCovarSamp = {
  arguments: EnvironmentRecipeEntryAggregateBoolExpCovarSampArguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
  predicate: Float8ComparisonExp;
};

export type EnvironmentRecipeEntryAggregateBoolExpCovarSampArguments = {
  X: EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpCovarSampArgumentsColumns;
  Y: EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpCovarSampArgumentsColumns;
};

export type EnvironmentRecipeEntryAggregateBoolExpMax = {
  arguments: EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpMaxArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
  predicate: Float8ComparisonExp;
};

export type EnvironmentRecipeEntryAggregateBoolExpMin = {
  arguments: EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpMinArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
  predicate: Float8ComparisonExp;
};

export type EnvironmentRecipeEntryAggregateBoolExpStddevSamp = {
  arguments: EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpStddevSampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
  predicate: Float8ComparisonExp;
};

export type EnvironmentRecipeEntryAggregateBoolExpSum = {
  arguments: EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpSumArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
  predicate: Float8ComparisonExp;
};

export type EnvironmentRecipeEntryAggregateBoolExpVarSamp = {
  arguments: EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpVarSampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
  predicate: Float8ComparisonExp;
};

/** aggregate fields of "environment_recipe_entry" */
export type EnvironmentRecipeEntryAggregateFields = {
  __typename?: 'environment_recipe_entry_aggregate_fields';
  avg?: Maybe<EnvironmentRecipeEntryAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<EnvironmentRecipeEntryMaxFields>;
  min?: Maybe<EnvironmentRecipeEntryMinFields>;
  stddev?: Maybe<EnvironmentRecipeEntryStddevFields>;
  stddev_pop?: Maybe<EnvironmentRecipeEntryStddevPopFields>;
  stddev_samp?: Maybe<EnvironmentRecipeEntryStddevSampFields>;
  sum?: Maybe<EnvironmentRecipeEntrySumFields>;
  var_pop?: Maybe<EnvironmentRecipeEntryVarPopFields>;
  var_samp?: Maybe<EnvironmentRecipeEntryVarSampFields>;
  variance?: Maybe<EnvironmentRecipeEntryVarianceFields>;
};


/** aggregate fields of "environment_recipe_entry" */
export type EnvironmentRecipeEntryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<EnvironmentRecipeEntrySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "environment_recipe_entry" */
export type EnvironmentRecipeEntryAggregateOrderBy = {
  avg?: InputMaybe<EnvironmentRecipeEntryAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<EnvironmentRecipeEntryMaxOrderBy>;
  min?: InputMaybe<EnvironmentRecipeEntryMinOrderBy>;
  stddev?: InputMaybe<EnvironmentRecipeEntryStddevOrderBy>;
  stddev_pop?: InputMaybe<EnvironmentRecipeEntryStddevPopOrderBy>;
  stddev_samp?: InputMaybe<EnvironmentRecipeEntryStddevSampOrderBy>;
  sum?: InputMaybe<EnvironmentRecipeEntrySumOrderBy>;
  var_pop?: InputMaybe<EnvironmentRecipeEntryVarPopOrderBy>;
  var_samp?: InputMaybe<EnvironmentRecipeEntryVarSampOrderBy>;
  variance?: InputMaybe<EnvironmentRecipeEntryVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "environment_recipe_entry" */
export type EnvironmentRecipeEntryArrRelInsertInput = {
  data: Array<EnvironmentRecipeEntryInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<EnvironmentRecipeEntryOnConflict>;
};

/** aggregate avg on columns */
export type EnvironmentRecipeEntryAvgFields = {
  __typename?: 'environment_recipe_entry_avg_fields';
  critical_lower?: Maybe<Scalars['Float']['output']>;
  critical_upper?: Maybe<Scalars['Float']['output']>;
  day?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  ideal_lower?: Maybe<Scalars['Float']['output']>;
  ideal_upper?: Maybe<Scalars['Float']['output']>;
  recipe_id?: Maybe<Scalars['Float']['output']>;
  set_point?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "environment_recipe_entry" */
export type EnvironmentRecipeEntryAvgOrderBy = {
  critical_lower?: InputMaybe<OrderBy>;
  critical_upper?: InputMaybe<OrderBy>;
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ideal_lower?: InputMaybe<OrderBy>;
  ideal_upper?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
  set_point?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "environment_recipe_entry". All fields are combined with a logical 'AND'. */
export type EnvironmentRecipeEntryBoolExp = {
  _and?: InputMaybe<Array<EnvironmentRecipeEntryBoolExp>>;
  _not?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
  _or?: InputMaybe<Array<EnvironmentRecipeEntryBoolExp>>;
  critical_lower?: InputMaybe<Float8ComparisonExp>;
  critical_upper?: InputMaybe<Float8ComparisonExp>;
  day?: InputMaybe<IntComparisonExp>;
  enumeration?: InputMaybe<EnumerationBoolExp>;
  environment_recipe?: InputMaybe<EnvironmentRecipeBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  ideal_lower?: InputMaybe<Float8ComparisonExp>;
  ideal_upper?: InputMaybe<Float8ComparisonExp>;
  lights_on?: InputMaybe<BooleanComparisonExp>;
  recipe_id?: InputMaybe<IntComparisonExp>;
  set_point?: InputMaybe<Float8ComparisonExp>;
  type_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "environment_recipe_entry" */
export enum EnvironmentRecipeEntryConstraint {
  /** unique or primary key constraint on columns "id" */
  environment_recipe_entry_pkey = 'environment_recipe_entry_pkey'
}

/** input type for incrementing numeric columns in table "environment_recipe_entry" */
export type EnvironmentRecipeEntryIncInput = {
  critical_lower?: InputMaybe<Scalars['float8']['input']>;
  critical_upper?: InputMaybe<Scalars['float8']['input']>;
  day?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  ideal_lower?: InputMaybe<Scalars['float8']['input']>;
  ideal_upper?: InputMaybe<Scalars['float8']['input']>;
  recipe_id?: InputMaybe<Scalars['Int']['input']>;
  set_point?: InputMaybe<Scalars['float8']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "environment_recipe_entry" */
export type EnvironmentRecipeEntryInsertInput = {
  critical_lower?: InputMaybe<Scalars['float8']['input']>;
  critical_upper?: InputMaybe<Scalars['float8']['input']>;
  day?: InputMaybe<Scalars['Int']['input']>;
  enumeration?: InputMaybe<EnumerationObjRelInsertInput>;
  environment_recipe?: InputMaybe<EnvironmentRecipeObjRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  ideal_lower?: InputMaybe<Scalars['float8']['input']>;
  ideal_upper?: InputMaybe<Scalars['float8']['input']>;
  lights_on?: InputMaybe<Scalars['Boolean']['input']>;
  recipe_id?: InputMaybe<Scalars['Int']['input']>;
  set_point?: InputMaybe<Scalars['float8']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type EnvironmentRecipeEntryMaxFields = {
  __typename?: 'environment_recipe_entry_max_fields';
  critical_lower?: Maybe<Scalars['float8']['output']>;
  critical_upper?: Maybe<Scalars['float8']['output']>;
  day?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  ideal_lower?: Maybe<Scalars['float8']['output']>;
  ideal_upper?: Maybe<Scalars['float8']['output']>;
  recipe_id?: Maybe<Scalars['Int']['output']>;
  set_point?: Maybe<Scalars['float8']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "environment_recipe_entry" */
export type EnvironmentRecipeEntryMaxOrderBy = {
  critical_lower?: InputMaybe<OrderBy>;
  critical_upper?: InputMaybe<OrderBy>;
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ideal_lower?: InputMaybe<OrderBy>;
  ideal_upper?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
  set_point?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EnvironmentRecipeEntryMinFields = {
  __typename?: 'environment_recipe_entry_min_fields';
  critical_lower?: Maybe<Scalars['float8']['output']>;
  critical_upper?: Maybe<Scalars['float8']['output']>;
  day?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  ideal_lower?: Maybe<Scalars['float8']['output']>;
  ideal_upper?: Maybe<Scalars['float8']['output']>;
  recipe_id?: Maybe<Scalars['Int']['output']>;
  set_point?: Maybe<Scalars['float8']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "environment_recipe_entry" */
export type EnvironmentRecipeEntryMinOrderBy = {
  critical_lower?: InputMaybe<OrderBy>;
  critical_upper?: InputMaybe<OrderBy>;
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ideal_lower?: InputMaybe<OrderBy>;
  ideal_upper?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
  set_point?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "environment_recipe_entry" */
export type EnvironmentRecipeEntryMutationResponse = {
  __typename?: 'environment_recipe_entry_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<EnvironmentRecipeEntry>;
};

/** on_conflict condition type for table "environment_recipe_entry" */
export type EnvironmentRecipeEntryOnConflict = {
  constraint: EnvironmentRecipeEntryConstraint;
  update_columns?: Array<EnvironmentRecipeEntryUpdateColumn>;
  where?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
};

/** Ordering options when selecting data from "environment_recipe_entry". */
export type EnvironmentRecipeEntryOrderBy = {
  critical_lower?: InputMaybe<OrderBy>;
  critical_upper?: InputMaybe<OrderBy>;
  day?: InputMaybe<OrderBy>;
  enumeration?: InputMaybe<EnumerationOrderBy>;
  environment_recipe?: InputMaybe<EnvironmentRecipeOrderBy>;
  id?: InputMaybe<OrderBy>;
  ideal_lower?: InputMaybe<OrderBy>;
  ideal_upper?: InputMaybe<OrderBy>;
  lights_on?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
  set_point?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: environment_recipe_entry */
export type EnvironmentRecipeEntryPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** select columns of table "environment_recipe_entry" */
export enum EnvironmentRecipeEntrySelectColumn {
  /** column name */
  critical_lower = 'critical_lower',
  /** column name */
  critical_upper = 'critical_upper',
  /** column name */
  day = 'day',
  /** column name */
  id = 'id',
  /** column name */
  ideal_lower = 'ideal_lower',
  /** column name */
  ideal_upper = 'ideal_upper',
  /** column name */
  lights_on = 'lights_on',
  /** column name */
  recipe_id = 'recipe_id',
  /** column name */
  set_point = 'set_point',
  /** column name */
  type_id = 'type_id'
}

/** select "environment_recipe_entry_aggregate_bool_exp_avg_arguments_columns" columns of table "environment_recipe_entry" */
export enum EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpAvgArgumentsColumns {
  /** column name */
  critical_lower = 'critical_lower',
  /** column name */
  critical_upper = 'critical_upper',
  /** column name */
  ideal_lower = 'ideal_lower',
  /** column name */
  ideal_upper = 'ideal_upper',
  /** column name */
  set_point = 'set_point'
}

/** select "environment_recipe_entry_aggregate_bool_exp_bool_and_arguments_columns" columns of table "environment_recipe_entry" */
export enum EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  lights_on = 'lights_on'
}

/** select "environment_recipe_entry_aggregate_bool_exp_bool_or_arguments_columns" columns of table "environment_recipe_entry" */
export enum EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  lights_on = 'lights_on'
}

/** select "environment_recipe_entry_aggregate_bool_exp_corr_arguments_columns" columns of table "environment_recipe_entry" */
export enum EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpCorrArgumentsColumns {
  /** column name */
  critical_lower = 'critical_lower',
  /** column name */
  critical_upper = 'critical_upper',
  /** column name */
  ideal_lower = 'ideal_lower',
  /** column name */
  ideal_upper = 'ideal_upper',
  /** column name */
  set_point = 'set_point'
}

/** select "environment_recipe_entry_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "environment_recipe_entry" */
export enum EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpCovarSampArgumentsColumns {
  /** column name */
  critical_lower = 'critical_lower',
  /** column name */
  critical_upper = 'critical_upper',
  /** column name */
  ideal_lower = 'ideal_lower',
  /** column name */
  ideal_upper = 'ideal_upper',
  /** column name */
  set_point = 'set_point'
}

/** select "environment_recipe_entry_aggregate_bool_exp_max_arguments_columns" columns of table "environment_recipe_entry" */
export enum EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpMaxArgumentsColumns {
  /** column name */
  critical_lower = 'critical_lower',
  /** column name */
  critical_upper = 'critical_upper',
  /** column name */
  ideal_lower = 'ideal_lower',
  /** column name */
  ideal_upper = 'ideal_upper',
  /** column name */
  set_point = 'set_point'
}

/** select "environment_recipe_entry_aggregate_bool_exp_min_arguments_columns" columns of table "environment_recipe_entry" */
export enum EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpMinArgumentsColumns {
  /** column name */
  critical_lower = 'critical_lower',
  /** column name */
  critical_upper = 'critical_upper',
  /** column name */
  ideal_lower = 'ideal_lower',
  /** column name */
  ideal_upper = 'ideal_upper',
  /** column name */
  set_point = 'set_point'
}

/** select "environment_recipe_entry_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "environment_recipe_entry" */
export enum EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpStddevSampArgumentsColumns {
  /** column name */
  critical_lower = 'critical_lower',
  /** column name */
  critical_upper = 'critical_upper',
  /** column name */
  ideal_lower = 'ideal_lower',
  /** column name */
  ideal_upper = 'ideal_upper',
  /** column name */
  set_point = 'set_point'
}

/** select "environment_recipe_entry_aggregate_bool_exp_sum_arguments_columns" columns of table "environment_recipe_entry" */
export enum EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpSumArgumentsColumns {
  /** column name */
  critical_lower = 'critical_lower',
  /** column name */
  critical_upper = 'critical_upper',
  /** column name */
  ideal_lower = 'ideal_lower',
  /** column name */
  ideal_upper = 'ideal_upper',
  /** column name */
  set_point = 'set_point'
}

/** select "environment_recipe_entry_aggregate_bool_exp_var_samp_arguments_columns" columns of table "environment_recipe_entry" */
export enum EnvironmentRecipeEntrySelectColumnEnvironmentRecipeEntryAggregateBoolExpVarSampArgumentsColumns {
  /** column name */
  critical_lower = 'critical_lower',
  /** column name */
  critical_upper = 'critical_upper',
  /** column name */
  ideal_lower = 'ideal_lower',
  /** column name */
  ideal_upper = 'ideal_upper',
  /** column name */
  set_point = 'set_point'
}

/** input type for updating data in table "environment_recipe_entry" */
export type EnvironmentRecipeEntrySetInput = {
  critical_lower?: InputMaybe<Scalars['float8']['input']>;
  critical_upper?: InputMaybe<Scalars['float8']['input']>;
  day?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  ideal_lower?: InputMaybe<Scalars['float8']['input']>;
  ideal_upper?: InputMaybe<Scalars['float8']['input']>;
  lights_on?: InputMaybe<Scalars['Boolean']['input']>;
  recipe_id?: InputMaybe<Scalars['Int']['input']>;
  set_point?: InputMaybe<Scalars['float8']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type EnvironmentRecipeEntryStddevFields = {
  __typename?: 'environment_recipe_entry_stddev_fields';
  critical_lower?: Maybe<Scalars['Float']['output']>;
  critical_upper?: Maybe<Scalars['Float']['output']>;
  day?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  ideal_lower?: Maybe<Scalars['Float']['output']>;
  ideal_upper?: Maybe<Scalars['Float']['output']>;
  recipe_id?: Maybe<Scalars['Float']['output']>;
  set_point?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "environment_recipe_entry" */
export type EnvironmentRecipeEntryStddevOrderBy = {
  critical_lower?: InputMaybe<OrderBy>;
  critical_upper?: InputMaybe<OrderBy>;
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ideal_lower?: InputMaybe<OrderBy>;
  ideal_upper?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
  set_point?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EnvironmentRecipeEntryStddevPopFields = {
  __typename?: 'environment_recipe_entry_stddev_pop_fields';
  critical_lower?: Maybe<Scalars['Float']['output']>;
  critical_upper?: Maybe<Scalars['Float']['output']>;
  day?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  ideal_lower?: Maybe<Scalars['Float']['output']>;
  ideal_upper?: Maybe<Scalars['Float']['output']>;
  recipe_id?: Maybe<Scalars['Float']['output']>;
  set_point?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "environment_recipe_entry" */
export type EnvironmentRecipeEntryStddevPopOrderBy = {
  critical_lower?: InputMaybe<OrderBy>;
  critical_upper?: InputMaybe<OrderBy>;
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ideal_lower?: InputMaybe<OrderBy>;
  ideal_upper?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
  set_point?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EnvironmentRecipeEntryStddevSampFields = {
  __typename?: 'environment_recipe_entry_stddev_samp_fields';
  critical_lower?: Maybe<Scalars['Float']['output']>;
  critical_upper?: Maybe<Scalars['Float']['output']>;
  day?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  ideal_lower?: Maybe<Scalars['Float']['output']>;
  ideal_upper?: Maybe<Scalars['Float']['output']>;
  recipe_id?: Maybe<Scalars['Float']['output']>;
  set_point?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "environment_recipe_entry" */
export type EnvironmentRecipeEntryStddevSampOrderBy = {
  critical_lower?: InputMaybe<OrderBy>;
  critical_upper?: InputMaybe<OrderBy>;
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ideal_lower?: InputMaybe<OrderBy>;
  ideal_upper?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
  set_point?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "environment_recipe_entry" */
export type EnvironmentRecipeEntryStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: EnvironmentRecipeEntryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnvironmentRecipeEntryStreamCursorValueInput = {
  critical_lower?: InputMaybe<Scalars['float8']['input']>;
  critical_upper?: InputMaybe<Scalars['float8']['input']>;
  day?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  ideal_lower?: InputMaybe<Scalars['float8']['input']>;
  ideal_upper?: InputMaybe<Scalars['float8']['input']>;
  lights_on?: InputMaybe<Scalars['Boolean']['input']>;
  recipe_id?: InputMaybe<Scalars['Int']['input']>;
  set_point?: InputMaybe<Scalars['float8']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type EnvironmentRecipeEntrySumFields = {
  __typename?: 'environment_recipe_entry_sum_fields';
  critical_lower?: Maybe<Scalars['float8']['output']>;
  critical_upper?: Maybe<Scalars['float8']['output']>;
  day?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  ideal_lower?: Maybe<Scalars['float8']['output']>;
  ideal_upper?: Maybe<Scalars['float8']['output']>;
  recipe_id?: Maybe<Scalars['Int']['output']>;
  set_point?: Maybe<Scalars['float8']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "environment_recipe_entry" */
export type EnvironmentRecipeEntrySumOrderBy = {
  critical_lower?: InputMaybe<OrderBy>;
  critical_upper?: InputMaybe<OrderBy>;
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ideal_lower?: InputMaybe<OrderBy>;
  ideal_upper?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
  set_point?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** update columns of table "environment_recipe_entry" */
export enum EnvironmentRecipeEntryUpdateColumn {
  /** column name */
  critical_lower = 'critical_lower',
  /** column name */
  critical_upper = 'critical_upper',
  /** column name */
  day = 'day',
  /** column name */
  id = 'id',
  /** column name */
  ideal_lower = 'ideal_lower',
  /** column name */
  ideal_upper = 'ideal_upper',
  /** column name */
  lights_on = 'lights_on',
  /** column name */
  recipe_id = 'recipe_id',
  /** column name */
  set_point = 'set_point',
  /** column name */
  type_id = 'type_id'
}

export type EnvironmentRecipeEntryUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EnvironmentRecipeEntryIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EnvironmentRecipeEntrySetInput>;
  /** filter the rows which have to be updated */
  where: EnvironmentRecipeEntryBoolExp;
};

/** aggregate var_pop on columns */
export type EnvironmentRecipeEntryVarPopFields = {
  __typename?: 'environment_recipe_entry_var_pop_fields';
  critical_lower?: Maybe<Scalars['Float']['output']>;
  critical_upper?: Maybe<Scalars['Float']['output']>;
  day?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  ideal_lower?: Maybe<Scalars['Float']['output']>;
  ideal_upper?: Maybe<Scalars['Float']['output']>;
  recipe_id?: Maybe<Scalars['Float']['output']>;
  set_point?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "environment_recipe_entry" */
export type EnvironmentRecipeEntryVarPopOrderBy = {
  critical_lower?: InputMaybe<OrderBy>;
  critical_upper?: InputMaybe<OrderBy>;
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ideal_lower?: InputMaybe<OrderBy>;
  ideal_upper?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
  set_point?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EnvironmentRecipeEntryVarSampFields = {
  __typename?: 'environment_recipe_entry_var_samp_fields';
  critical_lower?: Maybe<Scalars['Float']['output']>;
  critical_upper?: Maybe<Scalars['Float']['output']>;
  day?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  ideal_lower?: Maybe<Scalars['Float']['output']>;
  ideal_upper?: Maybe<Scalars['Float']['output']>;
  recipe_id?: Maybe<Scalars['Float']['output']>;
  set_point?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "environment_recipe_entry" */
export type EnvironmentRecipeEntryVarSampOrderBy = {
  critical_lower?: InputMaybe<OrderBy>;
  critical_upper?: InputMaybe<OrderBy>;
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ideal_lower?: InputMaybe<OrderBy>;
  ideal_upper?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
  set_point?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EnvironmentRecipeEntryVarianceFields = {
  __typename?: 'environment_recipe_entry_variance_fields';
  critical_lower?: Maybe<Scalars['Float']['output']>;
  critical_upper?: Maybe<Scalars['Float']['output']>;
  day?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  ideal_lower?: Maybe<Scalars['Float']['output']>;
  ideal_upper?: Maybe<Scalars['Float']['output']>;
  recipe_id?: Maybe<Scalars['Float']['output']>;
  set_point?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "environment_recipe_entry" */
export type EnvironmentRecipeEntryVarianceOrderBy = {
  critical_lower?: InputMaybe<OrderBy>;
  critical_upper?: InputMaybe<OrderBy>;
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  ideal_lower?: InputMaybe<OrderBy>;
  ideal_upper?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
  set_point?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** input type for incrementing numeric columns in table "environment_recipe" */
export type EnvironmentRecipeIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  location_id?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "environment_recipe" */
export type EnvironmentRecipeInsertInput = {
  environment_recipe_entrys?: InputMaybe<EnvironmentRecipeEntryArrRelInsertInput>;
  growth_cycles?: InputMaybe<GrowthCycleArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated?: InputMaybe<Scalars['timestamptz']['input']>;
  location?: InputMaybe<LocationObjRelInsertInput>;
  location_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type EnvironmentRecipeMaxFields = {
  __typename?: 'environment_recipe_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  last_updated?: Maybe<Scalars['timestamptz']['output']>;
  location_id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "environment_recipe" */
export type EnvironmentRecipeMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  last_updated?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type EnvironmentRecipeMinFields = {
  __typename?: 'environment_recipe_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  last_updated?: Maybe<Scalars['timestamptz']['output']>;
  location_id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "environment_recipe" */
export type EnvironmentRecipeMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  last_updated?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "environment_recipe" */
export type EnvironmentRecipeMutationResponse = {
  __typename?: 'environment_recipe_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<EnvironmentRecipe>;
};

/** input type for inserting object relation for remote table "environment_recipe" */
export type EnvironmentRecipeObjRelInsertInput = {
  data: EnvironmentRecipeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<EnvironmentRecipeOnConflict>;
};

/** on_conflict condition type for table "environment_recipe" */
export type EnvironmentRecipeOnConflict = {
  constraint: EnvironmentRecipeConstraint;
  update_columns?: Array<EnvironmentRecipeUpdateColumn>;
  where?: InputMaybe<EnvironmentRecipeBoolExp>;
};

/** Ordering options when selecting data from "environment_recipe". */
export type EnvironmentRecipeOrderBy = {
  environment_recipe_entrys_aggregate?: InputMaybe<EnvironmentRecipeEntryAggregateOrderBy>;
  growth_cycles_aggregate?: InputMaybe<GrowthCycleAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  is_active?: InputMaybe<OrderBy>;
  last_updated?: InputMaybe<OrderBy>;
  location?: InputMaybe<LocationOrderBy>;
  location_id?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: environment_recipe */
export type EnvironmentRecipePkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type EnvironmentRecipePrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "environment_recipe" */
export enum EnvironmentRecipeSelectColumn {
  /** column name */
  id = 'id',
  /** column name */
  is_active = 'is_active',
  /** column name */
  last_updated = 'last_updated',
  /** column name */
  location_id = 'location_id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  name = 'name',
  /** column name */
  organization_id = 'organization_id'
}

/** select "environment_recipe_aggregate_bool_exp_bool_and_arguments_columns" columns of table "environment_recipe" */
export enum EnvironmentRecipeSelectColumnEnvironmentRecipeAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  is_active = 'is_active'
}

/** select "environment_recipe_aggregate_bool_exp_bool_or_arguments_columns" columns of table "environment_recipe" */
export enum EnvironmentRecipeSelectColumnEnvironmentRecipeAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  is_active = 'is_active'
}

/** input type for updating data in table "environment_recipe" */
export type EnvironmentRecipeSetInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated?: InputMaybe<Scalars['timestamptz']['input']>;
  location_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type EnvironmentRecipeStddevFields = {
  __typename?: 'environment_recipe_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "environment_recipe" */
export type EnvironmentRecipeStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type EnvironmentRecipeStddevPopFields = {
  __typename?: 'environment_recipe_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "environment_recipe" */
export type EnvironmentRecipeStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type EnvironmentRecipeStddevSampFields = {
  __typename?: 'environment_recipe_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "environment_recipe" */
export type EnvironmentRecipeStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "environment_recipe" */
export type EnvironmentRecipeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: EnvironmentRecipeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type EnvironmentRecipeStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated?: InputMaybe<Scalars['timestamptz']['input']>;
  location_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type EnvironmentRecipeSumFields = {
  __typename?: 'environment_recipe_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  location_id?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "environment_recipe" */
export type EnvironmentRecipeSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** update columns of table "environment_recipe" */
export enum EnvironmentRecipeUpdateColumn {
  /** column name */
  id = 'id',
  /** column name */
  is_active = 'is_active',
  /** column name */
  last_updated = 'last_updated',
  /** column name */
  location_id = 'location_id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  name = 'name',
  /** column name */
  organization_id = 'organization_id'
}

export type EnvironmentRecipeUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<EnvironmentRecipeAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<EnvironmentRecipeDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<EnvironmentRecipeDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<EnvironmentRecipeDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EnvironmentRecipeIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<EnvironmentRecipePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EnvironmentRecipeSetInput>;
  /** filter the rows which have to be updated */
  where: EnvironmentRecipeBoolExp;
};

/** aggregate var_pop on columns */
export type EnvironmentRecipeVarPopFields = {
  __typename?: 'environment_recipe_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "environment_recipe" */
export type EnvironmentRecipeVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type EnvironmentRecipeVarSampFields = {
  __typename?: 'environment_recipe_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "environment_recipe" */
export type EnvironmentRecipeVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type EnvironmentRecipeVarianceFields = {
  __typename?: 'environment_recipe_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "environment_recipe" */
export type EnvironmentRecipeVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "field_intervention" */
export type FieldIntervention = {
  __typename?: 'field_intervention';
  category?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  notes: Scalars['jsonb']['output'];
  /** An object relationship */
  system: System;
  system_uid: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: User;
  user_id: Scalars['Int']['output'];
  web_uid?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  zone: Zone;
  zone_uid: Scalars['String']['output'];
};


/** columns and relationships of "field_intervention" */
export type FieldInterventionNotesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "field_intervention" */
export type FieldInterventionAggregate = {
  __typename?: 'field_intervention_aggregate';
  aggregate?: Maybe<FieldInterventionAggregateFields>;
  nodes: Array<FieldIntervention>;
};

export type FieldInterventionAggregateBoolExp = {
  count?: InputMaybe<FieldInterventionAggregateBoolExpCount>;
};

export type FieldInterventionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<FieldInterventionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<FieldInterventionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "field_intervention" */
export type FieldInterventionAggregateFields = {
  __typename?: 'field_intervention_aggregate_fields';
  avg?: Maybe<FieldInterventionAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<FieldInterventionMaxFields>;
  min?: Maybe<FieldInterventionMinFields>;
  stddev?: Maybe<FieldInterventionStddevFields>;
  stddev_pop?: Maybe<FieldInterventionStddevPopFields>;
  stddev_samp?: Maybe<FieldInterventionStddevSampFields>;
  sum?: Maybe<FieldInterventionSumFields>;
  var_pop?: Maybe<FieldInterventionVarPopFields>;
  var_samp?: Maybe<FieldInterventionVarSampFields>;
  variance?: Maybe<FieldInterventionVarianceFields>;
};


/** aggregate fields of "field_intervention" */
export type FieldInterventionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FieldInterventionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "field_intervention" */
export type FieldInterventionAggregateOrderBy = {
  avg?: InputMaybe<FieldInterventionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FieldInterventionMaxOrderBy>;
  min?: InputMaybe<FieldInterventionMinOrderBy>;
  stddev?: InputMaybe<FieldInterventionStddevOrderBy>;
  stddev_pop?: InputMaybe<FieldInterventionStddevPopOrderBy>;
  stddev_samp?: InputMaybe<FieldInterventionStddevSampOrderBy>;
  sum?: InputMaybe<FieldInterventionSumOrderBy>;
  var_pop?: InputMaybe<FieldInterventionVarPopOrderBy>;
  var_samp?: InputMaybe<FieldInterventionVarSampOrderBy>;
  variance?: InputMaybe<FieldInterventionVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type FieldInterventionAppendInput = {
  notes?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "field_intervention" */
export type FieldInterventionArrRelInsertInput = {
  data: Array<FieldInterventionInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<FieldInterventionOnConflict>;
};

/** aggregate avg on columns */
export type FieldInterventionAvgFields = {
  __typename?: 'field_intervention_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "field_intervention" */
export type FieldInterventionAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "field_intervention". All fields are combined with a logical 'AND'. */
export type FieldInterventionBoolExp = {
  _and?: InputMaybe<Array<FieldInterventionBoolExp>>;
  _not?: InputMaybe<FieldInterventionBoolExp>;
  _or?: InputMaybe<Array<FieldInterventionBoolExp>>;
  category?: InputMaybe<StringComparisonExp>;
  created_at?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  notes?: InputMaybe<JsonbComparisonExp>;
  system?: InputMaybe<SystemBoolExp>;
  system_uid?: InputMaybe<StringComparisonExp>;
  updated_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  user_id?: InputMaybe<IntComparisonExp>;
  web_uid?: InputMaybe<StringComparisonExp>;
  zone?: InputMaybe<ZoneBoolExp>;
  zone_uid?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "field_intervention" */
export enum FieldInterventionConstraint {
  /** unique or primary key constraint on columns "id" */
  field_intervention_pkey = 'field_intervention_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type FieldInterventionDeleteAtPathInput = {
  notes?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type FieldInterventionDeleteElemInput = {
  notes?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type FieldInterventionDeleteKeyInput = {
  notes?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "field_intervention" */
export type FieldInterventionIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "field_intervention" */
export type FieldInterventionInsertInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['jsonb']['input']>;
  system?: InputMaybe<SystemObjRelInsertInput>;
  system_uid?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  web_uid?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<ZoneObjRelInsertInput>;
  zone_uid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type FieldInterventionMaxFields = {
  __typename?: 'field_intervention_max_fields';
  category?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  system_uid?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  web_uid?: Maybe<Scalars['String']['output']>;
  zone_uid?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "field_intervention" */
export type FieldInterventionMaxOrderBy = {
  category?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  system_uid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
  web_uid?: InputMaybe<OrderBy>;
  zone_uid?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type FieldInterventionMinFields = {
  __typename?: 'field_intervention_min_fields';
  category?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  system_uid?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  web_uid?: Maybe<Scalars['String']['output']>;
  zone_uid?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "field_intervention" */
export type FieldInterventionMinOrderBy = {
  category?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  system_uid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
  web_uid?: InputMaybe<OrderBy>;
  zone_uid?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "field_intervention" */
export type FieldInterventionMutationResponse = {
  __typename?: 'field_intervention_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<FieldIntervention>;
};

/** on_conflict condition type for table "field_intervention" */
export type FieldInterventionOnConflict = {
  constraint: FieldInterventionConstraint;
  update_columns?: Array<FieldInterventionUpdateColumn>;
  where?: InputMaybe<FieldInterventionBoolExp>;
};

/** Ordering options when selecting data from "field_intervention". */
export type FieldInterventionOrderBy = {
  category?: InputMaybe<OrderBy>;
  created_at?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  notes?: InputMaybe<OrderBy>;
  system?: InputMaybe<SystemOrderBy>;
  system_uid?: InputMaybe<OrderBy>;
  updated_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  user_id?: InputMaybe<OrderBy>;
  web_uid?: InputMaybe<OrderBy>;
  zone?: InputMaybe<ZoneOrderBy>;
  zone_uid?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: field_intervention */
export type FieldInterventionPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type FieldInterventionPrependInput = {
  notes?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "field_intervention" */
export enum FieldInterventionSelectColumn {
  /** column name */
  category = 'category',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  notes = 'notes',
  /** column name */
  system_uid = 'system_uid',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  user_id = 'user_id',
  /** column name */
  web_uid = 'web_uid',
  /** column name */
  zone_uid = 'zone_uid'
}

/** input type for updating data in table "field_intervention" */
export type FieldInterventionSetInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['jsonb']['input']>;
  system_uid?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  web_uid?: InputMaybe<Scalars['String']['input']>;
  zone_uid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type FieldInterventionStddevFields = {
  __typename?: 'field_intervention_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "field_intervention" */
export type FieldInterventionStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type FieldInterventionStddevPopFields = {
  __typename?: 'field_intervention_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "field_intervention" */
export type FieldInterventionStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type FieldInterventionStddevSampFields = {
  __typename?: 'field_intervention_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "field_intervention" */
export type FieldInterventionStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "field_intervention" */
export type FieldInterventionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FieldInterventionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FieldInterventionStreamCursorValueInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['jsonb']['input']>;
  system_uid?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  web_uid?: InputMaybe<Scalars['String']['input']>;
  zone_uid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type FieldInterventionSumFields = {
  __typename?: 'field_intervention_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "field_intervention" */
export type FieldInterventionSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** update columns of table "field_intervention" */
export enum FieldInterventionUpdateColumn {
  /** column name */
  category = 'category',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  notes = 'notes',
  /** column name */
  system_uid = 'system_uid',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  user_id = 'user_id',
  /** column name */
  web_uid = 'web_uid',
  /** column name */
  zone_uid = 'zone_uid'
}

export type FieldInterventionUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<FieldInterventionAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<FieldInterventionDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<FieldInterventionDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<FieldInterventionDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FieldInterventionIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<FieldInterventionPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FieldInterventionSetInput>;
  /** filter the rows which have to be updated */
  where: FieldInterventionBoolExp;
};

/** aggregate var_pop on columns */
export type FieldInterventionVarPopFields = {
  __typename?: 'field_intervention_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "field_intervention" */
export type FieldInterventionVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type FieldInterventionVarSampFields = {
  __typename?: 'field_intervention_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "field_intervention" */
export type FieldInterventionVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type FieldInterventionVarianceFields = {
  __typename?: 'field_intervention_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "field_intervention" */
export type FieldInterventionVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8ComparisonExp = {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

export type GeographyCastExp = {
  geometry?: InputMaybe<GeometryComparisonExp>;
};

/** columns and relationships of "geography_columns" */
export type GeographyColumns = {
  __typename?: 'geography_columns';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  f_geography_column?: Maybe<Scalars['name']['output']>;
  f_table_catalog?: Maybe<Scalars['name']['output']>;
  f_table_name?: Maybe<Scalars['name']['output']>;
  f_table_schema?: Maybe<Scalars['name']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "geography_columns" */
export type GeographyColumnsAggregate = {
  __typename?: 'geography_columns_aggregate';
  aggregate?: Maybe<GeographyColumnsAggregateFields>;
  nodes: Array<GeographyColumns>;
};

/** aggregate fields of "geography_columns" */
export type GeographyColumnsAggregateFields = {
  __typename?: 'geography_columns_aggregate_fields';
  avg?: Maybe<GeographyColumnsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<GeographyColumnsMaxFields>;
  min?: Maybe<GeographyColumnsMinFields>;
  stddev?: Maybe<GeographyColumnsStddevFields>;
  stddev_pop?: Maybe<GeographyColumnsStddevPopFields>;
  stddev_samp?: Maybe<GeographyColumnsStddevSampFields>;
  sum?: Maybe<GeographyColumnsSumFields>;
  var_pop?: Maybe<GeographyColumnsVarPopFields>;
  var_samp?: Maybe<GeographyColumnsVarSampFields>;
  variance?: Maybe<GeographyColumnsVarianceFields>;
};


/** aggregate fields of "geography_columns" */
export type GeographyColumnsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GeographyColumnsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type GeographyColumnsAvgFields = {
  __typename?: 'geography_columns_avg_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "geography_columns". All fields are combined with a logical 'AND'. */
export type GeographyColumnsBoolExp = {
  _and?: InputMaybe<Array<GeographyColumnsBoolExp>>;
  _not?: InputMaybe<GeographyColumnsBoolExp>;
  _or?: InputMaybe<Array<GeographyColumnsBoolExp>>;
  coord_dimension?: InputMaybe<IntComparisonExp>;
  f_geography_column?: InputMaybe<NameComparisonExp>;
  f_table_catalog?: InputMaybe<NameComparisonExp>;
  f_table_name?: InputMaybe<NameComparisonExp>;
  f_table_schema?: InputMaybe<NameComparisonExp>;
  srid?: InputMaybe<IntComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** aggregate max on columns */
export type GeographyColumnsMaxFields = {
  __typename?: 'geography_columns_max_fields';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type GeographyColumnsMinFields = {
  __typename?: 'geography_columns_min_fields';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "geography_columns". */
export type GeographyColumnsOrderBy = {
  coord_dimension?: InputMaybe<OrderBy>;
  f_geography_column?: InputMaybe<OrderBy>;
  f_table_catalog?: InputMaybe<OrderBy>;
  f_table_name?: InputMaybe<OrderBy>;
  f_table_schema?: InputMaybe<OrderBy>;
  srid?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** select columns of table "geography_columns" */
export enum GeographyColumnsSelectColumn {
  /** column name */
  coord_dimension = 'coord_dimension',
  /** column name */
  f_geography_column = 'f_geography_column',
  /** column name */
  f_table_catalog = 'f_table_catalog',
  /** column name */
  f_table_name = 'f_table_name',
  /** column name */
  f_table_schema = 'f_table_schema',
  /** column name */
  srid = 'srid',
  /** column name */
  type = 'type'
}

/** aggregate stddev on columns */
export type GeographyColumnsStddevFields = {
  __typename?: 'geography_columns_stddev_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type GeographyColumnsStddevPopFields = {
  __typename?: 'geography_columns_stddev_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type GeographyColumnsStddevSampFields = {
  __typename?: 'geography_columns_stddev_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "geography_columns" */
export type GeographyColumnsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GeographyColumnsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GeographyColumnsStreamCursorValueInput = {
  coord_dimension?: InputMaybe<Scalars['Int']['input']>;
  f_geography_column?: InputMaybe<Scalars['name']['input']>;
  f_table_catalog?: InputMaybe<Scalars['name']['input']>;
  f_table_name?: InputMaybe<Scalars['name']['input']>;
  f_table_schema?: InputMaybe<Scalars['name']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type GeographyColumnsSumFields = {
  __typename?: 'geography_columns_sum_fields';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type GeographyColumnsVarPopFields = {
  __typename?: 'geography_columns_var_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type GeographyColumnsVarSampFields = {
  __typename?: 'geography_columns_var_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type GeographyColumnsVarianceFields = {
  __typename?: 'geography_columns_variance_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type GeographyComparisonExp = {
  _cast?: InputMaybe<GeographyCastExp>;
  _eq?: InputMaybe<Scalars['geography']['input']>;
  _gt?: InputMaybe<Scalars['geography']['input']>;
  _gte?: InputMaybe<Scalars['geography']['input']>;
  _in?: InputMaybe<Array<Scalars['geography']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['geography']['input']>;
  _lte?: InputMaybe<Scalars['geography']['input']>;
  _neq?: InputMaybe<Scalars['geography']['input']>;
  _nin?: InputMaybe<Array<Scalars['geography']['input']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: InputMaybe<StDWithinGeographyInput>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: InputMaybe<Scalars['geography']['input']>;
};

export type GeometryCastExp = {
  geography?: InputMaybe<GeographyComparisonExp>;
};

/** columns and relationships of "geometry_columns" */
export type GeometryColumns = {
  __typename?: 'geometry_columns';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  f_geometry_column?: Maybe<Scalars['name']['output']>;
  f_table_catalog?: Maybe<Scalars['String']['output']>;
  f_table_name?: Maybe<Scalars['name']['output']>;
  f_table_schema?: Maybe<Scalars['name']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "geometry_columns" */
export type GeometryColumnsAggregate = {
  __typename?: 'geometry_columns_aggregate';
  aggregate?: Maybe<GeometryColumnsAggregateFields>;
  nodes: Array<GeometryColumns>;
};

/** aggregate fields of "geometry_columns" */
export type GeometryColumnsAggregateFields = {
  __typename?: 'geometry_columns_aggregate_fields';
  avg?: Maybe<GeometryColumnsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<GeometryColumnsMaxFields>;
  min?: Maybe<GeometryColumnsMinFields>;
  stddev?: Maybe<GeometryColumnsStddevFields>;
  stddev_pop?: Maybe<GeometryColumnsStddevPopFields>;
  stddev_samp?: Maybe<GeometryColumnsStddevSampFields>;
  sum?: Maybe<GeometryColumnsSumFields>;
  var_pop?: Maybe<GeometryColumnsVarPopFields>;
  var_samp?: Maybe<GeometryColumnsVarSampFields>;
  variance?: Maybe<GeometryColumnsVarianceFields>;
};


/** aggregate fields of "geometry_columns" */
export type GeometryColumnsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GeometryColumnsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type GeometryColumnsAvgFields = {
  __typename?: 'geometry_columns_avg_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "geometry_columns". All fields are combined with a logical 'AND'. */
export type GeometryColumnsBoolExp = {
  _and?: InputMaybe<Array<GeometryColumnsBoolExp>>;
  _not?: InputMaybe<GeometryColumnsBoolExp>;
  _or?: InputMaybe<Array<GeometryColumnsBoolExp>>;
  coord_dimension?: InputMaybe<IntComparisonExp>;
  f_geometry_column?: InputMaybe<NameComparisonExp>;
  f_table_catalog?: InputMaybe<StringComparisonExp>;
  f_table_name?: InputMaybe<NameComparisonExp>;
  f_table_schema?: InputMaybe<NameComparisonExp>;
  srid?: InputMaybe<IntComparisonExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** input type for incrementing numeric columns in table "geometry_columns" */
export type GeometryColumnsIncInput = {
  coord_dimension?: InputMaybe<Scalars['Int']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "geometry_columns" */
export type GeometryColumnsInsertInput = {
  coord_dimension?: InputMaybe<Scalars['Int']['input']>;
  f_geometry_column?: InputMaybe<Scalars['name']['input']>;
  f_table_catalog?: InputMaybe<Scalars['String']['input']>;
  f_table_name?: InputMaybe<Scalars['name']['input']>;
  f_table_schema?: InputMaybe<Scalars['name']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type GeometryColumnsMaxFields = {
  __typename?: 'geometry_columns_max_fields';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  f_table_catalog?: Maybe<Scalars['String']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type GeometryColumnsMinFields = {
  __typename?: 'geometry_columns_min_fields';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  f_table_catalog?: Maybe<Scalars['String']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "geometry_columns" */
export type GeometryColumnsMutationResponse = {
  __typename?: 'geometry_columns_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<GeometryColumns>;
};

/** Ordering options when selecting data from "geometry_columns". */
export type GeometryColumnsOrderBy = {
  coord_dimension?: InputMaybe<OrderBy>;
  f_geometry_column?: InputMaybe<OrderBy>;
  f_table_catalog?: InputMaybe<OrderBy>;
  f_table_name?: InputMaybe<OrderBy>;
  f_table_schema?: InputMaybe<OrderBy>;
  srid?: InputMaybe<OrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** select columns of table "geometry_columns" */
export enum GeometryColumnsSelectColumn {
  /** column name */
  coord_dimension = 'coord_dimension',
  /** column name */
  f_geometry_column = 'f_geometry_column',
  /** column name */
  f_table_catalog = 'f_table_catalog',
  /** column name */
  f_table_name = 'f_table_name',
  /** column name */
  f_table_schema = 'f_table_schema',
  /** column name */
  srid = 'srid',
  /** column name */
  type = 'type'
}

/** input type for updating data in table "geometry_columns" */
export type GeometryColumnsSetInput = {
  coord_dimension?: InputMaybe<Scalars['Int']['input']>;
  f_geometry_column?: InputMaybe<Scalars['name']['input']>;
  f_table_catalog?: InputMaybe<Scalars['String']['input']>;
  f_table_name?: InputMaybe<Scalars['name']['input']>;
  f_table_schema?: InputMaybe<Scalars['name']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type GeometryColumnsStddevFields = {
  __typename?: 'geometry_columns_stddev_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type GeometryColumnsStddevPopFields = {
  __typename?: 'geometry_columns_stddev_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type GeometryColumnsStddevSampFields = {
  __typename?: 'geometry_columns_stddev_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "geometry_columns" */
export type GeometryColumnsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GeometryColumnsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GeometryColumnsStreamCursorValueInput = {
  coord_dimension?: InputMaybe<Scalars['Int']['input']>;
  f_geometry_column?: InputMaybe<Scalars['name']['input']>;
  f_table_catalog?: InputMaybe<Scalars['String']['input']>;
  f_table_name?: InputMaybe<Scalars['name']['input']>;
  f_table_schema?: InputMaybe<Scalars['name']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type GeometryColumnsSumFields = {
  __typename?: 'geometry_columns_sum_fields';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
};

export type GeometryColumnsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GeometryColumnsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GeometryColumnsSetInput>;
  /** filter the rows which have to be updated */
  where: GeometryColumnsBoolExp;
};

/** aggregate var_pop on columns */
export type GeometryColumnsVarPopFields = {
  __typename?: 'geometry_columns_var_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type GeometryColumnsVarSampFields = {
  __typename?: 'geometry_columns_var_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type GeometryColumnsVarianceFields = {
  __typename?: 'geometry_columns_variance_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type GeometryComparisonExp = {
  _cast?: InputMaybe<GeometryCastExp>;
  _eq?: InputMaybe<Scalars['geometry']['input']>;
  _gt?: InputMaybe<Scalars['geometry']['input']>;
  _gte?: InputMaybe<Scalars['geometry']['input']>;
  _in?: InputMaybe<Array<Scalars['geometry']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['geometry']['input']>;
  _lte?: InputMaybe<Scalars['geometry']['input']>;
  _neq?: InputMaybe<Scalars['geometry']['input']>;
  _nin?: InputMaybe<Array<Scalars['geometry']['input']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: InputMaybe<StDWithinInput>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column contain the given geometry value */
  _st_contains?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column cross the given geometry value */
  _st_crosses?: InputMaybe<Scalars['geometry']['input']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: InputMaybe<StDWithinInput>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: InputMaybe<Scalars['geometry']['input']>;
  /** is the column contained in the given geometry value */
  _st_within?: InputMaybe<Scalars['geometry']['input']>;
};

export type GetMeasurementsOnGridArgs = {
  selected_measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "get_measurements_on_grid_format_table" */
export type GetMeasurementsOnGridFormatTable = {
  __typename?: 'get_measurements_on_grid_format_table';
  grid_measurements?: Maybe<Scalars['_int4']['output']>;
  grid_shape_x?: Maybe<Scalars['Int']['output']>;
  grid_shape_y?: Maybe<Scalars['Int']['output']>;
  grid_shape_z?: Maybe<Scalars['Int']['output']>;
  measurement_run_id?: Maybe<Scalars['bigint']['output']>;
  sensor_type_id?: Maybe<Scalars['Int']['output']>;
};

export type GetMeasurementsOnGridFormatTableAggregate = {
  __typename?: 'get_measurements_on_grid_format_table_aggregate';
  aggregate?: Maybe<GetMeasurementsOnGridFormatTableAggregateFields>;
  nodes: Array<GetMeasurementsOnGridFormatTable>;
};

/** aggregate fields of "get_measurements_on_grid_format_table" */
export type GetMeasurementsOnGridFormatTableAggregateFields = {
  __typename?: 'get_measurements_on_grid_format_table_aggregate_fields';
  avg?: Maybe<GetMeasurementsOnGridFormatTableAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<GetMeasurementsOnGridFormatTableMaxFields>;
  min?: Maybe<GetMeasurementsOnGridFormatTableMinFields>;
  stddev?: Maybe<GetMeasurementsOnGridFormatTableStddevFields>;
  stddev_pop?: Maybe<GetMeasurementsOnGridFormatTableStddevPopFields>;
  stddev_samp?: Maybe<GetMeasurementsOnGridFormatTableStddevSampFields>;
  sum?: Maybe<GetMeasurementsOnGridFormatTableSumFields>;
  var_pop?: Maybe<GetMeasurementsOnGridFormatTableVarPopFields>;
  var_samp?: Maybe<GetMeasurementsOnGridFormatTableVarSampFields>;
  variance?: Maybe<GetMeasurementsOnGridFormatTableVarianceFields>;
};


/** aggregate fields of "get_measurements_on_grid_format_table" */
export type GetMeasurementsOnGridFormatTableAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GetMeasurementsOnGridFormatTableSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type GetMeasurementsOnGridFormatTableAvgFields = {
  __typename?: 'get_measurements_on_grid_format_table_avg_fields';
  grid_shape_x?: Maybe<Scalars['Float']['output']>;
  grid_shape_y?: Maybe<Scalars['Float']['output']>;
  grid_shape_z?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "get_measurements_on_grid_format_table". All fields are combined with a logical 'AND'. */
export type GetMeasurementsOnGridFormatTableBoolExp = {
  _and?: InputMaybe<Array<GetMeasurementsOnGridFormatTableBoolExp>>;
  _not?: InputMaybe<GetMeasurementsOnGridFormatTableBoolExp>;
  _or?: InputMaybe<Array<GetMeasurementsOnGridFormatTableBoolExp>>;
  grid_measurements?: InputMaybe<Int4ComparisonExp>;
  grid_shape_x?: InputMaybe<IntComparisonExp>;
  grid_shape_y?: InputMaybe<IntComparisonExp>;
  grid_shape_z?: InputMaybe<IntComparisonExp>;
  measurement_run_id?: InputMaybe<BigintComparisonExp>;
  sensor_type_id?: InputMaybe<IntComparisonExp>;
};

/** input type for incrementing numeric columns in table "get_measurements_on_grid_format_table" */
export type GetMeasurementsOnGridFormatTableIncInput = {
  grid_shape_x?: InputMaybe<Scalars['Int']['input']>;
  grid_shape_y?: InputMaybe<Scalars['Int']['input']>;
  grid_shape_z?: InputMaybe<Scalars['Int']['input']>;
  measurement_run_id?: InputMaybe<Scalars['bigint']['input']>;
  sensor_type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "get_measurements_on_grid_format_table" */
export type GetMeasurementsOnGridFormatTableInsertInput = {
  grid_measurements?: InputMaybe<Scalars['_int4']['input']>;
  grid_shape_x?: InputMaybe<Scalars['Int']['input']>;
  grid_shape_y?: InputMaybe<Scalars['Int']['input']>;
  grid_shape_z?: InputMaybe<Scalars['Int']['input']>;
  measurement_run_id?: InputMaybe<Scalars['bigint']['input']>;
  sensor_type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type GetMeasurementsOnGridFormatTableMaxFields = {
  __typename?: 'get_measurements_on_grid_format_table_max_fields';
  grid_shape_x?: Maybe<Scalars['Int']['output']>;
  grid_shape_y?: Maybe<Scalars['Int']['output']>;
  grid_shape_z?: Maybe<Scalars['Int']['output']>;
  measurement_run_id?: Maybe<Scalars['bigint']['output']>;
  sensor_type_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type GetMeasurementsOnGridFormatTableMinFields = {
  __typename?: 'get_measurements_on_grid_format_table_min_fields';
  grid_shape_x?: Maybe<Scalars['Int']['output']>;
  grid_shape_y?: Maybe<Scalars['Int']['output']>;
  grid_shape_z?: Maybe<Scalars['Int']['output']>;
  measurement_run_id?: Maybe<Scalars['bigint']['output']>;
  sensor_type_id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "get_measurements_on_grid_format_table" */
export type GetMeasurementsOnGridFormatTableMutationResponse = {
  __typename?: 'get_measurements_on_grid_format_table_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<GetMeasurementsOnGridFormatTable>;
};

/** Ordering options when selecting data from "get_measurements_on_grid_format_table". */
export type GetMeasurementsOnGridFormatTableOrderBy = {
  grid_measurements?: InputMaybe<OrderBy>;
  grid_shape_x?: InputMaybe<OrderBy>;
  grid_shape_y?: InputMaybe<OrderBy>;
  grid_shape_z?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  sensor_type_id?: InputMaybe<OrderBy>;
};

/** select columns of table "get_measurements_on_grid_format_table" */
export enum GetMeasurementsOnGridFormatTableSelectColumn {
  /** column name */
  grid_measurements = 'grid_measurements',
  /** column name */
  grid_shape_x = 'grid_shape_x',
  /** column name */
  grid_shape_y = 'grid_shape_y',
  /** column name */
  grid_shape_z = 'grid_shape_z',
  /** column name */
  measurement_run_id = 'measurement_run_id',
  /** column name */
  sensor_type_id = 'sensor_type_id'
}

/** input type for updating data in table "get_measurements_on_grid_format_table" */
export type GetMeasurementsOnGridFormatTableSetInput = {
  grid_measurements?: InputMaybe<Scalars['_int4']['input']>;
  grid_shape_x?: InputMaybe<Scalars['Int']['input']>;
  grid_shape_y?: InputMaybe<Scalars['Int']['input']>;
  grid_shape_z?: InputMaybe<Scalars['Int']['input']>;
  measurement_run_id?: InputMaybe<Scalars['bigint']['input']>;
  sensor_type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type GetMeasurementsOnGridFormatTableStddevFields = {
  __typename?: 'get_measurements_on_grid_format_table_stddev_fields';
  grid_shape_x?: Maybe<Scalars['Float']['output']>;
  grid_shape_y?: Maybe<Scalars['Float']['output']>;
  grid_shape_z?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type GetMeasurementsOnGridFormatTableStddevPopFields = {
  __typename?: 'get_measurements_on_grid_format_table_stddev_pop_fields';
  grid_shape_x?: Maybe<Scalars['Float']['output']>;
  grid_shape_y?: Maybe<Scalars['Float']['output']>;
  grid_shape_z?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type GetMeasurementsOnGridFormatTableStddevSampFields = {
  __typename?: 'get_measurements_on_grid_format_table_stddev_samp_fields';
  grid_shape_x?: Maybe<Scalars['Float']['output']>;
  grid_shape_y?: Maybe<Scalars['Float']['output']>;
  grid_shape_z?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "get_measurements_on_grid_format_table" */
export type GetMeasurementsOnGridFormatTableStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GetMeasurementsOnGridFormatTableStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GetMeasurementsOnGridFormatTableStreamCursorValueInput = {
  grid_measurements?: InputMaybe<Scalars['_int4']['input']>;
  grid_shape_x?: InputMaybe<Scalars['Int']['input']>;
  grid_shape_y?: InputMaybe<Scalars['Int']['input']>;
  grid_shape_z?: InputMaybe<Scalars['Int']['input']>;
  measurement_run_id?: InputMaybe<Scalars['bigint']['input']>;
  sensor_type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type GetMeasurementsOnGridFormatTableSumFields = {
  __typename?: 'get_measurements_on_grid_format_table_sum_fields';
  grid_shape_x?: Maybe<Scalars['Int']['output']>;
  grid_shape_y?: Maybe<Scalars['Int']['output']>;
  grid_shape_z?: Maybe<Scalars['Int']['output']>;
  measurement_run_id?: Maybe<Scalars['bigint']['output']>;
  sensor_type_id?: Maybe<Scalars['Int']['output']>;
};

export type GetMeasurementsOnGridFormatTableUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GetMeasurementsOnGridFormatTableIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GetMeasurementsOnGridFormatTableSetInput>;
  /** filter the rows which have to be updated */
  where: GetMeasurementsOnGridFormatTableBoolExp;
};

/** aggregate var_pop on columns */
export type GetMeasurementsOnGridFormatTableVarPopFields = {
  __typename?: 'get_measurements_on_grid_format_table_var_pop_fields';
  grid_shape_x?: Maybe<Scalars['Float']['output']>;
  grid_shape_y?: Maybe<Scalars['Float']['output']>;
  grid_shape_z?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type GetMeasurementsOnGridFormatTableVarSampFields = {
  __typename?: 'get_measurements_on_grid_format_table_var_samp_fields';
  grid_shape_x?: Maybe<Scalars['Float']['output']>;
  grid_shape_y?: Maybe<Scalars['Float']['output']>;
  grid_shape_z?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type GetMeasurementsOnGridFormatTableVarianceFields = {
  __typename?: 'get_measurements_on_grid_format_table_variance_fields';
  grid_shape_x?: Maybe<Scalars['Float']['output']>;
  grid_shape_y?: Maybe<Scalars['Float']['output']>;
  grid_shape_z?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
};

export type GetMostRecentVpdMeasurementsArgs = {
  requested_zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "get_most_recent_vpd_measurements_format_table" */
export type GetMostRecentVpdMeasurementsFormatTable = {
  __typename?: 'get_most_recent_vpd_measurements_format_table';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
  measurement_time?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "get_most_recent_vpd_measurements_format_table" */
export type GetMostRecentVpdMeasurementsFormatTableAggregate = {
  __typename?: 'get_most_recent_vpd_measurements_format_table_aggregate';
  aggregate?: Maybe<GetMostRecentVpdMeasurementsFormatTableAggregateFields>;
  nodes: Array<GetMostRecentVpdMeasurementsFormatTable>;
};

/** aggregate fields of "get_most_recent_vpd_measurements_format_table" */
export type GetMostRecentVpdMeasurementsFormatTableAggregateFields = {
  __typename?: 'get_most_recent_vpd_measurements_format_table_aggregate_fields';
  avg?: Maybe<GetMostRecentVpdMeasurementsFormatTableAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<GetMostRecentVpdMeasurementsFormatTableMaxFields>;
  min?: Maybe<GetMostRecentVpdMeasurementsFormatTableMinFields>;
  stddev?: Maybe<GetMostRecentVpdMeasurementsFormatTableStddevFields>;
  stddev_pop?: Maybe<GetMostRecentVpdMeasurementsFormatTableStddevPopFields>;
  stddev_samp?: Maybe<GetMostRecentVpdMeasurementsFormatTableStddevSampFields>;
  sum?: Maybe<GetMostRecentVpdMeasurementsFormatTableSumFields>;
  var_pop?: Maybe<GetMostRecentVpdMeasurementsFormatTableVarPopFields>;
  var_samp?: Maybe<GetMostRecentVpdMeasurementsFormatTableVarSampFields>;
  variance?: Maybe<GetMostRecentVpdMeasurementsFormatTableVarianceFields>;
};


/** aggregate fields of "get_most_recent_vpd_measurements_format_table" */
export type GetMostRecentVpdMeasurementsFormatTableAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GetMostRecentVpdMeasurementsFormatTableSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type GetMostRecentVpdMeasurementsFormatTableAvgFields = {
  __typename?: 'get_most_recent_vpd_measurements_format_table_avg_fields';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "get_most_recent_vpd_measurements_format_table". All fields are combined with a logical 'AND'. */
export type GetMostRecentVpdMeasurementsFormatTableBoolExp = {
  _and?: InputMaybe<Array<GetMostRecentVpdMeasurementsFormatTableBoolExp>>;
  _not?: InputMaybe<GetMostRecentVpdMeasurementsFormatTableBoolExp>;
  _or?: InputMaybe<Array<GetMostRecentVpdMeasurementsFormatTableBoolExp>>;
  air_vpd?: InputMaybe<FloatComparisonExp>;
  leaf_vpd?: InputMaybe<FloatComparisonExp>;
  measurement_time?: InputMaybe<TimestamptzComparisonExp>;
};

/** input type for incrementing numeric columns in table "get_most_recent_vpd_measurements_format_table" */
export type GetMostRecentVpdMeasurementsFormatTableIncInput = {
  air_vpd?: InputMaybe<Scalars['Float']['input']>;
  leaf_vpd?: InputMaybe<Scalars['Float']['input']>;
};

/** input type for inserting data into table "get_most_recent_vpd_measurements_format_table" */
export type GetMostRecentVpdMeasurementsFormatTableInsertInput = {
  air_vpd?: InputMaybe<Scalars['Float']['input']>;
  leaf_vpd?: InputMaybe<Scalars['Float']['input']>;
  measurement_time?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type GetMostRecentVpdMeasurementsFormatTableMaxFields = {
  __typename?: 'get_most_recent_vpd_measurements_format_table_max_fields';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
  measurement_time?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type GetMostRecentVpdMeasurementsFormatTableMinFields = {
  __typename?: 'get_most_recent_vpd_measurements_format_table_min_fields';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
  measurement_time?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "get_most_recent_vpd_measurements_format_table" */
export type GetMostRecentVpdMeasurementsFormatTableMutationResponse = {
  __typename?: 'get_most_recent_vpd_measurements_format_table_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<GetMostRecentVpdMeasurementsFormatTable>;
};

/** Ordering options when selecting data from "get_most_recent_vpd_measurements_format_table". */
export type GetMostRecentVpdMeasurementsFormatTableOrderBy = {
  air_vpd?: InputMaybe<OrderBy>;
  leaf_vpd?: InputMaybe<OrderBy>;
  measurement_time?: InputMaybe<OrderBy>;
};

/** select columns of table "get_most_recent_vpd_measurements_format_table" */
export enum GetMostRecentVpdMeasurementsFormatTableSelectColumn {
  /** column name */
  air_vpd = 'air_vpd',
  /** column name */
  leaf_vpd = 'leaf_vpd',
  /** column name */
  measurement_time = 'measurement_time'
}

/** input type for updating data in table "get_most_recent_vpd_measurements_format_table" */
export type GetMostRecentVpdMeasurementsFormatTableSetInput = {
  air_vpd?: InputMaybe<Scalars['Float']['input']>;
  leaf_vpd?: InputMaybe<Scalars['Float']['input']>;
  measurement_time?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type GetMostRecentVpdMeasurementsFormatTableStddevFields = {
  __typename?: 'get_most_recent_vpd_measurements_format_table_stddev_fields';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type GetMostRecentVpdMeasurementsFormatTableStddevPopFields = {
  __typename?: 'get_most_recent_vpd_measurements_format_table_stddev_pop_fields';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type GetMostRecentVpdMeasurementsFormatTableStddevSampFields = {
  __typename?: 'get_most_recent_vpd_measurements_format_table_stddev_samp_fields';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "get_most_recent_vpd_measurements_format_table" */
export type GetMostRecentVpdMeasurementsFormatTableStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GetMostRecentVpdMeasurementsFormatTableStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GetMostRecentVpdMeasurementsFormatTableStreamCursorValueInput = {
  air_vpd?: InputMaybe<Scalars['Float']['input']>;
  leaf_vpd?: InputMaybe<Scalars['Float']['input']>;
  measurement_time?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type GetMostRecentVpdMeasurementsFormatTableSumFields = {
  __typename?: 'get_most_recent_vpd_measurements_format_table_sum_fields';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
};

export type GetMostRecentVpdMeasurementsFormatTableUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GetMostRecentVpdMeasurementsFormatTableIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GetMostRecentVpdMeasurementsFormatTableSetInput>;
  /** filter the rows which have to be updated */
  where: GetMostRecentVpdMeasurementsFormatTableBoolExp;
};

/** aggregate var_pop on columns */
export type GetMostRecentVpdMeasurementsFormatTableVarPopFields = {
  __typename?: 'get_most_recent_vpd_measurements_format_table_var_pop_fields';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type GetMostRecentVpdMeasurementsFormatTableVarSampFields = {
  __typename?: 'get_most_recent_vpd_measurements_format_table_var_samp_fields';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type GetMostRecentVpdMeasurementsFormatTableVarianceFields = {
  __typename?: 'get_most_recent_vpd_measurements_format_table_variance_fields';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "grid" */
export type Grid = {
  __typename?: 'grid';
  /** An array relationship */
  grid_elements: Array<GridElement>;
  /** An aggregate relationship */
  grid_elements_aggregate: GridElementAggregate;
  id: Scalars['Int']['output'];
  /** An array relationship */
  measurement_runs: Array<MeasurementRun>;
  /** An aggregate relationship */
  measurement_runs_aggregate: MeasurementRunAggregate;
  offset_x: Scalars['Float']['output'];
  offset_y: Scalars['Float']['output'];
  offset_z: Scalars['Float']['output'];
  resolution_x: Scalars['Float']['output'];
  resolution_y: Scalars['Float']['output'];
  resolution_z: Scalars['Float']['output'];
  shape_x: Scalars['Int']['output'];
  shape_y: Scalars['Int']['output'];
  shape_z: Scalars['Int']['output'];
};


/** columns and relationships of "grid" */
export type GridGridElementsArgs = {
  distinct_on?: InputMaybe<Array<GridElementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GridElementOrderBy>>;
  where?: InputMaybe<GridElementBoolExp>;
};


/** columns and relationships of "grid" */
export type GridGridElementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<GridElementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GridElementOrderBy>>;
  where?: InputMaybe<GridElementBoolExp>;
};


/** columns and relationships of "grid" */
export type GridMeasurementRunsArgs = {
  distinct_on?: InputMaybe<Array<MeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementRunOrderBy>>;
  where?: InputMaybe<MeasurementRunBoolExp>;
};


/** columns and relationships of "grid" */
export type GridMeasurementRunsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementRunOrderBy>>;
  where?: InputMaybe<MeasurementRunBoolExp>;
};

/** aggregated selection of "grid" */
export type GridAggregate = {
  __typename?: 'grid_aggregate';
  aggregate?: Maybe<GridAggregateFields>;
  nodes: Array<Grid>;
};

/** aggregate fields of "grid" */
export type GridAggregateFields = {
  __typename?: 'grid_aggregate_fields';
  avg?: Maybe<GridAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<GridMaxFields>;
  min?: Maybe<GridMinFields>;
  stddev?: Maybe<GridStddevFields>;
  stddev_pop?: Maybe<GridStddevPopFields>;
  stddev_samp?: Maybe<GridStddevSampFields>;
  sum?: Maybe<GridSumFields>;
  var_pop?: Maybe<GridVarPopFields>;
  var_samp?: Maybe<GridVarSampFields>;
  variance?: Maybe<GridVarianceFields>;
};


/** aggregate fields of "grid" */
export type GridAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GridSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type GridAvgFields = {
  __typename?: 'grid_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offset_x?: Maybe<Scalars['Float']['output']>;
  offset_y?: Maybe<Scalars['Float']['output']>;
  offset_z?: Maybe<Scalars['Float']['output']>;
  resolution_x?: Maybe<Scalars['Float']['output']>;
  resolution_y?: Maybe<Scalars['Float']['output']>;
  resolution_z?: Maybe<Scalars['Float']['output']>;
  shape_x?: Maybe<Scalars['Float']['output']>;
  shape_y?: Maybe<Scalars['Float']['output']>;
  shape_z?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "grid". All fields are combined with a logical 'AND'. */
export type GridBoolExp = {
  _and?: InputMaybe<Array<GridBoolExp>>;
  _not?: InputMaybe<GridBoolExp>;
  _or?: InputMaybe<Array<GridBoolExp>>;
  grid_elements?: InputMaybe<GridElementBoolExp>;
  grid_elements_aggregate?: InputMaybe<GridElementAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  measurement_runs?: InputMaybe<MeasurementRunBoolExp>;
  measurement_runs_aggregate?: InputMaybe<MeasurementRunAggregateBoolExp>;
  offset_x?: InputMaybe<FloatComparisonExp>;
  offset_y?: InputMaybe<FloatComparisonExp>;
  offset_z?: InputMaybe<FloatComparisonExp>;
  resolution_x?: InputMaybe<FloatComparisonExp>;
  resolution_y?: InputMaybe<FloatComparisonExp>;
  resolution_z?: InputMaybe<FloatComparisonExp>;
  shape_x?: InputMaybe<IntComparisonExp>;
  shape_y?: InputMaybe<IntComparisonExp>;
  shape_z?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "grid" */
export enum GridConstraint {
  /** unique or primary key constraint on columns "id" */
  grid_pkey = 'grid_pkey',
  /** unique or primary key constraint on columns "resolution_x", "resolution_z", "shape_x", "offset_y", "shape_z", "resolution_y", "offset_z", "shape_y", "offset_x" */
  grid_un = 'grid_un'
}

/** columns and relationships of "grid_data" */
export type GridData = {
  __typename?: 'grid_data';
  col_idx?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  row_idx?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "grid_data" */
export type GridDataAggregate = {
  __typename?: 'grid_data_aggregate';
  aggregate?: Maybe<GridDataAggregateFields>;
  nodes: Array<GridData>;
};

/** aggregate fields of "grid_data" */
export type GridDataAggregateFields = {
  __typename?: 'grid_data_aggregate_fields';
  avg?: Maybe<GridDataAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<GridDataMaxFields>;
  min?: Maybe<GridDataMinFields>;
  stddev?: Maybe<GridDataStddevFields>;
  stddev_pop?: Maybe<GridDataStddevPopFields>;
  stddev_samp?: Maybe<GridDataStddevSampFields>;
  sum?: Maybe<GridDataSumFields>;
  var_pop?: Maybe<GridDataVarPopFields>;
  var_samp?: Maybe<GridDataVarSampFields>;
  variance?: Maybe<GridDataVarianceFields>;
};


/** aggregate fields of "grid_data" */
export type GridDataAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GridDataSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type GridDataAvgFields = {
  __typename?: 'grid_data_avg_fields';
  col_idx?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  row_idx?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "grid_data". All fields are combined with a logical 'AND'. */
export type GridDataBoolExp = {
  _and?: InputMaybe<Array<GridDataBoolExp>>;
  _not?: InputMaybe<GridDataBoolExp>;
  _or?: InputMaybe<Array<GridDataBoolExp>>;
  col_idx?: InputMaybe<BigintComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  measurement_id?: InputMaybe<BigintComparisonExp>;
  measurement_run_id?: InputMaybe<IntComparisonExp>;
  row_idx?: InputMaybe<BigintComparisonExp>;
};

/** aggregate max on columns */
export type GridDataMaxFields = {
  __typename?: 'grid_data_max_fields';
  col_idx?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  row_idx?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type GridDataMinFields = {
  __typename?: 'grid_data_min_fields';
  col_idx?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  row_idx?: Maybe<Scalars['bigint']['output']>;
};

/** Ordering options when selecting data from "grid_data". */
export type GridDataOrderBy = {
  col_idx?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  row_idx?: InputMaybe<OrderBy>;
};

/** select columns of table "grid_data" */
export enum GridDataSelectColumn {
  /** column name */
  col_idx = 'col_idx',
  /** column name */
  id = 'id',
  /** column name */
  measurement_id = 'measurement_id',
  /** column name */
  measurement_run_id = 'measurement_run_id',
  /** column name */
  row_idx = 'row_idx'
}

/** aggregate stddev on columns */
export type GridDataStddevFields = {
  __typename?: 'grid_data_stddev_fields';
  col_idx?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  row_idx?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type GridDataStddevPopFields = {
  __typename?: 'grid_data_stddev_pop_fields';
  col_idx?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  row_idx?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type GridDataStddevSampFields = {
  __typename?: 'grid_data_stddev_samp_fields';
  col_idx?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  row_idx?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "grid_data" */
export type GridDataStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GridDataStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GridDataStreamCursorValueInput = {
  col_idx?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  measurement_id?: InputMaybe<Scalars['bigint']['input']>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
  row_idx?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type GridDataSumFields = {
  __typename?: 'grid_data_sum_fields';
  col_idx?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  row_idx?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type GridDataVarPopFields = {
  __typename?: 'grid_data_var_pop_fields';
  col_idx?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  row_idx?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type GridDataVarSampFields = {
  __typename?: 'grid_data_var_samp_fields';
  col_idx?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  row_idx?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type GridDataVarianceFields = {
  __typename?: 'grid_data_variance_fields';
  col_idx?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  row_idx?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "grid_element" */
export type GridElement = {
  __typename?: 'grid_element';
  /** An object relationship */
  grid: Grid;
  grid_id: Scalars['Int']['output'];
  /** An object relationship */
  pose: Pose;
  pose_id: Scalars['Int']['output'];
  x_index: Scalars['Int']['output'];
  y_index: Scalars['Int']['output'];
  z_index: Scalars['Int']['output'];
};

/** aggregated selection of "grid_element" */
export type GridElementAggregate = {
  __typename?: 'grid_element_aggregate';
  aggregate?: Maybe<GridElementAggregateFields>;
  nodes: Array<GridElement>;
};

export type GridElementAggregateBoolExp = {
  count?: InputMaybe<GridElementAggregateBoolExpCount>;
};

export type GridElementAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<GridElementSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<GridElementBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "grid_element" */
export type GridElementAggregateFields = {
  __typename?: 'grid_element_aggregate_fields';
  avg?: Maybe<GridElementAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<GridElementMaxFields>;
  min?: Maybe<GridElementMinFields>;
  stddev?: Maybe<GridElementStddevFields>;
  stddev_pop?: Maybe<GridElementStddevPopFields>;
  stddev_samp?: Maybe<GridElementStddevSampFields>;
  sum?: Maybe<GridElementSumFields>;
  var_pop?: Maybe<GridElementVarPopFields>;
  var_samp?: Maybe<GridElementVarSampFields>;
  variance?: Maybe<GridElementVarianceFields>;
};


/** aggregate fields of "grid_element" */
export type GridElementAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GridElementSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "grid_element" */
export type GridElementAggregateOrderBy = {
  avg?: InputMaybe<GridElementAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<GridElementMaxOrderBy>;
  min?: InputMaybe<GridElementMinOrderBy>;
  stddev?: InputMaybe<GridElementStddevOrderBy>;
  stddev_pop?: InputMaybe<GridElementStddevPopOrderBy>;
  stddev_samp?: InputMaybe<GridElementStddevSampOrderBy>;
  sum?: InputMaybe<GridElementSumOrderBy>;
  var_pop?: InputMaybe<GridElementVarPopOrderBy>;
  var_samp?: InputMaybe<GridElementVarSampOrderBy>;
  variance?: InputMaybe<GridElementVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "grid_element" */
export type GridElementArrRelInsertInput = {
  data: Array<GridElementInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<GridElementOnConflict>;
};

/** aggregate avg on columns */
export type GridElementAvgFields = {
  __typename?: 'grid_element_avg_fields';
  grid_id?: Maybe<Scalars['Float']['output']>;
  pose_id?: Maybe<Scalars['Float']['output']>;
  x_index?: Maybe<Scalars['Float']['output']>;
  y_index?: Maybe<Scalars['Float']['output']>;
  z_index?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "grid_element" */
export type GridElementAvgOrderBy = {
  grid_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  x_index?: InputMaybe<OrderBy>;
  y_index?: InputMaybe<OrderBy>;
  z_index?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "grid_element". All fields are combined with a logical 'AND'. */
export type GridElementBoolExp = {
  _and?: InputMaybe<Array<GridElementBoolExp>>;
  _not?: InputMaybe<GridElementBoolExp>;
  _or?: InputMaybe<Array<GridElementBoolExp>>;
  grid?: InputMaybe<GridBoolExp>;
  grid_id?: InputMaybe<IntComparisonExp>;
  pose?: InputMaybe<PoseBoolExp>;
  pose_id?: InputMaybe<IntComparisonExp>;
  x_index?: InputMaybe<IntComparisonExp>;
  y_index?: InputMaybe<IntComparisonExp>;
  z_index?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "grid_element" */
export enum GridElementConstraint {
  /** unique or primary key constraint on columns "grid_id", "y_index", "x_index", "z_index" */
  grid_element_pkey = 'grid_element_pkey'
}

/** input type for incrementing numeric columns in table "grid_element" */
export type GridElementIncInput = {
  grid_id?: InputMaybe<Scalars['Int']['input']>;
  pose_id?: InputMaybe<Scalars['Int']['input']>;
  x_index?: InputMaybe<Scalars['Int']['input']>;
  y_index?: InputMaybe<Scalars['Int']['input']>;
  z_index?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "grid_element" */
export type GridElementInsertInput = {
  grid?: InputMaybe<GridObjRelInsertInput>;
  grid_id?: InputMaybe<Scalars['Int']['input']>;
  pose?: InputMaybe<PoseObjRelInsertInput>;
  pose_id?: InputMaybe<Scalars['Int']['input']>;
  x_index?: InputMaybe<Scalars['Int']['input']>;
  y_index?: InputMaybe<Scalars['Int']['input']>;
  z_index?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type GridElementMaxFields = {
  __typename?: 'grid_element_max_fields';
  grid_id?: Maybe<Scalars['Int']['output']>;
  pose_id?: Maybe<Scalars['Int']['output']>;
  x_index?: Maybe<Scalars['Int']['output']>;
  y_index?: Maybe<Scalars['Int']['output']>;
  z_index?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "grid_element" */
export type GridElementMaxOrderBy = {
  grid_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  x_index?: InputMaybe<OrderBy>;
  y_index?: InputMaybe<OrderBy>;
  z_index?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type GridElementMinFields = {
  __typename?: 'grid_element_min_fields';
  grid_id?: Maybe<Scalars['Int']['output']>;
  pose_id?: Maybe<Scalars['Int']['output']>;
  x_index?: Maybe<Scalars['Int']['output']>;
  y_index?: Maybe<Scalars['Int']['output']>;
  z_index?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "grid_element" */
export type GridElementMinOrderBy = {
  grid_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  x_index?: InputMaybe<OrderBy>;
  y_index?: InputMaybe<OrderBy>;
  z_index?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "grid_element" */
export type GridElementMutationResponse = {
  __typename?: 'grid_element_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<GridElement>;
};

/** on_conflict condition type for table "grid_element" */
export type GridElementOnConflict = {
  constraint: GridElementConstraint;
  update_columns?: Array<GridElementUpdateColumn>;
  where?: InputMaybe<GridElementBoolExp>;
};

/** Ordering options when selecting data from "grid_element". */
export type GridElementOrderBy = {
  grid?: InputMaybe<GridOrderBy>;
  grid_id?: InputMaybe<OrderBy>;
  pose?: InputMaybe<PoseOrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  x_index?: InputMaybe<OrderBy>;
  y_index?: InputMaybe<OrderBy>;
  z_index?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: grid_element */
export type GridElementPkColumnsInput = {
  grid_id: Scalars['Int']['input'];
  x_index: Scalars['Int']['input'];
  y_index: Scalars['Int']['input'];
  z_index: Scalars['Int']['input'];
};

/** select columns of table "grid_element" */
export enum GridElementSelectColumn {
  /** column name */
  grid_id = 'grid_id',
  /** column name */
  pose_id = 'pose_id',
  /** column name */
  x_index = 'x_index',
  /** column name */
  y_index = 'y_index',
  /** column name */
  z_index = 'z_index'
}

/** input type for updating data in table "grid_element" */
export type GridElementSetInput = {
  grid_id?: InputMaybe<Scalars['Int']['input']>;
  pose_id?: InputMaybe<Scalars['Int']['input']>;
  x_index?: InputMaybe<Scalars['Int']['input']>;
  y_index?: InputMaybe<Scalars['Int']['input']>;
  z_index?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type GridElementStddevFields = {
  __typename?: 'grid_element_stddev_fields';
  grid_id?: Maybe<Scalars['Float']['output']>;
  pose_id?: Maybe<Scalars['Float']['output']>;
  x_index?: Maybe<Scalars['Float']['output']>;
  y_index?: Maybe<Scalars['Float']['output']>;
  z_index?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "grid_element" */
export type GridElementStddevOrderBy = {
  grid_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  x_index?: InputMaybe<OrderBy>;
  y_index?: InputMaybe<OrderBy>;
  z_index?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type GridElementStddevPopFields = {
  __typename?: 'grid_element_stddev_pop_fields';
  grid_id?: Maybe<Scalars['Float']['output']>;
  pose_id?: Maybe<Scalars['Float']['output']>;
  x_index?: Maybe<Scalars['Float']['output']>;
  y_index?: Maybe<Scalars['Float']['output']>;
  z_index?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "grid_element" */
export type GridElementStddevPopOrderBy = {
  grid_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  x_index?: InputMaybe<OrderBy>;
  y_index?: InputMaybe<OrderBy>;
  z_index?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type GridElementStddevSampFields = {
  __typename?: 'grid_element_stddev_samp_fields';
  grid_id?: Maybe<Scalars['Float']['output']>;
  pose_id?: Maybe<Scalars['Float']['output']>;
  x_index?: Maybe<Scalars['Float']['output']>;
  y_index?: Maybe<Scalars['Float']['output']>;
  z_index?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "grid_element" */
export type GridElementStddevSampOrderBy = {
  grid_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  x_index?: InputMaybe<OrderBy>;
  y_index?: InputMaybe<OrderBy>;
  z_index?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "grid_element" */
export type GridElementStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GridElementStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GridElementStreamCursorValueInput = {
  grid_id?: InputMaybe<Scalars['Int']['input']>;
  pose_id?: InputMaybe<Scalars['Int']['input']>;
  x_index?: InputMaybe<Scalars['Int']['input']>;
  y_index?: InputMaybe<Scalars['Int']['input']>;
  z_index?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type GridElementSumFields = {
  __typename?: 'grid_element_sum_fields';
  grid_id?: Maybe<Scalars['Int']['output']>;
  pose_id?: Maybe<Scalars['Int']['output']>;
  x_index?: Maybe<Scalars['Int']['output']>;
  y_index?: Maybe<Scalars['Int']['output']>;
  z_index?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "grid_element" */
export type GridElementSumOrderBy = {
  grid_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  x_index?: InputMaybe<OrderBy>;
  y_index?: InputMaybe<OrderBy>;
  z_index?: InputMaybe<OrderBy>;
};

/** update columns of table "grid_element" */
export enum GridElementUpdateColumn {
  /** column name */
  grid_id = 'grid_id',
  /** column name */
  pose_id = 'pose_id',
  /** column name */
  x_index = 'x_index',
  /** column name */
  y_index = 'y_index',
  /** column name */
  z_index = 'z_index'
}

export type GridElementUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GridElementIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GridElementSetInput>;
  /** filter the rows which have to be updated */
  where: GridElementBoolExp;
};

/** aggregate var_pop on columns */
export type GridElementVarPopFields = {
  __typename?: 'grid_element_var_pop_fields';
  grid_id?: Maybe<Scalars['Float']['output']>;
  pose_id?: Maybe<Scalars['Float']['output']>;
  x_index?: Maybe<Scalars['Float']['output']>;
  y_index?: Maybe<Scalars['Float']['output']>;
  z_index?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "grid_element" */
export type GridElementVarPopOrderBy = {
  grid_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  x_index?: InputMaybe<OrderBy>;
  y_index?: InputMaybe<OrderBy>;
  z_index?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type GridElementVarSampFields = {
  __typename?: 'grid_element_var_samp_fields';
  grid_id?: Maybe<Scalars['Float']['output']>;
  pose_id?: Maybe<Scalars['Float']['output']>;
  x_index?: Maybe<Scalars['Float']['output']>;
  y_index?: Maybe<Scalars['Float']['output']>;
  z_index?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "grid_element" */
export type GridElementVarSampOrderBy = {
  grid_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  x_index?: InputMaybe<OrderBy>;
  y_index?: InputMaybe<OrderBy>;
  z_index?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type GridElementVarianceFields = {
  __typename?: 'grid_element_variance_fields';
  grid_id?: Maybe<Scalars['Float']['output']>;
  pose_id?: Maybe<Scalars['Float']['output']>;
  x_index?: Maybe<Scalars['Float']['output']>;
  y_index?: Maybe<Scalars['Float']['output']>;
  z_index?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "grid_element" */
export type GridElementVarianceOrderBy = {
  grid_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  x_index?: InputMaybe<OrderBy>;
  y_index?: InputMaybe<OrderBy>;
  z_index?: InputMaybe<OrderBy>;
};

/** input type for incrementing numeric columns in table "grid" */
export type GridIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  offset_x?: InputMaybe<Scalars['Float']['input']>;
  offset_y?: InputMaybe<Scalars['Float']['input']>;
  offset_z?: InputMaybe<Scalars['Float']['input']>;
  resolution_x?: InputMaybe<Scalars['Float']['input']>;
  resolution_y?: InputMaybe<Scalars['Float']['input']>;
  resolution_z?: InputMaybe<Scalars['Float']['input']>;
  shape_x?: InputMaybe<Scalars['Int']['input']>;
  shape_y?: InputMaybe<Scalars['Int']['input']>;
  shape_z?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "grid" */
export type GridInsertInput = {
  grid_elements?: InputMaybe<GridElementArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  measurement_runs?: InputMaybe<MeasurementRunArrRelInsertInput>;
  offset_x?: InputMaybe<Scalars['Float']['input']>;
  offset_y?: InputMaybe<Scalars['Float']['input']>;
  offset_z?: InputMaybe<Scalars['Float']['input']>;
  resolution_x?: InputMaybe<Scalars['Float']['input']>;
  resolution_y?: InputMaybe<Scalars['Float']['input']>;
  resolution_z?: InputMaybe<Scalars['Float']['input']>;
  shape_x?: InputMaybe<Scalars['Int']['input']>;
  shape_y?: InputMaybe<Scalars['Int']['input']>;
  shape_z?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type GridMaxFields = {
  __typename?: 'grid_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  offset_x?: Maybe<Scalars['Float']['output']>;
  offset_y?: Maybe<Scalars['Float']['output']>;
  offset_z?: Maybe<Scalars['Float']['output']>;
  resolution_x?: Maybe<Scalars['Float']['output']>;
  resolution_y?: Maybe<Scalars['Float']['output']>;
  resolution_z?: Maybe<Scalars['Float']['output']>;
  shape_x?: Maybe<Scalars['Int']['output']>;
  shape_y?: Maybe<Scalars['Int']['output']>;
  shape_z?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type GridMinFields = {
  __typename?: 'grid_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  offset_x?: Maybe<Scalars['Float']['output']>;
  offset_y?: Maybe<Scalars['Float']['output']>;
  offset_z?: Maybe<Scalars['Float']['output']>;
  resolution_x?: Maybe<Scalars['Float']['output']>;
  resolution_y?: Maybe<Scalars['Float']['output']>;
  resolution_z?: Maybe<Scalars['Float']['output']>;
  shape_x?: Maybe<Scalars['Int']['output']>;
  shape_y?: Maybe<Scalars['Int']['output']>;
  shape_z?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "grid" */
export type GridMutationResponse = {
  __typename?: 'grid_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Grid>;
};

/** input type for inserting object relation for remote table "grid" */
export type GridObjRelInsertInput = {
  data: GridInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<GridOnConflict>;
};

/** on_conflict condition type for table "grid" */
export type GridOnConflict = {
  constraint: GridConstraint;
  update_columns?: Array<GridUpdateColumn>;
  where?: InputMaybe<GridBoolExp>;
};

/** Ordering options when selecting data from "grid". */
export type GridOrderBy = {
  grid_elements_aggregate?: InputMaybe<GridElementAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_runs_aggregate?: InputMaybe<MeasurementRunAggregateOrderBy>;
  offset_x?: InputMaybe<OrderBy>;
  offset_y?: InputMaybe<OrderBy>;
  offset_z?: InputMaybe<OrderBy>;
  resolution_x?: InputMaybe<OrderBy>;
  resolution_y?: InputMaybe<OrderBy>;
  resolution_z?: InputMaybe<OrderBy>;
  shape_x?: InputMaybe<OrderBy>;
  shape_y?: InputMaybe<OrderBy>;
  shape_z?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: grid */
export type GridPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** select columns of table "grid" */
export enum GridSelectColumn {
  /** column name */
  id = 'id',
  /** column name */
  offset_x = 'offset_x',
  /** column name */
  offset_y = 'offset_y',
  /** column name */
  offset_z = 'offset_z',
  /** column name */
  resolution_x = 'resolution_x',
  /** column name */
  resolution_y = 'resolution_y',
  /** column name */
  resolution_z = 'resolution_z',
  /** column name */
  shape_x = 'shape_x',
  /** column name */
  shape_y = 'shape_y',
  /** column name */
  shape_z = 'shape_z'
}

/** input type for updating data in table "grid" */
export type GridSetInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  offset_x?: InputMaybe<Scalars['Float']['input']>;
  offset_y?: InputMaybe<Scalars['Float']['input']>;
  offset_z?: InputMaybe<Scalars['Float']['input']>;
  resolution_x?: InputMaybe<Scalars['Float']['input']>;
  resolution_y?: InputMaybe<Scalars['Float']['input']>;
  resolution_z?: InputMaybe<Scalars['Float']['input']>;
  shape_x?: InputMaybe<Scalars['Int']['input']>;
  shape_y?: InputMaybe<Scalars['Int']['input']>;
  shape_z?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type GridStddevFields = {
  __typename?: 'grid_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offset_x?: Maybe<Scalars['Float']['output']>;
  offset_y?: Maybe<Scalars['Float']['output']>;
  offset_z?: Maybe<Scalars['Float']['output']>;
  resolution_x?: Maybe<Scalars['Float']['output']>;
  resolution_y?: Maybe<Scalars['Float']['output']>;
  resolution_z?: Maybe<Scalars['Float']['output']>;
  shape_x?: Maybe<Scalars['Float']['output']>;
  shape_y?: Maybe<Scalars['Float']['output']>;
  shape_z?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type GridStddevPopFields = {
  __typename?: 'grid_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offset_x?: Maybe<Scalars['Float']['output']>;
  offset_y?: Maybe<Scalars['Float']['output']>;
  offset_z?: Maybe<Scalars['Float']['output']>;
  resolution_x?: Maybe<Scalars['Float']['output']>;
  resolution_y?: Maybe<Scalars['Float']['output']>;
  resolution_z?: Maybe<Scalars['Float']['output']>;
  shape_x?: Maybe<Scalars['Float']['output']>;
  shape_y?: Maybe<Scalars['Float']['output']>;
  shape_z?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type GridStddevSampFields = {
  __typename?: 'grid_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offset_x?: Maybe<Scalars['Float']['output']>;
  offset_y?: Maybe<Scalars['Float']['output']>;
  offset_z?: Maybe<Scalars['Float']['output']>;
  resolution_x?: Maybe<Scalars['Float']['output']>;
  resolution_y?: Maybe<Scalars['Float']['output']>;
  resolution_z?: Maybe<Scalars['Float']['output']>;
  shape_x?: Maybe<Scalars['Float']['output']>;
  shape_y?: Maybe<Scalars['Float']['output']>;
  shape_z?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "grid" */
export type GridStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GridStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GridStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  offset_x?: InputMaybe<Scalars['Float']['input']>;
  offset_y?: InputMaybe<Scalars['Float']['input']>;
  offset_z?: InputMaybe<Scalars['Float']['input']>;
  resolution_x?: InputMaybe<Scalars['Float']['input']>;
  resolution_y?: InputMaybe<Scalars['Float']['input']>;
  resolution_z?: InputMaybe<Scalars['Float']['input']>;
  shape_x?: InputMaybe<Scalars['Int']['input']>;
  shape_y?: InputMaybe<Scalars['Int']['input']>;
  shape_z?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type GridSumFields = {
  __typename?: 'grid_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  offset_x?: Maybe<Scalars['Float']['output']>;
  offset_y?: Maybe<Scalars['Float']['output']>;
  offset_z?: Maybe<Scalars['Float']['output']>;
  resolution_x?: Maybe<Scalars['Float']['output']>;
  resolution_y?: Maybe<Scalars['Float']['output']>;
  resolution_z?: Maybe<Scalars['Float']['output']>;
  shape_x?: Maybe<Scalars['Int']['output']>;
  shape_y?: Maybe<Scalars['Int']['output']>;
  shape_z?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "grid" */
export enum GridUpdateColumn {
  /** column name */
  id = 'id',
  /** column name */
  offset_x = 'offset_x',
  /** column name */
  offset_y = 'offset_y',
  /** column name */
  offset_z = 'offset_z',
  /** column name */
  resolution_x = 'resolution_x',
  /** column name */
  resolution_y = 'resolution_y',
  /** column name */
  resolution_z = 'resolution_z',
  /** column name */
  shape_x = 'shape_x',
  /** column name */
  shape_y = 'shape_y',
  /** column name */
  shape_z = 'shape_z'
}

export type GridUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GridIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GridSetInput>;
  /** filter the rows which have to be updated */
  where: GridBoolExp;
};

/** aggregate var_pop on columns */
export type GridVarPopFields = {
  __typename?: 'grid_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offset_x?: Maybe<Scalars['Float']['output']>;
  offset_y?: Maybe<Scalars['Float']['output']>;
  offset_z?: Maybe<Scalars['Float']['output']>;
  resolution_x?: Maybe<Scalars['Float']['output']>;
  resolution_y?: Maybe<Scalars['Float']['output']>;
  resolution_z?: Maybe<Scalars['Float']['output']>;
  shape_x?: Maybe<Scalars['Float']['output']>;
  shape_y?: Maybe<Scalars['Float']['output']>;
  shape_z?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type GridVarSampFields = {
  __typename?: 'grid_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offset_x?: Maybe<Scalars['Float']['output']>;
  offset_y?: Maybe<Scalars['Float']['output']>;
  offset_z?: Maybe<Scalars['Float']['output']>;
  resolution_x?: Maybe<Scalars['Float']['output']>;
  resolution_y?: Maybe<Scalars['Float']['output']>;
  resolution_z?: Maybe<Scalars['Float']['output']>;
  shape_x?: Maybe<Scalars['Float']['output']>;
  shape_y?: Maybe<Scalars['Float']['output']>;
  shape_z?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type GridVarianceFields = {
  __typename?: 'grid_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  offset_x?: Maybe<Scalars['Float']['output']>;
  offset_y?: Maybe<Scalars['Float']['output']>;
  offset_z?: Maybe<Scalars['Float']['output']>;
  resolution_x?: Maybe<Scalars['Float']['output']>;
  resolution_y?: Maybe<Scalars['Float']['output']>;
  resolution_z?: Maybe<Scalars['Float']['output']>;
  shape_x?: Maybe<Scalars['Float']['output']>;
  shape_y?: Maybe<Scalars['Float']['output']>;
  shape_z?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "grid_view" */
export type GridView = {
  __typename?: 'grid_view';
  grid_measurements?: Maybe<Scalars['_int8']['output']>;
  grid_shape_x?: Maybe<Scalars['Int']['output']>;
  grid_shape_y?: Maybe<Scalars['Int']['output']>;
  grid_shape_z?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  measurement_run?: Maybe<MeasurementRun>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  sensor?: Maybe<Enumeration>;
  sensor_type_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "grid_view" */
export type GridViewAggregate = {
  __typename?: 'grid_view_aggregate';
  aggregate?: Maybe<GridViewAggregateFields>;
  nodes: Array<GridView>;
};

/** aggregate fields of "grid_view" */
export type GridViewAggregateFields = {
  __typename?: 'grid_view_aggregate_fields';
  avg?: Maybe<GridViewAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<GridViewMaxFields>;
  min?: Maybe<GridViewMinFields>;
  stddev?: Maybe<GridViewStddevFields>;
  stddev_pop?: Maybe<GridViewStddevPopFields>;
  stddev_samp?: Maybe<GridViewStddevSampFields>;
  sum?: Maybe<GridViewSumFields>;
  var_pop?: Maybe<GridViewVarPopFields>;
  var_samp?: Maybe<GridViewVarSampFields>;
  variance?: Maybe<GridViewVarianceFields>;
};


/** aggregate fields of "grid_view" */
export type GridViewAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GridViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type GridViewAvgFields = {
  __typename?: 'grid_view_avg_fields';
  grid_shape_x?: Maybe<Scalars['Float']['output']>;
  grid_shape_y?: Maybe<Scalars['Float']['output']>;
  grid_shape_z?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "grid_view". All fields are combined with a logical 'AND'. */
export type GridViewBoolExp = {
  _and?: InputMaybe<Array<GridViewBoolExp>>;
  _not?: InputMaybe<GridViewBoolExp>;
  _or?: InputMaybe<Array<GridViewBoolExp>>;
  grid_measurements?: InputMaybe<Int8ComparisonExp>;
  grid_shape_x?: InputMaybe<IntComparisonExp>;
  grid_shape_y?: InputMaybe<IntComparisonExp>;
  grid_shape_z?: InputMaybe<IntComparisonExp>;
  measurement_run?: InputMaybe<MeasurementRunBoolExp>;
  measurement_run_id?: InputMaybe<IntComparisonExp>;
  sensor?: InputMaybe<EnumerationBoolExp>;
  sensor_type_id?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type GridViewMaxFields = {
  __typename?: 'grid_view_max_fields';
  grid_shape_x?: Maybe<Scalars['Int']['output']>;
  grid_shape_y?: Maybe<Scalars['Int']['output']>;
  grid_shape_z?: Maybe<Scalars['Int']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  sensor_type_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type GridViewMinFields = {
  __typename?: 'grid_view_min_fields';
  grid_shape_x?: Maybe<Scalars['Int']['output']>;
  grid_shape_y?: Maybe<Scalars['Int']['output']>;
  grid_shape_z?: Maybe<Scalars['Int']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  sensor_type_id?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "grid_view". */
export type GridViewOrderBy = {
  grid_measurements?: InputMaybe<OrderBy>;
  grid_shape_x?: InputMaybe<OrderBy>;
  grid_shape_y?: InputMaybe<OrderBy>;
  grid_shape_z?: InputMaybe<OrderBy>;
  measurement_run?: InputMaybe<MeasurementRunOrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  sensor?: InputMaybe<EnumerationOrderBy>;
  sensor_type_id?: InputMaybe<OrderBy>;
};

/** select columns of table "grid_view" */
export enum GridViewSelectColumn {
  /** column name */
  grid_measurements = 'grid_measurements',
  /** column name */
  grid_shape_x = 'grid_shape_x',
  /** column name */
  grid_shape_y = 'grid_shape_y',
  /** column name */
  grid_shape_z = 'grid_shape_z',
  /** column name */
  measurement_run_id = 'measurement_run_id',
  /** column name */
  sensor_type_id = 'sensor_type_id'
}

/** aggregate stddev on columns */
export type GridViewStddevFields = {
  __typename?: 'grid_view_stddev_fields';
  grid_shape_x?: Maybe<Scalars['Float']['output']>;
  grid_shape_y?: Maybe<Scalars['Float']['output']>;
  grid_shape_z?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type GridViewStddevPopFields = {
  __typename?: 'grid_view_stddev_pop_fields';
  grid_shape_x?: Maybe<Scalars['Float']['output']>;
  grid_shape_y?: Maybe<Scalars['Float']['output']>;
  grid_shape_z?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type GridViewStddevSampFields = {
  __typename?: 'grid_view_stddev_samp_fields';
  grid_shape_x?: Maybe<Scalars['Float']['output']>;
  grid_shape_y?: Maybe<Scalars['Float']['output']>;
  grid_shape_z?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "grid_view" */
export type GridViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GridViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GridViewStreamCursorValueInput = {
  grid_measurements?: InputMaybe<Scalars['_int8']['input']>;
  grid_shape_x?: InputMaybe<Scalars['Int']['input']>;
  grid_shape_y?: InputMaybe<Scalars['Int']['input']>;
  grid_shape_z?: InputMaybe<Scalars['Int']['input']>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
  sensor_type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type GridViewSumFields = {
  __typename?: 'grid_view_sum_fields';
  grid_shape_x?: Maybe<Scalars['Int']['output']>;
  grid_shape_y?: Maybe<Scalars['Int']['output']>;
  grid_shape_z?: Maybe<Scalars['Int']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  sensor_type_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type GridViewVarPopFields = {
  __typename?: 'grid_view_var_pop_fields';
  grid_shape_x?: Maybe<Scalars['Float']['output']>;
  grid_shape_y?: Maybe<Scalars['Float']['output']>;
  grid_shape_z?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type GridViewVarSampFields = {
  __typename?: 'grid_view_var_samp_fields';
  grid_shape_x?: Maybe<Scalars['Float']['output']>;
  grid_shape_y?: Maybe<Scalars['Float']['output']>;
  grid_shape_z?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type GridViewVarianceFields = {
  __typename?: 'grid_view_variance_fields';
  grid_shape_x?: Maybe<Scalars['Float']['output']>;
  grid_shape_y?: Maybe<Scalars['Float']['output']>;
  grid_shape_z?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "growth_cycle" */
export type GrowthCycle = {
  __typename?: 'growth_cycle';
  end_time: Scalars['timestamptz']['output'];
  /** An object relationship */
  environment_recipe?: Maybe<EnvironmentRecipe>;
  environment_recipe_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  is_active: Scalars['Boolean']['output'];
  /** An object relationship */
  light_cycle_recipe?: Maybe<LightCycleRecipe>;
  light_cycle_recipe_id?: Maybe<Scalars['Int']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  start_time: Scalars['timestamptz']['output'];
  /** An object relationship */
  zone: Zone;
  zone_id: Scalars['Int']['output'];
};


/** columns and relationships of "growth_cycle" */
export type GrowthCycleMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "growth_cycle" */
export type GrowthCycleAggregate = {
  __typename?: 'growth_cycle_aggregate';
  aggregate?: Maybe<GrowthCycleAggregateFields>;
  nodes: Array<GrowthCycle>;
};

export type GrowthCycleAggregateBoolExp = {
  bool_and?: InputMaybe<GrowthCycleAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<GrowthCycleAggregateBoolExpBoolOr>;
  count?: InputMaybe<GrowthCycleAggregateBoolExpCount>;
};

export type GrowthCycleAggregateBoolExpBoolAnd = {
  arguments: GrowthCycleSelectColumnGrowthCycleAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<GrowthCycleBoolExp>;
  predicate: BooleanComparisonExp;
};

export type GrowthCycleAggregateBoolExpBoolOr = {
  arguments: GrowthCycleSelectColumnGrowthCycleAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<GrowthCycleBoolExp>;
  predicate: BooleanComparisonExp;
};

export type GrowthCycleAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<GrowthCycleSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<GrowthCycleBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "growth_cycle" */
export type GrowthCycleAggregateFields = {
  __typename?: 'growth_cycle_aggregate_fields';
  avg?: Maybe<GrowthCycleAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<GrowthCycleMaxFields>;
  min?: Maybe<GrowthCycleMinFields>;
  stddev?: Maybe<GrowthCycleStddevFields>;
  stddev_pop?: Maybe<GrowthCycleStddevPopFields>;
  stddev_samp?: Maybe<GrowthCycleStddevSampFields>;
  sum?: Maybe<GrowthCycleSumFields>;
  var_pop?: Maybe<GrowthCycleVarPopFields>;
  var_samp?: Maybe<GrowthCycleVarSampFields>;
  variance?: Maybe<GrowthCycleVarianceFields>;
};


/** aggregate fields of "growth_cycle" */
export type GrowthCycleAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<GrowthCycleSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "growth_cycle" */
export type GrowthCycleAggregateOrderBy = {
  avg?: InputMaybe<GrowthCycleAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<GrowthCycleMaxOrderBy>;
  min?: InputMaybe<GrowthCycleMinOrderBy>;
  stddev?: InputMaybe<GrowthCycleStddevOrderBy>;
  stddev_pop?: InputMaybe<GrowthCycleStddevPopOrderBy>;
  stddev_samp?: InputMaybe<GrowthCycleStddevSampOrderBy>;
  sum?: InputMaybe<GrowthCycleSumOrderBy>;
  var_pop?: InputMaybe<GrowthCycleVarPopOrderBy>;
  var_samp?: InputMaybe<GrowthCycleVarSampOrderBy>;
  variance?: InputMaybe<GrowthCycleVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type GrowthCycleAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "growth_cycle" */
export type GrowthCycleArrRelInsertInput = {
  data: Array<GrowthCycleInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<GrowthCycleOnConflict>;
};

/** aggregate avg on columns */
export type GrowthCycleAvgFields = {
  __typename?: 'growth_cycle_avg_fields';
  environment_recipe_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  light_cycle_recipe_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "growth_cycle" */
export type GrowthCycleAvgOrderBy = {
  environment_recipe_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  light_cycle_recipe_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "growth_cycle". All fields are combined with a logical 'AND'. */
export type GrowthCycleBoolExp = {
  _and?: InputMaybe<Array<GrowthCycleBoolExp>>;
  _not?: InputMaybe<GrowthCycleBoolExp>;
  _or?: InputMaybe<Array<GrowthCycleBoolExp>>;
  end_time?: InputMaybe<TimestamptzComparisonExp>;
  environment_recipe?: InputMaybe<EnvironmentRecipeBoolExp>;
  environment_recipe_id?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  is_active?: InputMaybe<BooleanComparisonExp>;
  light_cycle_recipe?: InputMaybe<LightCycleRecipeBoolExp>;
  light_cycle_recipe_id?: InputMaybe<IntComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  start_time?: InputMaybe<TimestamptzComparisonExp>;
  zone?: InputMaybe<ZoneBoolExp>;
  zone_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "growth_cycle" */
export enum GrowthCycleConstraint {
  /** unique or primary key constraint on columns "id" */
  growth_cycle_pkey = 'growth_cycle_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type GrowthCycleDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type GrowthCycleDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type GrowthCycleDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "growth_cycle" */
export type GrowthCycleIncInput = {
  environment_recipe_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  light_cycle_recipe_id?: InputMaybe<Scalars['Int']['input']>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "growth_cycle" */
export type GrowthCycleInsertInput = {
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  environment_recipe?: InputMaybe<EnvironmentRecipeObjRelInsertInput>;
  environment_recipe_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  light_cycle_recipe?: InputMaybe<LightCycleRecipeObjRelInsertInput>;
  light_cycle_recipe_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
  zone?: InputMaybe<ZoneObjRelInsertInput>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type GrowthCycleMaxFields = {
  __typename?: 'growth_cycle_max_fields';
  end_time?: Maybe<Scalars['timestamptz']['output']>;
  environment_recipe_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  light_cycle_recipe_id?: Maybe<Scalars['Int']['output']>;
  start_time?: Maybe<Scalars['timestamptz']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "growth_cycle" */
export type GrowthCycleMaxOrderBy = {
  end_time?: InputMaybe<OrderBy>;
  environment_recipe_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  light_cycle_recipe_id?: InputMaybe<OrderBy>;
  start_time?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type GrowthCycleMinFields = {
  __typename?: 'growth_cycle_min_fields';
  end_time?: Maybe<Scalars['timestamptz']['output']>;
  environment_recipe_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  light_cycle_recipe_id?: Maybe<Scalars['Int']['output']>;
  start_time?: Maybe<Scalars['timestamptz']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "growth_cycle" */
export type GrowthCycleMinOrderBy = {
  end_time?: InputMaybe<OrderBy>;
  environment_recipe_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  light_cycle_recipe_id?: InputMaybe<OrderBy>;
  start_time?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "growth_cycle" */
export type GrowthCycleMutationResponse = {
  __typename?: 'growth_cycle_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<GrowthCycle>;
};

/** on_conflict condition type for table "growth_cycle" */
export type GrowthCycleOnConflict = {
  constraint: GrowthCycleConstraint;
  update_columns?: Array<GrowthCycleUpdateColumn>;
  where?: InputMaybe<GrowthCycleBoolExp>;
};

/** Ordering options when selecting data from "growth_cycle". */
export type GrowthCycleOrderBy = {
  end_time?: InputMaybe<OrderBy>;
  environment_recipe?: InputMaybe<EnvironmentRecipeOrderBy>;
  environment_recipe_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_active?: InputMaybe<OrderBy>;
  light_cycle_recipe?: InputMaybe<LightCycleRecipeOrderBy>;
  light_cycle_recipe_id?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  start_time?: InputMaybe<OrderBy>;
  zone?: InputMaybe<ZoneOrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: growth_cycle */
export type GrowthCyclePkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type GrowthCyclePrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "growth_cycle" */
export enum GrowthCycleSelectColumn {
  /** column name */
  end_time = 'end_time',
  /** column name */
  environment_recipe_id = 'environment_recipe_id',
  /** column name */
  id = 'id',
  /** column name */
  is_active = 'is_active',
  /** column name */
  light_cycle_recipe_id = 'light_cycle_recipe_id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  start_time = 'start_time',
  /** column name */
  zone_id = 'zone_id'
}

/** select "growth_cycle_aggregate_bool_exp_bool_and_arguments_columns" columns of table "growth_cycle" */
export enum GrowthCycleSelectColumnGrowthCycleAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  is_active = 'is_active'
}

/** select "growth_cycle_aggregate_bool_exp_bool_or_arguments_columns" columns of table "growth_cycle" */
export enum GrowthCycleSelectColumnGrowthCycleAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  is_active = 'is_active'
}

/** input type for updating data in table "growth_cycle" */
export type GrowthCycleSetInput = {
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  environment_recipe_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  light_cycle_recipe_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type GrowthCycleStddevFields = {
  __typename?: 'growth_cycle_stddev_fields';
  environment_recipe_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  light_cycle_recipe_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "growth_cycle" */
export type GrowthCycleStddevOrderBy = {
  environment_recipe_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  light_cycle_recipe_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type GrowthCycleStddevPopFields = {
  __typename?: 'growth_cycle_stddev_pop_fields';
  environment_recipe_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  light_cycle_recipe_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "growth_cycle" */
export type GrowthCycleStddevPopOrderBy = {
  environment_recipe_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  light_cycle_recipe_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type GrowthCycleStddevSampFields = {
  __typename?: 'growth_cycle_stddev_samp_fields';
  environment_recipe_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  light_cycle_recipe_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "growth_cycle" */
export type GrowthCycleStddevSampOrderBy = {
  environment_recipe_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  light_cycle_recipe_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "growth_cycle" */
export type GrowthCycleStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: GrowthCycleStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type GrowthCycleStreamCursorValueInput = {
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  environment_recipe_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  light_cycle_recipe_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type GrowthCycleSumFields = {
  __typename?: 'growth_cycle_sum_fields';
  environment_recipe_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  light_cycle_recipe_id?: Maybe<Scalars['Int']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "growth_cycle" */
export type GrowthCycleSumOrderBy = {
  environment_recipe_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  light_cycle_recipe_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** update columns of table "growth_cycle" */
export enum GrowthCycleUpdateColumn {
  /** column name */
  end_time = 'end_time',
  /** column name */
  environment_recipe_id = 'environment_recipe_id',
  /** column name */
  id = 'id',
  /** column name */
  is_active = 'is_active',
  /** column name */
  light_cycle_recipe_id = 'light_cycle_recipe_id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  start_time = 'start_time',
  /** column name */
  zone_id = 'zone_id'
}

export type GrowthCycleUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<GrowthCycleAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<GrowthCycleDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<GrowthCycleDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<GrowthCycleDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<GrowthCycleIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<GrowthCyclePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<GrowthCycleSetInput>;
  /** filter the rows which have to be updated */
  where: GrowthCycleBoolExp;
};

/** aggregate var_pop on columns */
export type GrowthCycleVarPopFields = {
  __typename?: 'growth_cycle_var_pop_fields';
  environment_recipe_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  light_cycle_recipe_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "growth_cycle" */
export type GrowthCycleVarPopOrderBy = {
  environment_recipe_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  light_cycle_recipe_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type GrowthCycleVarSampFields = {
  __typename?: 'growth_cycle_var_samp_fields';
  environment_recipe_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  light_cycle_recipe_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "growth_cycle" */
export type GrowthCycleVarSampOrderBy = {
  environment_recipe_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  light_cycle_recipe_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type GrowthCycleVarianceFields = {
  __typename?: 'growth_cycle_variance_fields';
  environment_recipe_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  light_cycle_recipe_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "growth_cycle" */
export type GrowthCycleVarianceOrderBy = {
  environment_recipe_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  light_cycle_recipe_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "heat_map" */
export type HeatMap = {
  __typename?: 'heat_map';
  data: Scalars['jsonb']['output'];
  /** An array relationship */
  discussions: Array<Discussion>;
  /** An aggregate relationship */
  discussions_aggregate: DiscussionAggregate;
  /** An object relationship */
  enumeration: Enumeration;
  id: Scalars['Int']['output'];
  /** An object relationship */
  lambda_run: LambdaRun;
  lambda_run_id: Scalars['Int']['output'];
  /** An object relationship */
  measurement_run: MeasurementRun;
  measurement_run_id: Scalars['Int']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  type_id: Scalars['Int']['output'];
};


/** columns and relationships of "heat_map" */
export type HeatMapDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "heat_map" */
export type HeatMapDiscussionsArgs = {
  distinct_on?: InputMaybe<Array<DiscussionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DiscussionOrderBy>>;
  where?: InputMaybe<DiscussionBoolExp>;
};


/** columns and relationships of "heat_map" */
export type HeatMapDiscussionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DiscussionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DiscussionOrderBy>>;
  where?: InputMaybe<DiscussionBoolExp>;
};


/** columns and relationships of "heat_map" */
export type HeatMapMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "heat_map" */
export type HeatMapAggregate = {
  __typename?: 'heat_map_aggregate';
  aggregate?: Maybe<HeatMapAggregateFields>;
  nodes: Array<HeatMap>;
};

export type HeatMapAggregateBoolExp = {
  count?: InputMaybe<HeatMapAggregateBoolExpCount>;
};

export type HeatMapAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<HeatMapSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<HeatMapBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "heat_map" */
export type HeatMapAggregateFields = {
  __typename?: 'heat_map_aggregate_fields';
  avg?: Maybe<HeatMapAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<HeatMapMaxFields>;
  min?: Maybe<HeatMapMinFields>;
  stddev?: Maybe<HeatMapStddevFields>;
  stddev_pop?: Maybe<HeatMapStddevPopFields>;
  stddev_samp?: Maybe<HeatMapStddevSampFields>;
  sum?: Maybe<HeatMapSumFields>;
  var_pop?: Maybe<HeatMapVarPopFields>;
  var_samp?: Maybe<HeatMapVarSampFields>;
  variance?: Maybe<HeatMapVarianceFields>;
};


/** aggregate fields of "heat_map" */
export type HeatMapAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<HeatMapSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "heat_map" */
export type HeatMapAggregateOrderBy = {
  avg?: InputMaybe<HeatMapAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<HeatMapMaxOrderBy>;
  min?: InputMaybe<HeatMapMinOrderBy>;
  stddev?: InputMaybe<HeatMapStddevOrderBy>;
  stddev_pop?: InputMaybe<HeatMapStddevPopOrderBy>;
  stddev_samp?: InputMaybe<HeatMapStddevSampOrderBy>;
  sum?: InputMaybe<HeatMapSumOrderBy>;
  var_pop?: InputMaybe<HeatMapVarPopOrderBy>;
  var_samp?: InputMaybe<HeatMapVarSampOrderBy>;
  variance?: InputMaybe<HeatMapVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type HeatMapAppendInput = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "heat_map" */
export type HeatMapArrRelInsertInput = {
  data: Array<HeatMapInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<HeatMapOnConflict>;
};

/** aggregate avg on columns */
export type HeatMapAvgFields = {
  __typename?: 'heat_map_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  lambda_run_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "heat_map" */
export type HeatMapAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "heat_map". All fields are combined with a logical 'AND'. */
export type HeatMapBoolExp = {
  _and?: InputMaybe<Array<HeatMapBoolExp>>;
  _not?: InputMaybe<HeatMapBoolExp>;
  _or?: InputMaybe<Array<HeatMapBoolExp>>;
  data?: InputMaybe<JsonbComparisonExp>;
  discussions?: InputMaybe<DiscussionBoolExp>;
  discussions_aggregate?: InputMaybe<DiscussionAggregateBoolExp>;
  enumeration?: InputMaybe<EnumerationBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  lambda_run?: InputMaybe<LambdaRunBoolExp>;
  lambda_run_id?: InputMaybe<IntComparisonExp>;
  measurement_run?: InputMaybe<MeasurementRunBoolExp>;
  measurement_run_id?: InputMaybe<IntComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  type_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "heat_map" */
export enum HeatMapConstraint {
  /** unique or primary key constraint on columns "id" */
  heat_map_pkey = 'heat_map_pkey',
  /** unique or primary key constraint on columns "measurement_run_id", "lambda_run_id", "type_id" */
  heat_map_un = 'heat_map_un'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type HeatMapDeleteAtPathInput = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type HeatMapDeleteElemInput = {
  data?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type HeatMapDeleteKeyInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "heat_map" */
export type HeatMapIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  lambda_run_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "heat_map" */
export type HeatMapInsertInput = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  discussions?: InputMaybe<DiscussionArrRelInsertInput>;
  enumeration?: InputMaybe<EnumerationObjRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lambda_run?: InputMaybe<LambdaRunObjRelInsertInput>;
  lambda_run_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_run?: InputMaybe<MeasurementRunObjRelInsertInput>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type HeatMapMaxFields = {
  __typename?: 'heat_map_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  lambda_run_id?: Maybe<Scalars['Int']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "heat_map" */
export type HeatMapMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type HeatMapMinFields = {
  __typename?: 'heat_map_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  lambda_run_id?: Maybe<Scalars['Int']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "heat_map" */
export type HeatMapMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "heat_map" */
export type HeatMapMutationResponse = {
  __typename?: 'heat_map_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<HeatMap>;
};

/** input type for inserting object relation for remote table "heat_map" */
export type HeatMapObjRelInsertInput = {
  data: HeatMapInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<HeatMapOnConflict>;
};

/** on_conflict condition type for table "heat_map" */
export type HeatMapOnConflict = {
  constraint: HeatMapConstraint;
  update_columns?: Array<HeatMapUpdateColumn>;
  where?: InputMaybe<HeatMapBoolExp>;
};

/** Ordering options when selecting data from "heat_map". */
export type HeatMapOrderBy = {
  data?: InputMaybe<OrderBy>;
  discussions_aggregate?: InputMaybe<DiscussionAggregateOrderBy>;
  enumeration?: InputMaybe<EnumerationOrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_run?: InputMaybe<LambdaRunOrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run?: InputMaybe<MeasurementRunOrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: heat_map */
export type HeatMapPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type HeatMapPrependInput = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "heat_map" */
export enum HeatMapSelectColumn {
  /** column name */
  data = 'data',
  /** column name */
  id = 'id',
  /** column name */
  lambda_run_id = 'lambda_run_id',
  /** column name */
  measurement_run_id = 'measurement_run_id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  type_id = 'type_id'
}

/** input type for updating data in table "heat_map" */
export type HeatMapSetInput = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lambda_run_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type HeatMapStddevFields = {
  __typename?: 'heat_map_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  lambda_run_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "heat_map" */
export type HeatMapStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type HeatMapStddevPopFields = {
  __typename?: 'heat_map_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  lambda_run_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "heat_map" */
export type HeatMapStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type HeatMapStddevSampFields = {
  __typename?: 'heat_map_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  lambda_run_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "heat_map" */
export type HeatMapStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "heat_map" */
export type HeatMapStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: HeatMapStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type HeatMapStreamCursorValueInput = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lambda_run_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type HeatMapSumFields = {
  __typename?: 'heat_map_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  lambda_run_id?: Maybe<Scalars['Int']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "heat_map" */
export type HeatMapSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** update columns of table "heat_map" */
export enum HeatMapUpdateColumn {
  /** column name */
  data = 'data',
  /** column name */
  id = 'id',
  /** column name */
  lambda_run_id = 'lambda_run_id',
  /** column name */
  measurement_run_id = 'measurement_run_id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  type_id = 'type_id'
}

export type HeatMapUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<HeatMapAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<HeatMapDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<HeatMapDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<HeatMapDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<HeatMapIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<HeatMapPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<HeatMapSetInput>;
  /** filter the rows which have to be updated */
  where: HeatMapBoolExp;
};

/** aggregate var_pop on columns */
export type HeatMapVarPopFields = {
  __typename?: 'heat_map_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  lambda_run_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "heat_map" */
export type HeatMapVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type HeatMapVarSampFields = {
  __typename?: 'heat_map_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  lambda_run_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "heat_map" */
export type HeatMapVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type HeatMapVarianceFields = {
  __typename?: 'heat_map_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  lambda_run_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "heat_map" */
export type HeatMapVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "image_annotation_tracking" */
export type ImageAnnotationTracking = {
  __typename?: 'image_annotation_tracking';
  id: Scalars['Int']['output'];
  /** An object relationship */
  measurement: Measurement;
  measurement_id: Scalars['Int']['output'];
  time: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: User;
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "image_annotation_tracking" */
export type ImageAnnotationTrackingAggregate = {
  __typename?: 'image_annotation_tracking_aggregate';
  aggregate?: Maybe<ImageAnnotationTrackingAggregateFields>;
  nodes: Array<ImageAnnotationTracking>;
};

export type ImageAnnotationTrackingAggregateBoolExp = {
  count?: InputMaybe<ImageAnnotationTrackingAggregateBoolExpCount>;
};

export type ImageAnnotationTrackingAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ImageAnnotationTrackingSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ImageAnnotationTrackingBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "image_annotation_tracking" */
export type ImageAnnotationTrackingAggregateFields = {
  __typename?: 'image_annotation_tracking_aggregate_fields';
  avg?: Maybe<ImageAnnotationTrackingAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ImageAnnotationTrackingMaxFields>;
  min?: Maybe<ImageAnnotationTrackingMinFields>;
  stddev?: Maybe<ImageAnnotationTrackingStddevFields>;
  stddev_pop?: Maybe<ImageAnnotationTrackingStddevPopFields>;
  stddev_samp?: Maybe<ImageAnnotationTrackingStddevSampFields>;
  sum?: Maybe<ImageAnnotationTrackingSumFields>;
  var_pop?: Maybe<ImageAnnotationTrackingVarPopFields>;
  var_samp?: Maybe<ImageAnnotationTrackingVarSampFields>;
  variance?: Maybe<ImageAnnotationTrackingVarianceFields>;
};


/** aggregate fields of "image_annotation_tracking" */
export type ImageAnnotationTrackingAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ImageAnnotationTrackingSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "image_annotation_tracking" */
export type ImageAnnotationTrackingAggregateOrderBy = {
  avg?: InputMaybe<ImageAnnotationTrackingAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ImageAnnotationTrackingMaxOrderBy>;
  min?: InputMaybe<ImageAnnotationTrackingMinOrderBy>;
  stddev?: InputMaybe<ImageAnnotationTrackingStddevOrderBy>;
  stddev_pop?: InputMaybe<ImageAnnotationTrackingStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ImageAnnotationTrackingStddevSampOrderBy>;
  sum?: InputMaybe<ImageAnnotationTrackingSumOrderBy>;
  var_pop?: InputMaybe<ImageAnnotationTrackingVarPopOrderBy>;
  var_samp?: InputMaybe<ImageAnnotationTrackingVarSampOrderBy>;
  variance?: InputMaybe<ImageAnnotationTrackingVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "image_annotation_tracking" */
export type ImageAnnotationTrackingArrRelInsertInput = {
  data: Array<ImageAnnotationTrackingInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ImageAnnotationTrackingOnConflict>;
};

/** aggregate avg on columns */
export type ImageAnnotationTrackingAvgFields = {
  __typename?: 'image_annotation_tracking_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "image_annotation_tracking" */
export type ImageAnnotationTrackingAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "image_annotation_tracking". All fields are combined with a logical 'AND'. */
export type ImageAnnotationTrackingBoolExp = {
  _and?: InputMaybe<Array<ImageAnnotationTrackingBoolExp>>;
  _not?: InputMaybe<ImageAnnotationTrackingBoolExp>;
  _or?: InputMaybe<Array<ImageAnnotationTrackingBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  measurement?: InputMaybe<MeasurementBoolExp>;
  measurement_id?: InputMaybe<IntComparisonExp>;
  time?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  user_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "image_annotation_tracking" */
export enum ImageAnnotationTrackingConstraint {
  /** unique or primary key constraint on columns "id" */
  image_annotation_tracking_pkey = 'image_annotation_tracking_pkey'
}

/** input type for incrementing numeric columns in table "image_annotation_tracking" */
export type ImageAnnotationTrackingIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  measurement_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "image_annotation_tracking" */
export type ImageAnnotationTrackingInsertInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  measurement?: InputMaybe<MeasurementObjRelInsertInput>;
  measurement_id?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type ImageAnnotationTrackingMaxFields = {
  __typename?: 'image_annotation_tracking_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  measurement_id?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "image_annotation_tracking" */
export type ImageAnnotationTrackingMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  time?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ImageAnnotationTrackingMinFields = {
  __typename?: 'image_annotation_tracking_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  measurement_id?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "image_annotation_tracking" */
export type ImageAnnotationTrackingMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  time?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "image_annotation_tracking" */
export type ImageAnnotationTrackingMutationResponse = {
  __typename?: 'image_annotation_tracking_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ImageAnnotationTracking>;
};

/** on_conflict condition type for table "image_annotation_tracking" */
export type ImageAnnotationTrackingOnConflict = {
  constraint: ImageAnnotationTrackingConstraint;
  update_columns?: Array<ImageAnnotationTrackingUpdateColumn>;
  where?: InputMaybe<ImageAnnotationTrackingBoolExp>;
};

/** Ordering options when selecting data from "image_annotation_tracking". */
export type ImageAnnotationTrackingOrderBy = {
  id?: InputMaybe<OrderBy>;
  measurement?: InputMaybe<MeasurementOrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  time?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: image_annotation_tracking */
export type ImageAnnotationTrackingPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** select columns of table "image_annotation_tracking" */
export enum ImageAnnotationTrackingSelectColumn {
  /** column name */
  id = 'id',
  /** column name */
  measurement_id = 'measurement_id',
  /** column name */
  time = 'time',
  /** column name */
  user_id = 'user_id'
}

/** input type for updating data in table "image_annotation_tracking" */
export type ImageAnnotationTrackingSetInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  measurement_id?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type ImageAnnotationTrackingStddevFields = {
  __typename?: 'image_annotation_tracking_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "image_annotation_tracking" */
export type ImageAnnotationTrackingStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ImageAnnotationTrackingStddevPopFields = {
  __typename?: 'image_annotation_tracking_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "image_annotation_tracking" */
export type ImageAnnotationTrackingStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ImageAnnotationTrackingStddevSampFields = {
  __typename?: 'image_annotation_tracking_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "image_annotation_tracking" */
export type ImageAnnotationTrackingStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "image_annotation_tracking" */
export type ImageAnnotationTrackingStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ImageAnnotationTrackingStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ImageAnnotationTrackingStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  measurement_id?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type ImageAnnotationTrackingSumFields = {
  __typename?: 'image_annotation_tracking_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  measurement_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "image_annotation_tracking" */
export type ImageAnnotationTrackingSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** update columns of table "image_annotation_tracking" */
export enum ImageAnnotationTrackingUpdateColumn {
  /** column name */
  id = 'id',
  /** column name */
  measurement_id = 'measurement_id',
  /** column name */
  time = 'time',
  /** column name */
  user_id = 'user_id'
}

export type ImageAnnotationTrackingUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ImageAnnotationTrackingIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ImageAnnotationTrackingSetInput>;
  /** filter the rows which have to be updated */
  where: ImageAnnotationTrackingBoolExp;
};

/** aggregate var_pop on columns */
export type ImageAnnotationTrackingVarPopFields = {
  __typename?: 'image_annotation_tracking_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "image_annotation_tracking" */
export type ImageAnnotationTrackingVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ImageAnnotationTrackingVarSampFields = {
  __typename?: 'image_annotation_tracking_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "image_annotation_tracking" */
export type ImageAnnotationTrackingVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ImageAnnotationTrackingVarianceFields = {
  __typename?: 'image_annotation_tracking_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "image_annotation_tracking" */
export type ImageAnnotationTrackingVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "image_data" */
export type ImageData = {
  __typename?: 'image_data';
  col_idx?: Maybe<Scalars['bigint']['output']>;
  coordinates?: Maybe<Scalars['geometry']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_path?: Maybe<Scalars['String']['output']>;
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  rotation?: Maybe<Scalars['geometry']['output']>;
  row_idx?: Maybe<Scalars['bigint']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "image_data" */
export type ImageDataAggregate = {
  __typename?: 'image_data_aggregate';
  aggregate?: Maybe<ImageDataAggregateFields>;
  nodes: Array<ImageData>;
};

/** aggregate fields of "image_data" */
export type ImageDataAggregateFields = {
  __typename?: 'image_data_aggregate_fields';
  avg?: Maybe<ImageDataAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ImageDataMaxFields>;
  min?: Maybe<ImageDataMinFields>;
  stddev?: Maybe<ImageDataStddevFields>;
  stddev_pop?: Maybe<ImageDataStddevPopFields>;
  stddev_samp?: Maybe<ImageDataStddevSampFields>;
  sum?: Maybe<ImageDataSumFields>;
  var_pop?: Maybe<ImageDataVarPopFields>;
  var_samp?: Maybe<ImageDataVarSampFields>;
  variance?: Maybe<ImageDataVarianceFields>;
};


/** aggregate fields of "image_data" */
export type ImageDataAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ImageDataSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type ImageDataAvgFields = {
  __typename?: 'image_data_avg_fields';
  col_idx?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  row_idx?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "image_data". All fields are combined with a logical 'AND'. */
export type ImageDataBoolExp = {
  _and?: InputMaybe<Array<ImageDataBoolExp>>;
  _not?: InputMaybe<ImageDataBoolExp>;
  _or?: InputMaybe<Array<ImageDataBoolExp>>;
  col_idx?: InputMaybe<BigintComparisonExp>;
  coordinates?: InputMaybe<GeometryComparisonExp>;
  id?: InputMaybe<BigintComparisonExp>;
  image_path?: InputMaybe<StringComparisonExp>;
  measurement_id?: InputMaybe<BigintComparisonExp>;
  measurement_run_id?: InputMaybe<IntComparisonExp>;
  rotation?: InputMaybe<GeometryComparisonExp>;
  row_idx?: InputMaybe<BigintComparisonExp>;
  time?: InputMaybe<TimestamptzComparisonExp>;
};

/** aggregate max on columns */
export type ImageDataMaxFields = {
  __typename?: 'image_data_max_fields';
  col_idx?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_path?: Maybe<Scalars['String']['output']>;
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  row_idx?: Maybe<Scalars['bigint']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type ImageDataMinFields = {
  __typename?: 'image_data_min_fields';
  col_idx?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_path?: Maybe<Scalars['String']['output']>;
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  row_idx?: Maybe<Scalars['bigint']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
};

/** Ordering options when selecting data from "image_data". */
export type ImageDataOrderBy = {
  col_idx?: InputMaybe<OrderBy>;
  coordinates?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image_path?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  rotation?: InputMaybe<OrderBy>;
  row_idx?: InputMaybe<OrderBy>;
  time?: InputMaybe<OrderBy>;
};

/** select columns of table "image_data" */
export enum ImageDataSelectColumn {
  /** column name */
  col_idx = 'col_idx',
  /** column name */
  coordinates = 'coordinates',
  /** column name */
  id = 'id',
  /** column name */
  image_path = 'image_path',
  /** column name */
  measurement_id = 'measurement_id',
  /** column name */
  measurement_run_id = 'measurement_run_id',
  /** column name */
  rotation = 'rotation',
  /** column name */
  row_idx = 'row_idx',
  /** column name */
  time = 'time'
}

/** aggregate stddev on columns */
export type ImageDataStddevFields = {
  __typename?: 'image_data_stddev_fields';
  col_idx?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  row_idx?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ImageDataStddevPopFields = {
  __typename?: 'image_data_stddev_pop_fields';
  col_idx?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  row_idx?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ImageDataStddevSampFields = {
  __typename?: 'image_data_stddev_samp_fields';
  col_idx?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  row_idx?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "image_data" */
export type ImageDataStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ImageDataStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ImageDataStreamCursorValueInput = {
  col_idx?: InputMaybe<Scalars['bigint']['input']>;
  coordinates?: InputMaybe<Scalars['geometry']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_path?: InputMaybe<Scalars['String']['input']>;
  measurement_id?: InputMaybe<Scalars['bigint']['input']>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
  rotation?: InputMaybe<Scalars['geometry']['input']>;
  row_idx?: InputMaybe<Scalars['bigint']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type ImageDataSumFields = {
  __typename?: 'image_data_sum_fields';
  col_idx?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  row_idx?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type ImageDataVarPopFields = {
  __typename?: 'image_data_var_pop_fields';
  col_idx?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  row_idx?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ImageDataVarSampFields = {
  __typename?: 'image_data_var_samp_fields';
  col_idx?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  row_idx?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ImageDataVarianceFields = {
  __typename?: 'image_data_variance_fields';
  col_idx?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  row_idx?: Maybe<Scalars['Float']['output']>;
};

export type JsonbCastExp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** columns and relationships of "label" */
export type Label = {
  __typename?: 'label';
  active: Scalars['Boolean']['output'];
  created_time: Scalars['timestamptz']['output'];
  /** An object relationship */
  enumeration: Enumeration;
  /** An object relationship */
  enumerationByLabelSourceTypeId: Enumeration;
  /** An object relationship */
  enumerationByLabelTypeId: Enumeration;
  /** An object relationship */
  enumerationByStatusId: Enumeration;
  human_source_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  label_name_id: Scalars['Int']['output'];
  label_source_type_id: Scalars['Int']['output'];
  label_type_id: Scalars['Int']['output'];
  lambda_source_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  lambda_version?: Maybe<LambdaVersion>;
  /** An object relationship */
  measurement?: Maybe<Measurement>;
  measurement_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  measurement_run?: Maybe<MeasurementRun>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  reviewed_by_id?: Maybe<Scalars['Int']['output']>;
  status_id: Scalars['Int']['output'];
  /** An object relationship */
  user?: Maybe<User>;
  /** An object relationship */
  userByHumanSourceId?: Maybe<User>;
};


/** columns and relationships of "label" */
export type LabelMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "label" */
export type LabelAggregate = {
  __typename?: 'label_aggregate';
  aggregate?: Maybe<LabelAggregateFields>;
  nodes: Array<Label>;
};

export type LabelAggregateBoolExp = {
  bool_and?: InputMaybe<LabelAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<LabelAggregateBoolExpBoolOr>;
  count?: InputMaybe<LabelAggregateBoolExpCount>;
};

export type LabelAggregateBoolExpBoolAnd = {
  arguments: LabelSelectColumnLabelAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<LabelBoolExp>;
  predicate: BooleanComparisonExp;
};

export type LabelAggregateBoolExpBoolOr = {
  arguments: LabelSelectColumnLabelAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<LabelBoolExp>;
  predicate: BooleanComparisonExp;
};

export type LabelAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<LabelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<LabelBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "label" */
export type LabelAggregateFields = {
  __typename?: 'label_aggregate_fields';
  avg?: Maybe<LabelAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<LabelMaxFields>;
  min?: Maybe<LabelMinFields>;
  stddev?: Maybe<LabelStddevFields>;
  stddev_pop?: Maybe<LabelStddevPopFields>;
  stddev_samp?: Maybe<LabelStddevSampFields>;
  sum?: Maybe<LabelSumFields>;
  var_pop?: Maybe<LabelVarPopFields>;
  var_samp?: Maybe<LabelVarSampFields>;
  variance?: Maybe<LabelVarianceFields>;
};


/** aggregate fields of "label" */
export type LabelAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LabelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "label" */
export type LabelAggregateOrderBy = {
  avg?: InputMaybe<LabelAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<LabelMaxOrderBy>;
  min?: InputMaybe<LabelMinOrderBy>;
  stddev?: InputMaybe<LabelStddevOrderBy>;
  stddev_pop?: InputMaybe<LabelStddevPopOrderBy>;
  stddev_samp?: InputMaybe<LabelStddevSampOrderBy>;
  sum?: InputMaybe<LabelSumOrderBy>;
  var_pop?: InputMaybe<LabelVarPopOrderBy>;
  var_samp?: InputMaybe<LabelVarSampOrderBy>;
  variance?: InputMaybe<LabelVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type LabelAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "label" */
export type LabelArrRelInsertInput = {
  data: Array<LabelInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<LabelOnConflict>;
};

/** aggregate avg on columns */
export type LabelAvgFields = {
  __typename?: 'label_avg_fields';
  human_source_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  label_name_id?: Maybe<Scalars['Float']['output']>;
  label_source_type_id?: Maybe<Scalars['Float']['output']>;
  label_type_id?: Maybe<Scalars['Float']['output']>;
  lambda_source_id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  reviewed_by_id?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "label" */
export type LabelAvgOrderBy = {
  human_source_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label_name_id?: InputMaybe<OrderBy>;
  label_source_type_id?: InputMaybe<OrderBy>;
  label_type_id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  reviewed_by_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "label". All fields are combined with a logical 'AND'. */
export type LabelBoolExp = {
  _and?: InputMaybe<Array<LabelBoolExp>>;
  _not?: InputMaybe<LabelBoolExp>;
  _or?: InputMaybe<Array<LabelBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  created_time?: InputMaybe<TimestamptzComparisonExp>;
  enumeration?: InputMaybe<EnumerationBoolExp>;
  enumerationByLabelSourceTypeId?: InputMaybe<EnumerationBoolExp>;
  enumerationByLabelTypeId?: InputMaybe<EnumerationBoolExp>;
  enumerationByStatusId?: InputMaybe<EnumerationBoolExp>;
  human_source_id?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  label_name_id?: InputMaybe<IntComparisonExp>;
  label_source_type_id?: InputMaybe<IntComparisonExp>;
  label_type_id?: InputMaybe<IntComparisonExp>;
  lambda_source_id?: InputMaybe<IntComparisonExp>;
  lambda_version?: InputMaybe<LambdaVersionBoolExp>;
  measurement?: InputMaybe<MeasurementBoolExp>;
  measurement_id?: InputMaybe<IntComparisonExp>;
  measurement_run?: InputMaybe<MeasurementRunBoolExp>;
  measurement_run_id?: InputMaybe<IntComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  reviewed_by_id?: InputMaybe<IntComparisonExp>;
  status_id?: InputMaybe<IntComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  userByHumanSourceId?: InputMaybe<UserBoolExp>;
};

/** columns and relationships of "label_category" */
export type LabelCategory = {
  __typename?: 'label_category';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  /** An object relationship */
  enumeration?: Maybe<Enumeration>;
  enumeration_id?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** An object relationship */
  label_category?: Maybe<LabelCategory>;
  /** An array relationship */
  label_categorys: Array<LabelCategory>;
  /** An aggregate relationship */
  label_categorys_aggregate: LabelCategoryAggregate;
  parent_label_category_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "label_category" */
export type LabelCategoryLabelCategorysArgs = {
  distinct_on?: InputMaybe<Array<LabelCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelCategoryOrderBy>>;
  where?: InputMaybe<LabelCategoryBoolExp>;
};


/** columns and relationships of "label_category" */
export type LabelCategoryLabelCategorysAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelCategoryOrderBy>>;
  where?: InputMaybe<LabelCategoryBoolExp>;
};

/** aggregated selection of "label_category" */
export type LabelCategoryAggregate = {
  __typename?: 'label_category_aggregate';
  aggregate?: Maybe<LabelCategoryAggregateFields>;
  nodes: Array<LabelCategory>;
};

export type LabelCategoryAggregateBoolExp = {
  count?: InputMaybe<LabelCategoryAggregateBoolExpCount>;
};

export type LabelCategoryAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<LabelCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<LabelCategoryBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "label_category" */
export type LabelCategoryAggregateFields = {
  __typename?: 'label_category_aggregate_fields';
  avg?: Maybe<LabelCategoryAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<LabelCategoryMaxFields>;
  min?: Maybe<LabelCategoryMinFields>;
  stddev?: Maybe<LabelCategoryStddevFields>;
  stddev_pop?: Maybe<LabelCategoryStddevPopFields>;
  stddev_samp?: Maybe<LabelCategoryStddevSampFields>;
  sum?: Maybe<LabelCategorySumFields>;
  var_pop?: Maybe<LabelCategoryVarPopFields>;
  var_samp?: Maybe<LabelCategoryVarSampFields>;
  variance?: Maybe<LabelCategoryVarianceFields>;
};


/** aggregate fields of "label_category" */
export type LabelCategoryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LabelCategorySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "label_category" */
export type LabelCategoryAggregateOrderBy = {
  avg?: InputMaybe<LabelCategoryAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<LabelCategoryMaxOrderBy>;
  min?: InputMaybe<LabelCategoryMinOrderBy>;
  stddev?: InputMaybe<LabelCategoryStddevOrderBy>;
  stddev_pop?: InputMaybe<LabelCategoryStddevPopOrderBy>;
  stddev_samp?: InputMaybe<LabelCategoryStddevSampOrderBy>;
  sum?: InputMaybe<LabelCategorySumOrderBy>;
  var_pop?: InputMaybe<LabelCategoryVarPopOrderBy>;
  var_samp?: InputMaybe<LabelCategoryVarSampOrderBy>;
  variance?: InputMaybe<LabelCategoryVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "label_category" */
export type LabelCategoryArrRelInsertInput = {
  data: Array<LabelCategoryInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<LabelCategoryOnConflict>;
};

/** aggregate avg on columns */
export type LabelCategoryAvgFields = {
  __typename?: 'label_category_avg_fields';
  enumeration_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  parent_label_category_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "label_category" */
export type LabelCategoryAvgOrderBy = {
  enumeration_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parent_label_category_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "label_category". All fields are combined with a logical 'AND'. */
export type LabelCategoryBoolExp = {
  _and?: InputMaybe<Array<LabelCategoryBoolExp>>;
  _not?: InputMaybe<LabelCategoryBoolExp>;
  _or?: InputMaybe<Array<LabelCategoryBoolExp>>;
  code?: InputMaybe<StringComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  enumeration?: InputMaybe<EnumerationBoolExp>;
  enumeration_id?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  label_category?: InputMaybe<LabelCategoryBoolExp>;
  label_categorys?: InputMaybe<LabelCategoryBoolExp>;
  label_categorys_aggregate?: InputMaybe<LabelCategoryAggregateBoolExp>;
  parent_label_category_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "label_category" */
export enum LabelCategoryConstraint {
  /** unique or primary key constraint on columns "id" */
  label_category_pkey = 'label_category_pkey'
}

/** input type for incrementing numeric columns in table "label_category" */
export type LabelCategoryIncInput = {
  enumeration_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  parent_label_category_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "label_category" */
export type LabelCategoryInsertInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enumeration?: InputMaybe<EnumerationObjRelInsertInput>;
  enumeration_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label_category?: InputMaybe<LabelCategoryObjRelInsertInput>;
  label_categorys?: InputMaybe<LabelCategoryArrRelInsertInput>;
  parent_label_category_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type LabelCategoryMaxFields = {
  __typename?: 'label_category_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enumeration_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  parent_label_category_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "label_category" */
export type LabelCategoryMaxOrderBy = {
  code?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  enumeration_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parent_label_category_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type LabelCategoryMinFields = {
  __typename?: 'label_category_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enumeration_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  parent_label_category_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "label_category" */
export type LabelCategoryMinOrderBy = {
  code?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  enumeration_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parent_label_category_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "label_category" */
export type LabelCategoryMutationResponse = {
  __typename?: 'label_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<LabelCategory>;
};

/** input type for inserting object relation for remote table "label_category" */
export type LabelCategoryObjRelInsertInput = {
  data: LabelCategoryInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<LabelCategoryOnConflict>;
};

/** on_conflict condition type for table "label_category" */
export type LabelCategoryOnConflict = {
  constraint: LabelCategoryConstraint;
  update_columns?: Array<LabelCategoryUpdateColumn>;
  where?: InputMaybe<LabelCategoryBoolExp>;
};

/** Ordering options when selecting data from "label_category". */
export type LabelCategoryOrderBy = {
  code?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  enumeration?: InputMaybe<EnumerationOrderBy>;
  enumeration_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label_category?: InputMaybe<LabelCategoryOrderBy>;
  label_categorys_aggregate?: InputMaybe<LabelCategoryAggregateOrderBy>;
  parent_label_category_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: label_category */
export type LabelCategoryPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** select columns of table "label_category" */
export enum LabelCategorySelectColumn {
  /** column name */
  code = 'code',
  /** column name */
  description = 'description',
  /** column name */
  enumeration_id = 'enumeration_id',
  /** column name */
  id = 'id',
  /** column name */
  parent_label_category_id = 'parent_label_category_id'
}

/** input type for updating data in table "label_category" */
export type LabelCategorySetInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enumeration_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  parent_label_category_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type LabelCategoryStddevFields = {
  __typename?: 'label_category_stddev_fields';
  enumeration_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  parent_label_category_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "label_category" */
export type LabelCategoryStddevOrderBy = {
  enumeration_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parent_label_category_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LabelCategoryStddevPopFields = {
  __typename?: 'label_category_stddev_pop_fields';
  enumeration_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  parent_label_category_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "label_category" */
export type LabelCategoryStddevPopOrderBy = {
  enumeration_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parent_label_category_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LabelCategoryStddevSampFields = {
  __typename?: 'label_category_stddev_samp_fields';
  enumeration_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  parent_label_category_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "label_category" */
export type LabelCategoryStddevSampOrderBy = {
  enumeration_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parent_label_category_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "label_category" */
export type LabelCategoryStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: LabelCategoryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LabelCategoryStreamCursorValueInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enumeration_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  parent_label_category_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type LabelCategorySumFields = {
  __typename?: 'label_category_sum_fields';
  enumeration_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  parent_label_category_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "label_category" */
export type LabelCategorySumOrderBy = {
  enumeration_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parent_label_category_id?: InputMaybe<OrderBy>;
};

/** update columns of table "label_category" */
export enum LabelCategoryUpdateColumn {
  /** column name */
  code = 'code',
  /** column name */
  description = 'description',
  /** column name */
  enumeration_id = 'enumeration_id',
  /** column name */
  id = 'id',
  /** column name */
  parent_label_category_id = 'parent_label_category_id'
}

export type LabelCategoryUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LabelCategoryIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LabelCategorySetInput>;
  /** filter the rows which have to be updated */
  where: LabelCategoryBoolExp;
};

/** aggregate var_pop on columns */
export type LabelCategoryVarPopFields = {
  __typename?: 'label_category_var_pop_fields';
  enumeration_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  parent_label_category_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "label_category" */
export type LabelCategoryVarPopOrderBy = {
  enumeration_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parent_label_category_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LabelCategoryVarSampFields = {
  __typename?: 'label_category_var_samp_fields';
  enumeration_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  parent_label_category_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "label_category" */
export type LabelCategoryVarSampOrderBy = {
  enumeration_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parent_label_category_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type LabelCategoryVarianceFields = {
  __typename?: 'label_category_variance_fields';
  enumeration_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  parent_label_category_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "label_category" */
export type LabelCategoryVarianceOrderBy = {
  enumeration_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  parent_label_category_id?: InputMaybe<OrderBy>;
};

/** unique or primary key constraints on table "label" */
export enum LabelConstraint {
  /** unique or primary key constraint on columns "id" */
  label_pkey = 'label_pkey'
}

/** columns and relationships of "label_counts_by_measurement" */
export type LabelCountsByMeasurement = {
  __typename?: 'label_counts_by_measurement';
  label_counts?: Maybe<Scalars['bigint']['output']>;
  label_ids?: Maybe<Scalars['_int4']['output']>;
  label_name_id?: Maybe<Scalars['Int']['output']>;
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "label_counts_by_measurement" */
export type LabelCountsByMeasurementAggregate = {
  __typename?: 'label_counts_by_measurement_aggregate';
  aggregate?: Maybe<LabelCountsByMeasurementAggregateFields>;
  nodes: Array<LabelCountsByMeasurement>;
};

/** aggregate fields of "label_counts_by_measurement" */
export type LabelCountsByMeasurementAggregateFields = {
  __typename?: 'label_counts_by_measurement_aggregate_fields';
  avg?: Maybe<LabelCountsByMeasurementAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<LabelCountsByMeasurementMaxFields>;
  min?: Maybe<LabelCountsByMeasurementMinFields>;
  stddev?: Maybe<LabelCountsByMeasurementStddevFields>;
  stddev_pop?: Maybe<LabelCountsByMeasurementStddevPopFields>;
  stddev_samp?: Maybe<LabelCountsByMeasurementStddevSampFields>;
  sum?: Maybe<LabelCountsByMeasurementSumFields>;
  var_pop?: Maybe<LabelCountsByMeasurementVarPopFields>;
  var_samp?: Maybe<LabelCountsByMeasurementVarSampFields>;
  variance?: Maybe<LabelCountsByMeasurementVarianceFields>;
};


/** aggregate fields of "label_counts_by_measurement" */
export type LabelCountsByMeasurementAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LabelCountsByMeasurementSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type LabelCountsByMeasurementAvgFields = {
  __typename?: 'label_counts_by_measurement_avg_fields';
  label_counts?: Maybe<Scalars['Float']['output']>;
  label_name_id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "label_counts_by_measurement". All fields are combined with a logical 'AND'. */
export type LabelCountsByMeasurementBoolExp = {
  _and?: InputMaybe<Array<LabelCountsByMeasurementBoolExp>>;
  _not?: InputMaybe<LabelCountsByMeasurementBoolExp>;
  _or?: InputMaybe<Array<LabelCountsByMeasurementBoolExp>>;
  label_counts?: InputMaybe<BigintComparisonExp>;
  label_ids?: InputMaybe<Int4ComparisonExp>;
  label_name_id?: InputMaybe<IntComparisonExp>;
  measurement_id?: InputMaybe<BigintComparisonExp>;
  measurement_run_id?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type LabelCountsByMeasurementMaxFields = {
  __typename?: 'label_counts_by_measurement_max_fields';
  label_counts?: Maybe<Scalars['bigint']['output']>;
  label_name_id?: Maybe<Scalars['Int']['output']>;
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type LabelCountsByMeasurementMinFields = {
  __typename?: 'label_counts_by_measurement_min_fields';
  label_counts?: Maybe<Scalars['bigint']['output']>;
  label_name_id?: Maybe<Scalars['Int']['output']>;
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "label_counts_by_measurement". */
export type LabelCountsByMeasurementOrderBy = {
  label_counts?: InputMaybe<OrderBy>;
  label_ids?: InputMaybe<OrderBy>;
  label_name_id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
};

/** select columns of table "label_counts_by_measurement" */
export enum LabelCountsByMeasurementSelectColumn {
  /** column name */
  label_counts = 'label_counts',
  /** column name */
  label_ids = 'label_ids',
  /** column name */
  label_name_id = 'label_name_id',
  /** column name */
  measurement_id = 'measurement_id',
  /** column name */
  measurement_run_id = 'measurement_run_id'
}

/** aggregate stddev on columns */
export type LabelCountsByMeasurementStddevFields = {
  __typename?: 'label_counts_by_measurement_stddev_fields';
  label_counts?: Maybe<Scalars['Float']['output']>;
  label_name_id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type LabelCountsByMeasurementStddevPopFields = {
  __typename?: 'label_counts_by_measurement_stddev_pop_fields';
  label_counts?: Maybe<Scalars['Float']['output']>;
  label_name_id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type LabelCountsByMeasurementStddevSampFields = {
  __typename?: 'label_counts_by_measurement_stddev_samp_fields';
  label_counts?: Maybe<Scalars['Float']['output']>;
  label_name_id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "label_counts_by_measurement" */
export type LabelCountsByMeasurementStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: LabelCountsByMeasurementStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LabelCountsByMeasurementStreamCursorValueInput = {
  label_counts?: InputMaybe<Scalars['bigint']['input']>;
  label_ids?: InputMaybe<Scalars['_int4']['input']>;
  label_name_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_id?: InputMaybe<Scalars['bigint']['input']>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type LabelCountsByMeasurementSumFields = {
  __typename?: 'label_counts_by_measurement_sum_fields';
  label_counts?: Maybe<Scalars['bigint']['output']>;
  label_name_id?: Maybe<Scalars['Int']['output']>;
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type LabelCountsByMeasurementVarPopFields = {
  __typename?: 'label_counts_by_measurement_var_pop_fields';
  label_counts?: Maybe<Scalars['Float']['output']>;
  label_name_id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type LabelCountsByMeasurementVarSampFields = {
  __typename?: 'label_counts_by_measurement_var_samp_fields';
  label_counts?: Maybe<Scalars['Float']['output']>;
  label_name_id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type LabelCountsByMeasurementVarianceFields = {
  __typename?: 'label_counts_by_measurement_variance_fields';
  label_counts?: Maybe<Scalars['Float']['output']>;
  label_name_id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type LabelDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type LabelDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type LabelDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "label" */
export type LabelIncInput = {
  human_source_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label_name_id?: InputMaybe<Scalars['Int']['input']>;
  label_source_type_id?: InputMaybe<Scalars['Int']['input']>;
  label_type_id?: InputMaybe<Scalars['Int']['input']>;
  lambda_source_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
  reviewed_by_id?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "label" */
export type LabelInsertInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_time?: InputMaybe<Scalars['timestamptz']['input']>;
  enumeration?: InputMaybe<EnumerationObjRelInsertInput>;
  enumerationByLabelSourceTypeId?: InputMaybe<EnumerationObjRelInsertInput>;
  enumerationByLabelTypeId?: InputMaybe<EnumerationObjRelInsertInput>;
  enumerationByStatusId?: InputMaybe<EnumerationObjRelInsertInput>;
  human_source_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label_name_id?: InputMaybe<Scalars['Int']['input']>;
  label_source_type_id?: InputMaybe<Scalars['Int']['input']>;
  label_type_id?: InputMaybe<Scalars['Int']['input']>;
  lambda_source_id?: InputMaybe<Scalars['Int']['input']>;
  lambda_version?: InputMaybe<LambdaVersionObjRelInsertInput>;
  measurement?: InputMaybe<MeasurementObjRelInsertInput>;
  measurement_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_run?: InputMaybe<MeasurementRunObjRelInsertInput>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  reviewed_by_id?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  userByHumanSourceId?: InputMaybe<UserObjRelInsertInput>;
};

/** aggregate max on columns */
export type LabelMaxFields = {
  __typename?: 'label_max_fields';
  created_time?: Maybe<Scalars['timestamptz']['output']>;
  human_source_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label_name_id?: Maybe<Scalars['Int']['output']>;
  label_source_type_id?: Maybe<Scalars['Int']['output']>;
  label_type_id?: Maybe<Scalars['Int']['output']>;
  lambda_source_id?: Maybe<Scalars['Int']['output']>;
  measurement_id?: Maybe<Scalars['Int']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  reviewed_by_id?: Maybe<Scalars['Int']['output']>;
  status_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "label" */
export type LabelMaxOrderBy = {
  created_time?: InputMaybe<OrderBy>;
  human_source_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label_name_id?: InputMaybe<OrderBy>;
  label_source_type_id?: InputMaybe<OrderBy>;
  label_type_id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  reviewed_by_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type LabelMinFields = {
  __typename?: 'label_min_fields';
  created_time?: Maybe<Scalars['timestamptz']['output']>;
  human_source_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label_name_id?: Maybe<Scalars['Int']['output']>;
  label_source_type_id?: Maybe<Scalars['Int']['output']>;
  label_type_id?: Maybe<Scalars['Int']['output']>;
  lambda_source_id?: Maybe<Scalars['Int']['output']>;
  measurement_id?: Maybe<Scalars['Int']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  reviewed_by_id?: Maybe<Scalars['Int']['output']>;
  status_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "label" */
export type LabelMinOrderBy = {
  created_time?: InputMaybe<OrderBy>;
  human_source_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label_name_id?: InputMaybe<OrderBy>;
  label_source_type_id?: InputMaybe<OrderBy>;
  label_type_id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  reviewed_by_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "label" */
export type LabelMutationResponse = {
  __typename?: 'label_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Label>;
};

/** on_conflict condition type for table "label" */
export type LabelOnConflict = {
  constraint: LabelConstraint;
  update_columns?: Array<LabelUpdateColumn>;
  where?: InputMaybe<LabelBoolExp>;
};

/** Ordering options when selecting data from "label". */
export type LabelOrderBy = {
  active?: InputMaybe<OrderBy>;
  created_time?: InputMaybe<OrderBy>;
  enumeration?: InputMaybe<EnumerationOrderBy>;
  enumerationByLabelSourceTypeId?: InputMaybe<EnumerationOrderBy>;
  enumerationByLabelTypeId?: InputMaybe<EnumerationOrderBy>;
  enumerationByStatusId?: InputMaybe<EnumerationOrderBy>;
  human_source_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label_name_id?: InputMaybe<OrderBy>;
  label_source_type_id?: InputMaybe<OrderBy>;
  label_type_id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  lambda_version?: InputMaybe<LambdaVersionOrderBy>;
  measurement?: InputMaybe<MeasurementOrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  measurement_run?: InputMaybe<MeasurementRunOrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  reviewed_by_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  userByHumanSourceId?: InputMaybe<UserOrderBy>;
};

/** primary key columns input for table: label */
export type LabelPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type LabelPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "label" */
export enum LabelSelectColumn {
  /** column name */
  active = 'active',
  /** column name */
  created_time = 'created_time',
  /** column name */
  human_source_id = 'human_source_id',
  /** column name */
  id = 'id',
  /** column name */
  label_name_id = 'label_name_id',
  /** column name */
  label_source_type_id = 'label_source_type_id',
  /** column name */
  label_type_id = 'label_type_id',
  /** column name */
  lambda_source_id = 'lambda_source_id',
  /** column name */
  measurement_id = 'measurement_id',
  /** column name */
  measurement_run_id = 'measurement_run_id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  reviewed_by_id = 'reviewed_by_id',
  /** column name */
  status_id = 'status_id'
}

/** select "label_aggregate_bool_exp_bool_and_arguments_columns" columns of table "label" */
export enum LabelSelectColumnLabelAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  active = 'active'
}

/** select "label_aggregate_bool_exp_bool_or_arguments_columns" columns of table "label" */
export enum LabelSelectColumnLabelAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  active = 'active'
}

/** input type for updating data in table "label" */
export type LabelSetInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_time?: InputMaybe<Scalars['timestamptz']['input']>;
  human_source_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label_name_id?: InputMaybe<Scalars['Int']['input']>;
  label_source_type_id?: InputMaybe<Scalars['Int']['input']>;
  label_type_id?: InputMaybe<Scalars['Int']['input']>;
  lambda_source_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  reviewed_by_id?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type LabelStddevFields = {
  __typename?: 'label_stddev_fields';
  human_source_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  label_name_id?: Maybe<Scalars['Float']['output']>;
  label_source_type_id?: Maybe<Scalars['Float']['output']>;
  label_type_id?: Maybe<Scalars['Float']['output']>;
  lambda_source_id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  reviewed_by_id?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "label" */
export type LabelStddevOrderBy = {
  human_source_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label_name_id?: InputMaybe<OrderBy>;
  label_source_type_id?: InputMaybe<OrderBy>;
  label_type_id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  reviewed_by_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LabelStddevPopFields = {
  __typename?: 'label_stddev_pop_fields';
  human_source_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  label_name_id?: Maybe<Scalars['Float']['output']>;
  label_source_type_id?: Maybe<Scalars['Float']['output']>;
  label_type_id?: Maybe<Scalars['Float']['output']>;
  lambda_source_id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  reviewed_by_id?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "label" */
export type LabelStddevPopOrderBy = {
  human_source_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label_name_id?: InputMaybe<OrderBy>;
  label_source_type_id?: InputMaybe<OrderBy>;
  label_type_id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  reviewed_by_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LabelStddevSampFields = {
  __typename?: 'label_stddev_samp_fields';
  human_source_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  label_name_id?: Maybe<Scalars['Float']['output']>;
  label_source_type_id?: Maybe<Scalars['Float']['output']>;
  label_type_id?: Maybe<Scalars['Float']['output']>;
  lambda_source_id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  reviewed_by_id?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "label" */
export type LabelStddevSampOrderBy = {
  human_source_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label_name_id?: InputMaybe<OrderBy>;
  label_source_type_id?: InputMaybe<OrderBy>;
  label_type_id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  reviewed_by_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "label" */
export type LabelStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: LabelStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LabelStreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_time?: InputMaybe<Scalars['timestamptz']['input']>;
  human_source_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  label_name_id?: InputMaybe<Scalars['Int']['input']>;
  label_source_type_id?: InputMaybe<Scalars['Int']['input']>;
  label_type_id?: InputMaybe<Scalars['Int']['input']>;
  lambda_source_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  reviewed_by_id?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type LabelSumFields = {
  __typename?: 'label_sum_fields';
  human_source_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  label_name_id?: Maybe<Scalars['Int']['output']>;
  label_source_type_id?: Maybe<Scalars['Int']['output']>;
  label_type_id?: Maybe<Scalars['Int']['output']>;
  lambda_source_id?: Maybe<Scalars['Int']['output']>;
  measurement_id?: Maybe<Scalars['Int']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  reviewed_by_id?: Maybe<Scalars['Int']['output']>;
  status_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "label" */
export type LabelSumOrderBy = {
  human_source_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label_name_id?: InputMaybe<OrderBy>;
  label_source_type_id?: InputMaybe<OrderBy>;
  label_type_id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  reviewed_by_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "label_task" */
export type LabelTask = {
  __typename?: 'label_task';
  create_time: Scalars['timestamptz']['output'];
  /** An object relationship */
  detection: Detection;
  detection_id: Scalars['Int']['output'];
  /** An object relationship */
  enumeration?: Maybe<Enumeration>;
  /** An object relationship */
  enumerationByPriorityId?: Maybe<Enumeration>;
  /** An object relationship */
  enumerationByStatusId?: Maybe<Enumeration>;
  id: Scalars['Int']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  minimum_expertise_id?: Maybe<Scalars['Int']['output']>;
  modified_time?: Maybe<Scalars['timestamptz']['output']>;
  priority_id?: Maybe<Scalars['Int']['output']>;
  status_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "label_task" */
export type LabelTaskMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "label_task" */
export type LabelTaskAggregate = {
  __typename?: 'label_task_aggregate';
  aggregate?: Maybe<LabelTaskAggregateFields>;
  nodes: Array<LabelTask>;
};

export type LabelTaskAggregateBoolExp = {
  count?: InputMaybe<LabelTaskAggregateBoolExpCount>;
};

export type LabelTaskAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<LabelTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<LabelTaskBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "label_task" */
export type LabelTaskAggregateFields = {
  __typename?: 'label_task_aggregate_fields';
  avg?: Maybe<LabelTaskAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<LabelTaskMaxFields>;
  min?: Maybe<LabelTaskMinFields>;
  stddev?: Maybe<LabelTaskStddevFields>;
  stddev_pop?: Maybe<LabelTaskStddevPopFields>;
  stddev_samp?: Maybe<LabelTaskStddevSampFields>;
  sum?: Maybe<LabelTaskSumFields>;
  var_pop?: Maybe<LabelTaskVarPopFields>;
  var_samp?: Maybe<LabelTaskVarSampFields>;
  variance?: Maybe<LabelTaskVarianceFields>;
};


/** aggregate fields of "label_task" */
export type LabelTaskAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LabelTaskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "label_task" */
export type LabelTaskAggregateOrderBy = {
  avg?: InputMaybe<LabelTaskAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<LabelTaskMaxOrderBy>;
  min?: InputMaybe<LabelTaskMinOrderBy>;
  stddev?: InputMaybe<LabelTaskStddevOrderBy>;
  stddev_pop?: InputMaybe<LabelTaskStddevPopOrderBy>;
  stddev_samp?: InputMaybe<LabelTaskStddevSampOrderBy>;
  sum?: InputMaybe<LabelTaskSumOrderBy>;
  var_pop?: InputMaybe<LabelTaskVarPopOrderBy>;
  var_samp?: InputMaybe<LabelTaskVarSampOrderBy>;
  variance?: InputMaybe<LabelTaskVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type LabelTaskAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "label_task" */
export type LabelTaskArrRelInsertInput = {
  data: Array<LabelTaskInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<LabelTaskOnConflict>;
};

/** aggregate avg on columns */
export type LabelTaskAvgFields = {
  __typename?: 'label_task_avg_fields';
  detection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  minimum_expertise_id?: Maybe<Scalars['Float']['output']>;
  priority_id?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "label_task" */
export type LabelTaskAvgOrderBy = {
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimum_expertise_id?: InputMaybe<OrderBy>;
  priority_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "label_task". All fields are combined with a logical 'AND'. */
export type LabelTaskBoolExp = {
  _and?: InputMaybe<Array<LabelTaskBoolExp>>;
  _not?: InputMaybe<LabelTaskBoolExp>;
  _or?: InputMaybe<Array<LabelTaskBoolExp>>;
  create_time?: InputMaybe<TimestamptzComparisonExp>;
  detection?: InputMaybe<DetectionBoolExp>;
  detection_id?: InputMaybe<IntComparisonExp>;
  enumeration?: InputMaybe<EnumerationBoolExp>;
  enumerationByPriorityId?: InputMaybe<EnumerationBoolExp>;
  enumerationByStatusId?: InputMaybe<EnumerationBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  minimum_expertise_id?: InputMaybe<IntComparisonExp>;
  modified_time?: InputMaybe<TimestamptzComparisonExp>;
  priority_id?: InputMaybe<IntComparisonExp>;
  status_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "label_task" */
export enum LabelTaskConstraint {
  /** unique or primary key constraint on columns "id" */
  label_task_pkey = 'label_task_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type LabelTaskDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type LabelTaskDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type LabelTaskDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "label_task" */
export type LabelTaskIncInput = {
  detection_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  minimum_expertise_id?: InputMaybe<Scalars['Int']['input']>;
  priority_id?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "label_task" */
export type LabelTaskInsertInput = {
  create_time?: InputMaybe<Scalars['timestamptz']['input']>;
  detection?: InputMaybe<DetectionObjRelInsertInput>;
  detection_id?: InputMaybe<Scalars['Int']['input']>;
  enumeration?: InputMaybe<EnumerationObjRelInsertInput>;
  enumerationByPriorityId?: InputMaybe<EnumerationObjRelInsertInput>;
  enumerationByStatusId?: InputMaybe<EnumerationObjRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  minimum_expertise_id?: InputMaybe<Scalars['Int']['input']>;
  modified_time?: InputMaybe<Scalars['timestamptz']['input']>;
  priority_id?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type LabelTaskMaxFields = {
  __typename?: 'label_task_max_fields';
  create_time?: Maybe<Scalars['timestamptz']['output']>;
  detection_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  minimum_expertise_id?: Maybe<Scalars['Int']['output']>;
  modified_time?: Maybe<Scalars['timestamptz']['output']>;
  priority_id?: Maybe<Scalars['Int']['output']>;
  status_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "label_task" */
export type LabelTaskMaxOrderBy = {
  create_time?: InputMaybe<OrderBy>;
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimum_expertise_id?: InputMaybe<OrderBy>;
  modified_time?: InputMaybe<OrderBy>;
  priority_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type LabelTaskMinFields = {
  __typename?: 'label_task_min_fields';
  create_time?: Maybe<Scalars['timestamptz']['output']>;
  detection_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  minimum_expertise_id?: Maybe<Scalars['Int']['output']>;
  modified_time?: Maybe<Scalars['timestamptz']['output']>;
  priority_id?: Maybe<Scalars['Int']['output']>;
  status_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "label_task" */
export type LabelTaskMinOrderBy = {
  create_time?: InputMaybe<OrderBy>;
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimum_expertise_id?: InputMaybe<OrderBy>;
  modified_time?: InputMaybe<OrderBy>;
  priority_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "label_task" */
export type LabelTaskMutationResponse = {
  __typename?: 'label_task_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<LabelTask>;
};

/** on_conflict condition type for table "label_task" */
export type LabelTaskOnConflict = {
  constraint: LabelTaskConstraint;
  update_columns?: Array<LabelTaskUpdateColumn>;
  where?: InputMaybe<LabelTaskBoolExp>;
};

/** Ordering options when selecting data from "label_task". */
export type LabelTaskOrderBy = {
  create_time?: InputMaybe<OrderBy>;
  detection?: InputMaybe<DetectionOrderBy>;
  detection_id?: InputMaybe<OrderBy>;
  enumeration?: InputMaybe<EnumerationOrderBy>;
  enumerationByPriorityId?: InputMaybe<EnumerationOrderBy>;
  enumerationByStatusId?: InputMaybe<EnumerationOrderBy>;
  id?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  minimum_expertise_id?: InputMaybe<OrderBy>;
  modified_time?: InputMaybe<OrderBy>;
  priority_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: label_task */
export type LabelTaskPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type LabelTaskPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "label_task" */
export enum LabelTaskSelectColumn {
  /** column name */
  create_time = 'create_time',
  /** column name */
  detection_id = 'detection_id',
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  minimum_expertise_id = 'minimum_expertise_id',
  /** column name */
  modified_time = 'modified_time',
  /** column name */
  priority_id = 'priority_id',
  /** column name */
  status_id = 'status_id'
}

/** input type for updating data in table "label_task" */
export type LabelTaskSetInput = {
  create_time?: InputMaybe<Scalars['timestamptz']['input']>;
  detection_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  minimum_expertise_id?: InputMaybe<Scalars['Int']['input']>;
  modified_time?: InputMaybe<Scalars['timestamptz']['input']>;
  priority_id?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type LabelTaskStddevFields = {
  __typename?: 'label_task_stddev_fields';
  detection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  minimum_expertise_id?: Maybe<Scalars['Float']['output']>;
  priority_id?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "label_task" */
export type LabelTaskStddevOrderBy = {
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimum_expertise_id?: InputMaybe<OrderBy>;
  priority_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LabelTaskStddevPopFields = {
  __typename?: 'label_task_stddev_pop_fields';
  detection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  minimum_expertise_id?: Maybe<Scalars['Float']['output']>;
  priority_id?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "label_task" */
export type LabelTaskStddevPopOrderBy = {
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimum_expertise_id?: InputMaybe<OrderBy>;
  priority_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LabelTaskStddevSampFields = {
  __typename?: 'label_task_stddev_samp_fields';
  detection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  minimum_expertise_id?: Maybe<Scalars['Float']['output']>;
  priority_id?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "label_task" */
export type LabelTaskStddevSampOrderBy = {
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimum_expertise_id?: InputMaybe<OrderBy>;
  priority_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "label_task" */
export type LabelTaskStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: LabelTaskStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LabelTaskStreamCursorValueInput = {
  create_time?: InputMaybe<Scalars['timestamptz']['input']>;
  detection_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  minimum_expertise_id?: InputMaybe<Scalars['Int']['input']>;
  modified_time?: InputMaybe<Scalars['timestamptz']['input']>;
  priority_id?: InputMaybe<Scalars['Int']['input']>;
  status_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type LabelTaskSumFields = {
  __typename?: 'label_task_sum_fields';
  detection_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  minimum_expertise_id?: Maybe<Scalars['Int']['output']>;
  priority_id?: Maybe<Scalars['Int']['output']>;
  status_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "label_task" */
export type LabelTaskSumOrderBy = {
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimum_expertise_id?: InputMaybe<OrderBy>;
  priority_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
};

/** update columns of table "label_task" */
export enum LabelTaskUpdateColumn {
  /** column name */
  create_time = 'create_time',
  /** column name */
  detection_id = 'detection_id',
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  minimum_expertise_id = 'minimum_expertise_id',
  /** column name */
  modified_time = 'modified_time',
  /** column name */
  priority_id = 'priority_id',
  /** column name */
  status_id = 'status_id'
}

export type LabelTaskUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<LabelTaskAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<LabelTaskDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<LabelTaskDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<LabelTaskDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LabelTaskIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<LabelTaskPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LabelTaskSetInput>;
  /** filter the rows which have to be updated */
  where: LabelTaskBoolExp;
};

/** aggregate var_pop on columns */
export type LabelTaskVarPopFields = {
  __typename?: 'label_task_var_pop_fields';
  detection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  minimum_expertise_id?: Maybe<Scalars['Float']['output']>;
  priority_id?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "label_task" */
export type LabelTaskVarPopOrderBy = {
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimum_expertise_id?: InputMaybe<OrderBy>;
  priority_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LabelTaskVarSampFields = {
  __typename?: 'label_task_var_samp_fields';
  detection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  minimum_expertise_id?: Maybe<Scalars['Float']['output']>;
  priority_id?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "label_task" */
export type LabelTaskVarSampOrderBy = {
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimum_expertise_id?: InputMaybe<OrderBy>;
  priority_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type LabelTaskVarianceFields = {
  __typename?: 'label_task_variance_fields';
  detection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  minimum_expertise_id?: Maybe<Scalars['Float']['output']>;
  priority_id?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "label_task" */
export type LabelTaskVarianceOrderBy = {
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  minimum_expertise_id?: InputMaybe<OrderBy>;
  priority_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
};

/** update columns of table "label" */
export enum LabelUpdateColumn {
  /** column name */
  active = 'active',
  /** column name */
  created_time = 'created_time',
  /** column name */
  human_source_id = 'human_source_id',
  /** column name */
  id = 'id',
  /** column name */
  label_name_id = 'label_name_id',
  /** column name */
  label_source_type_id = 'label_source_type_id',
  /** column name */
  label_type_id = 'label_type_id',
  /** column name */
  lambda_source_id = 'lambda_source_id',
  /** column name */
  measurement_id = 'measurement_id',
  /** column name */
  measurement_run_id = 'measurement_run_id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  reviewed_by_id = 'reviewed_by_id',
  /** column name */
  status_id = 'status_id'
}

export type LabelUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<LabelAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<LabelDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<LabelDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<LabelDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LabelIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<LabelPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LabelSetInput>;
  /** filter the rows which have to be updated */
  where: LabelBoolExp;
};

/** aggregate var_pop on columns */
export type LabelVarPopFields = {
  __typename?: 'label_var_pop_fields';
  human_source_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  label_name_id?: Maybe<Scalars['Float']['output']>;
  label_source_type_id?: Maybe<Scalars['Float']['output']>;
  label_type_id?: Maybe<Scalars['Float']['output']>;
  lambda_source_id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  reviewed_by_id?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "label" */
export type LabelVarPopOrderBy = {
  human_source_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label_name_id?: InputMaybe<OrderBy>;
  label_source_type_id?: InputMaybe<OrderBy>;
  label_type_id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  reviewed_by_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LabelVarSampFields = {
  __typename?: 'label_var_samp_fields';
  human_source_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  label_name_id?: Maybe<Scalars['Float']['output']>;
  label_source_type_id?: Maybe<Scalars['Float']['output']>;
  label_type_id?: Maybe<Scalars['Float']['output']>;
  lambda_source_id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  reviewed_by_id?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "label" */
export type LabelVarSampOrderBy = {
  human_source_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label_name_id?: InputMaybe<OrderBy>;
  label_source_type_id?: InputMaybe<OrderBy>;
  label_type_id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  reviewed_by_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type LabelVarianceFields = {
  __typename?: 'label_variance_fields';
  human_source_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  label_name_id?: Maybe<Scalars['Float']['output']>;
  label_source_type_id?: Maybe<Scalars['Float']['output']>;
  label_type_id?: Maybe<Scalars['Float']['output']>;
  lambda_source_id?: Maybe<Scalars['Float']['output']>;
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  reviewed_by_id?: Maybe<Scalars['Float']['output']>;
  status_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "label" */
export type LabelVarianceOrderBy = {
  human_source_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  label_name_id?: InputMaybe<OrderBy>;
  label_source_type_id?: InputMaybe<OrderBy>;
  label_type_id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  measurement_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  reviewed_by_id?: InputMaybe<OrderBy>;
  status_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "lambda_run" */
export type LambdaRun = {
  __typename?: 'lambda_run';
  duration_milliseconds?: Maybe<Scalars['bigint']['output']>;
  /** An array relationship */
  heat_maps: Array<HeatMap>;
  /** An aggregate relationship */
  heat_maps_aggregate: HeatMapAggregate;
  id: Scalars['Int']['output'];
  /** An array relationship */
  lambda_run_measurement_runs: Array<LambdaRunMeasurementRun>;
  /** An aggregate relationship */
  lambda_run_measurement_runs_aggregate: LambdaRunMeasurementRunAggregate;
  /** An object relationship */
  lambda_version: LambdaVersion;
  lambda_version_id: Scalars['Int']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  result_code?: Maybe<Scalars['Int']['output']>;
  start_time: Scalars['timestamptz']['output'];
};


/** columns and relationships of "lambda_run" */
export type LambdaRunHeatMapsArgs = {
  distinct_on?: InputMaybe<Array<HeatMapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeatMapOrderBy>>;
  where?: InputMaybe<HeatMapBoolExp>;
};


/** columns and relationships of "lambda_run" */
export type LambdaRunHeatMapsAggregateArgs = {
  distinct_on?: InputMaybe<Array<HeatMapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeatMapOrderBy>>;
  where?: InputMaybe<HeatMapBoolExp>;
};


/** columns and relationships of "lambda_run" */
export type LambdaRunLambdaRunMeasurementRunsArgs = {
  distinct_on?: InputMaybe<Array<LambdaRunMeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LambdaRunMeasurementRunOrderBy>>;
  where?: InputMaybe<LambdaRunMeasurementRunBoolExp>;
};


/** columns and relationships of "lambda_run" */
export type LambdaRunLambdaRunMeasurementRunsAggregateArgs = {
  distinct_on?: InputMaybe<Array<LambdaRunMeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LambdaRunMeasurementRunOrderBy>>;
  where?: InputMaybe<LambdaRunMeasurementRunBoolExp>;
};


/** columns and relationships of "lambda_run" */
export type LambdaRunMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "lambda_run" */
export type LambdaRunAggregate = {
  __typename?: 'lambda_run_aggregate';
  aggregate?: Maybe<LambdaRunAggregateFields>;
  nodes: Array<LambdaRun>;
};

export type LambdaRunAggregateBoolExp = {
  count?: InputMaybe<LambdaRunAggregateBoolExpCount>;
};

export type LambdaRunAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<LambdaRunSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<LambdaRunBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "lambda_run" */
export type LambdaRunAggregateFields = {
  __typename?: 'lambda_run_aggregate_fields';
  avg?: Maybe<LambdaRunAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<LambdaRunMaxFields>;
  min?: Maybe<LambdaRunMinFields>;
  stddev?: Maybe<LambdaRunStddevFields>;
  stddev_pop?: Maybe<LambdaRunStddevPopFields>;
  stddev_samp?: Maybe<LambdaRunStddevSampFields>;
  sum?: Maybe<LambdaRunSumFields>;
  var_pop?: Maybe<LambdaRunVarPopFields>;
  var_samp?: Maybe<LambdaRunVarSampFields>;
  variance?: Maybe<LambdaRunVarianceFields>;
};


/** aggregate fields of "lambda_run" */
export type LambdaRunAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LambdaRunSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "lambda_run" */
export type LambdaRunAggregateOrderBy = {
  avg?: InputMaybe<LambdaRunAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<LambdaRunMaxOrderBy>;
  min?: InputMaybe<LambdaRunMinOrderBy>;
  stddev?: InputMaybe<LambdaRunStddevOrderBy>;
  stddev_pop?: InputMaybe<LambdaRunStddevPopOrderBy>;
  stddev_samp?: InputMaybe<LambdaRunStddevSampOrderBy>;
  sum?: InputMaybe<LambdaRunSumOrderBy>;
  var_pop?: InputMaybe<LambdaRunVarPopOrderBy>;
  var_samp?: InputMaybe<LambdaRunVarSampOrderBy>;
  variance?: InputMaybe<LambdaRunVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type LambdaRunAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "lambda_run" */
export type LambdaRunArrRelInsertInput = {
  data: Array<LambdaRunInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<LambdaRunOnConflict>;
};

/** aggregate avg on columns */
export type LambdaRunAvgFields = {
  __typename?: 'lambda_run_avg_fields';
  duration_milliseconds?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lambda_version_id?: Maybe<Scalars['Float']['output']>;
  result_code?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "lambda_run" */
export type LambdaRunAvgOrderBy = {
  duration_milliseconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  result_code?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "lambda_run". All fields are combined with a logical 'AND'. */
export type LambdaRunBoolExp = {
  _and?: InputMaybe<Array<LambdaRunBoolExp>>;
  _not?: InputMaybe<LambdaRunBoolExp>;
  _or?: InputMaybe<Array<LambdaRunBoolExp>>;
  duration_milliseconds?: InputMaybe<BigintComparisonExp>;
  heat_maps?: InputMaybe<HeatMapBoolExp>;
  heat_maps_aggregate?: InputMaybe<HeatMapAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  lambda_run_measurement_runs?: InputMaybe<LambdaRunMeasurementRunBoolExp>;
  lambda_run_measurement_runs_aggregate?: InputMaybe<LambdaRunMeasurementRunAggregateBoolExp>;
  lambda_version?: InputMaybe<LambdaVersionBoolExp>;
  lambda_version_id?: InputMaybe<IntComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  result_code?: InputMaybe<IntComparisonExp>;
  start_time?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "lambda_run" */
export enum LambdaRunConstraint {
  /** unique or primary key constraint on columns "id" */
  lambda_run_pkey = 'lambda_run_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type LambdaRunDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type LambdaRunDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type LambdaRunDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "lambda_run" */
export type LambdaRunIncInput = {
  duration_milliseconds?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lambda_version_id?: InputMaybe<Scalars['Int']['input']>;
  result_code?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "lambda_run" */
export type LambdaRunInsertInput = {
  duration_milliseconds?: InputMaybe<Scalars['bigint']['input']>;
  heat_maps?: InputMaybe<HeatMapArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lambda_run_measurement_runs?: InputMaybe<LambdaRunMeasurementRunArrRelInsertInput>;
  lambda_version?: InputMaybe<LambdaVersionObjRelInsertInput>;
  lambda_version_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  result_code?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type LambdaRunMaxFields = {
  __typename?: 'lambda_run_max_fields';
  duration_milliseconds?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lambda_version_id?: Maybe<Scalars['Int']['output']>;
  result_code?: Maybe<Scalars['Int']['output']>;
  start_time?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "lambda_run" */
export type LambdaRunMaxOrderBy = {
  duration_milliseconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  result_code?: InputMaybe<OrderBy>;
  start_time?: InputMaybe<OrderBy>;
};

/** columns and relationships of "lambda_run_measurement_run" */
export type LambdaRunMeasurementRun = {
  __typename?: 'lambda_run_measurement_run';
  id: Scalars['Int']['output'];
  /** An object relationship */
  lambda_run: LambdaRun;
  lambda_run_id: Scalars['Int']['output'];
  /** An object relationship */
  measurement_run: MeasurementRun;
  measurement_run_id: Scalars['Int']['output'];
};

/** aggregated selection of "lambda_run_measurement_run" */
export type LambdaRunMeasurementRunAggregate = {
  __typename?: 'lambda_run_measurement_run_aggregate';
  aggregate?: Maybe<LambdaRunMeasurementRunAggregateFields>;
  nodes: Array<LambdaRunMeasurementRun>;
};

export type LambdaRunMeasurementRunAggregateBoolExp = {
  count?: InputMaybe<LambdaRunMeasurementRunAggregateBoolExpCount>;
};

export type LambdaRunMeasurementRunAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<LambdaRunMeasurementRunSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<LambdaRunMeasurementRunBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "lambda_run_measurement_run" */
export type LambdaRunMeasurementRunAggregateFields = {
  __typename?: 'lambda_run_measurement_run_aggregate_fields';
  avg?: Maybe<LambdaRunMeasurementRunAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<LambdaRunMeasurementRunMaxFields>;
  min?: Maybe<LambdaRunMeasurementRunMinFields>;
  stddev?: Maybe<LambdaRunMeasurementRunStddevFields>;
  stddev_pop?: Maybe<LambdaRunMeasurementRunStddevPopFields>;
  stddev_samp?: Maybe<LambdaRunMeasurementRunStddevSampFields>;
  sum?: Maybe<LambdaRunMeasurementRunSumFields>;
  var_pop?: Maybe<LambdaRunMeasurementRunVarPopFields>;
  var_samp?: Maybe<LambdaRunMeasurementRunVarSampFields>;
  variance?: Maybe<LambdaRunMeasurementRunVarianceFields>;
};


/** aggregate fields of "lambda_run_measurement_run" */
export type LambdaRunMeasurementRunAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LambdaRunMeasurementRunSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "lambda_run_measurement_run" */
export type LambdaRunMeasurementRunAggregateOrderBy = {
  avg?: InputMaybe<LambdaRunMeasurementRunAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<LambdaRunMeasurementRunMaxOrderBy>;
  min?: InputMaybe<LambdaRunMeasurementRunMinOrderBy>;
  stddev?: InputMaybe<LambdaRunMeasurementRunStddevOrderBy>;
  stddev_pop?: InputMaybe<LambdaRunMeasurementRunStddevPopOrderBy>;
  stddev_samp?: InputMaybe<LambdaRunMeasurementRunStddevSampOrderBy>;
  sum?: InputMaybe<LambdaRunMeasurementRunSumOrderBy>;
  var_pop?: InputMaybe<LambdaRunMeasurementRunVarPopOrderBy>;
  var_samp?: InputMaybe<LambdaRunMeasurementRunVarSampOrderBy>;
  variance?: InputMaybe<LambdaRunMeasurementRunVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "lambda_run_measurement_run" */
export type LambdaRunMeasurementRunArrRelInsertInput = {
  data: Array<LambdaRunMeasurementRunInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<LambdaRunMeasurementRunOnConflict>;
};

/** aggregate avg on columns */
export type LambdaRunMeasurementRunAvgFields = {
  __typename?: 'lambda_run_measurement_run_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  lambda_run_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "lambda_run_measurement_run" */
export type LambdaRunMeasurementRunAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "lambda_run_measurement_run". All fields are combined with a logical 'AND'. */
export type LambdaRunMeasurementRunBoolExp = {
  _and?: InputMaybe<Array<LambdaRunMeasurementRunBoolExp>>;
  _not?: InputMaybe<LambdaRunMeasurementRunBoolExp>;
  _or?: InputMaybe<Array<LambdaRunMeasurementRunBoolExp>>;
  id?: InputMaybe<IntComparisonExp>;
  lambda_run?: InputMaybe<LambdaRunBoolExp>;
  lambda_run_id?: InputMaybe<IntComparisonExp>;
  measurement_run?: InputMaybe<MeasurementRunBoolExp>;
  measurement_run_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "lambda_run_measurement_run" */
export enum LambdaRunMeasurementRunConstraint {
  /** unique or primary key constraint on columns "id" */
  lambda_run_measurement_run_pkey = 'lambda_run_measurement_run_pkey'
}

/** input type for incrementing numeric columns in table "lambda_run_measurement_run" */
export type LambdaRunMeasurementRunIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  lambda_run_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "lambda_run_measurement_run" */
export type LambdaRunMeasurementRunInsertInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  lambda_run?: InputMaybe<LambdaRunObjRelInsertInput>;
  lambda_run_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_run?: InputMaybe<MeasurementRunObjRelInsertInput>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type LambdaRunMeasurementRunMaxFields = {
  __typename?: 'lambda_run_measurement_run_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  lambda_run_id?: Maybe<Scalars['Int']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "lambda_run_measurement_run" */
export type LambdaRunMeasurementRunMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type LambdaRunMeasurementRunMinFields = {
  __typename?: 'lambda_run_measurement_run_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  lambda_run_id?: Maybe<Scalars['Int']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "lambda_run_measurement_run" */
export type LambdaRunMeasurementRunMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "lambda_run_measurement_run" */
export type LambdaRunMeasurementRunMutationResponse = {
  __typename?: 'lambda_run_measurement_run_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<LambdaRunMeasurementRun>;
};

/** on_conflict condition type for table "lambda_run_measurement_run" */
export type LambdaRunMeasurementRunOnConflict = {
  constraint: LambdaRunMeasurementRunConstraint;
  update_columns?: Array<LambdaRunMeasurementRunUpdateColumn>;
  where?: InputMaybe<LambdaRunMeasurementRunBoolExp>;
};

/** Ordering options when selecting data from "lambda_run_measurement_run". */
export type LambdaRunMeasurementRunOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_run?: InputMaybe<LambdaRunOrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run?: InputMaybe<MeasurementRunOrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: lambda_run_measurement_run */
export type LambdaRunMeasurementRunPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** select columns of table "lambda_run_measurement_run" */
export enum LambdaRunMeasurementRunSelectColumn {
  /** column name */
  id = 'id',
  /** column name */
  lambda_run_id = 'lambda_run_id',
  /** column name */
  measurement_run_id = 'measurement_run_id'
}

/** input type for updating data in table "lambda_run_measurement_run" */
export type LambdaRunMeasurementRunSetInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  lambda_run_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type LambdaRunMeasurementRunStddevFields = {
  __typename?: 'lambda_run_measurement_run_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  lambda_run_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "lambda_run_measurement_run" */
export type LambdaRunMeasurementRunStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LambdaRunMeasurementRunStddevPopFields = {
  __typename?: 'lambda_run_measurement_run_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  lambda_run_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "lambda_run_measurement_run" */
export type LambdaRunMeasurementRunStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LambdaRunMeasurementRunStddevSampFields = {
  __typename?: 'lambda_run_measurement_run_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  lambda_run_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "lambda_run_measurement_run" */
export type LambdaRunMeasurementRunStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "lambda_run_measurement_run" */
export type LambdaRunMeasurementRunStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: LambdaRunMeasurementRunStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LambdaRunMeasurementRunStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  lambda_run_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type LambdaRunMeasurementRunSumFields = {
  __typename?: 'lambda_run_measurement_run_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  lambda_run_id?: Maybe<Scalars['Int']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "lambda_run_measurement_run" */
export type LambdaRunMeasurementRunSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
};

/** update columns of table "lambda_run_measurement_run" */
export enum LambdaRunMeasurementRunUpdateColumn {
  /** column name */
  id = 'id',
  /** column name */
  lambda_run_id = 'lambda_run_id',
  /** column name */
  measurement_run_id = 'measurement_run_id'
}

export type LambdaRunMeasurementRunUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LambdaRunMeasurementRunIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LambdaRunMeasurementRunSetInput>;
  /** filter the rows which have to be updated */
  where: LambdaRunMeasurementRunBoolExp;
};

/** aggregate var_pop on columns */
export type LambdaRunMeasurementRunVarPopFields = {
  __typename?: 'lambda_run_measurement_run_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  lambda_run_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "lambda_run_measurement_run" */
export type LambdaRunMeasurementRunVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LambdaRunMeasurementRunVarSampFields = {
  __typename?: 'lambda_run_measurement_run_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  lambda_run_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "lambda_run_measurement_run" */
export type LambdaRunMeasurementRunVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type LambdaRunMeasurementRunVarianceFields = {
  __typename?: 'lambda_run_measurement_run_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  lambda_run_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "lambda_run_measurement_run" */
export type LambdaRunMeasurementRunVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_run_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type LambdaRunMinFields = {
  __typename?: 'lambda_run_min_fields';
  duration_milliseconds?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lambda_version_id?: Maybe<Scalars['Int']['output']>;
  result_code?: Maybe<Scalars['Int']['output']>;
  start_time?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "lambda_run" */
export type LambdaRunMinOrderBy = {
  duration_milliseconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  result_code?: InputMaybe<OrderBy>;
  start_time?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "lambda_run" */
export type LambdaRunMutationResponse = {
  __typename?: 'lambda_run_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<LambdaRun>;
};

/** input type for inserting object relation for remote table "lambda_run" */
export type LambdaRunObjRelInsertInput = {
  data: LambdaRunInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<LambdaRunOnConflict>;
};

/** on_conflict condition type for table "lambda_run" */
export type LambdaRunOnConflict = {
  constraint: LambdaRunConstraint;
  update_columns?: Array<LambdaRunUpdateColumn>;
  where?: InputMaybe<LambdaRunBoolExp>;
};

/** Ordering options when selecting data from "lambda_run". */
export type LambdaRunOrderBy = {
  duration_milliseconds?: InputMaybe<OrderBy>;
  heat_maps_aggregate?: InputMaybe<HeatMapAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_run_measurement_runs_aggregate?: InputMaybe<LambdaRunMeasurementRunAggregateOrderBy>;
  lambda_version?: InputMaybe<LambdaVersionOrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  result_code?: InputMaybe<OrderBy>;
  start_time?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: lambda_run */
export type LambdaRunPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type LambdaRunPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "lambda_run" */
export enum LambdaRunSelectColumn {
  /** column name */
  duration_milliseconds = 'duration_milliseconds',
  /** column name */
  id = 'id',
  /** column name */
  lambda_version_id = 'lambda_version_id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  result_code = 'result_code',
  /** column name */
  start_time = 'start_time'
}

/** input type for updating data in table "lambda_run" */
export type LambdaRunSetInput = {
  duration_milliseconds?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lambda_version_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  result_code?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type LambdaRunStddevFields = {
  __typename?: 'lambda_run_stddev_fields';
  duration_milliseconds?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lambda_version_id?: Maybe<Scalars['Float']['output']>;
  result_code?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "lambda_run" */
export type LambdaRunStddevOrderBy = {
  duration_milliseconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  result_code?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LambdaRunStddevPopFields = {
  __typename?: 'lambda_run_stddev_pop_fields';
  duration_milliseconds?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lambda_version_id?: Maybe<Scalars['Float']['output']>;
  result_code?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "lambda_run" */
export type LambdaRunStddevPopOrderBy = {
  duration_milliseconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  result_code?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LambdaRunStddevSampFields = {
  __typename?: 'lambda_run_stddev_samp_fields';
  duration_milliseconds?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lambda_version_id?: Maybe<Scalars['Float']['output']>;
  result_code?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "lambda_run" */
export type LambdaRunStddevSampOrderBy = {
  duration_milliseconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  result_code?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "lambda_run" */
export type LambdaRunStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: LambdaRunStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LambdaRunStreamCursorValueInput = {
  duration_milliseconds?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lambda_version_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  result_code?: InputMaybe<Scalars['Int']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type LambdaRunSumFields = {
  __typename?: 'lambda_run_sum_fields';
  duration_milliseconds?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lambda_version_id?: Maybe<Scalars['Int']['output']>;
  result_code?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "lambda_run" */
export type LambdaRunSumOrderBy = {
  duration_milliseconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  result_code?: InputMaybe<OrderBy>;
};

/** update columns of table "lambda_run" */
export enum LambdaRunUpdateColumn {
  /** column name */
  duration_milliseconds = 'duration_milliseconds',
  /** column name */
  id = 'id',
  /** column name */
  lambda_version_id = 'lambda_version_id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  result_code = 'result_code',
  /** column name */
  start_time = 'start_time'
}

export type LambdaRunUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<LambdaRunAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<LambdaRunDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<LambdaRunDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<LambdaRunDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LambdaRunIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<LambdaRunPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LambdaRunSetInput>;
  /** filter the rows which have to be updated */
  where: LambdaRunBoolExp;
};

/** aggregate var_pop on columns */
export type LambdaRunVarPopFields = {
  __typename?: 'lambda_run_var_pop_fields';
  duration_milliseconds?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lambda_version_id?: Maybe<Scalars['Float']['output']>;
  result_code?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "lambda_run" */
export type LambdaRunVarPopOrderBy = {
  duration_milliseconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  result_code?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LambdaRunVarSampFields = {
  __typename?: 'lambda_run_var_samp_fields';
  duration_milliseconds?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lambda_version_id?: Maybe<Scalars['Float']['output']>;
  result_code?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "lambda_run" */
export type LambdaRunVarSampOrderBy = {
  duration_milliseconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  result_code?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type LambdaRunVarianceFields = {
  __typename?: 'lambda_run_variance_fields';
  duration_milliseconds?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lambda_version_id?: Maybe<Scalars['Float']['output']>;
  result_code?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "lambda_run" */
export type LambdaRunVarianceOrderBy = {
  duration_milliseconds?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_version_id?: InputMaybe<OrderBy>;
  result_code?: InputMaybe<OrderBy>;
};

/** columns and relationships of "lambda_version" */
export type LambdaVersion = {
  __typename?: 'lambda_version';
  /** An array relationship */
  computed_measurements: Array<ComputedMeasurement>;
  /** An aggregate relationship */
  computed_measurements_aggregate: ComputedMeasurementAggregate;
  id: Scalars['Int']['output'];
  /** An array relationship */
  labels: Array<Label>;
  /** An aggregate relationship */
  labels_aggregate: LabelAggregate;
  /** An array relationship */
  lambda_runs: Array<LambdaRun>;
  /** An aggregate relationship */
  lambda_runs_aggregate: LambdaRunAggregate;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  name: Scalars['String']['output'];
  /** An array relationship */
  notifications: Array<Notification>;
  /** An aggregate relationship */
  notifications_aggregate: NotificationAggregate;
  uid: Scalars['String']['output'];
};


/** columns and relationships of "lambda_version" */
export type LambdaVersionComputedMeasurementsArgs = {
  distinct_on?: InputMaybe<Array<ComputedMeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputedMeasurementOrderBy>>;
  where?: InputMaybe<ComputedMeasurementBoolExp>;
};


/** columns and relationships of "lambda_version" */
export type LambdaVersionComputedMeasurementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ComputedMeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputedMeasurementOrderBy>>;
  where?: InputMaybe<ComputedMeasurementBoolExp>;
};


/** columns and relationships of "lambda_version" */
export type LambdaVersionLabelsArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


/** columns and relationships of "lambda_version" */
export type LambdaVersionLabelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


/** columns and relationships of "lambda_version" */
export type LambdaVersionLambdaRunsArgs = {
  distinct_on?: InputMaybe<Array<LambdaRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LambdaRunOrderBy>>;
  where?: InputMaybe<LambdaRunBoolExp>;
};


/** columns and relationships of "lambda_version" */
export type LambdaVersionLambdaRunsAggregateArgs = {
  distinct_on?: InputMaybe<Array<LambdaRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LambdaRunOrderBy>>;
  where?: InputMaybe<LambdaRunBoolExp>;
};


/** columns and relationships of "lambda_version" */
export type LambdaVersionMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "lambda_version" */
export type LambdaVersionNotificationsArgs = {
  distinct_on?: InputMaybe<Array<NotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotificationOrderBy>>;
  where?: InputMaybe<NotificationBoolExp>;
};


/** columns and relationships of "lambda_version" */
export type LambdaVersionNotificationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<NotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotificationOrderBy>>;
  where?: InputMaybe<NotificationBoolExp>;
};

/** aggregated selection of "lambda_version" */
export type LambdaVersionAggregate = {
  __typename?: 'lambda_version_aggregate';
  aggregate?: Maybe<LambdaVersionAggregateFields>;
  nodes: Array<LambdaVersion>;
};

/** aggregate fields of "lambda_version" */
export type LambdaVersionAggregateFields = {
  __typename?: 'lambda_version_aggregate_fields';
  avg?: Maybe<LambdaVersionAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<LambdaVersionMaxFields>;
  min?: Maybe<LambdaVersionMinFields>;
  stddev?: Maybe<LambdaVersionStddevFields>;
  stddev_pop?: Maybe<LambdaVersionStddevPopFields>;
  stddev_samp?: Maybe<LambdaVersionStddevSampFields>;
  sum?: Maybe<LambdaVersionSumFields>;
  var_pop?: Maybe<LambdaVersionVarPopFields>;
  var_samp?: Maybe<LambdaVersionVarSampFields>;
  variance?: Maybe<LambdaVersionVarianceFields>;
};


/** aggregate fields of "lambda_version" */
export type LambdaVersionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LambdaVersionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type LambdaVersionAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type LambdaVersionAvgFields = {
  __typename?: 'lambda_version_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "lambda_version". All fields are combined with a logical 'AND'. */
export type LambdaVersionBoolExp = {
  _and?: InputMaybe<Array<LambdaVersionBoolExp>>;
  _not?: InputMaybe<LambdaVersionBoolExp>;
  _or?: InputMaybe<Array<LambdaVersionBoolExp>>;
  computed_measurements?: InputMaybe<ComputedMeasurementBoolExp>;
  computed_measurements_aggregate?: InputMaybe<ComputedMeasurementAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  labels?: InputMaybe<LabelBoolExp>;
  labels_aggregate?: InputMaybe<LabelAggregateBoolExp>;
  lambda_runs?: InputMaybe<LambdaRunBoolExp>;
  lambda_runs_aggregate?: InputMaybe<LambdaRunAggregateBoolExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  notifications?: InputMaybe<NotificationBoolExp>;
  notifications_aggregate?: InputMaybe<NotificationAggregateBoolExp>;
  uid?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "lambda_version" */
export enum LambdaVersionConstraint {
  /** unique or primary key constraint on columns "id" */
  lambda_version_pkey = 'lambda_version_pkey',
  /** unique or primary key constraint on columns "name", "uid" */
  name_uid_un = 'name_uid_un'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type LambdaVersionDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type LambdaVersionDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type LambdaVersionDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "lambda_version" */
export type LambdaVersionIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "lambda_version" */
export type LambdaVersionInsertInput = {
  computed_measurements?: InputMaybe<ComputedMeasurementArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  labels?: InputMaybe<LabelArrRelInsertInput>;
  lambda_runs?: InputMaybe<LambdaRunArrRelInsertInput>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<NotificationArrRelInsertInput>;
  uid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type LambdaVersionMaxFields = {
  __typename?: 'lambda_version_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type LambdaVersionMinFields = {
  __typename?: 'lambda_version_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "lambda_version" */
export type LambdaVersionMutationResponse = {
  __typename?: 'lambda_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<LambdaVersion>;
};

/** input type for inserting object relation for remote table "lambda_version" */
export type LambdaVersionObjRelInsertInput = {
  data: LambdaVersionInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<LambdaVersionOnConflict>;
};

/** on_conflict condition type for table "lambda_version" */
export type LambdaVersionOnConflict = {
  constraint: LambdaVersionConstraint;
  update_columns?: Array<LambdaVersionUpdateColumn>;
  where?: InputMaybe<LambdaVersionBoolExp>;
};

/** Ordering options when selecting data from "lambda_version". */
export type LambdaVersionOrderBy = {
  computed_measurements_aggregate?: InputMaybe<ComputedMeasurementAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  labels_aggregate?: InputMaybe<LabelAggregateOrderBy>;
  lambda_runs_aggregate?: InputMaybe<LambdaRunAggregateOrderBy>;
  metadata?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  notifications_aggregate?: InputMaybe<NotificationAggregateOrderBy>;
  uid?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: lambda_version */
export type LambdaVersionPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type LambdaVersionPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "lambda_version" */
export enum LambdaVersionSelectColumn {
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  name = 'name',
  /** column name */
  uid = 'uid'
}

/** input type for updating data in table "lambda_version" */
export type LambdaVersionSetInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type LambdaVersionStddevFields = {
  __typename?: 'lambda_version_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type LambdaVersionStddevPopFields = {
  __typename?: 'lambda_version_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type LambdaVersionStddevSampFields = {
  __typename?: 'lambda_version_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "lambda_version" */
export type LambdaVersionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: LambdaVersionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LambdaVersionStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type LambdaVersionSumFields = {
  __typename?: 'lambda_version_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "lambda_version" */
export enum LambdaVersionUpdateColumn {
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  name = 'name',
  /** column name */
  uid = 'uid'
}

export type LambdaVersionUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<LambdaVersionAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<LambdaVersionDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<LambdaVersionDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<LambdaVersionDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LambdaVersionIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<LambdaVersionPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LambdaVersionSetInput>;
  /** filter the rows which have to be updated */
  where: LambdaVersionBoolExp;
};

/** aggregate var_pop on columns */
export type LambdaVersionVarPopFields = {
  __typename?: 'lambda_version_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type LambdaVersionVarSampFields = {
  __typename?: 'lambda_version_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type LambdaVersionVarianceFields = {
  __typename?: 'lambda_version_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "light_cycle_recipe" */
export type LightCycleRecipe = {
  __typename?: 'light_cycle_recipe';
  /** An array relationship */
  growth_cycles: Array<GrowthCycle>;
  /** An aggregate relationship */
  growth_cycles_aggregate: GrowthCycleAggregate;
  id: Scalars['Int']['output'];
  is_active: Scalars['Boolean']['output'];
  last_updated: Scalars['timestamptz']['output'];
  /** An array relationship */
  light_cycle_recipe_entrys: Array<LightCycleRecipeEntry>;
  /** An aggregate relationship */
  light_cycle_recipe_entrys_aggregate: LightCycleRecipeEntryAggregate;
  /** An object relationship */
  location?: Maybe<Location>;
  location_id?: Maybe<Scalars['Int']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  name: Scalars['String']['output'];
  /** An object relationship */
  organization?: Maybe<Organization>;
  organization_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "light_cycle_recipe" */
export type LightCycleRecipeGrowthCyclesArgs = {
  distinct_on?: InputMaybe<Array<GrowthCycleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GrowthCycleOrderBy>>;
  where?: InputMaybe<GrowthCycleBoolExp>;
};


/** columns and relationships of "light_cycle_recipe" */
export type LightCycleRecipeGrowthCyclesAggregateArgs = {
  distinct_on?: InputMaybe<Array<GrowthCycleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GrowthCycleOrderBy>>;
  where?: InputMaybe<GrowthCycleBoolExp>;
};


/** columns and relationships of "light_cycle_recipe" */
export type LightCycleRecipeLightCycleRecipeEntrysArgs = {
  distinct_on?: InputMaybe<Array<LightCycleRecipeEntrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LightCycleRecipeEntryOrderBy>>;
  where?: InputMaybe<LightCycleRecipeEntryBoolExp>;
};


/** columns and relationships of "light_cycle_recipe" */
export type LightCycleRecipeLightCycleRecipeEntrysAggregateArgs = {
  distinct_on?: InputMaybe<Array<LightCycleRecipeEntrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LightCycleRecipeEntryOrderBy>>;
  where?: InputMaybe<LightCycleRecipeEntryBoolExp>;
};


/** columns and relationships of "light_cycle_recipe" */
export type LightCycleRecipeMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "light_cycle_recipe" */
export type LightCycleRecipeAggregate = {
  __typename?: 'light_cycle_recipe_aggregate';
  aggregate?: Maybe<LightCycleRecipeAggregateFields>;
  nodes: Array<LightCycleRecipe>;
};

export type LightCycleRecipeAggregateBoolExp = {
  bool_and?: InputMaybe<LightCycleRecipeAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<LightCycleRecipeAggregateBoolExpBoolOr>;
  count?: InputMaybe<LightCycleRecipeAggregateBoolExpCount>;
};

export type LightCycleRecipeAggregateBoolExpBoolAnd = {
  arguments: LightCycleRecipeSelectColumnLightCycleRecipeAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<LightCycleRecipeBoolExp>;
  predicate: BooleanComparisonExp;
};

export type LightCycleRecipeAggregateBoolExpBoolOr = {
  arguments: LightCycleRecipeSelectColumnLightCycleRecipeAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<LightCycleRecipeBoolExp>;
  predicate: BooleanComparisonExp;
};

export type LightCycleRecipeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<LightCycleRecipeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<LightCycleRecipeBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "light_cycle_recipe" */
export type LightCycleRecipeAggregateFields = {
  __typename?: 'light_cycle_recipe_aggregate_fields';
  avg?: Maybe<LightCycleRecipeAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<LightCycleRecipeMaxFields>;
  min?: Maybe<LightCycleRecipeMinFields>;
  stddev?: Maybe<LightCycleRecipeStddevFields>;
  stddev_pop?: Maybe<LightCycleRecipeStddevPopFields>;
  stddev_samp?: Maybe<LightCycleRecipeStddevSampFields>;
  sum?: Maybe<LightCycleRecipeSumFields>;
  var_pop?: Maybe<LightCycleRecipeVarPopFields>;
  var_samp?: Maybe<LightCycleRecipeVarSampFields>;
  variance?: Maybe<LightCycleRecipeVarianceFields>;
};


/** aggregate fields of "light_cycle_recipe" */
export type LightCycleRecipeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LightCycleRecipeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "light_cycle_recipe" */
export type LightCycleRecipeAggregateOrderBy = {
  avg?: InputMaybe<LightCycleRecipeAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<LightCycleRecipeMaxOrderBy>;
  min?: InputMaybe<LightCycleRecipeMinOrderBy>;
  stddev?: InputMaybe<LightCycleRecipeStddevOrderBy>;
  stddev_pop?: InputMaybe<LightCycleRecipeStddevPopOrderBy>;
  stddev_samp?: InputMaybe<LightCycleRecipeStddevSampOrderBy>;
  sum?: InputMaybe<LightCycleRecipeSumOrderBy>;
  var_pop?: InputMaybe<LightCycleRecipeVarPopOrderBy>;
  var_samp?: InputMaybe<LightCycleRecipeVarSampOrderBy>;
  variance?: InputMaybe<LightCycleRecipeVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type LightCycleRecipeAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "light_cycle_recipe" */
export type LightCycleRecipeArrRelInsertInput = {
  data: Array<LightCycleRecipeInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<LightCycleRecipeOnConflict>;
};

/** aggregate avg on columns */
export type LightCycleRecipeAvgFields = {
  __typename?: 'light_cycle_recipe_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "light_cycle_recipe" */
export type LightCycleRecipeAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "light_cycle_recipe". All fields are combined with a logical 'AND'. */
export type LightCycleRecipeBoolExp = {
  _and?: InputMaybe<Array<LightCycleRecipeBoolExp>>;
  _not?: InputMaybe<LightCycleRecipeBoolExp>;
  _or?: InputMaybe<Array<LightCycleRecipeBoolExp>>;
  growth_cycles?: InputMaybe<GrowthCycleBoolExp>;
  growth_cycles_aggregate?: InputMaybe<GrowthCycleAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  is_active?: InputMaybe<BooleanComparisonExp>;
  last_updated?: InputMaybe<TimestamptzComparisonExp>;
  light_cycle_recipe_entrys?: InputMaybe<LightCycleRecipeEntryBoolExp>;
  light_cycle_recipe_entrys_aggregate?: InputMaybe<LightCycleRecipeEntryAggregateBoolExp>;
  location?: InputMaybe<LocationBoolExp>;
  location_id?: InputMaybe<IntComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organization_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "light_cycle_recipe" */
export enum LightCycleRecipeConstraint {
  /** unique or primary key constraint on columns "id" */
  light_cycle_recipe_pkey = 'light_cycle_recipe_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type LightCycleRecipeDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type LightCycleRecipeDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type LightCycleRecipeDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "light_cycle_recipe_entry" */
export type LightCycleRecipeEntry = {
  __typename?: 'light_cycle_recipe_entry';
  day: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  light_cycle_recipe: LightCycleRecipe;
  lights_on_duration_minutes?: Maybe<Scalars['float8']['output']>;
  lights_on_time?: Maybe<Scalars['time']['output']>;
  recipe_id: Scalars['Int']['output'];
};

/** aggregated selection of "light_cycle_recipe_entry" */
export type LightCycleRecipeEntryAggregate = {
  __typename?: 'light_cycle_recipe_entry_aggregate';
  aggregate?: Maybe<LightCycleRecipeEntryAggregateFields>;
  nodes: Array<LightCycleRecipeEntry>;
};

export type LightCycleRecipeEntryAggregateBoolExp = {
  avg?: InputMaybe<LightCycleRecipeEntryAggregateBoolExpAvg>;
  corr?: InputMaybe<LightCycleRecipeEntryAggregateBoolExpCorr>;
  count?: InputMaybe<LightCycleRecipeEntryAggregateBoolExpCount>;
  covar_samp?: InputMaybe<LightCycleRecipeEntryAggregateBoolExpCovarSamp>;
  max?: InputMaybe<LightCycleRecipeEntryAggregateBoolExpMax>;
  min?: InputMaybe<LightCycleRecipeEntryAggregateBoolExpMin>;
  stddev_samp?: InputMaybe<LightCycleRecipeEntryAggregateBoolExpStddevSamp>;
  sum?: InputMaybe<LightCycleRecipeEntryAggregateBoolExpSum>;
  var_samp?: InputMaybe<LightCycleRecipeEntryAggregateBoolExpVarSamp>;
};

export type LightCycleRecipeEntryAggregateBoolExpAvg = {
  arguments: LightCycleRecipeEntrySelectColumnLightCycleRecipeEntryAggregateBoolExpAvgArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<LightCycleRecipeEntryBoolExp>;
  predicate: Float8ComparisonExp;
};

export type LightCycleRecipeEntryAggregateBoolExpCorr = {
  arguments: LightCycleRecipeEntryAggregateBoolExpCorrArguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<LightCycleRecipeEntryBoolExp>;
  predicate: Float8ComparisonExp;
};

export type LightCycleRecipeEntryAggregateBoolExpCorrArguments = {
  X: LightCycleRecipeEntrySelectColumnLightCycleRecipeEntryAggregateBoolExpCorrArgumentsColumns;
  Y: LightCycleRecipeEntrySelectColumnLightCycleRecipeEntryAggregateBoolExpCorrArgumentsColumns;
};

export type LightCycleRecipeEntryAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<LightCycleRecipeEntrySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<LightCycleRecipeEntryBoolExp>;
  predicate: IntComparisonExp;
};

export type LightCycleRecipeEntryAggregateBoolExpCovarSamp = {
  arguments: LightCycleRecipeEntryAggregateBoolExpCovarSampArguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<LightCycleRecipeEntryBoolExp>;
  predicate: Float8ComparisonExp;
};

export type LightCycleRecipeEntryAggregateBoolExpCovarSampArguments = {
  X: LightCycleRecipeEntrySelectColumnLightCycleRecipeEntryAggregateBoolExpCovarSampArgumentsColumns;
  Y: LightCycleRecipeEntrySelectColumnLightCycleRecipeEntryAggregateBoolExpCovarSampArgumentsColumns;
};

export type LightCycleRecipeEntryAggregateBoolExpMax = {
  arguments: LightCycleRecipeEntrySelectColumnLightCycleRecipeEntryAggregateBoolExpMaxArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<LightCycleRecipeEntryBoolExp>;
  predicate: Float8ComparisonExp;
};

export type LightCycleRecipeEntryAggregateBoolExpMin = {
  arguments: LightCycleRecipeEntrySelectColumnLightCycleRecipeEntryAggregateBoolExpMinArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<LightCycleRecipeEntryBoolExp>;
  predicate: Float8ComparisonExp;
};

export type LightCycleRecipeEntryAggregateBoolExpStddevSamp = {
  arguments: LightCycleRecipeEntrySelectColumnLightCycleRecipeEntryAggregateBoolExpStddevSampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<LightCycleRecipeEntryBoolExp>;
  predicate: Float8ComparisonExp;
};

export type LightCycleRecipeEntryAggregateBoolExpSum = {
  arguments: LightCycleRecipeEntrySelectColumnLightCycleRecipeEntryAggregateBoolExpSumArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<LightCycleRecipeEntryBoolExp>;
  predicate: Float8ComparisonExp;
};

export type LightCycleRecipeEntryAggregateBoolExpVarSamp = {
  arguments: LightCycleRecipeEntrySelectColumnLightCycleRecipeEntryAggregateBoolExpVarSampArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<LightCycleRecipeEntryBoolExp>;
  predicate: Float8ComparisonExp;
};

/** aggregate fields of "light_cycle_recipe_entry" */
export type LightCycleRecipeEntryAggregateFields = {
  __typename?: 'light_cycle_recipe_entry_aggregate_fields';
  avg?: Maybe<LightCycleRecipeEntryAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<LightCycleRecipeEntryMaxFields>;
  min?: Maybe<LightCycleRecipeEntryMinFields>;
  stddev?: Maybe<LightCycleRecipeEntryStddevFields>;
  stddev_pop?: Maybe<LightCycleRecipeEntryStddevPopFields>;
  stddev_samp?: Maybe<LightCycleRecipeEntryStddevSampFields>;
  sum?: Maybe<LightCycleRecipeEntrySumFields>;
  var_pop?: Maybe<LightCycleRecipeEntryVarPopFields>;
  var_samp?: Maybe<LightCycleRecipeEntryVarSampFields>;
  variance?: Maybe<LightCycleRecipeEntryVarianceFields>;
};


/** aggregate fields of "light_cycle_recipe_entry" */
export type LightCycleRecipeEntryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LightCycleRecipeEntrySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "light_cycle_recipe_entry" */
export type LightCycleRecipeEntryAggregateOrderBy = {
  avg?: InputMaybe<LightCycleRecipeEntryAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<LightCycleRecipeEntryMaxOrderBy>;
  min?: InputMaybe<LightCycleRecipeEntryMinOrderBy>;
  stddev?: InputMaybe<LightCycleRecipeEntryStddevOrderBy>;
  stddev_pop?: InputMaybe<LightCycleRecipeEntryStddevPopOrderBy>;
  stddev_samp?: InputMaybe<LightCycleRecipeEntryStddevSampOrderBy>;
  sum?: InputMaybe<LightCycleRecipeEntrySumOrderBy>;
  var_pop?: InputMaybe<LightCycleRecipeEntryVarPopOrderBy>;
  var_samp?: InputMaybe<LightCycleRecipeEntryVarSampOrderBy>;
  variance?: InputMaybe<LightCycleRecipeEntryVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "light_cycle_recipe_entry" */
export type LightCycleRecipeEntryArrRelInsertInput = {
  data: Array<LightCycleRecipeEntryInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<LightCycleRecipeEntryOnConflict>;
};

/** aggregate avg on columns */
export type LightCycleRecipeEntryAvgFields = {
  __typename?: 'light_cycle_recipe_entry_avg_fields';
  day?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lights_on_duration_minutes?: Maybe<Scalars['Float']['output']>;
  recipe_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "light_cycle_recipe_entry" */
export type LightCycleRecipeEntryAvgOrderBy = {
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lights_on_duration_minutes?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "light_cycle_recipe_entry". All fields are combined with a logical 'AND'. */
export type LightCycleRecipeEntryBoolExp = {
  _and?: InputMaybe<Array<LightCycleRecipeEntryBoolExp>>;
  _not?: InputMaybe<LightCycleRecipeEntryBoolExp>;
  _or?: InputMaybe<Array<LightCycleRecipeEntryBoolExp>>;
  day?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  light_cycle_recipe?: InputMaybe<LightCycleRecipeBoolExp>;
  lights_on_duration_minutes?: InputMaybe<Float8ComparisonExp>;
  lights_on_time?: InputMaybe<TimeComparisonExp>;
  recipe_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "light_cycle_recipe_entry" */
export enum LightCycleRecipeEntryConstraint {
  /** unique or primary key constraint on columns "id" */
  light_cycle_recipe_entry_pkey = 'light_cycle_recipe_entry_pkey'
}

/** input type for incrementing numeric columns in table "light_cycle_recipe_entry" */
export type LightCycleRecipeEntryIncInput = {
  day?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lights_on_duration_minutes?: InputMaybe<Scalars['float8']['input']>;
  recipe_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "light_cycle_recipe_entry" */
export type LightCycleRecipeEntryInsertInput = {
  day?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  light_cycle_recipe?: InputMaybe<LightCycleRecipeObjRelInsertInput>;
  lights_on_duration_minutes?: InputMaybe<Scalars['float8']['input']>;
  lights_on_time?: InputMaybe<Scalars['time']['input']>;
  recipe_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type LightCycleRecipeEntryMaxFields = {
  __typename?: 'light_cycle_recipe_entry_max_fields';
  day?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lights_on_duration_minutes?: Maybe<Scalars['float8']['output']>;
  recipe_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "light_cycle_recipe_entry" */
export type LightCycleRecipeEntryMaxOrderBy = {
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lights_on_duration_minutes?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type LightCycleRecipeEntryMinFields = {
  __typename?: 'light_cycle_recipe_entry_min_fields';
  day?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lights_on_duration_minutes?: Maybe<Scalars['float8']['output']>;
  recipe_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "light_cycle_recipe_entry" */
export type LightCycleRecipeEntryMinOrderBy = {
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lights_on_duration_minutes?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "light_cycle_recipe_entry" */
export type LightCycleRecipeEntryMutationResponse = {
  __typename?: 'light_cycle_recipe_entry_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<LightCycleRecipeEntry>;
};

/** on_conflict condition type for table "light_cycle_recipe_entry" */
export type LightCycleRecipeEntryOnConflict = {
  constraint: LightCycleRecipeEntryConstraint;
  update_columns?: Array<LightCycleRecipeEntryUpdateColumn>;
  where?: InputMaybe<LightCycleRecipeEntryBoolExp>;
};

/** Ordering options when selecting data from "light_cycle_recipe_entry". */
export type LightCycleRecipeEntryOrderBy = {
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  light_cycle_recipe?: InputMaybe<LightCycleRecipeOrderBy>;
  lights_on_duration_minutes?: InputMaybe<OrderBy>;
  lights_on_time?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: light_cycle_recipe_entry */
export type LightCycleRecipeEntryPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** select columns of table "light_cycle_recipe_entry" */
export enum LightCycleRecipeEntrySelectColumn {
  /** column name */
  day = 'day',
  /** column name */
  id = 'id',
  /** column name */
  lights_on_duration_minutes = 'lights_on_duration_minutes',
  /** column name */
  lights_on_time = 'lights_on_time',
  /** column name */
  recipe_id = 'recipe_id'
}

/** select "light_cycle_recipe_entry_aggregate_bool_exp_avg_arguments_columns" columns of table "light_cycle_recipe_entry" */
export enum LightCycleRecipeEntrySelectColumnLightCycleRecipeEntryAggregateBoolExpAvgArgumentsColumns {
  /** column name */
  lights_on_duration_minutes = 'lights_on_duration_minutes'
}

/** select "light_cycle_recipe_entry_aggregate_bool_exp_corr_arguments_columns" columns of table "light_cycle_recipe_entry" */
export enum LightCycleRecipeEntrySelectColumnLightCycleRecipeEntryAggregateBoolExpCorrArgumentsColumns {
  /** column name */
  lights_on_duration_minutes = 'lights_on_duration_minutes'
}

/** select "light_cycle_recipe_entry_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "light_cycle_recipe_entry" */
export enum LightCycleRecipeEntrySelectColumnLightCycleRecipeEntryAggregateBoolExpCovarSampArgumentsColumns {
  /** column name */
  lights_on_duration_minutes = 'lights_on_duration_minutes'
}

/** select "light_cycle_recipe_entry_aggregate_bool_exp_max_arguments_columns" columns of table "light_cycle_recipe_entry" */
export enum LightCycleRecipeEntrySelectColumnLightCycleRecipeEntryAggregateBoolExpMaxArgumentsColumns {
  /** column name */
  lights_on_duration_minutes = 'lights_on_duration_minutes'
}

/** select "light_cycle_recipe_entry_aggregate_bool_exp_min_arguments_columns" columns of table "light_cycle_recipe_entry" */
export enum LightCycleRecipeEntrySelectColumnLightCycleRecipeEntryAggregateBoolExpMinArgumentsColumns {
  /** column name */
  lights_on_duration_minutes = 'lights_on_duration_minutes'
}

/** select "light_cycle_recipe_entry_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "light_cycle_recipe_entry" */
export enum LightCycleRecipeEntrySelectColumnLightCycleRecipeEntryAggregateBoolExpStddevSampArgumentsColumns {
  /** column name */
  lights_on_duration_minutes = 'lights_on_duration_minutes'
}

/** select "light_cycle_recipe_entry_aggregate_bool_exp_sum_arguments_columns" columns of table "light_cycle_recipe_entry" */
export enum LightCycleRecipeEntrySelectColumnLightCycleRecipeEntryAggregateBoolExpSumArgumentsColumns {
  /** column name */
  lights_on_duration_minutes = 'lights_on_duration_minutes'
}

/** select "light_cycle_recipe_entry_aggregate_bool_exp_var_samp_arguments_columns" columns of table "light_cycle_recipe_entry" */
export enum LightCycleRecipeEntrySelectColumnLightCycleRecipeEntryAggregateBoolExpVarSampArgumentsColumns {
  /** column name */
  lights_on_duration_minutes = 'lights_on_duration_minutes'
}

/** input type for updating data in table "light_cycle_recipe_entry" */
export type LightCycleRecipeEntrySetInput = {
  day?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lights_on_duration_minutes?: InputMaybe<Scalars['float8']['input']>;
  lights_on_time?: InputMaybe<Scalars['time']['input']>;
  recipe_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type LightCycleRecipeEntryStddevFields = {
  __typename?: 'light_cycle_recipe_entry_stddev_fields';
  day?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lights_on_duration_minutes?: Maybe<Scalars['Float']['output']>;
  recipe_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "light_cycle_recipe_entry" */
export type LightCycleRecipeEntryStddevOrderBy = {
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lights_on_duration_minutes?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LightCycleRecipeEntryStddevPopFields = {
  __typename?: 'light_cycle_recipe_entry_stddev_pop_fields';
  day?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lights_on_duration_minutes?: Maybe<Scalars['Float']['output']>;
  recipe_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "light_cycle_recipe_entry" */
export type LightCycleRecipeEntryStddevPopOrderBy = {
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lights_on_duration_minutes?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LightCycleRecipeEntryStddevSampFields = {
  __typename?: 'light_cycle_recipe_entry_stddev_samp_fields';
  day?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lights_on_duration_minutes?: Maybe<Scalars['Float']['output']>;
  recipe_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "light_cycle_recipe_entry" */
export type LightCycleRecipeEntryStddevSampOrderBy = {
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lights_on_duration_minutes?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "light_cycle_recipe_entry" */
export type LightCycleRecipeEntryStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: LightCycleRecipeEntryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LightCycleRecipeEntryStreamCursorValueInput = {
  day?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lights_on_duration_minutes?: InputMaybe<Scalars['float8']['input']>;
  lights_on_time?: InputMaybe<Scalars['time']['input']>;
  recipe_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type LightCycleRecipeEntrySumFields = {
  __typename?: 'light_cycle_recipe_entry_sum_fields';
  day?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lights_on_duration_minutes?: Maybe<Scalars['float8']['output']>;
  recipe_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "light_cycle_recipe_entry" */
export type LightCycleRecipeEntrySumOrderBy = {
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lights_on_duration_minutes?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
};

/** update columns of table "light_cycle_recipe_entry" */
export enum LightCycleRecipeEntryUpdateColumn {
  /** column name */
  day = 'day',
  /** column name */
  id = 'id',
  /** column name */
  lights_on_duration_minutes = 'lights_on_duration_minutes',
  /** column name */
  lights_on_time = 'lights_on_time',
  /** column name */
  recipe_id = 'recipe_id'
}

export type LightCycleRecipeEntryUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LightCycleRecipeEntryIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LightCycleRecipeEntrySetInput>;
  /** filter the rows which have to be updated */
  where: LightCycleRecipeEntryBoolExp;
};

/** aggregate var_pop on columns */
export type LightCycleRecipeEntryVarPopFields = {
  __typename?: 'light_cycle_recipe_entry_var_pop_fields';
  day?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lights_on_duration_minutes?: Maybe<Scalars['Float']['output']>;
  recipe_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "light_cycle_recipe_entry" */
export type LightCycleRecipeEntryVarPopOrderBy = {
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lights_on_duration_minutes?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LightCycleRecipeEntryVarSampFields = {
  __typename?: 'light_cycle_recipe_entry_var_samp_fields';
  day?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lights_on_duration_minutes?: Maybe<Scalars['Float']['output']>;
  recipe_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "light_cycle_recipe_entry" */
export type LightCycleRecipeEntryVarSampOrderBy = {
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lights_on_duration_minutes?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type LightCycleRecipeEntryVarianceFields = {
  __typename?: 'light_cycle_recipe_entry_variance_fields';
  day?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lights_on_duration_minutes?: Maybe<Scalars['Float']['output']>;
  recipe_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "light_cycle_recipe_entry" */
export type LightCycleRecipeEntryVarianceOrderBy = {
  day?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lights_on_duration_minutes?: InputMaybe<OrderBy>;
  recipe_id?: InputMaybe<OrderBy>;
};

/** input type for incrementing numeric columns in table "light_cycle_recipe" */
export type LightCycleRecipeIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  location_id?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "light_cycle_recipe" */
export type LightCycleRecipeInsertInput = {
  growth_cycles?: InputMaybe<GrowthCycleArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated?: InputMaybe<Scalars['timestamptz']['input']>;
  light_cycle_recipe_entrys?: InputMaybe<LightCycleRecipeEntryArrRelInsertInput>;
  location?: InputMaybe<LocationObjRelInsertInput>;
  location_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type LightCycleRecipeMaxFields = {
  __typename?: 'light_cycle_recipe_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  last_updated?: Maybe<Scalars['timestamptz']['output']>;
  location_id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "light_cycle_recipe" */
export type LightCycleRecipeMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  last_updated?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type LightCycleRecipeMinFields = {
  __typename?: 'light_cycle_recipe_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  last_updated?: Maybe<Scalars['timestamptz']['output']>;
  location_id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "light_cycle_recipe" */
export type LightCycleRecipeMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  last_updated?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "light_cycle_recipe" */
export type LightCycleRecipeMutationResponse = {
  __typename?: 'light_cycle_recipe_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<LightCycleRecipe>;
};

/** input type for inserting object relation for remote table "light_cycle_recipe" */
export type LightCycleRecipeObjRelInsertInput = {
  data: LightCycleRecipeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<LightCycleRecipeOnConflict>;
};

/** on_conflict condition type for table "light_cycle_recipe" */
export type LightCycleRecipeOnConflict = {
  constraint: LightCycleRecipeConstraint;
  update_columns?: Array<LightCycleRecipeUpdateColumn>;
  where?: InputMaybe<LightCycleRecipeBoolExp>;
};

/** Ordering options when selecting data from "light_cycle_recipe". */
export type LightCycleRecipeOrderBy = {
  growth_cycles_aggregate?: InputMaybe<GrowthCycleAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  is_active?: InputMaybe<OrderBy>;
  last_updated?: InputMaybe<OrderBy>;
  light_cycle_recipe_entrys_aggregate?: InputMaybe<LightCycleRecipeEntryAggregateOrderBy>;
  location?: InputMaybe<LocationOrderBy>;
  location_id?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: light_cycle_recipe */
export type LightCycleRecipePkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type LightCycleRecipePrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "light_cycle_recipe" */
export enum LightCycleRecipeSelectColumn {
  /** column name */
  id = 'id',
  /** column name */
  is_active = 'is_active',
  /** column name */
  last_updated = 'last_updated',
  /** column name */
  location_id = 'location_id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  name = 'name',
  /** column name */
  organization_id = 'organization_id'
}

/** select "light_cycle_recipe_aggregate_bool_exp_bool_and_arguments_columns" columns of table "light_cycle_recipe" */
export enum LightCycleRecipeSelectColumnLightCycleRecipeAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  is_active = 'is_active'
}

/** select "light_cycle_recipe_aggregate_bool_exp_bool_or_arguments_columns" columns of table "light_cycle_recipe" */
export enum LightCycleRecipeSelectColumnLightCycleRecipeAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  is_active = 'is_active'
}

/** input type for updating data in table "light_cycle_recipe" */
export type LightCycleRecipeSetInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated?: InputMaybe<Scalars['timestamptz']['input']>;
  location_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type LightCycleRecipeStddevFields = {
  __typename?: 'light_cycle_recipe_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "light_cycle_recipe" */
export type LightCycleRecipeStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LightCycleRecipeStddevPopFields = {
  __typename?: 'light_cycle_recipe_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "light_cycle_recipe" */
export type LightCycleRecipeStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LightCycleRecipeStddevSampFields = {
  __typename?: 'light_cycle_recipe_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "light_cycle_recipe" */
export type LightCycleRecipeStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "light_cycle_recipe" */
export type LightCycleRecipeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: LightCycleRecipeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LightCycleRecipeStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  last_updated?: InputMaybe<Scalars['timestamptz']['input']>;
  location_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type LightCycleRecipeSumFields = {
  __typename?: 'light_cycle_recipe_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  location_id?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "light_cycle_recipe" */
export type LightCycleRecipeSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** update columns of table "light_cycle_recipe" */
export enum LightCycleRecipeUpdateColumn {
  /** column name */
  id = 'id',
  /** column name */
  is_active = 'is_active',
  /** column name */
  last_updated = 'last_updated',
  /** column name */
  location_id = 'location_id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  name = 'name',
  /** column name */
  organization_id = 'organization_id'
}

export type LightCycleRecipeUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<LightCycleRecipeAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<LightCycleRecipeDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<LightCycleRecipeDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<LightCycleRecipeDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LightCycleRecipeIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<LightCycleRecipePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LightCycleRecipeSetInput>;
  /** filter the rows which have to be updated */
  where: LightCycleRecipeBoolExp;
};

/** aggregate var_pop on columns */
export type LightCycleRecipeVarPopFields = {
  __typename?: 'light_cycle_recipe_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "light_cycle_recipe" */
export type LightCycleRecipeVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LightCycleRecipeVarSampFields = {
  __typename?: 'light_cycle_recipe_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "light_cycle_recipe" */
export type LightCycleRecipeVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type LightCycleRecipeVarianceFields = {
  __typename?: 'light_cycle_recipe_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "light_cycle_recipe" */
export type LightCycleRecipeVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "location" */
export type Location = {
  __typename?: 'location';
  /** An object relationship */
  address: Address;
  address_id: Scalars['Int']['output'];
  /** An object relationship */
  enumeration: Enumeration;
  /** An array relationship */
  environment_recipes: Array<EnvironmentRecipe>;
  /** An aggregate relationship */
  environment_recipes_aggregate: EnvironmentRecipeAggregate;
  id: Scalars['Int']['output'];
  /** An array relationship */
  light_cycle_recipes: Array<LightCycleRecipe>;
  /** An aggregate relationship */
  light_cycle_recipes_aggregate: LightCycleRecipeAggregate;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  name_id: Scalars['Int']['output'];
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['Int']['output'];
  time_zone_name: Scalars['String']['output'];
  /** An array relationship */
  user_organization_roles: Array<UserOrganizationRole>;
  /** An aggregate relationship */
  user_organization_roles_aggregate: UserOrganizationRoleAggregate;
  /** An array relationship */
  zones: Array<Zone>;
  /** An aggregate relationship */
  zones_aggregate: ZoneAggregate;
};


/** columns and relationships of "location" */
export type LocationEnvironmentRecipesArgs = {
  distinct_on?: InputMaybe<Array<EnvironmentRecipeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EnvironmentRecipeOrderBy>>;
  where?: InputMaybe<EnvironmentRecipeBoolExp>;
};


/** columns and relationships of "location" */
export type LocationEnvironmentRecipesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EnvironmentRecipeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EnvironmentRecipeOrderBy>>;
  where?: InputMaybe<EnvironmentRecipeBoolExp>;
};


/** columns and relationships of "location" */
export type LocationLightCycleRecipesArgs = {
  distinct_on?: InputMaybe<Array<LightCycleRecipeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LightCycleRecipeOrderBy>>;
  where?: InputMaybe<LightCycleRecipeBoolExp>;
};


/** columns and relationships of "location" */
export type LocationLightCycleRecipesAggregateArgs = {
  distinct_on?: InputMaybe<Array<LightCycleRecipeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LightCycleRecipeOrderBy>>;
  where?: InputMaybe<LightCycleRecipeBoolExp>;
};


/** columns and relationships of "location" */
export type LocationMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "location" */
export type LocationUserOrganizationRolesArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizationRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrganizationRoleOrderBy>>;
  where?: InputMaybe<UserOrganizationRoleBoolExp>;
};


/** columns and relationships of "location" */
export type LocationUserOrganizationRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizationRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrganizationRoleOrderBy>>;
  where?: InputMaybe<UserOrganizationRoleBoolExp>;
};


/** columns and relationships of "location" */
export type LocationZonesArgs = {
  distinct_on?: InputMaybe<Array<ZoneSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ZoneOrderBy>>;
  where?: InputMaybe<ZoneBoolExp>;
};


/** columns and relationships of "location" */
export type LocationZonesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoneSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ZoneOrderBy>>;
  where?: InputMaybe<ZoneBoolExp>;
};

/** aggregated selection of "location" */
export type LocationAggregate = {
  __typename?: 'location_aggregate';
  aggregate?: Maybe<LocationAggregateFields>;
  nodes: Array<Location>;
};

export type LocationAggregateBoolExp = {
  count?: InputMaybe<LocationAggregateBoolExpCount>;
};

export type LocationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<LocationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<LocationBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "location" */
export type LocationAggregateFields = {
  __typename?: 'location_aggregate_fields';
  avg?: Maybe<LocationAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<LocationMaxFields>;
  min?: Maybe<LocationMinFields>;
  stddev?: Maybe<LocationStddevFields>;
  stddev_pop?: Maybe<LocationStddevPopFields>;
  stddev_samp?: Maybe<LocationStddevSampFields>;
  sum?: Maybe<LocationSumFields>;
  var_pop?: Maybe<LocationVarPopFields>;
  var_samp?: Maybe<LocationVarSampFields>;
  variance?: Maybe<LocationVarianceFields>;
};


/** aggregate fields of "location" */
export type LocationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LocationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "location" */
export type LocationAggregateOrderBy = {
  avg?: InputMaybe<LocationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<LocationMaxOrderBy>;
  min?: InputMaybe<LocationMinOrderBy>;
  stddev?: InputMaybe<LocationStddevOrderBy>;
  stddev_pop?: InputMaybe<LocationStddevPopOrderBy>;
  stddev_samp?: InputMaybe<LocationStddevSampOrderBy>;
  sum?: InputMaybe<LocationSumOrderBy>;
  var_pop?: InputMaybe<LocationVarPopOrderBy>;
  var_samp?: InputMaybe<LocationVarSampOrderBy>;
  variance?: InputMaybe<LocationVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type LocationAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "location" */
export type LocationArrRelInsertInput = {
  data: Array<LocationInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<LocationOnConflict>;
};

/** aggregate avg on columns */
export type LocationAvgFields = {
  __typename?: 'location_avg_fields';
  address_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "location" */
export type LocationAvgOrderBy = {
  address_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "location". All fields are combined with a logical 'AND'. */
export type LocationBoolExp = {
  _and?: InputMaybe<Array<LocationBoolExp>>;
  _not?: InputMaybe<LocationBoolExp>;
  _or?: InputMaybe<Array<LocationBoolExp>>;
  address?: InputMaybe<AddressBoolExp>;
  address_id?: InputMaybe<IntComparisonExp>;
  enumeration?: InputMaybe<EnumerationBoolExp>;
  environment_recipes?: InputMaybe<EnvironmentRecipeBoolExp>;
  environment_recipes_aggregate?: InputMaybe<EnvironmentRecipeAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  light_cycle_recipes?: InputMaybe<LightCycleRecipeBoolExp>;
  light_cycle_recipes_aggregate?: InputMaybe<LightCycleRecipeAggregateBoolExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  name_id?: InputMaybe<IntComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organization_id?: InputMaybe<IntComparisonExp>;
  time_zone_name?: InputMaybe<StringComparisonExp>;
  user_organization_roles?: InputMaybe<UserOrganizationRoleBoolExp>;
  user_organization_roles_aggregate?: InputMaybe<UserOrganizationRoleAggregateBoolExp>;
  zones?: InputMaybe<ZoneBoolExp>;
  zones_aggregate?: InputMaybe<ZoneAggregateBoolExp>;
};

/** unique or primary key constraints on table "location" */
export enum LocationConstraint {
  /** unique or primary key constraint on columns "id" */
  location_pkey = 'location_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type LocationDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type LocationDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type LocationDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "location" */
export type LocationIncInput = {
  address_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "location" */
export type LocationInsertInput = {
  address?: InputMaybe<AddressObjRelInsertInput>;
  address_id?: InputMaybe<Scalars['Int']['input']>;
  enumeration?: InputMaybe<EnumerationObjRelInsertInput>;
  environment_recipes?: InputMaybe<EnvironmentRecipeArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  light_cycle_recipes?: InputMaybe<LightCycleRecipeArrRelInsertInput>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  time_zone_name?: InputMaybe<Scalars['String']['input']>;
  user_organization_roles?: InputMaybe<UserOrganizationRoleArrRelInsertInput>;
  zones?: InputMaybe<ZoneArrRelInsertInput>;
};

/** aggregate max on columns */
export type LocationMaxFields = {
  __typename?: 'location_max_fields';
  address_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name_id?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  time_zone_name?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "location" */
export type LocationMaxOrderBy = {
  address_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  time_zone_name?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type LocationMinFields = {
  __typename?: 'location_min_fields';
  address_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name_id?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  time_zone_name?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "location" */
export type LocationMinOrderBy = {
  address_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  time_zone_name?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "location" */
export type LocationMutationResponse = {
  __typename?: 'location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Location>;
};

/** input type for inserting object relation for remote table "location" */
export type LocationObjRelInsertInput = {
  data: LocationInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<LocationOnConflict>;
};

/** on_conflict condition type for table "location" */
export type LocationOnConflict = {
  constraint: LocationConstraint;
  update_columns?: Array<LocationUpdateColumn>;
  where?: InputMaybe<LocationBoolExp>;
};

/** Ordering options when selecting data from "location". */
export type LocationOrderBy = {
  address?: InputMaybe<AddressOrderBy>;
  address_id?: InputMaybe<OrderBy>;
  enumeration?: InputMaybe<EnumerationOrderBy>;
  environment_recipes_aggregate?: InputMaybe<EnvironmentRecipeAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  light_cycle_recipes_aggregate?: InputMaybe<LightCycleRecipeAggregateOrderBy>;
  metadata?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  time_zone_name?: InputMaybe<OrderBy>;
  user_organization_roles_aggregate?: InputMaybe<UserOrganizationRoleAggregateOrderBy>;
  zones_aggregate?: InputMaybe<ZoneAggregateOrderBy>;
};

/** primary key columns input for table: location */
export type LocationPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type LocationPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "location" */
export enum LocationSelectColumn {
  /** column name */
  address_id = 'address_id',
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  name_id = 'name_id',
  /** column name */
  organization_id = 'organization_id',
  /** column name */
  time_zone_name = 'time_zone_name'
}

/** input type for updating data in table "location" */
export type LocationSetInput = {
  address_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  time_zone_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type LocationStddevFields = {
  __typename?: 'location_stddev_fields';
  address_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "location" */
export type LocationStddevOrderBy = {
  address_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type LocationStddevPopFields = {
  __typename?: 'location_stddev_pop_fields';
  address_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "location" */
export type LocationStddevPopOrderBy = {
  address_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type LocationStddevSampFields = {
  __typename?: 'location_stddev_samp_fields';
  address_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "location" */
export type LocationStddevSampOrderBy = {
  address_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "location" */
export type LocationStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: LocationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LocationStreamCursorValueInput = {
  address_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  time_zone_name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type LocationSumFields = {
  __typename?: 'location_sum_fields';
  address_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name_id?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "location" */
export type LocationSumOrderBy = {
  address_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** update columns of table "location" */
export enum LocationUpdateColumn {
  /** column name */
  address_id = 'address_id',
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  name_id = 'name_id',
  /** column name */
  organization_id = 'organization_id',
  /** column name */
  time_zone_name = 'time_zone_name'
}

export type LocationUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<LocationAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<LocationDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<LocationDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<LocationDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<LocationIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<LocationPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LocationSetInput>;
  /** filter the rows which have to be updated */
  where: LocationBoolExp;
};

/** aggregate var_pop on columns */
export type LocationVarPopFields = {
  __typename?: 'location_var_pop_fields';
  address_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "location" */
export type LocationVarPopOrderBy = {
  address_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type LocationVarSampFields = {
  __typename?: 'location_var_samp_fields';
  address_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "location" */
export type LocationVarSampOrderBy = {
  address_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type LocationVarianceFields = {
  __typename?: 'location_variance_fields';
  address_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "location" */
export type LocationVarianceOrderBy = {
  address_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "measurement" */
export type Measurement = {
  __typename?: 'measurement';
  /** An object relationship */
  config?: Maybe<Config>;
  config_id?: Maybe<Scalars['Int']['output']>;
  data: Scalars['jsonb']['output'];
  /** An array relationship */
  detection_runs: Array<DetectionRun>;
  /** An aggregate relationship */
  detection_runs_aggregate: DetectionRunAggregate;
  /** An array relationship */
  discussions: Array<Discussion>;
  /** An aggregate relationship */
  discussions_aggregate: DiscussionAggregate;
  /** An object relationship */
  enumeration: Enumeration;
  /** An object relationship */
  enumerationBySensorModelId?: Maybe<Enumeration>;
  /** An object relationship */
  enumerationByTypeId: Enumeration;
  id: Scalars['bigint']['output'];
  /** An array relationship */
  image_annotation_trackings: Array<ImageAnnotationTracking>;
  /** An aggregate relationship */
  image_annotation_trackings_aggregate: ImageAnnotationTrackingAggregate;
  /** An array relationship */
  labels: Array<Label>;
  /** An aggregate relationship */
  labels_aggregate: LabelAggregate;
  /** An object relationship */
  measurement_run?: Maybe<MeasurementRun>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  pose: Pose;
  pose_id: Scalars['Int']['output'];
  /** An object relationship */
  sensor?: Maybe<Sensor>;
  sensor_id?: Maybe<Scalars['Int']['output']>;
  sensor_model_id: Scalars['Int']['output'];
  /** An object relationship */
  system?: Maybe<System>;
  system_id?: Maybe<Scalars['Int']['output']>;
  time: Scalars['timestamptz']['output'];
  type_id: Scalars['Int']['output'];
  /** An object relationship */
  zone?: Maybe<Zone>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "measurement" */
export type MeasurementDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "measurement" */
export type MeasurementDetectionRunsArgs = {
  distinct_on?: InputMaybe<Array<DetectionRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectionRunOrderBy>>;
  where?: InputMaybe<DetectionRunBoolExp>;
};


/** columns and relationships of "measurement" */
export type MeasurementDetectionRunsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DetectionRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectionRunOrderBy>>;
  where?: InputMaybe<DetectionRunBoolExp>;
};


/** columns and relationships of "measurement" */
export type MeasurementDiscussionsArgs = {
  distinct_on?: InputMaybe<Array<DiscussionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DiscussionOrderBy>>;
  where?: InputMaybe<DiscussionBoolExp>;
};


/** columns and relationships of "measurement" */
export type MeasurementDiscussionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DiscussionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DiscussionOrderBy>>;
  where?: InputMaybe<DiscussionBoolExp>;
};


/** columns and relationships of "measurement" */
export type MeasurementImageAnnotationTrackingsArgs = {
  distinct_on?: InputMaybe<Array<ImageAnnotationTrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImageAnnotationTrackingOrderBy>>;
  where?: InputMaybe<ImageAnnotationTrackingBoolExp>;
};


/** columns and relationships of "measurement" */
export type MeasurementImageAnnotationTrackingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImageAnnotationTrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImageAnnotationTrackingOrderBy>>;
  where?: InputMaybe<ImageAnnotationTrackingBoolExp>;
};


/** columns and relationships of "measurement" */
export type MeasurementLabelsArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


/** columns and relationships of "measurement" */
export type MeasurementLabelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


/** columns and relationships of "measurement" */
export type MeasurementMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "measurement" */
export type MeasurementAggregate = {
  __typename?: 'measurement_aggregate';
  aggregate?: Maybe<MeasurementAggregateFields>;
  nodes: Array<Measurement>;
};

export type MeasurementAggregateBoolExp = {
  count?: InputMaybe<MeasurementAggregateBoolExpCount>;
};

export type MeasurementAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MeasurementSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<MeasurementBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "measurement" */
export type MeasurementAggregateFields = {
  __typename?: 'measurement_aggregate_fields';
  avg?: Maybe<MeasurementAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<MeasurementMaxFields>;
  min?: Maybe<MeasurementMinFields>;
  stddev?: Maybe<MeasurementStddevFields>;
  stddev_pop?: Maybe<MeasurementStddevPopFields>;
  stddev_samp?: Maybe<MeasurementStddevSampFields>;
  sum?: Maybe<MeasurementSumFields>;
  var_pop?: Maybe<MeasurementVarPopFields>;
  var_samp?: Maybe<MeasurementVarSampFields>;
  variance?: Maybe<MeasurementVarianceFields>;
};


/** aggregate fields of "measurement" */
export type MeasurementAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MeasurementSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "measurement" */
export type MeasurementAggregateOrderBy = {
  avg?: InputMaybe<MeasurementAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MeasurementMaxOrderBy>;
  min?: InputMaybe<MeasurementMinOrderBy>;
  stddev?: InputMaybe<MeasurementStddevOrderBy>;
  stddev_pop?: InputMaybe<MeasurementStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MeasurementStddevSampOrderBy>;
  sum?: InputMaybe<MeasurementSumOrderBy>;
  var_pop?: InputMaybe<MeasurementVarPopOrderBy>;
  var_samp?: InputMaybe<MeasurementVarSampOrderBy>;
  variance?: InputMaybe<MeasurementVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type MeasurementAppendInput = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "measurement" */
export type MeasurementArrRelInsertInput = {
  data: Array<MeasurementInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MeasurementOnConflict>;
};

/** aggregate avg on columns */
export type MeasurementAvgFields = {
  __typename?: 'measurement_avg_fields';
  config_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  pose_id?: Maybe<Scalars['Float']['output']>;
  sensor_id?: Maybe<Scalars['Float']['output']>;
  sensor_model_id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "measurement" */
export type MeasurementAvgOrderBy = {
  config_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  sensor_id?: InputMaybe<OrderBy>;
  sensor_model_id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "measurement". All fields are combined with a logical 'AND'. */
export type MeasurementBoolExp = {
  _and?: InputMaybe<Array<MeasurementBoolExp>>;
  _not?: InputMaybe<MeasurementBoolExp>;
  _or?: InputMaybe<Array<MeasurementBoolExp>>;
  config?: InputMaybe<ConfigBoolExp>;
  config_id?: InputMaybe<IntComparisonExp>;
  data?: InputMaybe<JsonbComparisonExp>;
  detection_runs?: InputMaybe<DetectionRunBoolExp>;
  detection_runs_aggregate?: InputMaybe<DetectionRunAggregateBoolExp>;
  discussions?: InputMaybe<DiscussionBoolExp>;
  discussions_aggregate?: InputMaybe<DiscussionAggregateBoolExp>;
  enumeration?: InputMaybe<EnumerationBoolExp>;
  enumerationBySensorModelId?: InputMaybe<EnumerationBoolExp>;
  enumerationByTypeId?: InputMaybe<EnumerationBoolExp>;
  id?: InputMaybe<BigintComparisonExp>;
  image_annotation_trackings?: InputMaybe<ImageAnnotationTrackingBoolExp>;
  image_annotation_trackings_aggregate?: InputMaybe<ImageAnnotationTrackingAggregateBoolExp>;
  labels?: InputMaybe<LabelBoolExp>;
  labels_aggregate?: InputMaybe<LabelAggregateBoolExp>;
  measurement_run?: InputMaybe<MeasurementRunBoolExp>;
  measurement_run_id?: InputMaybe<IntComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  pose?: InputMaybe<PoseBoolExp>;
  pose_id?: InputMaybe<IntComparisonExp>;
  sensor?: InputMaybe<SensorBoolExp>;
  sensor_id?: InputMaybe<IntComparisonExp>;
  sensor_model_id?: InputMaybe<IntComparisonExp>;
  system?: InputMaybe<SystemBoolExp>;
  system_id?: InputMaybe<IntComparisonExp>;
  time?: InputMaybe<TimestamptzComparisonExp>;
  type_id?: InputMaybe<IntComparisonExp>;
  zone?: InputMaybe<ZoneBoolExp>;
  zone_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "measurement" */
export enum MeasurementConstraint {
  /** unique or primary key constraint on columns "id" */
  measurement_id_un = 'measurement_id_un',
  /** unique or primary key constraint on columns "time", "id" */
  measurement_pkey = 'measurement_pkey',
  /** unique or primary key constraint on columns "system_id", "sensor_model_id", "time", "sensor_id", "type_id" */
  measurement_system_time_un = 'measurement_system_time_un',
  /** unique or primary key constraint on columns "measurement_run_id", "sensor_model_id", "time", "sensor_id", "type_id" */
  measurement_time_un = 'measurement_time_un'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type MeasurementDeleteAtPathInput = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type MeasurementDeleteElemInput = {
  data?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type MeasurementDeleteKeyInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "measurement" */
export type MeasurementIncInput = {
  config_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
  pose_id?: InputMaybe<Scalars['Int']['input']>;
  sensor_id?: InputMaybe<Scalars['Int']['input']>;
  sensor_model_id?: InputMaybe<Scalars['Int']['input']>;
  system_id?: InputMaybe<Scalars['Int']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "measurement" */
export type MeasurementInsertInput = {
  config?: InputMaybe<ConfigObjRelInsertInput>;
  config_id?: InputMaybe<Scalars['Int']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  detection_runs?: InputMaybe<DetectionRunArrRelInsertInput>;
  discussions?: InputMaybe<DiscussionArrRelInsertInput>;
  enumeration?: InputMaybe<EnumerationObjRelInsertInput>;
  enumerationBySensorModelId?: InputMaybe<EnumerationObjRelInsertInput>;
  enumerationByTypeId?: InputMaybe<EnumerationObjRelInsertInput>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_annotation_trackings?: InputMaybe<ImageAnnotationTrackingArrRelInsertInput>;
  labels?: InputMaybe<LabelArrRelInsertInput>;
  measurement_run?: InputMaybe<MeasurementRunObjRelInsertInput>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  pose?: InputMaybe<PoseObjRelInsertInput>;
  pose_id?: InputMaybe<Scalars['Int']['input']>;
  sensor?: InputMaybe<SensorObjRelInsertInput>;
  sensor_id?: InputMaybe<Scalars['Int']['input']>;
  sensor_model_id?: InputMaybe<Scalars['Int']['input']>;
  system?: InputMaybe<SystemObjRelInsertInput>;
  system_id?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
  zone?: InputMaybe<ZoneObjRelInsertInput>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type MeasurementMaxFields = {
  __typename?: 'measurement_max_fields';
  config_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  pose_id?: Maybe<Scalars['Int']['output']>;
  sensor_id?: Maybe<Scalars['Int']['output']>;
  sensor_model_id?: Maybe<Scalars['Int']['output']>;
  system_id?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "measurement" */
export type MeasurementMaxOrderBy = {
  config_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  sensor_id?: InputMaybe<OrderBy>;
  sensor_model_id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  time?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MeasurementMinFields = {
  __typename?: 'measurement_min_fields';
  config_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  pose_id?: Maybe<Scalars['Int']['output']>;
  sensor_id?: Maybe<Scalars['Int']['output']>;
  sensor_model_id?: Maybe<Scalars['Int']['output']>;
  system_id?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "measurement" */
export type MeasurementMinOrderBy = {
  config_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  sensor_id?: InputMaybe<OrderBy>;
  sensor_model_id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  time?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "measurement" */
export type MeasurementMutationResponse = {
  __typename?: 'measurement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Measurement>;
};

/** input type for inserting object relation for remote table "measurement" */
export type MeasurementObjRelInsertInput = {
  data: MeasurementInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<MeasurementOnConflict>;
};

/** on_conflict condition type for table "measurement" */
export type MeasurementOnConflict = {
  constraint: MeasurementConstraint;
  update_columns?: Array<MeasurementUpdateColumn>;
  where?: InputMaybe<MeasurementBoolExp>;
};

/** Ordering options when selecting data from "measurement". */
export type MeasurementOrderBy = {
  config?: InputMaybe<ConfigOrderBy>;
  config_id?: InputMaybe<OrderBy>;
  data?: InputMaybe<OrderBy>;
  detection_runs_aggregate?: InputMaybe<DetectionRunAggregateOrderBy>;
  discussions_aggregate?: InputMaybe<DiscussionAggregateOrderBy>;
  enumeration?: InputMaybe<EnumerationOrderBy>;
  enumerationBySensorModelId?: InputMaybe<EnumerationOrderBy>;
  enumerationByTypeId?: InputMaybe<EnumerationOrderBy>;
  id?: InputMaybe<OrderBy>;
  image_annotation_trackings_aggregate?: InputMaybe<ImageAnnotationTrackingAggregateOrderBy>;
  labels_aggregate?: InputMaybe<LabelAggregateOrderBy>;
  measurement_run?: InputMaybe<MeasurementRunOrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  pose?: InputMaybe<PoseOrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  sensor?: InputMaybe<SensorOrderBy>;
  sensor_id?: InputMaybe<OrderBy>;
  sensor_model_id?: InputMaybe<OrderBy>;
  system?: InputMaybe<SystemOrderBy>;
  system_id?: InputMaybe<OrderBy>;
  time?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone?: InputMaybe<ZoneOrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: measurement */
export type MeasurementPkColumnsInput = {
  id: Scalars['bigint']['input'];
  time: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type MeasurementPrependInput = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "measurement_run" */
export type MeasurementRun = {
  __typename?: 'measurement_run';
  annotation_status_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  computed_measurements: Array<ComputedMeasurement>;
  /** An aggregate relationship */
  computed_measurements_aggregate: ComputedMeasurementAggregate;
  /** An object relationship */
  config: Config;
  config_id: Scalars['Int']['output'];
  end_time?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  enumeration?: Maybe<Enumeration>;
  /** An object relationship */
  grid?: Maybe<Grid>;
  grid_id?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  heat_maps: Array<HeatMap>;
  /** An aggregate relationship */
  heat_maps_aggregate: HeatMapAggregate;
  id: Scalars['Int']['output'];
  /** An array relationship */
  labels: Array<Label>;
  /** An aggregate relationship */
  labels_aggregate: LabelAggregate;
  /** An array relationship */
  lambda_run_measurement_runs: Array<LambdaRunMeasurementRun>;
  /** An aggregate relationship */
  lambda_run_measurement_runs_aggregate: LambdaRunMeasurementRunAggregate;
  /** An array relationship */
  measurements: Array<Measurement>;
  /** An aggregate relationship */
  measurements_aggregate: MeasurementAggregate;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  start_time: Scalars['timestamptz']['output'];
  /** An object relationship */
  system: System;
  system_id: Scalars['Int']['output'];
  valid: Scalars['Boolean']['output'];
  /** An object relationship */
  zone: Zone;
  zone_id: Scalars['Int']['output'];
};


/** columns and relationships of "measurement_run" */
export type MeasurementRunComputedMeasurementsArgs = {
  distinct_on?: InputMaybe<Array<ComputedMeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputedMeasurementOrderBy>>;
  where?: InputMaybe<ComputedMeasurementBoolExp>;
};


/** columns and relationships of "measurement_run" */
export type MeasurementRunComputedMeasurementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ComputedMeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputedMeasurementOrderBy>>;
  where?: InputMaybe<ComputedMeasurementBoolExp>;
};


/** columns and relationships of "measurement_run" */
export type MeasurementRunHeatMapsArgs = {
  distinct_on?: InputMaybe<Array<HeatMapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeatMapOrderBy>>;
  where?: InputMaybe<HeatMapBoolExp>;
};


/** columns and relationships of "measurement_run" */
export type MeasurementRunHeatMapsAggregateArgs = {
  distinct_on?: InputMaybe<Array<HeatMapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeatMapOrderBy>>;
  where?: InputMaybe<HeatMapBoolExp>;
};


/** columns and relationships of "measurement_run" */
export type MeasurementRunLabelsArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


/** columns and relationships of "measurement_run" */
export type MeasurementRunLabelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


/** columns and relationships of "measurement_run" */
export type MeasurementRunLambdaRunMeasurementRunsArgs = {
  distinct_on?: InputMaybe<Array<LambdaRunMeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LambdaRunMeasurementRunOrderBy>>;
  where?: InputMaybe<LambdaRunMeasurementRunBoolExp>;
};


/** columns and relationships of "measurement_run" */
export type MeasurementRunLambdaRunMeasurementRunsAggregateArgs = {
  distinct_on?: InputMaybe<Array<LambdaRunMeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LambdaRunMeasurementRunOrderBy>>;
  where?: InputMaybe<LambdaRunMeasurementRunBoolExp>;
};


/** columns and relationships of "measurement_run" */
export type MeasurementRunMeasurementsArgs = {
  distinct_on?: InputMaybe<Array<MeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementOrderBy>>;
  where?: InputMaybe<MeasurementBoolExp>;
};


/** columns and relationships of "measurement_run" */
export type MeasurementRunMeasurementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementOrderBy>>;
  where?: InputMaybe<MeasurementBoolExp>;
};


/** columns and relationships of "measurement_run" */
export type MeasurementRunMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "measurement_run" */
export type MeasurementRunAggregate = {
  __typename?: 'measurement_run_aggregate';
  aggregate?: Maybe<MeasurementRunAggregateFields>;
  nodes: Array<MeasurementRun>;
};

export type MeasurementRunAggregateBoolExp = {
  bool_and?: InputMaybe<MeasurementRunAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<MeasurementRunAggregateBoolExpBoolOr>;
  count?: InputMaybe<MeasurementRunAggregateBoolExpCount>;
};

export type MeasurementRunAggregateBoolExpBoolAnd = {
  arguments: MeasurementRunSelectColumnMeasurementRunAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<MeasurementRunBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MeasurementRunAggregateBoolExpBoolOr = {
  arguments: MeasurementRunSelectColumnMeasurementRunAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<MeasurementRunBoolExp>;
  predicate: BooleanComparisonExp;
};

export type MeasurementRunAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<MeasurementRunSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<MeasurementRunBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "measurement_run" */
export type MeasurementRunAggregateFields = {
  __typename?: 'measurement_run_aggregate_fields';
  avg?: Maybe<MeasurementRunAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<MeasurementRunMaxFields>;
  min?: Maybe<MeasurementRunMinFields>;
  stddev?: Maybe<MeasurementRunStddevFields>;
  stddev_pop?: Maybe<MeasurementRunStddevPopFields>;
  stddev_samp?: Maybe<MeasurementRunStddevSampFields>;
  sum?: Maybe<MeasurementRunSumFields>;
  var_pop?: Maybe<MeasurementRunVarPopFields>;
  var_samp?: Maybe<MeasurementRunVarSampFields>;
  variance?: Maybe<MeasurementRunVarianceFields>;
};


/** aggregate fields of "measurement_run" */
export type MeasurementRunAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MeasurementRunSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "measurement_run" */
export type MeasurementRunAggregateOrderBy = {
  avg?: InputMaybe<MeasurementRunAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<MeasurementRunMaxOrderBy>;
  min?: InputMaybe<MeasurementRunMinOrderBy>;
  stddev?: InputMaybe<MeasurementRunStddevOrderBy>;
  stddev_pop?: InputMaybe<MeasurementRunStddevPopOrderBy>;
  stddev_samp?: InputMaybe<MeasurementRunStddevSampOrderBy>;
  sum?: InputMaybe<MeasurementRunSumOrderBy>;
  var_pop?: InputMaybe<MeasurementRunVarPopOrderBy>;
  var_samp?: InputMaybe<MeasurementRunVarSampOrderBy>;
  variance?: InputMaybe<MeasurementRunVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type MeasurementRunAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "measurement_run" */
export type MeasurementRunArrRelInsertInput = {
  data: Array<MeasurementRunInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<MeasurementRunOnConflict>;
};

/** aggregate avg on columns */
export type MeasurementRunAvgFields = {
  __typename?: 'measurement_run_avg_fields';
  annotation_status_id?: Maybe<Scalars['Float']['output']>;
  config_id?: Maybe<Scalars['Float']['output']>;
  grid_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "measurement_run" */
export type MeasurementRunAvgOrderBy = {
  annotation_status_id?: InputMaybe<OrderBy>;
  config_id?: InputMaybe<OrderBy>;
  grid_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "measurement_run". All fields are combined with a logical 'AND'. */
export type MeasurementRunBoolExp = {
  _and?: InputMaybe<Array<MeasurementRunBoolExp>>;
  _not?: InputMaybe<MeasurementRunBoolExp>;
  _or?: InputMaybe<Array<MeasurementRunBoolExp>>;
  annotation_status_id?: InputMaybe<IntComparisonExp>;
  computed_measurements?: InputMaybe<ComputedMeasurementBoolExp>;
  computed_measurements_aggregate?: InputMaybe<ComputedMeasurementAggregateBoolExp>;
  config?: InputMaybe<ConfigBoolExp>;
  config_id?: InputMaybe<IntComparisonExp>;
  end_time?: InputMaybe<TimestamptzComparisonExp>;
  enumeration?: InputMaybe<EnumerationBoolExp>;
  grid?: InputMaybe<GridBoolExp>;
  grid_id?: InputMaybe<IntComparisonExp>;
  heat_maps?: InputMaybe<HeatMapBoolExp>;
  heat_maps_aggregate?: InputMaybe<HeatMapAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  labels?: InputMaybe<LabelBoolExp>;
  labels_aggregate?: InputMaybe<LabelAggregateBoolExp>;
  lambda_run_measurement_runs?: InputMaybe<LambdaRunMeasurementRunBoolExp>;
  lambda_run_measurement_runs_aggregate?: InputMaybe<LambdaRunMeasurementRunAggregateBoolExp>;
  measurements?: InputMaybe<MeasurementBoolExp>;
  measurements_aggregate?: InputMaybe<MeasurementAggregateBoolExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  start_time?: InputMaybe<TimestamptzComparisonExp>;
  system?: InputMaybe<SystemBoolExp>;
  system_id?: InputMaybe<IntComparisonExp>;
  valid?: InputMaybe<BooleanComparisonExp>;
  zone?: InputMaybe<ZoneBoolExp>;
  zone_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "measurement_run" */
export enum MeasurementRunConstraint {
  /** unique or primary key constraint on columns "id" */
  measurement_run_pkey = 'measurement_run_pkey',
  /** unique or primary key constraint on columns "system_id", "start_time" */
  measurement_run_un = 'measurement_run_un'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type MeasurementRunDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type MeasurementRunDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type MeasurementRunDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "measurement_run" */
export type MeasurementRunIncInput = {
  annotation_status_id?: InputMaybe<Scalars['Int']['input']>;
  config_id?: InputMaybe<Scalars['Int']['input']>;
  grid_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  system_id?: InputMaybe<Scalars['Int']['input']>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "measurement_run" */
export type MeasurementRunInsertInput = {
  annotation_status_id?: InputMaybe<Scalars['Int']['input']>;
  computed_measurements?: InputMaybe<ComputedMeasurementArrRelInsertInput>;
  config?: InputMaybe<ConfigObjRelInsertInput>;
  config_id?: InputMaybe<Scalars['Int']['input']>;
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  enumeration?: InputMaybe<EnumerationObjRelInsertInput>;
  grid?: InputMaybe<GridObjRelInsertInput>;
  grid_id?: InputMaybe<Scalars['Int']['input']>;
  heat_maps?: InputMaybe<HeatMapArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  labels?: InputMaybe<LabelArrRelInsertInput>;
  lambda_run_measurement_runs?: InputMaybe<LambdaRunMeasurementRunArrRelInsertInput>;
  measurements?: InputMaybe<MeasurementArrRelInsertInput>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
  system?: InputMaybe<SystemObjRelInsertInput>;
  system_id?: InputMaybe<Scalars['Int']['input']>;
  valid?: InputMaybe<Scalars['Boolean']['input']>;
  zone?: InputMaybe<ZoneObjRelInsertInput>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type MeasurementRunMaxFields = {
  __typename?: 'measurement_run_max_fields';
  annotation_status_id?: Maybe<Scalars['Int']['output']>;
  config_id?: Maybe<Scalars['Int']['output']>;
  end_time?: Maybe<Scalars['timestamptz']['output']>;
  grid_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  start_time?: Maybe<Scalars['timestamptz']['output']>;
  system_id?: Maybe<Scalars['Int']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "measurement_run" */
export type MeasurementRunMaxOrderBy = {
  annotation_status_id?: InputMaybe<OrderBy>;
  config_id?: InputMaybe<OrderBy>;
  end_time?: InputMaybe<OrderBy>;
  grid_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  start_time?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type MeasurementRunMinFields = {
  __typename?: 'measurement_run_min_fields';
  annotation_status_id?: Maybe<Scalars['Int']['output']>;
  config_id?: Maybe<Scalars['Int']['output']>;
  end_time?: Maybe<Scalars['timestamptz']['output']>;
  grid_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  start_time?: Maybe<Scalars['timestamptz']['output']>;
  system_id?: Maybe<Scalars['Int']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "measurement_run" */
export type MeasurementRunMinOrderBy = {
  annotation_status_id?: InputMaybe<OrderBy>;
  config_id?: InputMaybe<OrderBy>;
  end_time?: InputMaybe<OrderBy>;
  grid_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  start_time?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "measurement_run" */
export type MeasurementRunMutationResponse = {
  __typename?: 'measurement_run_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MeasurementRun>;
};

/** input type for inserting object relation for remote table "measurement_run" */
export type MeasurementRunObjRelInsertInput = {
  data: MeasurementRunInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<MeasurementRunOnConflict>;
};

/** on_conflict condition type for table "measurement_run" */
export type MeasurementRunOnConflict = {
  constraint: MeasurementRunConstraint;
  update_columns?: Array<MeasurementRunUpdateColumn>;
  where?: InputMaybe<MeasurementRunBoolExp>;
};

/** Ordering options when selecting data from "measurement_run". */
export type MeasurementRunOrderBy = {
  annotation_status_id?: InputMaybe<OrderBy>;
  computed_measurements_aggregate?: InputMaybe<ComputedMeasurementAggregateOrderBy>;
  config?: InputMaybe<ConfigOrderBy>;
  config_id?: InputMaybe<OrderBy>;
  end_time?: InputMaybe<OrderBy>;
  enumeration?: InputMaybe<EnumerationOrderBy>;
  grid?: InputMaybe<GridOrderBy>;
  grid_id?: InputMaybe<OrderBy>;
  heat_maps_aggregate?: InputMaybe<HeatMapAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  labels_aggregate?: InputMaybe<LabelAggregateOrderBy>;
  lambda_run_measurement_runs_aggregate?: InputMaybe<LambdaRunMeasurementRunAggregateOrderBy>;
  measurements_aggregate?: InputMaybe<MeasurementAggregateOrderBy>;
  metadata?: InputMaybe<OrderBy>;
  start_time?: InputMaybe<OrderBy>;
  system?: InputMaybe<SystemOrderBy>;
  system_id?: InputMaybe<OrderBy>;
  valid?: InputMaybe<OrderBy>;
  zone?: InputMaybe<ZoneOrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: measurement_run */
export type MeasurementRunPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type MeasurementRunPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "measurement_run" */
export enum MeasurementRunSelectColumn {
  /** column name */
  annotation_status_id = 'annotation_status_id',
  /** column name */
  config_id = 'config_id',
  /** column name */
  end_time = 'end_time',
  /** column name */
  grid_id = 'grid_id',
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  start_time = 'start_time',
  /** column name */
  system_id = 'system_id',
  /** column name */
  valid = 'valid',
  /** column name */
  zone_id = 'zone_id'
}

/** select "measurement_run_aggregate_bool_exp_bool_and_arguments_columns" columns of table "measurement_run" */
export enum MeasurementRunSelectColumnMeasurementRunAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  valid = 'valid'
}

/** select "measurement_run_aggregate_bool_exp_bool_or_arguments_columns" columns of table "measurement_run" */
export enum MeasurementRunSelectColumnMeasurementRunAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  valid = 'valid'
}

/** input type for updating data in table "measurement_run" */
export type MeasurementRunSetInput = {
  annotation_status_id?: InputMaybe<Scalars['Int']['input']>;
  config_id?: InputMaybe<Scalars['Int']['input']>;
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  grid_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
  system_id?: InputMaybe<Scalars['Int']['input']>;
  valid?: InputMaybe<Scalars['Boolean']['input']>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type MeasurementRunStddevFields = {
  __typename?: 'measurement_run_stddev_fields';
  annotation_status_id?: Maybe<Scalars['Float']['output']>;
  config_id?: Maybe<Scalars['Float']['output']>;
  grid_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "measurement_run" */
export type MeasurementRunStddevOrderBy = {
  annotation_status_id?: InputMaybe<OrderBy>;
  config_id?: InputMaybe<OrderBy>;
  grid_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type MeasurementRunStddevPopFields = {
  __typename?: 'measurement_run_stddev_pop_fields';
  annotation_status_id?: Maybe<Scalars['Float']['output']>;
  config_id?: Maybe<Scalars['Float']['output']>;
  grid_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "measurement_run" */
export type MeasurementRunStddevPopOrderBy = {
  annotation_status_id?: InputMaybe<OrderBy>;
  config_id?: InputMaybe<OrderBy>;
  grid_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type MeasurementRunStddevSampFields = {
  __typename?: 'measurement_run_stddev_samp_fields';
  annotation_status_id?: Maybe<Scalars['Float']['output']>;
  config_id?: Maybe<Scalars['Float']['output']>;
  grid_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "measurement_run" */
export type MeasurementRunStddevSampOrderBy = {
  annotation_status_id?: InputMaybe<OrderBy>;
  config_id?: InputMaybe<OrderBy>;
  grid_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "measurement_run" */
export type MeasurementRunStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MeasurementRunStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MeasurementRunStreamCursorValueInput = {
  annotation_status_id?: InputMaybe<Scalars['Int']['input']>;
  config_id?: InputMaybe<Scalars['Int']['input']>;
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  grid_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
  system_id?: InputMaybe<Scalars['Int']['input']>;
  valid?: InputMaybe<Scalars['Boolean']['input']>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type MeasurementRunSumFields = {
  __typename?: 'measurement_run_sum_fields';
  annotation_status_id?: Maybe<Scalars['Int']['output']>;
  config_id?: Maybe<Scalars['Int']['output']>;
  grid_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  system_id?: Maybe<Scalars['Int']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "measurement_run" */
export type MeasurementRunSumOrderBy = {
  annotation_status_id?: InputMaybe<OrderBy>;
  config_id?: InputMaybe<OrderBy>;
  grid_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** update columns of table "measurement_run" */
export enum MeasurementRunUpdateColumn {
  /** column name */
  annotation_status_id = 'annotation_status_id',
  /** column name */
  config_id = 'config_id',
  /** column name */
  end_time = 'end_time',
  /** column name */
  grid_id = 'grid_id',
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  start_time = 'start_time',
  /** column name */
  system_id = 'system_id',
  /** column name */
  valid = 'valid',
  /** column name */
  zone_id = 'zone_id'
}

export type MeasurementRunUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<MeasurementRunAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<MeasurementRunDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<MeasurementRunDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<MeasurementRunDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MeasurementRunIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<MeasurementRunPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MeasurementRunSetInput>;
  /** filter the rows which have to be updated */
  where: MeasurementRunBoolExp;
};

/** aggregate var_pop on columns */
export type MeasurementRunVarPopFields = {
  __typename?: 'measurement_run_var_pop_fields';
  annotation_status_id?: Maybe<Scalars['Float']['output']>;
  config_id?: Maybe<Scalars['Float']['output']>;
  grid_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "measurement_run" */
export type MeasurementRunVarPopOrderBy = {
  annotation_status_id?: InputMaybe<OrderBy>;
  config_id?: InputMaybe<OrderBy>;
  grid_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type MeasurementRunVarSampFields = {
  __typename?: 'measurement_run_var_samp_fields';
  annotation_status_id?: Maybe<Scalars['Float']['output']>;
  config_id?: Maybe<Scalars['Float']['output']>;
  grid_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "measurement_run" */
export type MeasurementRunVarSampOrderBy = {
  annotation_status_id?: InputMaybe<OrderBy>;
  config_id?: InputMaybe<OrderBy>;
  grid_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MeasurementRunVarianceFields = {
  __typename?: 'measurement_run_variance_fields';
  annotation_status_id?: Maybe<Scalars['Float']['output']>;
  config_id?: Maybe<Scalars['Float']['output']>;
  grid_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "measurement_run" */
export type MeasurementRunVarianceOrderBy = {
  annotation_status_id?: InputMaybe<OrderBy>;
  config_id?: InputMaybe<OrderBy>;
  grid_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** select columns of table "measurement" */
export enum MeasurementSelectColumn {
  /** column name */
  config_id = 'config_id',
  /** column name */
  data = 'data',
  /** column name */
  id = 'id',
  /** column name */
  measurement_run_id = 'measurement_run_id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  pose_id = 'pose_id',
  /** column name */
  sensor_id = 'sensor_id',
  /** column name */
  sensor_model_id = 'sensor_model_id',
  /** column name */
  system_id = 'system_id',
  /** column name */
  time = 'time',
  /** column name */
  type_id = 'type_id',
  /** column name */
  zone_id = 'zone_id'
}

/** input type for updating data in table "measurement" */
export type MeasurementSetInput = {
  config_id?: InputMaybe<Scalars['Int']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  pose_id?: InputMaybe<Scalars['Int']['input']>;
  sensor_id?: InputMaybe<Scalars['Int']['input']>;
  sensor_model_id?: InputMaybe<Scalars['Int']['input']>;
  system_id?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type MeasurementStddevFields = {
  __typename?: 'measurement_stddev_fields';
  config_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  pose_id?: Maybe<Scalars['Float']['output']>;
  sensor_id?: Maybe<Scalars['Float']['output']>;
  sensor_model_id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "measurement" */
export type MeasurementStddevOrderBy = {
  config_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  sensor_id?: InputMaybe<OrderBy>;
  sensor_model_id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type MeasurementStddevPopFields = {
  __typename?: 'measurement_stddev_pop_fields';
  config_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  pose_id?: Maybe<Scalars['Float']['output']>;
  sensor_id?: Maybe<Scalars['Float']['output']>;
  sensor_model_id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "measurement" */
export type MeasurementStddevPopOrderBy = {
  config_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  sensor_id?: InputMaybe<OrderBy>;
  sensor_model_id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type MeasurementStddevSampFields = {
  __typename?: 'measurement_stddev_samp_fields';
  config_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  pose_id?: Maybe<Scalars['Float']['output']>;
  sensor_id?: Maybe<Scalars['Float']['output']>;
  sensor_model_id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "measurement" */
export type MeasurementStddevSampOrderBy = {
  config_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  sensor_id?: InputMaybe<OrderBy>;
  sensor_model_id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "measurement" */
export type MeasurementStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MeasurementStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MeasurementStreamCursorValueInput = {
  config_id?: InputMaybe<Scalars['Int']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  pose_id?: InputMaybe<Scalars['Int']['input']>;
  sensor_id?: InputMaybe<Scalars['Int']['input']>;
  sensor_model_id?: InputMaybe<Scalars['Int']['input']>;
  system_id?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type MeasurementSumFields = {
  __typename?: 'measurement_sum_fields';
  config_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  pose_id?: Maybe<Scalars['Int']['output']>;
  sensor_id?: Maybe<Scalars['Int']['output']>;
  sensor_model_id?: Maybe<Scalars['Int']['output']>;
  system_id?: Maybe<Scalars['Int']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "measurement" */
export type MeasurementSumOrderBy = {
  config_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  sensor_id?: InputMaybe<OrderBy>;
  sensor_model_id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** update columns of table "measurement" */
export enum MeasurementUpdateColumn {
  /** column name */
  config_id = 'config_id',
  /** column name */
  data = 'data',
  /** column name */
  id = 'id',
  /** column name */
  measurement_run_id = 'measurement_run_id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  pose_id = 'pose_id',
  /** column name */
  sensor_id = 'sensor_id',
  /** column name */
  sensor_model_id = 'sensor_model_id',
  /** column name */
  system_id = 'system_id',
  /** column name */
  time = 'time',
  /** column name */
  type_id = 'type_id',
  /** column name */
  zone_id = 'zone_id'
}

export type MeasurementUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<MeasurementAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<MeasurementDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<MeasurementDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<MeasurementDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MeasurementIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<MeasurementPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MeasurementSetInput>;
  /** filter the rows which have to be updated */
  where: MeasurementBoolExp;
};

/** aggregate var_pop on columns */
export type MeasurementVarPopFields = {
  __typename?: 'measurement_var_pop_fields';
  config_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  pose_id?: Maybe<Scalars['Float']['output']>;
  sensor_id?: Maybe<Scalars['Float']['output']>;
  sensor_model_id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "measurement" */
export type MeasurementVarPopOrderBy = {
  config_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  sensor_id?: InputMaybe<OrderBy>;
  sensor_model_id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type MeasurementVarSampFields = {
  __typename?: 'measurement_var_samp_fields';
  config_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  pose_id?: Maybe<Scalars['Float']['output']>;
  sensor_id?: Maybe<Scalars['Float']['output']>;
  sensor_model_id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "measurement" */
export type MeasurementVarSampOrderBy = {
  config_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  sensor_id?: InputMaybe<OrderBy>;
  sensor_model_id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type MeasurementVarianceFields = {
  __typename?: 'measurement_variance_fields';
  config_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  pose_id?: Maybe<Scalars['Float']['output']>;
  sensor_id?: Maybe<Scalars['Float']['output']>;
  sensor_model_id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "measurement" */
export type MeasurementVarianceOrderBy = {
  config_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  pose_id?: InputMaybe<OrderBy>;
  sensor_id?: InputMaybe<OrderBy>;
  sensor_model_id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "measurement_view" */
export type MeasurementView = {
  __typename?: 'measurement_view';
  data?: Maybe<Scalars['String']['output']>;
  measurement_type?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "measurement_view" */
export type MeasurementViewAggregate = {
  __typename?: 'measurement_view_aggregate';
  aggregate?: Maybe<MeasurementViewAggregateFields>;
  nodes: Array<MeasurementView>;
};

/** aggregate fields of "measurement_view" */
export type MeasurementViewAggregateFields = {
  __typename?: 'measurement_view_aggregate_fields';
  avg?: Maybe<MeasurementViewAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<MeasurementViewMaxFields>;
  min?: Maybe<MeasurementViewMinFields>;
  stddev?: Maybe<MeasurementViewStddevFields>;
  stddev_pop?: Maybe<MeasurementViewStddevPopFields>;
  stddev_samp?: Maybe<MeasurementViewStddevSampFields>;
  sum?: Maybe<MeasurementViewSumFields>;
  var_pop?: Maybe<MeasurementViewVarPopFields>;
  var_samp?: Maybe<MeasurementViewVarSampFields>;
  variance?: Maybe<MeasurementViewVarianceFields>;
};


/** aggregate fields of "measurement_view" */
export type MeasurementViewAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MeasurementViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type MeasurementViewAvgFields = {
  __typename?: 'measurement_view_avg_fields';
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "measurement_view". All fields are combined with a logical 'AND'. */
export type MeasurementViewBoolExp = {
  _and?: InputMaybe<Array<MeasurementViewBoolExp>>;
  _not?: InputMaybe<MeasurementViewBoolExp>;
  _or?: InputMaybe<Array<MeasurementViewBoolExp>>;
  data?: InputMaybe<StringComparisonExp>;
  measurement_type?: InputMaybe<StringComparisonExp>;
  time?: InputMaybe<TimestamptzComparisonExp>;
  zone_id?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type MeasurementViewMaxFields = {
  __typename?: 'measurement_view_max_fields';
  data?: Maybe<Scalars['String']['output']>;
  measurement_type?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type MeasurementViewMinFields = {
  __typename?: 'measurement_view_min_fields';
  data?: Maybe<Scalars['String']['output']>;
  measurement_type?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "measurement_view". */
export type MeasurementViewOrderBy = {
  data?: InputMaybe<OrderBy>;
  measurement_type?: InputMaybe<OrderBy>;
  time?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** select columns of table "measurement_view" */
export enum MeasurementViewSelectColumn {
  /** column name */
  data = 'data',
  /** column name */
  measurement_type = 'measurement_type',
  /** column name */
  time = 'time',
  /** column name */
  zone_id = 'zone_id'
}

/** aggregate stddev on columns */
export type MeasurementViewStddevFields = {
  __typename?: 'measurement_view_stddev_fields';
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type MeasurementViewStddevPopFields = {
  __typename?: 'measurement_view_stddev_pop_fields';
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type MeasurementViewStddevSampFields = {
  __typename?: 'measurement_view_stddev_samp_fields';
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "measurement_view" */
export type MeasurementViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MeasurementViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MeasurementViewStreamCursorValueInput = {
  data?: InputMaybe<Scalars['String']['input']>;
  measurement_type?: InputMaybe<Scalars['String']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type MeasurementViewSumFields = {
  __typename?: 'measurement_view_sum_fields';
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type MeasurementViewVarPopFields = {
  __typename?: 'measurement_view_var_pop_fields';
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type MeasurementViewVarSampFields = {
  __typename?: 'measurement_view_var_samp_fields';
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type MeasurementViewVarianceFields = {
  __typename?: 'measurement_view_variance_fields';
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "measurements_by_grid_index" */
export type MeasurementsByGridIndex = {
  __typename?: 'measurements_by_grid_index';
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  sensor_type_id?: Maybe<Scalars['Int']['output']>;
  x_index?: Maybe<Scalars['Int']['output']>;
  y_index?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "measurements_by_grid_index" */
export type MeasurementsByGridIndexAggregate = {
  __typename?: 'measurements_by_grid_index_aggregate';
  aggregate?: Maybe<MeasurementsByGridIndexAggregateFields>;
  nodes: Array<MeasurementsByGridIndex>;
};

/** aggregate fields of "measurements_by_grid_index" */
export type MeasurementsByGridIndexAggregateFields = {
  __typename?: 'measurements_by_grid_index_aggregate_fields';
  avg?: Maybe<MeasurementsByGridIndexAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<MeasurementsByGridIndexMaxFields>;
  min?: Maybe<MeasurementsByGridIndexMinFields>;
  stddev?: Maybe<MeasurementsByGridIndexStddevFields>;
  stddev_pop?: Maybe<MeasurementsByGridIndexStddevPopFields>;
  stddev_samp?: Maybe<MeasurementsByGridIndexStddevSampFields>;
  sum?: Maybe<MeasurementsByGridIndexSumFields>;
  var_pop?: Maybe<MeasurementsByGridIndexVarPopFields>;
  var_samp?: Maybe<MeasurementsByGridIndexVarSampFields>;
  variance?: Maybe<MeasurementsByGridIndexVarianceFields>;
};


/** aggregate fields of "measurements_by_grid_index" */
export type MeasurementsByGridIndexAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MeasurementsByGridIndexSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type MeasurementsByGridIndexAvgFields = {
  __typename?: 'measurements_by_grid_index_avg_fields';
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
  x_index?: Maybe<Scalars['Float']['output']>;
  y_index?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "measurements_by_grid_index". All fields are combined with a logical 'AND'. */
export type MeasurementsByGridIndexBoolExp = {
  _and?: InputMaybe<Array<MeasurementsByGridIndexBoolExp>>;
  _not?: InputMaybe<MeasurementsByGridIndexBoolExp>;
  _or?: InputMaybe<Array<MeasurementsByGridIndexBoolExp>>;
  measurement_id?: InputMaybe<BigintComparisonExp>;
  measurement_run_id?: InputMaybe<IntComparisonExp>;
  sensor_type_id?: InputMaybe<IntComparisonExp>;
  x_index?: InputMaybe<IntComparisonExp>;
  y_index?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type MeasurementsByGridIndexMaxFields = {
  __typename?: 'measurements_by_grid_index_max_fields';
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  sensor_type_id?: Maybe<Scalars['Int']['output']>;
  x_index?: Maybe<Scalars['Int']['output']>;
  y_index?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type MeasurementsByGridIndexMinFields = {
  __typename?: 'measurements_by_grid_index_min_fields';
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  sensor_type_id?: Maybe<Scalars['Int']['output']>;
  x_index?: Maybe<Scalars['Int']['output']>;
  y_index?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "measurements_by_grid_index". */
export type MeasurementsByGridIndexOrderBy = {
  measurement_id?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  sensor_type_id?: InputMaybe<OrderBy>;
  x_index?: InputMaybe<OrderBy>;
  y_index?: InputMaybe<OrderBy>;
};

/** select columns of table "measurements_by_grid_index" */
export enum MeasurementsByGridIndexSelectColumn {
  /** column name */
  measurement_id = 'measurement_id',
  /** column name */
  measurement_run_id = 'measurement_run_id',
  /** column name */
  sensor_type_id = 'sensor_type_id',
  /** column name */
  x_index = 'x_index',
  /** column name */
  y_index = 'y_index'
}

/** aggregate stddev on columns */
export type MeasurementsByGridIndexStddevFields = {
  __typename?: 'measurements_by_grid_index_stddev_fields';
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
  x_index?: Maybe<Scalars['Float']['output']>;
  y_index?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type MeasurementsByGridIndexStddevPopFields = {
  __typename?: 'measurements_by_grid_index_stddev_pop_fields';
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
  x_index?: Maybe<Scalars['Float']['output']>;
  y_index?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type MeasurementsByGridIndexStddevSampFields = {
  __typename?: 'measurements_by_grid_index_stddev_samp_fields';
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
  x_index?: Maybe<Scalars['Float']['output']>;
  y_index?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "measurements_by_grid_index" */
export type MeasurementsByGridIndexStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MeasurementsByGridIndexStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MeasurementsByGridIndexStreamCursorValueInput = {
  measurement_id?: InputMaybe<Scalars['bigint']['input']>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
  sensor_type_id?: InputMaybe<Scalars['Int']['input']>;
  x_index?: InputMaybe<Scalars['Int']['input']>;
  y_index?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type MeasurementsByGridIndexSumFields = {
  __typename?: 'measurements_by_grid_index_sum_fields';
  measurement_id?: Maybe<Scalars['bigint']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  sensor_type_id?: Maybe<Scalars['Int']['output']>;
  x_index?: Maybe<Scalars['Int']['output']>;
  y_index?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type MeasurementsByGridIndexVarPopFields = {
  __typename?: 'measurements_by_grid_index_var_pop_fields';
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
  x_index?: Maybe<Scalars['Float']['output']>;
  y_index?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type MeasurementsByGridIndexVarSampFields = {
  __typename?: 'measurements_by_grid_index_var_samp_fields';
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
  x_index?: Maybe<Scalars['Float']['output']>;
  y_index?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type MeasurementsByGridIndexVarianceFields = {
  __typename?: 'measurements_by_grid_index_variance_fields';
  measurement_id?: Maybe<Scalars['Float']['output']>;
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
  x_index?: Maybe<Scalars['Float']['output']>;
  y_index?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "measurements_on_grid" */
export type MeasurementsOnGrid = {
  __typename?: 'measurements_on_grid';
  grid_measurements?: Maybe<Scalars['_int8']['output']>;
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  sensor_type_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "measurements_on_grid" */
export type MeasurementsOnGridAggregate = {
  __typename?: 'measurements_on_grid_aggregate';
  aggregate?: Maybe<MeasurementsOnGridAggregateFields>;
  nodes: Array<MeasurementsOnGrid>;
};

/** aggregate fields of "measurements_on_grid" */
export type MeasurementsOnGridAggregateFields = {
  __typename?: 'measurements_on_grid_aggregate_fields';
  avg?: Maybe<MeasurementsOnGridAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<MeasurementsOnGridMaxFields>;
  min?: Maybe<MeasurementsOnGridMinFields>;
  stddev?: Maybe<MeasurementsOnGridStddevFields>;
  stddev_pop?: Maybe<MeasurementsOnGridStddevPopFields>;
  stddev_samp?: Maybe<MeasurementsOnGridStddevSampFields>;
  sum?: Maybe<MeasurementsOnGridSumFields>;
  var_pop?: Maybe<MeasurementsOnGridVarPopFields>;
  var_samp?: Maybe<MeasurementsOnGridVarSampFields>;
  variance?: Maybe<MeasurementsOnGridVarianceFields>;
};


/** aggregate fields of "measurements_on_grid" */
export type MeasurementsOnGridAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<MeasurementsOnGridSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type MeasurementsOnGridAvgFields = {
  __typename?: 'measurements_on_grid_avg_fields';
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "measurements_on_grid". All fields are combined with a logical 'AND'. */
export type MeasurementsOnGridBoolExp = {
  _and?: InputMaybe<Array<MeasurementsOnGridBoolExp>>;
  _not?: InputMaybe<MeasurementsOnGridBoolExp>;
  _or?: InputMaybe<Array<MeasurementsOnGridBoolExp>>;
  grid_measurements?: InputMaybe<Int8ComparisonExp>;
  measurement_run_id?: InputMaybe<IntComparisonExp>;
  sensor_type_id?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type MeasurementsOnGridMaxFields = {
  __typename?: 'measurements_on_grid_max_fields';
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  sensor_type_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type MeasurementsOnGridMinFields = {
  __typename?: 'measurements_on_grid_min_fields';
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  sensor_type_id?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "measurements_on_grid". */
export type MeasurementsOnGridOrderBy = {
  grid_measurements?: InputMaybe<OrderBy>;
  measurement_run_id?: InputMaybe<OrderBy>;
  sensor_type_id?: InputMaybe<OrderBy>;
};

/** select columns of table "measurements_on_grid" */
export enum MeasurementsOnGridSelectColumn {
  /** column name */
  grid_measurements = 'grid_measurements',
  /** column name */
  measurement_run_id = 'measurement_run_id',
  /** column name */
  sensor_type_id = 'sensor_type_id'
}

/** aggregate stddev on columns */
export type MeasurementsOnGridStddevFields = {
  __typename?: 'measurements_on_grid_stddev_fields';
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type MeasurementsOnGridStddevPopFields = {
  __typename?: 'measurements_on_grid_stddev_pop_fields';
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type MeasurementsOnGridStddevSampFields = {
  __typename?: 'measurements_on_grid_stddev_samp_fields';
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "measurements_on_grid" */
export type MeasurementsOnGridStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: MeasurementsOnGridStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type MeasurementsOnGridStreamCursorValueInput = {
  grid_measurements?: InputMaybe<Scalars['_int8']['input']>;
  measurement_run_id?: InputMaybe<Scalars['Int']['input']>;
  sensor_type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type MeasurementsOnGridSumFields = {
  __typename?: 'measurements_on_grid_sum_fields';
  measurement_run_id?: Maybe<Scalars['Int']['output']>;
  sensor_type_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type MeasurementsOnGridVarPopFields = {
  __typename?: 'measurements_on_grid_var_pop_fields';
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type MeasurementsOnGridVarSampFields = {
  __typename?: 'measurements_on_grid_var_samp_fields';
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type MeasurementsOnGridVarianceFields = {
  __typename?: 'measurements_on_grid_variance_fields';
  measurement_run_id?: Maybe<Scalars['Float']['output']>;
  sensor_type_id?: Maybe<Scalars['Float']['output']>;
};

/** mutation root */
export type MutationRoot = {
  __typename?: 'mutation_root';
  /** delete data from the table: "address" */
  delete_address?: Maybe<AddressMutationResponse>;
  /** delete single row from the table: "address" */
  delete_address_by_pk?: Maybe<Address>;
  /** delete data from the table: "aggregate_heatmap" */
  delete_aggregate_heatmap?: Maybe<AggregateHeatmapMutationResponse>;
  /** delete single row from the table: "aggregate_heatmap" */
  delete_aggregate_heatmap_by_pk?: Maybe<AggregateHeatmap>;
  /** delete data from the table: "alembic_version" */
  delete_alembic_version?: Maybe<AlembicVersionMutationResponse>;
  /** delete single row from the table: "alembic_version" */
  delete_alembic_version_by_pk?: Maybe<AlembicVersion>;
  /** delete data from the table: "classification" */
  delete_classification?: Maybe<ClassificationMutationResponse>;
  /** delete single row from the table: "classification" */
  delete_classification_by_pk?: Maybe<Classification>;
  /** delete data from the table: "comment" */
  delete_comment?: Maybe<CommentMutationResponse>;
  /** delete single row from the table: "comment" */
  delete_comment_by_pk?: Maybe<Comment>;
  /** delete data from the table: "compute" */
  delete_compute?: Maybe<ComputeMutationResponse>;
  /** delete single row from the table: "compute" */
  delete_compute_by_pk?: Maybe<Compute>;
  /** delete data from the table: "computed_measurement" */
  delete_computed_measurement?: Maybe<ComputedMeasurementMutationResponse>;
  /** delete single row from the table: "computed_measurement" */
  delete_computed_measurement_by_pk?: Maybe<ComputedMeasurement>;
  /** delete data from the table: "config" */
  delete_config?: Maybe<ConfigMutationResponse>;
  /** delete single row from the table: "config" */
  delete_config_by_pk?: Maybe<Config>;
  /** delete data from the table: "detection" */
  delete_detection?: Maybe<DetectionMutationResponse>;
  /** delete single row from the table: "detection" */
  delete_detection_by_pk?: Maybe<Detection>;
  /** delete data from the table: "detection_run" */
  delete_detection_run?: Maybe<DetectionRunMutationResponse>;
  /** delete single row from the table: "detection_run" */
  delete_detection_run_by_pk?: Maybe<DetectionRun>;
  /** delete data from the table: "detector" */
  delete_detector?: Maybe<DetectorMutationResponse>;
  /** delete single row from the table: "detector" */
  delete_detector_by_pk?: Maybe<Detector>;
  /** delete data from the table: "device_datum" */
  delete_device_datum?: Maybe<DeviceDatumMutationResponse>;
  /** delete single row from the table: "device_datum" */
  delete_device_datum_by_pk?: Maybe<DeviceDatum>;
  /** delete data from the table: "device_metadata" */
  delete_device_metadata?: Maybe<DeviceMetadataMutationResponse>;
  /** delete single row from the table: "device_metadata" */
  delete_device_metadata_by_pk?: Maybe<DeviceMetadata>;
  /** delete data from the table: "discussion" */
  delete_discussion?: Maybe<DiscussionMutationResponse>;
  /** delete single row from the table: "discussion" */
  delete_discussion_by_pk?: Maybe<Discussion>;
  /** delete data from the table: "enumeration" */
  delete_enumeration?: Maybe<EnumerationMutationResponse>;
  /** delete single row from the table: "enumeration" */
  delete_enumeration_by_pk?: Maybe<Enumeration>;
  /** delete data from the table: "environment_recipe" */
  delete_environment_recipe?: Maybe<EnvironmentRecipeMutationResponse>;
  /** delete single row from the table: "environment_recipe" */
  delete_environment_recipe_by_pk?: Maybe<EnvironmentRecipe>;
  /** delete data from the table: "environment_recipe_entry" */
  delete_environment_recipe_entry?: Maybe<EnvironmentRecipeEntryMutationResponse>;
  /** delete single row from the table: "environment_recipe_entry" */
  delete_environment_recipe_entry_by_pk?: Maybe<EnvironmentRecipeEntry>;
  /** delete data from the table: "field_intervention" */
  delete_field_intervention?: Maybe<FieldInterventionMutationResponse>;
  /** delete single row from the table: "field_intervention" */
  delete_field_intervention_by_pk?: Maybe<FieldIntervention>;
  /** delete data from the table: "geometry_columns" */
  delete_geometry_columns?: Maybe<GeometryColumnsMutationResponse>;
  /** delete data from the table: "get_measurements_on_grid_format_table" */
  delete_get_measurements_on_grid_format_table?: Maybe<GetMeasurementsOnGridFormatTableMutationResponse>;
  /** delete data from the table: "get_most_recent_vpd_measurements_format_table" */
  delete_get_most_recent_vpd_measurements_format_table?: Maybe<GetMostRecentVpdMeasurementsFormatTableMutationResponse>;
  /** delete data from the table: "grid" */
  delete_grid?: Maybe<GridMutationResponse>;
  /** delete single row from the table: "grid" */
  delete_grid_by_pk?: Maybe<Grid>;
  /** delete data from the table: "grid_element" */
  delete_grid_element?: Maybe<GridElementMutationResponse>;
  /** delete single row from the table: "grid_element" */
  delete_grid_element_by_pk?: Maybe<GridElement>;
  /** delete data from the table: "growth_cycle" */
  delete_growth_cycle?: Maybe<GrowthCycleMutationResponse>;
  /** delete single row from the table: "growth_cycle" */
  delete_growth_cycle_by_pk?: Maybe<GrowthCycle>;
  /** delete data from the table: "heat_map" */
  delete_heat_map?: Maybe<HeatMapMutationResponse>;
  /** delete single row from the table: "heat_map" */
  delete_heat_map_by_pk?: Maybe<HeatMap>;
  /** delete data from the table: "image_annotation_tracking" */
  delete_image_annotation_tracking?: Maybe<ImageAnnotationTrackingMutationResponse>;
  /** delete single row from the table: "image_annotation_tracking" */
  delete_image_annotation_tracking_by_pk?: Maybe<ImageAnnotationTracking>;
  /** delete data from the table: "label" */
  delete_label?: Maybe<LabelMutationResponse>;
  /** delete single row from the table: "label" */
  delete_label_by_pk?: Maybe<Label>;
  /** delete data from the table: "label_category" */
  delete_label_category?: Maybe<LabelCategoryMutationResponse>;
  /** delete single row from the table: "label_category" */
  delete_label_category_by_pk?: Maybe<LabelCategory>;
  /** delete data from the table: "label_task" */
  delete_label_task?: Maybe<LabelTaskMutationResponse>;
  /** delete single row from the table: "label_task" */
  delete_label_task_by_pk?: Maybe<LabelTask>;
  /** delete data from the table: "lambda_run" */
  delete_lambda_run?: Maybe<LambdaRunMutationResponse>;
  /** delete single row from the table: "lambda_run" */
  delete_lambda_run_by_pk?: Maybe<LambdaRun>;
  /** delete data from the table: "lambda_run_measurement_run" */
  delete_lambda_run_measurement_run?: Maybe<LambdaRunMeasurementRunMutationResponse>;
  /** delete single row from the table: "lambda_run_measurement_run" */
  delete_lambda_run_measurement_run_by_pk?: Maybe<LambdaRunMeasurementRun>;
  /** delete data from the table: "lambda_version" */
  delete_lambda_version?: Maybe<LambdaVersionMutationResponse>;
  /** delete single row from the table: "lambda_version" */
  delete_lambda_version_by_pk?: Maybe<LambdaVersion>;
  /** delete data from the table: "light_cycle_recipe" */
  delete_light_cycle_recipe?: Maybe<LightCycleRecipeMutationResponse>;
  /** delete single row from the table: "light_cycle_recipe" */
  delete_light_cycle_recipe_by_pk?: Maybe<LightCycleRecipe>;
  /** delete data from the table: "light_cycle_recipe_entry" */
  delete_light_cycle_recipe_entry?: Maybe<LightCycleRecipeEntryMutationResponse>;
  /** delete single row from the table: "light_cycle_recipe_entry" */
  delete_light_cycle_recipe_entry_by_pk?: Maybe<LightCycleRecipeEntry>;
  /** delete data from the table: "location" */
  delete_location?: Maybe<LocationMutationResponse>;
  /** delete single row from the table: "location" */
  delete_location_by_pk?: Maybe<Location>;
  /** delete data from the table: "measurement" */
  delete_measurement?: Maybe<MeasurementMutationResponse>;
  /** delete single row from the table: "measurement" */
  delete_measurement_by_pk?: Maybe<Measurement>;
  /** delete data from the table: "measurement_run" */
  delete_measurement_run?: Maybe<MeasurementRunMutationResponse>;
  /** delete single row from the table: "measurement_run" */
  delete_measurement_run_by_pk?: Maybe<MeasurementRun>;
  /** delete data from the table: "notification" */
  delete_notification?: Maybe<NotificationMutationResponse>;
  /** delete single row from the table: "notification" */
  delete_notification_by_pk?: Maybe<Notification>;
  /** delete data from the table: "organization" */
  delete_organization?: Maybe<OrganizationMutationResponse>;
  /** delete single row from the table: "organization" */
  delete_organization_by_pk?: Maybe<Organization>;
  /** delete data from the table: "parameters" */
  delete_parameters?: Maybe<ParametersMutationResponse>;
  /** delete single row from the table: "parameters" */
  delete_parameters_by_pk?: Maybe<Parameters>;
  /** delete data from the table: "pose" */
  delete_pose?: Maybe<PoseMutationResponse>;
  /** delete single row from the table: "pose" */
  delete_pose_by_pk?: Maybe<Pose>;
  /** delete data from the table: "proto" */
  delete_proto?: Maybe<ProtoMutationResponse>;
  /** delete single row from the table: "proto" */
  delete_proto_by_pk?: Maybe<Proto>;
  /** delete data from the table: "role_permission" */
  delete_role_permission?: Maybe<RolePermissionMutationResponse>;
  /** delete data from the table: "sensor" */
  delete_sensor?: Maybe<SensorMutationResponse>;
  /** delete single row from the table: "sensor" */
  delete_sensor_by_pk?: Maybe<Sensor>;
  /** delete data from the table: "spatial_ref_sys" */
  delete_spatial_ref_sys?: Maybe<SpatialRefSysMutationResponse>;
  /** delete single row from the table: "spatial_ref_sys" */
  delete_spatial_ref_sys_by_pk?: Maybe<SpatialRefSys>;
  /** delete data from the table: "system" */
  delete_system?: Maybe<SystemMutationResponse>;
  /** delete single row from the table: "system" */
  delete_system_by_pk?: Maybe<System>;
  /** delete data from the table: "track" */
  delete_track?: Maybe<TrackMutationResponse>;
  /** delete single row from the table: "track" */
  delete_track_by_pk?: Maybe<Track>;
  /** delete data from the table: "track_detections" */
  delete_track_detections?: Maybe<TrackDetectionsMutationResponse>;
  /** delete single row from the table: "track_detections" */
  delete_track_detections_by_pk?: Maybe<TrackDetections>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<UserMutationResponse>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "user_comment" */
  delete_user_comment?: Maybe<UserCommentMutationResponse>;
  /** delete single row from the table: "user_comment" */
  delete_user_comment_by_pk?: Maybe<UserComment>;
  /** delete data from the table: "user_label" */
  delete_user_label?: Maybe<UserLabelMutationResponse>;
  /** delete single row from the table: "user_label" */
  delete_user_label_by_pk?: Maybe<UserLabel>;
  /** delete data from the table: "user_notification" */
  delete_user_notification?: Maybe<UserNotificationMutationResponse>;
  /** delete single row from the table: "user_notification" */
  delete_user_notification_by_pk?: Maybe<UserNotification>;
  /** delete data from the table: "user_notification_setting" */
  delete_user_notification_setting?: Maybe<UserNotificationSettingMutationResponse>;
  /** delete single row from the table: "user_notification_setting" */
  delete_user_notification_setting_by_pk?: Maybe<UserNotificationSetting>;
  /** delete data from the table: "user_organization_role" */
  delete_user_organization_role?: Maybe<UserOrganizationRoleMutationResponse>;
  /** delete single row from the table: "user_organization_role" */
  delete_user_organization_role_by_pk?: Maybe<UserOrganizationRole>;
  /** delete data from the table: "version" */
  delete_version?: Maybe<VersionMutationResponse>;
  /** delete single row from the table: "version" */
  delete_version_by_pk?: Maybe<Version>;
  /** delete data from the table: "zone" */
  delete_zone?: Maybe<ZoneMutationResponse>;
  /** delete single row from the table: "zone" */
  delete_zone_by_pk?: Maybe<Zone>;
  /** execute VOLATILE function "get_most_recent_vpd_measurements" which returns "get_most_recent_vpd_measurements_format_table" */
  get_most_recent_vpd_measurements: Array<GetMostRecentVpdMeasurementsFormatTable>;
  /** insert data into the table: "address" */
  insert_address?: Maybe<AddressMutationResponse>;
  /** insert a single row into the table: "address" */
  insert_address_one?: Maybe<Address>;
  /** insert data into the table: "aggregate_heatmap" */
  insert_aggregate_heatmap?: Maybe<AggregateHeatmapMutationResponse>;
  /** insert a single row into the table: "aggregate_heatmap" */
  insert_aggregate_heatmap_one?: Maybe<AggregateHeatmap>;
  /** insert data into the table: "alembic_version" */
  insert_alembic_version?: Maybe<AlembicVersionMutationResponse>;
  /** insert a single row into the table: "alembic_version" */
  insert_alembic_version_one?: Maybe<AlembicVersion>;
  /** insert data into the table: "classification" */
  insert_classification?: Maybe<ClassificationMutationResponse>;
  /** insert a single row into the table: "classification" */
  insert_classification_one?: Maybe<Classification>;
  /** insert data into the table: "comment" */
  insert_comment?: Maybe<CommentMutationResponse>;
  /** insert a single row into the table: "comment" */
  insert_comment_one?: Maybe<Comment>;
  /** insert data into the table: "compute" */
  insert_compute?: Maybe<ComputeMutationResponse>;
  /** insert a single row into the table: "compute" */
  insert_compute_one?: Maybe<Compute>;
  /** insert data into the table: "computed_measurement" */
  insert_computed_measurement?: Maybe<ComputedMeasurementMutationResponse>;
  /** insert a single row into the table: "computed_measurement" */
  insert_computed_measurement_one?: Maybe<ComputedMeasurement>;
  /** insert data into the table: "config" */
  insert_config?: Maybe<ConfigMutationResponse>;
  /** insert a single row into the table: "config" */
  insert_config_one?: Maybe<Config>;
  /** insert data into the table: "detection" */
  insert_detection?: Maybe<DetectionMutationResponse>;
  /** insert a single row into the table: "detection" */
  insert_detection_one?: Maybe<Detection>;
  /** insert data into the table: "detection_run" */
  insert_detection_run?: Maybe<DetectionRunMutationResponse>;
  /** insert a single row into the table: "detection_run" */
  insert_detection_run_one?: Maybe<DetectionRun>;
  /** insert data into the table: "detector" */
  insert_detector?: Maybe<DetectorMutationResponse>;
  /** insert a single row into the table: "detector" */
  insert_detector_one?: Maybe<Detector>;
  /** insert data into the table: "device_datum" */
  insert_device_datum?: Maybe<DeviceDatumMutationResponse>;
  /** insert a single row into the table: "device_datum" */
  insert_device_datum_one?: Maybe<DeviceDatum>;
  /** insert data into the table: "device_metadata" */
  insert_device_metadata?: Maybe<DeviceMetadataMutationResponse>;
  /** insert a single row into the table: "device_metadata" */
  insert_device_metadata_one?: Maybe<DeviceMetadata>;
  /** insert data into the table: "discussion" */
  insert_discussion?: Maybe<DiscussionMutationResponse>;
  /** insert a single row into the table: "discussion" */
  insert_discussion_one?: Maybe<Discussion>;
  /** insert data into the table: "enumeration" */
  insert_enumeration?: Maybe<EnumerationMutationResponse>;
  /** insert a single row into the table: "enumeration" */
  insert_enumeration_one?: Maybe<Enumeration>;
  /** insert data into the table: "environment_recipe" */
  insert_environment_recipe?: Maybe<EnvironmentRecipeMutationResponse>;
  /** insert data into the table: "environment_recipe_entry" */
  insert_environment_recipe_entry?: Maybe<EnvironmentRecipeEntryMutationResponse>;
  /** insert a single row into the table: "environment_recipe_entry" */
  insert_environment_recipe_entry_one?: Maybe<EnvironmentRecipeEntry>;
  /** insert a single row into the table: "environment_recipe" */
  insert_environment_recipe_one?: Maybe<EnvironmentRecipe>;
  /** insert data into the table: "field_intervention" */
  insert_field_intervention?: Maybe<FieldInterventionMutationResponse>;
  /** insert a single row into the table: "field_intervention" */
  insert_field_intervention_one?: Maybe<FieldIntervention>;
  /** insert data into the table: "geometry_columns" */
  insert_geometry_columns?: Maybe<GeometryColumnsMutationResponse>;
  /** insert a single row into the table: "geometry_columns" */
  insert_geometry_columns_one?: Maybe<GeometryColumns>;
  /** insert data into the table: "get_measurements_on_grid_format_table" */
  insert_get_measurements_on_grid_format_table?: Maybe<GetMeasurementsOnGridFormatTableMutationResponse>;
  /** insert a single row into the table: "get_measurements_on_grid_format_table" */
  insert_get_measurements_on_grid_format_table_one?: Maybe<GetMeasurementsOnGridFormatTable>;
  /** insert data into the table: "get_most_recent_vpd_measurements_format_table" */
  insert_get_most_recent_vpd_measurements_format_table?: Maybe<GetMostRecentVpdMeasurementsFormatTableMutationResponse>;
  /** insert a single row into the table: "get_most_recent_vpd_measurements_format_table" */
  insert_get_most_recent_vpd_measurements_format_table_one?: Maybe<GetMostRecentVpdMeasurementsFormatTable>;
  /** insert data into the table: "grid" */
  insert_grid?: Maybe<GridMutationResponse>;
  /** insert data into the table: "grid_element" */
  insert_grid_element?: Maybe<GridElementMutationResponse>;
  /** insert a single row into the table: "grid_element" */
  insert_grid_element_one?: Maybe<GridElement>;
  /** insert a single row into the table: "grid" */
  insert_grid_one?: Maybe<Grid>;
  /** insert data into the table: "growth_cycle" */
  insert_growth_cycle?: Maybe<GrowthCycleMutationResponse>;
  /** insert a single row into the table: "growth_cycle" */
  insert_growth_cycle_one?: Maybe<GrowthCycle>;
  /** insert data into the table: "heat_map" */
  insert_heat_map?: Maybe<HeatMapMutationResponse>;
  /** insert a single row into the table: "heat_map" */
  insert_heat_map_one?: Maybe<HeatMap>;
  /** insert data into the table: "image_annotation_tracking" */
  insert_image_annotation_tracking?: Maybe<ImageAnnotationTrackingMutationResponse>;
  /** insert a single row into the table: "image_annotation_tracking" */
  insert_image_annotation_tracking_one?: Maybe<ImageAnnotationTracking>;
  /** insert data into the table: "label" */
  insert_label?: Maybe<LabelMutationResponse>;
  /** insert data into the table: "label_category" */
  insert_label_category?: Maybe<LabelCategoryMutationResponse>;
  /** insert a single row into the table: "label_category" */
  insert_label_category_one?: Maybe<LabelCategory>;
  /** insert a single row into the table: "label" */
  insert_label_one?: Maybe<Label>;
  /** insert data into the table: "label_task" */
  insert_label_task?: Maybe<LabelTaskMutationResponse>;
  /** insert a single row into the table: "label_task" */
  insert_label_task_one?: Maybe<LabelTask>;
  /** insert data into the table: "lambda_run" */
  insert_lambda_run?: Maybe<LambdaRunMutationResponse>;
  /** insert data into the table: "lambda_run_measurement_run" */
  insert_lambda_run_measurement_run?: Maybe<LambdaRunMeasurementRunMutationResponse>;
  /** insert a single row into the table: "lambda_run_measurement_run" */
  insert_lambda_run_measurement_run_one?: Maybe<LambdaRunMeasurementRun>;
  /** insert a single row into the table: "lambda_run" */
  insert_lambda_run_one?: Maybe<LambdaRun>;
  /** insert data into the table: "lambda_version" */
  insert_lambda_version?: Maybe<LambdaVersionMutationResponse>;
  /** insert a single row into the table: "lambda_version" */
  insert_lambda_version_one?: Maybe<LambdaVersion>;
  /** insert data into the table: "light_cycle_recipe" */
  insert_light_cycle_recipe?: Maybe<LightCycleRecipeMutationResponse>;
  /** insert data into the table: "light_cycle_recipe_entry" */
  insert_light_cycle_recipe_entry?: Maybe<LightCycleRecipeEntryMutationResponse>;
  /** insert a single row into the table: "light_cycle_recipe_entry" */
  insert_light_cycle_recipe_entry_one?: Maybe<LightCycleRecipeEntry>;
  /** insert a single row into the table: "light_cycle_recipe" */
  insert_light_cycle_recipe_one?: Maybe<LightCycleRecipe>;
  /** insert data into the table: "location" */
  insert_location?: Maybe<LocationMutationResponse>;
  /** insert a single row into the table: "location" */
  insert_location_one?: Maybe<Location>;
  /** insert data into the table: "measurement" */
  insert_measurement?: Maybe<MeasurementMutationResponse>;
  /** insert a single row into the table: "measurement" */
  insert_measurement_one?: Maybe<Measurement>;
  /** insert data into the table: "measurement_run" */
  insert_measurement_run?: Maybe<MeasurementRunMutationResponse>;
  /** insert a single row into the table: "measurement_run" */
  insert_measurement_run_one?: Maybe<MeasurementRun>;
  /** insert data into the table: "notification" */
  insert_notification?: Maybe<NotificationMutationResponse>;
  /** insert a single row into the table: "notification" */
  insert_notification_one?: Maybe<Notification>;
  /** insert data into the table: "organization" */
  insert_organization?: Maybe<OrganizationMutationResponse>;
  /** insert a single row into the table: "organization" */
  insert_organization_one?: Maybe<Organization>;
  /** insert data into the table: "parameters" */
  insert_parameters?: Maybe<ParametersMutationResponse>;
  /** insert a single row into the table: "parameters" */
  insert_parameters_one?: Maybe<Parameters>;
  /** insert data into the table: "pose" */
  insert_pose?: Maybe<PoseMutationResponse>;
  /** insert a single row into the table: "pose" */
  insert_pose_one?: Maybe<Pose>;
  /** insert data into the table: "proto" */
  insert_proto?: Maybe<ProtoMutationResponse>;
  /** insert a single row into the table: "proto" */
  insert_proto_one?: Maybe<Proto>;
  /** insert data into the table: "role_permission" */
  insert_role_permission?: Maybe<RolePermissionMutationResponse>;
  /** insert a single row into the table: "role_permission" */
  insert_role_permission_one?: Maybe<RolePermission>;
  /** insert data into the table: "sensor" */
  insert_sensor?: Maybe<SensorMutationResponse>;
  /** insert a single row into the table: "sensor" */
  insert_sensor_one?: Maybe<Sensor>;
  /** insert data into the table: "spatial_ref_sys" */
  insert_spatial_ref_sys?: Maybe<SpatialRefSysMutationResponse>;
  /** insert a single row into the table: "spatial_ref_sys" */
  insert_spatial_ref_sys_one?: Maybe<SpatialRefSys>;
  /** insert data into the table: "system" */
  insert_system?: Maybe<SystemMutationResponse>;
  /** insert a single row into the table: "system" */
  insert_system_one?: Maybe<System>;
  /** insert data into the table: "track" */
  insert_track?: Maybe<TrackMutationResponse>;
  /** insert data into the table: "track_detections" */
  insert_track_detections?: Maybe<TrackDetectionsMutationResponse>;
  /** insert a single row into the table: "track_detections" */
  insert_track_detections_one?: Maybe<TrackDetections>;
  /** insert a single row into the table: "track" */
  insert_track_one?: Maybe<Track>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<UserMutationResponse>;
  /** insert data into the table: "user_comment" */
  insert_user_comment?: Maybe<UserCommentMutationResponse>;
  /** insert a single row into the table: "user_comment" */
  insert_user_comment_one?: Maybe<UserComment>;
  /** insert data into the table: "user_label" */
  insert_user_label?: Maybe<UserLabelMutationResponse>;
  /** insert a single row into the table: "user_label" */
  insert_user_label_one?: Maybe<UserLabel>;
  /** insert data into the table: "user_notification" */
  insert_user_notification?: Maybe<UserNotificationMutationResponse>;
  /** insert a single row into the table: "user_notification" */
  insert_user_notification_one?: Maybe<UserNotification>;
  /** insert data into the table: "user_notification_setting" */
  insert_user_notification_setting?: Maybe<UserNotificationSettingMutationResponse>;
  /** insert a single row into the table: "user_notification_setting" */
  insert_user_notification_setting_one?: Maybe<UserNotificationSetting>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "user_organization_role" */
  insert_user_organization_role?: Maybe<UserOrganizationRoleMutationResponse>;
  /** insert a single row into the table: "user_organization_role" */
  insert_user_organization_role_one?: Maybe<UserOrganizationRole>;
  /** insert data into the table: "version" */
  insert_version?: Maybe<VersionMutationResponse>;
  /** insert a single row into the table: "version" */
  insert_version_one?: Maybe<Version>;
  /** insert data into the table: "zone" */
  insert_zone?: Maybe<ZoneMutationResponse>;
  /** insert a single row into the table: "zone" */
  insert_zone_one?: Maybe<Zone>;
  /** update data of the table: "address" */
  update_address?: Maybe<AddressMutationResponse>;
  /** update single row of the table: "address" */
  update_address_by_pk?: Maybe<Address>;
  /** update multiples rows of table: "address" */
  update_address_many?: Maybe<Array<Maybe<AddressMutationResponse>>>;
  /** update data of the table: "aggregate_heatmap" */
  update_aggregate_heatmap?: Maybe<AggregateHeatmapMutationResponse>;
  /** update single row of the table: "aggregate_heatmap" */
  update_aggregate_heatmap_by_pk?: Maybe<AggregateHeatmap>;
  /** update multiples rows of table: "aggregate_heatmap" */
  update_aggregate_heatmap_many?: Maybe<Array<Maybe<AggregateHeatmapMutationResponse>>>;
  /** update data of the table: "alembic_version" */
  update_alembic_version?: Maybe<AlembicVersionMutationResponse>;
  /** update single row of the table: "alembic_version" */
  update_alembic_version_by_pk?: Maybe<AlembicVersion>;
  /** update multiples rows of table: "alembic_version" */
  update_alembic_version_many?: Maybe<Array<Maybe<AlembicVersionMutationResponse>>>;
  /** update data of the table: "classification" */
  update_classification?: Maybe<ClassificationMutationResponse>;
  /** update single row of the table: "classification" */
  update_classification_by_pk?: Maybe<Classification>;
  /** update multiples rows of table: "classification" */
  update_classification_many?: Maybe<Array<Maybe<ClassificationMutationResponse>>>;
  /** update data of the table: "comment" */
  update_comment?: Maybe<CommentMutationResponse>;
  /** update single row of the table: "comment" */
  update_comment_by_pk?: Maybe<Comment>;
  /** update multiples rows of table: "comment" */
  update_comment_many?: Maybe<Array<Maybe<CommentMutationResponse>>>;
  /** update data of the table: "compute" */
  update_compute?: Maybe<ComputeMutationResponse>;
  /** update single row of the table: "compute" */
  update_compute_by_pk?: Maybe<Compute>;
  /** update multiples rows of table: "compute" */
  update_compute_many?: Maybe<Array<Maybe<ComputeMutationResponse>>>;
  /** update data of the table: "computed_measurement" */
  update_computed_measurement?: Maybe<ComputedMeasurementMutationResponse>;
  /** update single row of the table: "computed_measurement" */
  update_computed_measurement_by_pk?: Maybe<ComputedMeasurement>;
  /** update multiples rows of table: "computed_measurement" */
  update_computed_measurement_many?: Maybe<Array<Maybe<ComputedMeasurementMutationResponse>>>;
  /** update data of the table: "config" */
  update_config?: Maybe<ConfigMutationResponse>;
  /** update single row of the table: "config" */
  update_config_by_pk?: Maybe<Config>;
  /** update multiples rows of table: "config" */
  update_config_many?: Maybe<Array<Maybe<ConfigMutationResponse>>>;
  /** update data of the table: "detection" */
  update_detection?: Maybe<DetectionMutationResponse>;
  /** update single row of the table: "detection" */
  update_detection_by_pk?: Maybe<Detection>;
  /** update multiples rows of table: "detection" */
  update_detection_many?: Maybe<Array<Maybe<DetectionMutationResponse>>>;
  /** update data of the table: "detection_run" */
  update_detection_run?: Maybe<DetectionRunMutationResponse>;
  /** update single row of the table: "detection_run" */
  update_detection_run_by_pk?: Maybe<DetectionRun>;
  /** update multiples rows of table: "detection_run" */
  update_detection_run_many?: Maybe<Array<Maybe<DetectionRunMutationResponse>>>;
  /** update data of the table: "detector" */
  update_detector?: Maybe<DetectorMutationResponse>;
  /** update single row of the table: "detector" */
  update_detector_by_pk?: Maybe<Detector>;
  /** update multiples rows of table: "detector" */
  update_detector_many?: Maybe<Array<Maybe<DetectorMutationResponse>>>;
  /** update data of the table: "device_datum" */
  update_device_datum?: Maybe<DeviceDatumMutationResponse>;
  /** update single row of the table: "device_datum" */
  update_device_datum_by_pk?: Maybe<DeviceDatum>;
  /** update multiples rows of table: "device_datum" */
  update_device_datum_many?: Maybe<Array<Maybe<DeviceDatumMutationResponse>>>;
  /** update data of the table: "device_metadata" */
  update_device_metadata?: Maybe<DeviceMetadataMutationResponse>;
  /** update single row of the table: "device_metadata" */
  update_device_metadata_by_pk?: Maybe<DeviceMetadata>;
  /** update multiples rows of table: "device_metadata" */
  update_device_metadata_many?: Maybe<Array<Maybe<DeviceMetadataMutationResponse>>>;
  /** update data of the table: "discussion" */
  update_discussion?: Maybe<DiscussionMutationResponse>;
  /** update single row of the table: "discussion" */
  update_discussion_by_pk?: Maybe<Discussion>;
  /** update multiples rows of table: "discussion" */
  update_discussion_many?: Maybe<Array<Maybe<DiscussionMutationResponse>>>;
  /** update data of the table: "enumeration" */
  update_enumeration?: Maybe<EnumerationMutationResponse>;
  /** update single row of the table: "enumeration" */
  update_enumeration_by_pk?: Maybe<Enumeration>;
  /** update multiples rows of table: "enumeration" */
  update_enumeration_many?: Maybe<Array<Maybe<EnumerationMutationResponse>>>;
  /** update data of the table: "environment_recipe" */
  update_environment_recipe?: Maybe<EnvironmentRecipeMutationResponse>;
  /** update single row of the table: "environment_recipe" */
  update_environment_recipe_by_pk?: Maybe<EnvironmentRecipe>;
  /** update data of the table: "environment_recipe_entry" */
  update_environment_recipe_entry?: Maybe<EnvironmentRecipeEntryMutationResponse>;
  /** update single row of the table: "environment_recipe_entry" */
  update_environment_recipe_entry_by_pk?: Maybe<EnvironmentRecipeEntry>;
  /** update multiples rows of table: "environment_recipe_entry" */
  update_environment_recipe_entry_many?: Maybe<Array<Maybe<EnvironmentRecipeEntryMutationResponse>>>;
  /** update multiples rows of table: "environment_recipe" */
  update_environment_recipe_many?: Maybe<Array<Maybe<EnvironmentRecipeMutationResponse>>>;
  /** update data of the table: "field_intervention" */
  update_field_intervention?: Maybe<FieldInterventionMutationResponse>;
  /** update single row of the table: "field_intervention" */
  update_field_intervention_by_pk?: Maybe<FieldIntervention>;
  /** update multiples rows of table: "field_intervention" */
  update_field_intervention_many?: Maybe<Array<Maybe<FieldInterventionMutationResponse>>>;
  /** update data of the table: "geometry_columns" */
  update_geometry_columns?: Maybe<GeometryColumnsMutationResponse>;
  /** update multiples rows of table: "geometry_columns" */
  update_geometry_columns_many?: Maybe<Array<Maybe<GeometryColumnsMutationResponse>>>;
  /** update data of the table: "get_measurements_on_grid_format_table" */
  update_get_measurements_on_grid_format_table?: Maybe<GetMeasurementsOnGridFormatTableMutationResponse>;
  /** update multiples rows of table: "get_measurements_on_grid_format_table" */
  update_get_measurements_on_grid_format_table_many?: Maybe<Array<Maybe<GetMeasurementsOnGridFormatTableMutationResponse>>>;
  /** update data of the table: "get_most_recent_vpd_measurements_format_table" */
  update_get_most_recent_vpd_measurements_format_table?: Maybe<GetMostRecentVpdMeasurementsFormatTableMutationResponse>;
  /** update multiples rows of table: "get_most_recent_vpd_measurements_format_table" */
  update_get_most_recent_vpd_measurements_format_table_many?: Maybe<Array<Maybe<GetMostRecentVpdMeasurementsFormatTableMutationResponse>>>;
  /** update data of the table: "grid" */
  update_grid?: Maybe<GridMutationResponse>;
  /** update single row of the table: "grid" */
  update_grid_by_pk?: Maybe<Grid>;
  /** update data of the table: "grid_element" */
  update_grid_element?: Maybe<GridElementMutationResponse>;
  /** update single row of the table: "grid_element" */
  update_grid_element_by_pk?: Maybe<GridElement>;
  /** update multiples rows of table: "grid_element" */
  update_grid_element_many?: Maybe<Array<Maybe<GridElementMutationResponse>>>;
  /** update multiples rows of table: "grid" */
  update_grid_many?: Maybe<Array<Maybe<GridMutationResponse>>>;
  /** update data of the table: "growth_cycle" */
  update_growth_cycle?: Maybe<GrowthCycleMutationResponse>;
  /** update single row of the table: "growth_cycle" */
  update_growth_cycle_by_pk?: Maybe<GrowthCycle>;
  /** update multiples rows of table: "growth_cycle" */
  update_growth_cycle_many?: Maybe<Array<Maybe<GrowthCycleMutationResponse>>>;
  /** update data of the table: "heat_map" */
  update_heat_map?: Maybe<HeatMapMutationResponse>;
  /** update single row of the table: "heat_map" */
  update_heat_map_by_pk?: Maybe<HeatMap>;
  /** update multiples rows of table: "heat_map" */
  update_heat_map_many?: Maybe<Array<Maybe<HeatMapMutationResponse>>>;
  /** update data of the table: "image_annotation_tracking" */
  update_image_annotation_tracking?: Maybe<ImageAnnotationTrackingMutationResponse>;
  /** update single row of the table: "image_annotation_tracking" */
  update_image_annotation_tracking_by_pk?: Maybe<ImageAnnotationTracking>;
  /** update multiples rows of table: "image_annotation_tracking" */
  update_image_annotation_tracking_many?: Maybe<Array<Maybe<ImageAnnotationTrackingMutationResponse>>>;
  /** update data of the table: "label" */
  update_label?: Maybe<LabelMutationResponse>;
  /** update single row of the table: "label" */
  update_label_by_pk?: Maybe<Label>;
  /** update data of the table: "label_category" */
  update_label_category?: Maybe<LabelCategoryMutationResponse>;
  /** update single row of the table: "label_category" */
  update_label_category_by_pk?: Maybe<LabelCategory>;
  /** update multiples rows of table: "label_category" */
  update_label_category_many?: Maybe<Array<Maybe<LabelCategoryMutationResponse>>>;
  /** update multiples rows of table: "label" */
  update_label_many?: Maybe<Array<Maybe<LabelMutationResponse>>>;
  /** update data of the table: "label_task" */
  update_label_task?: Maybe<LabelTaskMutationResponse>;
  /** update single row of the table: "label_task" */
  update_label_task_by_pk?: Maybe<LabelTask>;
  /** update multiples rows of table: "label_task" */
  update_label_task_many?: Maybe<Array<Maybe<LabelTaskMutationResponse>>>;
  /** update data of the table: "lambda_run" */
  update_lambda_run?: Maybe<LambdaRunMutationResponse>;
  /** update single row of the table: "lambda_run" */
  update_lambda_run_by_pk?: Maybe<LambdaRun>;
  /** update multiples rows of table: "lambda_run" */
  update_lambda_run_many?: Maybe<Array<Maybe<LambdaRunMutationResponse>>>;
  /** update data of the table: "lambda_run_measurement_run" */
  update_lambda_run_measurement_run?: Maybe<LambdaRunMeasurementRunMutationResponse>;
  /** update single row of the table: "lambda_run_measurement_run" */
  update_lambda_run_measurement_run_by_pk?: Maybe<LambdaRunMeasurementRun>;
  /** update multiples rows of table: "lambda_run_measurement_run" */
  update_lambda_run_measurement_run_many?: Maybe<Array<Maybe<LambdaRunMeasurementRunMutationResponse>>>;
  /** update data of the table: "lambda_version" */
  update_lambda_version?: Maybe<LambdaVersionMutationResponse>;
  /** update single row of the table: "lambda_version" */
  update_lambda_version_by_pk?: Maybe<LambdaVersion>;
  /** update multiples rows of table: "lambda_version" */
  update_lambda_version_many?: Maybe<Array<Maybe<LambdaVersionMutationResponse>>>;
  /** update data of the table: "light_cycle_recipe" */
  update_light_cycle_recipe?: Maybe<LightCycleRecipeMutationResponse>;
  /** update single row of the table: "light_cycle_recipe" */
  update_light_cycle_recipe_by_pk?: Maybe<LightCycleRecipe>;
  /** update data of the table: "light_cycle_recipe_entry" */
  update_light_cycle_recipe_entry?: Maybe<LightCycleRecipeEntryMutationResponse>;
  /** update single row of the table: "light_cycle_recipe_entry" */
  update_light_cycle_recipe_entry_by_pk?: Maybe<LightCycleRecipeEntry>;
  /** update multiples rows of table: "light_cycle_recipe_entry" */
  update_light_cycle_recipe_entry_many?: Maybe<Array<Maybe<LightCycleRecipeEntryMutationResponse>>>;
  /** update multiples rows of table: "light_cycle_recipe" */
  update_light_cycle_recipe_many?: Maybe<Array<Maybe<LightCycleRecipeMutationResponse>>>;
  /** update data of the table: "location" */
  update_location?: Maybe<LocationMutationResponse>;
  /** update single row of the table: "location" */
  update_location_by_pk?: Maybe<Location>;
  /** update multiples rows of table: "location" */
  update_location_many?: Maybe<Array<Maybe<LocationMutationResponse>>>;
  /** update data of the table: "measurement" */
  update_measurement?: Maybe<MeasurementMutationResponse>;
  /** update single row of the table: "measurement" */
  update_measurement_by_pk?: Maybe<Measurement>;
  /** update multiples rows of table: "measurement" */
  update_measurement_many?: Maybe<Array<Maybe<MeasurementMutationResponse>>>;
  /** update data of the table: "measurement_run" */
  update_measurement_run?: Maybe<MeasurementRunMutationResponse>;
  /** update single row of the table: "measurement_run" */
  update_measurement_run_by_pk?: Maybe<MeasurementRun>;
  /** update multiples rows of table: "measurement_run" */
  update_measurement_run_many?: Maybe<Array<Maybe<MeasurementRunMutationResponse>>>;
  /** update data of the table: "notification" */
  update_notification?: Maybe<NotificationMutationResponse>;
  /** update single row of the table: "notification" */
  update_notification_by_pk?: Maybe<Notification>;
  /** update multiples rows of table: "notification" */
  update_notification_many?: Maybe<Array<Maybe<NotificationMutationResponse>>>;
  /** update data of the table: "organization" */
  update_organization?: Maybe<OrganizationMutationResponse>;
  /** update single row of the table: "organization" */
  update_organization_by_pk?: Maybe<Organization>;
  /** update multiples rows of table: "organization" */
  update_organization_many?: Maybe<Array<Maybe<OrganizationMutationResponse>>>;
  /** update data of the table: "parameters" */
  update_parameters?: Maybe<ParametersMutationResponse>;
  /** update single row of the table: "parameters" */
  update_parameters_by_pk?: Maybe<Parameters>;
  /** update multiples rows of table: "parameters" */
  update_parameters_many?: Maybe<Array<Maybe<ParametersMutationResponse>>>;
  /** update data of the table: "pose" */
  update_pose?: Maybe<PoseMutationResponse>;
  /** update single row of the table: "pose" */
  update_pose_by_pk?: Maybe<Pose>;
  /** update multiples rows of table: "pose" */
  update_pose_many?: Maybe<Array<Maybe<PoseMutationResponse>>>;
  /** update data of the table: "proto" */
  update_proto?: Maybe<ProtoMutationResponse>;
  /** update single row of the table: "proto" */
  update_proto_by_pk?: Maybe<Proto>;
  /** update multiples rows of table: "proto" */
  update_proto_many?: Maybe<Array<Maybe<ProtoMutationResponse>>>;
  /** update data of the table: "role_permission" */
  update_role_permission?: Maybe<RolePermissionMutationResponse>;
  /** update multiples rows of table: "role_permission" */
  update_role_permission_many?: Maybe<Array<Maybe<RolePermissionMutationResponse>>>;
  /** update data of the table: "sensor" */
  update_sensor?: Maybe<SensorMutationResponse>;
  /** update single row of the table: "sensor" */
  update_sensor_by_pk?: Maybe<Sensor>;
  /** update multiples rows of table: "sensor" */
  update_sensor_many?: Maybe<Array<Maybe<SensorMutationResponse>>>;
  /** update data of the table: "spatial_ref_sys" */
  update_spatial_ref_sys?: Maybe<SpatialRefSysMutationResponse>;
  /** update single row of the table: "spatial_ref_sys" */
  update_spatial_ref_sys_by_pk?: Maybe<SpatialRefSys>;
  /** update multiples rows of table: "spatial_ref_sys" */
  update_spatial_ref_sys_many?: Maybe<Array<Maybe<SpatialRefSysMutationResponse>>>;
  /** update data of the table: "system" */
  update_system?: Maybe<SystemMutationResponse>;
  /** update single row of the table: "system" */
  update_system_by_pk?: Maybe<System>;
  /** update multiples rows of table: "system" */
  update_system_many?: Maybe<Array<Maybe<SystemMutationResponse>>>;
  /** update data of the table: "track" */
  update_track?: Maybe<TrackMutationResponse>;
  /** update single row of the table: "track" */
  update_track_by_pk?: Maybe<Track>;
  /** update data of the table: "track_detections" */
  update_track_detections?: Maybe<TrackDetectionsMutationResponse>;
  /** update single row of the table: "track_detections" */
  update_track_detections_by_pk?: Maybe<TrackDetections>;
  /** update multiples rows of table: "track_detections" */
  update_track_detections_many?: Maybe<Array<Maybe<TrackDetectionsMutationResponse>>>;
  /** update multiples rows of table: "track" */
  update_track_many?: Maybe<Array<Maybe<TrackMutationResponse>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<UserMutationResponse>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update data of the table: "user_comment" */
  update_user_comment?: Maybe<UserCommentMutationResponse>;
  /** update single row of the table: "user_comment" */
  update_user_comment_by_pk?: Maybe<UserComment>;
  /** update multiples rows of table: "user_comment" */
  update_user_comment_many?: Maybe<Array<Maybe<UserCommentMutationResponse>>>;
  /** update data of the table: "user_label" */
  update_user_label?: Maybe<UserLabelMutationResponse>;
  /** update single row of the table: "user_label" */
  update_user_label_by_pk?: Maybe<UserLabel>;
  /** update multiples rows of table: "user_label" */
  update_user_label_many?: Maybe<Array<Maybe<UserLabelMutationResponse>>>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<UserMutationResponse>>>;
  /** update data of the table: "user_notification" */
  update_user_notification?: Maybe<UserNotificationMutationResponse>;
  /** update single row of the table: "user_notification" */
  update_user_notification_by_pk?: Maybe<UserNotification>;
  /** update multiples rows of table: "user_notification" */
  update_user_notification_many?: Maybe<Array<Maybe<UserNotificationMutationResponse>>>;
  /** update data of the table: "user_notification_setting" */
  update_user_notification_setting?: Maybe<UserNotificationSettingMutationResponse>;
  /** update single row of the table: "user_notification_setting" */
  update_user_notification_setting_by_pk?: Maybe<UserNotificationSetting>;
  /** update multiples rows of table: "user_notification_setting" */
  update_user_notification_setting_many?: Maybe<Array<Maybe<UserNotificationSettingMutationResponse>>>;
  /** update data of the table: "user_organization_role" */
  update_user_organization_role?: Maybe<UserOrganizationRoleMutationResponse>;
  /** update single row of the table: "user_organization_role" */
  update_user_organization_role_by_pk?: Maybe<UserOrganizationRole>;
  /** update multiples rows of table: "user_organization_role" */
  update_user_organization_role_many?: Maybe<Array<Maybe<UserOrganizationRoleMutationResponse>>>;
  /** update data of the table: "version" */
  update_version?: Maybe<VersionMutationResponse>;
  /** update single row of the table: "version" */
  update_version_by_pk?: Maybe<Version>;
  /** update multiples rows of table: "version" */
  update_version_many?: Maybe<Array<Maybe<VersionMutationResponse>>>;
  /** update data of the table: "zone" */
  update_zone?: Maybe<ZoneMutationResponse>;
  /** update single row of the table: "zone" */
  update_zone_by_pk?: Maybe<Zone>;
  /** update multiples rows of table: "zone" */
  update_zone_many?: Maybe<Array<Maybe<ZoneMutationResponse>>>;
};


/** mutation root */
export type MutationRootDeleteAddressArgs = {
  where: AddressBoolExp;
};


/** mutation root */
export type MutationRootDeleteAddressByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteAggregateHeatmapArgs = {
  where: AggregateHeatmapBoolExp;
};


/** mutation root */
export type MutationRootDeleteAggregateHeatmapByPkArgs = {
  uid: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAlembicVersionArgs = {
  where: AlembicVersionBoolExp;
};


/** mutation root */
export type MutationRootDeleteAlembicVersionByPkArgs = {
  version_num: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteClassificationArgs = {
  where: ClassificationBoolExp;
};


/** mutation root */
export type MutationRootDeleteClassificationByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteCommentArgs = {
  where: CommentBoolExp;
};


/** mutation root */
export type MutationRootDeleteCommentByPkArgs = {
  uid: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteComputeArgs = {
  where: ComputeBoolExp;
};


/** mutation root */
export type MutationRootDeleteComputeByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteComputedMeasurementArgs = {
  where: ComputedMeasurementBoolExp;
};


/** mutation root */
export type MutationRootDeleteComputedMeasurementByPkArgs = {
  id: Scalars['bigint']['input'];
  time: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteConfigArgs = {
  where: ConfigBoolExp;
};


/** mutation root */
export type MutationRootDeleteConfigByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteDetectionArgs = {
  where: DetectionBoolExp;
};


/** mutation root */
export type MutationRootDeleteDetectionByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteDetectionRunArgs = {
  where: DetectionRunBoolExp;
};


/** mutation root */
export type MutationRootDeleteDetectionRunByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteDetectorArgs = {
  where: DetectorBoolExp;
};


/** mutation root */
export type MutationRootDeleteDetectorByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteDeviceDatumArgs = {
  where: DeviceDatumBoolExp;
};


/** mutation root */
export type MutationRootDeleteDeviceDatumByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteDeviceMetadataArgs = {
  where: DeviceMetadataBoolExp;
};


/** mutation root */
export type MutationRootDeleteDeviceMetadataByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteDiscussionArgs = {
  where: DiscussionBoolExp;
};


/** mutation root */
export type MutationRootDeleteDiscussionByPkArgs = {
  uid: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteEnumerationArgs = {
  where: EnumerationBoolExp;
};


/** mutation root */
export type MutationRootDeleteEnumerationByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteEnvironmentRecipeArgs = {
  where: EnvironmentRecipeBoolExp;
};


/** mutation root */
export type MutationRootDeleteEnvironmentRecipeByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteEnvironmentRecipeEntryArgs = {
  where: EnvironmentRecipeEntryBoolExp;
};


/** mutation root */
export type MutationRootDeleteEnvironmentRecipeEntryByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteFieldInterventionArgs = {
  where: FieldInterventionBoolExp;
};


/** mutation root */
export type MutationRootDeleteFieldInterventionByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteGeometryColumnsArgs = {
  where: GeometryColumnsBoolExp;
};


/** mutation root */
export type MutationRootDeleteGetMeasurementsOnGridFormatTableArgs = {
  where: GetMeasurementsOnGridFormatTableBoolExp;
};


/** mutation root */
export type MutationRootDeleteGetMostRecentVpdMeasurementsFormatTableArgs = {
  where: GetMostRecentVpdMeasurementsFormatTableBoolExp;
};


/** mutation root */
export type MutationRootDeleteGridArgs = {
  where: GridBoolExp;
};


/** mutation root */
export type MutationRootDeleteGridByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteGridElementArgs = {
  where: GridElementBoolExp;
};


/** mutation root */
export type MutationRootDeleteGridElementByPkArgs = {
  grid_id: Scalars['Int']['input'];
  x_index: Scalars['Int']['input'];
  y_index: Scalars['Int']['input'];
  z_index: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteGrowthCycleArgs = {
  where: GrowthCycleBoolExp;
};


/** mutation root */
export type MutationRootDeleteGrowthCycleByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteHeatMapArgs = {
  where: HeatMapBoolExp;
};


/** mutation root */
export type MutationRootDeleteHeatMapByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteImageAnnotationTrackingArgs = {
  where: ImageAnnotationTrackingBoolExp;
};


/** mutation root */
export type MutationRootDeleteImageAnnotationTrackingByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteLabelArgs = {
  where: LabelBoolExp;
};


/** mutation root */
export type MutationRootDeleteLabelByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteLabelCategoryArgs = {
  where: LabelCategoryBoolExp;
};


/** mutation root */
export type MutationRootDeleteLabelCategoryByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteLabelTaskArgs = {
  where: LabelTaskBoolExp;
};


/** mutation root */
export type MutationRootDeleteLabelTaskByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteLambdaRunArgs = {
  where: LambdaRunBoolExp;
};


/** mutation root */
export type MutationRootDeleteLambdaRunByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteLambdaRunMeasurementRunArgs = {
  where: LambdaRunMeasurementRunBoolExp;
};


/** mutation root */
export type MutationRootDeleteLambdaRunMeasurementRunByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteLambdaVersionArgs = {
  where: LambdaVersionBoolExp;
};


/** mutation root */
export type MutationRootDeleteLambdaVersionByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteLightCycleRecipeArgs = {
  where: LightCycleRecipeBoolExp;
};


/** mutation root */
export type MutationRootDeleteLightCycleRecipeByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteLightCycleRecipeEntryArgs = {
  where: LightCycleRecipeEntryBoolExp;
};


/** mutation root */
export type MutationRootDeleteLightCycleRecipeEntryByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteLocationArgs = {
  where: LocationBoolExp;
};


/** mutation root */
export type MutationRootDeleteLocationByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteMeasurementArgs = {
  where: MeasurementBoolExp;
};


/** mutation root */
export type MutationRootDeleteMeasurementByPkArgs = {
  id: Scalars['bigint']['input'];
  time: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteMeasurementRunArgs = {
  where: MeasurementRunBoolExp;
};


/** mutation root */
export type MutationRootDeleteMeasurementRunByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteNotificationArgs = {
  where: NotificationBoolExp;
};


/** mutation root */
export type MutationRootDeleteNotificationByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteOrganizationArgs = {
  where: OrganizationBoolExp;
};


/** mutation root */
export type MutationRootDeleteOrganizationByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteParametersArgs = {
  where: ParametersBoolExp;
};


/** mutation root */
export type MutationRootDeleteParametersByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeletePoseArgs = {
  where: PoseBoolExp;
};


/** mutation root */
export type MutationRootDeletePoseByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteProtoArgs = {
  where: ProtoBoolExp;
};


/** mutation root */
export type MutationRootDeleteProtoByPkArgs = {
  filename: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteRolePermissionArgs = {
  where: RolePermissionBoolExp;
};


/** mutation root */
export type MutationRootDeleteSensorArgs = {
  where: SensorBoolExp;
};


/** mutation root */
export type MutationRootDeleteSensorByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteSpatialRefSysArgs = {
  where: SpatialRefSysBoolExp;
};


/** mutation root */
export type MutationRootDeleteSpatialRefSysByPkArgs = {
  srid: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteSystemArgs = {
  where: SystemBoolExp;
};


/** mutation root */
export type MutationRootDeleteSystemByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteTrackArgs = {
  where: TrackBoolExp;
};


/** mutation root */
export type MutationRootDeleteTrackByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteTrackDetectionsArgs = {
  where: TrackDetectionsBoolExp;
};


/** mutation root */
export type MutationRootDeleteTrackDetectionsByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteUserArgs = {
  where: UserBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteUserCommentArgs = {
  where: UserCommentBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserCommentByPkArgs = {
  comment_uid: Scalars['uuid']['input'];
  user_id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteUserLabelArgs = {
  where: UserLabelBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserLabelByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteUserNotificationArgs = {
  where: UserNotificationBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserNotificationByPkArgs = {
  notification_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteUserNotificationSettingArgs = {
  where: UserNotificationSettingBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserNotificationSettingByPkArgs = {
  uid: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteUserOrganizationRoleArgs = {
  where: UserOrganizationRoleBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserOrganizationRoleByPkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteVersionArgs = {
  where: VersionBoolExp;
};


/** mutation root */
export type MutationRootDeleteVersionByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootDeleteZoneArgs = {
  where: ZoneBoolExp;
};


/** mutation root */
export type MutationRootDeleteZoneByPkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type MutationRootGetMostRecentVpdMeasurementsArgs = {
  args: GetMostRecentVpdMeasurementsArgs;
  distinct_on?: InputMaybe<Array<GetMostRecentVpdMeasurementsFormatTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GetMostRecentVpdMeasurementsFormatTableOrderBy>>;
  where?: InputMaybe<GetMostRecentVpdMeasurementsFormatTableBoolExp>;
};


/** mutation root */
export type MutationRootInsertAddressArgs = {
  objects: Array<AddressInsertInput>;
  on_conflict?: InputMaybe<AddressOnConflict>;
};


/** mutation root */
export type MutationRootInsertAddressOneArgs = {
  object: AddressInsertInput;
  on_conflict?: InputMaybe<AddressOnConflict>;
};


/** mutation root */
export type MutationRootInsertAggregateHeatmapArgs = {
  objects: Array<AggregateHeatmapInsertInput>;
  on_conflict?: InputMaybe<AggregateHeatmapOnConflict>;
};


/** mutation root */
export type MutationRootInsertAggregateHeatmapOneArgs = {
  object: AggregateHeatmapInsertInput;
  on_conflict?: InputMaybe<AggregateHeatmapOnConflict>;
};


/** mutation root */
export type MutationRootInsertAlembicVersionArgs = {
  objects: Array<AlembicVersionInsertInput>;
  on_conflict?: InputMaybe<AlembicVersionOnConflict>;
};


/** mutation root */
export type MutationRootInsertAlembicVersionOneArgs = {
  object: AlembicVersionInsertInput;
  on_conflict?: InputMaybe<AlembicVersionOnConflict>;
};


/** mutation root */
export type MutationRootInsertClassificationArgs = {
  objects: Array<ClassificationInsertInput>;
  on_conflict?: InputMaybe<ClassificationOnConflict>;
};


/** mutation root */
export type MutationRootInsertClassificationOneArgs = {
  object: ClassificationInsertInput;
  on_conflict?: InputMaybe<ClassificationOnConflict>;
};


/** mutation root */
export type MutationRootInsertCommentArgs = {
  objects: Array<CommentInsertInput>;
  on_conflict?: InputMaybe<CommentOnConflict>;
};


/** mutation root */
export type MutationRootInsertCommentOneArgs = {
  object: CommentInsertInput;
  on_conflict?: InputMaybe<CommentOnConflict>;
};


/** mutation root */
export type MutationRootInsertComputeArgs = {
  objects: Array<ComputeInsertInput>;
  on_conflict?: InputMaybe<ComputeOnConflict>;
};


/** mutation root */
export type MutationRootInsertComputeOneArgs = {
  object: ComputeInsertInput;
  on_conflict?: InputMaybe<ComputeOnConflict>;
};


/** mutation root */
export type MutationRootInsertComputedMeasurementArgs = {
  objects: Array<ComputedMeasurementInsertInput>;
  on_conflict?: InputMaybe<ComputedMeasurementOnConflict>;
};


/** mutation root */
export type MutationRootInsertComputedMeasurementOneArgs = {
  object: ComputedMeasurementInsertInput;
  on_conflict?: InputMaybe<ComputedMeasurementOnConflict>;
};


/** mutation root */
export type MutationRootInsertConfigArgs = {
  objects: Array<ConfigInsertInput>;
  on_conflict?: InputMaybe<ConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertConfigOneArgs = {
  object: ConfigInsertInput;
  on_conflict?: InputMaybe<ConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertDetectionArgs = {
  objects: Array<DetectionInsertInput>;
  on_conflict?: InputMaybe<DetectionOnConflict>;
};


/** mutation root */
export type MutationRootInsertDetectionOneArgs = {
  object: DetectionInsertInput;
  on_conflict?: InputMaybe<DetectionOnConflict>;
};


/** mutation root */
export type MutationRootInsertDetectionRunArgs = {
  objects: Array<DetectionRunInsertInput>;
  on_conflict?: InputMaybe<DetectionRunOnConflict>;
};


/** mutation root */
export type MutationRootInsertDetectionRunOneArgs = {
  object: DetectionRunInsertInput;
  on_conflict?: InputMaybe<DetectionRunOnConflict>;
};


/** mutation root */
export type MutationRootInsertDetectorArgs = {
  objects: Array<DetectorInsertInput>;
  on_conflict?: InputMaybe<DetectorOnConflict>;
};


/** mutation root */
export type MutationRootInsertDetectorOneArgs = {
  object: DetectorInsertInput;
  on_conflict?: InputMaybe<DetectorOnConflict>;
};


/** mutation root */
export type MutationRootInsertDeviceDatumArgs = {
  objects: Array<DeviceDatumInsertInput>;
  on_conflict?: InputMaybe<DeviceDatumOnConflict>;
};


/** mutation root */
export type MutationRootInsertDeviceDatumOneArgs = {
  object: DeviceDatumInsertInput;
  on_conflict?: InputMaybe<DeviceDatumOnConflict>;
};


/** mutation root */
export type MutationRootInsertDeviceMetadataArgs = {
  objects: Array<DeviceMetadataInsertInput>;
  on_conflict?: InputMaybe<DeviceMetadataOnConflict>;
};


/** mutation root */
export type MutationRootInsertDeviceMetadataOneArgs = {
  object: DeviceMetadataInsertInput;
  on_conflict?: InputMaybe<DeviceMetadataOnConflict>;
};


/** mutation root */
export type MutationRootInsertDiscussionArgs = {
  objects: Array<DiscussionInsertInput>;
  on_conflict?: InputMaybe<DiscussionOnConflict>;
};


/** mutation root */
export type MutationRootInsertDiscussionOneArgs = {
  object: DiscussionInsertInput;
  on_conflict?: InputMaybe<DiscussionOnConflict>;
};


/** mutation root */
export type MutationRootInsertEnumerationArgs = {
  objects: Array<EnumerationInsertInput>;
  on_conflict?: InputMaybe<EnumerationOnConflict>;
};


/** mutation root */
export type MutationRootInsertEnumerationOneArgs = {
  object: EnumerationInsertInput;
  on_conflict?: InputMaybe<EnumerationOnConflict>;
};


/** mutation root */
export type MutationRootInsertEnvironmentRecipeArgs = {
  objects: Array<EnvironmentRecipeInsertInput>;
  on_conflict?: InputMaybe<EnvironmentRecipeOnConflict>;
};


/** mutation root */
export type MutationRootInsertEnvironmentRecipeEntryArgs = {
  objects: Array<EnvironmentRecipeEntryInsertInput>;
  on_conflict?: InputMaybe<EnvironmentRecipeEntryOnConflict>;
};


/** mutation root */
export type MutationRootInsertEnvironmentRecipeEntryOneArgs = {
  object: EnvironmentRecipeEntryInsertInput;
  on_conflict?: InputMaybe<EnvironmentRecipeEntryOnConflict>;
};


/** mutation root */
export type MutationRootInsertEnvironmentRecipeOneArgs = {
  object: EnvironmentRecipeInsertInput;
  on_conflict?: InputMaybe<EnvironmentRecipeOnConflict>;
};


/** mutation root */
export type MutationRootInsertFieldInterventionArgs = {
  objects: Array<FieldInterventionInsertInput>;
  on_conflict?: InputMaybe<FieldInterventionOnConflict>;
};


/** mutation root */
export type MutationRootInsertFieldInterventionOneArgs = {
  object: FieldInterventionInsertInput;
  on_conflict?: InputMaybe<FieldInterventionOnConflict>;
};


/** mutation root */
export type MutationRootInsertGeometryColumnsArgs = {
  objects: Array<GeometryColumnsInsertInput>;
};


/** mutation root */
export type MutationRootInsertGeometryColumnsOneArgs = {
  object: GeometryColumnsInsertInput;
};


/** mutation root */
export type MutationRootInsertGetMeasurementsOnGridFormatTableArgs = {
  objects: Array<GetMeasurementsOnGridFormatTableInsertInput>;
};


/** mutation root */
export type MutationRootInsertGetMeasurementsOnGridFormatTableOneArgs = {
  object: GetMeasurementsOnGridFormatTableInsertInput;
};


/** mutation root */
export type MutationRootInsertGetMostRecentVpdMeasurementsFormatTableArgs = {
  objects: Array<GetMostRecentVpdMeasurementsFormatTableInsertInput>;
};


/** mutation root */
export type MutationRootInsertGetMostRecentVpdMeasurementsFormatTableOneArgs = {
  object: GetMostRecentVpdMeasurementsFormatTableInsertInput;
};


/** mutation root */
export type MutationRootInsertGridArgs = {
  objects: Array<GridInsertInput>;
  on_conflict?: InputMaybe<GridOnConflict>;
};


/** mutation root */
export type MutationRootInsertGridElementArgs = {
  objects: Array<GridElementInsertInput>;
  on_conflict?: InputMaybe<GridElementOnConflict>;
};


/** mutation root */
export type MutationRootInsertGridElementOneArgs = {
  object: GridElementInsertInput;
  on_conflict?: InputMaybe<GridElementOnConflict>;
};


/** mutation root */
export type MutationRootInsertGridOneArgs = {
  object: GridInsertInput;
  on_conflict?: InputMaybe<GridOnConflict>;
};


/** mutation root */
export type MutationRootInsertGrowthCycleArgs = {
  objects: Array<GrowthCycleInsertInput>;
  on_conflict?: InputMaybe<GrowthCycleOnConflict>;
};


/** mutation root */
export type MutationRootInsertGrowthCycleOneArgs = {
  object: GrowthCycleInsertInput;
  on_conflict?: InputMaybe<GrowthCycleOnConflict>;
};


/** mutation root */
export type MutationRootInsertHeatMapArgs = {
  objects: Array<HeatMapInsertInput>;
  on_conflict?: InputMaybe<HeatMapOnConflict>;
};


/** mutation root */
export type MutationRootInsertHeatMapOneArgs = {
  object: HeatMapInsertInput;
  on_conflict?: InputMaybe<HeatMapOnConflict>;
};


/** mutation root */
export type MutationRootInsertImageAnnotationTrackingArgs = {
  objects: Array<ImageAnnotationTrackingInsertInput>;
  on_conflict?: InputMaybe<ImageAnnotationTrackingOnConflict>;
};


/** mutation root */
export type MutationRootInsertImageAnnotationTrackingOneArgs = {
  object: ImageAnnotationTrackingInsertInput;
  on_conflict?: InputMaybe<ImageAnnotationTrackingOnConflict>;
};


/** mutation root */
export type MutationRootInsertLabelArgs = {
  objects: Array<LabelInsertInput>;
  on_conflict?: InputMaybe<LabelOnConflict>;
};


/** mutation root */
export type MutationRootInsertLabelCategoryArgs = {
  objects: Array<LabelCategoryInsertInput>;
  on_conflict?: InputMaybe<LabelCategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertLabelCategoryOneArgs = {
  object: LabelCategoryInsertInput;
  on_conflict?: InputMaybe<LabelCategoryOnConflict>;
};


/** mutation root */
export type MutationRootInsertLabelOneArgs = {
  object: LabelInsertInput;
  on_conflict?: InputMaybe<LabelOnConflict>;
};


/** mutation root */
export type MutationRootInsertLabelTaskArgs = {
  objects: Array<LabelTaskInsertInput>;
  on_conflict?: InputMaybe<LabelTaskOnConflict>;
};


/** mutation root */
export type MutationRootInsertLabelTaskOneArgs = {
  object: LabelTaskInsertInput;
  on_conflict?: InputMaybe<LabelTaskOnConflict>;
};


/** mutation root */
export type MutationRootInsertLambdaRunArgs = {
  objects: Array<LambdaRunInsertInput>;
  on_conflict?: InputMaybe<LambdaRunOnConflict>;
};


/** mutation root */
export type MutationRootInsertLambdaRunMeasurementRunArgs = {
  objects: Array<LambdaRunMeasurementRunInsertInput>;
  on_conflict?: InputMaybe<LambdaRunMeasurementRunOnConflict>;
};


/** mutation root */
export type MutationRootInsertLambdaRunMeasurementRunOneArgs = {
  object: LambdaRunMeasurementRunInsertInput;
  on_conflict?: InputMaybe<LambdaRunMeasurementRunOnConflict>;
};


/** mutation root */
export type MutationRootInsertLambdaRunOneArgs = {
  object: LambdaRunInsertInput;
  on_conflict?: InputMaybe<LambdaRunOnConflict>;
};


/** mutation root */
export type MutationRootInsertLambdaVersionArgs = {
  objects: Array<LambdaVersionInsertInput>;
  on_conflict?: InputMaybe<LambdaVersionOnConflict>;
};


/** mutation root */
export type MutationRootInsertLambdaVersionOneArgs = {
  object: LambdaVersionInsertInput;
  on_conflict?: InputMaybe<LambdaVersionOnConflict>;
};


/** mutation root */
export type MutationRootInsertLightCycleRecipeArgs = {
  objects: Array<LightCycleRecipeInsertInput>;
  on_conflict?: InputMaybe<LightCycleRecipeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLightCycleRecipeEntryArgs = {
  objects: Array<LightCycleRecipeEntryInsertInput>;
  on_conflict?: InputMaybe<LightCycleRecipeEntryOnConflict>;
};


/** mutation root */
export type MutationRootInsertLightCycleRecipeEntryOneArgs = {
  object: LightCycleRecipeEntryInsertInput;
  on_conflict?: InputMaybe<LightCycleRecipeEntryOnConflict>;
};


/** mutation root */
export type MutationRootInsertLightCycleRecipeOneArgs = {
  object: LightCycleRecipeInsertInput;
  on_conflict?: InputMaybe<LightCycleRecipeOnConflict>;
};


/** mutation root */
export type MutationRootInsertLocationArgs = {
  objects: Array<LocationInsertInput>;
  on_conflict?: InputMaybe<LocationOnConflict>;
};


/** mutation root */
export type MutationRootInsertLocationOneArgs = {
  object: LocationInsertInput;
  on_conflict?: InputMaybe<LocationOnConflict>;
};


/** mutation root */
export type MutationRootInsertMeasurementArgs = {
  objects: Array<MeasurementInsertInput>;
  on_conflict?: InputMaybe<MeasurementOnConflict>;
};


/** mutation root */
export type MutationRootInsertMeasurementOneArgs = {
  object: MeasurementInsertInput;
  on_conflict?: InputMaybe<MeasurementOnConflict>;
};


/** mutation root */
export type MutationRootInsertMeasurementRunArgs = {
  objects: Array<MeasurementRunInsertInput>;
  on_conflict?: InputMaybe<MeasurementRunOnConflict>;
};


/** mutation root */
export type MutationRootInsertMeasurementRunOneArgs = {
  object: MeasurementRunInsertInput;
  on_conflict?: InputMaybe<MeasurementRunOnConflict>;
};


/** mutation root */
export type MutationRootInsertNotificationArgs = {
  objects: Array<NotificationInsertInput>;
  on_conflict?: InputMaybe<NotificationOnConflict>;
};


/** mutation root */
export type MutationRootInsertNotificationOneArgs = {
  object: NotificationInsertInput;
  on_conflict?: InputMaybe<NotificationOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrganizationArgs = {
  objects: Array<OrganizationInsertInput>;
  on_conflict?: InputMaybe<OrganizationOnConflict>;
};


/** mutation root */
export type MutationRootInsertOrganizationOneArgs = {
  object: OrganizationInsertInput;
  on_conflict?: InputMaybe<OrganizationOnConflict>;
};


/** mutation root */
export type MutationRootInsertParametersArgs = {
  objects: Array<ParametersInsertInput>;
  on_conflict?: InputMaybe<ParametersOnConflict>;
};


/** mutation root */
export type MutationRootInsertParametersOneArgs = {
  object: ParametersInsertInput;
  on_conflict?: InputMaybe<ParametersOnConflict>;
};


/** mutation root */
export type MutationRootInsertPoseArgs = {
  objects: Array<PoseInsertInput>;
  on_conflict?: InputMaybe<PoseOnConflict>;
};


/** mutation root */
export type MutationRootInsertPoseOneArgs = {
  object: PoseInsertInput;
  on_conflict?: InputMaybe<PoseOnConflict>;
};


/** mutation root */
export type MutationRootInsertProtoArgs = {
  objects: Array<ProtoInsertInput>;
  on_conflict?: InputMaybe<ProtoOnConflict>;
};


/** mutation root */
export type MutationRootInsertProtoOneArgs = {
  object: ProtoInsertInput;
  on_conflict?: InputMaybe<ProtoOnConflict>;
};


/** mutation root */
export type MutationRootInsertRolePermissionArgs = {
  objects: Array<RolePermissionInsertInput>;
  on_conflict?: InputMaybe<RolePermissionOnConflict>;
};


/** mutation root */
export type MutationRootInsertRolePermissionOneArgs = {
  object: RolePermissionInsertInput;
  on_conflict?: InputMaybe<RolePermissionOnConflict>;
};


/** mutation root */
export type MutationRootInsertSensorArgs = {
  objects: Array<SensorInsertInput>;
  on_conflict?: InputMaybe<SensorOnConflict>;
};


/** mutation root */
export type MutationRootInsertSensorOneArgs = {
  object: SensorInsertInput;
  on_conflict?: InputMaybe<SensorOnConflict>;
};


/** mutation root */
export type MutationRootInsertSpatialRefSysArgs = {
  objects: Array<SpatialRefSysInsertInput>;
  on_conflict?: InputMaybe<SpatialRefSysOnConflict>;
};


/** mutation root */
export type MutationRootInsertSpatialRefSysOneArgs = {
  object: SpatialRefSysInsertInput;
  on_conflict?: InputMaybe<SpatialRefSysOnConflict>;
};


/** mutation root */
export type MutationRootInsertSystemArgs = {
  objects: Array<SystemInsertInput>;
  on_conflict?: InputMaybe<SystemOnConflict>;
};


/** mutation root */
export type MutationRootInsertSystemOneArgs = {
  object: SystemInsertInput;
  on_conflict?: InputMaybe<SystemOnConflict>;
};


/** mutation root */
export type MutationRootInsertTrackArgs = {
  objects: Array<TrackInsertInput>;
  on_conflict?: InputMaybe<TrackOnConflict>;
};


/** mutation root */
export type MutationRootInsertTrackDetectionsArgs = {
  objects: Array<TrackDetectionsInsertInput>;
  on_conflict?: InputMaybe<TrackDetectionsOnConflict>;
};


/** mutation root */
export type MutationRootInsertTrackDetectionsOneArgs = {
  object: TrackDetectionsInsertInput;
  on_conflict?: InputMaybe<TrackDetectionsOnConflict>;
};


/** mutation root */
export type MutationRootInsertTrackOneArgs = {
  object: TrackInsertInput;
  on_conflict?: InputMaybe<TrackOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserArgs = {
  objects: Array<UserInsertInput>;
  on_conflict?: InputMaybe<UserOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserCommentArgs = {
  objects: Array<UserCommentInsertInput>;
  on_conflict?: InputMaybe<UserCommentOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserCommentOneArgs = {
  object: UserCommentInsertInput;
  on_conflict?: InputMaybe<UserCommentOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserLabelArgs = {
  objects: Array<UserLabelInsertInput>;
  on_conflict?: InputMaybe<UserLabelOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserLabelOneArgs = {
  object: UserLabelInsertInput;
  on_conflict?: InputMaybe<UserLabelOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserNotificationArgs = {
  objects: Array<UserNotificationInsertInput>;
  on_conflict?: InputMaybe<UserNotificationOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserNotificationOneArgs = {
  object: UserNotificationInsertInput;
  on_conflict?: InputMaybe<UserNotificationOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserNotificationSettingArgs = {
  objects: Array<UserNotificationSettingInsertInput>;
  on_conflict?: InputMaybe<UserNotificationSettingOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserNotificationSettingOneArgs = {
  object: UserNotificationSettingInsertInput;
  on_conflict?: InputMaybe<UserNotificationSettingOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserOneArgs = {
  object: UserInsertInput;
  on_conflict?: InputMaybe<UserOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserOrganizationRoleArgs = {
  objects: Array<UserOrganizationRoleInsertInput>;
  on_conflict?: InputMaybe<UserOrganizationRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserOrganizationRoleOneArgs = {
  object: UserOrganizationRoleInsertInput;
  on_conflict?: InputMaybe<UserOrganizationRoleOnConflict>;
};


/** mutation root */
export type MutationRootInsertVersionArgs = {
  objects: Array<VersionInsertInput>;
  on_conflict?: InputMaybe<VersionOnConflict>;
};


/** mutation root */
export type MutationRootInsertVersionOneArgs = {
  object: VersionInsertInput;
  on_conflict?: InputMaybe<VersionOnConflict>;
};


/** mutation root */
export type MutationRootInsertZoneArgs = {
  objects: Array<ZoneInsertInput>;
  on_conflict?: InputMaybe<ZoneOnConflict>;
};


/** mutation root */
export type MutationRootInsertZoneOneArgs = {
  object: ZoneInsertInput;
  on_conflict?: InputMaybe<ZoneOnConflict>;
};


/** mutation root */
export type MutationRootUpdateAddressArgs = {
  _append?: InputMaybe<AddressAppendInput>;
  _delete_at_path?: InputMaybe<AddressDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AddressDeleteElemInput>;
  _delete_key?: InputMaybe<AddressDeleteKeyInput>;
  _inc?: InputMaybe<AddressIncInput>;
  _prepend?: InputMaybe<AddressPrependInput>;
  _set?: InputMaybe<AddressSetInput>;
  where: AddressBoolExp;
};


/** mutation root */
export type MutationRootUpdateAddressByPkArgs = {
  _append?: InputMaybe<AddressAppendInput>;
  _delete_at_path?: InputMaybe<AddressDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AddressDeleteElemInput>;
  _delete_key?: InputMaybe<AddressDeleteKeyInput>;
  _inc?: InputMaybe<AddressIncInput>;
  _prepend?: InputMaybe<AddressPrependInput>;
  _set?: InputMaybe<AddressSetInput>;
  pk_columns: AddressPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAddressManyArgs = {
  updates: Array<AddressUpdates>;
};


/** mutation root */
export type MutationRootUpdateAggregateHeatmapArgs = {
  _append?: InputMaybe<AggregateHeatmapAppendInput>;
  _delete_at_path?: InputMaybe<AggregateHeatmapDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AggregateHeatmapDeleteElemInput>;
  _delete_key?: InputMaybe<AggregateHeatmapDeleteKeyInput>;
  _inc?: InputMaybe<AggregateHeatmapIncInput>;
  _prepend?: InputMaybe<AggregateHeatmapPrependInput>;
  _set?: InputMaybe<AggregateHeatmapSetInput>;
  where: AggregateHeatmapBoolExp;
};


/** mutation root */
export type MutationRootUpdateAggregateHeatmapByPkArgs = {
  _append?: InputMaybe<AggregateHeatmapAppendInput>;
  _delete_at_path?: InputMaybe<AggregateHeatmapDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AggregateHeatmapDeleteElemInput>;
  _delete_key?: InputMaybe<AggregateHeatmapDeleteKeyInput>;
  _inc?: InputMaybe<AggregateHeatmapIncInput>;
  _prepend?: InputMaybe<AggregateHeatmapPrependInput>;
  _set?: InputMaybe<AggregateHeatmapSetInput>;
  pk_columns: AggregateHeatmapPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAggregateHeatmapManyArgs = {
  updates: Array<AggregateHeatmapUpdates>;
};


/** mutation root */
export type MutationRootUpdateAlembicVersionArgs = {
  _set?: InputMaybe<AlembicVersionSetInput>;
  where: AlembicVersionBoolExp;
};


/** mutation root */
export type MutationRootUpdateAlembicVersionByPkArgs = {
  _set?: InputMaybe<AlembicVersionSetInput>;
  pk_columns: AlembicVersionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAlembicVersionManyArgs = {
  updates: Array<AlembicVersionUpdates>;
};


/** mutation root */
export type MutationRootUpdateClassificationArgs = {
  _append?: InputMaybe<ClassificationAppendInput>;
  _delete_at_path?: InputMaybe<ClassificationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ClassificationDeleteElemInput>;
  _delete_key?: InputMaybe<ClassificationDeleteKeyInput>;
  _inc?: InputMaybe<ClassificationIncInput>;
  _prepend?: InputMaybe<ClassificationPrependInput>;
  _set?: InputMaybe<ClassificationSetInput>;
  where: ClassificationBoolExp;
};


/** mutation root */
export type MutationRootUpdateClassificationByPkArgs = {
  _append?: InputMaybe<ClassificationAppendInput>;
  _delete_at_path?: InputMaybe<ClassificationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ClassificationDeleteElemInput>;
  _delete_key?: InputMaybe<ClassificationDeleteKeyInput>;
  _inc?: InputMaybe<ClassificationIncInput>;
  _prepend?: InputMaybe<ClassificationPrependInput>;
  _set?: InputMaybe<ClassificationSetInput>;
  pk_columns: ClassificationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateClassificationManyArgs = {
  updates: Array<ClassificationUpdates>;
};


/** mutation root */
export type MutationRootUpdateCommentArgs = {
  _append?: InputMaybe<CommentAppendInput>;
  _delete_at_path?: InputMaybe<CommentDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CommentDeleteElemInput>;
  _delete_key?: InputMaybe<CommentDeleteKeyInput>;
  _inc?: InputMaybe<CommentIncInput>;
  _prepend?: InputMaybe<CommentPrependInput>;
  _set?: InputMaybe<CommentSetInput>;
  where: CommentBoolExp;
};


/** mutation root */
export type MutationRootUpdateCommentByPkArgs = {
  _append?: InputMaybe<CommentAppendInput>;
  _delete_at_path?: InputMaybe<CommentDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CommentDeleteElemInput>;
  _delete_key?: InputMaybe<CommentDeleteKeyInput>;
  _inc?: InputMaybe<CommentIncInput>;
  _prepend?: InputMaybe<CommentPrependInput>;
  _set?: InputMaybe<CommentSetInput>;
  pk_columns: CommentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCommentManyArgs = {
  updates: Array<CommentUpdates>;
};


/** mutation root */
export type MutationRootUpdateComputeArgs = {
  _append?: InputMaybe<ComputeAppendInput>;
  _delete_at_path?: InputMaybe<ComputeDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ComputeDeleteElemInput>;
  _delete_key?: InputMaybe<ComputeDeleteKeyInput>;
  _inc?: InputMaybe<ComputeIncInput>;
  _prepend?: InputMaybe<ComputePrependInput>;
  _set?: InputMaybe<ComputeSetInput>;
  where: ComputeBoolExp;
};


/** mutation root */
export type MutationRootUpdateComputeByPkArgs = {
  _append?: InputMaybe<ComputeAppendInput>;
  _delete_at_path?: InputMaybe<ComputeDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ComputeDeleteElemInput>;
  _delete_key?: InputMaybe<ComputeDeleteKeyInput>;
  _inc?: InputMaybe<ComputeIncInput>;
  _prepend?: InputMaybe<ComputePrependInput>;
  _set?: InputMaybe<ComputeSetInput>;
  pk_columns: ComputePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateComputeManyArgs = {
  updates: Array<ComputeUpdates>;
};


/** mutation root */
export type MutationRootUpdateComputedMeasurementArgs = {
  _append?: InputMaybe<ComputedMeasurementAppendInput>;
  _delete_at_path?: InputMaybe<ComputedMeasurementDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ComputedMeasurementDeleteElemInput>;
  _delete_key?: InputMaybe<ComputedMeasurementDeleteKeyInput>;
  _inc?: InputMaybe<ComputedMeasurementIncInput>;
  _prepend?: InputMaybe<ComputedMeasurementPrependInput>;
  _set?: InputMaybe<ComputedMeasurementSetInput>;
  where: ComputedMeasurementBoolExp;
};


/** mutation root */
export type MutationRootUpdateComputedMeasurementByPkArgs = {
  _append?: InputMaybe<ComputedMeasurementAppendInput>;
  _delete_at_path?: InputMaybe<ComputedMeasurementDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ComputedMeasurementDeleteElemInput>;
  _delete_key?: InputMaybe<ComputedMeasurementDeleteKeyInput>;
  _inc?: InputMaybe<ComputedMeasurementIncInput>;
  _prepend?: InputMaybe<ComputedMeasurementPrependInput>;
  _set?: InputMaybe<ComputedMeasurementSetInput>;
  pk_columns: ComputedMeasurementPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateComputedMeasurementManyArgs = {
  updates: Array<ComputedMeasurementUpdates>;
};


/** mutation root */
export type MutationRootUpdateConfigArgs = {
  _append?: InputMaybe<ConfigAppendInput>;
  _delete_at_path?: InputMaybe<ConfigDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ConfigDeleteElemInput>;
  _delete_key?: InputMaybe<ConfigDeleteKeyInput>;
  _inc?: InputMaybe<ConfigIncInput>;
  _prepend?: InputMaybe<ConfigPrependInput>;
  _set?: InputMaybe<ConfigSetInput>;
  where: ConfigBoolExp;
};


/** mutation root */
export type MutationRootUpdateConfigByPkArgs = {
  _append?: InputMaybe<ConfigAppendInput>;
  _delete_at_path?: InputMaybe<ConfigDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ConfigDeleteElemInput>;
  _delete_key?: InputMaybe<ConfigDeleteKeyInput>;
  _inc?: InputMaybe<ConfigIncInput>;
  _prepend?: InputMaybe<ConfigPrependInput>;
  _set?: InputMaybe<ConfigSetInput>;
  pk_columns: ConfigPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateConfigManyArgs = {
  updates: Array<ConfigUpdates>;
};


/** mutation root */
export type MutationRootUpdateDetectionArgs = {
  _append?: InputMaybe<DetectionAppendInput>;
  _delete_at_path?: InputMaybe<DetectionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DetectionDeleteElemInput>;
  _delete_key?: InputMaybe<DetectionDeleteKeyInput>;
  _inc?: InputMaybe<DetectionIncInput>;
  _prepend?: InputMaybe<DetectionPrependInput>;
  _set?: InputMaybe<DetectionSetInput>;
  where: DetectionBoolExp;
};


/** mutation root */
export type MutationRootUpdateDetectionByPkArgs = {
  _append?: InputMaybe<DetectionAppendInput>;
  _delete_at_path?: InputMaybe<DetectionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DetectionDeleteElemInput>;
  _delete_key?: InputMaybe<DetectionDeleteKeyInput>;
  _inc?: InputMaybe<DetectionIncInput>;
  _prepend?: InputMaybe<DetectionPrependInput>;
  _set?: InputMaybe<DetectionSetInput>;
  pk_columns: DetectionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDetectionManyArgs = {
  updates: Array<DetectionUpdates>;
};


/** mutation root */
export type MutationRootUpdateDetectionRunArgs = {
  _append?: InputMaybe<DetectionRunAppendInput>;
  _delete_at_path?: InputMaybe<DetectionRunDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DetectionRunDeleteElemInput>;
  _delete_key?: InputMaybe<DetectionRunDeleteKeyInput>;
  _inc?: InputMaybe<DetectionRunIncInput>;
  _prepend?: InputMaybe<DetectionRunPrependInput>;
  _set?: InputMaybe<DetectionRunSetInput>;
  where: DetectionRunBoolExp;
};


/** mutation root */
export type MutationRootUpdateDetectionRunByPkArgs = {
  _append?: InputMaybe<DetectionRunAppendInput>;
  _delete_at_path?: InputMaybe<DetectionRunDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DetectionRunDeleteElemInput>;
  _delete_key?: InputMaybe<DetectionRunDeleteKeyInput>;
  _inc?: InputMaybe<DetectionRunIncInput>;
  _prepend?: InputMaybe<DetectionRunPrependInput>;
  _set?: InputMaybe<DetectionRunSetInput>;
  pk_columns: DetectionRunPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDetectionRunManyArgs = {
  updates: Array<DetectionRunUpdates>;
};


/** mutation root */
export type MutationRootUpdateDetectorArgs = {
  _append?: InputMaybe<DetectorAppendInput>;
  _delete_at_path?: InputMaybe<DetectorDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DetectorDeleteElemInput>;
  _delete_key?: InputMaybe<DetectorDeleteKeyInput>;
  _inc?: InputMaybe<DetectorIncInput>;
  _prepend?: InputMaybe<DetectorPrependInput>;
  _set?: InputMaybe<DetectorSetInput>;
  where: DetectorBoolExp;
};


/** mutation root */
export type MutationRootUpdateDetectorByPkArgs = {
  _append?: InputMaybe<DetectorAppendInput>;
  _delete_at_path?: InputMaybe<DetectorDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DetectorDeleteElemInput>;
  _delete_key?: InputMaybe<DetectorDeleteKeyInput>;
  _inc?: InputMaybe<DetectorIncInput>;
  _prepend?: InputMaybe<DetectorPrependInput>;
  _set?: InputMaybe<DetectorSetInput>;
  pk_columns: DetectorPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDetectorManyArgs = {
  updates: Array<DetectorUpdates>;
};


/** mutation root */
export type MutationRootUpdateDeviceDatumArgs = {
  _append?: InputMaybe<DeviceDatumAppendInput>;
  _delete_at_path?: InputMaybe<DeviceDatumDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DeviceDatumDeleteElemInput>;
  _delete_key?: InputMaybe<DeviceDatumDeleteKeyInput>;
  _prepend?: InputMaybe<DeviceDatumPrependInput>;
  _set?: InputMaybe<DeviceDatumSetInput>;
  where: DeviceDatumBoolExp;
};


/** mutation root */
export type MutationRootUpdateDeviceDatumByPkArgs = {
  _append?: InputMaybe<DeviceDatumAppendInput>;
  _delete_at_path?: InputMaybe<DeviceDatumDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DeviceDatumDeleteElemInput>;
  _delete_key?: InputMaybe<DeviceDatumDeleteKeyInput>;
  _prepend?: InputMaybe<DeviceDatumPrependInput>;
  _set?: InputMaybe<DeviceDatumSetInput>;
  pk_columns: DeviceDatumPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDeviceDatumManyArgs = {
  updates: Array<DeviceDatumUpdates>;
};


/** mutation root */
export type MutationRootUpdateDeviceMetadataArgs = {
  _append?: InputMaybe<DeviceMetadataAppendInput>;
  _delete_at_path?: InputMaybe<DeviceMetadataDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DeviceMetadataDeleteElemInput>;
  _delete_key?: InputMaybe<DeviceMetadataDeleteKeyInput>;
  _prepend?: InputMaybe<DeviceMetadataPrependInput>;
  _set?: InputMaybe<DeviceMetadataSetInput>;
  where: DeviceMetadataBoolExp;
};


/** mutation root */
export type MutationRootUpdateDeviceMetadataByPkArgs = {
  _append?: InputMaybe<DeviceMetadataAppendInput>;
  _delete_at_path?: InputMaybe<DeviceMetadataDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DeviceMetadataDeleteElemInput>;
  _delete_key?: InputMaybe<DeviceMetadataDeleteKeyInput>;
  _prepend?: InputMaybe<DeviceMetadataPrependInput>;
  _set?: InputMaybe<DeviceMetadataSetInput>;
  pk_columns: DeviceMetadataPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDeviceMetadataManyArgs = {
  updates: Array<DeviceMetadataUpdates>;
};


/** mutation root */
export type MutationRootUpdateDiscussionArgs = {
  _append?: InputMaybe<DiscussionAppendInput>;
  _delete_at_path?: InputMaybe<DiscussionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DiscussionDeleteElemInput>;
  _delete_key?: InputMaybe<DiscussionDeleteKeyInput>;
  _inc?: InputMaybe<DiscussionIncInput>;
  _prepend?: InputMaybe<DiscussionPrependInput>;
  _set?: InputMaybe<DiscussionSetInput>;
  where: DiscussionBoolExp;
};


/** mutation root */
export type MutationRootUpdateDiscussionByPkArgs = {
  _append?: InputMaybe<DiscussionAppendInput>;
  _delete_at_path?: InputMaybe<DiscussionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DiscussionDeleteElemInput>;
  _delete_key?: InputMaybe<DiscussionDeleteKeyInput>;
  _inc?: InputMaybe<DiscussionIncInput>;
  _prepend?: InputMaybe<DiscussionPrependInput>;
  _set?: InputMaybe<DiscussionSetInput>;
  pk_columns: DiscussionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDiscussionManyArgs = {
  updates: Array<DiscussionUpdates>;
};


/** mutation root */
export type MutationRootUpdateEnumerationArgs = {
  _append?: InputMaybe<EnumerationAppendInput>;
  _delete_at_path?: InputMaybe<EnumerationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<EnumerationDeleteElemInput>;
  _delete_key?: InputMaybe<EnumerationDeleteKeyInput>;
  _inc?: InputMaybe<EnumerationIncInput>;
  _prepend?: InputMaybe<EnumerationPrependInput>;
  _set?: InputMaybe<EnumerationSetInput>;
  where: EnumerationBoolExp;
};


/** mutation root */
export type MutationRootUpdateEnumerationByPkArgs = {
  _append?: InputMaybe<EnumerationAppendInput>;
  _delete_at_path?: InputMaybe<EnumerationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<EnumerationDeleteElemInput>;
  _delete_key?: InputMaybe<EnumerationDeleteKeyInput>;
  _inc?: InputMaybe<EnumerationIncInput>;
  _prepend?: InputMaybe<EnumerationPrependInput>;
  _set?: InputMaybe<EnumerationSetInput>;
  pk_columns: EnumerationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEnumerationManyArgs = {
  updates: Array<EnumerationUpdates>;
};


/** mutation root */
export type MutationRootUpdateEnvironmentRecipeArgs = {
  _append?: InputMaybe<EnvironmentRecipeAppendInput>;
  _delete_at_path?: InputMaybe<EnvironmentRecipeDeleteAtPathInput>;
  _delete_elem?: InputMaybe<EnvironmentRecipeDeleteElemInput>;
  _delete_key?: InputMaybe<EnvironmentRecipeDeleteKeyInput>;
  _inc?: InputMaybe<EnvironmentRecipeIncInput>;
  _prepend?: InputMaybe<EnvironmentRecipePrependInput>;
  _set?: InputMaybe<EnvironmentRecipeSetInput>;
  where: EnvironmentRecipeBoolExp;
};


/** mutation root */
export type MutationRootUpdateEnvironmentRecipeByPkArgs = {
  _append?: InputMaybe<EnvironmentRecipeAppendInput>;
  _delete_at_path?: InputMaybe<EnvironmentRecipeDeleteAtPathInput>;
  _delete_elem?: InputMaybe<EnvironmentRecipeDeleteElemInput>;
  _delete_key?: InputMaybe<EnvironmentRecipeDeleteKeyInput>;
  _inc?: InputMaybe<EnvironmentRecipeIncInput>;
  _prepend?: InputMaybe<EnvironmentRecipePrependInput>;
  _set?: InputMaybe<EnvironmentRecipeSetInput>;
  pk_columns: EnvironmentRecipePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEnvironmentRecipeEntryArgs = {
  _inc?: InputMaybe<EnvironmentRecipeEntryIncInput>;
  _set?: InputMaybe<EnvironmentRecipeEntrySetInput>;
  where: EnvironmentRecipeEntryBoolExp;
};


/** mutation root */
export type MutationRootUpdateEnvironmentRecipeEntryByPkArgs = {
  _inc?: InputMaybe<EnvironmentRecipeEntryIncInput>;
  _set?: InputMaybe<EnvironmentRecipeEntrySetInput>;
  pk_columns: EnvironmentRecipeEntryPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateEnvironmentRecipeEntryManyArgs = {
  updates: Array<EnvironmentRecipeEntryUpdates>;
};


/** mutation root */
export type MutationRootUpdateEnvironmentRecipeManyArgs = {
  updates: Array<EnvironmentRecipeUpdates>;
};


/** mutation root */
export type MutationRootUpdateFieldInterventionArgs = {
  _append?: InputMaybe<FieldInterventionAppendInput>;
  _delete_at_path?: InputMaybe<FieldInterventionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<FieldInterventionDeleteElemInput>;
  _delete_key?: InputMaybe<FieldInterventionDeleteKeyInput>;
  _inc?: InputMaybe<FieldInterventionIncInput>;
  _prepend?: InputMaybe<FieldInterventionPrependInput>;
  _set?: InputMaybe<FieldInterventionSetInput>;
  where: FieldInterventionBoolExp;
};


/** mutation root */
export type MutationRootUpdateFieldInterventionByPkArgs = {
  _append?: InputMaybe<FieldInterventionAppendInput>;
  _delete_at_path?: InputMaybe<FieldInterventionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<FieldInterventionDeleteElemInput>;
  _delete_key?: InputMaybe<FieldInterventionDeleteKeyInput>;
  _inc?: InputMaybe<FieldInterventionIncInput>;
  _prepend?: InputMaybe<FieldInterventionPrependInput>;
  _set?: InputMaybe<FieldInterventionSetInput>;
  pk_columns: FieldInterventionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFieldInterventionManyArgs = {
  updates: Array<FieldInterventionUpdates>;
};


/** mutation root */
export type MutationRootUpdateGeometryColumnsArgs = {
  _inc?: InputMaybe<GeometryColumnsIncInput>;
  _set?: InputMaybe<GeometryColumnsSetInput>;
  where: GeometryColumnsBoolExp;
};


/** mutation root */
export type MutationRootUpdateGeometryColumnsManyArgs = {
  updates: Array<GeometryColumnsUpdates>;
};


/** mutation root */
export type MutationRootUpdateGetMeasurementsOnGridFormatTableArgs = {
  _inc?: InputMaybe<GetMeasurementsOnGridFormatTableIncInput>;
  _set?: InputMaybe<GetMeasurementsOnGridFormatTableSetInput>;
  where: GetMeasurementsOnGridFormatTableBoolExp;
};


/** mutation root */
export type MutationRootUpdateGetMeasurementsOnGridFormatTableManyArgs = {
  updates: Array<GetMeasurementsOnGridFormatTableUpdates>;
};


/** mutation root */
export type MutationRootUpdateGetMostRecentVpdMeasurementsFormatTableArgs = {
  _inc?: InputMaybe<GetMostRecentVpdMeasurementsFormatTableIncInput>;
  _set?: InputMaybe<GetMostRecentVpdMeasurementsFormatTableSetInput>;
  where: GetMostRecentVpdMeasurementsFormatTableBoolExp;
};


/** mutation root */
export type MutationRootUpdateGetMostRecentVpdMeasurementsFormatTableManyArgs = {
  updates: Array<GetMostRecentVpdMeasurementsFormatTableUpdates>;
};


/** mutation root */
export type MutationRootUpdateGridArgs = {
  _inc?: InputMaybe<GridIncInput>;
  _set?: InputMaybe<GridSetInput>;
  where: GridBoolExp;
};


/** mutation root */
export type MutationRootUpdateGridByPkArgs = {
  _inc?: InputMaybe<GridIncInput>;
  _set?: InputMaybe<GridSetInput>;
  pk_columns: GridPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateGridElementArgs = {
  _inc?: InputMaybe<GridElementIncInput>;
  _set?: InputMaybe<GridElementSetInput>;
  where: GridElementBoolExp;
};


/** mutation root */
export type MutationRootUpdateGridElementByPkArgs = {
  _inc?: InputMaybe<GridElementIncInput>;
  _set?: InputMaybe<GridElementSetInput>;
  pk_columns: GridElementPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateGridElementManyArgs = {
  updates: Array<GridElementUpdates>;
};


/** mutation root */
export type MutationRootUpdateGridManyArgs = {
  updates: Array<GridUpdates>;
};


/** mutation root */
export type MutationRootUpdateGrowthCycleArgs = {
  _append?: InputMaybe<GrowthCycleAppendInput>;
  _delete_at_path?: InputMaybe<GrowthCycleDeleteAtPathInput>;
  _delete_elem?: InputMaybe<GrowthCycleDeleteElemInput>;
  _delete_key?: InputMaybe<GrowthCycleDeleteKeyInput>;
  _inc?: InputMaybe<GrowthCycleIncInput>;
  _prepend?: InputMaybe<GrowthCyclePrependInput>;
  _set?: InputMaybe<GrowthCycleSetInput>;
  where: GrowthCycleBoolExp;
};


/** mutation root */
export type MutationRootUpdateGrowthCycleByPkArgs = {
  _append?: InputMaybe<GrowthCycleAppendInput>;
  _delete_at_path?: InputMaybe<GrowthCycleDeleteAtPathInput>;
  _delete_elem?: InputMaybe<GrowthCycleDeleteElemInput>;
  _delete_key?: InputMaybe<GrowthCycleDeleteKeyInput>;
  _inc?: InputMaybe<GrowthCycleIncInput>;
  _prepend?: InputMaybe<GrowthCyclePrependInput>;
  _set?: InputMaybe<GrowthCycleSetInput>;
  pk_columns: GrowthCyclePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateGrowthCycleManyArgs = {
  updates: Array<GrowthCycleUpdates>;
};


/** mutation root */
export type MutationRootUpdateHeatMapArgs = {
  _append?: InputMaybe<HeatMapAppendInput>;
  _delete_at_path?: InputMaybe<HeatMapDeleteAtPathInput>;
  _delete_elem?: InputMaybe<HeatMapDeleteElemInput>;
  _delete_key?: InputMaybe<HeatMapDeleteKeyInput>;
  _inc?: InputMaybe<HeatMapIncInput>;
  _prepend?: InputMaybe<HeatMapPrependInput>;
  _set?: InputMaybe<HeatMapSetInput>;
  where: HeatMapBoolExp;
};


/** mutation root */
export type MutationRootUpdateHeatMapByPkArgs = {
  _append?: InputMaybe<HeatMapAppendInput>;
  _delete_at_path?: InputMaybe<HeatMapDeleteAtPathInput>;
  _delete_elem?: InputMaybe<HeatMapDeleteElemInput>;
  _delete_key?: InputMaybe<HeatMapDeleteKeyInput>;
  _inc?: InputMaybe<HeatMapIncInput>;
  _prepend?: InputMaybe<HeatMapPrependInput>;
  _set?: InputMaybe<HeatMapSetInput>;
  pk_columns: HeatMapPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateHeatMapManyArgs = {
  updates: Array<HeatMapUpdates>;
};


/** mutation root */
export type MutationRootUpdateImageAnnotationTrackingArgs = {
  _inc?: InputMaybe<ImageAnnotationTrackingIncInput>;
  _set?: InputMaybe<ImageAnnotationTrackingSetInput>;
  where: ImageAnnotationTrackingBoolExp;
};


/** mutation root */
export type MutationRootUpdateImageAnnotationTrackingByPkArgs = {
  _inc?: InputMaybe<ImageAnnotationTrackingIncInput>;
  _set?: InputMaybe<ImageAnnotationTrackingSetInput>;
  pk_columns: ImageAnnotationTrackingPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateImageAnnotationTrackingManyArgs = {
  updates: Array<ImageAnnotationTrackingUpdates>;
};


/** mutation root */
export type MutationRootUpdateLabelArgs = {
  _append?: InputMaybe<LabelAppendInput>;
  _delete_at_path?: InputMaybe<LabelDeleteAtPathInput>;
  _delete_elem?: InputMaybe<LabelDeleteElemInput>;
  _delete_key?: InputMaybe<LabelDeleteKeyInput>;
  _inc?: InputMaybe<LabelIncInput>;
  _prepend?: InputMaybe<LabelPrependInput>;
  _set?: InputMaybe<LabelSetInput>;
  where: LabelBoolExp;
};


/** mutation root */
export type MutationRootUpdateLabelByPkArgs = {
  _append?: InputMaybe<LabelAppendInput>;
  _delete_at_path?: InputMaybe<LabelDeleteAtPathInput>;
  _delete_elem?: InputMaybe<LabelDeleteElemInput>;
  _delete_key?: InputMaybe<LabelDeleteKeyInput>;
  _inc?: InputMaybe<LabelIncInput>;
  _prepend?: InputMaybe<LabelPrependInput>;
  _set?: InputMaybe<LabelSetInput>;
  pk_columns: LabelPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLabelCategoryArgs = {
  _inc?: InputMaybe<LabelCategoryIncInput>;
  _set?: InputMaybe<LabelCategorySetInput>;
  where: LabelCategoryBoolExp;
};


/** mutation root */
export type MutationRootUpdateLabelCategoryByPkArgs = {
  _inc?: InputMaybe<LabelCategoryIncInput>;
  _set?: InputMaybe<LabelCategorySetInput>;
  pk_columns: LabelCategoryPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLabelCategoryManyArgs = {
  updates: Array<LabelCategoryUpdates>;
};


/** mutation root */
export type MutationRootUpdateLabelManyArgs = {
  updates: Array<LabelUpdates>;
};


/** mutation root */
export type MutationRootUpdateLabelTaskArgs = {
  _append?: InputMaybe<LabelTaskAppendInput>;
  _delete_at_path?: InputMaybe<LabelTaskDeleteAtPathInput>;
  _delete_elem?: InputMaybe<LabelTaskDeleteElemInput>;
  _delete_key?: InputMaybe<LabelTaskDeleteKeyInput>;
  _inc?: InputMaybe<LabelTaskIncInput>;
  _prepend?: InputMaybe<LabelTaskPrependInput>;
  _set?: InputMaybe<LabelTaskSetInput>;
  where: LabelTaskBoolExp;
};


/** mutation root */
export type MutationRootUpdateLabelTaskByPkArgs = {
  _append?: InputMaybe<LabelTaskAppendInput>;
  _delete_at_path?: InputMaybe<LabelTaskDeleteAtPathInput>;
  _delete_elem?: InputMaybe<LabelTaskDeleteElemInput>;
  _delete_key?: InputMaybe<LabelTaskDeleteKeyInput>;
  _inc?: InputMaybe<LabelTaskIncInput>;
  _prepend?: InputMaybe<LabelTaskPrependInput>;
  _set?: InputMaybe<LabelTaskSetInput>;
  pk_columns: LabelTaskPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLabelTaskManyArgs = {
  updates: Array<LabelTaskUpdates>;
};


/** mutation root */
export type MutationRootUpdateLambdaRunArgs = {
  _append?: InputMaybe<LambdaRunAppendInput>;
  _delete_at_path?: InputMaybe<LambdaRunDeleteAtPathInput>;
  _delete_elem?: InputMaybe<LambdaRunDeleteElemInput>;
  _delete_key?: InputMaybe<LambdaRunDeleteKeyInput>;
  _inc?: InputMaybe<LambdaRunIncInput>;
  _prepend?: InputMaybe<LambdaRunPrependInput>;
  _set?: InputMaybe<LambdaRunSetInput>;
  where: LambdaRunBoolExp;
};


/** mutation root */
export type MutationRootUpdateLambdaRunByPkArgs = {
  _append?: InputMaybe<LambdaRunAppendInput>;
  _delete_at_path?: InputMaybe<LambdaRunDeleteAtPathInput>;
  _delete_elem?: InputMaybe<LambdaRunDeleteElemInput>;
  _delete_key?: InputMaybe<LambdaRunDeleteKeyInput>;
  _inc?: InputMaybe<LambdaRunIncInput>;
  _prepend?: InputMaybe<LambdaRunPrependInput>;
  _set?: InputMaybe<LambdaRunSetInput>;
  pk_columns: LambdaRunPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLambdaRunManyArgs = {
  updates: Array<LambdaRunUpdates>;
};


/** mutation root */
export type MutationRootUpdateLambdaRunMeasurementRunArgs = {
  _inc?: InputMaybe<LambdaRunMeasurementRunIncInput>;
  _set?: InputMaybe<LambdaRunMeasurementRunSetInput>;
  where: LambdaRunMeasurementRunBoolExp;
};


/** mutation root */
export type MutationRootUpdateLambdaRunMeasurementRunByPkArgs = {
  _inc?: InputMaybe<LambdaRunMeasurementRunIncInput>;
  _set?: InputMaybe<LambdaRunMeasurementRunSetInput>;
  pk_columns: LambdaRunMeasurementRunPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLambdaRunMeasurementRunManyArgs = {
  updates: Array<LambdaRunMeasurementRunUpdates>;
};


/** mutation root */
export type MutationRootUpdateLambdaVersionArgs = {
  _append?: InputMaybe<LambdaVersionAppendInput>;
  _delete_at_path?: InputMaybe<LambdaVersionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<LambdaVersionDeleteElemInput>;
  _delete_key?: InputMaybe<LambdaVersionDeleteKeyInput>;
  _inc?: InputMaybe<LambdaVersionIncInput>;
  _prepend?: InputMaybe<LambdaVersionPrependInput>;
  _set?: InputMaybe<LambdaVersionSetInput>;
  where: LambdaVersionBoolExp;
};


/** mutation root */
export type MutationRootUpdateLambdaVersionByPkArgs = {
  _append?: InputMaybe<LambdaVersionAppendInput>;
  _delete_at_path?: InputMaybe<LambdaVersionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<LambdaVersionDeleteElemInput>;
  _delete_key?: InputMaybe<LambdaVersionDeleteKeyInput>;
  _inc?: InputMaybe<LambdaVersionIncInput>;
  _prepend?: InputMaybe<LambdaVersionPrependInput>;
  _set?: InputMaybe<LambdaVersionSetInput>;
  pk_columns: LambdaVersionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLambdaVersionManyArgs = {
  updates: Array<LambdaVersionUpdates>;
};


/** mutation root */
export type MutationRootUpdateLightCycleRecipeArgs = {
  _append?: InputMaybe<LightCycleRecipeAppendInput>;
  _delete_at_path?: InputMaybe<LightCycleRecipeDeleteAtPathInput>;
  _delete_elem?: InputMaybe<LightCycleRecipeDeleteElemInput>;
  _delete_key?: InputMaybe<LightCycleRecipeDeleteKeyInput>;
  _inc?: InputMaybe<LightCycleRecipeIncInput>;
  _prepend?: InputMaybe<LightCycleRecipePrependInput>;
  _set?: InputMaybe<LightCycleRecipeSetInput>;
  where: LightCycleRecipeBoolExp;
};


/** mutation root */
export type MutationRootUpdateLightCycleRecipeByPkArgs = {
  _append?: InputMaybe<LightCycleRecipeAppendInput>;
  _delete_at_path?: InputMaybe<LightCycleRecipeDeleteAtPathInput>;
  _delete_elem?: InputMaybe<LightCycleRecipeDeleteElemInput>;
  _delete_key?: InputMaybe<LightCycleRecipeDeleteKeyInput>;
  _inc?: InputMaybe<LightCycleRecipeIncInput>;
  _prepend?: InputMaybe<LightCycleRecipePrependInput>;
  _set?: InputMaybe<LightCycleRecipeSetInput>;
  pk_columns: LightCycleRecipePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLightCycleRecipeEntryArgs = {
  _inc?: InputMaybe<LightCycleRecipeEntryIncInput>;
  _set?: InputMaybe<LightCycleRecipeEntrySetInput>;
  where: LightCycleRecipeEntryBoolExp;
};


/** mutation root */
export type MutationRootUpdateLightCycleRecipeEntryByPkArgs = {
  _inc?: InputMaybe<LightCycleRecipeEntryIncInput>;
  _set?: InputMaybe<LightCycleRecipeEntrySetInput>;
  pk_columns: LightCycleRecipeEntryPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLightCycleRecipeEntryManyArgs = {
  updates: Array<LightCycleRecipeEntryUpdates>;
};


/** mutation root */
export type MutationRootUpdateLightCycleRecipeManyArgs = {
  updates: Array<LightCycleRecipeUpdates>;
};


/** mutation root */
export type MutationRootUpdateLocationArgs = {
  _append?: InputMaybe<LocationAppendInput>;
  _delete_at_path?: InputMaybe<LocationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<LocationDeleteElemInput>;
  _delete_key?: InputMaybe<LocationDeleteKeyInput>;
  _inc?: InputMaybe<LocationIncInput>;
  _prepend?: InputMaybe<LocationPrependInput>;
  _set?: InputMaybe<LocationSetInput>;
  where: LocationBoolExp;
};


/** mutation root */
export type MutationRootUpdateLocationByPkArgs = {
  _append?: InputMaybe<LocationAppendInput>;
  _delete_at_path?: InputMaybe<LocationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<LocationDeleteElemInput>;
  _delete_key?: InputMaybe<LocationDeleteKeyInput>;
  _inc?: InputMaybe<LocationIncInput>;
  _prepend?: InputMaybe<LocationPrependInput>;
  _set?: InputMaybe<LocationSetInput>;
  pk_columns: LocationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLocationManyArgs = {
  updates: Array<LocationUpdates>;
};


/** mutation root */
export type MutationRootUpdateMeasurementArgs = {
  _append?: InputMaybe<MeasurementAppendInput>;
  _delete_at_path?: InputMaybe<MeasurementDeleteAtPathInput>;
  _delete_elem?: InputMaybe<MeasurementDeleteElemInput>;
  _delete_key?: InputMaybe<MeasurementDeleteKeyInput>;
  _inc?: InputMaybe<MeasurementIncInput>;
  _prepend?: InputMaybe<MeasurementPrependInput>;
  _set?: InputMaybe<MeasurementSetInput>;
  where: MeasurementBoolExp;
};


/** mutation root */
export type MutationRootUpdateMeasurementByPkArgs = {
  _append?: InputMaybe<MeasurementAppendInput>;
  _delete_at_path?: InputMaybe<MeasurementDeleteAtPathInput>;
  _delete_elem?: InputMaybe<MeasurementDeleteElemInput>;
  _delete_key?: InputMaybe<MeasurementDeleteKeyInput>;
  _inc?: InputMaybe<MeasurementIncInput>;
  _prepend?: InputMaybe<MeasurementPrependInput>;
  _set?: InputMaybe<MeasurementSetInput>;
  pk_columns: MeasurementPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMeasurementManyArgs = {
  updates: Array<MeasurementUpdates>;
};


/** mutation root */
export type MutationRootUpdateMeasurementRunArgs = {
  _append?: InputMaybe<MeasurementRunAppendInput>;
  _delete_at_path?: InputMaybe<MeasurementRunDeleteAtPathInput>;
  _delete_elem?: InputMaybe<MeasurementRunDeleteElemInput>;
  _delete_key?: InputMaybe<MeasurementRunDeleteKeyInput>;
  _inc?: InputMaybe<MeasurementRunIncInput>;
  _prepend?: InputMaybe<MeasurementRunPrependInput>;
  _set?: InputMaybe<MeasurementRunSetInput>;
  where: MeasurementRunBoolExp;
};


/** mutation root */
export type MutationRootUpdateMeasurementRunByPkArgs = {
  _append?: InputMaybe<MeasurementRunAppendInput>;
  _delete_at_path?: InputMaybe<MeasurementRunDeleteAtPathInput>;
  _delete_elem?: InputMaybe<MeasurementRunDeleteElemInput>;
  _delete_key?: InputMaybe<MeasurementRunDeleteKeyInput>;
  _inc?: InputMaybe<MeasurementRunIncInput>;
  _prepend?: InputMaybe<MeasurementRunPrependInput>;
  _set?: InputMaybe<MeasurementRunSetInput>;
  pk_columns: MeasurementRunPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateMeasurementRunManyArgs = {
  updates: Array<MeasurementRunUpdates>;
};


/** mutation root */
export type MutationRootUpdateNotificationArgs = {
  _append?: InputMaybe<NotificationAppendInput>;
  _delete_at_path?: InputMaybe<NotificationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<NotificationDeleteElemInput>;
  _delete_key?: InputMaybe<NotificationDeleteKeyInput>;
  _inc?: InputMaybe<NotificationIncInput>;
  _prepend?: InputMaybe<NotificationPrependInput>;
  _set?: InputMaybe<NotificationSetInput>;
  where: NotificationBoolExp;
};


/** mutation root */
export type MutationRootUpdateNotificationByPkArgs = {
  _append?: InputMaybe<NotificationAppendInput>;
  _delete_at_path?: InputMaybe<NotificationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<NotificationDeleteElemInput>;
  _delete_key?: InputMaybe<NotificationDeleteKeyInput>;
  _inc?: InputMaybe<NotificationIncInput>;
  _prepend?: InputMaybe<NotificationPrependInput>;
  _set?: InputMaybe<NotificationSetInput>;
  pk_columns: NotificationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateNotificationManyArgs = {
  updates: Array<NotificationUpdates>;
};


/** mutation root */
export type MutationRootUpdateOrganizationArgs = {
  _append?: InputMaybe<OrganizationAppendInput>;
  _delete_at_path?: InputMaybe<OrganizationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<OrganizationDeleteElemInput>;
  _delete_key?: InputMaybe<OrganizationDeleteKeyInput>;
  _inc?: InputMaybe<OrganizationIncInput>;
  _prepend?: InputMaybe<OrganizationPrependInput>;
  _set?: InputMaybe<OrganizationSetInput>;
  where: OrganizationBoolExp;
};


/** mutation root */
export type MutationRootUpdateOrganizationByPkArgs = {
  _append?: InputMaybe<OrganizationAppendInput>;
  _delete_at_path?: InputMaybe<OrganizationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<OrganizationDeleteElemInput>;
  _delete_key?: InputMaybe<OrganizationDeleteKeyInput>;
  _inc?: InputMaybe<OrganizationIncInput>;
  _prepend?: InputMaybe<OrganizationPrependInput>;
  _set?: InputMaybe<OrganizationSetInput>;
  pk_columns: OrganizationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOrganizationManyArgs = {
  updates: Array<OrganizationUpdates>;
};


/** mutation root */
export type MutationRootUpdateParametersArgs = {
  _append?: InputMaybe<ParametersAppendInput>;
  _delete_at_path?: InputMaybe<ParametersDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ParametersDeleteElemInput>;
  _delete_key?: InputMaybe<ParametersDeleteKeyInput>;
  _inc?: InputMaybe<ParametersIncInput>;
  _prepend?: InputMaybe<ParametersPrependInput>;
  _set?: InputMaybe<ParametersSetInput>;
  where: ParametersBoolExp;
};


/** mutation root */
export type MutationRootUpdateParametersByPkArgs = {
  _append?: InputMaybe<ParametersAppendInput>;
  _delete_at_path?: InputMaybe<ParametersDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ParametersDeleteElemInput>;
  _delete_key?: InputMaybe<ParametersDeleteKeyInput>;
  _inc?: InputMaybe<ParametersIncInput>;
  _prepend?: InputMaybe<ParametersPrependInput>;
  _set?: InputMaybe<ParametersSetInput>;
  pk_columns: ParametersPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateParametersManyArgs = {
  updates: Array<ParametersUpdates>;
};


/** mutation root */
export type MutationRootUpdatePoseArgs = {
  _inc?: InputMaybe<PoseIncInput>;
  _set?: InputMaybe<PoseSetInput>;
  where: PoseBoolExp;
};


/** mutation root */
export type MutationRootUpdatePoseByPkArgs = {
  _inc?: InputMaybe<PoseIncInput>;
  _set?: InputMaybe<PoseSetInput>;
  pk_columns: PosePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdatePoseManyArgs = {
  updates: Array<PoseUpdates>;
};


/** mutation root */
export type MutationRootUpdateProtoArgs = {
  _set?: InputMaybe<ProtoSetInput>;
  where: ProtoBoolExp;
};


/** mutation root */
export type MutationRootUpdateProtoByPkArgs = {
  _set?: InputMaybe<ProtoSetInput>;
  pk_columns: ProtoPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateProtoManyArgs = {
  updates: Array<ProtoUpdates>;
};


/** mutation root */
export type MutationRootUpdateRolePermissionArgs = {
  _append?: InputMaybe<RolePermissionAppendInput>;
  _delete_at_path?: InputMaybe<RolePermissionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<RolePermissionDeleteElemInput>;
  _delete_key?: InputMaybe<RolePermissionDeleteKeyInput>;
  _inc?: InputMaybe<RolePermissionIncInput>;
  _prepend?: InputMaybe<RolePermissionPrependInput>;
  _set?: InputMaybe<RolePermissionSetInput>;
  where: RolePermissionBoolExp;
};


/** mutation root */
export type MutationRootUpdateRolePermissionManyArgs = {
  updates: Array<RolePermissionUpdates>;
};


/** mutation root */
export type MutationRootUpdateSensorArgs = {
  _append?: InputMaybe<SensorAppendInput>;
  _delete_at_path?: InputMaybe<SensorDeleteAtPathInput>;
  _delete_elem?: InputMaybe<SensorDeleteElemInput>;
  _delete_key?: InputMaybe<SensorDeleteKeyInput>;
  _inc?: InputMaybe<SensorIncInput>;
  _prepend?: InputMaybe<SensorPrependInput>;
  _set?: InputMaybe<SensorSetInput>;
  where: SensorBoolExp;
};


/** mutation root */
export type MutationRootUpdateSensorByPkArgs = {
  _append?: InputMaybe<SensorAppendInput>;
  _delete_at_path?: InputMaybe<SensorDeleteAtPathInput>;
  _delete_elem?: InputMaybe<SensorDeleteElemInput>;
  _delete_key?: InputMaybe<SensorDeleteKeyInput>;
  _inc?: InputMaybe<SensorIncInput>;
  _prepend?: InputMaybe<SensorPrependInput>;
  _set?: InputMaybe<SensorSetInput>;
  pk_columns: SensorPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSensorManyArgs = {
  updates: Array<SensorUpdates>;
};


/** mutation root */
export type MutationRootUpdateSpatialRefSysArgs = {
  _inc?: InputMaybe<SpatialRefSysIncInput>;
  _set?: InputMaybe<SpatialRefSysSetInput>;
  where: SpatialRefSysBoolExp;
};


/** mutation root */
export type MutationRootUpdateSpatialRefSysByPkArgs = {
  _inc?: InputMaybe<SpatialRefSysIncInput>;
  _set?: InputMaybe<SpatialRefSysSetInput>;
  pk_columns: SpatialRefSysPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSpatialRefSysManyArgs = {
  updates: Array<SpatialRefSysUpdates>;
};


/** mutation root */
export type MutationRootUpdateSystemArgs = {
  _append?: InputMaybe<SystemAppendInput>;
  _delete_at_path?: InputMaybe<SystemDeleteAtPathInput>;
  _delete_elem?: InputMaybe<SystemDeleteElemInput>;
  _delete_key?: InputMaybe<SystemDeleteKeyInput>;
  _inc?: InputMaybe<SystemIncInput>;
  _prepend?: InputMaybe<SystemPrependInput>;
  _set?: InputMaybe<SystemSetInput>;
  where: SystemBoolExp;
};


/** mutation root */
export type MutationRootUpdateSystemByPkArgs = {
  _append?: InputMaybe<SystemAppendInput>;
  _delete_at_path?: InputMaybe<SystemDeleteAtPathInput>;
  _delete_elem?: InputMaybe<SystemDeleteElemInput>;
  _delete_key?: InputMaybe<SystemDeleteKeyInput>;
  _inc?: InputMaybe<SystemIncInput>;
  _prepend?: InputMaybe<SystemPrependInput>;
  _set?: InputMaybe<SystemSetInput>;
  pk_columns: SystemPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateSystemManyArgs = {
  updates: Array<SystemUpdates>;
};


/** mutation root */
export type MutationRootUpdateTrackArgs = {
  _append?: InputMaybe<TrackAppendInput>;
  _delete_at_path?: InputMaybe<TrackDeleteAtPathInput>;
  _delete_elem?: InputMaybe<TrackDeleteElemInput>;
  _delete_key?: InputMaybe<TrackDeleteKeyInput>;
  _inc?: InputMaybe<TrackIncInput>;
  _prepend?: InputMaybe<TrackPrependInput>;
  _set?: InputMaybe<TrackSetInput>;
  where: TrackBoolExp;
};


/** mutation root */
export type MutationRootUpdateTrackByPkArgs = {
  _append?: InputMaybe<TrackAppendInput>;
  _delete_at_path?: InputMaybe<TrackDeleteAtPathInput>;
  _delete_elem?: InputMaybe<TrackDeleteElemInput>;
  _delete_key?: InputMaybe<TrackDeleteKeyInput>;
  _inc?: InputMaybe<TrackIncInput>;
  _prepend?: InputMaybe<TrackPrependInput>;
  _set?: InputMaybe<TrackSetInput>;
  pk_columns: TrackPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTrackDetectionsArgs = {
  _inc?: InputMaybe<TrackDetectionsIncInput>;
  _set?: InputMaybe<TrackDetectionsSetInput>;
  where: TrackDetectionsBoolExp;
};


/** mutation root */
export type MutationRootUpdateTrackDetectionsByPkArgs = {
  _inc?: InputMaybe<TrackDetectionsIncInput>;
  _set?: InputMaybe<TrackDetectionsSetInput>;
  pk_columns: TrackDetectionsPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTrackDetectionsManyArgs = {
  updates: Array<TrackDetectionsUpdates>;
};


/** mutation root */
export type MutationRootUpdateTrackManyArgs = {
  updates: Array<TrackUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserArgs = {
  _append?: InputMaybe<UserAppendInput>;
  _delete_at_path?: InputMaybe<UserDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UserDeleteElemInput>;
  _delete_key?: InputMaybe<UserDeleteKeyInput>;
  _inc?: InputMaybe<UserIncInput>;
  _prepend?: InputMaybe<UserPrependInput>;
  _set?: InputMaybe<UserSetInput>;
  where: UserBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserByPkArgs = {
  _append?: InputMaybe<UserAppendInput>;
  _delete_at_path?: InputMaybe<UserDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UserDeleteElemInput>;
  _delete_key?: InputMaybe<UserDeleteKeyInput>;
  _inc?: InputMaybe<UserIncInput>;
  _prepend?: InputMaybe<UserPrependInput>;
  _set?: InputMaybe<UserSetInput>;
  pk_columns: UserPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserCommentArgs = {
  _inc?: InputMaybe<UserCommentIncInput>;
  _set?: InputMaybe<UserCommentSetInput>;
  where: UserCommentBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserCommentByPkArgs = {
  _inc?: InputMaybe<UserCommentIncInput>;
  _set?: InputMaybe<UserCommentSetInput>;
  pk_columns: UserCommentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserCommentManyArgs = {
  updates: Array<UserCommentUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserLabelArgs = {
  _append?: InputMaybe<UserLabelAppendInput>;
  _delete_at_path?: InputMaybe<UserLabelDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UserLabelDeleteElemInput>;
  _delete_key?: InputMaybe<UserLabelDeleteKeyInput>;
  _inc?: InputMaybe<UserLabelIncInput>;
  _prepend?: InputMaybe<UserLabelPrependInput>;
  _set?: InputMaybe<UserLabelSetInput>;
  where: UserLabelBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserLabelByPkArgs = {
  _append?: InputMaybe<UserLabelAppendInput>;
  _delete_at_path?: InputMaybe<UserLabelDeleteAtPathInput>;
  _delete_elem?: InputMaybe<UserLabelDeleteElemInput>;
  _delete_key?: InputMaybe<UserLabelDeleteKeyInput>;
  _inc?: InputMaybe<UserLabelIncInput>;
  _prepend?: InputMaybe<UserLabelPrependInput>;
  _set?: InputMaybe<UserLabelSetInput>;
  pk_columns: UserLabelPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserLabelManyArgs = {
  updates: Array<UserLabelUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserManyArgs = {
  updates: Array<UserUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserNotificationArgs = {
  _inc?: InputMaybe<UserNotificationIncInput>;
  _set?: InputMaybe<UserNotificationSetInput>;
  where: UserNotificationBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserNotificationByPkArgs = {
  _inc?: InputMaybe<UserNotificationIncInput>;
  _set?: InputMaybe<UserNotificationSetInput>;
  pk_columns: UserNotificationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserNotificationManyArgs = {
  updates: Array<UserNotificationUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserNotificationSettingArgs = {
  _inc?: InputMaybe<UserNotificationSettingIncInput>;
  _set?: InputMaybe<UserNotificationSettingSetInput>;
  where: UserNotificationSettingBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserNotificationSettingByPkArgs = {
  _inc?: InputMaybe<UserNotificationSettingIncInput>;
  _set?: InputMaybe<UserNotificationSettingSetInput>;
  pk_columns: UserNotificationSettingPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserNotificationSettingManyArgs = {
  updates: Array<UserNotificationSettingUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserOrganizationRoleArgs = {
  _inc?: InputMaybe<UserOrganizationRoleIncInput>;
  _set?: InputMaybe<UserOrganizationRoleSetInput>;
  where: UserOrganizationRoleBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserOrganizationRoleByPkArgs = {
  _inc?: InputMaybe<UserOrganizationRoleIncInput>;
  _set?: InputMaybe<UserOrganizationRoleSetInput>;
  pk_columns: UserOrganizationRolePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserOrganizationRoleManyArgs = {
  updates: Array<UserOrganizationRoleUpdates>;
};


/** mutation root */
export type MutationRootUpdateVersionArgs = {
  _append?: InputMaybe<VersionAppendInput>;
  _delete_at_path?: InputMaybe<VersionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<VersionDeleteElemInput>;
  _delete_key?: InputMaybe<VersionDeleteKeyInput>;
  _inc?: InputMaybe<VersionIncInput>;
  _prepend?: InputMaybe<VersionPrependInput>;
  _set?: InputMaybe<VersionSetInput>;
  where: VersionBoolExp;
};


/** mutation root */
export type MutationRootUpdateVersionByPkArgs = {
  _append?: InputMaybe<VersionAppendInput>;
  _delete_at_path?: InputMaybe<VersionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<VersionDeleteElemInput>;
  _delete_key?: InputMaybe<VersionDeleteKeyInput>;
  _inc?: InputMaybe<VersionIncInput>;
  _prepend?: InputMaybe<VersionPrependInput>;
  _set?: InputMaybe<VersionSetInput>;
  pk_columns: VersionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateVersionManyArgs = {
  updates: Array<VersionUpdates>;
};


/** mutation root */
export type MutationRootUpdateZoneArgs = {
  _append?: InputMaybe<ZoneAppendInput>;
  _delete_at_path?: InputMaybe<ZoneDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ZoneDeleteElemInput>;
  _delete_key?: InputMaybe<ZoneDeleteKeyInput>;
  _inc?: InputMaybe<ZoneIncInput>;
  _prepend?: InputMaybe<ZonePrependInput>;
  _set?: InputMaybe<ZoneSetInput>;
  where: ZoneBoolExp;
};


/** mutation root */
export type MutationRootUpdateZoneByPkArgs = {
  _append?: InputMaybe<ZoneAppendInput>;
  _delete_at_path?: InputMaybe<ZoneDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ZoneDeleteElemInput>;
  _delete_key?: InputMaybe<ZoneDeleteKeyInput>;
  _inc?: InputMaybe<ZoneIncInput>;
  _prepend?: InputMaybe<ZonePrependInput>;
  _set?: InputMaybe<ZoneSetInput>;
  pk_columns: ZonePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateZoneManyArgs = {
  updates: Array<ZoneUpdates>;
};

/** Boolean expression to compare columns of type "name". All fields are combined with logical 'AND'. */
export type NameComparisonExp = {
  _eq?: InputMaybe<Scalars['name']['input']>;
  _gt?: InputMaybe<Scalars['name']['input']>;
  _gte?: InputMaybe<Scalars['name']['input']>;
  _in?: InputMaybe<Array<Scalars['name']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['name']['input']>;
  _lte?: InputMaybe<Scalars['name']['input']>;
  _neq?: InputMaybe<Scalars['name']['input']>;
  _nin?: InputMaybe<Array<Scalars['name']['input']>>;
};

/** columns and relationships of "notification" */
export type Notification = {
  __typename?: 'notification';
  created_time: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  lambda_source_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  lambda_version?: Maybe<LambdaVersion>;
  location_id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  notification_type: Scalars['String']['output'];
  /** An array relationship */
  user_notifications: Array<UserNotification>;
  /** An aggregate relationship */
  user_notifications_aggregate: UserNotificationAggregate;
  zone_uid?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "notification" */
export type NotificationMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "notification" */
export type NotificationUserNotificationsArgs = {
  distinct_on?: InputMaybe<Array<UserNotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserNotificationOrderBy>>;
  where?: InputMaybe<UserNotificationBoolExp>;
};


/** columns and relationships of "notification" */
export type NotificationUserNotificationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserNotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserNotificationOrderBy>>;
  where?: InputMaybe<UserNotificationBoolExp>;
};

/** aggregated selection of "notification" */
export type NotificationAggregate = {
  __typename?: 'notification_aggregate';
  aggregate?: Maybe<NotificationAggregateFields>;
  nodes: Array<Notification>;
};

export type NotificationAggregateBoolExp = {
  count?: InputMaybe<NotificationAggregateBoolExpCount>;
};

export type NotificationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<NotificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<NotificationBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "notification" */
export type NotificationAggregateFields = {
  __typename?: 'notification_aggregate_fields';
  avg?: Maybe<NotificationAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<NotificationMaxFields>;
  min?: Maybe<NotificationMinFields>;
  stddev?: Maybe<NotificationStddevFields>;
  stddev_pop?: Maybe<NotificationStddevPopFields>;
  stddev_samp?: Maybe<NotificationStddevSampFields>;
  sum?: Maybe<NotificationSumFields>;
  var_pop?: Maybe<NotificationVarPopFields>;
  var_samp?: Maybe<NotificationVarSampFields>;
  variance?: Maybe<NotificationVarianceFields>;
};


/** aggregate fields of "notification" */
export type NotificationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<NotificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "notification" */
export type NotificationAggregateOrderBy = {
  avg?: InputMaybe<NotificationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<NotificationMaxOrderBy>;
  min?: InputMaybe<NotificationMinOrderBy>;
  stddev?: InputMaybe<NotificationStddevOrderBy>;
  stddev_pop?: InputMaybe<NotificationStddevPopOrderBy>;
  stddev_samp?: InputMaybe<NotificationStddevSampOrderBy>;
  sum?: InputMaybe<NotificationSumOrderBy>;
  var_pop?: InputMaybe<NotificationVarPopOrderBy>;
  var_samp?: InputMaybe<NotificationVarSampOrderBy>;
  variance?: InputMaybe<NotificationVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type NotificationAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "notification" */
export type NotificationArrRelInsertInput = {
  data: Array<NotificationInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<NotificationOnConflict>;
};

/** aggregate avg on columns */
export type NotificationAvgFields = {
  __typename?: 'notification_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  lambda_source_id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "notification" */
export type NotificationAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "notification". All fields are combined with a logical 'AND'. */
export type NotificationBoolExp = {
  _and?: InputMaybe<Array<NotificationBoolExp>>;
  _not?: InputMaybe<NotificationBoolExp>;
  _or?: InputMaybe<Array<NotificationBoolExp>>;
  created_time?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  lambda_source_id?: InputMaybe<IntComparisonExp>;
  lambda_version?: InputMaybe<LambdaVersionBoolExp>;
  location_id?: InputMaybe<IntComparisonExp>;
  message?: InputMaybe<StringComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  notification_type?: InputMaybe<StringComparisonExp>;
  user_notifications?: InputMaybe<UserNotificationBoolExp>;
  user_notifications_aggregate?: InputMaybe<UserNotificationAggregateBoolExp>;
  zone_uid?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "notification" */
export enum NotificationConstraint {
  /** unique or primary key constraint on columns "id" */
  notification_pkey = 'notification_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type NotificationDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type NotificationDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type NotificationDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "notification" */
export type NotificationIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  lambda_source_id?: InputMaybe<Scalars['Int']['input']>;
  location_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "notification" */
export type NotificationInsertInput = {
  created_time?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lambda_source_id?: InputMaybe<Scalars['Int']['input']>;
  lambda_version?: InputMaybe<LambdaVersionObjRelInsertInput>;
  location_id?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  notification_type?: InputMaybe<Scalars['String']['input']>;
  user_notifications?: InputMaybe<UserNotificationArrRelInsertInput>;
  zone_uid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type NotificationMaxFields = {
  __typename?: 'notification_max_fields';
  created_time?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lambda_source_id?: Maybe<Scalars['Int']['output']>;
  location_id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  notification_type?: Maybe<Scalars['String']['output']>;
  zone_uid?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "notification" */
export type NotificationMaxOrderBy = {
  created_time?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  message?: InputMaybe<OrderBy>;
  notification_type?: InputMaybe<OrderBy>;
  zone_uid?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type NotificationMinFields = {
  __typename?: 'notification_min_fields';
  created_time?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  lambda_source_id?: Maybe<Scalars['Int']['output']>;
  location_id?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  notification_type?: Maybe<Scalars['String']['output']>;
  zone_uid?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "notification" */
export type NotificationMinOrderBy = {
  created_time?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  message?: InputMaybe<OrderBy>;
  notification_type?: InputMaybe<OrderBy>;
  zone_uid?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "notification" */
export type NotificationMutationResponse = {
  __typename?: 'notification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification>;
};

/** input type for inserting object relation for remote table "notification" */
export type NotificationObjRelInsertInput = {
  data: NotificationInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<NotificationOnConflict>;
};

/** on_conflict condition type for table "notification" */
export type NotificationOnConflict = {
  constraint: NotificationConstraint;
  update_columns?: Array<NotificationUpdateColumn>;
  where?: InputMaybe<NotificationBoolExp>;
};

/** Ordering options when selecting data from "notification". */
export type NotificationOrderBy = {
  created_time?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  lambda_version?: InputMaybe<LambdaVersionOrderBy>;
  location_id?: InputMaybe<OrderBy>;
  message?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  notification_type?: InputMaybe<OrderBy>;
  user_notifications_aggregate?: InputMaybe<UserNotificationAggregateOrderBy>;
  zone_uid?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: notification */
export type NotificationPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type NotificationPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "notification" */
export enum NotificationSelectColumn {
  /** column name */
  created_time = 'created_time',
  /** column name */
  id = 'id',
  /** column name */
  lambda_source_id = 'lambda_source_id',
  /** column name */
  location_id = 'location_id',
  /** column name */
  message = 'message',
  /** column name */
  metadata = 'metadata',
  /** column name */
  notification_type = 'notification_type',
  /** column name */
  zone_uid = 'zone_uid'
}

/** input type for updating data in table "notification" */
export type NotificationSetInput = {
  created_time?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lambda_source_id?: InputMaybe<Scalars['Int']['input']>;
  location_id?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  notification_type?: InputMaybe<Scalars['String']['input']>;
  zone_uid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type NotificationStddevFields = {
  __typename?: 'notification_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  lambda_source_id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "notification" */
export type NotificationStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type NotificationStddevPopFields = {
  __typename?: 'notification_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  lambda_source_id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "notification" */
export type NotificationStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type NotificationStddevSampFields = {
  __typename?: 'notification_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  lambda_source_id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "notification" */
export type NotificationStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "notification" */
export type NotificationStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: NotificationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type NotificationStreamCursorValueInput = {
  created_time?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lambda_source_id?: InputMaybe<Scalars['Int']['input']>;
  location_id?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  notification_type?: InputMaybe<Scalars['String']['input']>;
  zone_uid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type NotificationSumFields = {
  __typename?: 'notification_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  lambda_source_id?: Maybe<Scalars['Int']['output']>;
  location_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "notification" */
export type NotificationSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** update columns of table "notification" */
export enum NotificationUpdateColumn {
  /** column name */
  created_time = 'created_time',
  /** column name */
  id = 'id',
  /** column name */
  lambda_source_id = 'lambda_source_id',
  /** column name */
  location_id = 'location_id',
  /** column name */
  message = 'message',
  /** column name */
  metadata = 'metadata',
  /** column name */
  notification_type = 'notification_type',
  /** column name */
  zone_uid = 'zone_uid'
}

export type NotificationUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<NotificationAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<NotificationDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<NotificationDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<NotificationDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<NotificationIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<NotificationPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<NotificationSetInput>;
  /** filter the rows which have to be updated */
  where: NotificationBoolExp;
};

/** aggregate var_pop on columns */
export type NotificationVarPopFields = {
  __typename?: 'notification_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  lambda_source_id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "notification" */
export type NotificationVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type NotificationVarSampFields = {
  __typename?: 'notification_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  lambda_source_id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "notification" */
export type NotificationVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type NotificationVarianceFields = {
  __typename?: 'notification_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  lambda_source_id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "notification" */
export type NotificationVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  lambda_source_id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  asc = 'asc',
  /** in ascending order, nulls first */
  asc_nulls_first = 'asc_nulls_first',
  /** in ascending order, nulls last */
  asc_nulls_last = 'asc_nulls_last',
  /** in descending order, nulls first */
  desc = 'desc',
  /** in descending order, nulls first */
  desc_nulls_first = 'desc_nulls_first',
  /** in descending order, nulls last */
  desc_nulls_last = 'desc_nulls_last'
}

/** columns and relationships of "organization" */
export type Organization = {
  __typename?: 'organization';
  /** An object relationship */
  address: Address;
  billing_address_id: Scalars['Int']['output'];
  /** An array relationship */
  computes: Array<Compute>;
  /** An aggregate relationship */
  computes_aggregate: ComputeAggregate;
  /** An object relationship */
  enumeration: Enumeration;
  /** An array relationship */
  environment_recipes: Array<EnvironmentRecipe>;
  /** An aggregate relationship */
  environment_recipes_aggregate: EnvironmentRecipeAggregate;
  id: Scalars['Int']['output'];
  is_active: Scalars['Boolean']['output'];
  /** An array relationship */
  light_cycle_recipes: Array<LightCycleRecipe>;
  /** An aggregate relationship */
  light_cycle_recipes_aggregate: LightCycleRecipeAggregate;
  /** An array relationship */
  locations: Array<Location>;
  /** An aggregate relationship */
  locations_aggregate: LocationAggregate;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  name_id: Scalars['Int']['output'];
  /** An array relationship */
  systems: Array<System>;
  /** An aggregate relationship */
  systems_aggregate: SystemAggregate;
  /** An array relationship */
  user_organization_roles: Array<UserOrganizationRole>;
  /** An aggregate relationship */
  user_organization_roles_aggregate: UserOrganizationRoleAggregate;
};


/** columns and relationships of "organization" */
export type OrganizationComputesArgs = {
  distinct_on?: InputMaybe<Array<ComputeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputeOrderBy>>;
  where?: InputMaybe<ComputeBoolExp>;
};


/** columns and relationships of "organization" */
export type OrganizationComputesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ComputeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputeOrderBy>>;
  where?: InputMaybe<ComputeBoolExp>;
};


/** columns and relationships of "organization" */
export type OrganizationEnvironmentRecipesArgs = {
  distinct_on?: InputMaybe<Array<EnvironmentRecipeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EnvironmentRecipeOrderBy>>;
  where?: InputMaybe<EnvironmentRecipeBoolExp>;
};


/** columns and relationships of "organization" */
export type OrganizationEnvironmentRecipesAggregateArgs = {
  distinct_on?: InputMaybe<Array<EnvironmentRecipeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EnvironmentRecipeOrderBy>>;
  where?: InputMaybe<EnvironmentRecipeBoolExp>;
};


/** columns and relationships of "organization" */
export type OrganizationLightCycleRecipesArgs = {
  distinct_on?: InputMaybe<Array<LightCycleRecipeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LightCycleRecipeOrderBy>>;
  where?: InputMaybe<LightCycleRecipeBoolExp>;
};


/** columns and relationships of "organization" */
export type OrganizationLightCycleRecipesAggregateArgs = {
  distinct_on?: InputMaybe<Array<LightCycleRecipeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LightCycleRecipeOrderBy>>;
  where?: InputMaybe<LightCycleRecipeBoolExp>;
};


/** columns and relationships of "organization" */
export type OrganizationLocationsArgs = {
  distinct_on?: InputMaybe<Array<LocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LocationOrderBy>>;
  where?: InputMaybe<LocationBoolExp>;
};


/** columns and relationships of "organization" */
export type OrganizationLocationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LocationOrderBy>>;
  where?: InputMaybe<LocationBoolExp>;
};


/** columns and relationships of "organization" */
export type OrganizationMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "organization" */
export type OrganizationSystemsArgs = {
  distinct_on?: InputMaybe<Array<SystemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SystemOrderBy>>;
  where?: InputMaybe<SystemBoolExp>;
};


/** columns and relationships of "organization" */
export type OrganizationSystemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<SystemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SystemOrderBy>>;
  where?: InputMaybe<SystemBoolExp>;
};


/** columns and relationships of "organization" */
export type OrganizationUserOrganizationRolesArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizationRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrganizationRoleOrderBy>>;
  where?: InputMaybe<UserOrganizationRoleBoolExp>;
};


/** columns and relationships of "organization" */
export type OrganizationUserOrganizationRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizationRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrganizationRoleOrderBy>>;
  where?: InputMaybe<UserOrganizationRoleBoolExp>;
};

/** aggregated selection of "organization" */
export type OrganizationAggregate = {
  __typename?: 'organization_aggregate';
  aggregate?: Maybe<OrganizationAggregateFields>;
  nodes: Array<Organization>;
};

export type OrganizationAggregateBoolExp = {
  bool_and?: InputMaybe<OrganizationAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<OrganizationAggregateBoolExpBoolOr>;
  count?: InputMaybe<OrganizationAggregateBoolExpCount>;
};

export type OrganizationAggregateBoolExpBoolAnd = {
  arguments: OrganizationSelectColumnOrganizationAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<OrganizationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type OrganizationAggregateBoolExpBoolOr = {
  arguments: OrganizationSelectColumnOrganizationAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<OrganizationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type OrganizationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<OrganizationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<OrganizationBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "organization" */
export type OrganizationAggregateFields = {
  __typename?: 'organization_aggregate_fields';
  avg?: Maybe<OrganizationAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<OrganizationMaxFields>;
  min?: Maybe<OrganizationMinFields>;
  stddev?: Maybe<OrganizationStddevFields>;
  stddev_pop?: Maybe<OrganizationStddevPopFields>;
  stddev_samp?: Maybe<OrganizationStddevSampFields>;
  sum?: Maybe<OrganizationSumFields>;
  var_pop?: Maybe<OrganizationVarPopFields>;
  var_samp?: Maybe<OrganizationVarSampFields>;
  variance?: Maybe<OrganizationVarianceFields>;
};


/** aggregate fields of "organization" */
export type OrganizationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OrganizationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "organization" */
export type OrganizationAggregateOrderBy = {
  avg?: InputMaybe<OrganizationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OrganizationMaxOrderBy>;
  min?: InputMaybe<OrganizationMinOrderBy>;
  stddev?: InputMaybe<OrganizationStddevOrderBy>;
  stddev_pop?: InputMaybe<OrganizationStddevPopOrderBy>;
  stddev_samp?: InputMaybe<OrganizationStddevSampOrderBy>;
  sum?: InputMaybe<OrganizationSumOrderBy>;
  var_pop?: InputMaybe<OrganizationVarPopOrderBy>;
  var_samp?: InputMaybe<OrganizationVarSampOrderBy>;
  variance?: InputMaybe<OrganizationVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type OrganizationAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "organization" */
export type OrganizationArrRelInsertInput = {
  data: Array<OrganizationInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<OrganizationOnConflict>;
};

/** aggregate avg on columns */
export type OrganizationAvgFields = {
  __typename?: 'organization_avg_fields';
  billing_address_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "organization" */
export type OrganizationAvgOrderBy = {
  billing_address_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "organization". All fields are combined with a logical 'AND'. */
export type OrganizationBoolExp = {
  _and?: InputMaybe<Array<OrganizationBoolExp>>;
  _not?: InputMaybe<OrganizationBoolExp>;
  _or?: InputMaybe<Array<OrganizationBoolExp>>;
  address?: InputMaybe<AddressBoolExp>;
  billing_address_id?: InputMaybe<IntComparisonExp>;
  computes?: InputMaybe<ComputeBoolExp>;
  computes_aggregate?: InputMaybe<ComputeAggregateBoolExp>;
  enumeration?: InputMaybe<EnumerationBoolExp>;
  environment_recipes?: InputMaybe<EnvironmentRecipeBoolExp>;
  environment_recipes_aggregate?: InputMaybe<EnvironmentRecipeAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  is_active?: InputMaybe<BooleanComparisonExp>;
  light_cycle_recipes?: InputMaybe<LightCycleRecipeBoolExp>;
  light_cycle_recipes_aggregate?: InputMaybe<LightCycleRecipeAggregateBoolExp>;
  locations?: InputMaybe<LocationBoolExp>;
  locations_aggregate?: InputMaybe<LocationAggregateBoolExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  name_id?: InputMaybe<IntComparisonExp>;
  systems?: InputMaybe<SystemBoolExp>;
  systems_aggregate?: InputMaybe<SystemAggregateBoolExp>;
  user_organization_roles?: InputMaybe<UserOrganizationRoleBoolExp>;
  user_organization_roles_aggregate?: InputMaybe<UserOrganizationRoleAggregateBoolExp>;
};

/** unique or primary key constraints on table "organization" */
export enum OrganizationConstraint {
  /** unique or primary key constraint on columns "id" */
  organization_pkey = 'organization_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type OrganizationDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type OrganizationDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type OrganizationDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "organization" */
export type OrganizationIncInput = {
  billing_address_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "organization" */
export type OrganizationInsertInput = {
  address?: InputMaybe<AddressObjRelInsertInput>;
  billing_address_id?: InputMaybe<Scalars['Int']['input']>;
  computes?: InputMaybe<ComputeArrRelInsertInput>;
  enumeration?: InputMaybe<EnumerationObjRelInsertInput>;
  environment_recipes?: InputMaybe<EnvironmentRecipeArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  light_cycle_recipes?: InputMaybe<LightCycleRecipeArrRelInsertInput>;
  locations?: InputMaybe<LocationArrRelInsertInput>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
  systems?: InputMaybe<SystemArrRelInsertInput>;
  user_organization_roles?: InputMaybe<UserOrganizationRoleArrRelInsertInput>;
};

/** aggregate max on columns */
export type OrganizationMaxFields = {
  __typename?: 'organization_max_fields';
  billing_address_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "organization" */
export type OrganizationMaxOrderBy = {
  billing_address_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type OrganizationMinFields = {
  __typename?: 'organization_min_fields';
  billing_address_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "organization" */
export type OrganizationMinOrderBy = {
  billing_address_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "organization" */
export type OrganizationMutationResponse = {
  __typename?: 'organization_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization>;
};

/** input type for inserting object relation for remote table "organization" */
export type OrganizationObjRelInsertInput = {
  data: OrganizationInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<OrganizationOnConflict>;
};

/** on_conflict condition type for table "organization" */
export type OrganizationOnConflict = {
  constraint: OrganizationConstraint;
  update_columns?: Array<OrganizationUpdateColumn>;
  where?: InputMaybe<OrganizationBoolExp>;
};

/** Ordering options when selecting data from "organization". */
export type OrganizationOrderBy = {
  address?: InputMaybe<AddressOrderBy>;
  billing_address_id?: InputMaybe<OrderBy>;
  computes_aggregate?: InputMaybe<ComputeAggregateOrderBy>;
  enumeration?: InputMaybe<EnumerationOrderBy>;
  environment_recipes_aggregate?: InputMaybe<EnvironmentRecipeAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  is_active?: InputMaybe<OrderBy>;
  light_cycle_recipes_aggregate?: InputMaybe<LightCycleRecipeAggregateOrderBy>;
  locations_aggregate?: InputMaybe<LocationAggregateOrderBy>;
  metadata?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  systems_aggregate?: InputMaybe<SystemAggregateOrderBy>;
  user_organization_roles_aggregate?: InputMaybe<UserOrganizationRoleAggregateOrderBy>;
};

/** primary key columns input for table: organization */
export type OrganizationPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type OrganizationPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "organization" */
export enum OrganizationSelectColumn {
  /** column name */
  billing_address_id = 'billing_address_id',
  /** column name */
  id = 'id',
  /** column name */
  is_active = 'is_active',
  /** column name */
  metadata = 'metadata',
  /** column name */
  name_id = 'name_id'
}

/** select "organization_aggregate_bool_exp_bool_and_arguments_columns" columns of table "organization" */
export enum OrganizationSelectColumnOrganizationAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  is_active = 'is_active'
}

/** select "organization_aggregate_bool_exp_bool_or_arguments_columns" columns of table "organization" */
export enum OrganizationSelectColumnOrganizationAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  is_active = 'is_active'
}

/** input type for updating data in table "organization" */
export type OrganizationSetInput = {
  billing_address_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type OrganizationStddevFields = {
  __typename?: 'organization_stddev_fields';
  billing_address_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "organization" */
export type OrganizationStddevOrderBy = {
  billing_address_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type OrganizationStddevPopFields = {
  __typename?: 'organization_stddev_pop_fields';
  billing_address_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "organization" */
export type OrganizationStddevPopOrderBy = {
  billing_address_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type OrganizationStddevSampFields = {
  __typename?: 'organization_stddev_samp_fields';
  billing_address_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "organization" */
export type OrganizationStddevSampOrderBy = {
  billing_address_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "organization" */
export type OrganizationStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OrganizationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OrganizationStreamCursorValueInput = {
  billing_address_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type OrganizationSumFields = {
  __typename?: 'organization_sum_fields';
  billing_address_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "organization" */
export type OrganizationSumOrderBy = {
  billing_address_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
};

/** update columns of table "organization" */
export enum OrganizationUpdateColumn {
  /** column name */
  billing_address_id = 'billing_address_id',
  /** column name */
  id = 'id',
  /** column name */
  is_active = 'is_active',
  /** column name */
  metadata = 'metadata',
  /** column name */
  name_id = 'name_id'
}

export type OrganizationUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<OrganizationAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<OrganizationDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<OrganizationDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<OrganizationDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<OrganizationIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<OrganizationPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OrganizationSetInput>;
  /** filter the rows which have to be updated */
  where: OrganizationBoolExp;
};

/** aggregate var_pop on columns */
export type OrganizationVarPopFields = {
  __typename?: 'organization_var_pop_fields';
  billing_address_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "organization" */
export type OrganizationVarPopOrderBy = {
  billing_address_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type OrganizationVarSampFields = {
  __typename?: 'organization_var_samp_fields';
  billing_address_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "organization" */
export type OrganizationVarSampOrderBy = {
  billing_address_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type OrganizationVarianceFields = {
  __typename?: 'organization_variance_fields';
  billing_address_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "organization" */
export type OrganizationVarianceOrderBy = {
  billing_address_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "parameters" */
export type Parameters = {
  __typename?: 'parameters';
  /** An object relationship */
  enumeration: Enumeration;
  id: Scalars['Int']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  type_id: Scalars['Int']['output'];
};


/** columns and relationships of "parameters" */
export type ParametersMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "parameters" */
export type ParametersAggregate = {
  __typename?: 'parameters_aggregate';
  aggregate?: Maybe<ParametersAggregateFields>;
  nodes: Array<Parameters>;
};

export type ParametersAggregateBoolExp = {
  count?: InputMaybe<ParametersAggregateBoolExpCount>;
};

export type ParametersAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ParametersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ParametersBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "parameters" */
export type ParametersAggregateFields = {
  __typename?: 'parameters_aggregate_fields';
  avg?: Maybe<ParametersAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ParametersMaxFields>;
  min?: Maybe<ParametersMinFields>;
  stddev?: Maybe<ParametersStddevFields>;
  stddev_pop?: Maybe<ParametersStddevPopFields>;
  stddev_samp?: Maybe<ParametersStddevSampFields>;
  sum?: Maybe<ParametersSumFields>;
  var_pop?: Maybe<ParametersVarPopFields>;
  var_samp?: Maybe<ParametersVarSampFields>;
  variance?: Maybe<ParametersVarianceFields>;
};


/** aggregate fields of "parameters" */
export type ParametersAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ParametersSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "parameters" */
export type ParametersAggregateOrderBy = {
  avg?: InputMaybe<ParametersAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ParametersMaxOrderBy>;
  min?: InputMaybe<ParametersMinOrderBy>;
  stddev?: InputMaybe<ParametersStddevOrderBy>;
  stddev_pop?: InputMaybe<ParametersStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ParametersStddevSampOrderBy>;
  sum?: InputMaybe<ParametersSumOrderBy>;
  var_pop?: InputMaybe<ParametersVarPopOrderBy>;
  var_samp?: InputMaybe<ParametersVarSampOrderBy>;
  variance?: InputMaybe<ParametersVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ParametersAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "parameters" */
export type ParametersArrRelInsertInput = {
  data: Array<ParametersInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ParametersOnConflict>;
};

/** aggregate avg on columns */
export type ParametersAvgFields = {
  __typename?: 'parameters_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "parameters" */
export type ParametersAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "parameters". All fields are combined with a logical 'AND'. */
export type ParametersBoolExp = {
  _and?: InputMaybe<Array<ParametersBoolExp>>;
  _not?: InputMaybe<ParametersBoolExp>;
  _or?: InputMaybe<Array<ParametersBoolExp>>;
  enumeration?: InputMaybe<EnumerationBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  type_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "parameters" */
export enum ParametersConstraint {
  /** unique or primary key constraint on columns "id" */
  parameters_pkey = 'parameters_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ParametersDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ParametersDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ParametersDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "parameters" */
export type ParametersIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "parameters" */
export type ParametersInsertInput = {
  enumeration?: InputMaybe<EnumerationObjRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type ParametersMaxFields = {
  __typename?: 'parameters_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "parameters" */
export type ParametersMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ParametersMinFields = {
  __typename?: 'parameters_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "parameters" */
export type ParametersMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "parameters" */
export type ParametersMutationResponse = {
  __typename?: 'parameters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Parameters>;
};

/** on_conflict condition type for table "parameters" */
export type ParametersOnConflict = {
  constraint: ParametersConstraint;
  update_columns?: Array<ParametersUpdateColumn>;
  where?: InputMaybe<ParametersBoolExp>;
};

/** Ordering options when selecting data from "parameters". */
export type ParametersOrderBy = {
  enumeration?: InputMaybe<EnumerationOrderBy>;
  id?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: parameters */
export type ParametersPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ParametersPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "parameters" */
export enum ParametersSelectColumn {
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  type_id = 'type_id'
}

/** input type for updating data in table "parameters" */
export type ParametersSetInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type ParametersStddevFields = {
  __typename?: 'parameters_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "parameters" */
export type ParametersStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ParametersStddevPopFields = {
  __typename?: 'parameters_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "parameters" */
export type ParametersStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ParametersStddevSampFields = {
  __typename?: 'parameters_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "parameters" */
export type ParametersStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "parameters" */
export type ParametersStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ParametersStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ParametersStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  type_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type ParametersSumFields = {
  __typename?: 'parameters_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  type_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "parameters" */
export type ParametersSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** update columns of table "parameters" */
export enum ParametersUpdateColumn {
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  type_id = 'type_id'
}

export type ParametersUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ParametersAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ParametersDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ParametersDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ParametersDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ParametersIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ParametersPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ParametersSetInput>;
  /** filter the rows which have to be updated */
  where: ParametersBoolExp;
};

/** aggregate var_pop on columns */
export type ParametersVarPopFields = {
  __typename?: 'parameters_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "parameters" */
export type ParametersVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ParametersVarSampFields = {
  __typename?: 'parameters_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "parameters" */
export type ParametersVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ParametersVarianceFields = {
  __typename?: 'parameters_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  type_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "parameters" */
export type ParametersVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  type_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "pose" */
export type Pose = {
  __typename?: 'pose';
  /** An array relationship */
  computed_measurements: Array<ComputedMeasurement>;
  /** An aggregate relationship */
  computed_measurements_aggregate: ComputedMeasurementAggregate;
  coordinates: Scalars['geometry']['output'];
  /** An array relationship */
  grid_elements: Array<GridElement>;
  /** An aggregate relationship */
  grid_elements_aggregate: GridElementAggregate;
  id: Scalars['Int']['output'];
  /** An array relationship */
  measurements: Array<Measurement>;
  /** An aggregate relationship */
  measurements_aggregate: MeasurementAggregate;
  rotation_vector_x?: Maybe<Scalars['Float']['output']>;
  rotation_vector_y?: Maybe<Scalars['Float']['output']>;
  rotation_vector_z?: Maybe<Scalars['Float']['output']>;
};


/** columns and relationships of "pose" */
export type PoseComputedMeasurementsArgs = {
  distinct_on?: InputMaybe<Array<ComputedMeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputedMeasurementOrderBy>>;
  where?: InputMaybe<ComputedMeasurementBoolExp>;
};


/** columns and relationships of "pose" */
export type PoseComputedMeasurementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ComputedMeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputedMeasurementOrderBy>>;
  where?: InputMaybe<ComputedMeasurementBoolExp>;
};


/** columns and relationships of "pose" */
export type PoseGridElementsArgs = {
  distinct_on?: InputMaybe<Array<GridElementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GridElementOrderBy>>;
  where?: InputMaybe<GridElementBoolExp>;
};


/** columns and relationships of "pose" */
export type PoseGridElementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<GridElementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GridElementOrderBy>>;
  where?: InputMaybe<GridElementBoolExp>;
};


/** columns and relationships of "pose" */
export type PoseMeasurementsArgs = {
  distinct_on?: InputMaybe<Array<MeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementOrderBy>>;
  where?: InputMaybe<MeasurementBoolExp>;
};


/** columns and relationships of "pose" */
export type PoseMeasurementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementOrderBy>>;
  where?: InputMaybe<MeasurementBoolExp>;
};

/** aggregated selection of "pose" */
export type PoseAggregate = {
  __typename?: 'pose_aggregate';
  aggregate?: Maybe<PoseAggregateFields>;
  nodes: Array<Pose>;
};

/** aggregate fields of "pose" */
export type PoseAggregateFields = {
  __typename?: 'pose_aggregate_fields';
  avg?: Maybe<PoseAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<PoseMaxFields>;
  min?: Maybe<PoseMinFields>;
  stddev?: Maybe<PoseStddevFields>;
  stddev_pop?: Maybe<PoseStddevPopFields>;
  stddev_samp?: Maybe<PoseStddevSampFields>;
  sum?: Maybe<PoseSumFields>;
  var_pop?: Maybe<PoseVarPopFields>;
  var_samp?: Maybe<PoseVarSampFields>;
  variance?: Maybe<PoseVarianceFields>;
};


/** aggregate fields of "pose" */
export type PoseAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PoseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type PoseAvgFields = {
  __typename?: 'pose_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rotation_vector_x?: Maybe<Scalars['Float']['output']>;
  rotation_vector_y?: Maybe<Scalars['Float']['output']>;
  rotation_vector_z?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "pose". All fields are combined with a logical 'AND'. */
export type PoseBoolExp = {
  _and?: InputMaybe<Array<PoseBoolExp>>;
  _not?: InputMaybe<PoseBoolExp>;
  _or?: InputMaybe<Array<PoseBoolExp>>;
  computed_measurements?: InputMaybe<ComputedMeasurementBoolExp>;
  computed_measurements_aggregate?: InputMaybe<ComputedMeasurementAggregateBoolExp>;
  coordinates?: InputMaybe<GeometryComparisonExp>;
  grid_elements?: InputMaybe<GridElementBoolExp>;
  grid_elements_aggregate?: InputMaybe<GridElementAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  measurements?: InputMaybe<MeasurementBoolExp>;
  measurements_aggregate?: InputMaybe<MeasurementAggregateBoolExp>;
  rotation_vector_x?: InputMaybe<FloatComparisonExp>;
  rotation_vector_y?: InputMaybe<FloatComparisonExp>;
  rotation_vector_z?: InputMaybe<FloatComparisonExp>;
};

/** unique or primary key constraints on table "pose" */
export enum PoseConstraint {
  /** unique or primary key constraint on columns "id" */
  pose_pkey = 'pose_pkey'
}

/** input type for incrementing numeric columns in table "pose" */
export type PoseIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  rotation_vector_x?: InputMaybe<Scalars['Float']['input']>;
  rotation_vector_y?: InputMaybe<Scalars['Float']['input']>;
  rotation_vector_z?: InputMaybe<Scalars['Float']['input']>;
};

/** input type for inserting data into table "pose" */
export type PoseInsertInput = {
  computed_measurements?: InputMaybe<ComputedMeasurementArrRelInsertInput>;
  coordinates?: InputMaybe<Scalars['geometry']['input']>;
  grid_elements?: InputMaybe<GridElementArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  measurements?: InputMaybe<MeasurementArrRelInsertInput>;
  rotation_vector_x?: InputMaybe<Scalars['Float']['input']>;
  rotation_vector_y?: InputMaybe<Scalars['Float']['input']>;
  rotation_vector_z?: InputMaybe<Scalars['Float']['input']>;
};

/** aggregate max on columns */
export type PoseMaxFields = {
  __typename?: 'pose_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  rotation_vector_x?: Maybe<Scalars['Float']['output']>;
  rotation_vector_y?: Maybe<Scalars['Float']['output']>;
  rotation_vector_z?: Maybe<Scalars['Float']['output']>;
};

/** aggregate min on columns */
export type PoseMinFields = {
  __typename?: 'pose_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  rotation_vector_x?: Maybe<Scalars['Float']['output']>;
  rotation_vector_y?: Maybe<Scalars['Float']['output']>;
  rotation_vector_z?: Maybe<Scalars['Float']['output']>;
};

/** response of any mutation on the table "pose" */
export type PoseMutationResponse = {
  __typename?: 'pose_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Pose>;
};

/** input type for inserting object relation for remote table "pose" */
export type PoseObjRelInsertInput = {
  data: PoseInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<PoseOnConflict>;
};

/** on_conflict condition type for table "pose" */
export type PoseOnConflict = {
  constraint: PoseConstraint;
  update_columns?: Array<PoseUpdateColumn>;
  where?: InputMaybe<PoseBoolExp>;
};

/** Ordering options when selecting data from "pose". */
export type PoseOrderBy = {
  computed_measurements_aggregate?: InputMaybe<ComputedMeasurementAggregateOrderBy>;
  coordinates?: InputMaybe<OrderBy>;
  grid_elements_aggregate?: InputMaybe<GridElementAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  measurements_aggregate?: InputMaybe<MeasurementAggregateOrderBy>;
  rotation_vector_x?: InputMaybe<OrderBy>;
  rotation_vector_y?: InputMaybe<OrderBy>;
  rotation_vector_z?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: pose */
export type PosePkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** select columns of table "pose" */
export enum PoseSelectColumn {
  /** column name */
  coordinates = 'coordinates',
  /** column name */
  id = 'id',
  /** column name */
  rotation_vector_x = 'rotation_vector_x',
  /** column name */
  rotation_vector_y = 'rotation_vector_y',
  /** column name */
  rotation_vector_z = 'rotation_vector_z'
}

/** input type for updating data in table "pose" */
export type PoseSetInput = {
  coordinates?: InputMaybe<Scalars['geometry']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  rotation_vector_x?: InputMaybe<Scalars['Float']['input']>;
  rotation_vector_y?: InputMaybe<Scalars['Float']['input']>;
  rotation_vector_z?: InputMaybe<Scalars['Float']['input']>;
};

/** aggregate stddev on columns */
export type PoseStddevFields = {
  __typename?: 'pose_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rotation_vector_x?: Maybe<Scalars['Float']['output']>;
  rotation_vector_y?: Maybe<Scalars['Float']['output']>;
  rotation_vector_z?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type PoseStddevPopFields = {
  __typename?: 'pose_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rotation_vector_x?: Maybe<Scalars['Float']['output']>;
  rotation_vector_y?: Maybe<Scalars['Float']['output']>;
  rotation_vector_z?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type PoseStddevSampFields = {
  __typename?: 'pose_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rotation_vector_x?: Maybe<Scalars['Float']['output']>;
  rotation_vector_y?: Maybe<Scalars['Float']['output']>;
  rotation_vector_z?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "pose" */
export type PoseStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PoseStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PoseStreamCursorValueInput = {
  coordinates?: InputMaybe<Scalars['geometry']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  rotation_vector_x?: InputMaybe<Scalars['Float']['input']>;
  rotation_vector_y?: InputMaybe<Scalars['Float']['input']>;
  rotation_vector_z?: InputMaybe<Scalars['Float']['input']>;
};

/** aggregate sum on columns */
export type PoseSumFields = {
  __typename?: 'pose_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  rotation_vector_x?: Maybe<Scalars['Float']['output']>;
  rotation_vector_y?: Maybe<Scalars['Float']['output']>;
  rotation_vector_z?: Maybe<Scalars['Float']['output']>;
};

/** update columns of table "pose" */
export enum PoseUpdateColumn {
  /** column name */
  coordinates = 'coordinates',
  /** column name */
  id = 'id',
  /** column name */
  rotation_vector_x = 'rotation_vector_x',
  /** column name */
  rotation_vector_y = 'rotation_vector_y',
  /** column name */
  rotation_vector_z = 'rotation_vector_z'
}

export type PoseUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<PoseIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<PoseSetInput>;
  /** filter the rows which have to be updated */
  where: PoseBoolExp;
};

/** aggregate var_pop on columns */
export type PoseVarPopFields = {
  __typename?: 'pose_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rotation_vector_x?: Maybe<Scalars['Float']['output']>;
  rotation_vector_y?: Maybe<Scalars['Float']['output']>;
  rotation_vector_z?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type PoseVarSampFields = {
  __typename?: 'pose_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rotation_vector_x?: Maybe<Scalars['Float']['output']>;
  rotation_vector_y?: Maybe<Scalars['Float']['output']>;
  rotation_vector_z?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type PoseVarianceFields = {
  __typename?: 'pose_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  rotation_vector_x?: Maybe<Scalars['Float']['output']>;
  rotation_vector_y?: Maybe<Scalars['Float']['output']>;
  rotation_vector_z?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "proto" */
export type Proto = {
  __typename?: 'proto';
  filename: Scalars['String']['output'];
  processed_time?: Maybe<Scalars['timestamptz']['output']>;
  proto_start_time: Scalars['timestamptz']['output'];
};

/** aggregated selection of "proto" */
export type ProtoAggregate = {
  __typename?: 'proto_aggregate';
  aggregate?: Maybe<ProtoAggregateFields>;
  nodes: Array<Proto>;
};

/** aggregate fields of "proto" */
export type ProtoAggregateFields = {
  __typename?: 'proto_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ProtoMaxFields>;
  min?: Maybe<ProtoMinFields>;
};


/** aggregate fields of "proto" */
export type ProtoAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ProtoSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "proto". All fields are combined with a logical 'AND'. */
export type ProtoBoolExp = {
  _and?: InputMaybe<Array<ProtoBoolExp>>;
  _not?: InputMaybe<ProtoBoolExp>;
  _or?: InputMaybe<Array<ProtoBoolExp>>;
  filename?: InputMaybe<StringComparisonExp>;
  processed_time?: InputMaybe<TimestamptzComparisonExp>;
  proto_start_time?: InputMaybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "proto" */
export enum ProtoConstraint {
  /** unique or primary key constraint on columns "filename" */
  proto_pkey = 'proto_pkey'
}

/** input type for inserting data into table "proto" */
export type ProtoInsertInput = {
  filename?: InputMaybe<Scalars['String']['input']>;
  processed_time?: InputMaybe<Scalars['timestamptz']['input']>;
  proto_start_time?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type ProtoMaxFields = {
  __typename?: 'proto_max_fields';
  filename?: Maybe<Scalars['String']['output']>;
  processed_time?: Maybe<Scalars['timestamptz']['output']>;
  proto_start_time?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type ProtoMinFields = {
  __typename?: 'proto_min_fields';
  filename?: Maybe<Scalars['String']['output']>;
  processed_time?: Maybe<Scalars['timestamptz']['output']>;
  proto_start_time?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "proto" */
export type ProtoMutationResponse = {
  __typename?: 'proto_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Proto>;
};

/** on_conflict condition type for table "proto" */
export type ProtoOnConflict = {
  constraint: ProtoConstraint;
  update_columns?: Array<ProtoUpdateColumn>;
  where?: InputMaybe<ProtoBoolExp>;
};

/** Ordering options when selecting data from "proto". */
export type ProtoOrderBy = {
  filename?: InputMaybe<OrderBy>;
  processed_time?: InputMaybe<OrderBy>;
  proto_start_time?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: proto */
export type ProtoPkColumnsInput = {
  filename: Scalars['String']['input'];
};

/** select columns of table "proto" */
export enum ProtoSelectColumn {
  /** column name */
  filename = 'filename',
  /** column name */
  processed_time = 'processed_time',
  /** column name */
  proto_start_time = 'proto_start_time'
}

/** input type for updating data in table "proto" */
export type ProtoSetInput = {
  filename?: InputMaybe<Scalars['String']['input']>;
  processed_time?: InputMaybe<Scalars['timestamptz']['input']>;
  proto_start_time?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "proto" */
export type ProtoStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ProtoStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProtoStreamCursorValueInput = {
  filename?: InputMaybe<Scalars['String']['input']>;
  processed_time?: InputMaybe<Scalars['timestamptz']['input']>;
  proto_start_time?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "proto" */
export enum ProtoUpdateColumn {
  /** column name */
  filename = 'filename',
  /** column name */
  processed_time = 'processed_time',
  /** column name */
  proto_start_time = 'proto_start_time'
}

export type ProtoUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ProtoSetInput>;
  /** filter the rows which have to be updated */
  where: ProtoBoolExp;
};

export type QueryRoot = {
  __typename?: 'query_root';
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: AddressAggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table: "aggregate_heatmap" */
  aggregate_heatmap: Array<AggregateHeatmap>;
  /** fetch aggregated fields from the table: "aggregate_heatmap" */
  aggregate_heatmap_aggregate: AggregateHeatmapAggregate;
  /** fetch data from the table: "aggregate_heatmap" using primary key columns */
  aggregate_heatmap_by_pk?: Maybe<AggregateHeatmap>;
  /** fetch data from the table: "alembic_version" */
  alembic_version: Array<AlembicVersion>;
  /** fetch aggregated fields from the table: "alembic_version" */
  alembic_version_aggregate: AlembicVersionAggregate;
  /** fetch data from the table: "alembic_version" using primary key columns */
  alembic_version_by_pk?: Maybe<AlembicVersion>;
  /** fetch data from the table: "classification" */
  classification: Array<Classification>;
  /** fetch aggregated fields from the table: "classification" */
  classification_aggregate: ClassificationAggregate;
  /** fetch data from the table: "classification" using primary key columns */
  classification_by_pk?: Maybe<Classification>;
  /** fetch data from the table: "comment" */
  comment: Array<Comment>;
  /** fetch aggregated fields from the table: "comment" */
  comment_aggregate: CommentAggregate;
  /** fetch data from the table: "comment" using primary key columns */
  comment_by_pk?: Maybe<Comment>;
  /** fetch data from the table: "compute" */
  compute: Array<Compute>;
  /** fetch aggregated fields from the table: "compute" */
  compute_aggregate: ComputeAggregate;
  /** fetch data from the table: "compute" using primary key columns */
  compute_by_pk?: Maybe<Compute>;
  /** fetch data from the table: "computed_measurement" */
  computed_measurement: Array<ComputedMeasurement>;
  /** fetch aggregated fields from the table: "computed_measurement" */
  computed_measurement_aggregate: ComputedMeasurementAggregate;
  /** fetch data from the table: "computed_measurement" using primary key columns */
  computed_measurement_by_pk?: Maybe<ComputedMeasurement>;
  /** fetch data from the table: "config" */
  config: Array<Config>;
  /** fetch aggregated fields from the table: "config" */
  config_aggregate: ConfigAggregate;
  /** fetch data from the table: "config" using primary key columns */
  config_by_pk?: Maybe<Config>;
  /** fetch data from the table: "detection" */
  detection: Array<Detection>;
  /** fetch aggregated fields from the table: "detection" */
  detection_aggregate: DetectionAggregate;
  /** fetch data from the table: "detection" using primary key columns */
  detection_by_pk?: Maybe<Detection>;
  /** fetch data from the table: "detection_run" */
  detection_run: Array<DetectionRun>;
  /** fetch aggregated fields from the table: "detection_run" */
  detection_run_aggregate: DetectionRunAggregate;
  /** fetch data from the table: "detection_run" using primary key columns */
  detection_run_by_pk?: Maybe<DetectionRun>;
  /** fetch data from the table: "detector" */
  detector: Array<Detector>;
  /** fetch aggregated fields from the table: "detector" */
  detector_aggregate: DetectorAggregate;
  /** fetch data from the table: "detector" using primary key columns */
  detector_by_pk?: Maybe<Detector>;
  /** fetch data from the table: "device_datum" */
  device_datum: Array<DeviceDatum>;
  /** fetch aggregated fields from the table: "device_datum" */
  device_datum_aggregate: DeviceDatumAggregate;
  /** fetch data from the table: "device_datum" using primary key columns */
  device_datum_by_pk?: Maybe<DeviceDatum>;
  /** fetch data from the table: "device_metadata" */
  device_metadata: Array<DeviceMetadata>;
  /** fetch aggregated fields from the table: "device_metadata" */
  device_metadata_aggregate: DeviceMetadataAggregate;
  /** fetch data from the table: "device_metadata" using primary key columns */
  device_metadata_by_pk?: Maybe<DeviceMetadata>;
  /** fetch data from the table: "discussion" */
  discussion: Array<Discussion>;
  /** fetch aggregated fields from the table: "discussion" */
  discussion_aggregate: DiscussionAggregate;
  /** fetch data from the table: "discussion" using primary key columns */
  discussion_by_pk?: Maybe<Discussion>;
  /** fetch data from the table: "enumeration" */
  enumeration: Array<Enumeration>;
  /** fetch aggregated fields from the table: "enumeration" */
  enumeration_aggregate: EnumerationAggregate;
  /** fetch data from the table: "enumeration" using primary key columns */
  enumeration_by_pk?: Maybe<Enumeration>;
  /** fetch data from the table: "environment_recipe" */
  environment_recipe: Array<EnvironmentRecipe>;
  /** fetch aggregated fields from the table: "environment_recipe" */
  environment_recipe_aggregate: EnvironmentRecipeAggregate;
  /** fetch data from the table: "environment_recipe" using primary key columns */
  environment_recipe_by_pk?: Maybe<EnvironmentRecipe>;
  /** fetch data from the table: "environment_recipe_entry" */
  environment_recipe_entry: Array<EnvironmentRecipeEntry>;
  /** fetch aggregated fields from the table: "environment_recipe_entry" */
  environment_recipe_entry_aggregate: EnvironmentRecipeEntryAggregate;
  /** fetch data from the table: "environment_recipe_entry" using primary key columns */
  environment_recipe_entry_by_pk?: Maybe<EnvironmentRecipeEntry>;
  /** fetch data from the table: "field_intervention" */
  field_intervention: Array<FieldIntervention>;
  /** fetch aggregated fields from the table: "field_intervention" */
  field_intervention_aggregate: FieldInterventionAggregate;
  /** fetch data from the table: "field_intervention" using primary key columns */
  field_intervention_by_pk?: Maybe<FieldIntervention>;
  /** fetch data from the table: "geography_columns" */
  geography_columns: Array<GeographyColumns>;
  /** fetch aggregated fields from the table: "geography_columns" */
  geography_columns_aggregate: GeographyColumnsAggregate;
  /** fetch data from the table: "geometry_columns" */
  geometry_columns: Array<GeometryColumns>;
  /** fetch aggregated fields from the table: "geometry_columns" */
  geometry_columns_aggregate: GeometryColumnsAggregate;
  /** execute function "get_measurements_on_grid" which returns "get_measurements_on_grid_format_table" */
  get_measurements_on_grid: Array<GetMeasurementsOnGridFormatTable>;
  /** execute function "get_measurements_on_grid" and query aggregates on result of table type "get_measurements_on_grid_format_table" */
  get_measurements_on_grid_aggregate: GetMeasurementsOnGridFormatTableAggregate;
  /** fetch data from the table: "get_measurements_on_grid_format_table" */
  get_measurements_on_grid_format_table: Array<GetMeasurementsOnGridFormatTable>;
  /** fetch aggregated fields from the table: "get_measurements_on_grid_format_table" */
  get_measurements_on_grid_format_table_aggregate: GetMeasurementsOnGridFormatTableAggregate;
  /** fetch data from the table: "get_most_recent_vpd_measurements_format_table" */
  get_most_recent_vpd_measurements_format_table: Array<GetMostRecentVpdMeasurementsFormatTable>;
  /** fetch aggregated fields from the table: "get_most_recent_vpd_measurements_format_table" */
  get_most_recent_vpd_measurements_format_table_aggregate: GetMostRecentVpdMeasurementsFormatTableAggregate;
  /** fetch data from the table: "grid" */
  grid: Array<Grid>;
  /** fetch aggregated fields from the table: "grid" */
  grid_aggregate: GridAggregate;
  /** fetch data from the table: "grid" using primary key columns */
  grid_by_pk?: Maybe<Grid>;
  /** fetch data from the table: "grid_data" */
  grid_data: Array<GridData>;
  /** fetch aggregated fields from the table: "grid_data" */
  grid_data_aggregate: GridDataAggregate;
  /** fetch data from the table: "grid_element" */
  grid_element: Array<GridElement>;
  /** fetch aggregated fields from the table: "grid_element" */
  grid_element_aggregate: GridElementAggregate;
  /** fetch data from the table: "grid_element" using primary key columns */
  grid_element_by_pk?: Maybe<GridElement>;
  /** fetch data from the table: "grid_view" */
  grid_view: Array<GridView>;
  /** fetch aggregated fields from the table: "grid_view" */
  grid_view_aggregate: GridViewAggregate;
  /** fetch data from the table: "growth_cycle" */
  growth_cycle: Array<GrowthCycle>;
  /** fetch aggregated fields from the table: "growth_cycle" */
  growth_cycle_aggregate: GrowthCycleAggregate;
  /** fetch data from the table: "growth_cycle" using primary key columns */
  growth_cycle_by_pk?: Maybe<GrowthCycle>;
  /** fetch data from the table: "heat_map" */
  heat_map: Array<HeatMap>;
  /** fetch aggregated fields from the table: "heat_map" */
  heat_map_aggregate: HeatMapAggregate;
  /** fetch data from the table: "heat_map" using primary key columns */
  heat_map_by_pk?: Maybe<HeatMap>;
  /** fetch data from the table: "image_annotation_tracking" */
  image_annotation_tracking: Array<ImageAnnotationTracking>;
  /** fetch aggregated fields from the table: "image_annotation_tracking" */
  image_annotation_tracking_aggregate: ImageAnnotationTrackingAggregate;
  /** fetch data from the table: "image_annotation_tracking" using primary key columns */
  image_annotation_tracking_by_pk?: Maybe<ImageAnnotationTracking>;
  /** fetch data from the table: "image_data" */
  image_data: Array<ImageData>;
  /** fetch aggregated fields from the table: "image_data" */
  image_data_aggregate: ImageDataAggregate;
  /** fetch data from the table: "label" */
  label: Array<Label>;
  /** fetch aggregated fields from the table: "label" */
  label_aggregate: LabelAggregate;
  /** fetch data from the table: "label" using primary key columns */
  label_by_pk?: Maybe<Label>;
  /** fetch data from the table: "label_category" */
  label_category: Array<LabelCategory>;
  /** fetch aggregated fields from the table: "label_category" */
  label_category_aggregate: LabelCategoryAggregate;
  /** fetch data from the table: "label_category" using primary key columns */
  label_category_by_pk?: Maybe<LabelCategory>;
  /** fetch data from the table: "label_counts_by_measurement" */
  label_counts_by_measurement: Array<LabelCountsByMeasurement>;
  /** fetch aggregated fields from the table: "label_counts_by_measurement" */
  label_counts_by_measurement_aggregate: LabelCountsByMeasurementAggregate;
  /** fetch data from the table: "label_task" */
  label_task: Array<LabelTask>;
  /** fetch aggregated fields from the table: "label_task" */
  label_task_aggregate: LabelTaskAggregate;
  /** fetch data from the table: "label_task" using primary key columns */
  label_task_by_pk?: Maybe<LabelTask>;
  /** fetch data from the table: "lambda_run" */
  lambda_run: Array<LambdaRun>;
  /** fetch aggregated fields from the table: "lambda_run" */
  lambda_run_aggregate: LambdaRunAggregate;
  /** fetch data from the table: "lambda_run" using primary key columns */
  lambda_run_by_pk?: Maybe<LambdaRun>;
  /** fetch data from the table: "lambda_run_measurement_run" */
  lambda_run_measurement_run: Array<LambdaRunMeasurementRun>;
  /** fetch aggregated fields from the table: "lambda_run_measurement_run" */
  lambda_run_measurement_run_aggregate: LambdaRunMeasurementRunAggregate;
  /** fetch data from the table: "lambda_run_measurement_run" using primary key columns */
  lambda_run_measurement_run_by_pk?: Maybe<LambdaRunMeasurementRun>;
  /** fetch data from the table: "lambda_version" */
  lambda_version: Array<LambdaVersion>;
  /** fetch aggregated fields from the table: "lambda_version" */
  lambda_version_aggregate: LambdaVersionAggregate;
  /** fetch data from the table: "lambda_version" using primary key columns */
  lambda_version_by_pk?: Maybe<LambdaVersion>;
  /** fetch data from the table: "light_cycle_recipe" */
  light_cycle_recipe: Array<LightCycleRecipe>;
  /** fetch aggregated fields from the table: "light_cycle_recipe" */
  light_cycle_recipe_aggregate: LightCycleRecipeAggregate;
  /** fetch data from the table: "light_cycle_recipe" using primary key columns */
  light_cycle_recipe_by_pk?: Maybe<LightCycleRecipe>;
  /** fetch data from the table: "light_cycle_recipe_entry" */
  light_cycle_recipe_entry: Array<LightCycleRecipeEntry>;
  /** fetch aggregated fields from the table: "light_cycle_recipe_entry" */
  light_cycle_recipe_entry_aggregate: LightCycleRecipeEntryAggregate;
  /** fetch data from the table: "light_cycle_recipe_entry" using primary key columns */
  light_cycle_recipe_entry_by_pk?: Maybe<LightCycleRecipeEntry>;
  /** fetch data from the table: "location" */
  location: Array<Location>;
  /** fetch aggregated fields from the table: "location" */
  location_aggregate: LocationAggregate;
  /** fetch data from the table: "location" using primary key columns */
  location_by_pk?: Maybe<Location>;
  /** fetch data from the table: "measurement" */
  measurement: Array<Measurement>;
  /** fetch aggregated fields from the table: "measurement" */
  measurement_aggregate: MeasurementAggregate;
  /** fetch data from the table: "measurement" using primary key columns */
  measurement_by_pk?: Maybe<Measurement>;
  /** fetch data from the table: "measurement_run" */
  measurement_run: Array<MeasurementRun>;
  /** fetch aggregated fields from the table: "measurement_run" */
  measurement_run_aggregate: MeasurementRunAggregate;
  /** fetch data from the table: "measurement_run" using primary key columns */
  measurement_run_by_pk?: Maybe<MeasurementRun>;
  /** fetch data from the table: "measurement_view" */
  measurement_view: Array<MeasurementView>;
  /** fetch aggregated fields from the table: "measurement_view" */
  measurement_view_aggregate: MeasurementViewAggregate;
  /** fetch data from the table: "measurements_by_grid_index" */
  measurements_by_grid_index: Array<MeasurementsByGridIndex>;
  /** fetch aggregated fields from the table: "measurements_by_grid_index" */
  measurements_by_grid_index_aggregate: MeasurementsByGridIndexAggregate;
  /** fetch data from the table: "measurements_on_grid" */
  measurements_on_grid: Array<MeasurementsOnGrid>;
  /** fetch aggregated fields from the table: "measurements_on_grid" */
  measurements_on_grid_aggregate: MeasurementsOnGridAggregate;
  /** fetch data from the table: "notification" */
  notification: Array<Notification>;
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: NotificationAggregate;
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<Notification>;
  /** fetch data from the table: "organization" */
  organization: Array<Organization>;
  /** fetch aggregated fields from the table: "organization" */
  organization_aggregate: OrganizationAggregate;
  /** fetch data from the table: "organization" using primary key columns */
  organization_by_pk?: Maybe<Organization>;
  /** An array relationship */
  parameters: Array<Parameters>;
  /** An aggregate relationship */
  parameters_aggregate: ParametersAggregate;
  /** fetch data from the table: "parameters" using primary key columns */
  parameters_by_pk?: Maybe<Parameters>;
  /** fetch data from the table: "pose" */
  pose: Array<Pose>;
  /** fetch aggregated fields from the table: "pose" */
  pose_aggregate: PoseAggregate;
  /** fetch data from the table: "pose" using primary key columns */
  pose_by_pk?: Maybe<Pose>;
  /** fetch data from the table: "proto" */
  proto: Array<Proto>;
  /** fetch aggregated fields from the table: "proto" */
  proto_aggregate: ProtoAggregate;
  /** fetch data from the table: "proto" using primary key columns */
  proto_by_pk?: Maybe<Proto>;
  /** fetch data from the table: "role_permission" */
  role_permission: Array<RolePermission>;
  /** fetch aggregated fields from the table: "role_permission" */
  role_permission_aggregate: RolePermissionAggregate;
  /** fetch data from the table: "sensor" */
  sensor: Array<Sensor>;
  /** fetch aggregated fields from the table: "sensor" */
  sensor_aggregate: SensorAggregate;
  /** fetch data from the table: "sensor" using primary key columns */
  sensor_by_pk?: Maybe<Sensor>;
  /** fetch data from the table: "spatial_ref_sys" */
  spatial_ref_sys: Array<SpatialRefSys>;
  /** fetch aggregated fields from the table: "spatial_ref_sys" */
  spatial_ref_sys_aggregate: SpatialRefSysAggregate;
  /** fetch data from the table: "spatial_ref_sys" using primary key columns */
  spatial_ref_sys_by_pk?: Maybe<SpatialRefSys>;
  /** fetch data from the table: "system" */
  system: Array<System>;
  /** fetch aggregated fields from the table: "system" */
  system_aggregate: SystemAggregate;
  /** fetch data from the table: "system" using primary key columns */
  system_by_pk?: Maybe<System>;
  /** fetch data from the table: "track" */
  track: Array<Track>;
  /** fetch aggregated fields from the table: "track" */
  track_aggregate: TrackAggregate;
  /** fetch data from the table: "track" using primary key columns */
  track_by_pk?: Maybe<Track>;
  /** An array relationship */
  track_detections: Array<TrackDetections>;
  /** An aggregate relationship */
  track_detections_aggregate: TrackDetectionsAggregate;
  /** fetch data from the table: "track_detections" using primary key columns */
  track_detections_by_pk?: Maybe<TrackDetections>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: UserAggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_comment" */
  user_comment: Array<UserComment>;
  /** fetch aggregated fields from the table: "user_comment" */
  user_comment_aggregate: UserCommentAggregate;
  /** fetch data from the table: "user_comment" using primary key columns */
  user_comment_by_pk?: Maybe<UserComment>;
  /** fetch data from the table: "user_label" */
  user_label: Array<UserLabel>;
  /** fetch aggregated fields from the table: "user_label" */
  user_label_aggregate: UserLabelAggregate;
  /** fetch data from the table: "user_label" using primary key columns */
  user_label_by_pk?: Maybe<UserLabel>;
  /** fetch data from the table: "user_notification" */
  user_notification: Array<UserNotification>;
  /** fetch aggregated fields from the table: "user_notification" */
  user_notification_aggregate: UserNotificationAggregate;
  /** fetch data from the table: "user_notification" using primary key columns */
  user_notification_by_pk?: Maybe<UserNotification>;
  /** fetch data from the table: "user_notification_setting" */
  user_notification_setting: Array<UserNotificationSetting>;
  /** fetch aggregated fields from the table: "user_notification_setting" */
  user_notification_setting_aggregate: UserNotificationSettingAggregate;
  /** fetch data from the table: "user_notification_setting" using primary key columns */
  user_notification_setting_by_pk?: Maybe<UserNotificationSetting>;
  /** fetch data from the table: "user_organization_role" */
  user_organization_role: Array<UserOrganizationRole>;
  /** fetch aggregated fields from the table: "user_organization_role" */
  user_organization_role_aggregate: UserOrganizationRoleAggregate;
  /** fetch data from the table: "user_organization_role" using primary key columns */
  user_organization_role_by_pk?: Maybe<UserOrganizationRole>;
  /** fetch data from the table: "version" */
  version: Array<Version>;
  /** fetch aggregated fields from the table: "version" */
  version_aggregate: VersionAggregate;
  /** fetch data from the table: "version" using primary key columns */
  version_by_pk?: Maybe<Version>;
  /** fetch data from the table: "vpd_view" */
  vpd_view: Array<VpdView>;
  /** fetch aggregated fields from the table: "vpd_view" */
  vpd_view_aggregate: VpdViewAggregate;
  /** fetch data from the table: "zone" */
  zone: Array<Zone>;
  /** fetch aggregated fields from the table: "zone" */
  zone_aggregate: ZoneAggregate;
  /** fetch data from the table: "zone" using primary key columns */
  zone_by_pk?: Maybe<Zone>;
};


export type QueryRootAddressArgs = {
  distinct_on?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};


export type QueryRootAddressAggregateArgs = {
  distinct_on?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};


export type QueryRootAddressByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootAggregateHeatmapArgs = {
  distinct_on?: InputMaybe<Array<AggregateHeatmapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AggregateHeatmapOrderBy>>;
  where?: InputMaybe<AggregateHeatmapBoolExp>;
};


export type QueryRootAggregateHeatmapAggregateArgs = {
  distinct_on?: InputMaybe<Array<AggregateHeatmapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AggregateHeatmapOrderBy>>;
  where?: InputMaybe<AggregateHeatmapBoolExp>;
};


export type QueryRootAggregateHeatmapByPkArgs = {
  uid: Scalars['uuid']['input'];
};


export type QueryRootAlembicVersionArgs = {
  distinct_on?: InputMaybe<Array<AlembicVersionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AlembicVersionOrderBy>>;
  where?: InputMaybe<AlembicVersionBoolExp>;
};


export type QueryRootAlembicVersionAggregateArgs = {
  distinct_on?: InputMaybe<Array<AlembicVersionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AlembicVersionOrderBy>>;
  where?: InputMaybe<AlembicVersionBoolExp>;
};


export type QueryRootAlembicVersionByPkArgs = {
  version_num: Scalars['String']['input'];
};


export type QueryRootClassificationArgs = {
  distinct_on?: InputMaybe<Array<ClassificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ClassificationOrderBy>>;
  where?: InputMaybe<ClassificationBoolExp>;
};


export type QueryRootClassificationAggregateArgs = {
  distinct_on?: InputMaybe<Array<ClassificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ClassificationOrderBy>>;
  where?: InputMaybe<ClassificationBoolExp>;
};


export type QueryRootClassificationByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootCommentArgs = {
  distinct_on?: InputMaybe<Array<CommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CommentOrderBy>>;
  where?: InputMaybe<CommentBoolExp>;
};


export type QueryRootCommentAggregateArgs = {
  distinct_on?: InputMaybe<Array<CommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CommentOrderBy>>;
  where?: InputMaybe<CommentBoolExp>;
};


export type QueryRootCommentByPkArgs = {
  uid: Scalars['uuid']['input'];
};


export type QueryRootComputeArgs = {
  distinct_on?: InputMaybe<Array<ComputeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputeOrderBy>>;
  where?: InputMaybe<ComputeBoolExp>;
};


export type QueryRootComputeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ComputeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputeOrderBy>>;
  where?: InputMaybe<ComputeBoolExp>;
};


export type QueryRootComputeByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootComputedMeasurementArgs = {
  distinct_on?: InputMaybe<Array<ComputedMeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputedMeasurementOrderBy>>;
  where?: InputMaybe<ComputedMeasurementBoolExp>;
};


export type QueryRootComputedMeasurementAggregateArgs = {
  distinct_on?: InputMaybe<Array<ComputedMeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputedMeasurementOrderBy>>;
  where?: InputMaybe<ComputedMeasurementBoolExp>;
};


export type QueryRootComputedMeasurementByPkArgs = {
  id: Scalars['bigint']['input'];
  time: Scalars['timestamptz']['input'];
};


export type QueryRootConfigArgs = {
  distinct_on?: InputMaybe<Array<ConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConfigOrderBy>>;
  where?: InputMaybe<ConfigBoolExp>;
};


export type QueryRootConfigAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConfigOrderBy>>;
  where?: InputMaybe<ConfigBoolExp>;
};


export type QueryRootConfigByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootDetectionArgs = {
  distinct_on?: InputMaybe<Array<DetectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectionOrderBy>>;
  where?: InputMaybe<DetectionBoolExp>;
};


export type QueryRootDetectionAggregateArgs = {
  distinct_on?: InputMaybe<Array<DetectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectionOrderBy>>;
  where?: InputMaybe<DetectionBoolExp>;
};


export type QueryRootDetectionByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootDetectionRunArgs = {
  distinct_on?: InputMaybe<Array<DetectionRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectionRunOrderBy>>;
  where?: InputMaybe<DetectionRunBoolExp>;
};


export type QueryRootDetectionRunAggregateArgs = {
  distinct_on?: InputMaybe<Array<DetectionRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectionRunOrderBy>>;
  where?: InputMaybe<DetectionRunBoolExp>;
};


export type QueryRootDetectionRunByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootDetectorArgs = {
  distinct_on?: InputMaybe<Array<DetectorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectorOrderBy>>;
  where?: InputMaybe<DetectorBoolExp>;
};


export type QueryRootDetectorAggregateArgs = {
  distinct_on?: InputMaybe<Array<DetectorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectorOrderBy>>;
  where?: InputMaybe<DetectorBoolExp>;
};


export type QueryRootDetectorByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootDeviceDatumArgs = {
  distinct_on?: InputMaybe<Array<DeviceDatumSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeviceDatumOrderBy>>;
  where?: InputMaybe<DeviceDatumBoolExp>;
};


export type QueryRootDeviceDatumAggregateArgs = {
  distinct_on?: InputMaybe<Array<DeviceDatumSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeviceDatumOrderBy>>;
  where?: InputMaybe<DeviceDatumBoolExp>;
};


export type QueryRootDeviceDatumByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootDeviceMetadataArgs = {
  distinct_on?: InputMaybe<Array<DeviceMetadataSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeviceMetadataOrderBy>>;
  where?: InputMaybe<DeviceMetadataBoolExp>;
};


export type QueryRootDeviceMetadataAggregateArgs = {
  distinct_on?: InputMaybe<Array<DeviceMetadataSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeviceMetadataOrderBy>>;
  where?: InputMaybe<DeviceMetadataBoolExp>;
};


export type QueryRootDeviceMetadataByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootDiscussionArgs = {
  distinct_on?: InputMaybe<Array<DiscussionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DiscussionOrderBy>>;
  where?: InputMaybe<DiscussionBoolExp>;
};


export type QueryRootDiscussionAggregateArgs = {
  distinct_on?: InputMaybe<Array<DiscussionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DiscussionOrderBy>>;
  where?: InputMaybe<DiscussionBoolExp>;
};


export type QueryRootDiscussionByPkArgs = {
  uid: Scalars['uuid']['input'];
};


export type QueryRootEnumerationArgs = {
  distinct_on?: InputMaybe<Array<EnumerationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EnumerationOrderBy>>;
  where?: InputMaybe<EnumerationBoolExp>;
};


export type QueryRootEnumerationAggregateArgs = {
  distinct_on?: InputMaybe<Array<EnumerationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EnumerationOrderBy>>;
  where?: InputMaybe<EnumerationBoolExp>;
};


export type QueryRootEnumerationByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootEnvironmentRecipeArgs = {
  distinct_on?: InputMaybe<Array<EnvironmentRecipeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EnvironmentRecipeOrderBy>>;
  where?: InputMaybe<EnvironmentRecipeBoolExp>;
};


export type QueryRootEnvironmentRecipeAggregateArgs = {
  distinct_on?: InputMaybe<Array<EnvironmentRecipeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EnvironmentRecipeOrderBy>>;
  where?: InputMaybe<EnvironmentRecipeBoolExp>;
};


export type QueryRootEnvironmentRecipeByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootEnvironmentRecipeEntryArgs = {
  distinct_on?: InputMaybe<Array<EnvironmentRecipeEntrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EnvironmentRecipeEntryOrderBy>>;
  where?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
};


export type QueryRootEnvironmentRecipeEntryAggregateArgs = {
  distinct_on?: InputMaybe<Array<EnvironmentRecipeEntrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EnvironmentRecipeEntryOrderBy>>;
  where?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
};


export type QueryRootEnvironmentRecipeEntryByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootFieldInterventionArgs = {
  distinct_on?: InputMaybe<Array<FieldInterventionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FieldInterventionOrderBy>>;
  where?: InputMaybe<FieldInterventionBoolExp>;
};


export type QueryRootFieldInterventionAggregateArgs = {
  distinct_on?: InputMaybe<Array<FieldInterventionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FieldInterventionOrderBy>>;
  where?: InputMaybe<FieldInterventionBoolExp>;
};


export type QueryRootFieldInterventionByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootGeographyColumnsArgs = {
  distinct_on?: InputMaybe<Array<GeographyColumnsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GeographyColumnsOrderBy>>;
  where?: InputMaybe<GeographyColumnsBoolExp>;
};


export type QueryRootGeographyColumnsAggregateArgs = {
  distinct_on?: InputMaybe<Array<GeographyColumnsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GeographyColumnsOrderBy>>;
  where?: InputMaybe<GeographyColumnsBoolExp>;
};


export type QueryRootGeometryColumnsArgs = {
  distinct_on?: InputMaybe<Array<GeometryColumnsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GeometryColumnsOrderBy>>;
  where?: InputMaybe<GeometryColumnsBoolExp>;
};


export type QueryRootGeometryColumnsAggregateArgs = {
  distinct_on?: InputMaybe<Array<GeometryColumnsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GeometryColumnsOrderBy>>;
  where?: InputMaybe<GeometryColumnsBoolExp>;
};


export type QueryRootGetMeasurementsOnGridArgs = {
  args: GetMeasurementsOnGridArgs;
  distinct_on?: InputMaybe<Array<GetMeasurementsOnGridFormatTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GetMeasurementsOnGridFormatTableOrderBy>>;
  where?: InputMaybe<GetMeasurementsOnGridFormatTableBoolExp>;
};


export type QueryRootGetMeasurementsOnGridAggregateArgs = {
  args: GetMeasurementsOnGridArgs;
  distinct_on?: InputMaybe<Array<GetMeasurementsOnGridFormatTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GetMeasurementsOnGridFormatTableOrderBy>>;
  where?: InputMaybe<GetMeasurementsOnGridFormatTableBoolExp>;
};


export type QueryRootGetMeasurementsOnGridFormatTableArgs = {
  distinct_on?: InputMaybe<Array<GetMeasurementsOnGridFormatTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GetMeasurementsOnGridFormatTableOrderBy>>;
  where?: InputMaybe<GetMeasurementsOnGridFormatTableBoolExp>;
};


export type QueryRootGetMeasurementsOnGridFormatTableAggregateArgs = {
  distinct_on?: InputMaybe<Array<GetMeasurementsOnGridFormatTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GetMeasurementsOnGridFormatTableOrderBy>>;
  where?: InputMaybe<GetMeasurementsOnGridFormatTableBoolExp>;
};


export type QueryRootGetMostRecentVpdMeasurementsFormatTableArgs = {
  distinct_on?: InputMaybe<Array<GetMostRecentVpdMeasurementsFormatTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GetMostRecentVpdMeasurementsFormatTableOrderBy>>;
  where?: InputMaybe<GetMostRecentVpdMeasurementsFormatTableBoolExp>;
};


export type QueryRootGetMostRecentVpdMeasurementsFormatTableAggregateArgs = {
  distinct_on?: InputMaybe<Array<GetMostRecentVpdMeasurementsFormatTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GetMostRecentVpdMeasurementsFormatTableOrderBy>>;
  where?: InputMaybe<GetMostRecentVpdMeasurementsFormatTableBoolExp>;
};


export type QueryRootGridArgs = {
  distinct_on?: InputMaybe<Array<GridSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GridOrderBy>>;
  where?: InputMaybe<GridBoolExp>;
};


export type QueryRootGridAggregateArgs = {
  distinct_on?: InputMaybe<Array<GridSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GridOrderBy>>;
  where?: InputMaybe<GridBoolExp>;
};


export type QueryRootGridByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootGridDataArgs = {
  distinct_on?: InputMaybe<Array<GridDataSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GridDataOrderBy>>;
  where?: InputMaybe<GridDataBoolExp>;
};


export type QueryRootGridDataAggregateArgs = {
  distinct_on?: InputMaybe<Array<GridDataSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GridDataOrderBy>>;
  where?: InputMaybe<GridDataBoolExp>;
};


export type QueryRootGridElementArgs = {
  distinct_on?: InputMaybe<Array<GridElementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GridElementOrderBy>>;
  where?: InputMaybe<GridElementBoolExp>;
};


export type QueryRootGridElementAggregateArgs = {
  distinct_on?: InputMaybe<Array<GridElementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GridElementOrderBy>>;
  where?: InputMaybe<GridElementBoolExp>;
};


export type QueryRootGridElementByPkArgs = {
  grid_id: Scalars['Int']['input'];
  x_index: Scalars['Int']['input'];
  y_index: Scalars['Int']['input'];
  z_index: Scalars['Int']['input'];
};


export type QueryRootGridViewArgs = {
  distinct_on?: InputMaybe<Array<GridViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GridViewOrderBy>>;
  where?: InputMaybe<GridViewBoolExp>;
};


export type QueryRootGridViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<GridViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GridViewOrderBy>>;
  where?: InputMaybe<GridViewBoolExp>;
};


export type QueryRootGrowthCycleArgs = {
  distinct_on?: InputMaybe<Array<GrowthCycleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GrowthCycleOrderBy>>;
  where?: InputMaybe<GrowthCycleBoolExp>;
};


export type QueryRootGrowthCycleAggregateArgs = {
  distinct_on?: InputMaybe<Array<GrowthCycleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GrowthCycleOrderBy>>;
  where?: InputMaybe<GrowthCycleBoolExp>;
};


export type QueryRootGrowthCycleByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootHeatMapArgs = {
  distinct_on?: InputMaybe<Array<HeatMapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeatMapOrderBy>>;
  where?: InputMaybe<HeatMapBoolExp>;
};


export type QueryRootHeatMapAggregateArgs = {
  distinct_on?: InputMaybe<Array<HeatMapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeatMapOrderBy>>;
  where?: InputMaybe<HeatMapBoolExp>;
};


export type QueryRootHeatMapByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootImageAnnotationTrackingArgs = {
  distinct_on?: InputMaybe<Array<ImageAnnotationTrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImageAnnotationTrackingOrderBy>>;
  where?: InputMaybe<ImageAnnotationTrackingBoolExp>;
};


export type QueryRootImageAnnotationTrackingAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImageAnnotationTrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImageAnnotationTrackingOrderBy>>;
  where?: InputMaybe<ImageAnnotationTrackingBoolExp>;
};


export type QueryRootImageAnnotationTrackingByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootImageDataArgs = {
  distinct_on?: InputMaybe<Array<ImageDataSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImageDataOrderBy>>;
  where?: InputMaybe<ImageDataBoolExp>;
};


export type QueryRootImageDataAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImageDataSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImageDataOrderBy>>;
  where?: InputMaybe<ImageDataBoolExp>;
};


export type QueryRootLabelArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


export type QueryRootLabelAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


export type QueryRootLabelByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootLabelCategoryArgs = {
  distinct_on?: InputMaybe<Array<LabelCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelCategoryOrderBy>>;
  where?: InputMaybe<LabelCategoryBoolExp>;
};


export type QueryRootLabelCategoryAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelCategoryOrderBy>>;
  where?: InputMaybe<LabelCategoryBoolExp>;
};


export type QueryRootLabelCategoryByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootLabelCountsByMeasurementArgs = {
  distinct_on?: InputMaybe<Array<LabelCountsByMeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelCountsByMeasurementOrderBy>>;
  where?: InputMaybe<LabelCountsByMeasurementBoolExp>;
};


export type QueryRootLabelCountsByMeasurementAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelCountsByMeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelCountsByMeasurementOrderBy>>;
  where?: InputMaybe<LabelCountsByMeasurementBoolExp>;
};


export type QueryRootLabelTaskArgs = {
  distinct_on?: InputMaybe<Array<LabelTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelTaskOrderBy>>;
  where?: InputMaybe<LabelTaskBoolExp>;
};


export type QueryRootLabelTaskAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelTaskOrderBy>>;
  where?: InputMaybe<LabelTaskBoolExp>;
};


export type QueryRootLabelTaskByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootLambdaRunArgs = {
  distinct_on?: InputMaybe<Array<LambdaRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LambdaRunOrderBy>>;
  where?: InputMaybe<LambdaRunBoolExp>;
};


export type QueryRootLambdaRunAggregateArgs = {
  distinct_on?: InputMaybe<Array<LambdaRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LambdaRunOrderBy>>;
  where?: InputMaybe<LambdaRunBoolExp>;
};


export type QueryRootLambdaRunByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootLambdaRunMeasurementRunArgs = {
  distinct_on?: InputMaybe<Array<LambdaRunMeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LambdaRunMeasurementRunOrderBy>>;
  where?: InputMaybe<LambdaRunMeasurementRunBoolExp>;
};


export type QueryRootLambdaRunMeasurementRunAggregateArgs = {
  distinct_on?: InputMaybe<Array<LambdaRunMeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LambdaRunMeasurementRunOrderBy>>;
  where?: InputMaybe<LambdaRunMeasurementRunBoolExp>;
};


export type QueryRootLambdaRunMeasurementRunByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootLambdaVersionArgs = {
  distinct_on?: InputMaybe<Array<LambdaVersionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LambdaVersionOrderBy>>;
  where?: InputMaybe<LambdaVersionBoolExp>;
};


export type QueryRootLambdaVersionAggregateArgs = {
  distinct_on?: InputMaybe<Array<LambdaVersionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LambdaVersionOrderBy>>;
  where?: InputMaybe<LambdaVersionBoolExp>;
};


export type QueryRootLambdaVersionByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootLightCycleRecipeArgs = {
  distinct_on?: InputMaybe<Array<LightCycleRecipeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LightCycleRecipeOrderBy>>;
  where?: InputMaybe<LightCycleRecipeBoolExp>;
};


export type QueryRootLightCycleRecipeAggregateArgs = {
  distinct_on?: InputMaybe<Array<LightCycleRecipeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LightCycleRecipeOrderBy>>;
  where?: InputMaybe<LightCycleRecipeBoolExp>;
};


export type QueryRootLightCycleRecipeByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootLightCycleRecipeEntryArgs = {
  distinct_on?: InputMaybe<Array<LightCycleRecipeEntrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LightCycleRecipeEntryOrderBy>>;
  where?: InputMaybe<LightCycleRecipeEntryBoolExp>;
};


export type QueryRootLightCycleRecipeEntryAggregateArgs = {
  distinct_on?: InputMaybe<Array<LightCycleRecipeEntrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LightCycleRecipeEntryOrderBy>>;
  where?: InputMaybe<LightCycleRecipeEntryBoolExp>;
};


export type QueryRootLightCycleRecipeEntryByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootLocationArgs = {
  distinct_on?: InputMaybe<Array<LocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LocationOrderBy>>;
  where?: InputMaybe<LocationBoolExp>;
};


export type QueryRootLocationAggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LocationOrderBy>>;
  where?: InputMaybe<LocationBoolExp>;
};


export type QueryRootLocationByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootMeasurementArgs = {
  distinct_on?: InputMaybe<Array<MeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementOrderBy>>;
  where?: InputMaybe<MeasurementBoolExp>;
};


export type QueryRootMeasurementAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementOrderBy>>;
  where?: InputMaybe<MeasurementBoolExp>;
};


export type QueryRootMeasurementByPkArgs = {
  id: Scalars['bigint']['input'];
  time: Scalars['timestamptz']['input'];
};


export type QueryRootMeasurementRunArgs = {
  distinct_on?: InputMaybe<Array<MeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementRunOrderBy>>;
  where?: InputMaybe<MeasurementRunBoolExp>;
};


export type QueryRootMeasurementRunAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementRunOrderBy>>;
  where?: InputMaybe<MeasurementRunBoolExp>;
};


export type QueryRootMeasurementRunByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootMeasurementViewArgs = {
  distinct_on?: InputMaybe<Array<MeasurementViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementViewOrderBy>>;
  where?: InputMaybe<MeasurementViewBoolExp>;
};


export type QueryRootMeasurementViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementViewOrderBy>>;
  where?: InputMaybe<MeasurementViewBoolExp>;
};


export type QueryRootMeasurementsByGridIndexArgs = {
  distinct_on?: InputMaybe<Array<MeasurementsByGridIndexSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementsByGridIndexOrderBy>>;
  where?: InputMaybe<MeasurementsByGridIndexBoolExp>;
};


export type QueryRootMeasurementsByGridIndexAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementsByGridIndexSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementsByGridIndexOrderBy>>;
  where?: InputMaybe<MeasurementsByGridIndexBoolExp>;
};


export type QueryRootMeasurementsOnGridArgs = {
  distinct_on?: InputMaybe<Array<MeasurementsOnGridSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementsOnGridOrderBy>>;
  where?: InputMaybe<MeasurementsOnGridBoolExp>;
};


export type QueryRootMeasurementsOnGridAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementsOnGridSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementsOnGridOrderBy>>;
  where?: InputMaybe<MeasurementsOnGridBoolExp>;
};


export type QueryRootNotificationArgs = {
  distinct_on?: InputMaybe<Array<NotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotificationOrderBy>>;
  where?: InputMaybe<NotificationBoolExp>;
};


export type QueryRootNotificationAggregateArgs = {
  distinct_on?: InputMaybe<Array<NotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotificationOrderBy>>;
  where?: InputMaybe<NotificationBoolExp>;
};


export type QueryRootNotificationByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootOrganizationArgs = {
  distinct_on?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};


export type QueryRootOrganizationAggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};


export type QueryRootOrganizationByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootParametersArgs = {
  distinct_on?: InputMaybe<Array<ParametersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ParametersOrderBy>>;
  where?: InputMaybe<ParametersBoolExp>;
};


export type QueryRootParametersAggregateArgs = {
  distinct_on?: InputMaybe<Array<ParametersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ParametersOrderBy>>;
  where?: InputMaybe<ParametersBoolExp>;
};


export type QueryRootParametersByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootPoseArgs = {
  distinct_on?: InputMaybe<Array<PoseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PoseOrderBy>>;
  where?: InputMaybe<PoseBoolExp>;
};


export type QueryRootPoseAggregateArgs = {
  distinct_on?: InputMaybe<Array<PoseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PoseOrderBy>>;
  where?: InputMaybe<PoseBoolExp>;
};


export type QueryRootPoseByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootProtoArgs = {
  distinct_on?: InputMaybe<Array<ProtoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProtoOrderBy>>;
  where?: InputMaybe<ProtoBoolExp>;
};


export type QueryRootProtoAggregateArgs = {
  distinct_on?: InputMaybe<Array<ProtoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProtoOrderBy>>;
  where?: InputMaybe<ProtoBoolExp>;
};


export type QueryRootProtoByPkArgs = {
  filename: Scalars['String']['input'];
};


export type QueryRootRolePermissionArgs = {
  distinct_on?: InputMaybe<Array<RolePermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RolePermissionOrderBy>>;
  where?: InputMaybe<RolePermissionBoolExp>;
};


export type QueryRootRolePermissionAggregateArgs = {
  distinct_on?: InputMaybe<Array<RolePermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RolePermissionOrderBy>>;
  where?: InputMaybe<RolePermissionBoolExp>;
};


export type QueryRootSensorArgs = {
  distinct_on?: InputMaybe<Array<SensorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SensorOrderBy>>;
  where?: InputMaybe<SensorBoolExp>;
};


export type QueryRootSensorAggregateArgs = {
  distinct_on?: InputMaybe<Array<SensorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SensorOrderBy>>;
  where?: InputMaybe<SensorBoolExp>;
};


export type QueryRootSensorByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootSpatialRefSysArgs = {
  distinct_on?: InputMaybe<Array<SpatialRefSysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SpatialRefSysOrderBy>>;
  where?: InputMaybe<SpatialRefSysBoolExp>;
};


export type QueryRootSpatialRefSysAggregateArgs = {
  distinct_on?: InputMaybe<Array<SpatialRefSysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SpatialRefSysOrderBy>>;
  where?: InputMaybe<SpatialRefSysBoolExp>;
};


export type QueryRootSpatialRefSysByPkArgs = {
  srid: Scalars['Int']['input'];
};


export type QueryRootSystemArgs = {
  distinct_on?: InputMaybe<Array<SystemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SystemOrderBy>>;
  where?: InputMaybe<SystemBoolExp>;
};


export type QueryRootSystemAggregateArgs = {
  distinct_on?: InputMaybe<Array<SystemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SystemOrderBy>>;
  where?: InputMaybe<SystemBoolExp>;
};


export type QueryRootSystemByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootTrackArgs = {
  distinct_on?: InputMaybe<Array<TrackSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TrackOrderBy>>;
  where?: InputMaybe<TrackBoolExp>;
};


export type QueryRootTrackAggregateArgs = {
  distinct_on?: InputMaybe<Array<TrackSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TrackOrderBy>>;
  where?: InputMaybe<TrackBoolExp>;
};


export type QueryRootTrackByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootTrackDetectionsArgs = {
  distinct_on?: InputMaybe<Array<TrackDetectionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TrackDetectionsOrderBy>>;
  where?: InputMaybe<TrackDetectionsBoolExp>;
};


export type QueryRootTrackDetectionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TrackDetectionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TrackDetectionsOrderBy>>;
  where?: InputMaybe<TrackDetectionsBoolExp>;
};


export type QueryRootTrackDetectionsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootUserArgs = {
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type QueryRootUserAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type QueryRootUserByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootUserCommentArgs = {
  distinct_on?: InputMaybe<Array<UserCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserCommentOrderBy>>;
  where?: InputMaybe<UserCommentBoolExp>;
};


export type QueryRootUserCommentAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserCommentOrderBy>>;
  where?: InputMaybe<UserCommentBoolExp>;
};


export type QueryRootUserCommentByPkArgs = {
  comment_uid: Scalars['uuid']['input'];
  user_id: Scalars['Int']['input'];
};


export type QueryRootUserLabelArgs = {
  distinct_on?: InputMaybe<Array<UserLabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserLabelOrderBy>>;
  where?: InputMaybe<UserLabelBoolExp>;
};


export type QueryRootUserLabelAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserLabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserLabelOrderBy>>;
  where?: InputMaybe<UserLabelBoolExp>;
};


export type QueryRootUserLabelByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootUserNotificationArgs = {
  distinct_on?: InputMaybe<Array<UserNotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserNotificationOrderBy>>;
  where?: InputMaybe<UserNotificationBoolExp>;
};


export type QueryRootUserNotificationAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserNotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserNotificationOrderBy>>;
  where?: InputMaybe<UserNotificationBoolExp>;
};


export type QueryRootUserNotificationByPkArgs = {
  notification_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};


export type QueryRootUserNotificationSettingArgs = {
  distinct_on?: InputMaybe<Array<UserNotificationSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserNotificationSettingOrderBy>>;
  where?: InputMaybe<UserNotificationSettingBoolExp>;
};


export type QueryRootUserNotificationSettingAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserNotificationSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserNotificationSettingOrderBy>>;
  where?: InputMaybe<UserNotificationSettingBoolExp>;
};


export type QueryRootUserNotificationSettingByPkArgs = {
  uid: Scalars['uuid']['input'];
};


export type QueryRootUserOrganizationRoleArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizationRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrganizationRoleOrderBy>>;
  where?: InputMaybe<UserOrganizationRoleBoolExp>;
};


export type QueryRootUserOrganizationRoleAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizationRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrganizationRoleOrderBy>>;
  where?: InputMaybe<UserOrganizationRoleBoolExp>;
};


export type QueryRootUserOrganizationRoleByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type QueryRootVersionArgs = {
  distinct_on?: InputMaybe<Array<VersionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VersionOrderBy>>;
  where?: InputMaybe<VersionBoolExp>;
};


export type QueryRootVersionAggregateArgs = {
  distinct_on?: InputMaybe<Array<VersionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VersionOrderBy>>;
  where?: InputMaybe<VersionBoolExp>;
};


export type QueryRootVersionByPkArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRootVpdViewArgs = {
  distinct_on?: InputMaybe<Array<VpdViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VpdViewOrderBy>>;
  where?: InputMaybe<VpdViewBoolExp>;
};


export type QueryRootVpdViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<VpdViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VpdViewOrderBy>>;
  where?: InputMaybe<VpdViewBoolExp>;
};


export type QueryRootZoneArgs = {
  distinct_on?: InputMaybe<Array<ZoneSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ZoneOrderBy>>;
  where?: InputMaybe<ZoneBoolExp>;
};


export type QueryRootZoneAggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoneSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ZoneOrderBy>>;
  where?: InputMaybe<ZoneBoolExp>;
};


export type QueryRootZoneByPkArgs = {
  id: Scalars['Int']['input'];
};

/** columns and relationships of "role_permission" */
export type RolePermission = {
  __typename?: 'role_permission';
  /** An object relationship */
  enumeration: Enumeration;
  /** An object relationship */
  enumerationByRoleId: Enumeration;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  permission_id: Scalars['Int']['output'];
  role_id: Scalars['Int']['output'];
};


/** columns and relationships of "role_permission" */
export type RolePermissionMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "role_permission" */
export type RolePermissionAggregate = {
  __typename?: 'role_permission_aggregate';
  aggregate?: Maybe<RolePermissionAggregateFields>;
  nodes: Array<RolePermission>;
};

export type RolePermissionAggregateBoolExp = {
  count?: InputMaybe<RolePermissionAggregateBoolExpCount>;
};

export type RolePermissionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<RolePermissionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<RolePermissionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "role_permission" */
export type RolePermissionAggregateFields = {
  __typename?: 'role_permission_aggregate_fields';
  avg?: Maybe<RolePermissionAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RolePermissionMaxFields>;
  min?: Maybe<RolePermissionMinFields>;
  stddev?: Maybe<RolePermissionStddevFields>;
  stddev_pop?: Maybe<RolePermissionStddevPopFields>;
  stddev_samp?: Maybe<RolePermissionStddevSampFields>;
  sum?: Maybe<RolePermissionSumFields>;
  var_pop?: Maybe<RolePermissionVarPopFields>;
  var_samp?: Maybe<RolePermissionVarSampFields>;
  variance?: Maybe<RolePermissionVarianceFields>;
};


/** aggregate fields of "role_permission" */
export type RolePermissionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RolePermissionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "role_permission" */
export type RolePermissionAggregateOrderBy = {
  avg?: InputMaybe<RolePermissionAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<RolePermissionMaxOrderBy>;
  min?: InputMaybe<RolePermissionMinOrderBy>;
  stddev?: InputMaybe<RolePermissionStddevOrderBy>;
  stddev_pop?: InputMaybe<RolePermissionStddevPopOrderBy>;
  stddev_samp?: InputMaybe<RolePermissionStddevSampOrderBy>;
  sum?: InputMaybe<RolePermissionSumOrderBy>;
  var_pop?: InputMaybe<RolePermissionVarPopOrderBy>;
  var_samp?: InputMaybe<RolePermissionVarSampOrderBy>;
  variance?: InputMaybe<RolePermissionVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type RolePermissionAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "role_permission" */
export type RolePermissionArrRelInsertInput = {
  data: Array<RolePermissionInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<RolePermissionOnConflict>;
};

/** aggregate avg on columns */
export type RolePermissionAvgFields = {
  __typename?: 'role_permission_avg_fields';
  permission_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "role_permission" */
export type RolePermissionAvgOrderBy = {
  permission_id?: InputMaybe<OrderBy>;
  role_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "role_permission". All fields are combined with a logical 'AND'. */
export type RolePermissionBoolExp = {
  _and?: InputMaybe<Array<RolePermissionBoolExp>>;
  _not?: InputMaybe<RolePermissionBoolExp>;
  _or?: InputMaybe<Array<RolePermissionBoolExp>>;
  enumeration?: InputMaybe<EnumerationBoolExp>;
  enumerationByRoleId?: InputMaybe<EnumerationBoolExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  permission_id?: InputMaybe<IntComparisonExp>;
  role_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "role_permission" */
export enum RolePermissionConstraint {
  /** unique or primary key constraint on columns "role_id", "permission_id" */
  role_permission_uq = 'role_permission_uq'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type RolePermissionDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type RolePermissionDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type RolePermissionDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "role_permission" */
export type RolePermissionIncInput = {
  permission_id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "role_permission" */
export type RolePermissionInsertInput = {
  enumeration?: InputMaybe<EnumerationObjRelInsertInput>;
  enumerationByRoleId?: InputMaybe<EnumerationObjRelInsertInput>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  permission_id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type RolePermissionMaxFields = {
  __typename?: 'role_permission_max_fields';
  permission_id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "role_permission" */
export type RolePermissionMaxOrderBy = {
  permission_id?: InputMaybe<OrderBy>;
  role_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type RolePermissionMinFields = {
  __typename?: 'role_permission_min_fields';
  permission_id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "role_permission" */
export type RolePermissionMinOrderBy = {
  permission_id?: InputMaybe<OrderBy>;
  role_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "role_permission" */
export type RolePermissionMutationResponse = {
  __typename?: 'role_permission_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RolePermission>;
};

/** on_conflict condition type for table "role_permission" */
export type RolePermissionOnConflict = {
  constraint: RolePermissionConstraint;
  update_columns?: Array<RolePermissionUpdateColumn>;
  where?: InputMaybe<RolePermissionBoolExp>;
};

/** Ordering options when selecting data from "role_permission". */
export type RolePermissionOrderBy = {
  enumeration?: InputMaybe<EnumerationOrderBy>;
  enumerationByRoleId?: InputMaybe<EnumerationOrderBy>;
  metadata?: InputMaybe<OrderBy>;
  permission_id?: InputMaybe<OrderBy>;
  role_id?: InputMaybe<OrderBy>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type RolePermissionPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "role_permission" */
export enum RolePermissionSelectColumn {
  /** column name */
  metadata = 'metadata',
  /** column name */
  permission_id = 'permission_id',
  /** column name */
  role_id = 'role_id'
}

/** input type for updating data in table "role_permission" */
export type RolePermissionSetInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  permission_id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type RolePermissionStddevFields = {
  __typename?: 'role_permission_stddev_fields';
  permission_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "role_permission" */
export type RolePermissionStddevOrderBy = {
  permission_id?: InputMaybe<OrderBy>;
  role_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type RolePermissionStddevPopFields = {
  __typename?: 'role_permission_stddev_pop_fields';
  permission_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "role_permission" */
export type RolePermissionStddevPopOrderBy = {
  permission_id?: InputMaybe<OrderBy>;
  role_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type RolePermissionStddevSampFields = {
  __typename?: 'role_permission_stddev_samp_fields';
  permission_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "role_permission" */
export type RolePermissionStddevSampOrderBy = {
  permission_id?: InputMaybe<OrderBy>;
  role_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "role_permission" */
export type RolePermissionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RolePermissionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RolePermissionStreamCursorValueInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  permission_id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type RolePermissionSumFields = {
  __typename?: 'role_permission_sum_fields';
  permission_id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "role_permission" */
export type RolePermissionSumOrderBy = {
  permission_id?: InputMaybe<OrderBy>;
  role_id?: InputMaybe<OrderBy>;
};

/** update columns of table "role_permission" */
export enum RolePermissionUpdateColumn {
  /** column name */
  metadata = 'metadata',
  /** column name */
  permission_id = 'permission_id',
  /** column name */
  role_id = 'role_id'
}

export type RolePermissionUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<RolePermissionAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<RolePermissionDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<RolePermissionDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<RolePermissionDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RolePermissionIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<RolePermissionPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RolePermissionSetInput>;
  /** filter the rows which have to be updated */
  where: RolePermissionBoolExp;
};

/** aggregate var_pop on columns */
export type RolePermissionVarPopFields = {
  __typename?: 'role_permission_var_pop_fields';
  permission_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "role_permission" */
export type RolePermissionVarPopOrderBy = {
  permission_id?: InputMaybe<OrderBy>;
  role_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type RolePermissionVarSampFields = {
  __typename?: 'role_permission_var_samp_fields';
  permission_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "role_permission" */
export type RolePermissionVarSampOrderBy = {
  permission_id?: InputMaybe<OrderBy>;
  role_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type RolePermissionVarianceFields = {
  __typename?: 'role_permission_variance_fields';
  permission_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "role_permission" */
export type RolePermissionVarianceOrderBy = {
  permission_id?: InputMaybe<OrderBy>;
  role_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "sensor" */
export type Sensor = {
  __typename?: 'sensor';
  component_uid: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** An array relationship */
  measurements: Array<Measurement>;
  /** An aggregate relationship */
  measurements_aggregate: MeasurementAggregate;
  metadata?: Maybe<Scalars['jsonb']['output']>;
};


/** columns and relationships of "sensor" */
export type SensorMeasurementsArgs = {
  distinct_on?: InputMaybe<Array<MeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementOrderBy>>;
  where?: InputMaybe<MeasurementBoolExp>;
};


/** columns and relationships of "sensor" */
export type SensorMeasurementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementOrderBy>>;
  where?: InputMaybe<MeasurementBoolExp>;
};


/** columns and relationships of "sensor" */
export type SensorMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "sensor" */
export type SensorAggregate = {
  __typename?: 'sensor_aggregate';
  aggregate?: Maybe<SensorAggregateFields>;
  nodes: Array<Sensor>;
};

/** aggregate fields of "sensor" */
export type SensorAggregateFields = {
  __typename?: 'sensor_aggregate_fields';
  avg?: Maybe<SensorAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<SensorMaxFields>;
  min?: Maybe<SensorMinFields>;
  stddev?: Maybe<SensorStddevFields>;
  stddev_pop?: Maybe<SensorStddevPopFields>;
  stddev_samp?: Maybe<SensorStddevSampFields>;
  sum?: Maybe<SensorSumFields>;
  var_pop?: Maybe<SensorVarPopFields>;
  var_samp?: Maybe<SensorVarSampFields>;
  variance?: Maybe<SensorVarianceFields>;
};


/** aggregate fields of "sensor" */
export type SensorAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SensorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type SensorAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type SensorAvgFields = {
  __typename?: 'sensor_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "sensor". All fields are combined with a logical 'AND'. */
export type SensorBoolExp = {
  _and?: InputMaybe<Array<SensorBoolExp>>;
  _not?: InputMaybe<SensorBoolExp>;
  _or?: InputMaybe<Array<SensorBoolExp>>;
  component_uid?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  measurements?: InputMaybe<MeasurementBoolExp>;
  measurements_aggregate?: InputMaybe<MeasurementAggregateBoolExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
};

/** unique or primary key constraints on table "sensor" */
export enum SensorConstraint {
  /** unique or primary key constraint on columns "component_uid" */
  sensor_component_uid_un = 'sensor_component_uid_un',
  /** unique or primary key constraint on columns "id" */
  sensor_pkey = 'sensor_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type SensorDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type SensorDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type SensorDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "sensor" */
export type SensorIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "sensor" */
export type SensorInsertInput = {
  component_uid?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  measurements?: InputMaybe<MeasurementArrRelInsertInput>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type SensorMaxFields = {
  __typename?: 'sensor_max_fields';
  component_uid?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type SensorMinFields = {
  __typename?: 'sensor_min_fields';
  component_uid?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "sensor" */
export type SensorMutationResponse = {
  __typename?: 'sensor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Sensor>;
};

/** input type for inserting object relation for remote table "sensor" */
export type SensorObjRelInsertInput = {
  data: SensorInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<SensorOnConflict>;
};

/** on_conflict condition type for table "sensor" */
export type SensorOnConflict = {
  constraint: SensorConstraint;
  update_columns?: Array<SensorUpdateColumn>;
  where?: InputMaybe<SensorBoolExp>;
};

/** Ordering options when selecting data from "sensor". */
export type SensorOrderBy = {
  component_uid?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  measurements_aggregate?: InputMaybe<MeasurementAggregateOrderBy>;
  metadata?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: sensor */
export type SensorPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type SensorPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "sensor" */
export enum SensorSelectColumn {
  /** column name */
  component_uid = 'component_uid',
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata'
}

/** input type for updating data in table "sensor" */
export type SensorSetInput = {
  component_uid?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate stddev on columns */
export type SensorStddevFields = {
  __typename?: 'sensor_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type SensorStddevPopFields = {
  __typename?: 'sensor_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type SensorStddevSampFields = {
  __typename?: 'sensor_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "sensor" */
export type SensorStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: SensorStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SensorStreamCursorValueInput = {
  component_uid?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate sum on columns */
export type SensorSumFields = {
  __typename?: 'sensor_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "sensor" */
export enum SensorUpdateColumn {
  /** column name */
  component_uid = 'component_uid',
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata'
}

export type SensorUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<SensorAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<SensorDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<SensorDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<SensorDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SensorIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<SensorPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SensorSetInput>;
  /** filter the rows which have to be updated */
  where: SensorBoolExp;
};

/** aggregate var_pop on columns */
export type SensorVarPopFields = {
  __typename?: 'sensor_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type SensorVarSampFields = {
  __typename?: 'sensor_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type SensorVarianceFields = {
  __typename?: 'sensor_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "spatial_ref_sys" */
export type SpatialRefSys = {
  __typename?: 'spatial_ref_sys';
  auth_name?: Maybe<Scalars['String']['output']>;
  auth_srid?: Maybe<Scalars['Int']['output']>;
  proj4text?: Maybe<Scalars['String']['output']>;
  srid: Scalars['Int']['output'];
  srtext?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "spatial_ref_sys" */
export type SpatialRefSysAggregate = {
  __typename?: 'spatial_ref_sys_aggregate';
  aggregate?: Maybe<SpatialRefSysAggregateFields>;
  nodes: Array<SpatialRefSys>;
};

/** aggregate fields of "spatial_ref_sys" */
export type SpatialRefSysAggregateFields = {
  __typename?: 'spatial_ref_sys_aggregate_fields';
  avg?: Maybe<SpatialRefSysAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<SpatialRefSysMaxFields>;
  min?: Maybe<SpatialRefSysMinFields>;
  stddev?: Maybe<SpatialRefSysStddevFields>;
  stddev_pop?: Maybe<SpatialRefSysStddevPopFields>;
  stddev_samp?: Maybe<SpatialRefSysStddevSampFields>;
  sum?: Maybe<SpatialRefSysSumFields>;
  var_pop?: Maybe<SpatialRefSysVarPopFields>;
  var_samp?: Maybe<SpatialRefSysVarSampFields>;
  variance?: Maybe<SpatialRefSysVarianceFields>;
};


/** aggregate fields of "spatial_ref_sys" */
export type SpatialRefSysAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SpatialRefSysSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type SpatialRefSysAvgFields = {
  __typename?: 'spatial_ref_sys_avg_fields';
  auth_srid?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "spatial_ref_sys". All fields are combined with a logical 'AND'. */
export type SpatialRefSysBoolExp = {
  _and?: InputMaybe<Array<SpatialRefSysBoolExp>>;
  _not?: InputMaybe<SpatialRefSysBoolExp>;
  _or?: InputMaybe<Array<SpatialRefSysBoolExp>>;
  auth_name?: InputMaybe<StringComparisonExp>;
  auth_srid?: InputMaybe<IntComparisonExp>;
  proj4text?: InputMaybe<StringComparisonExp>;
  srid?: InputMaybe<IntComparisonExp>;
  srtext?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "spatial_ref_sys" */
export enum SpatialRefSysConstraint {
  /** unique or primary key constraint on columns "srid" */
  spatial_ref_sys_pkey = 'spatial_ref_sys_pkey'
}

/** input type for incrementing numeric columns in table "spatial_ref_sys" */
export type SpatialRefSysIncInput = {
  auth_srid?: InputMaybe<Scalars['Int']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "spatial_ref_sys" */
export type SpatialRefSysInsertInput = {
  auth_name?: InputMaybe<Scalars['String']['input']>;
  auth_srid?: InputMaybe<Scalars['Int']['input']>;
  proj4text?: InputMaybe<Scalars['String']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
  srtext?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type SpatialRefSysMaxFields = {
  __typename?: 'spatial_ref_sys_max_fields';
  auth_name?: Maybe<Scalars['String']['output']>;
  auth_srid?: Maybe<Scalars['Int']['output']>;
  proj4text?: Maybe<Scalars['String']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  srtext?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type SpatialRefSysMinFields = {
  __typename?: 'spatial_ref_sys_min_fields';
  auth_name?: Maybe<Scalars['String']['output']>;
  auth_srid?: Maybe<Scalars['Int']['output']>;
  proj4text?: Maybe<Scalars['String']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  srtext?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "spatial_ref_sys" */
export type SpatialRefSysMutationResponse = {
  __typename?: 'spatial_ref_sys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<SpatialRefSys>;
};

/** on_conflict condition type for table "spatial_ref_sys" */
export type SpatialRefSysOnConflict = {
  constraint: SpatialRefSysConstraint;
  update_columns?: Array<SpatialRefSysUpdateColumn>;
  where?: InputMaybe<SpatialRefSysBoolExp>;
};

/** Ordering options when selecting data from "spatial_ref_sys". */
export type SpatialRefSysOrderBy = {
  auth_name?: InputMaybe<OrderBy>;
  auth_srid?: InputMaybe<OrderBy>;
  proj4text?: InputMaybe<OrderBy>;
  srid?: InputMaybe<OrderBy>;
  srtext?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: spatial_ref_sys */
export type SpatialRefSysPkColumnsInput = {
  srid: Scalars['Int']['input'];
};

/** select columns of table "spatial_ref_sys" */
export enum SpatialRefSysSelectColumn {
  /** column name */
  auth_name = 'auth_name',
  /** column name */
  auth_srid = 'auth_srid',
  /** column name */
  proj4text = 'proj4text',
  /** column name */
  srid = 'srid',
  /** column name */
  srtext = 'srtext'
}

/** input type for updating data in table "spatial_ref_sys" */
export type SpatialRefSysSetInput = {
  auth_name?: InputMaybe<Scalars['String']['input']>;
  auth_srid?: InputMaybe<Scalars['Int']['input']>;
  proj4text?: InputMaybe<Scalars['String']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
  srtext?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type SpatialRefSysStddevFields = {
  __typename?: 'spatial_ref_sys_stddev_fields';
  auth_srid?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type SpatialRefSysStddevPopFields = {
  __typename?: 'spatial_ref_sys_stddev_pop_fields';
  auth_srid?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type SpatialRefSysStddevSampFields = {
  __typename?: 'spatial_ref_sys_stddev_samp_fields';
  auth_srid?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "spatial_ref_sys" */
export type SpatialRefSysStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: SpatialRefSysStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SpatialRefSysStreamCursorValueInput = {
  auth_name?: InputMaybe<Scalars['String']['input']>;
  auth_srid?: InputMaybe<Scalars['Int']['input']>;
  proj4text?: InputMaybe<Scalars['String']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
  srtext?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type SpatialRefSysSumFields = {
  __typename?: 'spatial_ref_sys_sum_fields';
  auth_srid?: Maybe<Scalars['Int']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "spatial_ref_sys" */
export enum SpatialRefSysUpdateColumn {
  /** column name */
  auth_name = 'auth_name',
  /** column name */
  auth_srid = 'auth_srid',
  /** column name */
  proj4text = 'proj4text',
  /** column name */
  srid = 'srid',
  /** column name */
  srtext = 'srtext'
}

export type SpatialRefSysUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SpatialRefSysIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SpatialRefSysSetInput>;
  /** filter the rows which have to be updated */
  where: SpatialRefSysBoolExp;
};

/** aggregate var_pop on columns */
export type SpatialRefSysVarPopFields = {
  __typename?: 'spatial_ref_sys_var_pop_fields';
  auth_srid?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type SpatialRefSysVarSampFields = {
  __typename?: 'spatial_ref_sys_var_samp_fields';
  auth_srid?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type SpatialRefSysVarianceFields = {
  __typename?: 'spatial_ref_sys_variance_fields';
  auth_srid?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

export type StDWithinGeographyInput = {
  distance: Scalars['Float']['input'];
  from: Scalars['geography']['input'];
  use_spheroid?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StDWithinInput = {
  distance: Scalars['Float']['input'];
  from: Scalars['geometry']['input'];
};

export type SubscriptionRoot = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: AddressAggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table in a streaming manner: "address" */
  address_stream: Array<Address>;
  /** fetch data from the table: "aggregate_heatmap" */
  aggregate_heatmap: Array<AggregateHeatmap>;
  /** fetch aggregated fields from the table: "aggregate_heatmap" */
  aggregate_heatmap_aggregate: AggregateHeatmapAggregate;
  /** fetch data from the table: "aggregate_heatmap" using primary key columns */
  aggregate_heatmap_by_pk?: Maybe<AggregateHeatmap>;
  /** fetch data from the table in a streaming manner: "aggregate_heatmap" */
  aggregate_heatmap_stream: Array<AggregateHeatmap>;
  /** fetch data from the table: "alembic_version" */
  alembic_version: Array<AlembicVersion>;
  /** fetch aggregated fields from the table: "alembic_version" */
  alembic_version_aggregate: AlembicVersionAggregate;
  /** fetch data from the table: "alembic_version" using primary key columns */
  alembic_version_by_pk?: Maybe<AlembicVersion>;
  /** fetch data from the table in a streaming manner: "alembic_version" */
  alembic_version_stream: Array<AlembicVersion>;
  /** fetch data from the table: "classification" */
  classification: Array<Classification>;
  /** fetch aggregated fields from the table: "classification" */
  classification_aggregate: ClassificationAggregate;
  /** fetch data from the table: "classification" using primary key columns */
  classification_by_pk?: Maybe<Classification>;
  /** fetch data from the table in a streaming manner: "classification" */
  classification_stream: Array<Classification>;
  /** fetch data from the table: "comment" */
  comment: Array<Comment>;
  /** fetch aggregated fields from the table: "comment" */
  comment_aggregate: CommentAggregate;
  /** fetch data from the table: "comment" using primary key columns */
  comment_by_pk?: Maybe<Comment>;
  /** fetch data from the table in a streaming manner: "comment" */
  comment_stream: Array<Comment>;
  /** fetch data from the table: "compute" */
  compute: Array<Compute>;
  /** fetch aggregated fields from the table: "compute" */
  compute_aggregate: ComputeAggregate;
  /** fetch data from the table: "compute" using primary key columns */
  compute_by_pk?: Maybe<Compute>;
  /** fetch data from the table in a streaming manner: "compute" */
  compute_stream: Array<Compute>;
  /** fetch data from the table: "computed_measurement" */
  computed_measurement: Array<ComputedMeasurement>;
  /** fetch aggregated fields from the table: "computed_measurement" */
  computed_measurement_aggregate: ComputedMeasurementAggregate;
  /** fetch data from the table: "computed_measurement" using primary key columns */
  computed_measurement_by_pk?: Maybe<ComputedMeasurement>;
  /** fetch data from the table in a streaming manner: "computed_measurement" */
  computed_measurement_stream: Array<ComputedMeasurement>;
  /** fetch data from the table: "config" */
  config: Array<Config>;
  /** fetch aggregated fields from the table: "config" */
  config_aggregate: ConfigAggregate;
  /** fetch data from the table: "config" using primary key columns */
  config_by_pk?: Maybe<Config>;
  /** fetch data from the table in a streaming manner: "config" */
  config_stream: Array<Config>;
  /** fetch data from the table: "detection" */
  detection: Array<Detection>;
  /** fetch aggregated fields from the table: "detection" */
  detection_aggregate: DetectionAggregate;
  /** fetch data from the table: "detection" using primary key columns */
  detection_by_pk?: Maybe<Detection>;
  /** fetch data from the table: "detection_run" */
  detection_run: Array<DetectionRun>;
  /** fetch aggregated fields from the table: "detection_run" */
  detection_run_aggregate: DetectionRunAggregate;
  /** fetch data from the table: "detection_run" using primary key columns */
  detection_run_by_pk?: Maybe<DetectionRun>;
  /** fetch data from the table in a streaming manner: "detection_run" */
  detection_run_stream: Array<DetectionRun>;
  /** fetch data from the table in a streaming manner: "detection" */
  detection_stream: Array<Detection>;
  /** fetch data from the table: "detector" */
  detector: Array<Detector>;
  /** fetch aggregated fields from the table: "detector" */
  detector_aggregate: DetectorAggregate;
  /** fetch data from the table: "detector" using primary key columns */
  detector_by_pk?: Maybe<Detector>;
  /** fetch data from the table in a streaming manner: "detector" */
  detector_stream: Array<Detector>;
  /** fetch data from the table: "device_datum" */
  device_datum: Array<DeviceDatum>;
  /** fetch aggregated fields from the table: "device_datum" */
  device_datum_aggregate: DeviceDatumAggregate;
  /** fetch data from the table: "device_datum" using primary key columns */
  device_datum_by_pk?: Maybe<DeviceDatum>;
  /** fetch data from the table in a streaming manner: "device_datum" */
  device_datum_stream: Array<DeviceDatum>;
  /** fetch data from the table: "device_metadata" */
  device_metadata: Array<DeviceMetadata>;
  /** fetch aggregated fields from the table: "device_metadata" */
  device_metadata_aggregate: DeviceMetadataAggregate;
  /** fetch data from the table: "device_metadata" using primary key columns */
  device_metadata_by_pk?: Maybe<DeviceMetadata>;
  /** fetch data from the table in a streaming manner: "device_metadata" */
  device_metadata_stream: Array<DeviceMetadata>;
  /** fetch data from the table: "discussion" */
  discussion: Array<Discussion>;
  /** fetch aggregated fields from the table: "discussion" */
  discussion_aggregate: DiscussionAggregate;
  /** fetch data from the table: "discussion" using primary key columns */
  discussion_by_pk?: Maybe<Discussion>;
  /** fetch data from the table in a streaming manner: "discussion" */
  discussion_stream: Array<Discussion>;
  /** fetch data from the table: "enumeration" */
  enumeration: Array<Enumeration>;
  /** fetch aggregated fields from the table: "enumeration" */
  enumeration_aggregate: EnumerationAggregate;
  /** fetch data from the table: "enumeration" using primary key columns */
  enumeration_by_pk?: Maybe<Enumeration>;
  /** fetch data from the table in a streaming manner: "enumeration" */
  enumeration_stream: Array<Enumeration>;
  /** fetch data from the table: "environment_recipe" */
  environment_recipe: Array<EnvironmentRecipe>;
  /** fetch aggregated fields from the table: "environment_recipe" */
  environment_recipe_aggregate: EnvironmentRecipeAggregate;
  /** fetch data from the table: "environment_recipe" using primary key columns */
  environment_recipe_by_pk?: Maybe<EnvironmentRecipe>;
  /** fetch data from the table: "environment_recipe_entry" */
  environment_recipe_entry: Array<EnvironmentRecipeEntry>;
  /** fetch aggregated fields from the table: "environment_recipe_entry" */
  environment_recipe_entry_aggregate: EnvironmentRecipeEntryAggregate;
  /** fetch data from the table: "environment_recipe_entry" using primary key columns */
  environment_recipe_entry_by_pk?: Maybe<EnvironmentRecipeEntry>;
  /** fetch data from the table in a streaming manner: "environment_recipe_entry" */
  environment_recipe_entry_stream: Array<EnvironmentRecipeEntry>;
  /** fetch data from the table in a streaming manner: "environment_recipe" */
  environment_recipe_stream: Array<EnvironmentRecipe>;
  /** fetch data from the table: "field_intervention" */
  field_intervention: Array<FieldIntervention>;
  /** fetch aggregated fields from the table: "field_intervention" */
  field_intervention_aggregate: FieldInterventionAggregate;
  /** fetch data from the table: "field_intervention" using primary key columns */
  field_intervention_by_pk?: Maybe<FieldIntervention>;
  /** fetch data from the table in a streaming manner: "field_intervention" */
  field_intervention_stream: Array<FieldIntervention>;
  /** fetch data from the table: "geography_columns" */
  geography_columns: Array<GeographyColumns>;
  /** fetch aggregated fields from the table: "geography_columns" */
  geography_columns_aggregate: GeographyColumnsAggregate;
  /** fetch data from the table in a streaming manner: "geography_columns" */
  geography_columns_stream: Array<GeographyColumns>;
  /** fetch data from the table: "geometry_columns" */
  geometry_columns: Array<GeometryColumns>;
  /** fetch aggregated fields from the table: "geometry_columns" */
  geometry_columns_aggregate: GeometryColumnsAggregate;
  /** fetch data from the table in a streaming manner: "geometry_columns" */
  geometry_columns_stream: Array<GeometryColumns>;
  /** execute function "get_measurements_on_grid" which returns "get_measurements_on_grid_format_table" */
  get_measurements_on_grid: Array<GetMeasurementsOnGridFormatTable>;
  /** execute function "get_measurements_on_grid" and query aggregates on result of table type "get_measurements_on_grid_format_table" */
  get_measurements_on_grid_aggregate: GetMeasurementsOnGridFormatTableAggregate;
  /** fetch data from the table: "get_measurements_on_grid_format_table" */
  get_measurements_on_grid_format_table: Array<GetMeasurementsOnGridFormatTable>;
  /** fetch aggregated fields from the table: "get_measurements_on_grid_format_table" */
  get_measurements_on_grid_format_table_aggregate: GetMeasurementsOnGridFormatTableAggregate;
  /** fetch data from the table in a streaming manner: "get_measurements_on_grid_format_table" */
  get_measurements_on_grid_format_table_stream: Array<GetMeasurementsOnGridFormatTable>;
  /** fetch data from the table: "get_most_recent_vpd_measurements_format_table" */
  get_most_recent_vpd_measurements_format_table: Array<GetMostRecentVpdMeasurementsFormatTable>;
  /** fetch aggregated fields from the table: "get_most_recent_vpd_measurements_format_table" */
  get_most_recent_vpd_measurements_format_table_aggregate: GetMostRecentVpdMeasurementsFormatTableAggregate;
  /** fetch data from the table in a streaming manner: "get_most_recent_vpd_measurements_format_table" */
  get_most_recent_vpd_measurements_format_table_stream: Array<GetMostRecentVpdMeasurementsFormatTable>;
  /** fetch data from the table: "grid" */
  grid: Array<Grid>;
  /** fetch aggregated fields from the table: "grid" */
  grid_aggregate: GridAggregate;
  /** fetch data from the table: "grid" using primary key columns */
  grid_by_pk?: Maybe<Grid>;
  /** fetch data from the table: "grid_data" */
  grid_data: Array<GridData>;
  /** fetch aggregated fields from the table: "grid_data" */
  grid_data_aggregate: GridDataAggregate;
  /** fetch data from the table in a streaming manner: "grid_data" */
  grid_data_stream: Array<GridData>;
  /** fetch data from the table: "grid_element" */
  grid_element: Array<GridElement>;
  /** fetch aggregated fields from the table: "grid_element" */
  grid_element_aggregate: GridElementAggregate;
  /** fetch data from the table: "grid_element" using primary key columns */
  grid_element_by_pk?: Maybe<GridElement>;
  /** fetch data from the table in a streaming manner: "grid_element" */
  grid_element_stream: Array<GridElement>;
  /** fetch data from the table in a streaming manner: "grid" */
  grid_stream: Array<Grid>;
  /** fetch data from the table: "grid_view" */
  grid_view: Array<GridView>;
  /** fetch aggregated fields from the table: "grid_view" */
  grid_view_aggregate: GridViewAggregate;
  /** fetch data from the table in a streaming manner: "grid_view" */
  grid_view_stream: Array<GridView>;
  /** fetch data from the table: "growth_cycle" */
  growth_cycle: Array<GrowthCycle>;
  /** fetch aggregated fields from the table: "growth_cycle" */
  growth_cycle_aggregate: GrowthCycleAggregate;
  /** fetch data from the table: "growth_cycle" using primary key columns */
  growth_cycle_by_pk?: Maybe<GrowthCycle>;
  /** fetch data from the table in a streaming manner: "growth_cycle" */
  growth_cycle_stream: Array<GrowthCycle>;
  /** fetch data from the table: "heat_map" */
  heat_map: Array<HeatMap>;
  /** fetch aggregated fields from the table: "heat_map" */
  heat_map_aggregate: HeatMapAggregate;
  /** fetch data from the table: "heat_map" using primary key columns */
  heat_map_by_pk?: Maybe<HeatMap>;
  /** fetch data from the table in a streaming manner: "heat_map" */
  heat_map_stream: Array<HeatMap>;
  /** fetch data from the table: "image_annotation_tracking" */
  image_annotation_tracking: Array<ImageAnnotationTracking>;
  /** fetch aggregated fields from the table: "image_annotation_tracking" */
  image_annotation_tracking_aggregate: ImageAnnotationTrackingAggregate;
  /** fetch data from the table: "image_annotation_tracking" using primary key columns */
  image_annotation_tracking_by_pk?: Maybe<ImageAnnotationTracking>;
  /** fetch data from the table in a streaming manner: "image_annotation_tracking" */
  image_annotation_tracking_stream: Array<ImageAnnotationTracking>;
  /** fetch data from the table: "image_data" */
  image_data: Array<ImageData>;
  /** fetch aggregated fields from the table: "image_data" */
  image_data_aggregate: ImageDataAggregate;
  /** fetch data from the table in a streaming manner: "image_data" */
  image_data_stream: Array<ImageData>;
  /** fetch data from the table: "label" */
  label: Array<Label>;
  /** fetch aggregated fields from the table: "label" */
  label_aggregate: LabelAggregate;
  /** fetch data from the table: "label" using primary key columns */
  label_by_pk?: Maybe<Label>;
  /** fetch data from the table: "label_category" */
  label_category: Array<LabelCategory>;
  /** fetch aggregated fields from the table: "label_category" */
  label_category_aggregate: LabelCategoryAggregate;
  /** fetch data from the table: "label_category" using primary key columns */
  label_category_by_pk?: Maybe<LabelCategory>;
  /** fetch data from the table in a streaming manner: "label_category" */
  label_category_stream: Array<LabelCategory>;
  /** fetch data from the table: "label_counts_by_measurement" */
  label_counts_by_measurement: Array<LabelCountsByMeasurement>;
  /** fetch aggregated fields from the table: "label_counts_by_measurement" */
  label_counts_by_measurement_aggregate: LabelCountsByMeasurementAggregate;
  /** fetch data from the table in a streaming manner: "label_counts_by_measurement" */
  label_counts_by_measurement_stream: Array<LabelCountsByMeasurement>;
  /** fetch data from the table in a streaming manner: "label" */
  label_stream: Array<Label>;
  /** fetch data from the table: "label_task" */
  label_task: Array<LabelTask>;
  /** fetch aggregated fields from the table: "label_task" */
  label_task_aggregate: LabelTaskAggregate;
  /** fetch data from the table: "label_task" using primary key columns */
  label_task_by_pk?: Maybe<LabelTask>;
  /** fetch data from the table in a streaming manner: "label_task" */
  label_task_stream: Array<LabelTask>;
  /** fetch data from the table: "lambda_run" */
  lambda_run: Array<LambdaRun>;
  /** fetch aggregated fields from the table: "lambda_run" */
  lambda_run_aggregate: LambdaRunAggregate;
  /** fetch data from the table: "lambda_run" using primary key columns */
  lambda_run_by_pk?: Maybe<LambdaRun>;
  /** fetch data from the table: "lambda_run_measurement_run" */
  lambda_run_measurement_run: Array<LambdaRunMeasurementRun>;
  /** fetch aggregated fields from the table: "lambda_run_measurement_run" */
  lambda_run_measurement_run_aggregate: LambdaRunMeasurementRunAggregate;
  /** fetch data from the table: "lambda_run_measurement_run" using primary key columns */
  lambda_run_measurement_run_by_pk?: Maybe<LambdaRunMeasurementRun>;
  /** fetch data from the table in a streaming manner: "lambda_run_measurement_run" */
  lambda_run_measurement_run_stream: Array<LambdaRunMeasurementRun>;
  /** fetch data from the table in a streaming manner: "lambda_run" */
  lambda_run_stream: Array<LambdaRun>;
  /** fetch data from the table: "lambda_version" */
  lambda_version: Array<LambdaVersion>;
  /** fetch aggregated fields from the table: "lambda_version" */
  lambda_version_aggregate: LambdaVersionAggregate;
  /** fetch data from the table: "lambda_version" using primary key columns */
  lambda_version_by_pk?: Maybe<LambdaVersion>;
  /** fetch data from the table in a streaming manner: "lambda_version" */
  lambda_version_stream: Array<LambdaVersion>;
  /** fetch data from the table: "light_cycle_recipe" */
  light_cycle_recipe: Array<LightCycleRecipe>;
  /** fetch aggregated fields from the table: "light_cycle_recipe" */
  light_cycle_recipe_aggregate: LightCycleRecipeAggregate;
  /** fetch data from the table: "light_cycle_recipe" using primary key columns */
  light_cycle_recipe_by_pk?: Maybe<LightCycleRecipe>;
  /** fetch data from the table: "light_cycle_recipe_entry" */
  light_cycle_recipe_entry: Array<LightCycleRecipeEntry>;
  /** fetch aggregated fields from the table: "light_cycle_recipe_entry" */
  light_cycle_recipe_entry_aggregate: LightCycleRecipeEntryAggregate;
  /** fetch data from the table: "light_cycle_recipe_entry" using primary key columns */
  light_cycle_recipe_entry_by_pk?: Maybe<LightCycleRecipeEntry>;
  /** fetch data from the table in a streaming manner: "light_cycle_recipe_entry" */
  light_cycle_recipe_entry_stream: Array<LightCycleRecipeEntry>;
  /** fetch data from the table in a streaming manner: "light_cycle_recipe" */
  light_cycle_recipe_stream: Array<LightCycleRecipe>;
  /** fetch data from the table: "location" */
  location: Array<Location>;
  /** fetch aggregated fields from the table: "location" */
  location_aggregate: LocationAggregate;
  /** fetch data from the table: "location" using primary key columns */
  location_by_pk?: Maybe<Location>;
  /** fetch data from the table in a streaming manner: "location" */
  location_stream: Array<Location>;
  /** fetch data from the table: "measurement" */
  measurement: Array<Measurement>;
  /** fetch aggregated fields from the table: "measurement" */
  measurement_aggregate: MeasurementAggregate;
  /** fetch data from the table: "measurement" using primary key columns */
  measurement_by_pk?: Maybe<Measurement>;
  /** fetch data from the table: "measurement_run" */
  measurement_run: Array<MeasurementRun>;
  /** fetch aggregated fields from the table: "measurement_run" */
  measurement_run_aggregate: MeasurementRunAggregate;
  /** fetch data from the table: "measurement_run" using primary key columns */
  measurement_run_by_pk?: Maybe<MeasurementRun>;
  /** fetch data from the table in a streaming manner: "measurement_run" */
  measurement_run_stream: Array<MeasurementRun>;
  /** fetch data from the table in a streaming manner: "measurement" */
  measurement_stream: Array<Measurement>;
  /** fetch data from the table: "measurement_view" */
  measurement_view: Array<MeasurementView>;
  /** fetch aggregated fields from the table: "measurement_view" */
  measurement_view_aggregate: MeasurementViewAggregate;
  /** fetch data from the table in a streaming manner: "measurement_view" */
  measurement_view_stream: Array<MeasurementView>;
  /** fetch data from the table: "measurements_by_grid_index" */
  measurements_by_grid_index: Array<MeasurementsByGridIndex>;
  /** fetch aggregated fields from the table: "measurements_by_grid_index" */
  measurements_by_grid_index_aggregate: MeasurementsByGridIndexAggregate;
  /** fetch data from the table in a streaming manner: "measurements_by_grid_index" */
  measurements_by_grid_index_stream: Array<MeasurementsByGridIndex>;
  /** fetch data from the table: "measurements_on_grid" */
  measurements_on_grid: Array<MeasurementsOnGrid>;
  /** fetch aggregated fields from the table: "measurements_on_grid" */
  measurements_on_grid_aggregate: MeasurementsOnGridAggregate;
  /** fetch data from the table in a streaming manner: "measurements_on_grid" */
  measurements_on_grid_stream: Array<MeasurementsOnGrid>;
  /** fetch data from the table: "notification" */
  notification: Array<Notification>;
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: NotificationAggregate;
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<Notification>;
  /** fetch data from the table in a streaming manner: "notification" */
  notification_stream: Array<Notification>;
  /** fetch data from the table: "organization" */
  organization: Array<Organization>;
  /** fetch aggregated fields from the table: "organization" */
  organization_aggregate: OrganizationAggregate;
  /** fetch data from the table: "organization" using primary key columns */
  organization_by_pk?: Maybe<Organization>;
  /** fetch data from the table in a streaming manner: "organization" */
  organization_stream: Array<Organization>;
  /** An array relationship */
  parameters: Array<Parameters>;
  /** An aggregate relationship */
  parameters_aggregate: ParametersAggregate;
  /** fetch data from the table: "parameters" using primary key columns */
  parameters_by_pk?: Maybe<Parameters>;
  /** fetch data from the table in a streaming manner: "parameters" */
  parameters_stream: Array<Parameters>;
  /** fetch data from the table: "pose" */
  pose: Array<Pose>;
  /** fetch aggregated fields from the table: "pose" */
  pose_aggregate: PoseAggregate;
  /** fetch data from the table: "pose" using primary key columns */
  pose_by_pk?: Maybe<Pose>;
  /** fetch data from the table in a streaming manner: "pose" */
  pose_stream: Array<Pose>;
  /** fetch data from the table: "proto" */
  proto: Array<Proto>;
  /** fetch aggregated fields from the table: "proto" */
  proto_aggregate: ProtoAggregate;
  /** fetch data from the table: "proto" using primary key columns */
  proto_by_pk?: Maybe<Proto>;
  /** fetch data from the table in a streaming manner: "proto" */
  proto_stream: Array<Proto>;
  /** fetch data from the table: "role_permission" */
  role_permission: Array<RolePermission>;
  /** fetch aggregated fields from the table: "role_permission" */
  role_permission_aggregate: RolePermissionAggregate;
  /** fetch data from the table in a streaming manner: "role_permission" */
  role_permission_stream: Array<RolePermission>;
  /** fetch data from the table: "sensor" */
  sensor: Array<Sensor>;
  /** fetch aggregated fields from the table: "sensor" */
  sensor_aggregate: SensorAggregate;
  /** fetch data from the table: "sensor" using primary key columns */
  sensor_by_pk?: Maybe<Sensor>;
  /** fetch data from the table in a streaming manner: "sensor" */
  sensor_stream: Array<Sensor>;
  /** fetch data from the table: "spatial_ref_sys" */
  spatial_ref_sys: Array<SpatialRefSys>;
  /** fetch aggregated fields from the table: "spatial_ref_sys" */
  spatial_ref_sys_aggregate: SpatialRefSysAggregate;
  /** fetch data from the table: "spatial_ref_sys" using primary key columns */
  spatial_ref_sys_by_pk?: Maybe<SpatialRefSys>;
  /** fetch data from the table in a streaming manner: "spatial_ref_sys" */
  spatial_ref_sys_stream: Array<SpatialRefSys>;
  /** fetch data from the table: "system" */
  system: Array<System>;
  /** fetch aggregated fields from the table: "system" */
  system_aggregate: SystemAggregate;
  /** fetch data from the table: "system" using primary key columns */
  system_by_pk?: Maybe<System>;
  /** fetch data from the table in a streaming manner: "system" */
  system_stream: Array<System>;
  /** fetch data from the table: "track" */
  track: Array<Track>;
  /** fetch aggregated fields from the table: "track" */
  track_aggregate: TrackAggregate;
  /** fetch data from the table: "track" using primary key columns */
  track_by_pk?: Maybe<Track>;
  /** An array relationship */
  track_detections: Array<TrackDetections>;
  /** An aggregate relationship */
  track_detections_aggregate: TrackDetectionsAggregate;
  /** fetch data from the table: "track_detections" using primary key columns */
  track_detections_by_pk?: Maybe<TrackDetections>;
  /** fetch data from the table in a streaming manner: "track_detections" */
  track_detections_stream: Array<TrackDetections>;
  /** fetch data from the table in a streaming manner: "track" */
  track_stream: Array<Track>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: UserAggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_comment" */
  user_comment: Array<UserComment>;
  /** fetch aggregated fields from the table: "user_comment" */
  user_comment_aggregate: UserCommentAggregate;
  /** fetch data from the table: "user_comment" using primary key columns */
  user_comment_by_pk?: Maybe<UserComment>;
  /** fetch data from the table in a streaming manner: "user_comment" */
  user_comment_stream: Array<UserComment>;
  /** fetch data from the table: "user_label" */
  user_label: Array<UserLabel>;
  /** fetch aggregated fields from the table: "user_label" */
  user_label_aggregate: UserLabelAggregate;
  /** fetch data from the table: "user_label" using primary key columns */
  user_label_by_pk?: Maybe<UserLabel>;
  /** fetch data from the table in a streaming manner: "user_label" */
  user_label_stream: Array<UserLabel>;
  /** fetch data from the table: "user_notification" */
  user_notification: Array<UserNotification>;
  /** fetch aggregated fields from the table: "user_notification" */
  user_notification_aggregate: UserNotificationAggregate;
  /** fetch data from the table: "user_notification" using primary key columns */
  user_notification_by_pk?: Maybe<UserNotification>;
  /** fetch data from the table: "user_notification_setting" */
  user_notification_setting: Array<UserNotificationSetting>;
  /** fetch aggregated fields from the table: "user_notification_setting" */
  user_notification_setting_aggregate: UserNotificationSettingAggregate;
  /** fetch data from the table: "user_notification_setting" using primary key columns */
  user_notification_setting_by_pk?: Maybe<UserNotificationSetting>;
  /** fetch data from the table in a streaming manner: "user_notification_setting" */
  user_notification_setting_stream: Array<UserNotificationSetting>;
  /** fetch data from the table in a streaming manner: "user_notification" */
  user_notification_stream: Array<UserNotification>;
  /** fetch data from the table: "user_organization_role" */
  user_organization_role: Array<UserOrganizationRole>;
  /** fetch aggregated fields from the table: "user_organization_role" */
  user_organization_role_aggregate: UserOrganizationRoleAggregate;
  /** fetch data from the table: "user_organization_role" using primary key columns */
  user_organization_role_by_pk?: Maybe<UserOrganizationRole>;
  /** fetch data from the table in a streaming manner: "user_organization_role" */
  user_organization_role_stream: Array<UserOrganizationRole>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
  /** fetch data from the table: "version" */
  version: Array<Version>;
  /** fetch aggregated fields from the table: "version" */
  version_aggregate: VersionAggregate;
  /** fetch data from the table: "version" using primary key columns */
  version_by_pk?: Maybe<Version>;
  /** fetch data from the table in a streaming manner: "version" */
  version_stream: Array<Version>;
  /** fetch data from the table: "vpd_view" */
  vpd_view: Array<VpdView>;
  /** fetch aggregated fields from the table: "vpd_view" */
  vpd_view_aggregate: VpdViewAggregate;
  /** fetch data from the table in a streaming manner: "vpd_view" */
  vpd_view_stream: Array<VpdView>;
  /** fetch data from the table: "zone" */
  zone: Array<Zone>;
  /** fetch aggregated fields from the table: "zone" */
  zone_aggregate: ZoneAggregate;
  /** fetch data from the table: "zone" using primary key columns */
  zone_by_pk?: Maybe<Zone>;
  /** fetch data from the table in a streaming manner: "zone" */
  zone_stream: Array<Zone>;
};


export type SubscriptionRootAddressArgs = {
  distinct_on?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};


export type SubscriptionRootAddressAggregateArgs = {
  distinct_on?: InputMaybe<Array<AddressSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AddressOrderBy>>;
  where?: InputMaybe<AddressBoolExp>;
};


export type SubscriptionRootAddressByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootAddressStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AddressStreamCursorInput>>;
  where?: InputMaybe<AddressBoolExp>;
};


export type SubscriptionRootAggregateHeatmapArgs = {
  distinct_on?: InputMaybe<Array<AggregateHeatmapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AggregateHeatmapOrderBy>>;
  where?: InputMaybe<AggregateHeatmapBoolExp>;
};


export type SubscriptionRootAggregateHeatmapAggregateArgs = {
  distinct_on?: InputMaybe<Array<AggregateHeatmapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AggregateHeatmapOrderBy>>;
  where?: InputMaybe<AggregateHeatmapBoolExp>;
};


export type SubscriptionRootAggregateHeatmapByPkArgs = {
  uid: Scalars['uuid']['input'];
};


export type SubscriptionRootAggregateHeatmapStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AggregateHeatmapStreamCursorInput>>;
  where?: InputMaybe<AggregateHeatmapBoolExp>;
};


export type SubscriptionRootAlembicVersionArgs = {
  distinct_on?: InputMaybe<Array<AlembicVersionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AlembicVersionOrderBy>>;
  where?: InputMaybe<AlembicVersionBoolExp>;
};


export type SubscriptionRootAlembicVersionAggregateArgs = {
  distinct_on?: InputMaybe<Array<AlembicVersionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AlembicVersionOrderBy>>;
  where?: InputMaybe<AlembicVersionBoolExp>;
};


export type SubscriptionRootAlembicVersionByPkArgs = {
  version_num: Scalars['String']['input'];
};


export type SubscriptionRootAlembicVersionStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AlembicVersionStreamCursorInput>>;
  where?: InputMaybe<AlembicVersionBoolExp>;
};


export type SubscriptionRootClassificationArgs = {
  distinct_on?: InputMaybe<Array<ClassificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ClassificationOrderBy>>;
  where?: InputMaybe<ClassificationBoolExp>;
};


export type SubscriptionRootClassificationAggregateArgs = {
  distinct_on?: InputMaybe<Array<ClassificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ClassificationOrderBy>>;
  where?: InputMaybe<ClassificationBoolExp>;
};


export type SubscriptionRootClassificationByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootClassificationStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ClassificationStreamCursorInput>>;
  where?: InputMaybe<ClassificationBoolExp>;
};


export type SubscriptionRootCommentArgs = {
  distinct_on?: InputMaybe<Array<CommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CommentOrderBy>>;
  where?: InputMaybe<CommentBoolExp>;
};


export type SubscriptionRootCommentAggregateArgs = {
  distinct_on?: InputMaybe<Array<CommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CommentOrderBy>>;
  where?: InputMaybe<CommentBoolExp>;
};


export type SubscriptionRootCommentByPkArgs = {
  uid: Scalars['uuid']['input'];
};


export type SubscriptionRootCommentStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CommentStreamCursorInput>>;
  where?: InputMaybe<CommentBoolExp>;
};


export type SubscriptionRootComputeArgs = {
  distinct_on?: InputMaybe<Array<ComputeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputeOrderBy>>;
  where?: InputMaybe<ComputeBoolExp>;
};


export type SubscriptionRootComputeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ComputeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputeOrderBy>>;
  where?: InputMaybe<ComputeBoolExp>;
};


export type SubscriptionRootComputeByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootComputeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ComputeStreamCursorInput>>;
  where?: InputMaybe<ComputeBoolExp>;
};


export type SubscriptionRootComputedMeasurementArgs = {
  distinct_on?: InputMaybe<Array<ComputedMeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputedMeasurementOrderBy>>;
  where?: InputMaybe<ComputedMeasurementBoolExp>;
};


export type SubscriptionRootComputedMeasurementAggregateArgs = {
  distinct_on?: InputMaybe<Array<ComputedMeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputedMeasurementOrderBy>>;
  where?: InputMaybe<ComputedMeasurementBoolExp>;
};


export type SubscriptionRootComputedMeasurementByPkArgs = {
  id: Scalars['bigint']['input'];
  time: Scalars['timestamptz']['input'];
};


export type SubscriptionRootComputedMeasurementStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ComputedMeasurementStreamCursorInput>>;
  where?: InputMaybe<ComputedMeasurementBoolExp>;
};


export type SubscriptionRootConfigArgs = {
  distinct_on?: InputMaybe<Array<ConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConfigOrderBy>>;
  where?: InputMaybe<ConfigBoolExp>;
};


export type SubscriptionRootConfigAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConfigOrderBy>>;
  where?: InputMaybe<ConfigBoolExp>;
};


export type SubscriptionRootConfigByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootConfigStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ConfigStreamCursorInput>>;
  where?: InputMaybe<ConfigBoolExp>;
};


export type SubscriptionRootDetectionArgs = {
  distinct_on?: InputMaybe<Array<DetectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectionOrderBy>>;
  where?: InputMaybe<DetectionBoolExp>;
};


export type SubscriptionRootDetectionAggregateArgs = {
  distinct_on?: InputMaybe<Array<DetectionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectionOrderBy>>;
  where?: InputMaybe<DetectionBoolExp>;
};


export type SubscriptionRootDetectionByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootDetectionRunArgs = {
  distinct_on?: InputMaybe<Array<DetectionRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectionRunOrderBy>>;
  where?: InputMaybe<DetectionRunBoolExp>;
};


export type SubscriptionRootDetectionRunAggregateArgs = {
  distinct_on?: InputMaybe<Array<DetectionRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectionRunOrderBy>>;
  where?: InputMaybe<DetectionRunBoolExp>;
};


export type SubscriptionRootDetectionRunByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootDetectionRunStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DetectionRunStreamCursorInput>>;
  where?: InputMaybe<DetectionRunBoolExp>;
};


export type SubscriptionRootDetectionStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DetectionStreamCursorInput>>;
  where?: InputMaybe<DetectionBoolExp>;
};


export type SubscriptionRootDetectorArgs = {
  distinct_on?: InputMaybe<Array<DetectorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectorOrderBy>>;
  where?: InputMaybe<DetectorBoolExp>;
};


export type SubscriptionRootDetectorAggregateArgs = {
  distinct_on?: InputMaybe<Array<DetectorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectorOrderBy>>;
  where?: InputMaybe<DetectorBoolExp>;
};


export type SubscriptionRootDetectorByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootDetectorStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DetectorStreamCursorInput>>;
  where?: InputMaybe<DetectorBoolExp>;
};


export type SubscriptionRootDeviceDatumArgs = {
  distinct_on?: InputMaybe<Array<DeviceDatumSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeviceDatumOrderBy>>;
  where?: InputMaybe<DeviceDatumBoolExp>;
};


export type SubscriptionRootDeviceDatumAggregateArgs = {
  distinct_on?: InputMaybe<Array<DeviceDatumSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeviceDatumOrderBy>>;
  where?: InputMaybe<DeviceDatumBoolExp>;
};


export type SubscriptionRootDeviceDatumByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootDeviceDatumStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DeviceDatumStreamCursorInput>>;
  where?: InputMaybe<DeviceDatumBoolExp>;
};


export type SubscriptionRootDeviceMetadataArgs = {
  distinct_on?: InputMaybe<Array<DeviceMetadataSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeviceMetadataOrderBy>>;
  where?: InputMaybe<DeviceMetadataBoolExp>;
};


export type SubscriptionRootDeviceMetadataAggregateArgs = {
  distinct_on?: InputMaybe<Array<DeviceMetadataSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DeviceMetadataOrderBy>>;
  where?: InputMaybe<DeviceMetadataBoolExp>;
};


export type SubscriptionRootDeviceMetadataByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootDeviceMetadataStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DeviceMetadataStreamCursorInput>>;
  where?: InputMaybe<DeviceMetadataBoolExp>;
};


export type SubscriptionRootDiscussionArgs = {
  distinct_on?: InputMaybe<Array<DiscussionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DiscussionOrderBy>>;
  where?: InputMaybe<DiscussionBoolExp>;
};


export type SubscriptionRootDiscussionAggregateArgs = {
  distinct_on?: InputMaybe<Array<DiscussionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DiscussionOrderBy>>;
  where?: InputMaybe<DiscussionBoolExp>;
};


export type SubscriptionRootDiscussionByPkArgs = {
  uid: Scalars['uuid']['input'];
};


export type SubscriptionRootDiscussionStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DiscussionStreamCursorInput>>;
  where?: InputMaybe<DiscussionBoolExp>;
};


export type SubscriptionRootEnumerationArgs = {
  distinct_on?: InputMaybe<Array<EnumerationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EnumerationOrderBy>>;
  where?: InputMaybe<EnumerationBoolExp>;
};


export type SubscriptionRootEnumerationAggregateArgs = {
  distinct_on?: InputMaybe<Array<EnumerationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EnumerationOrderBy>>;
  where?: InputMaybe<EnumerationBoolExp>;
};


export type SubscriptionRootEnumerationByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootEnumerationStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<EnumerationStreamCursorInput>>;
  where?: InputMaybe<EnumerationBoolExp>;
};


export type SubscriptionRootEnvironmentRecipeArgs = {
  distinct_on?: InputMaybe<Array<EnvironmentRecipeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EnvironmentRecipeOrderBy>>;
  where?: InputMaybe<EnvironmentRecipeBoolExp>;
};


export type SubscriptionRootEnvironmentRecipeAggregateArgs = {
  distinct_on?: InputMaybe<Array<EnvironmentRecipeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EnvironmentRecipeOrderBy>>;
  where?: InputMaybe<EnvironmentRecipeBoolExp>;
};


export type SubscriptionRootEnvironmentRecipeByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootEnvironmentRecipeEntryArgs = {
  distinct_on?: InputMaybe<Array<EnvironmentRecipeEntrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EnvironmentRecipeEntryOrderBy>>;
  where?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
};


export type SubscriptionRootEnvironmentRecipeEntryAggregateArgs = {
  distinct_on?: InputMaybe<Array<EnvironmentRecipeEntrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<EnvironmentRecipeEntryOrderBy>>;
  where?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
};


export type SubscriptionRootEnvironmentRecipeEntryByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootEnvironmentRecipeEntryStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<EnvironmentRecipeEntryStreamCursorInput>>;
  where?: InputMaybe<EnvironmentRecipeEntryBoolExp>;
};


export type SubscriptionRootEnvironmentRecipeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<EnvironmentRecipeStreamCursorInput>>;
  where?: InputMaybe<EnvironmentRecipeBoolExp>;
};


export type SubscriptionRootFieldInterventionArgs = {
  distinct_on?: InputMaybe<Array<FieldInterventionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FieldInterventionOrderBy>>;
  where?: InputMaybe<FieldInterventionBoolExp>;
};


export type SubscriptionRootFieldInterventionAggregateArgs = {
  distinct_on?: InputMaybe<Array<FieldInterventionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FieldInterventionOrderBy>>;
  where?: InputMaybe<FieldInterventionBoolExp>;
};


export type SubscriptionRootFieldInterventionByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootFieldInterventionStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<FieldInterventionStreamCursorInput>>;
  where?: InputMaybe<FieldInterventionBoolExp>;
};


export type SubscriptionRootGeographyColumnsArgs = {
  distinct_on?: InputMaybe<Array<GeographyColumnsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GeographyColumnsOrderBy>>;
  where?: InputMaybe<GeographyColumnsBoolExp>;
};


export type SubscriptionRootGeographyColumnsAggregateArgs = {
  distinct_on?: InputMaybe<Array<GeographyColumnsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GeographyColumnsOrderBy>>;
  where?: InputMaybe<GeographyColumnsBoolExp>;
};


export type SubscriptionRootGeographyColumnsStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GeographyColumnsStreamCursorInput>>;
  where?: InputMaybe<GeographyColumnsBoolExp>;
};


export type SubscriptionRootGeometryColumnsArgs = {
  distinct_on?: InputMaybe<Array<GeometryColumnsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GeometryColumnsOrderBy>>;
  where?: InputMaybe<GeometryColumnsBoolExp>;
};


export type SubscriptionRootGeometryColumnsAggregateArgs = {
  distinct_on?: InputMaybe<Array<GeometryColumnsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GeometryColumnsOrderBy>>;
  where?: InputMaybe<GeometryColumnsBoolExp>;
};


export type SubscriptionRootGeometryColumnsStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GeometryColumnsStreamCursorInput>>;
  where?: InputMaybe<GeometryColumnsBoolExp>;
};


export type SubscriptionRootGetMeasurementsOnGridArgs = {
  args: GetMeasurementsOnGridArgs;
  distinct_on?: InputMaybe<Array<GetMeasurementsOnGridFormatTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GetMeasurementsOnGridFormatTableOrderBy>>;
  where?: InputMaybe<GetMeasurementsOnGridFormatTableBoolExp>;
};


export type SubscriptionRootGetMeasurementsOnGridAggregateArgs = {
  args: GetMeasurementsOnGridArgs;
  distinct_on?: InputMaybe<Array<GetMeasurementsOnGridFormatTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GetMeasurementsOnGridFormatTableOrderBy>>;
  where?: InputMaybe<GetMeasurementsOnGridFormatTableBoolExp>;
};


export type SubscriptionRootGetMeasurementsOnGridFormatTableArgs = {
  distinct_on?: InputMaybe<Array<GetMeasurementsOnGridFormatTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GetMeasurementsOnGridFormatTableOrderBy>>;
  where?: InputMaybe<GetMeasurementsOnGridFormatTableBoolExp>;
};


export type SubscriptionRootGetMeasurementsOnGridFormatTableAggregateArgs = {
  distinct_on?: InputMaybe<Array<GetMeasurementsOnGridFormatTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GetMeasurementsOnGridFormatTableOrderBy>>;
  where?: InputMaybe<GetMeasurementsOnGridFormatTableBoolExp>;
};


export type SubscriptionRootGetMeasurementsOnGridFormatTableStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GetMeasurementsOnGridFormatTableStreamCursorInput>>;
  where?: InputMaybe<GetMeasurementsOnGridFormatTableBoolExp>;
};


export type SubscriptionRootGetMostRecentVpdMeasurementsFormatTableArgs = {
  distinct_on?: InputMaybe<Array<GetMostRecentVpdMeasurementsFormatTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GetMostRecentVpdMeasurementsFormatTableOrderBy>>;
  where?: InputMaybe<GetMostRecentVpdMeasurementsFormatTableBoolExp>;
};


export type SubscriptionRootGetMostRecentVpdMeasurementsFormatTableAggregateArgs = {
  distinct_on?: InputMaybe<Array<GetMostRecentVpdMeasurementsFormatTableSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GetMostRecentVpdMeasurementsFormatTableOrderBy>>;
  where?: InputMaybe<GetMostRecentVpdMeasurementsFormatTableBoolExp>;
};


export type SubscriptionRootGetMostRecentVpdMeasurementsFormatTableStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GetMostRecentVpdMeasurementsFormatTableStreamCursorInput>>;
  where?: InputMaybe<GetMostRecentVpdMeasurementsFormatTableBoolExp>;
};


export type SubscriptionRootGridArgs = {
  distinct_on?: InputMaybe<Array<GridSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GridOrderBy>>;
  where?: InputMaybe<GridBoolExp>;
};


export type SubscriptionRootGridAggregateArgs = {
  distinct_on?: InputMaybe<Array<GridSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GridOrderBy>>;
  where?: InputMaybe<GridBoolExp>;
};


export type SubscriptionRootGridByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootGridDataArgs = {
  distinct_on?: InputMaybe<Array<GridDataSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GridDataOrderBy>>;
  where?: InputMaybe<GridDataBoolExp>;
};


export type SubscriptionRootGridDataAggregateArgs = {
  distinct_on?: InputMaybe<Array<GridDataSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GridDataOrderBy>>;
  where?: InputMaybe<GridDataBoolExp>;
};


export type SubscriptionRootGridDataStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GridDataStreamCursorInput>>;
  where?: InputMaybe<GridDataBoolExp>;
};


export type SubscriptionRootGridElementArgs = {
  distinct_on?: InputMaybe<Array<GridElementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GridElementOrderBy>>;
  where?: InputMaybe<GridElementBoolExp>;
};


export type SubscriptionRootGridElementAggregateArgs = {
  distinct_on?: InputMaybe<Array<GridElementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GridElementOrderBy>>;
  where?: InputMaybe<GridElementBoolExp>;
};


export type SubscriptionRootGridElementByPkArgs = {
  grid_id: Scalars['Int']['input'];
  x_index: Scalars['Int']['input'];
  y_index: Scalars['Int']['input'];
  z_index: Scalars['Int']['input'];
};


export type SubscriptionRootGridElementStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GridElementStreamCursorInput>>;
  where?: InputMaybe<GridElementBoolExp>;
};


export type SubscriptionRootGridStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GridStreamCursorInput>>;
  where?: InputMaybe<GridBoolExp>;
};


export type SubscriptionRootGridViewArgs = {
  distinct_on?: InputMaybe<Array<GridViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GridViewOrderBy>>;
  where?: InputMaybe<GridViewBoolExp>;
};


export type SubscriptionRootGridViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<GridViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GridViewOrderBy>>;
  where?: InputMaybe<GridViewBoolExp>;
};


export type SubscriptionRootGridViewStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GridViewStreamCursorInput>>;
  where?: InputMaybe<GridViewBoolExp>;
};


export type SubscriptionRootGrowthCycleArgs = {
  distinct_on?: InputMaybe<Array<GrowthCycleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GrowthCycleOrderBy>>;
  where?: InputMaybe<GrowthCycleBoolExp>;
};


export type SubscriptionRootGrowthCycleAggregateArgs = {
  distinct_on?: InputMaybe<Array<GrowthCycleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GrowthCycleOrderBy>>;
  where?: InputMaybe<GrowthCycleBoolExp>;
};


export type SubscriptionRootGrowthCycleByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootGrowthCycleStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<GrowthCycleStreamCursorInput>>;
  where?: InputMaybe<GrowthCycleBoolExp>;
};


export type SubscriptionRootHeatMapArgs = {
  distinct_on?: InputMaybe<Array<HeatMapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeatMapOrderBy>>;
  where?: InputMaybe<HeatMapBoolExp>;
};


export type SubscriptionRootHeatMapAggregateArgs = {
  distinct_on?: InputMaybe<Array<HeatMapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<HeatMapOrderBy>>;
  where?: InputMaybe<HeatMapBoolExp>;
};


export type SubscriptionRootHeatMapByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootHeatMapStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<HeatMapStreamCursorInput>>;
  where?: InputMaybe<HeatMapBoolExp>;
};


export type SubscriptionRootImageAnnotationTrackingArgs = {
  distinct_on?: InputMaybe<Array<ImageAnnotationTrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImageAnnotationTrackingOrderBy>>;
  where?: InputMaybe<ImageAnnotationTrackingBoolExp>;
};


export type SubscriptionRootImageAnnotationTrackingAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImageAnnotationTrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImageAnnotationTrackingOrderBy>>;
  where?: InputMaybe<ImageAnnotationTrackingBoolExp>;
};


export type SubscriptionRootImageAnnotationTrackingByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootImageAnnotationTrackingStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ImageAnnotationTrackingStreamCursorInput>>;
  where?: InputMaybe<ImageAnnotationTrackingBoolExp>;
};


export type SubscriptionRootImageDataArgs = {
  distinct_on?: InputMaybe<Array<ImageDataSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImageDataOrderBy>>;
  where?: InputMaybe<ImageDataBoolExp>;
};


export type SubscriptionRootImageDataAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImageDataSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImageDataOrderBy>>;
  where?: InputMaybe<ImageDataBoolExp>;
};


export type SubscriptionRootImageDataStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ImageDataStreamCursorInput>>;
  where?: InputMaybe<ImageDataBoolExp>;
};


export type SubscriptionRootLabelArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


export type SubscriptionRootLabelAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


export type SubscriptionRootLabelByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootLabelCategoryArgs = {
  distinct_on?: InputMaybe<Array<LabelCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelCategoryOrderBy>>;
  where?: InputMaybe<LabelCategoryBoolExp>;
};


export type SubscriptionRootLabelCategoryAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelCategorySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelCategoryOrderBy>>;
  where?: InputMaybe<LabelCategoryBoolExp>;
};


export type SubscriptionRootLabelCategoryByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootLabelCategoryStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<LabelCategoryStreamCursorInput>>;
  where?: InputMaybe<LabelCategoryBoolExp>;
};


export type SubscriptionRootLabelCountsByMeasurementArgs = {
  distinct_on?: InputMaybe<Array<LabelCountsByMeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelCountsByMeasurementOrderBy>>;
  where?: InputMaybe<LabelCountsByMeasurementBoolExp>;
};


export type SubscriptionRootLabelCountsByMeasurementAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelCountsByMeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelCountsByMeasurementOrderBy>>;
  where?: InputMaybe<LabelCountsByMeasurementBoolExp>;
};


export type SubscriptionRootLabelCountsByMeasurementStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<LabelCountsByMeasurementStreamCursorInput>>;
  where?: InputMaybe<LabelCountsByMeasurementBoolExp>;
};


export type SubscriptionRootLabelStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<LabelStreamCursorInput>>;
  where?: InputMaybe<LabelBoolExp>;
};


export type SubscriptionRootLabelTaskArgs = {
  distinct_on?: InputMaybe<Array<LabelTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelTaskOrderBy>>;
  where?: InputMaybe<LabelTaskBoolExp>;
};


export type SubscriptionRootLabelTaskAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelTaskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelTaskOrderBy>>;
  where?: InputMaybe<LabelTaskBoolExp>;
};


export type SubscriptionRootLabelTaskByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootLabelTaskStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<LabelTaskStreamCursorInput>>;
  where?: InputMaybe<LabelTaskBoolExp>;
};


export type SubscriptionRootLambdaRunArgs = {
  distinct_on?: InputMaybe<Array<LambdaRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LambdaRunOrderBy>>;
  where?: InputMaybe<LambdaRunBoolExp>;
};


export type SubscriptionRootLambdaRunAggregateArgs = {
  distinct_on?: InputMaybe<Array<LambdaRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LambdaRunOrderBy>>;
  where?: InputMaybe<LambdaRunBoolExp>;
};


export type SubscriptionRootLambdaRunByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootLambdaRunMeasurementRunArgs = {
  distinct_on?: InputMaybe<Array<LambdaRunMeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LambdaRunMeasurementRunOrderBy>>;
  where?: InputMaybe<LambdaRunMeasurementRunBoolExp>;
};


export type SubscriptionRootLambdaRunMeasurementRunAggregateArgs = {
  distinct_on?: InputMaybe<Array<LambdaRunMeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LambdaRunMeasurementRunOrderBy>>;
  where?: InputMaybe<LambdaRunMeasurementRunBoolExp>;
};


export type SubscriptionRootLambdaRunMeasurementRunByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootLambdaRunMeasurementRunStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<LambdaRunMeasurementRunStreamCursorInput>>;
  where?: InputMaybe<LambdaRunMeasurementRunBoolExp>;
};


export type SubscriptionRootLambdaRunStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<LambdaRunStreamCursorInput>>;
  where?: InputMaybe<LambdaRunBoolExp>;
};


export type SubscriptionRootLambdaVersionArgs = {
  distinct_on?: InputMaybe<Array<LambdaVersionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LambdaVersionOrderBy>>;
  where?: InputMaybe<LambdaVersionBoolExp>;
};


export type SubscriptionRootLambdaVersionAggregateArgs = {
  distinct_on?: InputMaybe<Array<LambdaVersionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LambdaVersionOrderBy>>;
  where?: InputMaybe<LambdaVersionBoolExp>;
};


export type SubscriptionRootLambdaVersionByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootLambdaVersionStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<LambdaVersionStreamCursorInput>>;
  where?: InputMaybe<LambdaVersionBoolExp>;
};


export type SubscriptionRootLightCycleRecipeArgs = {
  distinct_on?: InputMaybe<Array<LightCycleRecipeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LightCycleRecipeOrderBy>>;
  where?: InputMaybe<LightCycleRecipeBoolExp>;
};


export type SubscriptionRootLightCycleRecipeAggregateArgs = {
  distinct_on?: InputMaybe<Array<LightCycleRecipeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LightCycleRecipeOrderBy>>;
  where?: InputMaybe<LightCycleRecipeBoolExp>;
};


export type SubscriptionRootLightCycleRecipeByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootLightCycleRecipeEntryArgs = {
  distinct_on?: InputMaybe<Array<LightCycleRecipeEntrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LightCycleRecipeEntryOrderBy>>;
  where?: InputMaybe<LightCycleRecipeEntryBoolExp>;
};


export type SubscriptionRootLightCycleRecipeEntryAggregateArgs = {
  distinct_on?: InputMaybe<Array<LightCycleRecipeEntrySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LightCycleRecipeEntryOrderBy>>;
  where?: InputMaybe<LightCycleRecipeEntryBoolExp>;
};


export type SubscriptionRootLightCycleRecipeEntryByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootLightCycleRecipeEntryStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<LightCycleRecipeEntryStreamCursorInput>>;
  where?: InputMaybe<LightCycleRecipeEntryBoolExp>;
};


export type SubscriptionRootLightCycleRecipeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<LightCycleRecipeStreamCursorInput>>;
  where?: InputMaybe<LightCycleRecipeBoolExp>;
};


export type SubscriptionRootLocationArgs = {
  distinct_on?: InputMaybe<Array<LocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LocationOrderBy>>;
  where?: InputMaybe<LocationBoolExp>;
};


export type SubscriptionRootLocationAggregateArgs = {
  distinct_on?: InputMaybe<Array<LocationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LocationOrderBy>>;
  where?: InputMaybe<LocationBoolExp>;
};


export type SubscriptionRootLocationByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootLocationStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<LocationStreamCursorInput>>;
  where?: InputMaybe<LocationBoolExp>;
};


export type SubscriptionRootMeasurementArgs = {
  distinct_on?: InputMaybe<Array<MeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementOrderBy>>;
  where?: InputMaybe<MeasurementBoolExp>;
};


export type SubscriptionRootMeasurementAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementOrderBy>>;
  where?: InputMaybe<MeasurementBoolExp>;
};


export type SubscriptionRootMeasurementByPkArgs = {
  id: Scalars['bigint']['input'];
  time: Scalars['timestamptz']['input'];
};


export type SubscriptionRootMeasurementRunArgs = {
  distinct_on?: InputMaybe<Array<MeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementRunOrderBy>>;
  where?: InputMaybe<MeasurementRunBoolExp>;
};


export type SubscriptionRootMeasurementRunAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementRunOrderBy>>;
  where?: InputMaybe<MeasurementRunBoolExp>;
};


export type SubscriptionRootMeasurementRunByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootMeasurementRunStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MeasurementRunStreamCursorInput>>;
  where?: InputMaybe<MeasurementRunBoolExp>;
};


export type SubscriptionRootMeasurementStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MeasurementStreamCursorInput>>;
  where?: InputMaybe<MeasurementBoolExp>;
};


export type SubscriptionRootMeasurementViewArgs = {
  distinct_on?: InputMaybe<Array<MeasurementViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementViewOrderBy>>;
  where?: InputMaybe<MeasurementViewBoolExp>;
};


export type SubscriptionRootMeasurementViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementViewOrderBy>>;
  where?: InputMaybe<MeasurementViewBoolExp>;
};


export type SubscriptionRootMeasurementViewStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MeasurementViewStreamCursorInput>>;
  where?: InputMaybe<MeasurementViewBoolExp>;
};


export type SubscriptionRootMeasurementsByGridIndexArgs = {
  distinct_on?: InputMaybe<Array<MeasurementsByGridIndexSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementsByGridIndexOrderBy>>;
  where?: InputMaybe<MeasurementsByGridIndexBoolExp>;
};


export type SubscriptionRootMeasurementsByGridIndexAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementsByGridIndexSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementsByGridIndexOrderBy>>;
  where?: InputMaybe<MeasurementsByGridIndexBoolExp>;
};


export type SubscriptionRootMeasurementsByGridIndexStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MeasurementsByGridIndexStreamCursorInput>>;
  where?: InputMaybe<MeasurementsByGridIndexBoolExp>;
};


export type SubscriptionRootMeasurementsOnGridArgs = {
  distinct_on?: InputMaybe<Array<MeasurementsOnGridSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementsOnGridOrderBy>>;
  where?: InputMaybe<MeasurementsOnGridBoolExp>;
};


export type SubscriptionRootMeasurementsOnGridAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementsOnGridSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementsOnGridOrderBy>>;
  where?: InputMaybe<MeasurementsOnGridBoolExp>;
};


export type SubscriptionRootMeasurementsOnGridStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<MeasurementsOnGridStreamCursorInput>>;
  where?: InputMaybe<MeasurementsOnGridBoolExp>;
};


export type SubscriptionRootNotificationArgs = {
  distinct_on?: InputMaybe<Array<NotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotificationOrderBy>>;
  where?: InputMaybe<NotificationBoolExp>;
};


export type SubscriptionRootNotificationAggregateArgs = {
  distinct_on?: InputMaybe<Array<NotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NotificationOrderBy>>;
  where?: InputMaybe<NotificationBoolExp>;
};


export type SubscriptionRootNotificationByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootNotificationStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<NotificationStreamCursorInput>>;
  where?: InputMaybe<NotificationBoolExp>;
};


export type SubscriptionRootOrganizationArgs = {
  distinct_on?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};


export type SubscriptionRootOrganizationAggregateArgs = {
  distinct_on?: InputMaybe<Array<OrganizationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OrganizationOrderBy>>;
  where?: InputMaybe<OrganizationBoolExp>;
};


export type SubscriptionRootOrganizationByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootOrganizationStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<OrganizationStreamCursorInput>>;
  where?: InputMaybe<OrganizationBoolExp>;
};


export type SubscriptionRootParametersArgs = {
  distinct_on?: InputMaybe<Array<ParametersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ParametersOrderBy>>;
  where?: InputMaybe<ParametersBoolExp>;
};


export type SubscriptionRootParametersAggregateArgs = {
  distinct_on?: InputMaybe<Array<ParametersSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ParametersOrderBy>>;
  where?: InputMaybe<ParametersBoolExp>;
};


export type SubscriptionRootParametersByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootParametersStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ParametersStreamCursorInput>>;
  where?: InputMaybe<ParametersBoolExp>;
};


export type SubscriptionRootPoseArgs = {
  distinct_on?: InputMaybe<Array<PoseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PoseOrderBy>>;
  where?: InputMaybe<PoseBoolExp>;
};


export type SubscriptionRootPoseAggregateArgs = {
  distinct_on?: InputMaybe<Array<PoseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PoseOrderBy>>;
  where?: InputMaybe<PoseBoolExp>;
};


export type SubscriptionRootPoseByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootPoseStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PoseStreamCursorInput>>;
  where?: InputMaybe<PoseBoolExp>;
};


export type SubscriptionRootProtoArgs = {
  distinct_on?: InputMaybe<Array<ProtoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProtoOrderBy>>;
  where?: InputMaybe<ProtoBoolExp>;
};


export type SubscriptionRootProtoAggregateArgs = {
  distinct_on?: InputMaybe<Array<ProtoSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ProtoOrderBy>>;
  where?: InputMaybe<ProtoBoolExp>;
};


export type SubscriptionRootProtoByPkArgs = {
  filename: Scalars['String']['input'];
};


export type SubscriptionRootProtoStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ProtoStreamCursorInput>>;
  where?: InputMaybe<ProtoBoolExp>;
};


export type SubscriptionRootRolePermissionArgs = {
  distinct_on?: InputMaybe<Array<RolePermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RolePermissionOrderBy>>;
  where?: InputMaybe<RolePermissionBoolExp>;
};


export type SubscriptionRootRolePermissionAggregateArgs = {
  distinct_on?: InputMaybe<Array<RolePermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RolePermissionOrderBy>>;
  where?: InputMaybe<RolePermissionBoolExp>;
};


export type SubscriptionRootRolePermissionStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RolePermissionStreamCursorInput>>;
  where?: InputMaybe<RolePermissionBoolExp>;
};


export type SubscriptionRootSensorArgs = {
  distinct_on?: InputMaybe<Array<SensorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SensorOrderBy>>;
  where?: InputMaybe<SensorBoolExp>;
};


export type SubscriptionRootSensorAggregateArgs = {
  distinct_on?: InputMaybe<Array<SensorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SensorOrderBy>>;
  where?: InputMaybe<SensorBoolExp>;
};


export type SubscriptionRootSensorByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootSensorStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<SensorStreamCursorInput>>;
  where?: InputMaybe<SensorBoolExp>;
};


export type SubscriptionRootSpatialRefSysArgs = {
  distinct_on?: InputMaybe<Array<SpatialRefSysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SpatialRefSysOrderBy>>;
  where?: InputMaybe<SpatialRefSysBoolExp>;
};


export type SubscriptionRootSpatialRefSysAggregateArgs = {
  distinct_on?: InputMaybe<Array<SpatialRefSysSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SpatialRefSysOrderBy>>;
  where?: InputMaybe<SpatialRefSysBoolExp>;
};


export type SubscriptionRootSpatialRefSysByPkArgs = {
  srid: Scalars['Int']['input'];
};


export type SubscriptionRootSpatialRefSysStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<SpatialRefSysStreamCursorInput>>;
  where?: InputMaybe<SpatialRefSysBoolExp>;
};


export type SubscriptionRootSystemArgs = {
  distinct_on?: InputMaybe<Array<SystemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SystemOrderBy>>;
  where?: InputMaybe<SystemBoolExp>;
};


export type SubscriptionRootSystemAggregateArgs = {
  distinct_on?: InputMaybe<Array<SystemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SystemOrderBy>>;
  where?: InputMaybe<SystemBoolExp>;
};


export type SubscriptionRootSystemByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootSystemStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<SystemStreamCursorInput>>;
  where?: InputMaybe<SystemBoolExp>;
};


export type SubscriptionRootTrackArgs = {
  distinct_on?: InputMaybe<Array<TrackSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TrackOrderBy>>;
  where?: InputMaybe<TrackBoolExp>;
};


export type SubscriptionRootTrackAggregateArgs = {
  distinct_on?: InputMaybe<Array<TrackSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TrackOrderBy>>;
  where?: InputMaybe<TrackBoolExp>;
};


export type SubscriptionRootTrackByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootTrackDetectionsArgs = {
  distinct_on?: InputMaybe<Array<TrackDetectionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TrackDetectionsOrderBy>>;
  where?: InputMaybe<TrackDetectionsBoolExp>;
};


export type SubscriptionRootTrackDetectionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TrackDetectionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TrackDetectionsOrderBy>>;
  where?: InputMaybe<TrackDetectionsBoolExp>;
};


export type SubscriptionRootTrackDetectionsByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootTrackDetectionsStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TrackDetectionsStreamCursorInput>>;
  where?: InputMaybe<TrackDetectionsBoolExp>;
};


export type SubscriptionRootTrackStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TrackStreamCursorInput>>;
  where?: InputMaybe<TrackBoolExp>;
};


export type SubscriptionRootUserArgs = {
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type SubscriptionRootUserAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type SubscriptionRootUserByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootUserCommentArgs = {
  distinct_on?: InputMaybe<Array<UserCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserCommentOrderBy>>;
  where?: InputMaybe<UserCommentBoolExp>;
};


export type SubscriptionRootUserCommentAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserCommentOrderBy>>;
  where?: InputMaybe<UserCommentBoolExp>;
};


export type SubscriptionRootUserCommentByPkArgs = {
  comment_uid: Scalars['uuid']['input'];
  user_id: Scalars['Int']['input'];
};


export type SubscriptionRootUserCommentStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserCommentStreamCursorInput>>;
  where?: InputMaybe<UserCommentBoolExp>;
};


export type SubscriptionRootUserLabelArgs = {
  distinct_on?: InputMaybe<Array<UserLabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserLabelOrderBy>>;
  where?: InputMaybe<UserLabelBoolExp>;
};


export type SubscriptionRootUserLabelAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserLabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserLabelOrderBy>>;
  where?: InputMaybe<UserLabelBoolExp>;
};


export type SubscriptionRootUserLabelByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootUserLabelStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserLabelStreamCursorInput>>;
  where?: InputMaybe<UserLabelBoolExp>;
};


export type SubscriptionRootUserNotificationArgs = {
  distinct_on?: InputMaybe<Array<UserNotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserNotificationOrderBy>>;
  where?: InputMaybe<UserNotificationBoolExp>;
};


export type SubscriptionRootUserNotificationAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserNotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserNotificationOrderBy>>;
  where?: InputMaybe<UserNotificationBoolExp>;
};


export type SubscriptionRootUserNotificationByPkArgs = {
  notification_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};


export type SubscriptionRootUserNotificationSettingArgs = {
  distinct_on?: InputMaybe<Array<UserNotificationSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserNotificationSettingOrderBy>>;
  where?: InputMaybe<UserNotificationSettingBoolExp>;
};


export type SubscriptionRootUserNotificationSettingAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserNotificationSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserNotificationSettingOrderBy>>;
  where?: InputMaybe<UserNotificationSettingBoolExp>;
};


export type SubscriptionRootUserNotificationSettingByPkArgs = {
  uid: Scalars['uuid']['input'];
};


export type SubscriptionRootUserNotificationSettingStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserNotificationSettingStreamCursorInput>>;
  where?: InputMaybe<UserNotificationSettingBoolExp>;
};


export type SubscriptionRootUserNotificationStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserNotificationStreamCursorInput>>;
  where?: InputMaybe<UserNotificationBoolExp>;
};


export type SubscriptionRootUserOrganizationRoleArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizationRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrganizationRoleOrderBy>>;
  where?: InputMaybe<UserOrganizationRoleBoolExp>;
};


export type SubscriptionRootUserOrganizationRoleAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizationRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrganizationRoleOrderBy>>;
  where?: InputMaybe<UserOrganizationRoleBoolExp>;
};


export type SubscriptionRootUserOrganizationRoleByPkArgs = {
  id: Scalars['uuid']['input'];
};


export type SubscriptionRootUserOrganizationRoleStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserOrganizationRoleStreamCursorInput>>;
  where?: InputMaybe<UserOrganizationRoleBoolExp>;
};


export type SubscriptionRootUserStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserStreamCursorInput>>;
  where?: InputMaybe<UserBoolExp>;
};


export type SubscriptionRootVersionArgs = {
  distinct_on?: InputMaybe<Array<VersionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VersionOrderBy>>;
  where?: InputMaybe<VersionBoolExp>;
};


export type SubscriptionRootVersionAggregateArgs = {
  distinct_on?: InputMaybe<Array<VersionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VersionOrderBy>>;
  where?: InputMaybe<VersionBoolExp>;
};


export type SubscriptionRootVersionByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootVersionStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<VersionStreamCursorInput>>;
  where?: InputMaybe<VersionBoolExp>;
};


export type SubscriptionRootVpdViewArgs = {
  distinct_on?: InputMaybe<Array<VpdViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VpdViewOrderBy>>;
  where?: InputMaybe<VpdViewBoolExp>;
};


export type SubscriptionRootVpdViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<VpdViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VpdViewOrderBy>>;
  where?: InputMaybe<VpdViewBoolExp>;
};


export type SubscriptionRootVpdViewStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<VpdViewStreamCursorInput>>;
  where?: InputMaybe<VpdViewBoolExp>;
};


export type SubscriptionRootZoneArgs = {
  distinct_on?: InputMaybe<Array<ZoneSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ZoneOrderBy>>;
  where?: InputMaybe<ZoneBoolExp>;
};


export type SubscriptionRootZoneAggregateArgs = {
  distinct_on?: InputMaybe<Array<ZoneSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ZoneOrderBy>>;
  where?: InputMaybe<ZoneBoolExp>;
};


export type SubscriptionRootZoneByPkArgs = {
  id: Scalars['Int']['input'];
};


export type SubscriptionRootZoneStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ZoneStreamCursorInput>>;
  where?: InputMaybe<ZoneBoolExp>;
};

/** columns and relationships of "system" */
export type System = {
  __typename?: 'system';
  /** An array relationship */
  computes: Array<Compute>;
  /** An aggregate relationship */
  computes_aggregate: ComputeAggregate;
  /** An array relationship */
  configs: Array<Config>;
  /** An aggregate relationship */
  configs_aggregate: ConfigAggregate;
  /** An array relationship */
  field_interventions: Array<FieldIntervention>;
  /** An aggregate relationship */
  field_interventions_aggregate: FieldInterventionAggregate;
  /** An object relationship */
  hardwareVersion: Version;
  hardware_version_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  is_active: Scalars['Boolean']['output'];
  /** An array relationship */
  measurement_runs: Array<MeasurementRun>;
  /** An aggregate relationship */
  measurement_runs_aggregate: MeasurementRunAggregate;
  /** An array relationship */
  measurements: Array<Measurement>;
  /** An aggregate relationship */
  measurements_aggregate: MeasurementAggregate;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  organization: Organization;
  organization_id: Scalars['Int']['output'];
  /** An object relationship */
  softwareVersion: Version;
  software_version_id: Scalars['Int']['output'];
  system_status: Scalars['String']['output'];
  system_status_created_at: Scalars['timestamptz']['output'];
  system_status_updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  tracks: Array<Track>;
  /** An aggregate relationship */
  tracks_aggregate: TrackAggregate;
  uid: Scalars['String']['output'];
  /** An object relationship */
  zone: Zone;
  zone_id: Scalars['Int']['output'];
};


/** columns and relationships of "system" */
export type SystemComputesArgs = {
  distinct_on?: InputMaybe<Array<ComputeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputeOrderBy>>;
  where?: InputMaybe<ComputeBoolExp>;
};


/** columns and relationships of "system" */
export type SystemComputesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ComputeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputeOrderBy>>;
  where?: InputMaybe<ComputeBoolExp>;
};


/** columns and relationships of "system" */
export type SystemConfigsArgs = {
  distinct_on?: InputMaybe<Array<ConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConfigOrderBy>>;
  where?: InputMaybe<ConfigBoolExp>;
};


/** columns and relationships of "system" */
export type SystemConfigsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConfigOrderBy>>;
  where?: InputMaybe<ConfigBoolExp>;
};


/** columns and relationships of "system" */
export type SystemFieldInterventionsArgs = {
  distinct_on?: InputMaybe<Array<FieldInterventionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FieldInterventionOrderBy>>;
  where?: InputMaybe<FieldInterventionBoolExp>;
};


/** columns and relationships of "system" */
export type SystemFieldInterventionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<FieldInterventionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FieldInterventionOrderBy>>;
  where?: InputMaybe<FieldInterventionBoolExp>;
};


/** columns and relationships of "system" */
export type SystemMeasurementRunsArgs = {
  distinct_on?: InputMaybe<Array<MeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementRunOrderBy>>;
  where?: InputMaybe<MeasurementRunBoolExp>;
};


/** columns and relationships of "system" */
export type SystemMeasurementRunsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementRunOrderBy>>;
  where?: InputMaybe<MeasurementRunBoolExp>;
};


/** columns and relationships of "system" */
export type SystemMeasurementsArgs = {
  distinct_on?: InputMaybe<Array<MeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementOrderBy>>;
  where?: InputMaybe<MeasurementBoolExp>;
};


/** columns and relationships of "system" */
export type SystemMeasurementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementOrderBy>>;
  where?: InputMaybe<MeasurementBoolExp>;
};


/** columns and relationships of "system" */
export type SystemMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "system" */
export type SystemTracksArgs = {
  distinct_on?: InputMaybe<Array<TrackSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TrackOrderBy>>;
  where?: InputMaybe<TrackBoolExp>;
};


/** columns and relationships of "system" */
export type SystemTracksAggregateArgs = {
  distinct_on?: InputMaybe<Array<TrackSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TrackOrderBy>>;
  where?: InputMaybe<TrackBoolExp>;
};

/** aggregated selection of "system" */
export type SystemAggregate = {
  __typename?: 'system_aggregate';
  aggregate?: Maybe<SystemAggregateFields>;
  nodes: Array<System>;
};

export type SystemAggregateBoolExp = {
  bool_and?: InputMaybe<SystemAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<SystemAggregateBoolExpBoolOr>;
  count?: InputMaybe<SystemAggregateBoolExpCount>;
};

export type SystemAggregateBoolExpBoolAnd = {
  arguments: SystemSelectColumnSystemAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SystemBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SystemAggregateBoolExpBoolOr = {
  arguments: SystemSelectColumnSystemAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SystemBoolExp>;
  predicate: BooleanComparisonExp;
};

export type SystemAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<SystemSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<SystemBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "system" */
export type SystemAggregateFields = {
  __typename?: 'system_aggregate_fields';
  avg?: Maybe<SystemAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<SystemMaxFields>;
  min?: Maybe<SystemMinFields>;
  stddev?: Maybe<SystemStddevFields>;
  stddev_pop?: Maybe<SystemStddevPopFields>;
  stddev_samp?: Maybe<SystemStddevSampFields>;
  sum?: Maybe<SystemSumFields>;
  var_pop?: Maybe<SystemVarPopFields>;
  var_samp?: Maybe<SystemVarSampFields>;
  variance?: Maybe<SystemVarianceFields>;
};


/** aggregate fields of "system" */
export type SystemAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SystemSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "system" */
export type SystemAggregateOrderBy = {
  avg?: InputMaybe<SystemAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<SystemMaxOrderBy>;
  min?: InputMaybe<SystemMinOrderBy>;
  stddev?: InputMaybe<SystemStddevOrderBy>;
  stddev_pop?: InputMaybe<SystemStddevPopOrderBy>;
  stddev_samp?: InputMaybe<SystemStddevSampOrderBy>;
  sum?: InputMaybe<SystemSumOrderBy>;
  var_pop?: InputMaybe<SystemVarPopOrderBy>;
  var_samp?: InputMaybe<SystemVarSampOrderBy>;
  variance?: InputMaybe<SystemVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type SystemAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "system" */
export type SystemArrRelInsertInput = {
  data: Array<SystemInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<SystemOnConflict>;
};

/** aggregate avg on columns */
export type SystemAvgFields = {
  __typename?: 'system_avg_fields';
  hardware_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  software_version_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "system" */
export type SystemAvgOrderBy = {
  hardware_version_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  software_version_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "system". All fields are combined with a logical 'AND'. */
export type SystemBoolExp = {
  _and?: InputMaybe<Array<SystemBoolExp>>;
  _not?: InputMaybe<SystemBoolExp>;
  _or?: InputMaybe<Array<SystemBoolExp>>;
  computes?: InputMaybe<ComputeBoolExp>;
  computes_aggregate?: InputMaybe<ComputeAggregateBoolExp>;
  configs?: InputMaybe<ConfigBoolExp>;
  configs_aggregate?: InputMaybe<ConfigAggregateBoolExp>;
  field_interventions?: InputMaybe<FieldInterventionBoolExp>;
  field_interventions_aggregate?: InputMaybe<FieldInterventionAggregateBoolExp>;
  hardwareVersion?: InputMaybe<VersionBoolExp>;
  hardware_version_id?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  is_active?: InputMaybe<BooleanComparisonExp>;
  measurement_runs?: InputMaybe<MeasurementRunBoolExp>;
  measurement_runs_aggregate?: InputMaybe<MeasurementRunAggregateBoolExp>;
  measurements?: InputMaybe<MeasurementBoolExp>;
  measurements_aggregate?: InputMaybe<MeasurementAggregateBoolExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organization_id?: InputMaybe<IntComparisonExp>;
  softwareVersion?: InputMaybe<VersionBoolExp>;
  software_version_id?: InputMaybe<IntComparisonExp>;
  system_status?: InputMaybe<StringComparisonExp>;
  system_status_created_at?: InputMaybe<TimestamptzComparisonExp>;
  system_status_updated_at?: InputMaybe<TimestamptzComparisonExp>;
  tracks?: InputMaybe<TrackBoolExp>;
  tracks_aggregate?: InputMaybe<TrackAggregateBoolExp>;
  uid?: InputMaybe<StringComparisonExp>;
  zone?: InputMaybe<ZoneBoolExp>;
  zone_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "system" */
export enum SystemConstraint {
  /** unique or primary key constraint on columns "id" */
  system_pkey = 'system_pkey',
  /** unique or primary key constraint on columns "uid" */
  system_uid_unique = 'system_uid_unique'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type SystemDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type SystemDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type SystemDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "system" */
export type SystemIncInput = {
  hardware_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  software_version_id?: InputMaybe<Scalars['Int']['input']>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "system" */
export type SystemInsertInput = {
  computes?: InputMaybe<ComputeArrRelInsertInput>;
  configs?: InputMaybe<ConfigArrRelInsertInput>;
  field_interventions?: InputMaybe<FieldInterventionArrRelInsertInput>;
  hardwareVersion?: InputMaybe<VersionObjRelInsertInput>;
  hardware_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  measurement_runs?: InputMaybe<MeasurementRunArrRelInsertInput>;
  measurements?: InputMaybe<MeasurementArrRelInsertInput>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  softwareVersion?: InputMaybe<VersionObjRelInsertInput>;
  software_version_id?: InputMaybe<Scalars['Int']['input']>;
  system_status?: InputMaybe<Scalars['String']['input']>;
  system_status_created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  system_status_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  tracks?: InputMaybe<TrackArrRelInsertInput>;
  uid?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<ZoneObjRelInsertInput>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type SystemMaxFields = {
  __typename?: 'system_max_fields';
  hardware_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  software_version_id?: Maybe<Scalars['Int']['output']>;
  system_status?: Maybe<Scalars['String']['output']>;
  system_status_created_at?: Maybe<Scalars['timestamptz']['output']>;
  system_status_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "system" */
export type SystemMaxOrderBy = {
  hardware_version_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  software_version_id?: InputMaybe<OrderBy>;
  system_status?: InputMaybe<OrderBy>;
  system_status_created_at?: InputMaybe<OrderBy>;
  system_status_updated_at?: InputMaybe<OrderBy>;
  uid?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type SystemMinFields = {
  __typename?: 'system_min_fields';
  hardware_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  software_version_id?: Maybe<Scalars['Int']['output']>;
  system_status?: Maybe<Scalars['String']['output']>;
  system_status_created_at?: Maybe<Scalars['timestamptz']['output']>;
  system_status_updated_at?: Maybe<Scalars['timestamptz']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "system" */
export type SystemMinOrderBy = {
  hardware_version_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  software_version_id?: InputMaybe<OrderBy>;
  system_status?: InputMaybe<OrderBy>;
  system_status_created_at?: InputMaybe<OrderBy>;
  system_status_updated_at?: InputMaybe<OrderBy>;
  uid?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "system" */
export type SystemMutationResponse = {
  __typename?: 'system_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<System>;
};

/** input type for inserting object relation for remote table "system" */
export type SystemObjRelInsertInput = {
  data: SystemInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<SystemOnConflict>;
};

/** on_conflict condition type for table "system" */
export type SystemOnConflict = {
  constraint: SystemConstraint;
  update_columns?: Array<SystemUpdateColumn>;
  where?: InputMaybe<SystemBoolExp>;
};

/** Ordering options when selecting data from "system". */
export type SystemOrderBy = {
  computes_aggregate?: InputMaybe<ComputeAggregateOrderBy>;
  configs_aggregate?: InputMaybe<ConfigAggregateOrderBy>;
  field_interventions_aggregate?: InputMaybe<FieldInterventionAggregateOrderBy>;
  hardwareVersion?: InputMaybe<VersionOrderBy>;
  hardware_version_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  is_active?: InputMaybe<OrderBy>;
  measurement_runs_aggregate?: InputMaybe<MeasurementRunAggregateOrderBy>;
  measurements_aggregate?: InputMaybe<MeasurementAggregateOrderBy>;
  metadata?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  softwareVersion?: InputMaybe<VersionOrderBy>;
  software_version_id?: InputMaybe<OrderBy>;
  system_status?: InputMaybe<OrderBy>;
  system_status_created_at?: InputMaybe<OrderBy>;
  system_status_updated_at?: InputMaybe<OrderBy>;
  tracks_aggregate?: InputMaybe<TrackAggregateOrderBy>;
  uid?: InputMaybe<OrderBy>;
  zone?: InputMaybe<ZoneOrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: system */
export type SystemPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type SystemPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "system" */
export enum SystemSelectColumn {
  /** column name */
  hardware_version_id = 'hardware_version_id',
  /** column name */
  id = 'id',
  /** column name */
  is_active = 'is_active',
  /** column name */
  metadata = 'metadata',
  /** column name */
  organization_id = 'organization_id',
  /** column name */
  software_version_id = 'software_version_id',
  /** column name */
  system_status = 'system_status',
  /** column name */
  system_status_created_at = 'system_status_created_at',
  /** column name */
  system_status_updated_at = 'system_status_updated_at',
  /** column name */
  uid = 'uid',
  /** column name */
  zone_id = 'zone_id'
}

/** select "system_aggregate_bool_exp_bool_and_arguments_columns" columns of table "system" */
export enum SystemSelectColumnSystemAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  is_active = 'is_active'
}

/** select "system_aggregate_bool_exp_bool_or_arguments_columns" columns of table "system" */
export enum SystemSelectColumnSystemAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  is_active = 'is_active'
}

/** input type for updating data in table "system" */
export type SystemSetInput = {
  hardware_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  software_version_id?: InputMaybe<Scalars['Int']['input']>;
  system_status?: InputMaybe<Scalars['String']['input']>;
  system_status_created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  system_status_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type SystemStddevFields = {
  __typename?: 'system_stddev_fields';
  hardware_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  software_version_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "system" */
export type SystemStddevOrderBy = {
  hardware_version_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  software_version_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type SystemStddevPopFields = {
  __typename?: 'system_stddev_pop_fields';
  hardware_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  software_version_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "system" */
export type SystemStddevPopOrderBy = {
  hardware_version_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  software_version_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type SystemStddevSampFields = {
  __typename?: 'system_stddev_samp_fields';
  hardware_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  software_version_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "system" */
export type SystemStddevSampOrderBy = {
  hardware_version_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  software_version_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "system" */
export type SystemStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: SystemStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SystemStreamCursorValueInput = {
  hardware_version_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  software_version_id?: InputMaybe<Scalars['Int']['input']>;
  system_status?: InputMaybe<Scalars['String']['input']>;
  system_status_created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  system_status_updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type SystemSumFields = {
  __typename?: 'system_sum_fields';
  hardware_version_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  software_version_id?: Maybe<Scalars['Int']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "system" */
export type SystemSumOrderBy = {
  hardware_version_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  software_version_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** update columns of table "system" */
export enum SystemUpdateColumn {
  /** column name */
  hardware_version_id = 'hardware_version_id',
  /** column name */
  id = 'id',
  /** column name */
  is_active = 'is_active',
  /** column name */
  metadata = 'metadata',
  /** column name */
  organization_id = 'organization_id',
  /** column name */
  software_version_id = 'software_version_id',
  /** column name */
  system_status = 'system_status',
  /** column name */
  system_status_created_at = 'system_status_created_at',
  /** column name */
  system_status_updated_at = 'system_status_updated_at',
  /** column name */
  uid = 'uid',
  /** column name */
  zone_id = 'zone_id'
}

export type SystemUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<SystemAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<SystemDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<SystemDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<SystemDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<SystemIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<SystemPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<SystemSetInput>;
  /** filter the rows which have to be updated */
  where: SystemBoolExp;
};

/** aggregate var_pop on columns */
export type SystemVarPopFields = {
  __typename?: 'system_var_pop_fields';
  hardware_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  software_version_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "system" */
export type SystemVarPopOrderBy = {
  hardware_version_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  software_version_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type SystemVarSampFields = {
  __typename?: 'system_var_samp_fields';
  hardware_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  software_version_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "system" */
export type SystemVarSampOrderBy = {
  hardware_version_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  software_version_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type SystemVarianceFields = {
  __typename?: 'system_variance_fields';
  hardware_version_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  software_version_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "system" */
export type SystemVarianceOrderBy = {
  hardware_version_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  software_version_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type TimeComparisonExp = {
  _eq?: InputMaybe<Scalars['time']['input']>;
  _gt?: InputMaybe<Scalars['time']['input']>;
  _gte?: InputMaybe<Scalars['time']['input']>;
  _in?: InputMaybe<Array<Scalars['time']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['time']['input']>;
  _lte?: InputMaybe<Scalars['time']['input']>;
  _neq?: InputMaybe<Scalars['time']['input']>;
  _nin?: InputMaybe<Array<Scalars['time']['input']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "track" */
export type Track = {
  __typename?: 'track';
  /** An object relationship */
  classification?: Maybe<Classification>;
  classification_id?: Maybe<Scalars['Int']['output']>;
  first_image_time: Scalars['timestamptz']['output'];
  id: Scalars['Int']['output'];
  last_image_time: Scalars['timestamptz']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  system: System;
  system_id: Scalars['Int']['output'];
  /** An array relationship */
  track_detections: Array<TrackDetections>;
  /** An aggregate relationship */
  track_detections_aggregate: TrackDetectionsAggregate;
};


/** columns and relationships of "track" */
export type TrackMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "track" */
export type TrackTrackDetectionsArgs = {
  distinct_on?: InputMaybe<Array<TrackDetectionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TrackDetectionsOrderBy>>;
  where?: InputMaybe<TrackDetectionsBoolExp>;
};


/** columns and relationships of "track" */
export type TrackTrackDetectionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TrackDetectionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TrackDetectionsOrderBy>>;
  where?: InputMaybe<TrackDetectionsBoolExp>;
};

/** aggregated selection of "track" */
export type TrackAggregate = {
  __typename?: 'track_aggregate';
  aggregate?: Maybe<TrackAggregateFields>;
  nodes: Array<Track>;
};

export type TrackAggregateBoolExp = {
  count?: InputMaybe<TrackAggregateBoolExpCount>;
};

export type TrackAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TrackSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TrackBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "track" */
export type TrackAggregateFields = {
  __typename?: 'track_aggregate_fields';
  avg?: Maybe<TrackAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<TrackMaxFields>;
  min?: Maybe<TrackMinFields>;
  stddev?: Maybe<TrackStddevFields>;
  stddev_pop?: Maybe<TrackStddevPopFields>;
  stddev_samp?: Maybe<TrackStddevSampFields>;
  sum?: Maybe<TrackSumFields>;
  var_pop?: Maybe<TrackVarPopFields>;
  var_samp?: Maybe<TrackVarSampFields>;
  variance?: Maybe<TrackVarianceFields>;
};


/** aggregate fields of "track" */
export type TrackAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TrackSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "track" */
export type TrackAggregateOrderBy = {
  avg?: InputMaybe<TrackAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TrackMaxOrderBy>;
  min?: InputMaybe<TrackMinOrderBy>;
  stddev?: InputMaybe<TrackStddevOrderBy>;
  stddev_pop?: InputMaybe<TrackStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TrackStddevSampOrderBy>;
  sum?: InputMaybe<TrackSumOrderBy>;
  var_pop?: InputMaybe<TrackVarPopOrderBy>;
  var_samp?: InputMaybe<TrackVarSampOrderBy>;
  variance?: InputMaybe<TrackVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type TrackAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "track" */
export type TrackArrRelInsertInput = {
  data: Array<TrackInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TrackOnConflict>;
};

/** aggregate avg on columns */
export type TrackAvgFields = {
  __typename?: 'track_avg_fields';
  classification_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "track" */
export type TrackAvgOrderBy = {
  classification_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "track". All fields are combined with a logical 'AND'. */
export type TrackBoolExp = {
  _and?: InputMaybe<Array<TrackBoolExp>>;
  _not?: InputMaybe<TrackBoolExp>;
  _or?: InputMaybe<Array<TrackBoolExp>>;
  classification?: InputMaybe<ClassificationBoolExp>;
  classification_id?: InputMaybe<IntComparisonExp>;
  first_image_time?: InputMaybe<TimestamptzComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  last_image_time?: InputMaybe<TimestamptzComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  system?: InputMaybe<SystemBoolExp>;
  system_id?: InputMaybe<IntComparisonExp>;
  track_detections?: InputMaybe<TrackDetectionsBoolExp>;
  track_detections_aggregate?: InputMaybe<TrackDetectionsAggregateBoolExp>;
};

/** unique or primary key constraints on table "track" */
export enum TrackConstraint {
  /** unique or primary key constraint on columns "id" */
  track_pkey = 'track_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type TrackDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type TrackDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type TrackDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "track_detections" */
export type TrackDetections = {
  __typename?: 'track_detections';
  /** An object relationship */
  detection: Detection;
  detection_id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** An object relationship */
  track: Track;
  track_id: Scalars['Int']['output'];
};

/** aggregated selection of "track_detections" */
export type TrackDetectionsAggregate = {
  __typename?: 'track_detections_aggregate';
  aggregate?: Maybe<TrackDetectionsAggregateFields>;
  nodes: Array<TrackDetections>;
};

export type TrackDetectionsAggregateBoolExp = {
  count?: InputMaybe<TrackDetectionsAggregateBoolExpCount>;
};

export type TrackDetectionsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TrackDetectionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TrackDetectionsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "track_detections" */
export type TrackDetectionsAggregateFields = {
  __typename?: 'track_detections_aggregate_fields';
  avg?: Maybe<TrackDetectionsAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<TrackDetectionsMaxFields>;
  min?: Maybe<TrackDetectionsMinFields>;
  stddev?: Maybe<TrackDetectionsStddevFields>;
  stddev_pop?: Maybe<TrackDetectionsStddevPopFields>;
  stddev_samp?: Maybe<TrackDetectionsStddevSampFields>;
  sum?: Maybe<TrackDetectionsSumFields>;
  var_pop?: Maybe<TrackDetectionsVarPopFields>;
  var_samp?: Maybe<TrackDetectionsVarSampFields>;
  variance?: Maybe<TrackDetectionsVarianceFields>;
};


/** aggregate fields of "track_detections" */
export type TrackDetectionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TrackDetectionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "track_detections" */
export type TrackDetectionsAggregateOrderBy = {
  avg?: InputMaybe<TrackDetectionsAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TrackDetectionsMaxOrderBy>;
  min?: InputMaybe<TrackDetectionsMinOrderBy>;
  stddev?: InputMaybe<TrackDetectionsStddevOrderBy>;
  stddev_pop?: InputMaybe<TrackDetectionsStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TrackDetectionsStddevSampOrderBy>;
  sum?: InputMaybe<TrackDetectionsSumOrderBy>;
  var_pop?: InputMaybe<TrackDetectionsVarPopOrderBy>;
  var_samp?: InputMaybe<TrackDetectionsVarSampOrderBy>;
  variance?: InputMaybe<TrackDetectionsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "track_detections" */
export type TrackDetectionsArrRelInsertInput = {
  data: Array<TrackDetectionsInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TrackDetectionsOnConflict>;
};

/** aggregate avg on columns */
export type TrackDetectionsAvgFields = {
  __typename?: 'track_detections_avg_fields';
  detection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  track_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "track_detections" */
export type TrackDetectionsAvgOrderBy = {
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  track_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "track_detections". All fields are combined with a logical 'AND'. */
export type TrackDetectionsBoolExp = {
  _and?: InputMaybe<Array<TrackDetectionsBoolExp>>;
  _not?: InputMaybe<TrackDetectionsBoolExp>;
  _or?: InputMaybe<Array<TrackDetectionsBoolExp>>;
  detection?: InputMaybe<DetectionBoolExp>;
  detection_id?: InputMaybe<IntComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  track?: InputMaybe<TrackBoolExp>;
  track_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "track_detections" */
export enum TrackDetectionsConstraint {
  /** unique or primary key constraint on columns "id" */
  track_detections_pkey = 'track_detections_pkey'
}

/** input type for incrementing numeric columns in table "track_detections" */
export type TrackDetectionsIncInput = {
  detection_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  track_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "track_detections" */
export type TrackDetectionsInsertInput = {
  detection?: InputMaybe<DetectionObjRelInsertInput>;
  detection_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  track?: InputMaybe<TrackObjRelInsertInput>;
  track_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type TrackDetectionsMaxFields = {
  __typename?: 'track_detections_max_fields';
  detection_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  track_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "track_detections" */
export type TrackDetectionsMaxOrderBy = {
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  track_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TrackDetectionsMinFields = {
  __typename?: 'track_detections_min_fields';
  detection_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  track_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "track_detections" */
export type TrackDetectionsMinOrderBy = {
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  track_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "track_detections" */
export type TrackDetectionsMutationResponse = {
  __typename?: 'track_detections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TrackDetections>;
};

/** on_conflict condition type for table "track_detections" */
export type TrackDetectionsOnConflict = {
  constraint: TrackDetectionsConstraint;
  update_columns?: Array<TrackDetectionsUpdateColumn>;
  where?: InputMaybe<TrackDetectionsBoolExp>;
};

/** Ordering options when selecting data from "track_detections". */
export type TrackDetectionsOrderBy = {
  detection?: InputMaybe<DetectionOrderBy>;
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  track?: InputMaybe<TrackOrderBy>;
  track_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: track_detections */
export type TrackDetectionsPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** select columns of table "track_detections" */
export enum TrackDetectionsSelectColumn {
  /** column name */
  detection_id = 'detection_id',
  /** column name */
  id = 'id',
  /** column name */
  track_id = 'track_id'
}

/** input type for updating data in table "track_detections" */
export type TrackDetectionsSetInput = {
  detection_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  track_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type TrackDetectionsStddevFields = {
  __typename?: 'track_detections_stddev_fields';
  detection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  track_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "track_detections" */
export type TrackDetectionsStddevOrderBy = {
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  track_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type TrackDetectionsStddevPopFields = {
  __typename?: 'track_detections_stddev_pop_fields';
  detection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  track_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "track_detections" */
export type TrackDetectionsStddevPopOrderBy = {
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  track_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type TrackDetectionsStddevSampFields = {
  __typename?: 'track_detections_stddev_samp_fields';
  detection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  track_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "track_detections" */
export type TrackDetectionsStddevSampOrderBy = {
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  track_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "track_detections" */
export type TrackDetectionsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TrackDetectionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TrackDetectionsStreamCursorValueInput = {
  detection_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  track_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type TrackDetectionsSumFields = {
  __typename?: 'track_detections_sum_fields';
  detection_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  track_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "track_detections" */
export type TrackDetectionsSumOrderBy = {
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  track_id?: InputMaybe<OrderBy>;
};

/** update columns of table "track_detections" */
export enum TrackDetectionsUpdateColumn {
  /** column name */
  detection_id = 'detection_id',
  /** column name */
  id = 'id',
  /** column name */
  track_id = 'track_id'
}

export type TrackDetectionsUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TrackDetectionsIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TrackDetectionsSetInput>;
  /** filter the rows which have to be updated */
  where: TrackDetectionsBoolExp;
};

/** aggregate var_pop on columns */
export type TrackDetectionsVarPopFields = {
  __typename?: 'track_detections_var_pop_fields';
  detection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  track_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "track_detections" */
export type TrackDetectionsVarPopOrderBy = {
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  track_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type TrackDetectionsVarSampFields = {
  __typename?: 'track_detections_var_samp_fields';
  detection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  track_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "track_detections" */
export type TrackDetectionsVarSampOrderBy = {
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  track_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TrackDetectionsVarianceFields = {
  __typename?: 'track_detections_variance_fields';
  detection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  track_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "track_detections" */
export type TrackDetectionsVarianceOrderBy = {
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  track_id?: InputMaybe<OrderBy>;
};

/** input type for incrementing numeric columns in table "track" */
export type TrackIncInput = {
  classification_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  system_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "track" */
export type TrackInsertInput = {
  classification?: InputMaybe<ClassificationObjRelInsertInput>;
  classification_id?: InputMaybe<Scalars['Int']['input']>;
  first_image_time?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  last_image_time?: InputMaybe<Scalars['timestamptz']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  system?: InputMaybe<SystemObjRelInsertInput>;
  system_id?: InputMaybe<Scalars['Int']['input']>;
  track_detections?: InputMaybe<TrackDetectionsArrRelInsertInput>;
};

/** aggregate max on columns */
export type TrackMaxFields = {
  __typename?: 'track_max_fields';
  classification_id?: Maybe<Scalars['Int']['output']>;
  first_image_time?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_image_time?: Maybe<Scalars['timestamptz']['output']>;
  system_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "track" */
export type TrackMaxOrderBy = {
  classification_id?: InputMaybe<OrderBy>;
  first_image_time?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  last_image_time?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TrackMinFields = {
  __typename?: 'track_min_fields';
  classification_id?: Maybe<Scalars['Int']['output']>;
  first_image_time?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_image_time?: Maybe<Scalars['timestamptz']['output']>;
  system_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "track" */
export type TrackMinOrderBy = {
  classification_id?: InputMaybe<OrderBy>;
  first_image_time?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  last_image_time?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "track" */
export type TrackMutationResponse = {
  __typename?: 'track_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Track>;
};

/** input type for inserting object relation for remote table "track" */
export type TrackObjRelInsertInput = {
  data: TrackInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<TrackOnConflict>;
};

/** on_conflict condition type for table "track" */
export type TrackOnConflict = {
  constraint: TrackConstraint;
  update_columns?: Array<TrackUpdateColumn>;
  where?: InputMaybe<TrackBoolExp>;
};

/** Ordering options when selecting data from "track". */
export type TrackOrderBy = {
  classification?: InputMaybe<ClassificationOrderBy>;
  classification_id?: InputMaybe<OrderBy>;
  first_image_time?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  last_image_time?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  system?: InputMaybe<SystemOrderBy>;
  system_id?: InputMaybe<OrderBy>;
  track_detections_aggregate?: InputMaybe<TrackDetectionsAggregateOrderBy>;
};

/** primary key columns input for table: track */
export type TrackPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type TrackPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "track" */
export enum TrackSelectColumn {
  /** column name */
  classification_id = 'classification_id',
  /** column name */
  first_image_time = 'first_image_time',
  /** column name */
  id = 'id',
  /** column name */
  last_image_time = 'last_image_time',
  /** column name */
  metadata = 'metadata',
  /** column name */
  system_id = 'system_id'
}

/** input type for updating data in table "track" */
export type TrackSetInput = {
  classification_id?: InputMaybe<Scalars['Int']['input']>;
  first_image_time?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  last_image_time?: InputMaybe<Scalars['timestamptz']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  system_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type TrackStddevFields = {
  __typename?: 'track_stddev_fields';
  classification_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "track" */
export type TrackStddevOrderBy = {
  classification_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type TrackStddevPopFields = {
  __typename?: 'track_stddev_pop_fields';
  classification_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "track" */
export type TrackStddevPopOrderBy = {
  classification_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type TrackStddevSampFields = {
  __typename?: 'track_stddev_samp_fields';
  classification_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "track" */
export type TrackStddevSampOrderBy = {
  classification_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "track" */
export type TrackStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TrackStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TrackStreamCursorValueInput = {
  classification_id?: InputMaybe<Scalars['Int']['input']>;
  first_image_time?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  last_image_time?: InputMaybe<Scalars['timestamptz']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  system_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type TrackSumFields = {
  __typename?: 'track_sum_fields';
  classification_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  system_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "track" */
export type TrackSumOrderBy = {
  classification_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
};

/** update columns of table "track" */
export enum TrackUpdateColumn {
  /** column name */
  classification_id = 'classification_id',
  /** column name */
  first_image_time = 'first_image_time',
  /** column name */
  id = 'id',
  /** column name */
  last_image_time = 'last_image_time',
  /** column name */
  metadata = 'metadata',
  /** column name */
  system_id = 'system_id'
}

export type TrackUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<TrackAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<TrackDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<TrackDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<TrackDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TrackIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<TrackPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TrackSetInput>;
  /** filter the rows which have to be updated */
  where: TrackBoolExp;
};

/** aggregate var_pop on columns */
export type TrackVarPopFields = {
  __typename?: 'track_var_pop_fields';
  classification_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "track" */
export type TrackVarPopOrderBy = {
  classification_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type TrackVarSampFields = {
  __typename?: 'track_var_samp_fields';
  classification_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "track" */
export type TrackVarSampOrderBy = {
  classification_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TrackVarianceFields = {
  __typename?: 'track_variance_fields';
  classification_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  system_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "track" */
export type TrackVarianceOrderBy = {
  classification_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  system_id?: InputMaybe<OrderBy>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  active: Scalars['Boolean']['output'];
  /** An array relationship */
  comments: Array<Comment>;
  /** An aggregate relationship */
  comments_aggregate: CommentAggregate;
  /** An array relationship */
  discussions: Array<Discussion>;
  /** An aggregate relationship */
  discussions_aggregate: DiscussionAggregate;
  email: Scalars['String']['output'];
  /** An array relationship */
  field_interventions: Array<FieldIntervention>;
  /** An aggregate relationship */
  field_interventions_aggregate: FieldInterventionAggregate;
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** An array relationship */
  image_annotation_trackings: Array<ImageAnnotationTracking>;
  /** An aggregate relationship */
  image_annotation_trackings_aggregate: ImageAnnotationTrackingAggregate;
  /** An array relationship */
  labels: Array<Label>;
  /** An array relationship */
  labelsByHumanSourceId: Array<Label>;
  /** An aggregate relationship */
  labelsByHumanSourceId_aggregate: LabelAggregate;
  /** An aggregate relationship */
  labels_aggregate: LabelAggregate;
  last_name?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  password: Scalars['String']['output'];
  phone_number?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  user_comments: Array<UserComment>;
  /** An aggregate relationship */
  user_comments_aggregate: UserCommentAggregate;
  /** An array relationship */
  user_labels: Array<UserLabel>;
  /** An aggregate relationship */
  user_labels_aggregate: UserLabelAggregate;
  /** An array relationship */
  user_notification_settings: Array<UserNotificationSetting>;
  /** An aggregate relationship */
  user_notification_settings_aggregate: UserNotificationSettingAggregate;
  /** An array relationship */
  user_notifications: Array<UserNotification>;
  /** An aggregate relationship */
  user_notifications_aggregate: UserNotificationAggregate;
  /** An array relationship */
  user_organization_roles: Array<UserOrganizationRole>;
  /** An aggregate relationship */
  user_organization_roles_aggregate: UserOrganizationRoleAggregate;
};


/** columns and relationships of "user" */
export type UserCommentsArgs = {
  distinct_on?: InputMaybe<Array<CommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CommentOrderBy>>;
  where?: InputMaybe<CommentBoolExp>;
};


/** columns and relationships of "user" */
export type UserCommentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CommentOrderBy>>;
  where?: InputMaybe<CommentBoolExp>;
};


/** columns and relationships of "user" */
export type UserDiscussionsArgs = {
  distinct_on?: InputMaybe<Array<DiscussionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DiscussionOrderBy>>;
  where?: InputMaybe<DiscussionBoolExp>;
};


/** columns and relationships of "user" */
export type UserDiscussionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DiscussionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DiscussionOrderBy>>;
  where?: InputMaybe<DiscussionBoolExp>;
};


/** columns and relationships of "user" */
export type UserFieldInterventionsArgs = {
  distinct_on?: InputMaybe<Array<FieldInterventionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FieldInterventionOrderBy>>;
  where?: InputMaybe<FieldInterventionBoolExp>;
};


/** columns and relationships of "user" */
export type UserFieldInterventionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<FieldInterventionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FieldInterventionOrderBy>>;
  where?: InputMaybe<FieldInterventionBoolExp>;
};


/** columns and relationships of "user" */
export type UserImageAnnotationTrackingsArgs = {
  distinct_on?: InputMaybe<Array<ImageAnnotationTrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImageAnnotationTrackingOrderBy>>;
  where?: InputMaybe<ImageAnnotationTrackingBoolExp>;
};


/** columns and relationships of "user" */
export type UserImageAnnotationTrackingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImageAnnotationTrackingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImageAnnotationTrackingOrderBy>>;
  where?: InputMaybe<ImageAnnotationTrackingBoolExp>;
};


/** columns and relationships of "user" */
export type UserLabelsArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


/** columns and relationships of "user" */
export type UserLabelsByHumanSourceIdArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


/** columns and relationships of "user" */
export type UserLabelsByHumanSourceIdAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


/** columns and relationships of "user" */
export type UserLabelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<LabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LabelOrderBy>>;
  where?: InputMaybe<LabelBoolExp>;
};


/** columns and relationships of "user" */
export type UserMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "user" */
export type UserUserCommentsArgs = {
  distinct_on?: InputMaybe<Array<UserCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserCommentOrderBy>>;
  where?: InputMaybe<UserCommentBoolExp>;
};


/** columns and relationships of "user" */
export type UserUserCommentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserCommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserCommentOrderBy>>;
  where?: InputMaybe<UserCommentBoolExp>;
};


/** columns and relationships of "user" */
export type UserUserLabelsArgs = {
  distinct_on?: InputMaybe<Array<UserLabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserLabelOrderBy>>;
  where?: InputMaybe<UserLabelBoolExp>;
};


/** columns and relationships of "user" */
export type UserUserLabelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserLabelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserLabelOrderBy>>;
  where?: InputMaybe<UserLabelBoolExp>;
};


/** columns and relationships of "user" */
export type UserUserNotificationSettingsArgs = {
  distinct_on?: InputMaybe<Array<UserNotificationSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserNotificationSettingOrderBy>>;
  where?: InputMaybe<UserNotificationSettingBoolExp>;
};


/** columns and relationships of "user" */
export type UserUserNotificationSettingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserNotificationSettingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserNotificationSettingOrderBy>>;
  where?: InputMaybe<UserNotificationSettingBoolExp>;
};


/** columns and relationships of "user" */
export type UserUserNotificationsArgs = {
  distinct_on?: InputMaybe<Array<UserNotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserNotificationOrderBy>>;
  where?: InputMaybe<UserNotificationBoolExp>;
};


/** columns and relationships of "user" */
export type UserUserNotificationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserNotificationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserNotificationOrderBy>>;
  where?: InputMaybe<UserNotificationBoolExp>;
};


/** columns and relationships of "user" */
export type UserUserOrganizationRolesArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizationRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrganizationRoleOrderBy>>;
  where?: InputMaybe<UserOrganizationRoleBoolExp>;
};


/** columns and relationships of "user" */
export type UserUserOrganizationRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizationRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrganizationRoleOrderBy>>;
  where?: InputMaybe<UserOrganizationRoleBoolExp>;
};

/** aggregated selection of "user" */
export type UserAggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<UserAggregateFields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type UserAggregateFields = {
  __typename?: 'user_aggregate_fields';
  avg?: Maybe<UserAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserMaxFields>;
  min?: Maybe<UserMinFields>;
  stddev?: Maybe<UserStddevFields>;
  stddev_pop?: Maybe<UserStddevPopFields>;
  stddev_samp?: Maybe<UserStddevSampFields>;
  sum?: Maybe<UserSumFields>;
  var_pop?: Maybe<UserVarPopFields>;
  var_samp?: Maybe<UserVarSampFields>;
  variance?: Maybe<UserVarianceFields>;
};


/** aggregate fields of "user" */
export type UserAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type UserAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type UserAvgFields = {
  __typename?: 'user_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type UserBoolExp = {
  _and?: InputMaybe<Array<UserBoolExp>>;
  _not?: InputMaybe<UserBoolExp>;
  _or?: InputMaybe<Array<UserBoolExp>>;
  active?: InputMaybe<BooleanComparisonExp>;
  comments?: InputMaybe<CommentBoolExp>;
  comments_aggregate?: InputMaybe<CommentAggregateBoolExp>;
  discussions?: InputMaybe<DiscussionBoolExp>;
  discussions_aggregate?: InputMaybe<DiscussionAggregateBoolExp>;
  email?: InputMaybe<StringComparisonExp>;
  field_interventions?: InputMaybe<FieldInterventionBoolExp>;
  field_interventions_aggregate?: InputMaybe<FieldInterventionAggregateBoolExp>;
  first_name?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<IntComparisonExp>;
  image_annotation_trackings?: InputMaybe<ImageAnnotationTrackingBoolExp>;
  image_annotation_trackings_aggregate?: InputMaybe<ImageAnnotationTrackingAggregateBoolExp>;
  labels?: InputMaybe<LabelBoolExp>;
  labelsByHumanSourceId?: InputMaybe<LabelBoolExp>;
  labelsByHumanSourceId_aggregate?: InputMaybe<LabelAggregateBoolExp>;
  labels_aggregate?: InputMaybe<LabelAggregateBoolExp>;
  last_name?: InputMaybe<StringComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  password?: InputMaybe<StringComparisonExp>;
  phone_number?: InputMaybe<StringComparisonExp>;
  user_comments?: InputMaybe<UserCommentBoolExp>;
  user_comments_aggregate?: InputMaybe<UserCommentAggregateBoolExp>;
  user_labels?: InputMaybe<UserLabelBoolExp>;
  user_labels_aggregate?: InputMaybe<UserLabelAggregateBoolExp>;
  user_notification_settings?: InputMaybe<UserNotificationSettingBoolExp>;
  user_notification_settings_aggregate?: InputMaybe<UserNotificationSettingAggregateBoolExp>;
  user_notifications?: InputMaybe<UserNotificationBoolExp>;
  user_notifications_aggregate?: InputMaybe<UserNotificationAggregateBoolExp>;
  user_organization_roles?: InputMaybe<UserOrganizationRoleBoolExp>;
  user_organization_roles_aggregate?: InputMaybe<UserOrganizationRoleAggregateBoolExp>;
};

/** columns and relationships of "user_comment" */
export type UserComment = {
  __typename?: 'user_comment';
  /** An object relationship */
  comment: Comment;
  comment_uid: Scalars['uuid']['output'];
  /** An object relationship */
  discussion: Discussion;
  discussion_uid: Scalars['uuid']['output'];
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  tagged_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: User;
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "user_comment" */
export type UserCommentAggregate = {
  __typename?: 'user_comment_aggregate';
  aggregate?: Maybe<UserCommentAggregateFields>;
  nodes: Array<UserComment>;
};

export type UserCommentAggregateBoolExp = {
  count?: InputMaybe<UserCommentAggregateBoolExpCount>;
};

export type UserCommentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserCommentBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "user_comment" */
export type UserCommentAggregateFields = {
  __typename?: 'user_comment_aggregate_fields';
  avg?: Maybe<UserCommentAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserCommentMaxFields>;
  min?: Maybe<UserCommentMinFields>;
  stddev?: Maybe<UserCommentStddevFields>;
  stddev_pop?: Maybe<UserCommentStddevPopFields>;
  stddev_samp?: Maybe<UserCommentStddevSampFields>;
  sum?: Maybe<UserCommentSumFields>;
  var_pop?: Maybe<UserCommentVarPopFields>;
  var_samp?: Maybe<UserCommentVarSampFields>;
  variance?: Maybe<UserCommentVarianceFields>;
};


/** aggregate fields of "user_comment" */
export type UserCommentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserCommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_comment" */
export type UserCommentAggregateOrderBy = {
  avg?: InputMaybe<UserCommentAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserCommentMaxOrderBy>;
  min?: InputMaybe<UserCommentMinOrderBy>;
  stddev?: InputMaybe<UserCommentStddevOrderBy>;
  stddev_pop?: InputMaybe<UserCommentStddevPopOrderBy>;
  stddev_samp?: InputMaybe<UserCommentStddevSampOrderBy>;
  sum?: InputMaybe<UserCommentSumOrderBy>;
  var_pop?: InputMaybe<UserCommentVarPopOrderBy>;
  var_samp?: InputMaybe<UserCommentVarSampOrderBy>;
  variance?: InputMaybe<UserCommentVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "user_comment" */
export type UserCommentArrRelInsertInput = {
  data: Array<UserCommentInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserCommentOnConflict>;
};

/** aggregate avg on columns */
export type UserCommentAvgFields = {
  __typename?: 'user_comment_avg_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "user_comment" */
export type UserCommentAvgOrderBy = {
  user_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "user_comment". All fields are combined with a logical 'AND'. */
export type UserCommentBoolExp = {
  _and?: InputMaybe<Array<UserCommentBoolExp>>;
  _not?: InputMaybe<UserCommentBoolExp>;
  _or?: InputMaybe<Array<UserCommentBoolExp>>;
  comment?: InputMaybe<CommentBoolExp>;
  comment_uid?: InputMaybe<UuidComparisonExp>;
  discussion?: InputMaybe<DiscussionBoolExp>;
  discussion_uid?: InputMaybe<UuidComparisonExp>;
  read_at?: InputMaybe<TimestamptzComparisonExp>;
  tagged_at?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  user_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "user_comment" */
export enum UserCommentConstraint {
  /** unique or primary key constraint on columns "user_id", "comment_uid" */
  uq_user_comment = 'uq_user_comment'
}

/** input type for incrementing numeric columns in table "user_comment" */
export type UserCommentIncInput = {
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user_comment" */
export type UserCommentInsertInput = {
  comment?: InputMaybe<CommentObjRelInsertInput>;
  comment_uid?: InputMaybe<Scalars['uuid']['input']>;
  discussion?: InputMaybe<DiscussionObjRelInsertInput>;
  discussion_uid?: InputMaybe<Scalars['uuid']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  tagged_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type UserCommentMaxFields = {
  __typename?: 'user_comment_max_fields';
  comment_uid?: Maybe<Scalars['uuid']['output']>;
  discussion_uid?: Maybe<Scalars['uuid']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  tagged_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "user_comment" */
export type UserCommentMaxOrderBy = {
  comment_uid?: InputMaybe<OrderBy>;
  discussion_uid?: InputMaybe<OrderBy>;
  read_at?: InputMaybe<OrderBy>;
  tagged_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserCommentMinFields = {
  __typename?: 'user_comment_min_fields';
  comment_uid?: Maybe<Scalars['uuid']['output']>;
  discussion_uid?: Maybe<Scalars['uuid']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  tagged_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "user_comment" */
export type UserCommentMinOrderBy = {
  comment_uid?: InputMaybe<OrderBy>;
  discussion_uid?: InputMaybe<OrderBy>;
  read_at?: InputMaybe<OrderBy>;
  tagged_at?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "user_comment" */
export type UserCommentMutationResponse = {
  __typename?: 'user_comment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserComment>;
};

/** on_conflict condition type for table "user_comment" */
export type UserCommentOnConflict = {
  constraint: UserCommentConstraint;
  update_columns?: Array<UserCommentUpdateColumn>;
  where?: InputMaybe<UserCommentBoolExp>;
};

/** Ordering options when selecting data from "user_comment". */
export type UserCommentOrderBy = {
  comment?: InputMaybe<CommentOrderBy>;
  comment_uid?: InputMaybe<OrderBy>;
  discussion?: InputMaybe<DiscussionOrderBy>;
  discussion_uid?: InputMaybe<OrderBy>;
  read_at?: InputMaybe<OrderBy>;
  tagged_at?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user_comment */
export type UserCommentPkColumnsInput = {
  comment_uid: Scalars['uuid']['input'];
  user_id: Scalars['Int']['input'];
};

/** select columns of table "user_comment" */
export enum UserCommentSelectColumn {
  /** column name */
  comment_uid = 'comment_uid',
  /** column name */
  discussion_uid = 'discussion_uid',
  /** column name */
  read_at = 'read_at',
  /** column name */
  tagged_at = 'tagged_at',
  /** column name */
  user_id = 'user_id'
}

/** input type for updating data in table "user_comment" */
export type UserCommentSetInput = {
  comment_uid?: InputMaybe<Scalars['uuid']['input']>;
  discussion_uid?: InputMaybe<Scalars['uuid']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  tagged_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type UserCommentStddevFields = {
  __typename?: 'user_comment_stddev_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "user_comment" */
export type UserCommentStddevOrderBy = {
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UserCommentStddevPopFields = {
  __typename?: 'user_comment_stddev_pop_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "user_comment" */
export type UserCommentStddevPopOrderBy = {
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UserCommentStddevSampFields = {
  __typename?: 'user_comment_stddev_samp_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "user_comment" */
export type UserCommentStddevSampOrderBy = {
  user_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "user_comment" */
export type UserCommentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserCommentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserCommentStreamCursorValueInput = {
  comment_uid?: InputMaybe<Scalars['uuid']['input']>;
  discussion_uid?: InputMaybe<Scalars['uuid']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  tagged_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type UserCommentSumFields = {
  __typename?: 'user_comment_sum_fields';
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "user_comment" */
export type UserCommentSumOrderBy = {
  user_id?: InputMaybe<OrderBy>;
};

/** update columns of table "user_comment" */
export enum UserCommentUpdateColumn {
  /** column name */
  comment_uid = 'comment_uid',
  /** column name */
  discussion_uid = 'discussion_uid',
  /** column name */
  read_at = 'read_at',
  /** column name */
  tagged_at = 'tagged_at',
  /** column name */
  user_id = 'user_id'
}

export type UserCommentUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserCommentIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserCommentSetInput>;
  /** filter the rows which have to be updated */
  where: UserCommentBoolExp;
};

/** aggregate var_pop on columns */
export type UserCommentVarPopFields = {
  __typename?: 'user_comment_var_pop_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "user_comment" */
export type UserCommentVarPopOrderBy = {
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UserCommentVarSampFields = {
  __typename?: 'user_comment_var_samp_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "user_comment" */
export type UserCommentVarSampOrderBy = {
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserCommentVarianceFields = {
  __typename?: 'user_comment_variance_fields';
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "user_comment" */
export type UserCommentVarianceOrderBy = {
  user_id?: InputMaybe<OrderBy>;
};

/** unique or primary key constraints on table "user" */
export enum UserConstraint {
  /** unique or primary key constraint on columns "id" */
  user_pkey = 'user_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type UserDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type UserDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type UserDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "user" */
export type UserIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user" */
export type UserInsertInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  comments?: InputMaybe<CommentArrRelInsertInput>;
  discussions?: InputMaybe<DiscussionArrRelInsertInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  field_interventions?: InputMaybe<FieldInterventionArrRelInsertInput>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image_annotation_trackings?: InputMaybe<ImageAnnotationTrackingArrRelInsertInput>;
  labels?: InputMaybe<LabelArrRelInsertInput>;
  labelsByHumanSourceId?: InputMaybe<LabelArrRelInsertInput>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  user_comments?: InputMaybe<UserCommentArrRelInsertInput>;
  user_labels?: InputMaybe<UserLabelArrRelInsertInput>;
  user_notification_settings?: InputMaybe<UserNotificationSettingArrRelInsertInput>;
  user_notifications?: InputMaybe<UserNotificationArrRelInsertInput>;
  user_organization_roles?: InputMaybe<UserOrganizationRoleArrRelInsertInput>;
};

/** columns and relationships of "user_label" */
export type UserLabel = {
  __typename?: 'user_label';
  /** An object relationship */
  classification: Classification;
  classification_id: Scalars['Int']['output'];
  confidence_id: Scalars['Int']['output'];
  create_time: Scalars['timestamptz']['output'];
  /** An object relationship */
  detection: Detection;
  detection_id: Scalars['Int']['output'];
  /** An object relationship */
  enumeration: Enumeration;
  id: Scalars['Int']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  modified_time?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: User;
  user_id: Scalars['Int']['output'];
};


/** columns and relationships of "user_label" */
export type UserLabelMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "user_label" */
export type UserLabelAggregate = {
  __typename?: 'user_label_aggregate';
  aggregate?: Maybe<UserLabelAggregateFields>;
  nodes: Array<UserLabel>;
};

export type UserLabelAggregateBoolExp = {
  count?: InputMaybe<UserLabelAggregateBoolExpCount>;
};

export type UserLabelAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserLabelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserLabelBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "user_label" */
export type UserLabelAggregateFields = {
  __typename?: 'user_label_aggregate_fields';
  avg?: Maybe<UserLabelAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserLabelMaxFields>;
  min?: Maybe<UserLabelMinFields>;
  stddev?: Maybe<UserLabelStddevFields>;
  stddev_pop?: Maybe<UserLabelStddevPopFields>;
  stddev_samp?: Maybe<UserLabelStddevSampFields>;
  sum?: Maybe<UserLabelSumFields>;
  var_pop?: Maybe<UserLabelVarPopFields>;
  var_samp?: Maybe<UserLabelVarSampFields>;
  variance?: Maybe<UserLabelVarianceFields>;
};


/** aggregate fields of "user_label" */
export type UserLabelAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserLabelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_label" */
export type UserLabelAggregateOrderBy = {
  avg?: InputMaybe<UserLabelAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserLabelMaxOrderBy>;
  min?: InputMaybe<UserLabelMinOrderBy>;
  stddev?: InputMaybe<UserLabelStddevOrderBy>;
  stddev_pop?: InputMaybe<UserLabelStddevPopOrderBy>;
  stddev_samp?: InputMaybe<UserLabelStddevSampOrderBy>;
  sum?: InputMaybe<UserLabelSumOrderBy>;
  var_pop?: InputMaybe<UserLabelVarPopOrderBy>;
  var_samp?: InputMaybe<UserLabelVarSampOrderBy>;
  variance?: InputMaybe<UserLabelVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type UserLabelAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "user_label" */
export type UserLabelArrRelInsertInput = {
  data: Array<UserLabelInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserLabelOnConflict>;
};

/** aggregate avg on columns */
export type UserLabelAvgFields = {
  __typename?: 'user_label_avg_fields';
  classification_id?: Maybe<Scalars['Float']['output']>;
  confidence_id?: Maybe<Scalars['Float']['output']>;
  detection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "user_label" */
export type UserLabelAvgOrderBy = {
  classification_id?: InputMaybe<OrderBy>;
  confidence_id?: InputMaybe<OrderBy>;
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "user_label". All fields are combined with a logical 'AND'. */
export type UserLabelBoolExp = {
  _and?: InputMaybe<Array<UserLabelBoolExp>>;
  _not?: InputMaybe<UserLabelBoolExp>;
  _or?: InputMaybe<Array<UserLabelBoolExp>>;
  classification?: InputMaybe<ClassificationBoolExp>;
  classification_id?: InputMaybe<IntComparisonExp>;
  confidence_id?: InputMaybe<IntComparisonExp>;
  create_time?: InputMaybe<TimestamptzComparisonExp>;
  detection?: InputMaybe<DetectionBoolExp>;
  detection_id?: InputMaybe<IntComparisonExp>;
  enumeration?: InputMaybe<EnumerationBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  modified_time?: InputMaybe<TimestamptzComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  user_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "user_label" */
export enum UserLabelConstraint {
  /** unique or primary key constraint on columns "id" */
  user_label_pkey = 'user_label_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type UserLabelDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type UserLabelDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type UserLabelDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "user_label" */
export type UserLabelIncInput = {
  classification_id?: InputMaybe<Scalars['Int']['input']>;
  confidence_id?: InputMaybe<Scalars['Int']['input']>;
  detection_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user_label" */
export type UserLabelInsertInput = {
  classification?: InputMaybe<ClassificationObjRelInsertInput>;
  classification_id?: InputMaybe<Scalars['Int']['input']>;
  confidence_id?: InputMaybe<Scalars['Int']['input']>;
  create_time?: InputMaybe<Scalars['timestamptz']['input']>;
  detection?: InputMaybe<DetectionObjRelInsertInput>;
  detection_id?: InputMaybe<Scalars['Int']['input']>;
  enumeration?: InputMaybe<EnumerationObjRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  modified_time?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type UserLabelMaxFields = {
  __typename?: 'user_label_max_fields';
  classification_id?: Maybe<Scalars['Int']['output']>;
  confidence_id?: Maybe<Scalars['Int']['output']>;
  create_time?: Maybe<Scalars['timestamptz']['output']>;
  detection_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  modified_time?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "user_label" */
export type UserLabelMaxOrderBy = {
  classification_id?: InputMaybe<OrderBy>;
  confidence_id?: InputMaybe<OrderBy>;
  create_time?: InputMaybe<OrderBy>;
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified_time?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserLabelMinFields = {
  __typename?: 'user_label_min_fields';
  classification_id?: Maybe<Scalars['Int']['output']>;
  confidence_id?: Maybe<Scalars['Int']['output']>;
  create_time?: Maybe<Scalars['timestamptz']['output']>;
  detection_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  modified_time?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "user_label" */
export type UserLabelMinOrderBy = {
  classification_id?: InputMaybe<OrderBy>;
  confidence_id?: InputMaybe<OrderBy>;
  create_time?: InputMaybe<OrderBy>;
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  modified_time?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "user_label" */
export type UserLabelMutationResponse = {
  __typename?: 'user_label_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserLabel>;
};

/** on_conflict condition type for table "user_label" */
export type UserLabelOnConflict = {
  constraint: UserLabelConstraint;
  update_columns?: Array<UserLabelUpdateColumn>;
  where?: InputMaybe<UserLabelBoolExp>;
};

/** Ordering options when selecting data from "user_label". */
export type UserLabelOrderBy = {
  classification?: InputMaybe<ClassificationOrderBy>;
  classification_id?: InputMaybe<OrderBy>;
  confidence_id?: InputMaybe<OrderBy>;
  create_time?: InputMaybe<OrderBy>;
  detection?: InputMaybe<DetectionOrderBy>;
  detection_id?: InputMaybe<OrderBy>;
  enumeration?: InputMaybe<EnumerationOrderBy>;
  id?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  modified_time?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user_label */
export type UserLabelPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type UserLabelPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "user_label" */
export enum UserLabelSelectColumn {
  /** column name */
  classification_id = 'classification_id',
  /** column name */
  confidence_id = 'confidence_id',
  /** column name */
  create_time = 'create_time',
  /** column name */
  detection_id = 'detection_id',
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  modified_time = 'modified_time',
  /** column name */
  user_id = 'user_id'
}

/** input type for updating data in table "user_label" */
export type UserLabelSetInput = {
  classification_id?: InputMaybe<Scalars['Int']['input']>;
  confidence_id?: InputMaybe<Scalars['Int']['input']>;
  create_time?: InputMaybe<Scalars['timestamptz']['input']>;
  detection_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  modified_time?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type UserLabelStddevFields = {
  __typename?: 'user_label_stddev_fields';
  classification_id?: Maybe<Scalars['Float']['output']>;
  confidence_id?: Maybe<Scalars['Float']['output']>;
  detection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "user_label" */
export type UserLabelStddevOrderBy = {
  classification_id?: InputMaybe<OrderBy>;
  confidence_id?: InputMaybe<OrderBy>;
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UserLabelStddevPopFields = {
  __typename?: 'user_label_stddev_pop_fields';
  classification_id?: Maybe<Scalars['Float']['output']>;
  confidence_id?: Maybe<Scalars['Float']['output']>;
  detection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "user_label" */
export type UserLabelStddevPopOrderBy = {
  classification_id?: InputMaybe<OrderBy>;
  confidence_id?: InputMaybe<OrderBy>;
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UserLabelStddevSampFields = {
  __typename?: 'user_label_stddev_samp_fields';
  classification_id?: Maybe<Scalars['Float']['output']>;
  confidence_id?: Maybe<Scalars['Float']['output']>;
  detection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "user_label" */
export type UserLabelStddevSampOrderBy = {
  classification_id?: InputMaybe<OrderBy>;
  confidence_id?: InputMaybe<OrderBy>;
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "user_label" */
export type UserLabelStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserLabelStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserLabelStreamCursorValueInput = {
  classification_id?: InputMaybe<Scalars['Int']['input']>;
  confidence_id?: InputMaybe<Scalars['Int']['input']>;
  create_time?: InputMaybe<Scalars['timestamptz']['input']>;
  detection_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  modified_time?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type UserLabelSumFields = {
  __typename?: 'user_label_sum_fields';
  classification_id?: Maybe<Scalars['Int']['output']>;
  confidence_id?: Maybe<Scalars['Int']['output']>;
  detection_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "user_label" */
export type UserLabelSumOrderBy = {
  classification_id?: InputMaybe<OrderBy>;
  confidence_id?: InputMaybe<OrderBy>;
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** update columns of table "user_label" */
export enum UserLabelUpdateColumn {
  /** column name */
  classification_id = 'classification_id',
  /** column name */
  confidence_id = 'confidence_id',
  /** column name */
  create_time = 'create_time',
  /** column name */
  detection_id = 'detection_id',
  /** column name */
  id = 'id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  modified_time = 'modified_time',
  /** column name */
  user_id = 'user_id'
}

export type UserLabelUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<UserLabelAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<UserLabelDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<UserLabelDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<UserLabelDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserLabelIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<UserLabelPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserLabelSetInput>;
  /** filter the rows which have to be updated */
  where: UserLabelBoolExp;
};

/** aggregate var_pop on columns */
export type UserLabelVarPopFields = {
  __typename?: 'user_label_var_pop_fields';
  classification_id?: Maybe<Scalars['Float']['output']>;
  confidence_id?: Maybe<Scalars['Float']['output']>;
  detection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "user_label" */
export type UserLabelVarPopOrderBy = {
  classification_id?: InputMaybe<OrderBy>;
  confidence_id?: InputMaybe<OrderBy>;
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UserLabelVarSampFields = {
  __typename?: 'user_label_var_samp_fields';
  classification_id?: Maybe<Scalars['Float']['output']>;
  confidence_id?: Maybe<Scalars['Float']['output']>;
  detection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "user_label" */
export type UserLabelVarSampOrderBy = {
  classification_id?: InputMaybe<OrderBy>;
  confidence_id?: InputMaybe<OrderBy>;
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserLabelVarianceFields = {
  __typename?: 'user_label_variance_fields';
  classification_id?: Maybe<Scalars['Float']['output']>;
  confidence_id?: Maybe<Scalars['Float']['output']>;
  detection_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "user_label" */
export type UserLabelVarianceOrderBy = {
  classification_id?: InputMaybe<OrderBy>;
  confidence_id?: InputMaybe<OrderBy>;
  detection_id?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate max on columns */
export type UserMaxFields = {
  __typename?: 'user_max_fields';
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type UserMinFields = {
  __typename?: 'user_min_fields';
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "user" */
export type UserMutationResponse = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** columns and relationships of "user_notification" */
export type UserNotification = {
  __typename?: 'user_notification';
  email_sent_at?: Maybe<Scalars['timestamptz']['output']>;
  email_sent_to?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  notification: Notification;
  notification_id: Scalars['Int']['output'];
  sms_sent_at?: Maybe<Scalars['timestamptz']['output']>;
  sms_sent_to?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  user: User;
  user_id: Scalars['Int']['output'];
};

/** aggregated selection of "user_notification" */
export type UserNotificationAggregate = {
  __typename?: 'user_notification_aggregate';
  aggregate?: Maybe<UserNotificationAggregateFields>;
  nodes: Array<UserNotification>;
};

export type UserNotificationAggregateBoolExp = {
  count?: InputMaybe<UserNotificationAggregateBoolExpCount>;
};

export type UserNotificationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserNotificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserNotificationBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "user_notification" */
export type UserNotificationAggregateFields = {
  __typename?: 'user_notification_aggregate_fields';
  avg?: Maybe<UserNotificationAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserNotificationMaxFields>;
  min?: Maybe<UserNotificationMinFields>;
  stddev?: Maybe<UserNotificationStddevFields>;
  stddev_pop?: Maybe<UserNotificationStddevPopFields>;
  stddev_samp?: Maybe<UserNotificationStddevSampFields>;
  sum?: Maybe<UserNotificationSumFields>;
  var_pop?: Maybe<UserNotificationVarPopFields>;
  var_samp?: Maybe<UserNotificationVarSampFields>;
  variance?: Maybe<UserNotificationVarianceFields>;
};


/** aggregate fields of "user_notification" */
export type UserNotificationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserNotificationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_notification" */
export type UserNotificationAggregateOrderBy = {
  avg?: InputMaybe<UserNotificationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserNotificationMaxOrderBy>;
  min?: InputMaybe<UserNotificationMinOrderBy>;
  stddev?: InputMaybe<UserNotificationStddevOrderBy>;
  stddev_pop?: InputMaybe<UserNotificationStddevPopOrderBy>;
  stddev_samp?: InputMaybe<UserNotificationStddevSampOrderBy>;
  sum?: InputMaybe<UserNotificationSumOrderBy>;
  var_pop?: InputMaybe<UserNotificationVarPopOrderBy>;
  var_samp?: InputMaybe<UserNotificationVarSampOrderBy>;
  variance?: InputMaybe<UserNotificationVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "user_notification" */
export type UserNotificationArrRelInsertInput = {
  data: Array<UserNotificationInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserNotificationOnConflict>;
};

/** aggregate avg on columns */
export type UserNotificationAvgFields = {
  __typename?: 'user_notification_avg_fields';
  notification_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "user_notification" */
export type UserNotificationAvgOrderBy = {
  notification_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "user_notification". All fields are combined with a logical 'AND'. */
export type UserNotificationBoolExp = {
  _and?: InputMaybe<Array<UserNotificationBoolExp>>;
  _not?: InputMaybe<UserNotificationBoolExp>;
  _or?: InputMaybe<Array<UserNotificationBoolExp>>;
  email_sent_at?: InputMaybe<TimestamptzComparisonExp>;
  email_sent_to?: InputMaybe<StringComparisonExp>;
  notification?: InputMaybe<NotificationBoolExp>;
  notification_id?: InputMaybe<IntComparisonExp>;
  sms_sent_at?: InputMaybe<TimestamptzComparisonExp>;
  sms_sent_to?: InputMaybe<StringComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  user_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "user_notification" */
export enum UserNotificationConstraint {
  /** unique or primary key constraint on columns "user_id", "notification_id" */
  user_notification_pkey = 'user_notification_pkey'
}

/** input type for incrementing numeric columns in table "user_notification" */
export type UserNotificationIncInput = {
  notification_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user_notification" */
export type UserNotificationInsertInput = {
  email_sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email_sent_to?: InputMaybe<Scalars['String']['input']>;
  notification?: InputMaybe<NotificationObjRelInsertInput>;
  notification_id?: InputMaybe<Scalars['Int']['input']>;
  sms_sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sms_sent_to?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type UserNotificationMaxFields = {
  __typename?: 'user_notification_max_fields';
  email_sent_at?: Maybe<Scalars['timestamptz']['output']>;
  email_sent_to?: Maybe<Scalars['String']['output']>;
  notification_id?: Maybe<Scalars['Int']['output']>;
  sms_sent_at?: Maybe<Scalars['timestamptz']['output']>;
  sms_sent_to?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "user_notification" */
export type UserNotificationMaxOrderBy = {
  email_sent_at?: InputMaybe<OrderBy>;
  email_sent_to?: InputMaybe<OrderBy>;
  notification_id?: InputMaybe<OrderBy>;
  sms_sent_at?: InputMaybe<OrderBy>;
  sms_sent_to?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserNotificationMinFields = {
  __typename?: 'user_notification_min_fields';
  email_sent_at?: Maybe<Scalars['timestamptz']['output']>;
  email_sent_to?: Maybe<Scalars['String']['output']>;
  notification_id?: Maybe<Scalars['Int']['output']>;
  sms_sent_at?: Maybe<Scalars['timestamptz']['output']>;
  sms_sent_to?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "user_notification" */
export type UserNotificationMinOrderBy = {
  email_sent_at?: InputMaybe<OrderBy>;
  email_sent_to?: InputMaybe<OrderBy>;
  notification_id?: InputMaybe<OrderBy>;
  sms_sent_at?: InputMaybe<OrderBy>;
  sms_sent_to?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "user_notification" */
export type UserNotificationMutationResponse = {
  __typename?: 'user_notification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserNotification>;
};

/** on_conflict condition type for table "user_notification" */
export type UserNotificationOnConflict = {
  constraint: UserNotificationConstraint;
  update_columns?: Array<UserNotificationUpdateColumn>;
  where?: InputMaybe<UserNotificationBoolExp>;
};

/** Ordering options when selecting data from "user_notification". */
export type UserNotificationOrderBy = {
  email_sent_at?: InputMaybe<OrderBy>;
  email_sent_to?: InputMaybe<OrderBy>;
  notification?: InputMaybe<NotificationOrderBy>;
  notification_id?: InputMaybe<OrderBy>;
  sms_sent_at?: InputMaybe<OrderBy>;
  sms_sent_to?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user_notification */
export type UserNotificationPkColumnsInput = {
  notification_id: Scalars['Int']['input'];
  user_id: Scalars['Int']['input'];
};

/** select columns of table "user_notification" */
export enum UserNotificationSelectColumn {
  /** column name */
  email_sent_at = 'email_sent_at',
  /** column name */
  email_sent_to = 'email_sent_to',
  /** column name */
  notification_id = 'notification_id',
  /** column name */
  sms_sent_at = 'sms_sent_at',
  /** column name */
  sms_sent_to = 'sms_sent_to',
  /** column name */
  user_id = 'user_id'
}

/** input type for updating data in table "user_notification" */
export type UserNotificationSetInput = {
  email_sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email_sent_to?: InputMaybe<Scalars['String']['input']>;
  notification_id?: InputMaybe<Scalars['Int']['input']>;
  sms_sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sms_sent_to?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** columns and relationships of "user_notification_setting" */
export type UserNotificationSetting = {
  __typename?: 'user_notification_setting';
  location_id?: Maybe<Scalars['Int']['output']>;
  notification_type: Scalars['String']['output'];
  organization_code?: Maybe<Scalars['String']['output']>;
  target: Scalars['String']['output'];
  uid: Scalars['uuid']['output'];
  /** An object relationship */
  user: User;
  user_id: Scalars['Int']['output'];
  zone_uid?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "user_notification_setting" */
export type UserNotificationSettingAggregate = {
  __typename?: 'user_notification_setting_aggregate';
  aggregate?: Maybe<UserNotificationSettingAggregateFields>;
  nodes: Array<UserNotificationSetting>;
};

export type UserNotificationSettingAggregateBoolExp = {
  count?: InputMaybe<UserNotificationSettingAggregateBoolExpCount>;
};

export type UserNotificationSettingAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserNotificationSettingSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserNotificationSettingBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "user_notification_setting" */
export type UserNotificationSettingAggregateFields = {
  __typename?: 'user_notification_setting_aggregate_fields';
  avg?: Maybe<UserNotificationSettingAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserNotificationSettingMaxFields>;
  min?: Maybe<UserNotificationSettingMinFields>;
  stddev?: Maybe<UserNotificationSettingStddevFields>;
  stddev_pop?: Maybe<UserNotificationSettingStddevPopFields>;
  stddev_samp?: Maybe<UserNotificationSettingStddevSampFields>;
  sum?: Maybe<UserNotificationSettingSumFields>;
  var_pop?: Maybe<UserNotificationSettingVarPopFields>;
  var_samp?: Maybe<UserNotificationSettingVarSampFields>;
  variance?: Maybe<UserNotificationSettingVarianceFields>;
};


/** aggregate fields of "user_notification_setting" */
export type UserNotificationSettingAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserNotificationSettingSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_notification_setting" */
export type UserNotificationSettingAggregateOrderBy = {
  avg?: InputMaybe<UserNotificationSettingAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserNotificationSettingMaxOrderBy>;
  min?: InputMaybe<UserNotificationSettingMinOrderBy>;
  stddev?: InputMaybe<UserNotificationSettingStddevOrderBy>;
  stddev_pop?: InputMaybe<UserNotificationSettingStddevPopOrderBy>;
  stddev_samp?: InputMaybe<UserNotificationSettingStddevSampOrderBy>;
  sum?: InputMaybe<UserNotificationSettingSumOrderBy>;
  var_pop?: InputMaybe<UserNotificationSettingVarPopOrderBy>;
  var_samp?: InputMaybe<UserNotificationSettingVarSampOrderBy>;
  variance?: InputMaybe<UserNotificationSettingVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "user_notification_setting" */
export type UserNotificationSettingArrRelInsertInput = {
  data: Array<UserNotificationSettingInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserNotificationSettingOnConflict>;
};

/** aggregate avg on columns */
export type UserNotificationSettingAvgFields = {
  __typename?: 'user_notification_setting_avg_fields';
  location_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "user_notification_setting" */
export type UserNotificationSettingAvgOrderBy = {
  location_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "user_notification_setting". All fields are combined with a logical 'AND'. */
export type UserNotificationSettingBoolExp = {
  _and?: InputMaybe<Array<UserNotificationSettingBoolExp>>;
  _not?: InputMaybe<UserNotificationSettingBoolExp>;
  _or?: InputMaybe<Array<UserNotificationSettingBoolExp>>;
  location_id?: InputMaybe<IntComparisonExp>;
  notification_type?: InputMaybe<StringComparisonExp>;
  organization_code?: InputMaybe<StringComparisonExp>;
  target?: InputMaybe<StringComparisonExp>;
  uid?: InputMaybe<UuidComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  user_id?: InputMaybe<IntComparisonExp>;
  zone_uid?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "user_notification_setting" */
export enum UserNotificationSettingConstraint {
  /** unique or primary key constraint on columns "uid" */
  user_notification_setting_pkey = 'user_notification_setting_pkey'
}

/** input type for incrementing numeric columns in table "user_notification_setting" */
export type UserNotificationSettingIncInput = {
  location_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user_notification_setting" */
export type UserNotificationSettingInsertInput = {
  location_id?: InputMaybe<Scalars['Int']['input']>;
  notification_type?: InputMaybe<Scalars['String']['input']>;
  organization_code?: InputMaybe<Scalars['String']['input']>;
  target?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  zone_uid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type UserNotificationSettingMaxFields = {
  __typename?: 'user_notification_setting_max_fields';
  location_id?: Maybe<Scalars['Int']['output']>;
  notification_type?: Maybe<Scalars['String']['output']>;
  organization_code?: Maybe<Scalars['String']['output']>;
  target?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  zone_uid?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "user_notification_setting" */
export type UserNotificationSettingMaxOrderBy = {
  location_id?: InputMaybe<OrderBy>;
  notification_type?: InputMaybe<OrderBy>;
  organization_code?: InputMaybe<OrderBy>;
  target?: InputMaybe<OrderBy>;
  uid?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
  zone_uid?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserNotificationSettingMinFields = {
  __typename?: 'user_notification_setting_min_fields';
  location_id?: Maybe<Scalars['Int']['output']>;
  notification_type?: Maybe<Scalars['String']['output']>;
  organization_code?: Maybe<Scalars['String']['output']>;
  target?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  zone_uid?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "user_notification_setting" */
export type UserNotificationSettingMinOrderBy = {
  location_id?: InputMaybe<OrderBy>;
  notification_type?: InputMaybe<OrderBy>;
  organization_code?: InputMaybe<OrderBy>;
  target?: InputMaybe<OrderBy>;
  uid?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
  zone_uid?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "user_notification_setting" */
export type UserNotificationSettingMutationResponse = {
  __typename?: 'user_notification_setting_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserNotificationSetting>;
};

/** on_conflict condition type for table "user_notification_setting" */
export type UserNotificationSettingOnConflict = {
  constraint: UserNotificationSettingConstraint;
  update_columns?: Array<UserNotificationSettingUpdateColumn>;
  where?: InputMaybe<UserNotificationSettingBoolExp>;
};

/** Ordering options when selecting data from "user_notification_setting". */
export type UserNotificationSettingOrderBy = {
  location_id?: InputMaybe<OrderBy>;
  notification_type?: InputMaybe<OrderBy>;
  organization_code?: InputMaybe<OrderBy>;
  target?: InputMaybe<OrderBy>;
  uid?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  user_id?: InputMaybe<OrderBy>;
  zone_uid?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user_notification_setting */
export type UserNotificationSettingPkColumnsInput = {
  uid: Scalars['uuid']['input'];
};

/** select columns of table "user_notification_setting" */
export enum UserNotificationSettingSelectColumn {
  /** column name */
  location_id = 'location_id',
  /** column name */
  notification_type = 'notification_type',
  /** column name */
  organization_code = 'organization_code',
  /** column name */
  target = 'target',
  /** column name */
  uid = 'uid',
  /** column name */
  user_id = 'user_id',
  /** column name */
  zone_uid = 'zone_uid'
}

/** input type for updating data in table "user_notification_setting" */
export type UserNotificationSettingSetInput = {
  location_id?: InputMaybe<Scalars['Int']['input']>;
  notification_type?: InputMaybe<Scalars['String']['input']>;
  organization_code?: InputMaybe<Scalars['String']['input']>;
  target?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  zone_uid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type UserNotificationSettingStddevFields = {
  __typename?: 'user_notification_setting_stddev_fields';
  location_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "user_notification_setting" */
export type UserNotificationSettingStddevOrderBy = {
  location_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UserNotificationSettingStddevPopFields = {
  __typename?: 'user_notification_setting_stddev_pop_fields';
  location_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "user_notification_setting" */
export type UserNotificationSettingStddevPopOrderBy = {
  location_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UserNotificationSettingStddevSampFields = {
  __typename?: 'user_notification_setting_stddev_samp_fields';
  location_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "user_notification_setting" */
export type UserNotificationSettingStddevSampOrderBy = {
  location_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "user_notification_setting" */
export type UserNotificationSettingStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserNotificationSettingStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserNotificationSettingStreamCursorValueInput = {
  location_id?: InputMaybe<Scalars['Int']['input']>;
  notification_type?: InputMaybe<Scalars['String']['input']>;
  organization_code?: InputMaybe<Scalars['String']['input']>;
  target?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  zone_uid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type UserNotificationSettingSumFields = {
  __typename?: 'user_notification_setting_sum_fields';
  location_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "user_notification_setting" */
export type UserNotificationSettingSumOrderBy = {
  location_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** update columns of table "user_notification_setting" */
export enum UserNotificationSettingUpdateColumn {
  /** column name */
  location_id = 'location_id',
  /** column name */
  notification_type = 'notification_type',
  /** column name */
  organization_code = 'organization_code',
  /** column name */
  target = 'target',
  /** column name */
  uid = 'uid',
  /** column name */
  user_id = 'user_id',
  /** column name */
  zone_uid = 'zone_uid'
}

export type UserNotificationSettingUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserNotificationSettingIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserNotificationSettingSetInput>;
  /** filter the rows which have to be updated */
  where: UserNotificationSettingBoolExp;
};

/** aggregate var_pop on columns */
export type UserNotificationSettingVarPopFields = {
  __typename?: 'user_notification_setting_var_pop_fields';
  location_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "user_notification_setting" */
export type UserNotificationSettingVarPopOrderBy = {
  location_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UserNotificationSettingVarSampFields = {
  __typename?: 'user_notification_setting_var_samp_fields';
  location_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "user_notification_setting" */
export type UserNotificationSettingVarSampOrderBy = {
  location_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserNotificationSettingVarianceFields = {
  __typename?: 'user_notification_setting_variance_fields';
  location_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "user_notification_setting" */
export type UserNotificationSettingVarianceOrderBy = {
  location_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev on columns */
export type UserNotificationStddevFields = {
  __typename?: 'user_notification_stddev_fields';
  notification_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "user_notification" */
export type UserNotificationStddevOrderBy = {
  notification_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UserNotificationStddevPopFields = {
  __typename?: 'user_notification_stddev_pop_fields';
  notification_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "user_notification" */
export type UserNotificationStddevPopOrderBy = {
  notification_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UserNotificationStddevSampFields = {
  __typename?: 'user_notification_stddev_samp_fields';
  notification_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "user_notification" */
export type UserNotificationStddevSampOrderBy = {
  notification_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "user_notification" */
export type UserNotificationStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserNotificationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserNotificationStreamCursorValueInput = {
  email_sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email_sent_to?: InputMaybe<Scalars['String']['input']>;
  notification_id?: InputMaybe<Scalars['Int']['input']>;
  sms_sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  sms_sent_to?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type UserNotificationSumFields = {
  __typename?: 'user_notification_sum_fields';
  notification_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "user_notification" */
export type UserNotificationSumOrderBy = {
  notification_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** update columns of table "user_notification" */
export enum UserNotificationUpdateColumn {
  /** column name */
  email_sent_at = 'email_sent_at',
  /** column name */
  email_sent_to = 'email_sent_to',
  /** column name */
  notification_id = 'notification_id',
  /** column name */
  sms_sent_at = 'sms_sent_at',
  /** column name */
  sms_sent_to = 'sms_sent_to',
  /** column name */
  user_id = 'user_id'
}

export type UserNotificationUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserNotificationIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserNotificationSetInput>;
  /** filter the rows which have to be updated */
  where: UserNotificationBoolExp;
};

/** aggregate var_pop on columns */
export type UserNotificationVarPopFields = {
  __typename?: 'user_notification_var_pop_fields';
  notification_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "user_notification" */
export type UserNotificationVarPopOrderBy = {
  notification_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UserNotificationVarSampFields = {
  __typename?: 'user_notification_var_samp_fields';
  notification_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "user_notification" */
export type UserNotificationVarSampOrderBy = {
  notification_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserNotificationVarianceFields = {
  __typename?: 'user_notification_variance_fields';
  notification_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "user_notification" */
export type UserNotificationVarianceOrderBy = {
  notification_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
};

/** input type for inserting object relation for remote table "user" */
export type UserObjRelInsertInput = {
  data: UserInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<UserOnConflict>;
};

/** on_conflict condition type for table "user" */
export type UserOnConflict = {
  constraint: UserConstraint;
  update_columns?: Array<UserUpdateColumn>;
  where?: InputMaybe<UserBoolExp>;
};

/** Ordering options when selecting data from "user". */
export type UserOrderBy = {
  active?: InputMaybe<OrderBy>;
  comments_aggregate?: InputMaybe<CommentAggregateOrderBy>;
  discussions_aggregate?: InputMaybe<DiscussionAggregateOrderBy>;
  email?: InputMaybe<OrderBy>;
  field_interventions_aggregate?: InputMaybe<FieldInterventionAggregateOrderBy>;
  first_name?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  image_annotation_trackings_aggregate?: InputMaybe<ImageAnnotationTrackingAggregateOrderBy>;
  labelsByHumanSourceId_aggregate?: InputMaybe<LabelAggregateOrderBy>;
  labels_aggregate?: InputMaybe<LabelAggregateOrderBy>;
  last_name?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  password?: InputMaybe<OrderBy>;
  phone_number?: InputMaybe<OrderBy>;
  user_comments_aggregate?: InputMaybe<UserCommentAggregateOrderBy>;
  user_labels_aggregate?: InputMaybe<UserLabelAggregateOrderBy>;
  user_notification_settings_aggregate?: InputMaybe<UserNotificationSettingAggregateOrderBy>;
  user_notifications_aggregate?: InputMaybe<UserNotificationAggregateOrderBy>;
  user_organization_roles_aggregate?: InputMaybe<UserOrganizationRoleAggregateOrderBy>;
};

/** columns and relationships of "user_organization_role" */
export type UserOrganizationRole = {
  __typename?: 'user_organization_role';
  id: Scalars['uuid']['output'];
  /** An object relationship */
  location?: Maybe<Location>;
  location_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  organization?: Maybe<Organization>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  role: Enumeration;
  role_id: Scalars['Int']['output'];
  /** An object relationship */
  user: User;
  user_id: Scalars['Int']['output'];
  /** An object relationship */
  zone?: Maybe<Zone>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "user_organization_role" */
export type UserOrganizationRoleAggregate = {
  __typename?: 'user_organization_role_aggregate';
  aggregate?: Maybe<UserOrganizationRoleAggregateFields>;
  nodes: Array<UserOrganizationRole>;
};

export type UserOrganizationRoleAggregateBoolExp = {
  count?: InputMaybe<UserOrganizationRoleAggregateBoolExpCount>;
};

export type UserOrganizationRoleAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserOrganizationRoleSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserOrganizationRoleBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "user_organization_role" */
export type UserOrganizationRoleAggregateFields = {
  __typename?: 'user_organization_role_aggregate_fields';
  avg?: Maybe<UserOrganizationRoleAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<UserOrganizationRoleMaxFields>;
  min?: Maybe<UserOrganizationRoleMinFields>;
  stddev?: Maybe<UserOrganizationRoleStddevFields>;
  stddev_pop?: Maybe<UserOrganizationRoleStddevPopFields>;
  stddev_samp?: Maybe<UserOrganizationRoleStddevSampFields>;
  sum?: Maybe<UserOrganizationRoleSumFields>;
  var_pop?: Maybe<UserOrganizationRoleVarPopFields>;
  var_samp?: Maybe<UserOrganizationRoleVarSampFields>;
  variance?: Maybe<UserOrganizationRoleVarianceFields>;
};


/** aggregate fields of "user_organization_role" */
export type UserOrganizationRoleAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserOrganizationRoleSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_organization_role" */
export type UserOrganizationRoleAggregateOrderBy = {
  avg?: InputMaybe<UserOrganizationRoleAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserOrganizationRoleMaxOrderBy>;
  min?: InputMaybe<UserOrganizationRoleMinOrderBy>;
  stddev?: InputMaybe<UserOrganizationRoleStddevOrderBy>;
  stddev_pop?: InputMaybe<UserOrganizationRoleStddevPopOrderBy>;
  stddev_samp?: InputMaybe<UserOrganizationRoleStddevSampOrderBy>;
  sum?: InputMaybe<UserOrganizationRoleSumOrderBy>;
  var_pop?: InputMaybe<UserOrganizationRoleVarPopOrderBy>;
  var_samp?: InputMaybe<UserOrganizationRoleVarSampOrderBy>;
  variance?: InputMaybe<UserOrganizationRoleVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "user_organization_role" */
export type UserOrganizationRoleArrRelInsertInput = {
  data: Array<UserOrganizationRoleInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserOrganizationRoleOnConflict>;
};

/** aggregate avg on columns */
export type UserOrganizationRoleAvgFields = {
  __typename?: 'user_organization_role_avg_fields';
  location_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "user_organization_role" */
export type UserOrganizationRoleAvgOrderBy = {
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  role_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "user_organization_role". All fields are combined with a logical 'AND'. */
export type UserOrganizationRoleBoolExp = {
  _and?: InputMaybe<Array<UserOrganizationRoleBoolExp>>;
  _not?: InputMaybe<UserOrganizationRoleBoolExp>;
  _or?: InputMaybe<Array<UserOrganizationRoleBoolExp>>;
  id?: InputMaybe<UuidComparisonExp>;
  location?: InputMaybe<LocationBoolExp>;
  location_id?: InputMaybe<IntComparisonExp>;
  organization?: InputMaybe<OrganizationBoolExp>;
  organization_id?: InputMaybe<IntComparisonExp>;
  role?: InputMaybe<EnumerationBoolExp>;
  role_id?: InputMaybe<IntComparisonExp>;
  user?: InputMaybe<UserBoolExp>;
  user_id?: InputMaybe<IntComparisonExp>;
  zone?: InputMaybe<ZoneBoolExp>;
  zone_id?: InputMaybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "user_organization_role" */
export enum UserOrganizationRoleConstraint {
  /** unique or primary key constraint on columns "id" */
  pk_user_organization_role = 'pk_user_organization_role',
  /** unique or primary key constraint on columns "role_id", "zone_id", "user_id", "organization_id", "location_id" */
  user_organization_role_unique_constraint = 'user_organization_role_unique_constraint'
}

/** input type for incrementing numeric columns in table "user_organization_role" */
export type UserOrganizationRoleIncInput = {
  location_id?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user_organization_role" */
export type UserOrganizationRoleInsertInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  location?: InputMaybe<LocationObjRelInsertInput>;
  location_id?: InputMaybe<Scalars['Int']['input']>;
  organization?: InputMaybe<OrganizationObjRelInsertInput>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<EnumerationObjRelInsertInput>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<UserObjRelInsertInput>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  zone?: InputMaybe<ZoneObjRelInsertInput>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type UserOrganizationRoleMaxFields = {
  __typename?: 'user_organization_role_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "user_organization_role" */
export type UserOrganizationRoleMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  role_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserOrganizationRoleMinFields = {
  __typename?: 'user_organization_role_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "user_organization_role" */
export type UserOrganizationRoleMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  role_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "user_organization_role" */
export type UserOrganizationRoleMutationResponse = {
  __typename?: 'user_organization_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserOrganizationRole>;
};

/** on_conflict condition type for table "user_organization_role" */
export type UserOrganizationRoleOnConflict = {
  constraint: UserOrganizationRoleConstraint;
  update_columns?: Array<UserOrganizationRoleUpdateColumn>;
  where?: InputMaybe<UserOrganizationRoleBoolExp>;
};

/** Ordering options when selecting data from "user_organization_role". */
export type UserOrganizationRoleOrderBy = {
  id?: InputMaybe<OrderBy>;
  location?: InputMaybe<LocationOrderBy>;
  location_id?: InputMaybe<OrderBy>;
  organization?: InputMaybe<OrganizationOrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  role?: InputMaybe<EnumerationOrderBy>;
  role_id?: InputMaybe<OrderBy>;
  user?: InputMaybe<UserOrderBy>;
  user_id?: InputMaybe<OrderBy>;
  zone?: InputMaybe<ZoneOrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user_organization_role */
export type UserOrganizationRolePkColumnsInput = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_organization_role" */
export enum UserOrganizationRoleSelectColumn {
  /** column name */
  id = 'id',
  /** column name */
  location_id = 'location_id',
  /** column name */
  organization_id = 'organization_id',
  /** column name */
  role_id = 'role_id',
  /** column name */
  user_id = 'user_id',
  /** column name */
  zone_id = 'zone_id'
}

/** input type for updating data in table "user_organization_role" */
export type UserOrganizationRoleSetInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type UserOrganizationRoleStddevFields = {
  __typename?: 'user_organization_role_stddev_fields';
  location_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "user_organization_role" */
export type UserOrganizationRoleStddevOrderBy = {
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  role_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UserOrganizationRoleStddevPopFields = {
  __typename?: 'user_organization_role_stddev_pop_fields';
  location_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "user_organization_role" */
export type UserOrganizationRoleStddevPopOrderBy = {
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  role_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UserOrganizationRoleStddevSampFields = {
  __typename?: 'user_organization_role_stddev_samp_fields';
  location_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "user_organization_role" */
export type UserOrganizationRoleStddevSampOrderBy = {
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  role_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "user_organization_role" */
export type UserOrganizationRoleStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserOrganizationRoleStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserOrganizationRoleStreamCursorValueInput = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  location_id?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['Int']['input']>;
  role_id?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['Int']['input']>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type UserOrganizationRoleSumFields = {
  __typename?: 'user_organization_role_sum_fields';
  location_id?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['Int']['output']>;
  role_id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "user_organization_role" */
export type UserOrganizationRoleSumOrderBy = {
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  role_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** update columns of table "user_organization_role" */
export enum UserOrganizationRoleUpdateColumn {
  /** column name */
  id = 'id',
  /** column name */
  location_id = 'location_id',
  /** column name */
  organization_id = 'organization_id',
  /** column name */
  role_id = 'role_id',
  /** column name */
  user_id = 'user_id',
  /** column name */
  zone_id = 'zone_id'
}

export type UserOrganizationRoleUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserOrganizationRoleIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserOrganizationRoleSetInput>;
  /** filter the rows which have to be updated */
  where: UserOrganizationRoleBoolExp;
};

/** aggregate var_pop on columns */
export type UserOrganizationRoleVarPopFields = {
  __typename?: 'user_organization_role_var_pop_fields';
  location_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "user_organization_role" */
export type UserOrganizationRoleVarPopOrderBy = {
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  role_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UserOrganizationRoleVarSampFields = {
  __typename?: 'user_organization_role_var_samp_fields';
  location_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "user_organization_role" */
export type UserOrganizationRoleVarSampOrderBy = {
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  role_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type UserOrganizationRoleVarianceFields = {
  __typename?: 'user_organization_role_variance_fields';
  location_id?: Maybe<Scalars['Float']['output']>;
  organization_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
  user_id?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "user_organization_role" */
export type UserOrganizationRoleVarianceOrderBy = {
  location_id?: InputMaybe<OrderBy>;
  organization_id?: InputMaybe<OrderBy>;
  role_id?: InputMaybe<OrderBy>;
  user_id?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: user */
export type UserPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type UserPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "user" */
export enum UserSelectColumn {
  /** column name */
  active = 'active',
  /** column name */
  email = 'email',
  /** column name */
  first_name = 'first_name',
  /** column name */
  id = 'id',
  /** column name */
  last_name = 'last_name',
  /** column name */
  metadata = 'metadata',
  /** column name */
  password = 'password',
  /** column name */
  phone_number = 'phone_number'
}

/** input type for updating data in table "user" */
export type UserSetInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type UserStddevFields = {
  __typename?: 'user_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type UserStddevPopFields = {
  __typename?: 'user_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type UserStddevSampFields = {
  __typename?: 'user_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "user" */
export type UserStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserStreamCursorValueInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type UserSumFields = {
  __typename?: 'user_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "user" */
export enum UserUpdateColumn {
  /** column name */
  active = 'active',
  /** column name */
  email = 'email',
  /** column name */
  first_name = 'first_name',
  /** column name */
  id = 'id',
  /** column name */
  last_name = 'last_name',
  /** column name */
  metadata = 'metadata',
  /** column name */
  password = 'password',
  /** column name */
  phone_number = 'phone_number'
}

export type UserUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<UserAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<UserDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<UserDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<UserDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<UserIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<UserPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserSetInput>;
  /** filter the rows which have to be updated */
  where: UserBoolExp;
};

/** aggregate var_pop on columns */
export type UserVarPopFields = {
  __typename?: 'user_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type UserVarSampFields = {
  __typename?: 'user_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type UserVarianceFields = {
  __typename?: 'user_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** columns and relationships of "version" */
export type Version = {
  __typename?: 'version';
  /** An array relationship */
  configs: Array<Config>;
  /** An aggregate relationship */
  configs_aggregate: ConfigAggregate;
  /** An array relationship */
  detectors: Array<Detector>;
  /** An aggregate relationship */
  detectors_aggregate: DetectorAggregate;
  id: Scalars['Int']['output'];
  major: Scalars['Int']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  minor: Scalars['Int']['output'];
  patch: Scalars['Int']['output'];
  /** An array relationship */
  systems: Array<System>;
  /** An array relationship */
  systemsBySoftwareVersionId: Array<System>;
  /** An aggregate relationship */
  systemsBySoftwareVersionId_aggregate: SystemAggregate;
  /** An aggregate relationship */
  systems_aggregate: SystemAggregate;
  type: Scalars['String']['output'];
};


/** columns and relationships of "version" */
export type VersionConfigsArgs = {
  distinct_on?: InputMaybe<Array<ConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConfigOrderBy>>;
  where?: InputMaybe<ConfigBoolExp>;
};


/** columns and relationships of "version" */
export type VersionConfigsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConfigOrderBy>>;
  where?: InputMaybe<ConfigBoolExp>;
};


/** columns and relationships of "version" */
export type VersionDetectorsArgs = {
  distinct_on?: InputMaybe<Array<DetectorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectorOrderBy>>;
  where?: InputMaybe<DetectorBoolExp>;
};


/** columns and relationships of "version" */
export type VersionDetectorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DetectorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DetectorOrderBy>>;
  where?: InputMaybe<DetectorBoolExp>;
};


/** columns and relationships of "version" */
export type VersionMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "version" */
export type VersionSystemsArgs = {
  distinct_on?: InputMaybe<Array<SystemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SystemOrderBy>>;
  where?: InputMaybe<SystemBoolExp>;
};


/** columns and relationships of "version" */
export type VersionSystemsBySoftwareVersionIdArgs = {
  distinct_on?: InputMaybe<Array<SystemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SystemOrderBy>>;
  where?: InputMaybe<SystemBoolExp>;
};


/** columns and relationships of "version" */
export type VersionSystemsBySoftwareVersionIdAggregateArgs = {
  distinct_on?: InputMaybe<Array<SystemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SystemOrderBy>>;
  where?: InputMaybe<SystemBoolExp>;
};


/** columns and relationships of "version" */
export type VersionSystemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<SystemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SystemOrderBy>>;
  where?: InputMaybe<SystemBoolExp>;
};

/** aggregated selection of "version" */
export type VersionAggregate = {
  __typename?: 'version_aggregate';
  aggregate?: Maybe<VersionAggregateFields>;
  nodes: Array<Version>;
};

/** aggregate fields of "version" */
export type VersionAggregateFields = {
  __typename?: 'version_aggregate_fields';
  avg?: Maybe<VersionAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<VersionMaxFields>;
  min?: Maybe<VersionMinFields>;
  stddev?: Maybe<VersionStddevFields>;
  stddev_pop?: Maybe<VersionStddevPopFields>;
  stddev_samp?: Maybe<VersionStddevSampFields>;
  sum?: Maybe<VersionSumFields>;
  var_pop?: Maybe<VersionVarPopFields>;
  var_samp?: Maybe<VersionVarSampFields>;
  variance?: Maybe<VersionVarianceFields>;
};


/** aggregate fields of "version" */
export type VersionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<VersionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type VersionAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type VersionAvgFields = {
  __typename?: 'version_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  major?: Maybe<Scalars['Float']['output']>;
  minor?: Maybe<Scalars['Float']['output']>;
  patch?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "version". All fields are combined with a logical 'AND'. */
export type VersionBoolExp = {
  _and?: InputMaybe<Array<VersionBoolExp>>;
  _not?: InputMaybe<VersionBoolExp>;
  _or?: InputMaybe<Array<VersionBoolExp>>;
  configs?: InputMaybe<ConfigBoolExp>;
  configs_aggregate?: InputMaybe<ConfigAggregateBoolExp>;
  detectors?: InputMaybe<DetectorBoolExp>;
  detectors_aggregate?: InputMaybe<DetectorAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  major?: InputMaybe<IntComparisonExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  minor?: InputMaybe<IntComparisonExp>;
  patch?: InputMaybe<IntComparisonExp>;
  systems?: InputMaybe<SystemBoolExp>;
  systemsBySoftwareVersionId?: InputMaybe<SystemBoolExp>;
  systemsBySoftwareVersionId_aggregate?: InputMaybe<SystemAggregateBoolExp>;
  systems_aggregate?: InputMaybe<SystemAggregateBoolExp>;
  type?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "version" */
export enum VersionConstraint {
  /** unique or primary key constraint on columns "id" */
  version_pkey = 'version_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type VersionDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type VersionDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type VersionDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "version" */
export type VersionIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  major?: InputMaybe<Scalars['Int']['input']>;
  minor?: InputMaybe<Scalars['Int']['input']>;
  patch?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "version" */
export type VersionInsertInput = {
  configs?: InputMaybe<ConfigArrRelInsertInput>;
  detectors?: InputMaybe<DetectorArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  major?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  minor?: InputMaybe<Scalars['Int']['input']>;
  patch?: InputMaybe<Scalars['Int']['input']>;
  systems?: InputMaybe<SystemArrRelInsertInput>;
  systemsBySoftwareVersionId?: InputMaybe<SystemArrRelInsertInput>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type VersionMaxFields = {
  __typename?: 'version_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  major?: Maybe<Scalars['Int']['output']>;
  minor?: Maybe<Scalars['Int']['output']>;
  patch?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type VersionMinFields = {
  __typename?: 'version_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  major?: Maybe<Scalars['Int']['output']>;
  minor?: Maybe<Scalars['Int']['output']>;
  patch?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "version" */
export type VersionMutationResponse = {
  __typename?: 'version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Version>;
};

/** input type for inserting object relation for remote table "version" */
export type VersionObjRelInsertInput = {
  data: VersionInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<VersionOnConflict>;
};

/** on_conflict condition type for table "version" */
export type VersionOnConflict = {
  constraint: VersionConstraint;
  update_columns?: Array<VersionUpdateColumn>;
  where?: InputMaybe<VersionBoolExp>;
};

/** Ordering options when selecting data from "version". */
export type VersionOrderBy = {
  configs_aggregate?: InputMaybe<ConfigAggregateOrderBy>;
  detectors_aggregate?: InputMaybe<DetectorAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  major?: InputMaybe<OrderBy>;
  metadata?: InputMaybe<OrderBy>;
  minor?: InputMaybe<OrderBy>;
  patch?: InputMaybe<OrderBy>;
  systemsBySoftwareVersionId_aggregate?: InputMaybe<SystemAggregateOrderBy>;
  systems_aggregate?: InputMaybe<SystemAggregateOrderBy>;
  type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: version */
export type VersionPkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type VersionPrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "version" */
export enum VersionSelectColumn {
  /** column name */
  id = 'id',
  /** column name */
  major = 'major',
  /** column name */
  metadata = 'metadata',
  /** column name */
  minor = 'minor',
  /** column name */
  patch = 'patch',
  /** column name */
  type = 'type'
}

/** input type for updating data in table "version" */
export type VersionSetInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  major?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  minor?: InputMaybe<Scalars['Int']['input']>;
  patch?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type VersionStddevFields = {
  __typename?: 'version_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  major?: Maybe<Scalars['Float']['output']>;
  minor?: Maybe<Scalars['Float']['output']>;
  patch?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type VersionStddevPopFields = {
  __typename?: 'version_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  major?: Maybe<Scalars['Float']['output']>;
  minor?: Maybe<Scalars['Float']['output']>;
  patch?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type VersionStddevSampFields = {
  __typename?: 'version_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  major?: Maybe<Scalars['Float']['output']>;
  minor?: Maybe<Scalars['Float']['output']>;
  patch?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "version" */
export type VersionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: VersionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type VersionStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  major?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  minor?: InputMaybe<Scalars['Int']['input']>;
  patch?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type VersionSumFields = {
  __typename?: 'version_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  major?: Maybe<Scalars['Int']['output']>;
  minor?: Maybe<Scalars['Int']['output']>;
  patch?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "version" */
export enum VersionUpdateColumn {
  /** column name */
  id = 'id',
  /** column name */
  major = 'major',
  /** column name */
  metadata = 'metadata',
  /** column name */
  minor = 'minor',
  /** column name */
  patch = 'patch',
  /** column name */
  type = 'type'
}

export type VersionUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<VersionAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<VersionDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<VersionDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<VersionDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<VersionIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<VersionPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VersionSetInput>;
  /** filter the rows which have to be updated */
  where: VersionBoolExp;
};

/** aggregate var_pop on columns */
export type VersionVarPopFields = {
  __typename?: 'version_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  major?: Maybe<Scalars['Float']['output']>;
  minor?: Maybe<Scalars['Float']['output']>;
  patch?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type VersionVarSampFields = {
  __typename?: 'version_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  major?: Maybe<Scalars['Float']['output']>;
  minor?: Maybe<Scalars['Float']['output']>;
  patch?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type VersionVarianceFields = {
  __typename?: 'version_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  major?: Maybe<Scalars['Float']['output']>;
  minor?: Maybe<Scalars['Float']['output']>;
  patch?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "vpd_view" */
export type VpdView = {
  __typename?: 'vpd_view';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "vpd_view" */
export type VpdViewAggregate = {
  __typename?: 'vpd_view_aggregate';
  aggregate?: Maybe<VpdViewAggregateFields>;
  nodes: Array<VpdView>;
};

/** aggregate fields of "vpd_view" */
export type VpdViewAggregateFields = {
  __typename?: 'vpd_view_aggregate_fields';
  avg?: Maybe<VpdViewAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<VpdViewMaxFields>;
  min?: Maybe<VpdViewMinFields>;
  stddev?: Maybe<VpdViewStddevFields>;
  stddev_pop?: Maybe<VpdViewStddevPopFields>;
  stddev_samp?: Maybe<VpdViewStddevSampFields>;
  sum?: Maybe<VpdViewSumFields>;
  var_pop?: Maybe<VpdViewVarPopFields>;
  var_samp?: Maybe<VpdViewVarSampFields>;
  variance?: Maybe<VpdViewVarianceFields>;
};


/** aggregate fields of "vpd_view" */
export type VpdViewAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<VpdViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type VpdViewAvgFields = {
  __typename?: 'vpd_view_avg_fields';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "vpd_view". All fields are combined with a logical 'AND'. */
export type VpdViewBoolExp = {
  _and?: InputMaybe<Array<VpdViewBoolExp>>;
  _not?: InputMaybe<VpdViewBoolExp>;
  _or?: InputMaybe<Array<VpdViewBoolExp>>;
  air_vpd?: InputMaybe<FloatComparisonExp>;
  leaf_vpd?: InputMaybe<FloatComparisonExp>;
  time?: InputMaybe<TimestamptzComparisonExp>;
  zone_id?: InputMaybe<IntComparisonExp>;
};

/** aggregate max on columns */
export type VpdViewMaxFields = {
  __typename?: 'vpd_view_max_fields';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type VpdViewMinFields = {
  __typename?: 'vpd_view_min_fields';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['timestamptz']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "vpd_view". */
export type VpdViewOrderBy = {
  air_vpd?: InputMaybe<OrderBy>;
  leaf_vpd?: InputMaybe<OrderBy>;
  time?: InputMaybe<OrderBy>;
  zone_id?: InputMaybe<OrderBy>;
};

/** select columns of table "vpd_view" */
export enum VpdViewSelectColumn {
  /** column name */
  air_vpd = 'air_vpd',
  /** column name */
  leaf_vpd = 'leaf_vpd',
  /** column name */
  time = 'time',
  /** column name */
  zone_id = 'zone_id'
}

/** aggregate stddev on columns */
export type VpdViewStddevFields = {
  __typename?: 'vpd_view_stddev_fields';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type VpdViewStddevPopFields = {
  __typename?: 'vpd_view_stddev_pop_fields';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type VpdViewStddevSampFields = {
  __typename?: 'vpd_view_stddev_samp_fields';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "vpd_view" */
export type VpdViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: VpdViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type VpdViewStreamCursorValueInput = {
  air_vpd?: InputMaybe<Scalars['Float']['input']>;
  leaf_vpd?: InputMaybe<Scalars['Float']['input']>;
  time?: InputMaybe<Scalars['timestamptz']['input']>;
  zone_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type VpdViewSumFields = {
  __typename?: 'vpd_view_sum_fields';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type VpdViewVarPopFields = {
  __typename?: 'vpd_view_var_pop_fields';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type VpdViewVarSampFields = {
  __typename?: 'vpd_view_var_samp_fields';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type VpdViewVarianceFields = {
  __typename?: 'vpd_view_variance_fields';
  air_vpd?: Maybe<Scalars['Float']['output']>;
  leaf_vpd?: Maybe<Scalars['Float']['output']>;
  zone_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "zone" */
export type Zone = {
  __typename?: 'zone';
  /** An array relationship */
  aggregate_heatmaps: Array<AggregateHeatmap>;
  /** An aggregate relationship */
  aggregate_heatmaps_aggregate: AggregateHeatmapAggregate;
  /** An array relationship */
  computed_measurements: Array<ComputedMeasurement>;
  /** An aggregate relationship */
  computed_measurements_aggregate: ComputedMeasurementAggregate;
  /** An array relationship */
  discussions: Array<Discussion>;
  /** An aggregate relationship */
  discussions_aggregate: DiscussionAggregate;
  /** An object relationship */
  enumeration: Enumeration;
  /** An array relationship */
  field_interventions: Array<FieldIntervention>;
  /** An aggregate relationship */
  field_interventions_aggregate: FieldInterventionAggregate;
  /** An array relationship */
  growth_cycles: Array<GrowthCycle>;
  /** An aggregate relationship */
  growth_cycles_aggregate: GrowthCycleAggregate;
  id: Scalars['Int']['output'];
  is_active: Scalars['Boolean']['output'];
  /** An object relationship */
  location: Location;
  location_id: Scalars['Int']['output'];
  /** An array relationship */
  measurement_runs: Array<MeasurementRun>;
  /** An aggregate relationship */
  measurement_runs_aggregate: MeasurementRunAggregate;
  /** An array relationship */
  measurements: Array<Measurement>;
  /** An aggregate relationship */
  measurements_aggregate: MeasurementAggregate;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  name_id: Scalars['Int']['output'];
  size_x?: Maybe<Scalars['Float']['output']>;
  size_y?: Maybe<Scalars['Float']['output']>;
  size_z?: Maybe<Scalars['Float']['output']>;
  /** An array relationship */
  systems: Array<System>;
  /** An aggregate relationship */
  systems_aggregate: SystemAggregate;
  uid: Scalars['String']['output'];
  /** An array relationship */
  user_organization_roles: Array<UserOrganizationRole>;
  /** An aggregate relationship */
  user_organization_roles_aggregate: UserOrganizationRoleAggregate;
};


/** columns and relationships of "zone" */
export type ZoneAggregateHeatmapsArgs = {
  distinct_on?: InputMaybe<Array<AggregateHeatmapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AggregateHeatmapOrderBy>>;
  where?: InputMaybe<AggregateHeatmapBoolExp>;
};


/** columns and relationships of "zone" */
export type ZoneAggregateHeatmapsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AggregateHeatmapSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AggregateHeatmapOrderBy>>;
  where?: InputMaybe<AggregateHeatmapBoolExp>;
};


/** columns and relationships of "zone" */
export type ZoneComputedMeasurementsArgs = {
  distinct_on?: InputMaybe<Array<ComputedMeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputedMeasurementOrderBy>>;
  where?: InputMaybe<ComputedMeasurementBoolExp>;
};


/** columns and relationships of "zone" */
export type ZoneComputedMeasurementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ComputedMeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComputedMeasurementOrderBy>>;
  where?: InputMaybe<ComputedMeasurementBoolExp>;
};


/** columns and relationships of "zone" */
export type ZoneDiscussionsArgs = {
  distinct_on?: InputMaybe<Array<DiscussionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DiscussionOrderBy>>;
  where?: InputMaybe<DiscussionBoolExp>;
};


/** columns and relationships of "zone" */
export type ZoneDiscussionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DiscussionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DiscussionOrderBy>>;
  where?: InputMaybe<DiscussionBoolExp>;
};


/** columns and relationships of "zone" */
export type ZoneFieldInterventionsArgs = {
  distinct_on?: InputMaybe<Array<FieldInterventionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FieldInterventionOrderBy>>;
  where?: InputMaybe<FieldInterventionBoolExp>;
};


/** columns and relationships of "zone" */
export type ZoneFieldInterventionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<FieldInterventionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FieldInterventionOrderBy>>;
  where?: InputMaybe<FieldInterventionBoolExp>;
};


/** columns and relationships of "zone" */
export type ZoneGrowthCyclesArgs = {
  distinct_on?: InputMaybe<Array<GrowthCycleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GrowthCycleOrderBy>>;
  where?: InputMaybe<GrowthCycleBoolExp>;
};


/** columns and relationships of "zone" */
export type ZoneGrowthCyclesAggregateArgs = {
  distinct_on?: InputMaybe<Array<GrowthCycleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<GrowthCycleOrderBy>>;
  where?: InputMaybe<GrowthCycleBoolExp>;
};


/** columns and relationships of "zone" */
export type ZoneMeasurementRunsArgs = {
  distinct_on?: InputMaybe<Array<MeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementRunOrderBy>>;
  where?: InputMaybe<MeasurementRunBoolExp>;
};


/** columns and relationships of "zone" */
export type ZoneMeasurementRunsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementRunSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementRunOrderBy>>;
  where?: InputMaybe<MeasurementRunBoolExp>;
};


/** columns and relationships of "zone" */
export type ZoneMeasurementsArgs = {
  distinct_on?: InputMaybe<Array<MeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementOrderBy>>;
  where?: InputMaybe<MeasurementBoolExp>;
};


/** columns and relationships of "zone" */
export type ZoneMeasurementsAggregateArgs = {
  distinct_on?: InputMaybe<Array<MeasurementSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MeasurementOrderBy>>;
  where?: InputMaybe<MeasurementBoolExp>;
};


/** columns and relationships of "zone" */
export type ZoneMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "zone" */
export type ZoneSystemsArgs = {
  distinct_on?: InputMaybe<Array<SystemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SystemOrderBy>>;
  where?: InputMaybe<SystemBoolExp>;
};


/** columns and relationships of "zone" */
export type ZoneSystemsAggregateArgs = {
  distinct_on?: InputMaybe<Array<SystemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<SystemOrderBy>>;
  where?: InputMaybe<SystemBoolExp>;
};


/** columns and relationships of "zone" */
export type ZoneUserOrganizationRolesArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizationRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrganizationRoleOrderBy>>;
  where?: InputMaybe<UserOrganizationRoleBoolExp>;
};


/** columns and relationships of "zone" */
export type ZoneUserOrganizationRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserOrganizationRoleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrganizationRoleOrderBy>>;
  where?: InputMaybe<UserOrganizationRoleBoolExp>;
};

/** aggregated selection of "zone" */
export type ZoneAggregate = {
  __typename?: 'zone_aggregate';
  aggregate?: Maybe<ZoneAggregateFields>;
  nodes: Array<Zone>;
};

export type ZoneAggregateBoolExp = {
  bool_and?: InputMaybe<ZoneAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<ZoneAggregateBoolExpBoolOr>;
  count?: InputMaybe<ZoneAggregateBoolExpCount>;
};

export type ZoneAggregateBoolExpBoolAnd = {
  arguments: ZoneSelectColumnZoneAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ZoneBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ZoneAggregateBoolExpBoolOr = {
  arguments: ZoneSelectColumnZoneAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ZoneBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ZoneAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ZoneSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ZoneBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "zone" */
export type ZoneAggregateFields = {
  __typename?: 'zone_aggregate_fields';
  avg?: Maybe<ZoneAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ZoneMaxFields>;
  min?: Maybe<ZoneMinFields>;
  stddev?: Maybe<ZoneStddevFields>;
  stddev_pop?: Maybe<ZoneStddevPopFields>;
  stddev_samp?: Maybe<ZoneStddevSampFields>;
  sum?: Maybe<ZoneSumFields>;
  var_pop?: Maybe<ZoneVarPopFields>;
  var_samp?: Maybe<ZoneVarSampFields>;
  variance?: Maybe<ZoneVarianceFields>;
};


/** aggregate fields of "zone" */
export type ZoneAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ZoneSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "zone" */
export type ZoneAggregateOrderBy = {
  avg?: InputMaybe<ZoneAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ZoneMaxOrderBy>;
  min?: InputMaybe<ZoneMinOrderBy>;
  stddev?: InputMaybe<ZoneStddevOrderBy>;
  stddev_pop?: InputMaybe<ZoneStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ZoneStddevSampOrderBy>;
  sum?: InputMaybe<ZoneSumOrderBy>;
  var_pop?: InputMaybe<ZoneVarPopOrderBy>;
  var_samp?: InputMaybe<ZoneVarSampOrderBy>;
  variance?: InputMaybe<ZoneVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ZoneAppendInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "zone" */
export type ZoneArrRelInsertInput = {
  data: Array<ZoneInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ZoneOnConflict>;
};

/** aggregate avg on columns */
export type ZoneAvgFields = {
  __typename?: 'zone_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
  size_x?: Maybe<Scalars['Float']['output']>;
  size_y?: Maybe<Scalars['Float']['output']>;
  size_z?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "zone" */
export type ZoneAvgOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  size_x?: InputMaybe<OrderBy>;
  size_y?: InputMaybe<OrderBy>;
  size_z?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "zone". All fields are combined with a logical 'AND'. */
export type ZoneBoolExp = {
  _and?: InputMaybe<Array<ZoneBoolExp>>;
  _not?: InputMaybe<ZoneBoolExp>;
  _or?: InputMaybe<Array<ZoneBoolExp>>;
  aggregate_heatmaps?: InputMaybe<AggregateHeatmapBoolExp>;
  aggregate_heatmaps_aggregate?: InputMaybe<AggregateHeatmapAggregateBoolExp>;
  computed_measurements?: InputMaybe<ComputedMeasurementBoolExp>;
  computed_measurements_aggregate?: InputMaybe<ComputedMeasurementAggregateBoolExp>;
  discussions?: InputMaybe<DiscussionBoolExp>;
  discussions_aggregate?: InputMaybe<DiscussionAggregateBoolExp>;
  enumeration?: InputMaybe<EnumerationBoolExp>;
  field_interventions?: InputMaybe<FieldInterventionBoolExp>;
  field_interventions_aggregate?: InputMaybe<FieldInterventionAggregateBoolExp>;
  growth_cycles?: InputMaybe<GrowthCycleBoolExp>;
  growth_cycles_aggregate?: InputMaybe<GrowthCycleAggregateBoolExp>;
  id?: InputMaybe<IntComparisonExp>;
  is_active?: InputMaybe<BooleanComparisonExp>;
  location?: InputMaybe<LocationBoolExp>;
  location_id?: InputMaybe<IntComparisonExp>;
  measurement_runs?: InputMaybe<MeasurementRunBoolExp>;
  measurement_runs_aggregate?: InputMaybe<MeasurementRunAggregateBoolExp>;
  measurements?: InputMaybe<MeasurementBoolExp>;
  measurements_aggregate?: InputMaybe<MeasurementAggregateBoolExp>;
  metadata?: InputMaybe<JsonbComparisonExp>;
  name_id?: InputMaybe<IntComparisonExp>;
  size_x?: InputMaybe<FloatComparisonExp>;
  size_y?: InputMaybe<FloatComparisonExp>;
  size_z?: InputMaybe<FloatComparisonExp>;
  systems?: InputMaybe<SystemBoolExp>;
  systems_aggregate?: InputMaybe<SystemAggregateBoolExp>;
  uid?: InputMaybe<StringComparisonExp>;
  user_organization_roles?: InputMaybe<UserOrganizationRoleBoolExp>;
  user_organization_roles_aggregate?: InputMaybe<UserOrganizationRoleAggregateBoolExp>;
};

/** unique or primary key constraints on table "zone" */
export enum ZoneConstraint {
  /** unique or primary key constraint on columns "id" */
  zone_pkey = 'zone_pkey',
  /** unique or primary key constraint on columns "uid" */
  zone_uid_key = 'zone_uid_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ZoneDeleteAtPathInput = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ZoneDeleteElemInput = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ZoneDeleteKeyInput = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "zone" */
export type ZoneIncInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  location_id?: InputMaybe<Scalars['Int']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
  size_x?: InputMaybe<Scalars['Float']['input']>;
  size_y?: InputMaybe<Scalars['Float']['input']>;
  size_z?: InputMaybe<Scalars['Float']['input']>;
};

/** input type for inserting data into table "zone" */
export type ZoneInsertInput = {
  aggregate_heatmaps?: InputMaybe<AggregateHeatmapArrRelInsertInput>;
  computed_measurements?: InputMaybe<ComputedMeasurementArrRelInsertInput>;
  discussions?: InputMaybe<DiscussionArrRelInsertInput>;
  enumeration?: InputMaybe<EnumerationObjRelInsertInput>;
  field_interventions?: InputMaybe<FieldInterventionArrRelInsertInput>;
  growth_cycles?: InputMaybe<GrowthCycleArrRelInsertInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<LocationObjRelInsertInput>;
  location_id?: InputMaybe<Scalars['Int']['input']>;
  measurement_runs?: InputMaybe<MeasurementRunArrRelInsertInput>;
  measurements?: InputMaybe<MeasurementArrRelInsertInput>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
  size_x?: InputMaybe<Scalars['Float']['input']>;
  size_y?: InputMaybe<Scalars['Float']['input']>;
  size_z?: InputMaybe<Scalars['Float']['input']>;
  systems?: InputMaybe<SystemArrRelInsertInput>;
  uid?: InputMaybe<Scalars['String']['input']>;
  user_organization_roles?: InputMaybe<UserOrganizationRoleArrRelInsertInput>;
};

/** aggregate max on columns */
export type ZoneMaxFields = {
  __typename?: 'zone_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  location_id?: Maybe<Scalars['Int']['output']>;
  name_id?: Maybe<Scalars['Int']['output']>;
  size_x?: Maybe<Scalars['Float']['output']>;
  size_y?: Maybe<Scalars['Float']['output']>;
  size_z?: Maybe<Scalars['Float']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "zone" */
export type ZoneMaxOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  size_x?: InputMaybe<OrderBy>;
  size_y?: InputMaybe<OrderBy>;
  size_z?: InputMaybe<OrderBy>;
  uid?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ZoneMinFields = {
  __typename?: 'zone_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  location_id?: Maybe<Scalars['Int']['output']>;
  name_id?: Maybe<Scalars['Int']['output']>;
  size_x?: Maybe<Scalars['Float']['output']>;
  size_y?: Maybe<Scalars['Float']['output']>;
  size_z?: Maybe<Scalars['Float']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "zone" */
export type ZoneMinOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  size_x?: InputMaybe<OrderBy>;
  size_y?: InputMaybe<OrderBy>;
  size_z?: InputMaybe<OrderBy>;
  uid?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "zone" */
export type ZoneMutationResponse = {
  __typename?: 'zone_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Zone>;
};

/** input type for inserting object relation for remote table "zone" */
export type ZoneObjRelInsertInput = {
  data: ZoneInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ZoneOnConflict>;
};

/** on_conflict condition type for table "zone" */
export type ZoneOnConflict = {
  constraint: ZoneConstraint;
  update_columns?: Array<ZoneUpdateColumn>;
  where?: InputMaybe<ZoneBoolExp>;
};

/** Ordering options when selecting data from "zone". */
export type ZoneOrderBy = {
  aggregate_heatmaps_aggregate?: InputMaybe<AggregateHeatmapAggregateOrderBy>;
  computed_measurements_aggregate?: InputMaybe<ComputedMeasurementAggregateOrderBy>;
  discussions_aggregate?: InputMaybe<DiscussionAggregateOrderBy>;
  enumeration?: InputMaybe<EnumerationOrderBy>;
  field_interventions_aggregate?: InputMaybe<FieldInterventionAggregateOrderBy>;
  growth_cycles_aggregate?: InputMaybe<GrowthCycleAggregateOrderBy>;
  id?: InputMaybe<OrderBy>;
  is_active?: InputMaybe<OrderBy>;
  location?: InputMaybe<LocationOrderBy>;
  location_id?: InputMaybe<OrderBy>;
  measurement_runs_aggregate?: InputMaybe<MeasurementRunAggregateOrderBy>;
  measurements_aggregate?: InputMaybe<MeasurementAggregateOrderBy>;
  metadata?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  size_x?: InputMaybe<OrderBy>;
  size_y?: InputMaybe<OrderBy>;
  size_z?: InputMaybe<OrderBy>;
  systems_aggregate?: InputMaybe<SystemAggregateOrderBy>;
  uid?: InputMaybe<OrderBy>;
  user_organization_roles_aggregate?: InputMaybe<UserOrganizationRoleAggregateOrderBy>;
};

/** primary key columns input for table: zone */
export type ZonePkColumnsInput = {
  id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ZonePrependInput = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "zone" */
export enum ZoneSelectColumn {
  /** column name */
  id = 'id',
  /** column name */
  is_active = 'is_active',
  /** column name */
  location_id = 'location_id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  name_id = 'name_id',
  /** column name */
  size_x = 'size_x',
  /** column name */
  size_y = 'size_y',
  /** column name */
  size_z = 'size_z',
  /** column name */
  uid = 'uid'
}

/** select "zone_aggregate_bool_exp_bool_and_arguments_columns" columns of table "zone" */
export enum ZoneSelectColumnZoneAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  is_active = 'is_active'
}

/** select "zone_aggregate_bool_exp_bool_or_arguments_columns" columns of table "zone" */
export enum ZoneSelectColumnZoneAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  is_active = 'is_active'
}

/** input type for updating data in table "zone" */
export type ZoneSetInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  location_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
  size_x?: InputMaybe<Scalars['Float']['input']>;
  size_y?: InputMaybe<Scalars['Float']['input']>;
  size_z?: InputMaybe<Scalars['Float']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type ZoneStddevFields = {
  __typename?: 'zone_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
  size_x?: Maybe<Scalars['Float']['output']>;
  size_y?: Maybe<Scalars['Float']['output']>;
  size_z?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "zone" */
export type ZoneStddevOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  size_x?: InputMaybe<OrderBy>;
  size_y?: InputMaybe<OrderBy>;
  size_z?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ZoneStddevPopFields = {
  __typename?: 'zone_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
  size_x?: Maybe<Scalars['Float']['output']>;
  size_y?: Maybe<Scalars['Float']['output']>;
  size_z?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "zone" */
export type ZoneStddevPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  size_x?: InputMaybe<OrderBy>;
  size_y?: InputMaybe<OrderBy>;
  size_z?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ZoneStddevSampFields = {
  __typename?: 'zone_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
  size_x?: Maybe<Scalars['Float']['output']>;
  size_y?: Maybe<Scalars['Float']['output']>;
  size_z?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "zone" */
export type ZoneStddevSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  size_x?: InputMaybe<OrderBy>;
  size_y?: InputMaybe<OrderBy>;
  size_z?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "zone" */
export type ZoneStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ZoneStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ZoneStreamCursorValueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  location_id?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  name_id?: InputMaybe<Scalars['Int']['input']>;
  size_x?: InputMaybe<Scalars['Float']['input']>;
  size_y?: InputMaybe<Scalars['Float']['input']>;
  size_z?: InputMaybe<Scalars['Float']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ZoneSumFields = {
  __typename?: 'zone_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
  location_id?: Maybe<Scalars['Int']['output']>;
  name_id?: Maybe<Scalars['Int']['output']>;
  size_x?: Maybe<Scalars['Float']['output']>;
  size_y?: Maybe<Scalars['Float']['output']>;
  size_z?: Maybe<Scalars['Float']['output']>;
};

/** order by sum() on columns of table "zone" */
export type ZoneSumOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  size_x?: InputMaybe<OrderBy>;
  size_y?: InputMaybe<OrderBy>;
  size_z?: InputMaybe<OrderBy>;
};

/** update columns of table "zone" */
export enum ZoneUpdateColumn {
  /** column name */
  id = 'id',
  /** column name */
  is_active = 'is_active',
  /** column name */
  location_id = 'location_id',
  /** column name */
  metadata = 'metadata',
  /** column name */
  name_id = 'name_id',
  /** column name */
  size_x = 'size_x',
  /** column name */
  size_y = 'size_y',
  /** column name */
  size_z = 'size_z',
  /** column name */
  uid = 'uid'
}

export type ZoneUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ZoneAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ZoneDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ZoneDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ZoneDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ZoneIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ZonePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ZoneSetInput>;
  /** filter the rows which have to be updated */
  where: ZoneBoolExp;
};

/** aggregate var_pop on columns */
export type ZoneVarPopFields = {
  __typename?: 'zone_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
  size_x?: Maybe<Scalars['Float']['output']>;
  size_y?: Maybe<Scalars['Float']['output']>;
  size_z?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "zone" */
export type ZoneVarPopOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  size_x?: InputMaybe<OrderBy>;
  size_y?: InputMaybe<OrderBy>;
  size_z?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ZoneVarSampFields = {
  __typename?: 'zone_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
  size_x?: Maybe<Scalars['Float']['output']>;
  size_y?: Maybe<Scalars['Float']['output']>;
  size_z?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "zone" */
export type ZoneVarSampOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  size_x?: InputMaybe<OrderBy>;
  size_y?: InputMaybe<OrderBy>;
  size_z?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ZoneVarianceFields = {
  __typename?: 'zone_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  name_id?: Maybe<Scalars['Float']['output']>;
  size_x?: Maybe<Scalars['Float']['output']>;
  size_y?: Maybe<Scalars['Float']['output']>;
  size_z?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "zone" */
export type ZoneVarianceOrderBy = {
  id?: InputMaybe<OrderBy>;
  location_id?: InputMaybe<OrderBy>;
  name_id?: InputMaybe<OrderBy>;
  size_x?: InputMaybe<OrderBy>;
  size_y?: InputMaybe<OrderBy>;
  size_z?: InputMaybe<OrderBy>;
};

export type GetComputedMeasurementQueryVariables = Exact<{
  computedMetricTypeCodes: Array<Scalars['String']['input']> | Scalars['String']['input'];
  startTime: Scalars['timestamptz']['input'];
  endTime: Scalars['timestamptz']['input'];
  zoneId: Scalars['Int']['input'];
}>;


export type GetComputedMeasurementQuery = { __typename?: 'query_root', computed_measurement: Array<{ __typename?: 'computed_measurement', time: any, data: any, zone_id: number }> };

export type GetDailyHealthLabelsByZoneIdQueryVariables = Exact<{
  zoneId: Scalars['Int']['input'];
  start?: InputMaybe<Scalars['timestamptz']['input']>;
  end?: InputMaybe<Scalars['timestamptz']['input']>;
}>;


export type GetDailyHealthLabelsByZoneIdQuery = { __typename?: 'query_root', computed_measurement: Array<{ __typename?: 'computed_measurement', data: any, zone_id: number, time: any, type?: { __typename?: 'enumeration', code: string } | null }> };

export type GetDailyHealthLabelsByZoneIdTypeCodeQueryVariables = Exact<{
  zoneId: Scalars['Int']['input'];
  start: Scalars['timestamptz']['input'];
  end: Scalars['timestamptz']['input'];
  typeCodes: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type GetDailyHealthLabelsByZoneIdTypeCodeQuery = { __typename?: 'query_root', computed_measurement: Array<{ __typename?: 'computed_measurement', data: any, zone_id: number, time: any, type?: { __typename?: 'enumeration', code: string } | null }> };

export type GetHealthDataByZoneIdQueryVariables = Exact<{
  zoneId: Scalars['Int']['input'];
  start?: InputMaybe<Scalars['timestamptz']['input']>;
  end?: InputMaybe<Scalars['timestamptz']['input']>;
}>;


export type GetHealthDataByZoneIdQuery = { __typename?: 'query_root', computed_measurement: Array<{ __typename?: 'computed_measurement', data: any, zone_id: number, time: any, type?: { __typename?: 'enumeration', code: string } | null }> };

export type GetLatestComputedMeasurementQueryVariables = Exact<{
  computedMetricTypeCode: Scalars['String']['input'];
  startTime: Scalars['timestamptz']['input'];
  endTime: Scalars['timestamptz']['input'];
  zoneId: Scalars['Int']['input'];
}>;


export type GetLatestComputedMeasurementQuery = { __typename?: 'query_root', computed_measurement: Array<{ __typename?: 'computed_measurement', time: any, data: any, zone_id: number, computed_metric_type: { __typename?: 'enumeration', code: string } }> };

export type DeleteCommentMutationVariables = Exact<{
  uid: Scalars['uuid']['input'];
}>;


export type DeleteCommentMutation = { __typename?: 'mutation_root', delete_comment_by_pk?: { __typename?: 'comment', uid: any } | null };

export type WithOnlyFirstDiscussionCommentFragment = { __typename?: 'discussion', comments: Array<{ __typename?: 'comment', uid: any, last_updated_at: any, first_comment_in_discussion: boolean, discussion_uid: any, created_at: any, content: any, author_organization_code: string, author_id: number, user: { __typename?: 'user', first_name?: string | null, last_name?: string | null }, user_comments: Array<{ __typename: 'user_comment', user_id: number, comment_uid: any, read_at?: any | null, tagged_at?: any | null }> }> };

export type WithAllDiscussionCommentsFragment = { __typename?: 'discussion', comments: Array<{ __typename?: 'comment', uid: any, last_updated_at: any, first_comment_in_discussion: boolean, discussion_uid: any, created_at: any, content: any, author_organization_code: string, author_id: number, user: { __typename?: 'user', first_name?: string | null, last_name?: string | null }, user_comments: Array<{ __typename: 'user_comment', user_id: number, comment_uid: any, read_at?: any | null, tagged_at?: any | null }> }> };

export type DiscussionFieldsWithOnlyFirstCommentFragment = { __typename?: 'discussion', uid: any, zone_uid: string, category: string, type: string, annotation_type: string, status: string, start_time: any, measurement_id?: any | null, heat_map_id?: number | null, heat_map_aggregate_uid?: any | null, last_updated_at: any, end_time: any, created_at: any, area?: any | null, author_organization_code: string, author_id: number, zone: { __typename?: 'zone', id: number }, user: { __typename?: 'user', first_name?: string | null, last_name?: string | null }, comments: Array<{ __typename?: 'comment', uid: any, last_updated_at: any, first_comment_in_discussion: boolean, discussion_uid: any, created_at: any, content: any, author_organization_code: string, author_id: number, user: { __typename?: 'user', first_name?: string | null, last_name?: string | null }, user_comments: Array<{ __typename: 'user_comment', user_id: number, comment_uid: any, read_at?: any | null, tagged_at?: any | null }> }> };

export type DiscussionFieldsWithAllCommentsFragment = { __typename?: 'discussion', uid: any, zone_uid: string, category: string, type: string, annotation_type: string, status: string, start_time: any, measurement_id?: any | null, heat_map_id?: number | null, heat_map_aggregate_uid?: any | null, last_updated_at: any, end_time: any, created_at: any, area?: any | null, author_organization_code: string, author_id: number, zone: { __typename?: 'zone', id: number }, user: { __typename?: 'user', first_name?: string | null, last_name?: string | null }, comments: Array<{ __typename?: 'comment', uid: any, last_updated_at: any, first_comment_in_discussion: boolean, discussion_uid: any, created_at: any, content: any, author_organization_code: string, author_id: number, user: { __typename?: 'user', first_name?: string | null, last_name?: string | null }, user_comments: Array<{ __typename: 'user_comment', user_id: number, comment_uid: any, read_at?: any | null, tagged_at?: any | null }> }> };

export type GetCommentsQueryVariables = Exact<{
  discussion_uid: Scalars['uuid']['input'];
  user_id: Scalars['Int']['input'];
}>;


export type GetCommentsQuery = { __typename?: 'query_root', comment: Array<{ __typename?: 'comment', uid: any, last_updated_at: any, first_comment_in_discussion: boolean, discussion_uid: any, created_at: any, content: any, author_organization_code: string, author_id: number, user: { __typename?: 'user', first_name?: string | null, last_name?: string | null }, user_comments: Array<{ __typename: 'user_comment', user_id: number, comment_uid: any, read_at?: any | null, tagged_at?: any | null }> }> };

export type GetDiscussionsQueryVariables = Exact<{
  where: DiscussionBoolExp;
  order_by?: InputMaybe<Array<DiscussionOrderBy> | DiscussionOrderBy>;
  user_id: Scalars['Int']['input'];
}>;


export type GetDiscussionsQuery = { __typename?: 'query_root', discussion: Array<{ __typename?: 'discussion', uid: any, zone_uid: string, category: string, type: string, annotation_type: string, status: string, start_time: any, measurement_id?: any | null, heat_map_id?: number | null, heat_map_aggregate_uid?: any | null, last_updated_at: any, end_time: any, created_at: any, area?: any | null, author_organization_code: string, author_id: number, zone: { __typename?: 'zone', id: number }, user: { __typename?: 'user', first_name?: string | null, last_name?: string | null }, comments: Array<{ __typename?: 'comment', uid: any, last_updated_at: any, first_comment_in_discussion: boolean, discussion_uid: any, created_at: any, content: any, author_organization_code: string, author_id: number, user: { __typename?: 'user', first_name?: string | null, last_name?: string | null }, user_comments: Array<{ __typename: 'user_comment', user_id: number, comment_uid: any, read_at?: any | null, tagged_at?: any | null }> }> }> };

export type GetDiscussionsWithCommentsAndUnreadCommentsCountQueryVariables = Exact<{
  user_id: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  zone_uids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  customBoolExp: DiscussionBoolExp;
}>;


export type GetDiscussionsWithCommentsAndUnreadCommentsCountQuery = { __typename?: 'query_root', unread_comments: { __typename?: 'comment_aggregate', aggregate?: { __typename?: 'comment_aggregate_fields', count: number } | null }, discussion: Array<{ __typename?: 'discussion', uid: any, zone_uid: string, category: string, type: string, annotation_type: string, status: string, start_time: any, measurement_id?: any | null, heat_map_id?: number | null, heat_map_aggregate_uid?: any | null, last_updated_at: any, end_time: any, created_at: any, area?: any | null, author_organization_code: string, author_id: number, zone: { __typename?: 'zone', id: number }, user: { __typename?: 'user', first_name?: string | null, last_name?: string | null }, comments: Array<{ __typename?: 'comment', uid: any, last_updated_at: any, first_comment_in_discussion: boolean, discussion_uid: any, created_at: any, content: any, author_organization_code: string, author_id: number, user: { __typename?: 'user', first_name?: string | null, last_name?: string | null }, user_comments: Array<{ __typename: 'user_comment', user_id: number, comment_uid: any, read_at?: any | null, tagged_at?: any | null }> }> }> };

export type GetDiscussionsWithUnreadCommentsQueryVariables = Exact<{
  user_id: Scalars['Int']['input'];
  zone_uids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  customBoolExp: DiscussionBoolExp;
}>;


export type GetDiscussionsWithUnreadCommentsQuery = { __typename?: 'query_root', discussion: Array<{ __typename?: 'discussion', uid: any, zone_uid: string, category: string, type: string, annotation_type: string, status: string, start_time: any, measurement_id?: any | null, heat_map_id?: number | null, heat_map_aggregate_uid?: any | null, last_updated_at: any, end_time: any, created_at: any, area?: any | null, author_organization_code: string, author_id: number, zone: { __typename?: 'zone', id: number }, user: { __typename?: 'user', first_name?: string | null, last_name?: string | null }, comments: Array<{ __typename?: 'comment', uid: any, last_updated_at: any, first_comment_in_discussion: boolean, discussion_uid: any, created_at: any, content: any, author_organization_code: string, author_id: number, user: { __typename?: 'user', first_name?: string | null, last_name?: string | null }, user_comments: Array<{ __typename: 'user_comment', user_id: number, comment_uid: any, read_at?: any | null, tagged_at?: any | null }> }> }> };

export type GetInsightsQueryVariables = Exact<{
  zone_uids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  statuses: Array<Scalars['String']['input']> | Scalars['String']['input'];
  end_time: Scalars['timestamptz']['input'];
  start_time: Scalars['timestamptz']['input'];
  start_time_aggregate: Scalars['timestamptz']['input'];
  user_id: Scalars['Int']['input'];
}>;


export type GetInsightsQuery = { __typename?: 'query_root', discussion: Array<{ __typename?: 'discussion', uid: any, zone_uid: string, category: string, type: string, annotation_type: string, status: string, start_time: any, measurement_id?: any | null, heat_map_id?: number | null, heat_map_aggregate_uid?: any | null, last_updated_at: any, end_time: any, created_at: any, area?: any | null, author_organization_code: string, author_id: number, measurement?: { __typename?: 'measurement', data: any } | null, zone: { __typename?: 'zone', id: number }, user: { __typename?: 'user', first_name?: string | null, last_name?: string | null }, comments: Array<{ __typename?: 'comment', uid: any, last_updated_at: any, first_comment_in_discussion: boolean, discussion_uid: any, created_at: any, content: any, author_organization_code: string, author_id: number, user: { __typename?: 'user', first_name?: string | null, last_name?: string | null }, user_comments: Array<{ __typename: 'user_comment', user_id: number, comment_uid: any, read_at?: any | null, tagged_at?: any | null }> }> }>, discussion_aggregate: { __typename?: 'discussion_aggregate', aggregate?: { __typename?: 'discussion_aggregate_fields', count: number } | null } };

export type InsertCommentMutationVariables = Exact<{
  uid?: InputMaybe<Scalars['uuid']['input']>;
  discussion_uid: Scalars['uuid']['input'];
  content: Scalars['jsonb']['input'];
  author_id: Scalars['Int']['input'];
  author_organization_code: Scalars['String']['input'];
  mentions: Array<UserCommentInsertInput> | UserCommentInsertInput;
}>;


export type InsertCommentMutation = { __typename?: 'mutation_root', insert_comment_one?: { __typename?: 'comment', uid: any, created_at: any, last_updated_at: any } | null, insert_user_comment?: { __typename?: 'user_comment_mutation_response', affected_rows: number } | null };

export type InsertDiscussionMutationVariables = Exact<{
  uid?: InputMaybe<Scalars['uuid']['input']>;
  zone_uid: Scalars['String']['input'];
  type: Scalars['String']['input'];
  annotation_type: Scalars['String']['input'];
  status: Scalars['String']['input'];
  category: Scalars['String']['input'];
  author_id: Scalars['Int']['input'];
  author_organization_code: Scalars['String']['input'];
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  measurement_id?: InputMaybe<Scalars['bigint']['input']>;
  heat_map_id?: InputMaybe<Scalars['Int']['input']>;
  heat_map_aggregate_uid?: InputMaybe<Scalars['uuid']['input']>;
  area?: InputMaybe<Scalars['jsonb']['input']>;
  comments: Array<CommentInsertInput> | CommentInsertInput;
  mentions: Array<UserCommentInsertInput> | UserCommentInsertInput;
}>;


export type InsertDiscussionMutation = { __typename?: 'mutation_root', insert_discussion_one?: { __typename?: 'discussion', uid: any, created_at: any, last_updated_at: any } | null, insert_user_comment?: { __typename?: 'user_comment_mutation_response', affected_rows: number } | null };

export type MarkCommentAsReadMutationVariables = Exact<{
  discussionUid: Scalars['uuid']['input'];
  commentUid: Scalars['uuid']['input'];
  userId: Scalars['Int']['input'];
  readAt: Scalars['timestamptz']['input'];
}>;


export type MarkCommentAsReadMutation = { __typename?: 'mutation_root', insert_user_comment_one?: { __typename: 'user_comment', comment_uid: any, user_id: number, read_at?: any | null, tagged_at?: any | null } | null };

export type MarkCommentsAsReadMutationVariables = Exact<{
  comments: Array<UserCommentInsertInput> | UserCommentInsertInput;
}>;


export type MarkCommentsAsReadMutation = { __typename?: 'mutation_root', insert_user_comment?: { __typename?: 'user_comment_mutation_response', returning: Array<{ __typename?: 'user_comment', discussion_uid: any, comment_uid: any, user_id: number, read_at?: any | null, tagged_at?: any | null }> } | null };

export type UpdateCommentMutationVariables = Exact<{
  comment_uid: Scalars['uuid']['input'];
  content: Scalars['jsonb']['input'];
  mentions: Array<UserCommentInsertInput> | UserCommentInsertInput;
  mentionsUserIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type UpdateCommentMutation = { __typename?: 'mutation_root', update_comment?: { __typename?: 'comment_mutation_response', affected_rows: number } | null, update_user_comment?: { __typename?: 'user_comment_mutation_response', affected_rows: number } | null, insert_user_comment?: { __typename?: 'user_comment_mutation_response', affected_rows: number } | null };

export type UpdateDiscussionMutationVariables = Exact<{
  discussion_uid: Scalars['uuid']['input'];
  category: Scalars['String']['input'];
  status: Scalars['String']['input'];
  start_time?: InputMaybe<Scalars['timestamptz']['input']>;
  end_time?: InputMaybe<Scalars['timestamptz']['input']>;
  comment_uid: Scalars['uuid']['input'];
  comment_content: Scalars['jsonb']['input'];
  mentions: Array<UserCommentInsertInput> | UserCommentInsertInput;
  mentionsUserIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type UpdateDiscussionMutation = { __typename?: 'mutation_root', update_discussion?: { __typename?: 'discussion_mutation_response', affected_rows: number } | null, update_comment?: { __typename?: 'comment_mutation_response', affected_rows: number } | null, update_user_comment?: { __typename?: 'user_comment_mutation_response', affected_rows: number } | null, insert_user_comment?: { __typename?: 'user_comment_mutation_response', affected_rows: number } | null };

export type UpdateDiscussionStatusMutationVariables = Exact<{
  uids: Array<Scalars['uuid']['input']> | Scalars['uuid']['input'];
  status: Scalars['String']['input'];
}>;


export type UpdateDiscussionStatusMutation = { __typename?: 'mutation_root', update_discussion?: { __typename?: 'discussion_mutation_response', affected_rows: number } | null };

export type GetEnumerationByCodeAndTypeQueryVariables = Exact<{
  code?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetEnumerationByCodeAndTypeQuery = { __typename?: 'query_root', enumeration: Array<{ __typename?: 'enumeration', code: string, description: string, id: number, metadata?: any | null }> };

export type GetEnumerationsByTypeQueryVariables = Exact<{
  types?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type GetEnumerationsByTypeQuery = { __typename?: 'query_root', enumeration: Array<{ __typename?: 'enumeration', id: number, code: string, description: string, type: string, metadata?: any | null }> };

export type GetMeasurementsOnGridByRunIdAndSensorTypeIdQueryVariables = Exact<{
  run_id: Scalars['Int']['input'];
  sensor_type_id: Scalars['Int']['input'];
}>;


export type GetMeasurementsOnGridByRunIdAndSensorTypeIdQuery = { __typename?: 'query_root', get_measurements_on_grid: Array<{ __typename?: 'get_measurements_on_grid_format_table', measurement_run_id?: any | null, grid_shape_x?: number | null, grid_shape_y?: number | null, grid_measurements?: any | null }> };

export type GetGrowthCycleByDatesQueryVariables = Exact<{
  zone_id: Scalars['Int']['input'];
  start: Scalars['timestamptz']['input'];
  end: Scalars['timestamptz']['input'];
}>;


export type GetGrowthCycleByDatesQuery = { __typename?: 'query_root', growth_cycle: Array<{ __typename?: 'growth_cycle', id: number, start_time: any, zone_id: number, end_time: any, is_active: boolean, metadata?: any | null, environment_recipe?: { __typename?: 'environment_recipe', id: number, name: string, is_active: boolean, location_id?: number | null, organization_id?: number | null, last_updated: any, environment_recipe_entrys: Array<{ __typename?: 'environment_recipe_entry', id: number, day: number, type_id: number, lights_on: boolean, set_point?: any | null, recipe_id: number, ideal_lower?: any | null, ideal_upper?: any | null, critical_lower?: any | null, critical_upper?: any | null, enumeration: { __typename?: 'enumeration', code: string } }>, growth_cycles_aggregate: { __typename?: 'growth_cycle_aggregate', aggregate?: { __typename?: 'growth_cycle_aggregate_fields', count: number } | null } } | null, light_cycle_recipe?: { __typename?: 'light_cycle_recipe', id: number, name: string, is_active: boolean, location_id?: number | null, organization_id?: number | null, last_updated: any, light_cycle_recipe_entrys: Array<{ __typename?: 'light_cycle_recipe_entry', id: number, day: number, recipe_id: number, lights_on_time?: any | null, lights_on_duration_minutes?: any | null }>, growth_cycles_aggregate: { __typename?: 'growth_cycle_aggregate', aggregate?: { __typename?: 'growth_cycle_aggregate_fields', count: number } | null } } | null }> };

export type GetGrowthCyclesWithRecipesByZoneIdQueryVariables = Exact<{
  zone_id: Scalars['Int']['input'];
}>;


export type GetGrowthCyclesWithRecipesByZoneIdQuery = { __typename?: 'query_root', growth_cycle: Array<{ __typename?: 'growth_cycle', id: number, start_time: any, zone_id: number, end_time: any, is_active: boolean, metadata?: any | null, environment_recipe?: { __typename?: 'environment_recipe', id: number, name: string, is_active: boolean, location_id?: number | null, organization_id?: number | null, last_updated: any, environment_recipe_entrys: Array<{ __typename?: 'environment_recipe_entry', id: number, day: number, type_id: number, lights_on: boolean, set_point?: any | null, recipe_id: number, ideal_lower?: any | null, ideal_upper?: any | null, critical_lower?: any | null, critical_upper?: any | null, enumeration: { __typename?: 'enumeration', code: string } }>, growth_cycles_aggregate: { __typename?: 'growth_cycle_aggregate', aggregate?: { __typename?: 'growth_cycle_aggregate_fields', count: number } | null } } | null, light_cycle_recipe?: { __typename?: 'light_cycle_recipe', id: number, name: string, is_active: boolean, location_id?: number | null, organization_id?: number | null, last_updated: any, light_cycle_recipe_entrys: Array<{ __typename?: 'light_cycle_recipe_entry', id: number, day: number, recipe_id: number, lights_on_time?: any | null, lights_on_duration_minutes?: any | null }>, growth_cycles_aggregate: { __typename?: 'growth_cycle_aggregate', aggregate?: { __typename?: 'growth_cycle_aggregate_fields', count: number } | null } } | null }> };

export type GetRecipesByLocationIdQueryVariables = Exact<{
  location_id: Scalars['Int']['input'];
}>;


export type GetRecipesByLocationIdQuery = { __typename?: 'query_root', environment_recipe: Array<{ __typename?: 'environment_recipe', id: number, name: string, is_active: boolean, location_id?: number | null, organization_id?: number | null, last_updated: any, environment_recipe_entrys: Array<{ __typename?: 'environment_recipe_entry', id: number, day: number, type_id: number, lights_on: boolean, set_point?: any | null, recipe_id: number, ideal_lower?: any | null, ideal_upper?: any | null, critical_lower?: any | null, critical_upper?: any | null, enumeration: { __typename?: 'enumeration', code: string } }>, growth_cycles_aggregate: { __typename?: 'growth_cycle_aggregate', aggregate?: { __typename?: 'growth_cycle_aggregate_fields', count: number } | null } }>, light_cycle_recipe: Array<{ __typename?: 'light_cycle_recipe', id: number, name: string, is_active: boolean, location_id?: number | null, organization_id?: number | null, last_updated: any, light_cycle_recipe_entrys: Array<{ __typename?: 'light_cycle_recipe_entry', id: number, day: number, recipe_id: number, lights_on_time?: any | null, lights_on_duration_minutes?: any | null }>, growth_cycles_aggregate: { __typename?: 'growth_cycle_aggregate', aggregate?: { __typename?: 'growth_cycle_aggregate_fields', count: number } | null } }> };

export type GrowthCycleFieldsFragment = { __typename?: 'growth_cycle', id: number, start_time: any, zone_id: number, end_time: any, is_active: boolean, metadata?: any | null, environment_recipe?: { __typename?: 'environment_recipe', id: number, name: string, is_active: boolean, location_id?: number | null, organization_id?: number | null, last_updated: any, environment_recipe_entrys: Array<{ __typename?: 'environment_recipe_entry', id: number, day: number, type_id: number, lights_on: boolean, set_point?: any | null, recipe_id: number, ideal_lower?: any | null, ideal_upper?: any | null, critical_lower?: any | null, critical_upper?: any | null, enumeration: { __typename?: 'enumeration', code: string } }>, growth_cycles_aggregate: { __typename?: 'growth_cycle_aggregate', aggregate?: { __typename?: 'growth_cycle_aggregate_fields', count: number } | null } } | null, light_cycle_recipe?: { __typename?: 'light_cycle_recipe', id: number, name: string, is_active: boolean, location_id?: number | null, organization_id?: number | null, last_updated: any, light_cycle_recipe_entrys: Array<{ __typename?: 'light_cycle_recipe_entry', id: number, day: number, recipe_id: number, lights_on_time?: any | null, lights_on_duration_minutes?: any | null }>, growth_cycles_aggregate: { __typename?: 'growth_cycle_aggregate', aggregate?: { __typename?: 'growth_cycle_aggregate_fields', count: number } | null } } | null };

export type InsertEnvironmentRecipeMutationVariables = Exact<{
  name: Scalars['String']['input'];
  location_id: Scalars['Int']['input'];
  organization_id: Scalars['Int']['input'];
  entries: EnvironmentRecipeEntryArrRelInsertInput;
  is_active: Scalars['Boolean']['input'];
}>;


export type InsertEnvironmentRecipeMutation = { __typename?: 'mutation_root', insert_environment_recipe?: { __typename?: 'environment_recipe_mutation_response', returning: Array<{ __typename?: 'environment_recipe', id: number }> } | null };

export type InsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutationVariables = Exact<{
  zone_id: Scalars['Int']['input'];
  start_time: Scalars['timestamptz']['input'];
  end_time: Scalars['timestamptz']['input'];
  metadata: Scalars['jsonb']['input'];
  location_id: Scalars['Int']['input'];
  organization_id: Scalars['Int']['input'];
  env_recipe_name: Scalars['String']['input'];
  env_recipe_entries: EnvironmentRecipeEntryArrRelInsertInput;
  lc_recipe_id: Scalars['Int']['input'];
  lc_recipe_name: Scalars['String']['input'];
  lc_recipe_entries: Array<LightCycleRecipeEntryInsertInput> | LightCycleRecipeEntryInsertInput;
}>;


export type InsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutation = { __typename?: 'mutation_root', insert_growth_cycle_one?: { __typename?: 'growth_cycle', id: number } | null, update_light_cycle_recipe?: { __typename?: 'light_cycle_recipe_mutation_response', affected_rows: number } | null, delete_light_cycle_recipe_entry?: { __typename?: 'light_cycle_recipe_entry_mutation_response', affected_rows: number } | null, insert_light_cycle_recipe_entry?: { __typename?: 'light_cycle_recipe_entry_mutation_response', affected_rows: number } | null };

export type InsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutationVariables = Exact<{
  zone_id: Scalars['Int']['input'];
  start_time: Scalars['timestamptz']['input'];
  end_time: Scalars['timestamptz']['input'];
  metadata: Scalars['jsonb']['input'];
  location_id: Scalars['Int']['input'];
  organization_id: Scalars['Int']['input'];
  env_recipe_id: Scalars['Int']['input'];
  env_recipe_name: Scalars['String']['input'];
  env_recipe_entries: Array<EnvironmentRecipeEntryInsertInput> | EnvironmentRecipeEntryInsertInput;
  lc_recipe_name: Scalars['String']['input'];
  lc_recipe_entries: LightCycleRecipeEntryArrRelInsertInput;
}>;


export type InsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutation = { __typename?: 'mutation_root', insert_growth_cycle_one?: { __typename?: 'growth_cycle', id: number } | null, update_environment_recipe?: { __typename?: 'environment_recipe_mutation_response', affected_rows: number } | null, delete_environment_recipe_entry?: { __typename?: 'environment_recipe_entry_mutation_response', affected_rows: number } | null, insert_environment_recipe_entry?: { __typename?: 'environment_recipe_entry_mutation_response', affected_rows: number } | null };

export type InsertGrowthCycleAndUpdateRecipesMutationVariables = Exact<{
  zone_id: Scalars['Int']['input'];
  start_time: Scalars['timestamptz']['input'];
  end_time: Scalars['timestamptz']['input'];
  metadata: Scalars['jsonb']['input'];
  env_recipe_id: Scalars['Int']['input'];
  env_recipe_name: Scalars['String']['input'];
  env_recipe_entries: Array<EnvironmentRecipeEntryInsertInput> | EnvironmentRecipeEntryInsertInput;
  lc_recipe_id: Scalars['Int']['input'];
  lc_recipe_name: Scalars['String']['input'];
  lc_recipe_entries: Array<LightCycleRecipeEntryInsertInput> | LightCycleRecipeEntryInsertInput;
}>;


export type InsertGrowthCycleAndUpdateRecipesMutation = { __typename?: 'mutation_root', insert_growth_cycle_one?: { __typename?: 'growth_cycle', id: number } | null, update_environment_recipe?: { __typename?: 'environment_recipe_mutation_response', affected_rows: number } | null, delete_environment_recipe_entry?: { __typename?: 'environment_recipe_entry_mutation_response', affected_rows: number } | null, insert_environment_recipe_entry?: { __typename?: 'environment_recipe_entry_mutation_response', affected_rows: number } | null, update_light_cycle_recipe?: { __typename?: 'light_cycle_recipe_mutation_response', affected_rows: number } | null, delete_light_cycle_recipe_entry?: { __typename?: 'light_cycle_recipe_entry_mutation_response', affected_rows: number } | null, insert_light_cycle_recipe_entry?: { __typename?: 'light_cycle_recipe_entry_mutation_response', affected_rows: number } | null };

export type InsertGrowthCycleWithRecipesMutationVariables = Exact<{
  zone_id: Scalars['Int']['input'];
  start_time: Scalars['timestamptz']['input'];
  end_time: Scalars['timestamptz']['input'];
  metadata: Scalars['jsonb']['input'];
  location_id: Scalars['Int']['input'];
  organization_id: Scalars['Int']['input'];
  env_recipe_name: Scalars['String']['input'];
  env_recipe_entries: EnvironmentRecipeEntryArrRelInsertInput;
  lc_recipe_name: Scalars['String']['input'];
  lc_recipe_entries: LightCycleRecipeEntryArrRelInsertInput;
}>;


export type InsertGrowthCycleWithRecipesMutation = { __typename?: 'mutation_root', insert_growth_cycle_one?: { __typename?: 'growth_cycle', id: number } | null };

export type InsertLightCycleRecipeMutationVariables = Exact<{
  name: Scalars['String']['input'];
  location_id: Scalars['Int']['input'];
  organization_id: Scalars['Int']['input'];
  entries: LightCycleRecipeEntryArrRelInsertInput;
  is_active: Scalars['Boolean']['input'];
}>;


export type InsertLightCycleRecipeMutation = { __typename?: 'mutation_root', insert_light_cycle_recipe?: { __typename?: 'light_cycle_recipe_mutation_response', returning: Array<{ __typename?: 'light_cycle_recipe', id: number }> } | null };

export type RemoveEnvironmentRecipeMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type RemoveEnvironmentRecipeMutation = { __typename?: 'mutation_root', update_environment_recipe?: { __typename?: 'environment_recipe_mutation_response', affected_rows: number } | null };

export type RemoveGrowthCycleWithRecipesMutationVariables = Exact<{
  growth_cycle_id: Scalars['Int']['input'];
}>;


export type RemoveGrowthCycleWithRecipesMutation = { __typename?: 'mutation_root', update_growth_cycle?: { __typename?: 'growth_cycle_mutation_response', affected_rows: number } | null };

export type RemoveLightCycleRecipeMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type RemoveLightCycleRecipeMutation = { __typename?: 'mutation_root', update_light_cycle_recipe?: { __typename?: 'light_cycle_recipe_mutation_response', affected_rows: number } | null };

export type UpdateEnvironmentRecipeMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  entries: Array<EnvironmentRecipeEntryInsertInput> | EnvironmentRecipeEntryInsertInput;
}>;


export type UpdateEnvironmentRecipeMutation = { __typename?: 'mutation_root', update_environment_recipe?: { __typename?: 'environment_recipe_mutation_response', affected_rows: number } | null, delete_environment_recipe_entry?: { __typename?: 'environment_recipe_entry_mutation_response', affected_rows: number } | null, insert_environment_recipe_entry?: { __typename?: 'environment_recipe_entry_mutation_response', affected_rows: number } | null };

export type UpdateGrowthCycleMetadataMutationVariables = Exact<{
  growth_cycle_id: Scalars['Int']['input'];
  metadata: Scalars['jsonb']['input'];
}>;


export type UpdateGrowthCycleMetadataMutation = { __typename?: 'mutation_root', update_growth_cycle?: { __typename?: 'growth_cycle_mutation_response', affected_rows: number } | null };

export type UpdateGrowthCycleWithRecipesMutationVariables = Exact<{
  growth_cycle_id: Scalars['Int']['input'];
  start_time: Scalars['timestamptz']['input'];
  end_time: Scalars['timestamptz']['input'];
  metadata: Scalars['jsonb']['input'];
  env_recipe_id: Scalars['Int']['input'];
  env_recipe_name: Scalars['String']['input'];
  env_recipe_entries: Array<EnvironmentRecipeEntryInsertInput> | EnvironmentRecipeEntryInsertInput;
  lc_recipe_id: Scalars['Int']['input'];
  lc_recipe_name: Scalars['String']['input'];
  lc_recipe_entries: Array<LightCycleRecipeEntryInsertInput> | LightCycleRecipeEntryInsertInput;
}>;


export type UpdateGrowthCycleWithRecipesMutation = { __typename?: 'mutation_root', update_growth_cycle?: { __typename?: 'growth_cycle_mutation_response', affected_rows: number } | null, update_environment_recipe?: { __typename?: 'environment_recipe_mutation_response', affected_rows: number } | null, delete_environment_recipe_entry?: { __typename?: 'environment_recipe_entry_mutation_response', affected_rows: number } | null, insert_environment_recipe_entry?: { __typename?: 'environment_recipe_entry_mutation_response', affected_rows: number } | null, update_light_cycle_recipe?: { __typename?: 'light_cycle_recipe_mutation_response', affected_rows: number } | null, delete_light_cycle_recipe_entry?: { __typename?: 'light_cycle_recipe_entry_mutation_response', affected_rows: number } | null, insert_light_cycle_recipe_entry?: { __typename?: 'light_cycle_recipe_entry_mutation_response', affected_rows: number } | null };

export type UpdateLightCycleRecipeMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  entries: Array<LightCycleRecipeEntryInsertInput> | LightCycleRecipeEntryInsertInput;
}>;


export type UpdateLightCycleRecipeMutation = { __typename?: 'mutation_root', update_light_cycle_recipe?: { __typename?: 'light_cycle_recipe_mutation_response', affected_rows: number } | null, delete_light_cycle_recipe_entry?: { __typename?: 'light_cycle_recipe_entry_mutation_response', affected_rows: number } | null, insert_light_cycle_recipe_entry?: { __typename?: 'light_cycle_recipe_entry_mutation_response', affected_rows: number } | null };

export type GetHeatMapsQueryVariables = Exact<{
  start: Scalars['timestamptz']['input'];
  end: Scalars['timestamptz']['input'];
  zone_id: Scalars['Int']['input'];
  zone_uid: Scalars['String']['input'];
  type: Scalars['String']['input'];
  enumeration: Scalars['String']['input'];
  statisticsPath?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetHeatMapsQuery = { __typename?: 'query_root', heat_map: Array<{ __typename?: 'heat_map', id: number, measurement_run: { __typename?: 'measurement_run', start_time: any, end_time?: any | null, id: number, metadata?: any | null } }>, aggregate_heatmap: Array<{ __typename?: 'aggregate_heatmap', start_time: any, end_time: any, uid: any }> };

export type GetHeatMapByIdQueryVariables = Exact<{
  uid?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  statisticsPath?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetHeatMapByIdQuery = { __typename?: 'query_root', heat_map: Array<{ __typename?: 'heat_map', data: any, measurement_run: { __typename?: 'measurement_run', metadata?: any | null } }>, aggregate_heatmap: Array<{ __typename?: 'aggregate_heatmap', data?: any | null }> };

export type GetLabelCountsByMeasurementIdQueryVariables = Exact<{
  measurementId: Scalars['bigint']['input'];
}>;


export type GetLabelCountsByMeasurementIdQuery = { __typename?: 'query_root', label_counts_by_measurement: Array<{ __typename?: 'label_counts_by_measurement', measurement_run_id?: number | null, measurement_id?: any | null, label_name_id?: number | null, label_counts?: any | null, label_ids?: any | null }> };

export type GetLabelCountsByMeasurementRunIdQueryVariables = Exact<{
  measurementRunId: Scalars['Int']['input'];
}>;


export type GetLabelCountsByMeasurementRunIdQuery = { __typename?: 'query_root', label_counts_by_measurement: Array<{ __typename?: 'label_counts_by_measurement', measurement_run_id?: number | null, measurement_id?: any | null, label_name_id?: number | null, label_counts?: any | null, label_ids?: any | null }> };

export type GetLabelsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type GetLabelsByIdsQuery = { __typename?: 'query_root', label: Array<{ __typename?: 'label', id: number, active: boolean, metadata?: any | null, human_source_id?: number | null, lambda_source_id?: number | null, measurement?: { __typename?: 'measurement', id: any } | null, measurement_run?: { __typename?: 'measurement_run', id: number } | null, enumeration: { __typename?: 'enumeration', code: string, description: string, id: number, type: string } }> };

export type GetLocationByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetLocationByIdQuery = { __typename?: 'query_root', location: Array<{ __typename?: 'location', id: number, name_id: number, metadata?: any | null, address_id: number, time_zone_name: string, organization_id: number, enumeration: { __typename?: 'enumeration', code: string, description: string }, organization: { __typename?: 'organization', enumeration: { __typename?: 'enumeration', code: string, description: string } } }> };

export type UpdateLocationMetadataMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  metadata: Scalars['jsonb']['input'];
}>;


export type UpdateLocationMetadataMutation = { __typename?: 'mutation_root', update_location?: { __typename?: 'location_mutation_response', affected_rows: number } | null };

export type GetLimitedZoneMeasurementsQueryVariables = Exact<{
  zone_id: Scalars['Int']['input'];
  start?: InputMaybe<Scalars['timestamptz']['input']>;
  end?: InputMaybe<Scalars['timestamptz']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetLimitedZoneMeasurementsQuery = { __typename?: 'query_root', measurement_view: Array<{ __typename?: 'measurement_view', zone_id?: number | null, measurement_type?: string | null, data?: string | null, time?: any | null }> };

export type GetMeasurementsByIdsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['bigint']['input']> | Scalars['bigint']['input']>;
}>;


export type GetMeasurementsByIdsQuery = { __typename?: 'query_root', measurement: Array<{ __typename?: 'measurement', id: any, type_id: number, pose_id: number, data: any, time: any, metadata?: any | null, image_annotation_trackings_aggregate: { __typename?: 'image_annotation_tracking_aggregate', aggregate?: { __typename?: 'image_annotation_tracking_aggregate_fields', count: number } | null }, measurement_run?: { __typename?: 'measurement_run', id: number, metadata?: any | null } | null }> };

export type GetMeasurementsByRunIdsAndPositionQueryVariables = Exact<{
  measurementRunIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  xIndex: Scalars['Int']['input'];
  yIndex: Scalars['Int']['input'];
  sensorTypeId: Scalars['Int']['input'];
}>;


export type GetMeasurementsByRunIdsAndPositionQuery = { __typename?: 'query_root', measurements_by_grid_index: Array<{ __typename?: 'measurements_by_grid_index', measurement_id?: any | null }> };

export type GetUnlimitedZoneMeasurementsQueryVariables = Exact<{
  zone_id: Scalars['Int']['input'];
  measurement_types: Array<Scalars['String']['input']> | Scalars['String']['input'];
  start?: InputMaybe<Scalars['timestamptz']['input']>;
  end?: InputMaybe<Scalars['timestamptz']['input']>;
}>;


export type GetUnlimitedZoneMeasurementsQuery = { __typename?: 'query_root', measurement_view: Array<{ __typename?: 'measurement_view', zone_id?: number | null, measurement_type?: string | null, data?: string | null, time?: any | null }> };

export type UpdateMeasurementMetadataByIdMutationVariables = Exact<{
  metadata: Scalars['jsonb']['input'];
  id: Scalars['bigint']['input'];
}>;


export type UpdateMeasurementMetadataByIdMutation = { __typename?: 'mutation_root', update_measurement?: { __typename?: 'measurement_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'measurement', id: any, metadata?: any | null }> } | null };

export type GetImageFeedMeasurementRunsQueryVariables = Exact<{
  zone_id: Scalars['Int']['input'];
  start?: InputMaybe<Scalars['timestamptz']['input']>;
  end?: InputMaybe<Scalars['timestamptz']['input']>;
}>;


export type GetImageFeedMeasurementRunsQuery = { __typename?: 'query_root', measurement_run: Array<{ __typename?: 'measurement_run', start_time: any, end_time?: any | null, id: number, annotation_status_id?: number | null, metadata?: any | null, grid_id?: number | null, grid?: { __typename?: 'grid', shape_x: number, shape_y: number } | null }> };

export type GetMeasurementRunsBetweenDatesByZoneQueryVariables = Exact<{
  zone_id: Scalars['Int']['input'];
  start: Scalars['timestamptz']['input'];
  end: Scalars['timestamptz']['input'];
}>;


export type GetMeasurementRunsBetweenDatesByZoneQuery = { __typename?: 'query_root', measurement_run: Array<{ __typename?: 'measurement_run', start_time: any, end_time?: any | null, id: number, metadata?: any | null }> };

export type RegisterUserToOrganizationWithRoleMutationVariables = Exact<{
  userId: Scalars['Int']['input'];
  organizationId: Scalars['Int']['input'];
  roleId: Scalars['Int']['input'];
}>;


export type RegisterUserToOrganizationWithRoleMutation = { __typename?: 'mutation_root', insert_user_organization_role?: { __typename?: 'user_organization_role_mutation_response', returning: Array<{ __typename?: 'user_organization_role', user_id: number, role_id: number, organization_id?: number | null }> } | null };

export type GetPermissionsByRoleIdsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type GetPermissionsByRoleIdsQuery = { __typename?: 'query_root', role_permission: Array<{ __typename?: 'role_permission', role_id: number, permission_id: number, enumeration: { __typename?: 'enumeration', code: string, metadata?: any | null } }> };

export type GetSystemByZoneUidQueryVariables = Exact<{
  zone_uid?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSystemByZoneUidQuery = { __typename?: 'query_root', system: Array<{ __typename?: 'system', id: number, system_status: string, system_status_created_at: any, system_status_updated_at: any }> };

export type CreateUserMutationVariables = Exact<{
  active: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateUserMutation = { __typename?: 'mutation_root', insert_user?: { __typename?: 'user_mutation_response', returning: Array<{ __typename?: 'user', id: number, active: boolean, email: string, first_name?: string | null, last_name?: string | null, password: string, metadata?: any | null }> } | null };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'mutation_root', delete_user_by_pk?: { __typename?: 'user', id: number, email: string } | null };

export type DeleteUserOrganizationRoleMutationVariables = Exact<{
  userId: Scalars['Int']['input'];
  organizationId: Scalars['Int']['input'];
  roleId: Scalars['Int']['input'];
}>;


export type DeleteUserOrganizationRoleMutation = { __typename?: 'mutation_root', delete_user_organization_role?: { __typename?: 'user_organization_role_mutation_response', returning: Array<{ __typename?: 'user_organization_role', role_id: number, user_id: number, organization_id?: number | null }> } | null };

export type GetDiscussionByUidQueryVariables = Exact<{
  discussionUid: Scalars['uuid']['input'];
}>;


export type GetDiscussionByUidQuery = { __typename?: 'query_root', discussion: Array<{ __typename?: 'discussion', uid: any, zone_uid: string }> };

export type GetNonKioskUsersWithZoneViewPermissionQueryVariables = Exact<{
  zoneUid: Scalars['String']['input'];
  customAndCondition: UserBoolExp;
}>;


export type GetNonKioskUsersWithZoneViewPermissionQuery = { __typename?: 'query_root', user: Array<{ __typename?: 'user', id: number, email: string, first_name?: string | null, last_name?: string | null }> };

export type GetUserByEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type GetUserByEmailQuery = { __typename?: 'query_root', user: Array<{ __typename?: 'user', id: number, active: boolean, email: string, first_name?: string | null, last_name?: string | null, metadata?: any | null, phone_number?: string | null, user_labels: Array<{ __typename?: 'user_label', id: number, modified_time?: any | null, metadata?: any | null, classification: { __typename?: 'classification', metadata?: any | null } }>, user_organization_roles: Array<{ __typename?: 'user_organization_role', role_id: number, user_id: number, organization_id?: number | null, location_id?: number | null, zone_id?: number | null, role: { __typename?: 'enumeration', code: string, description: string, id: number }, organization?: { __typename?: 'organization', enumeration: { __typename?: 'enumeration', code: string, description: string, id: number } } | null, location?: { __typename?: 'location', organization_id: number, enumeration: { __typename?: 'enumeration', description: string }, organization: { __typename?: 'organization', enumeration: { __typename?: 'enumeration', code: string, description: string, id: number } } } | null, zone?: { __typename?: 'zone', uid: string, enumeration: { __typename?: 'enumeration', description: string }, location: { __typename?: 'location', id: number, organization_id: number, organization: { __typename?: 'organization', enumeration: { __typename?: 'enumeration', code: string, description: string, id: number } } } } | null }> }> };

export type GetUsersByOrganizationQueryVariables = Exact<{
  organizationId: Scalars['Int']['input'];
}>;


export type GetUsersByOrganizationQuery = { __typename?: 'query_root', user: Array<{ __typename?: 'user', id: number, active: boolean, email: string, first_name?: string | null, last_name?: string | null, metadata?: any | null, phone_number?: string | null, user_labels: Array<{ __typename?: 'user_label', id: number, modified_time?: any | null, metadata?: any | null, classification: { __typename?: 'classification', metadata?: any | null } }>, user_organization_roles: Array<{ __typename?: 'user_organization_role', organization_id?: number | null, role_id: number, user_id: number, location_id?: number | null, zone_id?: number | null, role: { __typename?: 'enumeration', code: string, description: string, id: number }, location?: { __typename?: 'location', organization_id: number, enumeration: { __typename?: 'enumeration', description: string }, organization: { __typename?: 'organization', enumeration: { __typename?: 'enumeration', code: string, description: string, id: number } } } | null, zone?: { __typename?: 'zone', uid: string, enumeration: { __typename?: 'enumeration', description: string }, location: { __typename?: 'location', id: number, organization_id: number, organization: { __typename?: 'organization', enumeration: { __typename?: 'enumeration', code: string, description: string, id: number } } } } | null, organization?: { __typename?: 'organization', enumeration: { __typename?: 'enumeration', code: string, description: string, id: number } } | null }> }> };

export type RegisterUserOrganizationRoleMutationVariables = Exact<{
  userId: Scalars['Int']['input'];
  organizationId: Scalars['Int']['input'];
  roleId: Scalars['Int']['input'];
}>;


export type RegisterUserOrganizationRoleMutation = { __typename?: 'mutation_root', insert_user_organization_role?: { __typename?: 'user_organization_role_mutation_response', returning: Array<{ __typename?: 'user_organization_role', role_id: number, user_id: number, organization_id?: number | null }> } | null };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  active: Scalars['Boolean']['input'];
  firstName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateUserMutation = { __typename?: 'mutation_root', update_user_by_pk?: { __typename?: 'user', id: number, active: boolean, email: string, first_name?: string | null, last_name?: string | null, metadata?: any | null, phone_number?: string | null } | null };

export type UpdateUserPasswordByEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type UpdateUserPasswordByEmailMutation = { __typename?: 'mutation_root', update_user?: { __typename?: 'user_mutation_response', returning: Array<{ __typename?: 'user', email: string, password: string }> } | null };

export type UpdateUserMetadataMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  metadata: Scalars['jsonb']['input'];
}>;


export type UpdateUserMetadataMutation = { __typename?: 'mutation_root', update_user_by_pk?: { __typename?: 'user', metadata?: any | null } | null };

export type GetZonesByOrganizationCodeQueryVariables = Exact<{
  code: Scalars['String']['input'];
  hideInactiveZones: Scalars['Boolean']['input'];
}>;


export type GetZonesByOrganizationCodeQuery = { __typename?: 'query_root', zone: Array<{ __typename?: 'zone', id: number, uid: string, location_id: number, metadata?: any | null, name_id: number, size_x?: number | null, size_y?: number | null, size_z?: number | null, is_active: boolean, enumeration: { __typename?: 'enumeration', code: string, description: string }, location: { __typename?: 'location', time_zone_name: string, enumeration: { __typename?: 'enumeration', description: string } } }> };

export const WithOnlyFirstDiscussionCommentFragmentDoc = gql`
    fragment WithOnlyFirstDiscussionComment on discussion {
  comments(where: {first_comment_in_discussion: {_eq: true}}) {
    uid
    last_updated_at
    first_comment_in_discussion
    discussion_uid
    created_at
    content
    author_organization_code
    author_id
    user {
      first_name
      last_name
    }
    user_comments(where: {user_id: {_eq: $user_id}}) {
      user_id
      comment_uid
      read_at
      tagged_at
      __typename
    }
  }
}
    `;
export const DiscussionFieldsWithOnlyFirstCommentFragmentDoc = gql`
    fragment DiscussionFieldsWithOnlyFirstComment on discussion {
  uid
  zone_uid
  category
  type
  annotation_type
  status
  start_time
  measurement_id
  heat_map_id
  heat_map_aggregate_uid
  last_updated_at
  end_time
  created_at
  area
  author_organization_code
  author_id
  zone {
    id
  }
  user {
    first_name
    last_name
  }
  ...WithOnlyFirstDiscussionComment
}
    ${WithOnlyFirstDiscussionCommentFragmentDoc}`;
export const WithAllDiscussionCommentsFragmentDoc = gql`
    fragment WithAllDiscussionComments on discussion {
  comments {
    uid
    last_updated_at
    first_comment_in_discussion
    discussion_uid
    created_at
    content
    author_organization_code
    author_id
    user {
      first_name
      last_name
    }
    user_comments(where: {user_id: {_eq: $user_id}}) {
      user_id
      comment_uid
      read_at
      tagged_at
      __typename
    }
  }
}
    `;
export const DiscussionFieldsWithAllCommentsFragmentDoc = gql`
    fragment DiscussionFieldsWithAllComments on discussion {
  uid
  zone_uid
  category
  type
  annotation_type
  status
  start_time
  measurement_id
  heat_map_id
  heat_map_aggregate_uid
  last_updated_at
  end_time
  created_at
  area
  author_organization_code
  author_id
  zone {
    id
  }
  user {
    first_name
    last_name
  }
  ...WithAllDiscussionComments
}
    ${WithAllDiscussionCommentsFragmentDoc}`;
export const GrowthCycleFieldsFragmentDoc = gql`
    fragment GrowthCycleFields on growth_cycle {
  id
  start_time
  zone_id
  end_time
  is_active
  metadata
  environment_recipe {
    id
    name
    is_active
    location_id
    organization_id
    last_updated
    environment_recipe_entrys {
      id
      day
      type_id
      lights_on
      set_point
      recipe_id
      ideal_lower
      ideal_upper
      critical_lower
      critical_upper
      enumeration {
        code
      }
    }
    growth_cycles_aggregate {
      aggregate {
        count
      }
    }
  }
  light_cycle_recipe {
    id
    name
    is_active
    location_id
    organization_id
    last_updated
    light_cycle_recipe_entrys {
      id
      day
      recipe_id
      lights_on_time
      lights_on_duration_minutes
    }
    growth_cycles_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;
export const GetComputedMeasurementDocument = gql`
    query getComputedMeasurement($computedMetricTypeCodes: [String!]!, $startTime: timestamptz!, $endTime: timestamptz!, $zoneId: Int!) {
  computed_measurement(
    where: {computed_metric_type: {type: {_eq: "COMPUTED_METRIC"}, code: {_in: $computedMetricTypeCodes}}, time: {_lte: $endTime, _gte: $startTime}, zone_id: {_eq: $zoneId}}
    order_by: {time: asc}
  ) {
    time
    data
    zone_id
  }
}
    `;

/**
 * __useGetComputedMeasurementQuery__
 *
 * To run a query within a React component, call `useGetComputedMeasurementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetComputedMeasurementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetComputedMeasurementQuery({
 *   variables: {
 *      computedMetricTypeCodes: // value for 'computedMetricTypeCodes'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      zoneId: // value for 'zoneId'
 *   },
 * });
 */
export function useGetComputedMeasurementQuery(baseOptions: Apollo.QueryHookOptions<GetComputedMeasurementQuery, GetComputedMeasurementQueryVariables> & ({ variables: GetComputedMeasurementQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetComputedMeasurementQuery, GetComputedMeasurementQueryVariables>(GetComputedMeasurementDocument, options);
      }
export function useGetComputedMeasurementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetComputedMeasurementQuery, GetComputedMeasurementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetComputedMeasurementQuery, GetComputedMeasurementQueryVariables>(GetComputedMeasurementDocument, options);
        }
export function useGetComputedMeasurementSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetComputedMeasurementQuery, GetComputedMeasurementQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetComputedMeasurementQuery, GetComputedMeasurementQueryVariables>(GetComputedMeasurementDocument, options);
        }
export type GetComputedMeasurementQueryHookResult = ReturnType<typeof useGetComputedMeasurementQuery>;
export type GetComputedMeasurementLazyQueryHookResult = ReturnType<typeof useGetComputedMeasurementLazyQuery>;
export type GetComputedMeasurementSuspenseQueryHookResult = ReturnType<typeof useGetComputedMeasurementSuspenseQuery>;
export type GetComputedMeasurementQueryResult = Apollo.QueryResult<GetComputedMeasurementQuery, GetComputedMeasurementQueryVariables>;
export const GetDailyHealthLabelsByZoneIdDocument = gql`
    query getDailyHealthLabelsByZoneId($zoneId: Int!, $start: timestamptz = "", $end: timestamptz = "") {
  computed_measurement(
    order_by: {time: asc}
    where: {computed_metric_type: {code: {_eq: "DAILY_HEALTH_LABEL_COUNT"}}, type: {code: {_neq: "OBJECT:BUD"}}, zone_id: {_eq: $zoneId}, time: {_gte: $start, _lte: $end}}
  ) {
    data
    zone_id
    time
    type {
      code
    }
  }
}
    `;

/**
 * __useGetDailyHealthLabelsByZoneIdQuery__
 *
 * To run a query within a React component, call `useGetDailyHealthLabelsByZoneIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyHealthLabelsByZoneIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyHealthLabelsByZoneIdQuery({
 *   variables: {
 *      zoneId: // value for 'zoneId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetDailyHealthLabelsByZoneIdQuery(baseOptions: Apollo.QueryHookOptions<GetDailyHealthLabelsByZoneIdQuery, GetDailyHealthLabelsByZoneIdQueryVariables> & ({ variables: GetDailyHealthLabelsByZoneIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDailyHealthLabelsByZoneIdQuery, GetDailyHealthLabelsByZoneIdQueryVariables>(GetDailyHealthLabelsByZoneIdDocument, options);
      }
export function useGetDailyHealthLabelsByZoneIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDailyHealthLabelsByZoneIdQuery, GetDailyHealthLabelsByZoneIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDailyHealthLabelsByZoneIdQuery, GetDailyHealthLabelsByZoneIdQueryVariables>(GetDailyHealthLabelsByZoneIdDocument, options);
        }
export function useGetDailyHealthLabelsByZoneIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDailyHealthLabelsByZoneIdQuery, GetDailyHealthLabelsByZoneIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDailyHealthLabelsByZoneIdQuery, GetDailyHealthLabelsByZoneIdQueryVariables>(GetDailyHealthLabelsByZoneIdDocument, options);
        }
export type GetDailyHealthLabelsByZoneIdQueryHookResult = ReturnType<typeof useGetDailyHealthLabelsByZoneIdQuery>;
export type GetDailyHealthLabelsByZoneIdLazyQueryHookResult = ReturnType<typeof useGetDailyHealthLabelsByZoneIdLazyQuery>;
export type GetDailyHealthLabelsByZoneIdSuspenseQueryHookResult = ReturnType<typeof useGetDailyHealthLabelsByZoneIdSuspenseQuery>;
export type GetDailyHealthLabelsByZoneIdQueryResult = Apollo.QueryResult<GetDailyHealthLabelsByZoneIdQuery, GetDailyHealthLabelsByZoneIdQueryVariables>;
export const GetDailyHealthLabelsByZoneIdTypeCodeDocument = gql`
    query getDailyHealthLabelsByZoneIdTypeCode($zoneId: Int!, $start: timestamptz!, $end: timestamptz!, $typeCodes: [String!]!) {
  computed_measurement(
    order_by: {time: asc}
    where: {computed_metric_type: {code: {_eq: "DAILY_HEALTH_LABEL_COUNT"}}, type: {code: {_in: $typeCodes}}, zone_id: {_eq: $zoneId}, time: {_gte: $start, _lte: $end}}
  ) {
    data
    zone_id
    time
    type {
      code
    }
  }
}
    `;

/**
 * __useGetDailyHealthLabelsByZoneIdTypeCodeQuery__
 *
 * To run a query within a React component, call `useGetDailyHealthLabelsByZoneIdTypeCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyHealthLabelsByZoneIdTypeCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyHealthLabelsByZoneIdTypeCodeQuery({
 *   variables: {
 *      zoneId: // value for 'zoneId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      typeCodes: // value for 'typeCodes'
 *   },
 * });
 */
export function useGetDailyHealthLabelsByZoneIdTypeCodeQuery(baseOptions: Apollo.QueryHookOptions<GetDailyHealthLabelsByZoneIdTypeCodeQuery, GetDailyHealthLabelsByZoneIdTypeCodeQueryVariables> & ({ variables: GetDailyHealthLabelsByZoneIdTypeCodeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDailyHealthLabelsByZoneIdTypeCodeQuery, GetDailyHealthLabelsByZoneIdTypeCodeQueryVariables>(GetDailyHealthLabelsByZoneIdTypeCodeDocument, options);
      }
export function useGetDailyHealthLabelsByZoneIdTypeCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDailyHealthLabelsByZoneIdTypeCodeQuery, GetDailyHealthLabelsByZoneIdTypeCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDailyHealthLabelsByZoneIdTypeCodeQuery, GetDailyHealthLabelsByZoneIdTypeCodeQueryVariables>(GetDailyHealthLabelsByZoneIdTypeCodeDocument, options);
        }
export function useGetDailyHealthLabelsByZoneIdTypeCodeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDailyHealthLabelsByZoneIdTypeCodeQuery, GetDailyHealthLabelsByZoneIdTypeCodeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDailyHealthLabelsByZoneIdTypeCodeQuery, GetDailyHealthLabelsByZoneIdTypeCodeQueryVariables>(GetDailyHealthLabelsByZoneIdTypeCodeDocument, options);
        }
export type GetDailyHealthLabelsByZoneIdTypeCodeQueryHookResult = ReturnType<typeof useGetDailyHealthLabelsByZoneIdTypeCodeQuery>;
export type GetDailyHealthLabelsByZoneIdTypeCodeLazyQueryHookResult = ReturnType<typeof useGetDailyHealthLabelsByZoneIdTypeCodeLazyQuery>;
export type GetDailyHealthLabelsByZoneIdTypeCodeSuspenseQueryHookResult = ReturnType<typeof useGetDailyHealthLabelsByZoneIdTypeCodeSuspenseQuery>;
export type GetDailyHealthLabelsByZoneIdTypeCodeQueryResult = Apollo.QueryResult<GetDailyHealthLabelsByZoneIdTypeCodeQuery, GetDailyHealthLabelsByZoneIdTypeCodeQueryVariables>;
export const GetHealthDataByZoneIdDocument = gql`
    query getHealthDataByZoneId($zoneId: Int!, $start: timestamptz = "", $end: timestamptz = "") {
  computed_measurement(
    where: {computed_metric_type: {code: {_eq: "HEALTH_SCORE"}}, zone_id: {_eq: $zoneId}, time: {_gte: $start, _lte: $end}}
  ) {
    data
    zone_id
    time
    type {
      code
    }
  }
}
    `;

/**
 * __useGetHealthDataByZoneIdQuery__
 *
 * To run a query within a React component, call `useGetHealthDataByZoneIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHealthDataByZoneIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHealthDataByZoneIdQuery({
 *   variables: {
 *      zoneId: // value for 'zoneId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetHealthDataByZoneIdQuery(baseOptions: Apollo.QueryHookOptions<GetHealthDataByZoneIdQuery, GetHealthDataByZoneIdQueryVariables> & ({ variables: GetHealthDataByZoneIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHealthDataByZoneIdQuery, GetHealthDataByZoneIdQueryVariables>(GetHealthDataByZoneIdDocument, options);
      }
export function useGetHealthDataByZoneIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHealthDataByZoneIdQuery, GetHealthDataByZoneIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHealthDataByZoneIdQuery, GetHealthDataByZoneIdQueryVariables>(GetHealthDataByZoneIdDocument, options);
        }
export function useGetHealthDataByZoneIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetHealthDataByZoneIdQuery, GetHealthDataByZoneIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetHealthDataByZoneIdQuery, GetHealthDataByZoneIdQueryVariables>(GetHealthDataByZoneIdDocument, options);
        }
export type GetHealthDataByZoneIdQueryHookResult = ReturnType<typeof useGetHealthDataByZoneIdQuery>;
export type GetHealthDataByZoneIdLazyQueryHookResult = ReturnType<typeof useGetHealthDataByZoneIdLazyQuery>;
export type GetHealthDataByZoneIdSuspenseQueryHookResult = ReturnType<typeof useGetHealthDataByZoneIdSuspenseQuery>;
export type GetHealthDataByZoneIdQueryResult = Apollo.QueryResult<GetHealthDataByZoneIdQuery, GetHealthDataByZoneIdQueryVariables>;
export const GetLatestComputedMeasurementDocument = gql`
    query getLatestComputedMeasurement($computedMetricTypeCode: String!, $startTime: timestamptz!, $endTime: timestamptz!, $zoneId: Int!) {
  computed_measurement(
    where: {computed_metric_type: {type: {_eq: "COMPUTED_METRIC"}, code: {_eq: $computedMetricTypeCode}}, time: {_lte: $endTime, _gte: $startTime}, zone_id: {_eq: $zoneId}}
    limit: 1
    order_by: {time: desc}
  ) {
    time
    data
    computed_metric_type {
      code
    }
    zone_id
  }
}
    `;

/**
 * __useGetLatestComputedMeasurementQuery__
 *
 * To run a query within a React component, call `useGetLatestComputedMeasurementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestComputedMeasurementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestComputedMeasurementQuery({
 *   variables: {
 *      computedMetricTypeCode: // value for 'computedMetricTypeCode'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      zoneId: // value for 'zoneId'
 *   },
 * });
 */
export function useGetLatestComputedMeasurementQuery(baseOptions: Apollo.QueryHookOptions<GetLatestComputedMeasurementQuery, GetLatestComputedMeasurementQueryVariables> & ({ variables: GetLatestComputedMeasurementQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestComputedMeasurementQuery, GetLatestComputedMeasurementQueryVariables>(GetLatestComputedMeasurementDocument, options);
      }
export function useGetLatestComputedMeasurementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestComputedMeasurementQuery, GetLatestComputedMeasurementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestComputedMeasurementQuery, GetLatestComputedMeasurementQueryVariables>(GetLatestComputedMeasurementDocument, options);
        }
export function useGetLatestComputedMeasurementSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLatestComputedMeasurementQuery, GetLatestComputedMeasurementQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLatestComputedMeasurementQuery, GetLatestComputedMeasurementQueryVariables>(GetLatestComputedMeasurementDocument, options);
        }
export type GetLatestComputedMeasurementQueryHookResult = ReturnType<typeof useGetLatestComputedMeasurementQuery>;
export type GetLatestComputedMeasurementLazyQueryHookResult = ReturnType<typeof useGetLatestComputedMeasurementLazyQuery>;
export type GetLatestComputedMeasurementSuspenseQueryHookResult = ReturnType<typeof useGetLatestComputedMeasurementSuspenseQuery>;
export type GetLatestComputedMeasurementQueryResult = Apollo.QueryResult<GetLatestComputedMeasurementQuery, GetLatestComputedMeasurementQueryVariables>;
export const DeleteCommentDocument = gql`
    mutation deleteComment($uid: uuid!) {
  delete_comment_by_pk(uid: $uid) {
    uid
  }
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const GetCommentsDocument = gql`
    query getComments($discussion_uid: uuid!, $user_id: Int!) {
  comment(where: {discussion_uid: {_eq: $discussion_uid}}) {
    uid
    last_updated_at
    first_comment_in_discussion
    discussion_uid
    created_at
    content
    author_organization_code
    author_id
    user {
      first_name
      last_name
    }
    user_comments(where: {user_id: {_eq: $user_id}}) {
      user_id
      comment_uid
      read_at
      tagged_at
      __typename
    }
  }
}
    `;

/**
 * __useGetCommentsQuery__
 *
 * To run a query within a React component, call `useGetCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsQuery({
 *   variables: {
 *      discussion_uid: // value for 'discussion_uid'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetCommentsQuery(baseOptions: Apollo.QueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables> & ({ variables: GetCommentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options);
      }
export function useGetCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options);
        }
export function useGetCommentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options);
        }
export type GetCommentsQueryHookResult = ReturnType<typeof useGetCommentsQuery>;
export type GetCommentsLazyQueryHookResult = ReturnType<typeof useGetCommentsLazyQuery>;
export type GetCommentsSuspenseQueryHookResult = ReturnType<typeof useGetCommentsSuspenseQuery>;
export type GetCommentsQueryResult = Apollo.QueryResult<GetCommentsQuery, GetCommentsQueryVariables>;
export const GetDiscussionsDocument = gql`
    query getDiscussions($where: discussion_bool_exp!, $order_by: [discussion_order_by!] = {}, $user_id: Int!) {
  discussion(where: $where, order_by: $order_by) {
    ...DiscussionFieldsWithOnlyFirstComment
  }
}
    ${DiscussionFieldsWithOnlyFirstCommentFragmentDoc}`;

/**
 * __useGetDiscussionsQuery__
 *
 * To run a query within a React component, call `useGetDiscussionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscussionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscussionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetDiscussionsQuery(baseOptions: Apollo.QueryHookOptions<GetDiscussionsQuery, GetDiscussionsQueryVariables> & ({ variables: GetDiscussionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiscussionsQuery, GetDiscussionsQueryVariables>(GetDiscussionsDocument, options);
      }
export function useGetDiscussionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiscussionsQuery, GetDiscussionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiscussionsQuery, GetDiscussionsQueryVariables>(GetDiscussionsDocument, options);
        }
export function useGetDiscussionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDiscussionsQuery, GetDiscussionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDiscussionsQuery, GetDiscussionsQueryVariables>(GetDiscussionsDocument, options);
        }
export type GetDiscussionsQueryHookResult = ReturnType<typeof useGetDiscussionsQuery>;
export type GetDiscussionsLazyQueryHookResult = ReturnType<typeof useGetDiscussionsLazyQuery>;
export type GetDiscussionsSuspenseQueryHookResult = ReturnType<typeof useGetDiscussionsSuspenseQuery>;
export type GetDiscussionsQueryResult = Apollo.QueryResult<GetDiscussionsQuery, GetDiscussionsQueryVariables>;
export const GetDiscussionsWithCommentsAndUnreadCommentsCountDocument = gql`
    query getDiscussionsWithCommentsAndUnreadCommentsCount($user_id: Int!, $limit: Int!, $zone_uids: [String!]!, $customBoolExp: discussion_bool_exp!) {
  unread_comments: comment_aggregate(
    where: {author_id: {_neq: $user_id}, _not: {user_comments: {user_id: {_eq: $user_id}, read_at: {_is_null: false}}}, discussion: {zone_uid: {_in: $zone_uids}, _and: [$customBoolExp]}}
  ) {
    aggregate {
      count
    }
  }
  discussion(
    where: {comments: {author_id: {_neq: $user_id}}, zone_uid: {_in: $zone_uids}, _and: [$customBoolExp]}
    limit: $limit
    order_by: {comments_aggregate: {max: {last_updated_at: desc}}}
  ) {
    ...DiscussionFieldsWithAllComments
  }
}
    ${DiscussionFieldsWithAllCommentsFragmentDoc}`;

/**
 * __useGetDiscussionsWithCommentsAndUnreadCommentsCountQuery__
 *
 * To run a query within a React component, call `useGetDiscussionsWithCommentsAndUnreadCommentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscussionsWithCommentsAndUnreadCommentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscussionsWithCommentsAndUnreadCommentsCountQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      limit: // value for 'limit'
 *      zone_uids: // value for 'zone_uids'
 *      customBoolExp: // value for 'customBoolExp'
 *   },
 * });
 */
export function useGetDiscussionsWithCommentsAndUnreadCommentsCountQuery(baseOptions: Apollo.QueryHookOptions<GetDiscussionsWithCommentsAndUnreadCommentsCountQuery, GetDiscussionsWithCommentsAndUnreadCommentsCountQueryVariables> & ({ variables: GetDiscussionsWithCommentsAndUnreadCommentsCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiscussionsWithCommentsAndUnreadCommentsCountQuery, GetDiscussionsWithCommentsAndUnreadCommentsCountQueryVariables>(GetDiscussionsWithCommentsAndUnreadCommentsCountDocument, options);
      }
export function useGetDiscussionsWithCommentsAndUnreadCommentsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiscussionsWithCommentsAndUnreadCommentsCountQuery, GetDiscussionsWithCommentsAndUnreadCommentsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiscussionsWithCommentsAndUnreadCommentsCountQuery, GetDiscussionsWithCommentsAndUnreadCommentsCountQueryVariables>(GetDiscussionsWithCommentsAndUnreadCommentsCountDocument, options);
        }
export function useGetDiscussionsWithCommentsAndUnreadCommentsCountSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDiscussionsWithCommentsAndUnreadCommentsCountQuery, GetDiscussionsWithCommentsAndUnreadCommentsCountQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDiscussionsWithCommentsAndUnreadCommentsCountQuery, GetDiscussionsWithCommentsAndUnreadCommentsCountQueryVariables>(GetDiscussionsWithCommentsAndUnreadCommentsCountDocument, options);
        }
export type GetDiscussionsWithCommentsAndUnreadCommentsCountQueryHookResult = ReturnType<typeof useGetDiscussionsWithCommentsAndUnreadCommentsCountQuery>;
export type GetDiscussionsWithCommentsAndUnreadCommentsCountLazyQueryHookResult = ReturnType<typeof useGetDiscussionsWithCommentsAndUnreadCommentsCountLazyQuery>;
export type GetDiscussionsWithCommentsAndUnreadCommentsCountSuspenseQueryHookResult = ReturnType<typeof useGetDiscussionsWithCommentsAndUnreadCommentsCountSuspenseQuery>;
export type GetDiscussionsWithCommentsAndUnreadCommentsCountQueryResult = Apollo.QueryResult<GetDiscussionsWithCommentsAndUnreadCommentsCountQuery, GetDiscussionsWithCommentsAndUnreadCommentsCountQueryVariables>;
export const GetDiscussionsWithUnreadCommentsDocument = gql`
    query getDiscussionsWithUnreadComments($user_id: Int!, $zone_uids: [String!]!, $customBoolExp: discussion_bool_exp!) {
  discussion(
    where: {comments: {author_id: {_neq: $user_id}, _not: {user_comments: {user_id: {_eq: $user_id}, read_at: {_is_null: false}}}}, zone_uid: {_in: $zone_uids}, _and: [$customBoolExp]}
    order_by: {comments_aggregate: {max: {last_updated_at: desc}}}
  ) {
    ...DiscussionFieldsWithAllComments
  }
}
    ${DiscussionFieldsWithAllCommentsFragmentDoc}`;

/**
 * __useGetDiscussionsWithUnreadCommentsQuery__
 *
 * To run a query within a React component, call `useGetDiscussionsWithUnreadCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscussionsWithUnreadCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscussionsWithUnreadCommentsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      zone_uids: // value for 'zone_uids'
 *      customBoolExp: // value for 'customBoolExp'
 *   },
 * });
 */
export function useGetDiscussionsWithUnreadCommentsQuery(baseOptions: Apollo.QueryHookOptions<GetDiscussionsWithUnreadCommentsQuery, GetDiscussionsWithUnreadCommentsQueryVariables> & ({ variables: GetDiscussionsWithUnreadCommentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiscussionsWithUnreadCommentsQuery, GetDiscussionsWithUnreadCommentsQueryVariables>(GetDiscussionsWithUnreadCommentsDocument, options);
      }
export function useGetDiscussionsWithUnreadCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiscussionsWithUnreadCommentsQuery, GetDiscussionsWithUnreadCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiscussionsWithUnreadCommentsQuery, GetDiscussionsWithUnreadCommentsQueryVariables>(GetDiscussionsWithUnreadCommentsDocument, options);
        }
export function useGetDiscussionsWithUnreadCommentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDiscussionsWithUnreadCommentsQuery, GetDiscussionsWithUnreadCommentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDiscussionsWithUnreadCommentsQuery, GetDiscussionsWithUnreadCommentsQueryVariables>(GetDiscussionsWithUnreadCommentsDocument, options);
        }
export type GetDiscussionsWithUnreadCommentsQueryHookResult = ReturnType<typeof useGetDiscussionsWithUnreadCommentsQuery>;
export type GetDiscussionsWithUnreadCommentsLazyQueryHookResult = ReturnType<typeof useGetDiscussionsWithUnreadCommentsLazyQuery>;
export type GetDiscussionsWithUnreadCommentsSuspenseQueryHookResult = ReturnType<typeof useGetDiscussionsWithUnreadCommentsSuspenseQuery>;
export type GetDiscussionsWithUnreadCommentsQueryResult = Apollo.QueryResult<GetDiscussionsWithUnreadCommentsQuery, GetDiscussionsWithUnreadCommentsQueryVariables>;
export const GetInsightsDocument = gql`
    query getInsights($zone_uids: [String!]!, $statuses: [String!]!, $end_time: timestamptz!, $start_time: timestamptz!, $start_time_aggregate: timestamptz!, $user_id: Int!) {
  discussion(
    where: {type: {_eq: "insight"}, annotation_type: {_in: ["event", "single_image_annotation", "time_range_annotation", "heatmap_aggregate_annotation", "heatmap_annotation"]}, zone_uid: {_in: $zone_uids}, status: {_in: $statuses}, _or: [{_and: [{start_time: {_gte: $start_time}}, {start_time: {_lte: $end_time}}]}, {_and: [{end_time: {_gte: $start_time}}, {end_time: {_lte: $end_time}}]}]}
    order_by: {end_time: desc}
  ) {
    ...DiscussionFieldsWithOnlyFirstComment
    measurement {
      data
    }
  }
  discussion_aggregate(
    where: {type: {_eq: "insight"}, annotation_type: {_in: ["event", "single_image_annotation", "time_range_annotation", "heatmap_aggregate_annotation", "heatmap_annotation"]}, zone_uid: {_in: $zone_uids}, status: {_in: $statuses}, start_time: {_gte: $start_time_aggregate}}
  ) {
    aggregate {
      count
    }
  }
}
    ${DiscussionFieldsWithOnlyFirstCommentFragmentDoc}`;

/**
 * __useGetInsightsQuery__
 *
 * To run a query within a React component, call `useGetInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInsightsQuery({
 *   variables: {
 *      zone_uids: // value for 'zone_uids'
 *      statuses: // value for 'statuses'
 *      end_time: // value for 'end_time'
 *      start_time: // value for 'start_time'
 *      start_time_aggregate: // value for 'start_time_aggregate'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetInsightsQuery(baseOptions: Apollo.QueryHookOptions<GetInsightsQuery, GetInsightsQueryVariables> & ({ variables: GetInsightsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInsightsQuery, GetInsightsQueryVariables>(GetInsightsDocument, options);
      }
export function useGetInsightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInsightsQuery, GetInsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInsightsQuery, GetInsightsQueryVariables>(GetInsightsDocument, options);
        }
export function useGetInsightsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetInsightsQuery, GetInsightsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetInsightsQuery, GetInsightsQueryVariables>(GetInsightsDocument, options);
        }
export type GetInsightsQueryHookResult = ReturnType<typeof useGetInsightsQuery>;
export type GetInsightsLazyQueryHookResult = ReturnType<typeof useGetInsightsLazyQuery>;
export type GetInsightsSuspenseQueryHookResult = ReturnType<typeof useGetInsightsSuspenseQuery>;
export type GetInsightsQueryResult = Apollo.QueryResult<GetInsightsQuery, GetInsightsQueryVariables>;
export const InsertCommentDocument = gql`
    mutation insertComment($uid: uuid, $discussion_uid: uuid!, $content: jsonb!, $author_id: Int!, $author_organization_code: String!, $mentions: [user_comment_insert_input!]!) {
  insert_comment_one(
    object: {uid: $uid, discussion_uid: $discussion_uid, content: $content, author_id: $author_id, author_organization_code: $author_organization_code, first_comment_in_discussion: false}
  ) {
    uid
    created_at
    last_updated_at
  }
  insert_user_comment(
    objects: $mentions
    on_conflict: {constraint: uq_user_comment, update_columns: [tagged_at]}
  ) {
    affected_rows
  }
}
    `;
export type InsertCommentMutationFn = Apollo.MutationFunction<InsertCommentMutation, InsertCommentMutationVariables>;

/**
 * __useInsertCommentMutation__
 *
 * To run a mutation, you first call `useInsertCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCommentMutation, { data, loading, error }] = useInsertCommentMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      discussion_uid: // value for 'discussion_uid'
 *      content: // value for 'content'
 *      author_id: // value for 'author_id'
 *      author_organization_code: // value for 'author_organization_code'
 *      mentions: // value for 'mentions'
 *   },
 * });
 */
export function useInsertCommentMutation(baseOptions?: Apollo.MutationHookOptions<InsertCommentMutation, InsertCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertCommentMutation, InsertCommentMutationVariables>(InsertCommentDocument, options);
      }
export type InsertCommentMutationHookResult = ReturnType<typeof useInsertCommentMutation>;
export type InsertCommentMutationResult = Apollo.MutationResult<InsertCommentMutation>;
export type InsertCommentMutationOptions = Apollo.BaseMutationOptions<InsertCommentMutation, InsertCommentMutationVariables>;
export const InsertDiscussionDocument = gql`
    mutation insertDiscussion($uid: uuid, $zone_uid: String!, $type: String!, $annotation_type: String!, $status: String!, $category: String!, $author_id: Int!, $author_organization_code: String!, $start_time: timestamptz, $end_time: timestamptz, $measurement_id: bigint, $heat_map_id: Int, $heat_map_aggregate_uid: uuid, $area: jsonb, $comments: [comment_insert_input!]!, $mentions: [user_comment_insert_input!]!) {
  insert_discussion_one(
    object: {uid: $uid, zone_uid: $zone_uid, type: $type, annotation_type: $annotation_type, status: $status, category: $category, author_id: $author_id, author_organization_code: $author_organization_code, measurement_id: $measurement_id, heat_map_id: $heat_map_id, heat_map_aggregate_uid: $heat_map_aggregate_uid, start_time: $start_time, end_time: $end_time, area: $area, comments: {data: $comments}}
  ) {
    uid
    created_at
    last_updated_at
  }
  insert_user_comment(
    objects: $mentions
    on_conflict: {constraint: uq_user_comment, update_columns: [tagged_at]}
  ) {
    affected_rows
  }
}
    `;
export type InsertDiscussionMutationFn = Apollo.MutationFunction<InsertDiscussionMutation, InsertDiscussionMutationVariables>;

/**
 * __useInsertDiscussionMutation__
 *
 * To run a mutation, you first call `useInsertDiscussionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDiscussionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDiscussionMutation, { data, loading, error }] = useInsertDiscussionMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      zone_uid: // value for 'zone_uid'
 *      type: // value for 'type'
 *      annotation_type: // value for 'annotation_type'
 *      status: // value for 'status'
 *      category: // value for 'category'
 *      author_id: // value for 'author_id'
 *      author_organization_code: // value for 'author_organization_code'
 *      start_time: // value for 'start_time'
 *      end_time: // value for 'end_time'
 *      measurement_id: // value for 'measurement_id'
 *      heat_map_id: // value for 'heat_map_id'
 *      heat_map_aggregate_uid: // value for 'heat_map_aggregate_uid'
 *      area: // value for 'area'
 *      comments: // value for 'comments'
 *      mentions: // value for 'mentions'
 *   },
 * });
 */
export function useInsertDiscussionMutation(baseOptions?: Apollo.MutationHookOptions<InsertDiscussionMutation, InsertDiscussionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertDiscussionMutation, InsertDiscussionMutationVariables>(InsertDiscussionDocument, options);
      }
export type InsertDiscussionMutationHookResult = ReturnType<typeof useInsertDiscussionMutation>;
export type InsertDiscussionMutationResult = Apollo.MutationResult<InsertDiscussionMutation>;
export type InsertDiscussionMutationOptions = Apollo.BaseMutationOptions<InsertDiscussionMutation, InsertDiscussionMutationVariables>;
export const MarkCommentAsReadDocument = gql`
    mutation markCommentAsRead($discussionUid: uuid!, $commentUid: uuid!, $userId: Int!, $readAt: timestamptz!) {
  insert_user_comment_one(
    object: {discussion_uid: $discussionUid, comment_uid: $commentUid, user_id: $userId, read_at: $readAt}
    on_conflict: {constraint: uq_user_comment, update_columns: [read_at]}
  ) {
    comment_uid
    user_id
    read_at
    tagged_at
    __typename
  }
}
    `;
export type MarkCommentAsReadMutationFn = Apollo.MutationFunction<MarkCommentAsReadMutation, MarkCommentAsReadMutationVariables>;

/**
 * __useMarkCommentAsReadMutation__
 *
 * To run a mutation, you first call `useMarkCommentAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkCommentAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markCommentAsReadMutation, { data, loading, error }] = useMarkCommentAsReadMutation({
 *   variables: {
 *      discussionUid: // value for 'discussionUid'
 *      commentUid: // value for 'commentUid'
 *      userId: // value for 'userId'
 *      readAt: // value for 'readAt'
 *   },
 * });
 */
export function useMarkCommentAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkCommentAsReadMutation, MarkCommentAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkCommentAsReadMutation, MarkCommentAsReadMutationVariables>(MarkCommentAsReadDocument, options);
      }
export type MarkCommentAsReadMutationHookResult = ReturnType<typeof useMarkCommentAsReadMutation>;
export type MarkCommentAsReadMutationResult = Apollo.MutationResult<MarkCommentAsReadMutation>;
export type MarkCommentAsReadMutationOptions = Apollo.BaseMutationOptions<MarkCommentAsReadMutation, MarkCommentAsReadMutationVariables>;
export const MarkCommentsAsReadDocument = gql`
    mutation markCommentsAsRead($comments: [user_comment_insert_input!]!) {
  insert_user_comment(
    objects: $comments
    on_conflict: {constraint: uq_user_comment, update_columns: read_at}
  ) {
    returning {
      discussion_uid
      comment_uid
      user_id
      read_at
      tagged_at
      user_id
    }
  }
}
    `;
export type MarkCommentsAsReadMutationFn = Apollo.MutationFunction<MarkCommentsAsReadMutation, MarkCommentsAsReadMutationVariables>;

/**
 * __useMarkCommentsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkCommentsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkCommentsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markCommentsAsReadMutation, { data, loading, error }] = useMarkCommentsAsReadMutation({
 *   variables: {
 *      comments: // value for 'comments'
 *   },
 * });
 */
export function useMarkCommentsAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkCommentsAsReadMutation, MarkCommentsAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkCommentsAsReadMutation, MarkCommentsAsReadMutationVariables>(MarkCommentsAsReadDocument, options);
      }
export type MarkCommentsAsReadMutationHookResult = ReturnType<typeof useMarkCommentsAsReadMutation>;
export type MarkCommentsAsReadMutationResult = Apollo.MutationResult<MarkCommentsAsReadMutation>;
export type MarkCommentsAsReadMutationOptions = Apollo.BaseMutationOptions<MarkCommentsAsReadMutation, MarkCommentsAsReadMutationVariables>;
export const UpdateCommentDocument = gql`
    mutation updateComment($comment_uid: uuid!, $content: jsonb!, $mentions: [user_comment_insert_input!]!, $mentionsUserIds: [Int!]!) {
  update_comment(where: {uid: {_eq: $comment_uid}}, _set: {content: $content}) {
    affected_rows
  }
  update_user_comment(
    where: {_and: [{comment_uid: {_eq: $comment_uid}}, {user_id: {_nin: $mentionsUserIds}}, {tagged_at: {_is_null: false}}]}
    _set: {tagged_at: null}
  ) {
    affected_rows
  }
  insert_user_comment(
    objects: $mentions
    on_conflict: {constraint: uq_user_comment, update_columns: [tagged_at]}
  ) {
    affected_rows
  }
}
    `;
export type UpdateCommentMutationFn = Apollo.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      comment_uid: // value for 'comment_uid'
 *      content: // value for 'content'
 *      mentions: // value for 'mentions'
 *      mentionsUserIds: // value for 'mentionsUserIds'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(UpdateCommentDocument, options);
      }
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<UpdateCommentMutation, UpdateCommentMutationVariables>;
export const UpdateDiscussionDocument = gql`
    mutation updateDiscussion($discussion_uid: uuid!, $category: String!, $status: String!, $start_time: timestamptz, $end_time: timestamptz, $comment_uid: uuid!, $comment_content: jsonb!, $mentions: [user_comment_insert_input!]!, $mentionsUserIds: [Int!]!) {
  update_discussion(
    where: {uid: {_eq: $discussion_uid}}
    _set: {last_updated_at: now, category: $category, status: $status, start_time: $start_time, end_time: $end_time}
  ) {
    affected_rows
  }
  update_comment(
    where: {uid: {_eq: $comment_uid}}
    _set: {content: $comment_content, last_updated_at: now}
  ) {
    affected_rows
  }
  update_user_comment(
    where: {_and: [{comment_uid: {_eq: $comment_uid}}, {user_id: {_nin: $mentionsUserIds}}, {tagged_at: {_is_null: false}}]}
    _set: {tagged_at: null}
  ) {
    affected_rows
  }
  insert_user_comment(
    objects: $mentions
    on_conflict: {constraint: uq_user_comment, update_columns: [tagged_at]}
  ) {
    affected_rows
  }
}
    `;
export type UpdateDiscussionMutationFn = Apollo.MutationFunction<UpdateDiscussionMutation, UpdateDiscussionMutationVariables>;

/**
 * __useUpdateDiscussionMutation__
 *
 * To run a mutation, you first call `useUpdateDiscussionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscussionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiscussionMutation, { data, loading, error }] = useUpdateDiscussionMutation({
 *   variables: {
 *      discussion_uid: // value for 'discussion_uid'
 *      category: // value for 'category'
 *      status: // value for 'status'
 *      start_time: // value for 'start_time'
 *      end_time: // value for 'end_time'
 *      comment_uid: // value for 'comment_uid'
 *      comment_content: // value for 'comment_content'
 *      mentions: // value for 'mentions'
 *      mentionsUserIds: // value for 'mentionsUserIds'
 *   },
 * });
 */
export function useUpdateDiscussionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDiscussionMutation, UpdateDiscussionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDiscussionMutation, UpdateDiscussionMutationVariables>(UpdateDiscussionDocument, options);
      }
export type UpdateDiscussionMutationHookResult = ReturnType<typeof useUpdateDiscussionMutation>;
export type UpdateDiscussionMutationResult = Apollo.MutationResult<UpdateDiscussionMutation>;
export type UpdateDiscussionMutationOptions = Apollo.BaseMutationOptions<UpdateDiscussionMutation, UpdateDiscussionMutationVariables>;
export const UpdateDiscussionStatusDocument = gql`
    mutation updateDiscussionStatus($uids: [uuid!]!, $status: String!) {
  update_discussion(
    where: {uid: {_in: $uids}}
    _set: {last_updated_at: now, status: $status}
  ) {
    affected_rows
  }
}
    `;
export type UpdateDiscussionStatusMutationFn = Apollo.MutationFunction<UpdateDiscussionStatusMutation, UpdateDiscussionStatusMutationVariables>;

/**
 * __useUpdateDiscussionStatusMutation__
 *
 * To run a mutation, you first call `useUpdateDiscussionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscussionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiscussionStatusMutation, { data, loading, error }] = useUpdateDiscussionStatusMutation({
 *   variables: {
 *      uids: // value for 'uids'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateDiscussionStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDiscussionStatusMutation, UpdateDiscussionStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDiscussionStatusMutation, UpdateDiscussionStatusMutationVariables>(UpdateDiscussionStatusDocument, options);
      }
export type UpdateDiscussionStatusMutationHookResult = ReturnType<typeof useUpdateDiscussionStatusMutation>;
export type UpdateDiscussionStatusMutationResult = Apollo.MutationResult<UpdateDiscussionStatusMutation>;
export type UpdateDiscussionStatusMutationOptions = Apollo.BaseMutationOptions<UpdateDiscussionStatusMutation, UpdateDiscussionStatusMutationVariables>;
export const GetEnumerationByCodeAndTypeDocument = gql`
    query getEnumerationByCodeAndType($code: String = "", $type: String = "") {
  enumeration(where: {code: {_eq: $code}, type: {_eq: $type}}) {
    code
    description
    id
    metadata
  }
}
    `;

/**
 * __useGetEnumerationByCodeAndTypeQuery__
 *
 * To run a query within a React component, call `useGetEnumerationByCodeAndTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnumerationByCodeAndTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnumerationByCodeAndTypeQuery({
 *   variables: {
 *      code: // value for 'code'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetEnumerationByCodeAndTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetEnumerationByCodeAndTypeQuery, GetEnumerationByCodeAndTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEnumerationByCodeAndTypeQuery, GetEnumerationByCodeAndTypeQueryVariables>(GetEnumerationByCodeAndTypeDocument, options);
      }
export function useGetEnumerationByCodeAndTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnumerationByCodeAndTypeQuery, GetEnumerationByCodeAndTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEnumerationByCodeAndTypeQuery, GetEnumerationByCodeAndTypeQueryVariables>(GetEnumerationByCodeAndTypeDocument, options);
        }
export function useGetEnumerationByCodeAndTypeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEnumerationByCodeAndTypeQuery, GetEnumerationByCodeAndTypeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEnumerationByCodeAndTypeQuery, GetEnumerationByCodeAndTypeQueryVariables>(GetEnumerationByCodeAndTypeDocument, options);
        }
export type GetEnumerationByCodeAndTypeQueryHookResult = ReturnType<typeof useGetEnumerationByCodeAndTypeQuery>;
export type GetEnumerationByCodeAndTypeLazyQueryHookResult = ReturnType<typeof useGetEnumerationByCodeAndTypeLazyQuery>;
export type GetEnumerationByCodeAndTypeSuspenseQueryHookResult = ReturnType<typeof useGetEnumerationByCodeAndTypeSuspenseQuery>;
export type GetEnumerationByCodeAndTypeQueryResult = Apollo.QueryResult<GetEnumerationByCodeAndTypeQuery, GetEnumerationByCodeAndTypeQueryVariables>;
export const GetEnumerationsByTypeDocument = gql`
    query getEnumerationsByType($types: [String!]) {
  enumeration(where: {type: {_in: $types}}) {
    id
    code
    description
    type
    metadata
  }
}
    `;

/**
 * __useGetEnumerationsByTypeQuery__
 *
 * To run a query within a React component, call `useGetEnumerationsByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnumerationsByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnumerationsByTypeQuery({
 *   variables: {
 *      types: // value for 'types'
 *   },
 * });
 */
export function useGetEnumerationsByTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetEnumerationsByTypeQuery, GetEnumerationsByTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEnumerationsByTypeQuery, GetEnumerationsByTypeQueryVariables>(GetEnumerationsByTypeDocument, options);
      }
export function useGetEnumerationsByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnumerationsByTypeQuery, GetEnumerationsByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEnumerationsByTypeQuery, GetEnumerationsByTypeQueryVariables>(GetEnumerationsByTypeDocument, options);
        }
export function useGetEnumerationsByTypeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEnumerationsByTypeQuery, GetEnumerationsByTypeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEnumerationsByTypeQuery, GetEnumerationsByTypeQueryVariables>(GetEnumerationsByTypeDocument, options);
        }
export type GetEnumerationsByTypeQueryHookResult = ReturnType<typeof useGetEnumerationsByTypeQuery>;
export type GetEnumerationsByTypeLazyQueryHookResult = ReturnType<typeof useGetEnumerationsByTypeLazyQuery>;
export type GetEnumerationsByTypeSuspenseQueryHookResult = ReturnType<typeof useGetEnumerationsByTypeSuspenseQuery>;
export type GetEnumerationsByTypeQueryResult = Apollo.QueryResult<GetEnumerationsByTypeQuery, GetEnumerationsByTypeQueryVariables>;
export const GetMeasurementsOnGridByRunIdAndSensorTypeIdDocument = gql`
    query getMeasurementsOnGridByRunIdAndSensorTypeId($run_id: Int!, $sensor_type_id: Int!) {
  get_measurements_on_grid(
    args: {selected_measurement_run_id: $run_id}
    where: {sensor_type_id: {_eq: $sensor_type_id}}
  ) {
    measurement_run_id
    grid_shape_x
    grid_shape_y
    grid_measurements
  }
}
    `;

/**
 * __useGetMeasurementsOnGridByRunIdAndSensorTypeIdQuery__
 *
 * To run a query within a React component, call `useGetMeasurementsOnGridByRunIdAndSensorTypeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeasurementsOnGridByRunIdAndSensorTypeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeasurementsOnGridByRunIdAndSensorTypeIdQuery({
 *   variables: {
 *      run_id: // value for 'run_id'
 *      sensor_type_id: // value for 'sensor_type_id'
 *   },
 * });
 */
export function useGetMeasurementsOnGridByRunIdAndSensorTypeIdQuery(baseOptions: Apollo.QueryHookOptions<GetMeasurementsOnGridByRunIdAndSensorTypeIdQuery, GetMeasurementsOnGridByRunIdAndSensorTypeIdQueryVariables> & ({ variables: GetMeasurementsOnGridByRunIdAndSensorTypeIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeasurementsOnGridByRunIdAndSensorTypeIdQuery, GetMeasurementsOnGridByRunIdAndSensorTypeIdQueryVariables>(GetMeasurementsOnGridByRunIdAndSensorTypeIdDocument, options);
      }
export function useGetMeasurementsOnGridByRunIdAndSensorTypeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeasurementsOnGridByRunIdAndSensorTypeIdQuery, GetMeasurementsOnGridByRunIdAndSensorTypeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeasurementsOnGridByRunIdAndSensorTypeIdQuery, GetMeasurementsOnGridByRunIdAndSensorTypeIdQueryVariables>(GetMeasurementsOnGridByRunIdAndSensorTypeIdDocument, options);
        }
export function useGetMeasurementsOnGridByRunIdAndSensorTypeIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetMeasurementsOnGridByRunIdAndSensorTypeIdQuery, GetMeasurementsOnGridByRunIdAndSensorTypeIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMeasurementsOnGridByRunIdAndSensorTypeIdQuery, GetMeasurementsOnGridByRunIdAndSensorTypeIdQueryVariables>(GetMeasurementsOnGridByRunIdAndSensorTypeIdDocument, options);
        }
export type GetMeasurementsOnGridByRunIdAndSensorTypeIdQueryHookResult = ReturnType<typeof useGetMeasurementsOnGridByRunIdAndSensorTypeIdQuery>;
export type GetMeasurementsOnGridByRunIdAndSensorTypeIdLazyQueryHookResult = ReturnType<typeof useGetMeasurementsOnGridByRunIdAndSensorTypeIdLazyQuery>;
export type GetMeasurementsOnGridByRunIdAndSensorTypeIdSuspenseQueryHookResult = ReturnType<typeof useGetMeasurementsOnGridByRunIdAndSensorTypeIdSuspenseQuery>;
export type GetMeasurementsOnGridByRunIdAndSensorTypeIdQueryResult = Apollo.QueryResult<GetMeasurementsOnGridByRunIdAndSensorTypeIdQuery, GetMeasurementsOnGridByRunIdAndSensorTypeIdQueryVariables>;
export const GetGrowthCycleByDatesDocument = gql`
    query getGrowthCycleByDates($zone_id: Int!, $start: timestamptz!, $end: timestamptz!) {
  growth_cycle(
    where: {zone_id: {_eq: $zone_id}, is_active: {_eq: true}, _and: [{start_time: {_lte: $end}}, {end_time: {_gte: $start}}]}
    order_by: {start_time: desc}
    limit: 1
  ) {
    ...GrowthCycleFields
  }
}
    ${GrowthCycleFieldsFragmentDoc}`;

/**
 * __useGetGrowthCycleByDatesQuery__
 *
 * To run a query within a React component, call `useGetGrowthCycleByDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGrowthCycleByDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGrowthCycleByDatesQuery({
 *   variables: {
 *      zone_id: // value for 'zone_id'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetGrowthCycleByDatesQuery(baseOptions: Apollo.QueryHookOptions<GetGrowthCycleByDatesQuery, GetGrowthCycleByDatesQueryVariables> & ({ variables: GetGrowthCycleByDatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGrowthCycleByDatesQuery, GetGrowthCycleByDatesQueryVariables>(GetGrowthCycleByDatesDocument, options);
      }
export function useGetGrowthCycleByDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGrowthCycleByDatesQuery, GetGrowthCycleByDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGrowthCycleByDatesQuery, GetGrowthCycleByDatesQueryVariables>(GetGrowthCycleByDatesDocument, options);
        }
export function useGetGrowthCycleByDatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGrowthCycleByDatesQuery, GetGrowthCycleByDatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGrowthCycleByDatesQuery, GetGrowthCycleByDatesQueryVariables>(GetGrowthCycleByDatesDocument, options);
        }
export type GetGrowthCycleByDatesQueryHookResult = ReturnType<typeof useGetGrowthCycleByDatesQuery>;
export type GetGrowthCycleByDatesLazyQueryHookResult = ReturnType<typeof useGetGrowthCycleByDatesLazyQuery>;
export type GetGrowthCycleByDatesSuspenseQueryHookResult = ReturnType<typeof useGetGrowthCycleByDatesSuspenseQuery>;
export type GetGrowthCycleByDatesQueryResult = Apollo.QueryResult<GetGrowthCycleByDatesQuery, GetGrowthCycleByDatesQueryVariables>;
export const GetGrowthCyclesWithRecipesByZoneIdDocument = gql`
    query getGrowthCyclesWithRecipesByZoneId($zone_id: Int!) {
  growth_cycle(where: {zone_id: {_eq: $zone_id}, is_active: {_eq: true}}) {
    ...GrowthCycleFields
  }
}
    ${GrowthCycleFieldsFragmentDoc}`;

/**
 * __useGetGrowthCyclesWithRecipesByZoneIdQuery__
 *
 * To run a query within a React component, call `useGetGrowthCyclesWithRecipesByZoneIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGrowthCyclesWithRecipesByZoneIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGrowthCyclesWithRecipesByZoneIdQuery({
 *   variables: {
 *      zone_id: // value for 'zone_id'
 *   },
 * });
 */
export function useGetGrowthCyclesWithRecipesByZoneIdQuery(baseOptions: Apollo.QueryHookOptions<GetGrowthCyclesWithRecipesByZoneIdQuery, GetGrowthCyclesWithRecipesByZoneIdQueryVariables> & ({ variables: GetGrowthCyclesWithRecipesByZoneIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGrowthCyclesWithRecipesByZoneIdQuery, GetGrowthCyclesWithRecipesByZoneIdQueryVariables>(GetGrowthCyclesWithRecipesByZoneIdDocument, options);
      }
export function useGetGrowthCyclesWithRecipesByZoneIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGrowthCyclesWithRecipesByZoneIdQuery, GetGrowthCyclesWithRecipesByZoneIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGrowthCyclesWithRecipesByZoneIdQuery, GetGrowthCyclesWithRecipesByZoneIdQueryVariables>(GetGrowthCyclesWithRecipesByZoneIdDocument, options);
        }
export function useGetGrowthCyclesWithRecipesByZoneIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGrowthCyclesWithRecipesByZoneIdQuery, GetGrowthCyclesWithRecipesByZoneIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGrowthCyclesWithRecipesByZoneIdQuery, GetGrowthCyclesWithRecipesByZoneIdQueryVariables>(GetGrowthCyclesWithRecipesByZoneIdDocument, options);
        }
export type GetGrowthCyclesWithRecipesByZoneIdQueryHookResult = ReturnType<typeof useGetGrowthCyclesWithRecipesByZoneIdQuery>;
export type GetGrowthCyclesWithRecipesByZoneIdLazyQueryHookResult = ReturnType<typeof useGetGrowthCyclesWithRecipesByZoneIdLazyQuery>;
export type GetGrowthCyclesWithRecipesByZoneIdSuspenseQueryHookResult = ReturnType<typeof useGetGrowthCyclesWithRecipesByZoneIdSuspenseQuery>;
export type GetGrowthCyclesWithRecipesByZoneIdQueryResult = Apollo.QueryResult<GetGrowthCyclesWithRecipesByZoneIdQuery, GetGrowthCyclesWithRecipesByZoneIdQueryVariables>;
export const GetRecipesByLocationIdDocument = gql`
    query GetRecipesByLocationId($location_id: Int!) {
  environment_recipe(
    where: {location_id: {_eq: $location_id}, is_active: {_eq: true}}
  ) {
    id
    name
    is_active
    location_id
    organization_id
    last_updated
    environment_recipe_entrys {
      id
      day
      type_id
      lights_on
      set_point
      recipe_id
      ideal_lower
      ideal_upper
      critical_lower
      critical_upper
      enumeration {
        code
      }
    }
    growth_cycles_aggregate {
      aggregate {
        count
      }
    }
  }
  light_cycle_recipe(
    where: {location_id: {_eq: $location_id}, is_active: {_eq: true}}
  ) {
    id
    name
    is_active
    location_id
    organization_id
    last_updated
    light_cycle_recipe_entrys {
      id
      day
      recipe_id
      lights_on_time
      lights_on_duration_minutes
    }
    growth_cycles_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetRecipesByLocationIdQuery__
 *
 * To run a query within a React component, call `useGetRecipesByLocationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipesByLocationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipesByLocationIdQuery({
 *   variables: {
 *      location_id: // value for 'location_id'
 *   },
 * });
 */
export function useGetRecipesByLocationIdQuery(baseOptions: Apollo.QueryHookOptions<GetRecipesByLocationIdQuery, GetRecipesByLocationIdQueryVariables> & ({ variables: GetRecipesByLocationIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecipesByLocationIdQuery, GetRecipesByLocationIdQueryVariables>(GetRecipesByLocationIdDocument, options);
      }
export function useGetRecipesByLocationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecipesByLocationIdQuery, GetRecipesByLocationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecipesByLocationIdQuery, GetRecipesByLocationIdQueryVariables>(GetRecipesByLocationIdDocument, options);
        }
export function useGetRecipesByLocationIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRecipesByLocationIdQuery, GetRecipesByLocationIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRecipesByLocationIdQuery, GetRecipesByLocationIdQueryVariables>(GetRecipesByLocationIdDocument, options);
        }
export type GetRecipesByLocationIdQueryHookResult = ReturnType<typeof useGetRecipesByLocationIdQuery>;
export type GetRecipesByLocationIdLazyQueryHookResult = ReturnType<typeof useGetRecipesByLocationIdLazyQuery>;
export type GetRecipesByLocationIdSuspenseQueryHookResult = ReturnType<typeof useGetRecipesByLocationIdSuspenseQuery>;
export type GetRecipesByLocationIdQueryResult = Apollo.QueryResult<GetRecipesByLocationIdQuery, GetRecipesByLocationIdQueryVariables>;
export const InsertEnvironmentRecipeDocument = gql`
    mutation InsertEnvironmentRecipe($name: String!, $location_id: Int!, $organization_id: Int!, $entries: environment_recipe_entry_arr_rel_insert_input!, $is_active: Boolean!) {
  insert_environment_recipe(
    objects: {name: $name, location_id: $location_id, organization_id: $organization_id, environment_recipe_entrys: $entries, last_updated: now, is_active: $is_active}
  ) {
    returning {
      id
    }
  }
}
    `;
export type InsertEnvironmentRecipeMutationFn = Apollo.MutationFunction<InsertEnvironmentRecipeMutation, InsertEnvironmentRecipeMutationVariables>;

/**
 * __useInsertEnvironmentRecipeMutation__
 *
 * To run a mutation, you first call `useInsertEnvironmentRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertEnvironmentRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEnvironmentRecipeMutation, { data, loading, error }] = useInsertEnvironmentRecipeMutation({
 *   variables: {
 *      name: // value for 'name'
 *      location_id: // value for 'location_id'
 *      organization_id: // value for 'organization_id'
 *      entries: // value for 'entries'
 *      is_active: // value for 'is_active'
 *   },
 * });
 */
export function useInsertEnvironmentRecipeMutation(baseOptions?: Apollo.MutationHookOptions<InsertEnvironmentRecipeMutation, InsertEnvironmentRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertEnvironmentRecipeMutation, InsertEnvironmentRecipeMutationVariables>(InsertEnvironmentRecipeDocument, options);
      }
export type InsertEnvironmentRecipeMutationHookResult = ReturnType<typeof useInsertEnvironmentRecipeMutation>;
export type InsertEnvironmentRecipeMutationResult = Apollo.MutationResult<InsertEnvironmentRecipeMutation>;
export type InsertEnvironmentRecipeMutationOptions = Apollo.BaseMutationOptions<InsertEnvironmentRecipeMutation, InsertEnvironmentRecipeMutationVariables>;
export const InsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeDocument = gql`
    mutation InsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipe($zone_id: Int!, $start_time: timestamptz!, $end_time: timestamptz!, $metadata: jsonb!, $location_id: Int!, $organization_id: Int!, $env_recipe_name: String!, $env_recipe_entries: environment_recipe_entry_arr_rel_insert_input!, $lc_recipe_id: Int!, $lc_recipe_name: String!, $lc_recipe_entries: [light_cycle_recipe_entry_insert_input!]!) {
  insert_growth_cycle_one(
    object: {zone_id: $zone_id, start_time: $start_time, end_time: $end_time, metadata: $metadata, is_active: true, light_cycle_recipe_id: $lc_recipe_id, environment_recipe: {data: {environment_recipe_entrys: $env_recipe_entries, name: $env_recipe_name, location_id: $location_id, organization_id: $organization_id, is_active: true, last_updated: now}}}
  ) {
    id
  }
  update_light_cycle_recipe(
    where: {id: {_eq: $lc_recipe_id}}
    _set: {name: $lc_recipe_name, last_updated: now, is_active: true}
  ) {
    affected_rows
  }
  delete_light_cycle_recipe_entry(where: {recipe_id: {_eq: $lc_recipe_id}}) {
    affected_rows
  }
  insert_light_cycle_recipe_entry(objects: $lc_recipe_entries) {
    affected_rows
  }
}
    `;
export type InsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutationFn = Apollo.MutationFunction<InsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutation, InsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutationVariables>;

/**
 * __useInsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutation__
 *
 * To run a mutation, you first call `useInsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutation, { data, loading, error }] = useInsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutation({
 *   variables: {
 *      zone_id: // value for 'zone_id'
 *      start_time: // value for 'start_time'
 *      end_time: // value for 'end_time'
 *      metadata: // value for 'metadata'
 *      location_id: // value for 'location_id'
 *      organization_id: // value for 'organization_id'
 *      env_recipe_name: // value for 'env_recipe_name'
 *      env_recipe_entries: // value for 'env_recipe_entries'
 *      lc_recipe_id: // value for 'lc_recipe_id'
 *      lc_recipe_name: // value for 'lc_recipe_name'
 *      lc_recipe_entries: // value for 'lc_recipe_entries'
 *   },
 * });
 */
export function useInsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutation(baseOptions?: Apollo.MutationHookOptions<InsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutation, InsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutation, InsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutationVariables>(InsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeDocument, options);
      }
export type InsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutationHookResult = ReturnType<typeof useInsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutation>;
export type InsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutationResult = Apollo.MutationResult<InsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutation>;
export type InsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutationOptions = Apollo.BaseMutationOptions<InsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutation, InsertGrowthCycleAndEnvironmentRecipeAndUpdateLightCycleRecipeMutationVariables>;
export const InsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeDocument = gql`
    mutation InsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipe($zone_id: Int!, $start_time: timestamptz!, $end_time: timestamptz!, $metadata: jsonb!, $location_id: Int!, $organization_id: Int!, $env_recipe_id: Int!, $env_recipe_name: String!, $env_recipe_entries: [environment_recipe_entry_insert_input!]!, $lc_recipe_name: String!, $lc_recipe_entries: light_cycle_recipe_entry_arr_rel_insert_input!) {
  insert_growth_cycle_one(
    object: {zone_id: $zone_id, start_time: $start_time, end_time: $end_time, metadata: $metadata, is_active: true, environment_recipe_id: $env_recipe_id, light_cycle_recipe: {data: {light_cycle_recipe_entrys: $lc_recipe_entries, name: $lc_recipe_name, location_id: $location_id, organization_id: $organization_id, is_active: true, last_updated: now}}}
  ) {
    id
  }
  update_environment_recipe(
    where: {id: {_eq: $env_recipe_id}}
    _set: {name: $env_recipe_name, last_updated: now, is_active: true}
  ) {
    affected_rows
  }
  delete_environment_recipe_entry(where: {recipe_id: {_eq: $env_recipe_id}}) {
    affected_rows
  }
  insert_environment_recipe_entry(objects: $env_recipe_entries) {
    affected_rows
  }
}
    `;
export type InsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutationFn = Apollo.MutationFunction<InsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutation, InsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutationVariables>;

/**
 * __useInsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutation__
 *
 * To run a mutation, you first call `useInsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutation, { data, loading, error }] = useInsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutation({
 *   variables: {
 *      zone_id: // value for 'zone_id'
 *      start_time: // value for 'start_time'
 *      end_time: // value for 'end_time'
 *      metadata: // value for 'metadata'
 *      location_id: // value for 'location_id'
 *      organization_id: // value for 'organization_id'
 *      env_recipe_id: // value for 'env_recipe_id'
 *      env_recipe_name: // value for 'env_recipe_name'
 *      env_recipe_entries: // value for 'env_recipe_entries'
 *      lc_recipe_name: // value for 'lc_recipe_name'
 *      lc_recipe_entries: // value for 'lc_recipe_entries'
 *   },
 * });
 */
export function useInsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutation(baseOptions?: Apollo.MutationHookOptions<InsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutation, InsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutation, InsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutationVariables>(InsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeDocument, options);
      }
export type InsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutationHookResult = ReturnType<typeof useInsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutation>;
export type InsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutationResult = Apollo.MutationResult<InsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutation>;
export type InsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutationOptions = Apollo.BaseMutationOptions<InsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutation, InsertGrowthCycleAndLightCycleRecipeAndUpdateEnvironmentRecipeMutationVariables>;
export const InsertGrowthCycleAndUpdateRecipesDocument = gql`
    mutation InsertGrowthCycleAndUpdateRecipes($zone_id: Int!, $start_time: timestamptz!, $end_time: timestamptz!, $metadata: jsonb!, $env_recipe_id: Int!, $env_recipe_name: String!, $env_recipe_entries: [environment_recipe_entry_insert_input!]!, $lc_recipe_id: Int!, $lc_recipe_name: String!, $lc_recipe_entries: [light_cycle_recipe_entry_insert_input!]!) {
  insert_growth_cycle_one(
    object: {zone_id: $zone_id, start_time: $start_time, end_time: $end_time, metadata: $metadata, is_active: true, environment_recipe_id: $env_recipe_id, light_cycle_recipe_id: $lc_recipe_id}
  ) {
    id
  }
  update_environment_recipe(
    where: {id: {_eq: $env_recipe_id}}
    _set: {name: $env_recipe_name, last_updated: now, is_active: true}
  ) {
    affected_rows
  }
  delete_environment_recipe_entry(where: {recipe_id: {_eq: $env_recipe_id}}) {
    affected_rows
  }
  insert_environment_recipe_entry(objects: $env_recipe_entries) {
    affected_rows
  }
  update_light_cycle_recipe(
    where: {id: {_eq: $lc_recipe_id}}
    _set: {name: $lc_recipe_name, last_updated: now, is_active: true}
  ) {
    affected_rows
  }
  delete_light_cycle_recipe_entry(where: {recipe_id: {_eq: $lc_recipe_id}}) {
    affected_rows
  }
  insert_light_cycle_recipe_entry(objects: $lc_recipe_entries) {
    affected_rows
  }
}
    `;
export type InsertGrowthCycleAndUpdateRecipesMutationFn = Apollo.MutationFunction<InsertGrowthCycleAndUpdateRecipesMutation, InsertGrowthCycleAndUpdateRecipesMutationVariables>;

/**
 * __useInsertGrowthCycleAndUpdateRecipesMutation__
 *
 * To run a mutation, you first call `useInsertGrowthCycleAndUpdateRecipesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertGrowthCycleAndUpdateRecipesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertGrowthCycleAndUpdateRecipesMutation, { data, loading, error }] = useInsertGrowthCycleAndUpdateRecipesMutation({
 *   variables: {
 *      zone_id: // value for 'zone_id'
 *      start_time: // value for 'start_time'
 *      end_time: // value for 'end_time'
 *      metadata: // value for 'metadata'
 *      env_recipe_id: // value for 'env_recipe_id'
 *      env_recipe_name: // value for 'env_recipe_name'
 *      env_recipe_entries: // value for 'env_recipe_entries'
 *      lc_recipe_id: // value for 'lc_recipe_id'
 *      lc_recipe_name: // value for 'lc_recipe_name'
 *      lc_recipe_entries: // value for 'lc_recipe_entries'
 *   },
 * });
 */
export function useInsertGrowthCycleAndUpdateRecipesMutation(baseOptions?: Apollo.MutationHookOptions<InsertGrowthCycleAndUpdateRecipesMutation, InsertGrowthCycleAndUpdateRecipesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertGrowthCycleAndUpdateRecipesMutation, InsertGrowthCycleAndUpdateRecipesMutationVariables>(InsertGrowthCycleAndUpdateRecipesDocument, options);
      }
export type InsertGrowthCycleAndUpdateRecipesMutationHookResult = ReturnType<typeof useInsertGrowthCycleAndUpdateRecipesMutation>;
export type InsertGrowthCycleAndUpdateRecipesMutationResult = Apollo.MutationResult<InsertGrowthCycleAndUpdateRecipesMutation>;
export type InsertGrowthCycleAndUpdateRecipesMutationOptions = Apollo.BaseMutationOptions<InsertGrowthCycleAndUpdateRecipesMutation, InsertGrowthCycleAndUpdateRecipesMutationVariables>;
export const InsertGrowthCycleWithRecipesDocument = gql`
    mutation InsertGrowthCycleWithRecipes($zone_id: Int!, $start_time: timestamptz!, $end_time: timestamptz!, $metadata: jsonb!, $location_id: Int!, $organization_id: Int!, $env_recipe_name: String!, $env_recipe_entries: environment_recipe_entry_arr_rel_insert_input!, $lc_recipe_name: String!, $lc_recipe_entries: light_cycle_recipe_entry_arr_rel_insert_input!) {
  insert_growth_cycle_one(
    object: {zone_id: $zone_id, start_time: $start_time, end_time: $end_time, metadata: $metadata, is_active: true, environment_recipe: {data: {environment_recipe_entrys: $env_recipe_entries, name: $env_recipe_name, location_id: $location_id, organization_id: $organization_id, is_active: true, last_updated: now}}, light_cycle_recipe: {data: {light_cycle_recipe_entrys: $lc_recipe_entries, name: $lc_recipe_name, location_id: $location_id, organization_id: $organization_id, is_active: true, last_updated: now}}}
  ) {
    id
  }
}
    `;
export type InsertGrowthCycleWithRecipesMutationFn = Apollo.MutationFunction<InsertGrowthCycleWithRecipesMutation, InsertGrowthCycleWithRecipesMutationVariables>;

/**
 * __useInsertGrowthCycleWithRecipesMutation__
 *
 * To run a mutation, you first call `useInsertGrowthCycleWithRecipesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertGrowthCycleWithRecipesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertGrowthCycleWithRecipesMutation, { data, loading, error }] = useInsertGrowthCycleWithRecipesMutation({
 *   variables: {
 *      zone_id: // value for 'zone_id'
 *      start_time: // value for 'start_time'
 *      end_time: // value for 'end_time'
 *      metadata: // value for 'metadata'
 *      location_id: // value for 'location_id'
 *      organization_id: // value for 'organization_id'
 *      env_recipe_name: // value for 'env_recipe_name'
 *      env_recipe_entries: // value for 'env_recipe_entries'
 *      lc_recipe_name: // value for 'lc_recipe_name'
 *      lc_recipe_entries: // value for 'lc_recipe_entries'
 *   },
 * });
 */
export function useInsertGrowthCycleWithRecipesMutation(baseOptions?: Apollo.MutationHookOptions<InsertGrowthCycleWithRecipesMutation, InsertGrowthCycleWithRecipesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertGrowthCycleWithRecipesMutation, InsertGrowthCycleWithRecipesMutationVariables>(InsertGrowthCycleWithRecipesDocument, options);
      }
export type InsertGrowthCycleWithRecipesMutationHookResult = ReturnType<typeof useInsertGrowthCycleWithRecipesMutation>;
export type InsertGrowthCycleWithRecipesMutationResult = Apollo.MutationResult<InsertGrowthCycleWithRecipesMutation>;
export type InsertGrowthCycleWithRecipesMutationOptions = Apollo.BaseMutationOptions<InsertGrowthCycleWithRecipesMutation, InsertGrowthCycleWithRecipesMutationVariables>;
export const InsertLightCycleRecipeDocument = gql`
    mutation InsertLightCycleRecipe($name: String!, $location_id: Int!, $organization_id: Int!, $entries: light_cycle_recipe_entry_arr_rel_insert_input!, $is_active: Boolean!) {
  insert_light_cycle_recipe(
    objects: {name: $name, location_id: $location_id, organization_id: $organization_id, light_cycle_recipe_entrys: $entries, last_updated: now, is_active: $is_active}
  ) {
    returning {
      id
    }
  }
}
    `;
export type InsertLightCycleRecipeMutationFn = Apollo.MutationFunction<InsertLightCycleRecipeMutation, InsertLightCycleRecipeMutationVariables>;

/**
 * __useInsertLightCycleRecipeMutation__
 *
 * To run a mutation, you first call `useInsertLightCycleRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLightCycleRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLightCycleRecipeMutation, { data, loading, error }] = useInsertLightCycleRecipeMutation({
 *   variables: {
 *      name: // value for 'name'
 *      location_id: // value for 'location_id'
 *      organization_id: // value for 'organization_id'
 *      entries: // value for 'entries'
 *      is_active: // value for 'is_active'
 *   },
 * });
 */
export function useInsertLightCycleRecipeMutation(baseOptions?: Apollo.MutationHookOptions<InsertLightCycleRecipeMutation, InsertLightCycleRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLightCycleRecipeMutation, InsertLightCycleRecipeMutationVariables>(InsertLightCycleRecipeDocument, options);
      }
export type InsertLightCycleRecipeMutationHookResult = ReturnType<typeof useInsertLightCycleRecipeMutation>;
export type InsertLightCycleRecipeMutationResult = Apollo.MutationResult<InsertLightCycleRecipeMutation>;
export type InsertLightCycleRecipeMutationOptions = Apollo.BaseMutationOptions<InsertLightCycleRecipeMutation, InsertLightCycleRecipeMutationVariables>;
export const RemoveEnvironmentRecipeDocument = gql`
    mutation RemoveEnvironmentRecipe($id: Int!) {
  update_environment_recipe(
    where: {id: {_eq: $id}}
    _set: {is_active: false, last_updated: now}
  ) {
    affected_rows
  }
}
    `;
export type RemoveEnvironmentRecipeMutationFn = Apollo.MutationFunction<RemoveEnvironmentRecipeMutation, RemoveEnvironmentRecipeMutationVariables>;

/**
 * __useRemoveEnvironmentRecipeMutation__
 *
 * To run a mutation, you first call `useRemoveEnvironmentRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEnvironmentRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEnvironmentRecipeMutation, { data, loading, error }] = useRemoveEnvironmentRecipeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveEnvironmentRecipeMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEnvironmentRecipeMutation, RemoveEnvironmentRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEnvironmentRecipeMutation, RemoveEnvironmentRecipeMutationVariables>(RemoveEnvironmentRecipeDocument, options);
      }
export type RemoveEnvironmentRecipeMutationHookResult = ReturnType<typeof useRemoveEnvironmentRecipeMutation>;
export type RemoveEnvironmentRecipeMutationResult = Apollo.MutationResult<RemoveEnvironmentRecipeMutation>;
export type RemoveEnvironmentRecipeMutationOptions = Apollo.BaseMutationOptions<RemoveEnvironmentRecipeMutation, RemoveEnvironmentRecipeMutationVariables>;
export const RemoveGrowthCycleWithRecipesDocument = gql`
    mutation RemoveGrowthCycleWithRecipes($growth_cycle_id: Int!) {
  update_growth_cycle(
    where: {id: {_eq: $growth_cycle_id}}
    _set: {is_active: false}
  ) {
    affected_rows
  }
}
    `;
export type RemoveGrowthCycleWithRecipesMutationFn = Apollo.MutationFunction<RemoveGrowthCycleWithRecipesMutation, RemoveGrowthCycleWithRecipesMutationVariables>;

/**
 * __useRemoveGrowthCycleWithRecipesMutation__
 *
 * To run a mutation, you first call `useRemoveGrowthCycleWithRecipesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGrowthCycleWithRecipesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGrowthCycleWithRecipesMutation, { data, loading, error }] = useRemoveGrowthCycleWithRecipesMutation({
 *   variables: {
 *      growth_cycle_id: // value for 'growth_cycle_id'
 *   },
 * });
 */
export function useRemoveGrowthCycleWithRecipesMutation(baseOptions?: Apollo.MutationHookOptions<RemoveGrowthCycleWithRecipesMutation, RemoveGrowthCycleWithRecipesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveGrowthCycleWithRecipesMutation, RemoveGrowthCycleWithRecipesMutationVariables>(RemoveGrowthCycleWithRecipesDocument, options);
      }
export type RemoveGrowthCycleWithRecipesMutationHookResult = ReturnType<typeof useRemoveGrowthCycleWithRecipesMutation>;
export type RemoveGrowthCycleWithRecipesMutationResult = Apollo.MutationResult<RemoveGrowthCycleWithRecipesMutation>;
export type RemoveGrowthCycleWithRecipesMutationOptions = Apollo.BaseMutationOptions<RemoveGrowthCycleWithRecipesMutation, RemoveGrowthCycleWithRecipesMutationVariables>;
export const RemoveLightCycleRecipeDocument = gql`
    mutation RemoveLightCycleRecipe($id: Int!) {
  update_light_cycle_recipe(
    where: {id: {_eq: $id}}
    _set: {is_active: false, last_updated: now}
  ) {
    affected_rows
  }
}
    `;
export type RemoveLightCycleRecipeMutationFn = Apollo.MutationFunction<RemoveLightCycleRecipeMutation, RemoveLightCycleRecipeMutationVariables>;

/**
 * __useRemoveLightCycleRecipeMutation__
 *
 * To run a mutation, you first call `useRemoveLightCycleRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLightCycleRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLightCycleRecipeMutation, { data, loading, error }] = useRemoveLightCycleRecipeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveLightCycleRecipeMutation(baseOptions?: Apollo.MutationHookOptions<RemoveLightCycleRecipeMutation, RemoveLightCycleRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveLightCycleRecipeMutation, RemoveLightCycleRecipeMutationVariables>(RemoveLightCycleRecipeDocument, options);
      }
export type RemoveLightCycleRecipeMutationHookResult = ReturnType<typeof useRemoveLightCycleRecipeMutation>;
export type RemoveLightCycleRecipeMutationResult = Apollo.MutationResult<RemoveLightCycleRecipeMutation>;
export type RemoveLightCycleRecipeMutationOptions = Apollo.BaseMutationOptions<RemoveLightCycleRecipeMutation, RemoveLightCycleRecipeMutationVariables>;
export const UpdateEnvironmentRecipeDocument = gql`
    mutation UpdateEnvironmentRecipe($id: Int!, $name: String!, $entries: [environment_recipe_entry_insert_input!]!) {
  update_environment_recipe(
    where: {id: {_eq: $id}}
    _set: {name: $name, last_updated: now}
  ) {
    affected_rows
  }
  delete_environment_recipe_entry(where: {recipe_id: {_eq: $id}}) {
    affected_rows
  }
  insert_environment_recipe_entry(objects: $entries) {
    affected_rows
  }
}
    `;
export type UpdateEnvironmentRecipeMutationFn = Apollo.MutationFunction<UpdateEnvironmentRecipeMutation, UpdateEnvironmentRecipeMutationVariables>;

/**
 * __useUpdateEnvironmentRecipeMutation__
 *
 * To run a mutation, you first call `useUpdateEnvironmentRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnvironmentRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnvironmentRecipeMutation, { data, loading, error }] = useUpdateEnvironmentRecipeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      entries: // value for 'entries'
 *   },
 * });
 */
export function useUpdateEnvironmentRecipeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEnvironmentRecipeMutation, UpdateEnvironmentRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEnvironmentRecipeMutation, UpdateEnvironmentRecipeMutationVariables>(UpdateEnvironmentRecipeDocument, options);
      }
export type UpdateEnvironmentRecipeMutationHookResult = ReturnType<typeof useUpdateEnvironmentRecipeMutation>;
export type UpdateEnvironmentRecipeMutationResult = Apollo.MutationResult<UpdateEnvironmentRecipeMutation>;
export type UpdateEnvironmentRecipeMutationOptions = Apollo.BaseMutationOptions<UpdateEnvironmentRecipeMutation, UpdateEnvironmentRecipeMutationVariables>;
export const UpdateGrowthCycleMetadataDocument = gql`
    mutation UpdateGrowthCycleMetadata($growth_cycle_id: Int!, $metadata: jsonb!) {
  update_growth_cycle(
    where: {id: {_eq: $growth_cycle_id}}
    _set: {metadata: $metadata}
  ) {
    affected_rows
  }
}
    `;
export type UpdateGrowthCycleMetadataMutationFn = Apollo.MutationFunction<UpdateGrowthCycleMetadataMutation, UpdateGrowthCycleMetadataMutationVariables>;

/**
 * __useUpdateGrowthCycleMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateGrowthCycleMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGrowthCycleMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGrowthCycleMetadataMutation, { data, loading, error }] = useUpdateGrowthCycleMetadataMutation({
 *   variables: {
 *      growth_cycle_id: // value for 'growth_cycle_id'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useUpdateGrowthCycleMetadataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGrowthCycleMetadataMutation, UpdateGrowthCycleMetadataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGrowthCycleMetadataMutation, UpdateGrowthCycleMetadataMutationVariables>(UpdateGrowthCycleMetadataDocument, options);
      }
export type UpdateGrowthCycleMetadataMutationHookResult = ReturnType<typeof useUpdateGrowthCycleMetadataMutation>;
export type UpdateGrowthCycleMetadataMutationResult = Apollo.MutationResult<UpdateGrowthCycleMetadataMutation>;
export type UpdateGrowthCycleMetadataMutationOptions = Apollo.BaseMutationOptions<UpdateGrowthCycleMetadataMutation, UpdateGrowthCycleMetadataMutationVariables>;
export const UpdateGrowthCycleWithRecipesDocument = gql`
    mutation UpdateGrowthCycleWithRecipes($growth_cycle_id: Int!, $start_time: timestamptz!, $end_time: timestamptz!, $metadata: jsonb!, $env_recipe_id: Int!, $env_recipe_name: String!, $env_recipe_entries: [environment_recipe_entry_insert_input!]!, $lc_recipe_id: Int!, $lc_recipe_name: String!, $lc_recipe_entries: [light_cycle_recipe_entry_insert_input!]!) {
  update_growth_cycle(
    where: {id: {_eq: $growth_cycle_id}}
    _set: {start_time: $start_time, end_time: $end_time, metadata: $metadata, environment_recipe_id: $env_recipe_id, light_cycle_recipe_id: $lc_recipe_id}
  ) {
    affected_rows
  }
  update_environment_recipe(
    where: {id: {_eq: $env_recipe_id}}
    _set: {name: $env_recipe_name, last_updated: now, is_active: true}
  ) {
    affected_rows
  }
  delete_environment_recipe_entry(where: {recipe_id: {_eq: $env_recipe_id}}) {
    affected_rows
  }
  insert_environment_recipe_entry(objects: $env_recipe_entries) {
    affected_rows
  }
  update_light_cycle_recipe(
    where: {id: {_eq: $lc_recipe_id}}
    _set: {name: $lc_recipe_name, last_updated: now, is_active: true}
  ) {
    affected_rows
  }
  delete_light_cycle_recipe_entry(where: {recipe_id: {_eq: $lc_recipe_id}}) {
    affected_rows
  }
  insert_light_cycle_recipe_entry(objects: $lc_recipe_entries) {
    affected_rows
  }
}
    `;
export type UpdateGrowthCycleWithRecipesMutationFn = Apollo.MutationFunction<UpdateGrowthCycleWithRecipesMutation, UpdateGrowthCycleWithRecipesMutationVariables>;

/**
 * __useUpdateGrowthCycleWithRecipesMutation__
 *
 * To run a mutation, you first call `useUpdateGrowthCycleWithRecipesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGrowthCycleWithRecipesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGrowthCycleWithRecipesMutation, { data, loading, error }] = useUpdateGrowthCycleWithRecipesMutation({
 *   variables: {
 *      growth_cycle_id: // value for 'growth_cycle_id'
 *      start_time: // value for 'start_time'
 *      end_time: // value for 'end_time'
 *      metadata: // value for 'metadata'
 *      env_recipe_id: // value for 'env_recipe_id'
 *      env_recipe_name: // value for 'env_recipe_name'
 *      env_recipe_entries: // value for 'env_recipe_entries'
 *      lc_recipe_id: // value for 'lc_recipe_id'
 *      lc_recipe_name: // value for 'lc_recipe_name'
 *      lc_recipe_entries: // value for 'lc_recipe_entries'
 *   },
 * });
 */
export function useUpdateGrowthCycleWithRecipesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGrowthCycleWithRecipesMutation, UpdateGrowthCycleWithRecipesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGrowthCycleWithRecipesMutation, UpdateGrowthCycleWithRecipesMutationVariables>(UpdateGrowthCycleWithRecipesDocument, options);
      }
export type UpdateGrowthCycleWithRecipesMutationHookResult = ReturnType<typeof useUpdateGrowthCycleWithRecipesMutation>;
export type UpdateGrowthCycleWithRecipesMutationResult = Apollo.MutationResult<UpdateGrowthCycleWithRecipesMutation>;
export type UpdateGrowthCycleWithRecipesMutationOptions = Apollo.BaseMutationOptions<UpdateGrowthCycleWithRecipesMutation, UpdateGrowthCycleWithRecipesMutationVariables>;
export const UpdateLightCycleRecipeDocument = gql`
    mutation UpdateLightCycleRecipe($id: Int!, $name: String!, $entries: [light_cycle_recipe_entry_insert_input!]!) {
  update_light_cycle_recipe(
    where: {id: {_eq: $id}}
    _set: {name: $name, last_updated: now}
  ) {
    affected_rows
  }
  delete_light_cycle_recipe_entry(where: {recipe_id: {_eq: $id}}) {
    affected_rows
  }
  insert_light_cycle_recipe_entry(objects: $entries) {
    affected_rows
  }
}
    `;
export type UpdateLightCycleRecipeMutationFn = Apollo.MutationFunction<UpdateLightCycleRecipeMutation, UpdateLightCycleRecipeMutationVariables>;

/**
 * __useUpdateLightCycleRecipeMutation__
 *
 * To run a mutation, you first call `useUpdateLightCycleRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLightCycleRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLightCycleRecipeMutation, { data, loading, error }] = useUpdateLightCycleRecipeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      entries: // value for 'entries'
 *   },
 * });
 */
export function useUpdateLightCycleRecipeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLightCycleRecipeMutation, UpdateLightCycleRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLightCycleRecipeMutation, UpdateLightCycleRecipeMutationVariables>(UpdateLightCycleRecipeDocument, options);
      }
export type UpdateLightCycleRecipeMutationHookResult = ReturnType<typeof useUpdateLightCycleRecipeMutation>;
export type UpdateLightCycleRecipeMutationResult = Apollo.MutationResult<UpdateLightCycleRecipeMutation>;
export type UpdateLightCycleRecipeMutationOptions = Apollo.BaseMutationOptions<UpdateLightCycleRecipeMutation, UpdateLightCycleRecipeMutationVariables>;
export const GetHeatMapsDocument = gql`
    query getHeatMaps($start: timestamptz!, $end: timestamptz!, $zone_id: Int!, $zone_uid: String!, $type: String!, $enumeration: String!, $statisticsPath: String = "statistics.XXX") {
  heat_map(
    order_by: {measurement_run: {start_time: asc}}
    where: {measurement_run: {zone_id: {_eq: $zone_id}, end_time: {_gte: $start, _lte: $end}, valid: {_eq: true}, metadata: {_has_key: "statistics"}}, enumeration: {code: {_eq: $enumeration}}}
  ) {
    id
    measurement_run {
      start_time
      end_time
      id
      metadata(path: $statisticsPath)
    }
  }
  aggregate_heatmap(
    order_by: {start_time: asc}
    where: {zone_uid: {_eq: $zone_uid}, end_time: {_gte: $start, _lte: $end}, is_valid: {_eq: true}, type_uid: {_eq: $type}}
  ) {
    start_time
    end_time
    uid
  }
}
    `;

/**
 * __useGetHeatMapsQuery__
 *
 * To run a query within a React component, call `useGetHeatMapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHeatMapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHeatMapsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      zone_id: // value for 'zone_id'
 *      zone_uid: // value for 'zone_uid'
 *      type: // value for 'type'
 *      enumeration: // value for 'enumeration'
 *      statisticsPath: // value for 'statisticsPath'
 *   },
 * });
 */
export function useGetHeatMapsQuery(baseOptions: Apollo.QueryHookOptions<GetHeatMapsQuery, GetHeatMapsQueryVariables> & ({ variables: GetHeatMapsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHeatMapsQuery, GetHeatMapsQueryVariables>(GetHeatMapsDocument, options);
      }
export function useGetHeatMapsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHeatMapsQuery, GetHeatMapsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHeatMapsQuery, GetHeatMapsQueryVariables>(GetHeatMapsDocument, options);
        }
export function useGetHeatMapsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetHeatMapsQuery, GetHeatMapsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetHeatMapsQuery, GetHeatMapsQueryVariables>(GetHeatMapsDocument, options);
        }
export type GetHeatMapsQueryHookResult = ReturnType<typeof useGetHeatMapsQuery>;
export type GetHeatMapsLazyQueryHookResult = ReturnType<typeof useGetHeatMapsLazyQuery>;
export type GetHeatMapsSuspenseQueryHookResult = ReturnType<typeof useGetHeatMapsSuspenseQuery>;
export type GetHeatMapsQueryResult = Apollo.QueryResult<GetHeatMapsQuery, GetHeatMapsQueryVariables>;
export const GetHeatMapByIdDocument = gql`
    query getHeatMapById($uid: uuid = "00000000-0000-0000-0000-000000000000", $id: Int = -1, $statisticsPath: String = "statistics.XXX") {
  heat_map(where: {id: {_eq: $id}}) {
    measurement_run {
      metadata(path: $statisticsPath)
    }
    data(path: "data")
  }
  aggregate_heatmap(where: {uid: {_eq: $uid}}) {
    data(path: "data")
  }
}
    `;

/**
 * __useGetHeatMapByIdQuery__
 *
 * To run a query within a React component, call `useGetHeatMapByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHeatMapByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHeatMapByIdQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *      id: // value for 'id'
 *      statisticsPath: // value for 'statisticsPath'
 *   },
 * });
 */
export function useGetHeatMapByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetHeatMapByIdQuery, GetHeatMapByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHeatMapByIdQuery, GetHeatMapByIdQueryVariables>(GetHeatMapByIdDocument, options);
      }
export function useGetHeatMapByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHeatMapByIdQuery, GetHeatMapByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHeatMapByIdQuery, GetHeatMapByIdQueryVariables>(GetHeatMapByIdDocument, options);
        }
export function useGetHeatMapByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetHeatMapByIdQuery, GetHeatMapByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetHeatMapByIdQuery, GetHeatMapByIdQueryVariables>(GetHeatMapByIdDocument, options);
        }
export type GetHeatMapByIdQueryHookResult = ReturnType<typeof useGetHeatMapByIdQuery>;
export type GetHeatMapByIdLazyQueryHookResult = ReturnType<typeof useGetHeatMapByIdLazyQuery>;
export type GetHeatMapByIdSuspenseQueryHookResult = ReturnType<typeof useGetHeatMapByIdSuspenseQuery>;
export type GetHeatMapByIdQueryResult = Apollo.QueryResult<GetHeatMapByIdQuery, GetHeatMapByIdQueryVariables>;
export const GetLabelCountsByMeasurementIdDocument = gql`
    query getLabelCountsByMeasurementId($measurementId: bigint!) {
  label_counts_by_measurement(where: {measurement_id: {_eq: $measurementId}}) {
    measurement_run_id
    measurement_id
    label_name_id
    label_counts
    label_ids
  }
}
    `;

/**
 * __useGetLabelCountsByMeasurementIdQuery__
 *
 * To run a query within a React component, call `useGetLabelCountsByMeasurementIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelCountsByMeasurementIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabelCountsByMeasurementIdQuery({
 *   variables: {
 *      measurementId: // value for 'measurementId'
 *   },
 * });
 */
export function useGetLabelCountsByMeasurementIdQuery(baseOptions: Apollo.QueryHookOptions<GetLabelCountsByMeasurementIdQuery, GetLabelCountsByMeasurementIdQueryVariables> & ({ variables: GetLabelCountsByMeasurementIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLabelCountsByMeasurementIdQuery, GetLabelCountsByMeasurementIdQueryVariables>(GetLabelCountsByMeasurementIdDocument, options);
      }
export function useGetLabelCountsByMeasurementIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLabelCountsByMeasurementIdQuery, GetLabelCountsByMeasurementIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLabelCountsByMeasurementIdQuery, GetLabelCountsByMeasurementIdQueryVariables>(GetLabelCountsByMeasurementIdDocument, options);
        }
export function useGetLabelCountsByMeasurementIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLabelCountsByMeasurementIdQuery, GetLabelCountsByMeasurementIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLabelCountsByMeasurementIdQuery, GetLabelCountsByMeasurementIdQueryVariables>(GetLabelCountsByMeasurementIdDocument, options);
        }
export type GetLabelCountsByMeasurementIdQueryHookResult = ReturnType<typeof useGetLabelCountsByMeasurementIdQuery>;
export type GetLabelCountsByMeasurementIdLazyQueryHookResult = ReturnType<typeof useGetLabelCountsByMeasurementIdLazyQuery>;
export type GetLabelCountsByMeasurementIdSuspenseQueryHookResult = ReturnType<typeof useGetLabelCountsByMeasurementIdSuspenseQuery>;
export type GetLabelCountsByMeasurementIdQueryResult = Apollo.QueryResult<GetLabelCountsByMeasurementIdQuery, GetLabelCountsByMeasurementIdQueryVariables>;
export const GetLabelCountsByMeasurementRunIdDocument = gql`
    query getLabelCountsByMeasurementRunId($measurementRunId: Int!) {
  label_counts_by_measurement(
    where: {measurement_run_id: {_eq: $measurementRunId}}
  ) {
    measurement_run_id
    measurement_id
    label_name_id
    label_counts
    label_ids
  }
}
    `;

/**
 * __useGetLabelCountsByMeasurementRunIdQuery__
 *
 * To run a query within a React component, call `useGetLabelCountsByMeasurementRunIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelCountsByMeasurementRunIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabelCountsByMeasurementRunIdQuery({
 *   variables: {
 *      measurementRunId: // value for 'measurementRunId'
 *   },
 * });
 */
export function useGetLabelCountsByMeasurementRunIdQuery(baseOptions: Apollo.QueryHookOptions<GetLabelCountsByMeasurementRunIdQuery, GetLabelCountsByMeasurementRunIdQueryVariables> & ({ variables: GetLabelCountsByMeasurementRunIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLabelCountsByMeasurementRunIdQuery, GetLabelCountsByMeasurementRunIdQueryVariables>(GetLabelCountsByMeasurementRunIdDocument, options);
      }
export function useGetLabelCountsByMeasurementRunIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLabelCountsByMeasurementRunIdQuery, GetLabelCountsByMeasurementRunIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLabelCountsByMeasurementRunIdQuery, GetLabelCountsByMeasurementRunIdQueryVariables>(GetLabelCountsByMeasurementRunIdDocument, options);
        }
export function useGetLabelCountsByMeasurementRunIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLabelCountsByMeasurementRunIdQuery, GetLabelCountsByMeasurementRunIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLabelCountsByMeasurementRunIdQuery, GetLabelCountsByMeasurementRunIdQueryVariables>(GetLabelCountsByMeasurementRunIdDocument, options);
        }
export type GetLabelCountsByMeasurementRunIdQueryHookResult = ReturnType<typeof useGetLabelCountsByMeasurementRunIdQuery>;
export type GetLabelCountsByMeasurementRunIdLazyQueryHookResult = ReturnType<typeof useGetLabelCountsByMeasurementRunIdLazyQuery>;
export type GetLabelCountsByMeasurementRunIdSuspenseQueryHookResult = ReturnType<typeof useGetLabelCountsByMeasurementRunIdSuspenseQuery>;
export type GetLabelCountsByMeasurementRunIdQueryResult = Apollo.QueryResult<GetLabelCountsByMeasurementRunIdQuery, GetLabelCountsByMeasurementRunIdQueryVariables>;
export const GetLabelsByIdsDocument = gql`
    query getLabelsByIds($ids: [Int!]!) {
  label(
    where: {id: {_in: $ids}, enumerationByLabelTypeId: {code: {_eq: "RECTANGLE"}}}
  ) {
    id
    active
    measurement {
      id
    }
    measurement_run {
      id
    }
    enumeration {
      code
      description
      id
      type
    }
    metadata
    human_source_id
    lambda_source_id
  }
}
    `;

/**
 * __useGetLabelsByIdsQuery__
 *
 * To run a query within a React component, call `useGetLabelsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabelsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetLabelsByIdsQuery(baseOptions: Apollo.QueryHookOptions<GetLabelsByIdsQuery, GetLabelsByIdsQueryVariables> & ({ variables: GetLabelsByIdsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLabelsByIdsQuery, GetLabelsByIdsQueryVariables>(GetLabelsByIdsDocument, options);
      }
export function useGetLabelsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLabelsByIdsQuery, GetLabelsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLabelsByIdsQuery, GetLabelsByIdsQueryVariables>(GetLabelsByIdsDocument, options);
        }
export function useGetLabelsByIdsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLabelsByIdsQuery, GetLabelsByIdsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLabelsByIdsQuery, GetLabelsByIdsQueryVariables>(GetLabelsByIdsDocument, options);
        }
export type GetLabelsByIdsQueryHookResult = ReturnType<typeof useGetLabelsByIdsQuery>;
export type GetLabelsByIdsLazyQueryHookResult = ReturnType<typeof useGetLabelsByIdsLazyQuery>;
export type GetLabelsByIdsSuspenseQueryHookResult = ReturnType<typeof useGetLabelsByIdsSuspenseQuery>;
export type GetLabelsByIdsQueryResult = Apollo.QueryResult<GetLabelsByIdsQuery, GetLabelsByIdsQueryVariables>;
export const GetLocationByIdDocument = gql`
    query getLocationById($id: Int!) {
  location(where: {id: {_eq: $id}}) {
    id
    name_id
    metadata
    address_id
    time_zone_name
    organization_id
    enumeration {
      code
      description
    }
    organization {
      enumeration {
        code
        description
      }
    }
  }
}
    `;

/**
 * __useGetLocationByIdQuery__
 *
 * To run a query within a React component, call `useGetLocationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationByIdQuery(baseOptions: Apollo.QueryHookOptions<GetLocationByIdQuery, GetLocationByIdQueryVariables> & ({ variables: GetLocationByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationByIdQuery, GetLocationByIdQueryVariables>(GetLocationByIdDocument, options);
      }
export function useGetLocationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationByIdQuery, GetLocationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationByIdQuery, GetLocationByIdQueryVariables>(GetLocationByIdDocument, options);
        }
export function useGetLocationByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLocationByIdQuery, GetLocationByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLocationByIdQuery, GetLocationByIdQueryVariables>(GetLocationByIdDocument, options);
        }
export type GetLocationByIdQueryHookResult = ReturnType<typeof useGetLocationByIdQuery>;
export type GetLocationByIdLazyQueryHookResult = ReturnType<typeof useGetLocationByIdLazyQuery>;
export type GetLocationByIdSuspenseQueryHookResult = ReturnType<typeof useGetLocationByIdSuspenseQuery>;
export type GetLocationByIdQueryResult = Apollo.QueryResult<GetLocationByIdQuery, GetLocationByIdQueryVariables>;
export const UpdateLocationMetadataDocument = gql`
    mutation updateLocationMetadata($id: Int!, $metadata: jsonb!) {
  update_location(where: {id: {_eq: $id}}, _set: {metadata: $metadata}) {
    affected_rows
  }
}
    `;
export type UpdateLocationMetadataMutationFn = Apollo.MutationFunction<UpdateLocationMetadataMutation, UpdateLocationMetadataMutationVariables>;

/**
 * __useUpdateLocationMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMetadataMutation, { data, loading, error }] = useUpdateLocationMetadataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useUpdateLocationMetadataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationMetadataMutation, UpdateLocationMetadataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationMetadataMutation, UpdateLocationMetadataMutationVariables>(UpdateLocationMetadataDocument, options);
      }
export type UpdateLocationMetadataMutationHookResult = ReturnType<typeof useUpdateLocationMetadataMutation>;
export type UpdateLocationMetadataMutationResult = Apollo.MutationResult<UpdateLocationMetadataMutation>;
export type UpdateLocationMetadataMutationOptions = Apollo.BaseMutationOptions<UpdateLocationMetadataMutation, UpdateLocationMetadataMutationVariables>;
export const GetLimitedZoneMeasurementsDocument = gql`
    query getLimitedZoneMeasurements($zone_id: Int!, $start: timestamptz = "", $end: timestamptz = "", $limit: Int = 10) {
  measurement_view(
    where: {zone_id: {_eq: $zone_id}, time: {_gte: $start, _lte: $end}}
    order_by: {time: desc}
    limit: $limit
  ) {
    zone_id
    measurement_type
    data
    time
  }
}
    `;

/**
 * __useGetLimitedZoneMeasurementsQuery__
 *
 * To run a query within a React component, call `useGetLimitedZoneMeasurementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLimitedZoneMeasurementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLimitedZoneMeasurementsQuery({
 *   variables: {
 *      zone_id: // value for 'zone_id'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetLimitedZoneMeasurementsQuery(baseOptions: Apollo.QueryHookOptions<GetLimitedZoneMeasurementsQuery, GetLimitedZoneMeasurementsQueryVariables> & ({ variables: GetLimitedZoneMeasurementsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLimitedZoneMeasurementsQuery, GetLimitedZoneMeasurementsQueryVariables>(GetLimitedZoneMeasurementsDocument, options);
      }
export function useGetLimitedZoneMeasurementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLimitedZoneMeasurementsQuery, GetLimitedZoneMeasurementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLimitedZoneMeasurementsQuery, GetLimitedZoneMeasurementsQueryVariables>(GetLimitedZoneMeasurementsDocument, options);
        }
export function useGetLimitedZoneMeasurementsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLimitedZoneMeasurementsQuery, GetLimitedZoneMeasurementsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLimitedZoneMeasurementsQuery, GetLimitedZoneMeasurementsQueryVariables>(GetLimitedZoneMeasurementsDocument, options);
        }
export type GetLimitedZoneMeasurementsQueryHookResult = ReturnType<typeof useGetLimitedZoneMeasurementsQuery>;
export type GetLimitedZoneMeasurementsLazyQueryHookResult = ReturnType<typeof useGetLimitedZoneMeasurementsLazyQuery>;
export type GetLimitedZoneMeasurementsSuspenseQueryHookResult = ReturnType<typeof useGetLimitedZoneMeasurementsSuspenseQuery>;
export type GetLimitedZoneMeasurementsQueryResult = Apollo.QueryResult<GetLimitedZoneMeasurementsQuery, GetLimitedZoneMeasurementsQueryVariables>;
export const GetMeasurementsByIdsDocument = gql`
    query getMeasurementsByIds($ids: [bigint!] = []) {
  measurement(where: {id: {_in: $ids}}) {
    id
    type_id
    pose_id
    data
    time
    metadata
    image_annotation_trackings_aggregate {
      aggregate {
        count
      }
    }
    measurement_run {
      id
      metadata
    }
  }
}
    `;

/**
 * __useGetMeasurementsByIdsQuery__
 *
 * To run a query within a React component, call `useGetMeasurementsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeasurementsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeasurementsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetMeasurementsByIdsQuery(baseOptions?: Apollo.QueryHookOptions<GetMeasurementsByIdsQuery, GetMeasurementsByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeasurementsByIdsQuery, GetMeasurementsByIdsQueryVariables>(GetMeasurementsByIdsDocument, options);
      }
export function useGetMeasurementsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeasurementsByIdsQuery, GetMeasurementsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeasurementsByIdsQuery, GetMeasurementsByIdsQueryVariables>(GetMeasurementsByIdsDocument, options);
        }
export function useGetMeasurementsByIdsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetMeasurementsByIdsQuery, GetMeasurementsByIdsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMeasurementsByIdsQuery, GetMeasurementsByIdsQueryVariables>(GetMeasurementsByIdsDocument, options);
        }
export type GetMeasurementsByIdsQueryHookResult = ReturnType<typeof useGetMeasurementsByIdsQuery>;
export type GetMeasurementsByIdsLazyQueryHookResult = ReturnType<typeof useGetMeasurementsByIdsLazyQuery>;
export type GetMeasurementsByIdsSuspenseQueryHookResult = ReturnType<typeof useGetMeasurementsByIdsSuspenseQuery>;
export type GetMeasurementsByIdsQueryResult = Apollo.QueryResult<GetMeasurementsByIdsQuery, GetMeasurementsByIdsQueryVariables>;
export const GetMeasurementsByRunIdsAndPositionDocument = gql`
    query getMeasurementsByRunIdsAndPosition($measurementRunIds: [Int!], $xIndex: Int!, $yIndex: Int!, $sensorTypeId: Int!) {
  measurements_by_grid_index(
    where: {measurement_run_id: {_in: $measurementRunIds}, x_index: {_eq: $xIndex}, y_index: {_eq: $yIndex}, sensor_type_id: {_eq: $sensorTypeId}}
  ) {
    measurement_id
  }
}
    `;

/**
 * __useGetMeasurementsByRunIdsAndPositionQuery__
 *
 * To run a query within a React component, call `useGetMeasurementsByRunIdsAndPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeasurementsByRunIdsAndPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeasurementsByRunIdsAndPositionQuery({
 *   variables: {
 *      measurementRunIds: // value for 'measurementRunIds'
 *      xIndex: // value for 'xIndex'
 *      yIndex: // value for 'yIndex'
 *      sensorTypeId: // value for 'sensorTypeId'
 *   },
 * });
 */
export function useGetMeasurementsByRunIdsAndPositionQuery(baseOptions: Apollo.QueryHookOptions<GetMeasurementsByRunIdsAndPositionQuery, GetMeasurementsByRunIdsAndPositionQueryVariables> & ({ variables: GetMeasurementsByRunIdsAndPositionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeasurementsByRunIdsAndPositionQuery, GetMeasurementsByRunIdsAndPositionQueryVariables>(GetMeasurementsByRunIdsAndPositionDocument, options);
      }
export function useGetMeasurementsByRunIdsAndPositionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeasurementsByRunIdsAndPositionQuery, GetMeasurementsByRunIdsAndPositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeasurementsByRunIdsAndPositionQuery, GetMeasurementsByRunIdsAndPositionQueryVariables>(GetMeasurementsByRunIdsAndPositionDocument, options);
        }
export function useGetMeasurementsByRunIdsAndPositionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetMeasurementsByRunIdsAndPositionQuery, GetMeasurementsByRunIdsAndPositionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMeasurementsByRunIdsAndPositionQuery, GetMeasurementsByRunIdsAndPositionQueryVariables>(GetMeasurementsByRunIdsAndPositionDocument, options);
        }
export type GetMeasurementsByRunIdsAndPositionQueryHookResult = ReturnType<typeof useGetMeasurementsByRunIdsAndPositionQuery>;
export type GetMeasurementsByRunIdsAndPositionLazyQueryHookResult = ReturnType<typeof useGetMeasurementsByRunIdsAndPositionLazyQuery>;
export type GetMeasurementsByRunIdsAndPositionSuspenseQueryHookResult = ReturnType<typeof useGetMeasurementsByRunIdsAndPositionSuspenseQuery>;
export type GetMeasurementsByRunIdsAndPositionQueryResult = Apollo.QueryResult<GetMeasurementsByRunIdsAndPositionQuery, GetMeasurementsByRunIdsAndPositionQueryVariables>;
export const GetUnlimitedZoneMeasurementsDocument = gql`
    query getUnlimitedZoneMeasurements($zone_id: Int!, $measurement_types: [String!]!, $start: timestamptz = "", $end: timestamptz = "") {
  measurement_view(
    where: {zone_id: {_eq: $zone_id}, measurement_type: {_in: $measurement_types}, time: {_gte: $start, _lte: $end}}
    order_by: {time: asc}
  ) {
    zone_id
    measurement_type
    data
    time
  }
}
    `;

/**
 * __useGetUnlimitedZoneMeasurementsQuery__
 *
 * To run a query within a React component, call `useGetUnlimitedZoneMeasurementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnlimitedZoneMeasurementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnlimitedZoneMeasurementsQuery({
 *   variables: {
 *      zone_id: // value for 'zone_id'
 *      measurement_types: // value for 'measurement_types'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetUnlimitedZoneMeasurementsQuery(baseOptions: Apollo.QueryHookOptions<GetUnlimitedZoneMeasurementsQuery, GetUnlimitedZoneMeasurementsQueryVariables> & ({ variables: GetUnlimitedZoneMeasurementsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnlimitedZoneMeasurementsQuery, GetUnlimitedZoneMeasurementsQueryVariables>(GetUnlimitedZoneMeasurementsDocument, options);
      }
export function useGetUnlimitedZoneMeasurementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnlimitedZoneMeasurementsQuery, GetUnlimitedZoneMeasurementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnlimitedZoneMeasurementsQuery, GetUnlimitedZoneMeasurementsQueryVariables>(GetUnlimitedZoneMeasurementsDocument, options);
        }
export function useGetUnlimitedZoneMeasurementsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUnlimitedZoneMeasurementsQuery, GetUnlimitedZoneMeasurementsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUnlimitedZoneMeasurementsQuery, GetUnlimitedZoneMeasurementsQueryVariables>(GetUnlimitedZoneMeasurementsDocument, options);
        }
export type GetUnlimitedZoneMeasurementsQueryHookResult = ReturnType<typeof useGetUnlimitedZoneMeasurementsQuery>;
export type GetUnlimitedZoneMeasurementsLazyQueryHookResult = ReturnType<typeof useGetUnlimitedZoneMeasurementsLazyQuery>;
export type GetUnlimitedZoneMeasurementsSuspenseQueryHookResult = ReturnType<typeof useGetUnlimitedZoneMeasurementsSuspenseQuery>;
export type GetUnlimitedZoneMeasurementsQueryResult = Apollo.QueryResult<GetUnlimitedZoneMeasurementsQuery, GetUnlimitedZoneMeasurementsQueryVariables>;
export const UpdateMeasurementMetadataByIdDocument = gql`
    mutation updateMeasurementMetadataById($metadata: jsonb!, $id: bigint!) {
  update_measurement(_set: {metadata: $metadata}, where: {id: {_eq: $id}}) {
    affected_rows
    returning {
      id
      metadata
    }
  }
}
    `;
export type UpdateMeasurementMetadataByIdMutationFn = Apollo.MutationFunction<UpdateMeasurementMetadataByIdMutation, UpdateMeasurementMetadataByIdMutationVariables>;

/**
 * __useUpdateMeasurementMetadataByIdMutation__
 *
 * To run a mutation, you first call `useUpdateMeasurementMetadataByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeasurementMetadataByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeasurementMetadataByIdMutation, { data, loading, error }] = useUpdateMeasurementMetadataByIdMutation({
 *   variables: {
 *      metadata: // value for 'metadata'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateMeasurementMetadataByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMeasurementMetadataByIdMutation, UpdateMeasurementMetadataByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMeasurementMetadataByIdMutation, UpdateMeasurementMetadataByIdMutationVariables>(UpdateMeasurementMetadataByIdDocument, options);
      }
export type UpdateMeasurementMetadataByIdMutationHookResult = ReturnType<typeof useUpdateMeasurementMetadataByIdMutation>;
export type UpdateMeasurementMetadataByIdMutationResult = Apollo.MutationResult<UpdateMeasurementMetadataByIdMutation>;
export type UpdateMeasurementMetadataByIdMutationOptions = Apollo.BaseMutationOptions<UpdateMeasurementMetadataByIdMutation, UpdateMeasurementMetadataByIdMutationVariables>;
export const GetImageFeedMeasurementRunsDocument = gql`
    query getImageFeedMeasurementRuns($zone_id: Int!, $start: timestamptz = "", $end: timestamptz = "") {
  measurement_run(
    where: {zone_id: {_eq: $zone_id}, metadata: {_has_keys_all: ["rgb_images_processed", "image_info"]}, start_time: {_gte: $start, _lte: $end}, valid: {_eq: true}}
    order_by: {start_time: desc}
  ) {
    start_time
    end_time
    id
    annotation_status_id
    metadata
    grid {
      shape_x
      shape_y
    }
    grid_id
  }
}
    `;

/**
 * __useGetImageFeedMeasurementRunsQuery__
 *
 * To run a query within a React component, call `useGetImageFeedMeasurementRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImageFeedMeasurementRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImageFeedMeasurementRunsQuery({
 *   variables: {
 *      zone_id: // value for 'zone_id'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetImageFeedMeasurementRunsQuery(baseOptions: Apollo.QueryHookOptions<GetImageFeedMeasurementRunsQuery, GetImageFeedMeasurementRunsQueryVariables> & ({ variables: GetImageFeedMeasurementRunsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetImageFeedMeasurementRunsQuery, GetImageFeedMeasurementRunsQueryVariables>(GetImageFeedMeasurementRunsDocument, options);
      }
export function useGetImageFeedMeasurementRunsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetImageFeedMeasurementRunsQuery, GetImageFeedMeasurementRunsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetImageFeedMeasurementRunsQuery, GetImageFeedMeasurementRunsQueryVariables>(GetImageFeedMeasurementRunsDocument, options);
        }
export function useGetImageFeedMeasurementRunsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetImageFeedMeasurementRunsQuery, GetImageFeedMeasurementRunsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetImageFeedMeasurementRunsQuery, GetImageFeedMeasurementRunsQueryVariables>(GetImageFeedMeasurementRunsDocument, options);
        }
export type GetImageFeedMeasurementRunsQueryHookResult = ReturnType<typeof useGetImageFeedMeasurementRunsQuery>;
export type GetImageFeedMeasurementRunsLazyQueryHookResult = ReturnType<typeof useGetImageFeedMeasurementRunsLazyQuery>;
export type GetImageFeedMeasurementRunsSuspenseQueryHookResult = ReturnType<typeof useGetImageFeedMeasurementRunsSuspenseQuery>;
export type GetImageFeedMeasurementRunsQueryResult = Apollo.QueryResult<GetImageFeedMeasurementRunsQuery, GetImageFeedMeasurementRunsQueryVariables>;
export const GetMeasurementRunsBetweenDatesByZoneDocument = gql`
    query getMeasurementRunsBetweenDatesByZone($zone_id: Int!, $start: timestamptz!, $end: timestamptz!) {
  measurement_run(
    where: {zone_id: {_eq: $zone_id}, _and: [{_and: [{start_time: {_gte: $start}}, {start_time: {_lte: $end}}]}, {_and: [{end_time: {_gte: $start}}, {end_time: {_lte: $end}}]}], metadata: {_has_key: "statistics"}, valid: {_eq: true}}
    order_by: {start_time: asc}
  ) {
    start_time
    end_time
    id
    metadata
  }
}
    `;

/**
 * __useGetMeasurementRunsBetweenDatesByZoneQuery__
 *
 * To run a query within a React component, call `useGetMeasurementRunsBetweenDatesByZoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeasurementRunsBetweenDatesByZoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeasurementRunsBetweenDatesByZoneQuery({
 *   variables: {
 *      zone_id: // value for 'zone_id'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetMeasurementRunsBetweenDatesByZoneQuery(baseOptions: Apollo.QueryHookOptions<GetMeasurementRunsBetweenDatesByZoneQuery, GetMeasurementRunsBetweenDatesByZoneQueryVariables> & ({ variables: GetMeasurementRunsBetweenDatesByZoneQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeasurementRunsBetweenDatesByZoneQuery, GetMeasurementRunsBetweenDatesByZoneQueryVariables>(GetMeasurementRunsBetweenDatesByZoneDocument, options);
      }
export function useGetMeasurementRunsBetweenDatesByZoneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeasurementRunsBetweenDatesByZoneQuery, GetMeasurementRunsBetweenDatesByZoneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeasurementRunsBetweenDatesByZoneQuery, GetMeasurementRunsBetweenDatesByZoneQueryVariables>(GetMeasurementRunsBetweenDatesByZoneDocument, options);
        }
export function useGetMeasurementRunsBetweenDatesByZoneSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetMeasurementRunsBetweenDatesByZoneQuery, GetMeasurementRunsBetweenDatesByZoneQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMeasurementRunsBetweenDatesByZoneQuery, GetMeasurementRunsBetweenDatesByZoneQueryVariables>(GetMeasurementRunsBetweenDatesByZoneDocument, options);
        }
export type GetMeasurementRunsBetweenDatesByZoneQueryHookResult = ReturnType<typeof useGetMeasurementRunsBetweenDatesByZoneQuery>;
export type GetMeasurementRunsBetweenDatesByZoneLazyQueryHookResult = ReturnType<typeof useGetMeasurementRunsBetweenDatesByZoneLazyQuery>;
export type GetMeasurementRunsBetweenDatesByZoneSuspenseQueryHookResult = ReturnType<typeof useGetMeasurementRunsBetweenDatesByZoneSuspenseQuery>;
export type GetMeasurementRunsBetweenDatesByZoneQueryResult = Apollo.QueryResult<GetMeasurementRunsBetweenDatesByZoneQuery, GetMeasurementRunsBetweenDatesByZoneQueryVariables>;
export const RegisterUserToOrganizationWithRoleDocument = gql`
    mutation registerUserToOrganizationWithRole($userId: Int!, $organizationId: Int!, $roleId: Int!) {
  insert_user_organization_role(
    objects: {role_id: $roleId, user_id: $userId, organization_id: $organizationId}
  ) {
    returning {
      user_id
      role_id
      organization_id
    }
  }
}
    `;
export type RegisterUserToOrganizationWithRoleMutationFn = Apollo.MutationFunction<RegisterUserToOrganizationWithRoleMutation, RegisterUserToOrganizationWithRoleMutationVariables>;

/**
 * __useRegisterUserToOrganizationWithRoleMutation__
 *
 * To run a mutation, you first call `useRegisterUserToOrganizationWithRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserToOrganizationWithRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserToOrganizationWithRoleMutation, { data, loading, error }] = useRegisterUserToOrganizationWithRoleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationId: // value for 'organizationId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useRegisterUserToOrganizationWithRoleMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserToOrganizationWithRoleMutation, RegisterUserToOrganizationWithRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserToOrganizationWithRoleMutation, RegisterUserToOrganizationWithRoleMutationVariables>(RegisterUserToOrganizationWithRoleDocument, options);
      }
export type RegisterUserToOrganizationWithRoleMutationHookResult = ReturnType<typeof useRegisterUserToOrganizationWithRoleMutation>;
export type RegisterUserToOrganizationWithRoleMutationResult = Apollo.MutationResult<RegisterUserToOrganizationWithRoleMutation>;
export type RegisterUserToOrganizationWithRoleMutationOptions = Apollo.BaseMutationOptions<RegisterUserToOrganizationWithRoleMutation, RegisterUserToOrganizationWithRoleMutationVariables>;
export const GetPermissionsByRoleIdsDocument = gql`
    query get_permissions_by_role_ids($ids: [Int!]) {
  role_permission(where: {role_id: {_in: $ids}}) {
    role_id
    permission_id
    enumeration {
      code
      metadata
    }
  }
}
    `;

/**
 * __useGetPermissionsByRoleIdsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsByRoleIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsByRoleIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsByRoleIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetPermissionsByRoleIdsQuery(baseOptions?: Apollo.QueryHookOptions<GetPermissionsByRoleIdsQuery, GetPermissionsByRoleIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermissionsByRoleIdsQuery, GetPermissionsByRoleIdsQueryVariables>(GetPermissionsByRoleIdsDocument, options);
      }
export function useGetPermissionsByRoleIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionsByRoleIdsQuery, GetPermissionsByRoleIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermissionsByRoleIdsQuery, GetPermissionsByRoleIdsQueryVariables>(GetPermissionsByRoleIdsDocument, options);
        }
export function useGetPermissionsByRoleIdsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPermissionsByRoleIdsQuery, GetPermissionsByRoleIdsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPermissionsByRoleIdsQuery, GetPermissionsByRoleIdsQueryVariables>(GetPermissionsByRoleIdsDocument, options);
        }
export type GetPermissionsByRoleIdsQueryHookResult = ReturnType<typeof useGetPermissionsByRoleIdsQuery>;
export type GetPermissionsByRoleIdsLazyQueryHookResult = ReturnType<typeof useGetPermissionsByRoleIdsLazyQuery>;
export type GetPermissionsByRoleIdsSuspenseQueryHookResult = ReturnType<typeof useGetPermissionsByRoleIdsSuspenseQuery>;
export type GetPermissionsByRoleIdsQueryResult = Apollo.QueryResult<GetPermissionsByRoleIdsQuery, GetPermissionsByRoleIdsQueryVariables>;
export const GetSystemByZoneUidDocument = gql`
    query getSystemByZoneUid($zone_uid: String = "") {
  system(where: {is_active: {_eq: true}, zone: {uid: {_eq: $zone_uid}}}) {
    id
    system_status
    system_status_created_at
    system_status_updated_at
  }
}
    `;

/**
 * __useGetSystemByZoneUidQuery__
 *
 * To run a query within a React component, call `useGetSystemByZoneUidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemByZoneUidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemByZoneUidQuery({
 *   variables: {
 *      zone_uid: // value for 'zone_uid'
 *   },
 * });
 */
export function useGetSystemByZoneUidQuery(baseOptions?: Apollo.QueryHookOptions<GetSystemByZoneUidQuery, GetSystemByZoneUidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSystemByZoneUidQuery, GetSystemByZoneUidQueryVariables>(GetSystemByZoneUidDocument, options);
      }
export function useGetSystemByZoneUidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSystemByZoneUidQuery, GetSystemByZoneUidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSystemByZoneUidQuery, GetSystemByZoneUidQueryVariables>(GetSystemByZoneUidDocument, options);
        }
export function useGetSystemByZoneUidSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSystemByZoneUidQuery, GetSystemByZoneUidQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSystemByZoneUidQuery, GetSystemByZoneUidQueryVariables>(GetSystemByZoneUidDocument, options);
        }
export type GetSystemByZoneUidQueryHookResult = ReturnType<typeof useGetSystemByZoneUidQuery>;
export type GetSystemByZoneUidLazyQueryHookResult = ReturnType<typeof useGetSystemByZoneUidLazyQuery>;
export type GetSystemByZoneUidSuspenseQueryHookResult = ReturnType<typeof useGetSystemByZoneUidSuspenseQuery>;
export type GetSystemByZoneUidQueryResult = Apollo.QueryResult<GetSystemByZoneUidQuery, GetSystemByZoneUidQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($active: Boolean!, $email: String!, $firstName: String!, $lastName: String!, $password: String!, $phoneNumber: String) {
  insert_user(
    objects: {active: $active, email: $email, first_name: $firstName, last_name: $lastName, password: $password, phone_number: $phoneNumber, metadata: {version: "1.0.0"}}
  ) {
    returning {
      id
      active
      email
      first_name
      last_name
      password
      metadata
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      active: // value for 'active'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      password: // value for 'password'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: Int!) {
  delete_user_by_pk(id: $id) {
    id
    email
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DeleteUserOrganizationRoleDocument = gql`
    mutation deleteUserOrganizationRole($userId: Int!, $organizationId: Int!, $roleId: Int!) {
  delete_user_organization_role(
    where: {_and: {organization_id: {_eq: $organizationId}, user_id: {_eq: $userId}, role_id: {_eq: $roleId}}}
  ) {
    returning {
      role_id
      user_id
      organization_id
    }
  }
}
    `;
export type DeleteUserOrganizationRoleMutationFn = Apollo.MutationFunction<DeleteUserOrganizationRoleMutation, DeleteUserOrganizationRoleMutationVariables>;

/**
 * __useDeleteUserOrganizationRoleMutation__
 *
 * To run a mutation, you first call `useDeleteUserOrganizationRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserOrganizationRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserOrganizationRoleMutation, { data, loading, error }] = useDeleteUserOrganizationRoleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationId: // value for 'organizationId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useDeleteUserOrganizationRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserOrganizationRoleMutation, DeleteUserOrganizationRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserOrganizationRoleMutation, DeleteUserOrganizationRoleMutationVariables>(DeleteUserOrganizationRoleDocument, options);
      }
export type DeleteUserOrganizationRoleMutationHookResult = ReturnType<typeof useDeleteUserOrganizationRoleMutation>;
export type DeleteUserOrganizationRoleMutationResult = Apollo.MutationResult<DeleteUserOrganizationRoleMutation>;
export type DeleteUserOrganizationRoleMutationOptions = Apollo.BaseMutationOptions<DeleteUserOrganizationRoleMutation, DeleteUserOrganizationRoleMutationVariables>;
export const GetDiscussionByUidDocument = gql`
    query getDiscussionByUid($discussionUid: uuid!) {
  discussion(where: {uid: {_eq: $discussionUid}}) {
    uid
    zone_uid
  }
}
    `;

/**
 * __useGetDiscussionByUidQuery__
 *
 * To run a query within a React component, call `useGetDiscussionByUidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscussionByUidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscussionByUidQuery({
 *   variables: {
 *      discussionUid: // value for 'discussionUid'
 *   },
 * });
 */
export function useGetDiscussionByUidQuery(baseOptions: Apollo.QueryHookOptions<GetDiscussionByUidQuery, GetDiscussionByUidQueryVariables> & ({ variables: GetDiscussionByUidQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiscussionByUidQuery, GetDiscussionByUidQueryVariables>(GetDiscussionByUidDocument, options);
      }
export function useGetDiscussionByUidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiscussionByUidQuery, GetDiscussionByUidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiscussionByUidQuery, GetDiscussionByUidQueryVariables>(GetDiscussionByUidDocument, options);
        }
export function useGetDiscussionByUidSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDiscussionByUidQuery, GetDiscussionByUidQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDiscussionByUidQuery, GetDiscussionByUidQueryVariables>(GetDiscussionByUidDocument, options);
        }
export type GetDiscussionByUidQueryHookResult = ReturnType<typeof useGetDiscussionByUidQuery>;
export type GetDiscussionByUidLazyQueryHookResult = ReturnType<typeof useGetDiscussionByUidLazyQuery>;
export type GetDiscussionByUidSuspenseQueryHookResult = ReturnType<typeof useGetDiscussionByUidSuspenseQuery>;
export type GetDiscussionByUidQueryResult = Apollo.QueryResult<GetDiscussionByUidQuery, GetDiscussionByUidQueryVariables>;
export const GetNonKioskUsersWithZoneViewPermissionDocument = gql`
    query getNonKioskUsersWithZoneViewPermission($zoneUid: String!, $customAndCondition: user_bool_exp!) {
  user(
    where: {_and: [$customAndCondition, {active: {_eq: true}}, {_not: {user_organization_roles: {role: {_and: [{type: {_eq: "ROLE_TYPE"}}, {code: {_eq: "KIOSK"}}]}}}}, {_or: [{user_organization_roles: {_and: [{role: {rolePermissionsByRoleId: {enumeration: {code: {_eq: "ZONE:VIEW"}}}}}, {zone: {uid: {_eq: $zoneUid}}}]}}, {user_organization_roles: {_and: [{role: {rolePermissionsByRoleId: {enumeration: {code: {_eq: "ZONE:VIEW"}}}}}, {location: {zones: {uid: {_eq: $zoneUid}}}}]}}, {user_organization_roles: {_and: [{role: {rolePermissionsByRoleId: {enumeration: {code: {_eq: "ZONE:VIEW"}}}}}, {organization: {locations: {zones: {uid: {_eq: $zoneUid}}}}}]}}]}]}
  ) {
    id
    email
    first_name
    last_name
  }
}
    `;

/**
 * __useGetNonKioskUsersWithZoneViewPermissionQuery__
 *
 * To run a query within a React component, call `useGetNonKioskUsersWithZoneViewPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNonKioskUsersWithZoneViewPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNonKioskUsersWithZoneViewPermissionQuery({
 *   variables: {
 *      zoneUid: // value for 'zoneUid'
 *      customAndCondition: // value for 'customAndCondition'
 *   },
 * });
 */
export function useGetNonKioskUsersWithZoneViewPermissionQuery(baseOptions: Apollo.QueryHookOptions<GetNonKioskUsersWithZoneViewPermissionQuery, GetNonKioskUsersWithZoneViewPermissionQueryVariables> & ({ variables: GetNonKioskUsersWithZoneViewPermissionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNonKioskUsersWithZoneViewPermissionQuery, GetNonKioskUsersWithZoneViewPermissionQueryVariables>(GetNonKioskUsersWithZoneViewPermissionDocument, options);
      }
export function useGetNonKioskUsersWithZoneViewPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNonKioskUsersWithZoneViewPermissionQuery, GetNonKioskUsersWithZoneViewPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNonKioskUsersWithZoneViewPermissionQuery, GetNonKioskUsersWithZoneViewPermissionQueryVariables>(GetNonKioskUsersWithZoneViewPermissionDocument, options);
        }
export function useGetNonKioskUsersWithZoneViewPermissionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetNonKioskUsersWithZoneViewPermissionQuery, GetNonKioskUsersWithZoneViewPermissionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetNonKioskUsersWithZoneViewPermissionQuery, GetNonKioskUsersWithZoneViewPermissionQueryVariables>(GetNonKioskUsersWithZoneViewPermissionDocument, options);
        }
export type GetNonKioskUsersWithZoneViewPermissionQueryHookResult = ReturnType<typeof useGetNonKioskUsersWithZoneViewPermissionQuery>;
export type GetNonKioskUsersWithZoneViewPermissionLazyQueryHookResult = ReturnType<typeof useGetNonKioskUsersWithZoneViewPermissionLazyQuery>;
export type GetNonKioskUsersWithZoneViewPermissionSuspenseQueryHookResult = ReturnType<typeof useGetNonKioskUsersWithZoneViewPermissionSuspenseQuery>;
export type GetNonKioskUsersWithZoneViewPermissionQueryResult = Apollo.QueryResult<GetNonKioskUsersWithZoneViewPermissionQuery, GetNonKioskUsersWithZoneViewPermissionQueryVariables>;
export const GetUserByEmailDocument = gql`
    query getUserByEmail($email: String!) {
  user(where: {email: {_eq: $email}}, limit: 1) {
    id
    active
    email
    first_name
    last_name
    user_labels {
      id
      classification {
        metadata
      }
      modified_time
      metadata
    }
    metadata
    phone_number
    user_organization_roles(
      where: {_or: [{_or: [{organization: {is_active: {_eq: true}}}, {organization: {enumeration: {code: {_eq: "NEATLEAF"}}}}]}, {location: {organization: {is_active: {_eq: true}}}}, {zone: {location: {organization: {is_active: {_eq: true}}}}}]}
    ) {
      role_id
      role {
        code
        description
        id
      }
      user_id
      organization_id
      organization {
        enumeration {
          code
          description
          id
        }
      }
      location_id
      location {
        enumeration {
          description
        }
        organization_id
        organization {
          enumeration {
            code
            description
            id
          }
        }
      }
      zone_id
      zone {
        uid
        enumeration {
          description
        }
        location {
          id
          organization_id
          organization {
            enumeration {
              code
              description
              id
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserByEmailQuery__
 *
 * To run a query within a React component, call `useGetUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserByEmailQuery(baseOptions: Apollo.QueryHookOptions<GetUserByEmailQuery, GetUserByEmailQueryVariables> & ({ variables: GetUserByEmailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(GetUserByEmailDocument, options);
      }
export function useGetUserByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByEmailQuery, GetUserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(GetUserByEmailDocument, options);
        }
export function useGetUserByEmailSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserByEmailQuery, GetUserByEmailQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserByEmailQuery, GetUserByEmailQueryVariables>(GetUserByEmailDocument, options);
        }
export type GetUserByEmailQueryHookResult = ReturnType<typeof useGetUserByEmailQuery>;
export type GetUserByEmailLazyQueryHookResult = ReturnType<typeof useGetUserByEmailLazyQuery>;
export type GetUserByEmailSuspenseQueryHookResult = ReturnType<typeof useGetUserByEmailSuspenseQuery>;
export type GetUserByEmailQueryResult = Apollo.QueryResult<GetUserByEmailQuery, GetUserByEmailQueryVariables>;
export const GetUsersByOrganizationDocument = gql`
    query getUsersByOrganization($organizationId: Int!) {
  user(
    where: {_or: [{user_organization_roles: {organization_id: {_eq: $organizationId}}}, {user_organization_roles: {location: {organization_id: {_eq: $organizationId}}}}, {user_organization_roles: {zone: {location: {organization_id: {_eq: $organizationId}}}}}]}
  ) {
    id
    active
    email
    first_name
    last_name
    user_labels {
      id
      classification {
        metadata
      }
      modified_time
      metadata
    }
    metadata
    phone_number
    user_organization_roles {
      organization_id
      role_id
      role {
        code
        description
        id
      }
      user_id
      location_id
      location {
        enumeration {
          description
        }
        organization_id
        organization {
          enumeration {
            code
            description
            id
          }
        }
      }
      zone {
        uid
        enumeration {
          description
        }
        location {
          id
          organization_id
          organization {
            enumeration {
              code
              description
              id
            }
          }
        }
      }
      zone_id
      organization {
        enumeration {
          code
          description
          id
        }
      }
    }
  }
}
    `;

/**
 * __useGetUsersByOrganizationQuery__
 *
 * To run a query within a React component, call `useGetUsersByOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetUsersByOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables> & ({ variables: GetUsersByOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>(GetUsersByOrganizationDocument, options);
      }
export function useGetUsersByOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>(GetUsersByOrganizationDocument, options);
        }
export function useGetUsersByOrganizationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>(GetUsersByOrganizationDocument, options);
        }
export type GetUsersByOrganizationQueryHookResult = ReturnType<typeof useGetUsersByOrganizationQuery>;
export type GetUsersByOrganizationLazyQueryHookResult = ReturnType<typeof useGetUsersByOrganizationLazyQuery>;
export type GetUsersByOrganizationSuspenseQueryHookResult = ReturnType<typeof useGetUsersByOrganizationSuspenseQuery>;
export type GetUsersByOrganizationQueryResult = Apollo.QueryResult<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>;
export const RegisterUserOrganizationRoleDocument = gql`
    mutation registerUserOrganizationRole($userId: Int!, $organizationId: Int!, $roleId: Int!) {
  insert_user_organization_role(
    objects: {role_id: $roleId, user_id: $userId, organization_id: $organizationId}
  ) {
    returning {
      role_id
      user_id
      organization_id
    }
  }
}
    `;
export type RegisterUserOrganizationRoleMutationFn = Apollo.MutationFunction<RegisterUserOrganizationRoleMutation, RegisterUserOrganizationRoleMutationVariables>;

/**
 * __useRegisterUserOrganizationRoleMutation__
 *
 * To run a mutation, you first call `useRegisterUserOrganizationRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserOrganizationRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserOrganizationRoleMutation, { data, loading, error }] = useRegisterUserOrganizationRoleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationId: // value for 'organizationId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useRegisterUserOrganizationRoleMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserOrganizationRoleMutation, RegisterUserOrganizationRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserOrganizationRoleMutation, RegisterUserOrganizationRoleMutationVariables>(RegisterUserOrganizationRoleDocument, options);
      }
export type RegisterUserOrganizationRoleMutationHookResult = ReturnType<typeof useRegisterUserOrganizationRoleMutation>;
export type RegisterUserOrganizationRoleMutationResult = Apollo.MutationResult<RegisterUserOrganizationRoleMutation>;
export type RegisterUserOrganizationRoleMutationOptions = Apollo.BaseMutationOptions<RegisterUserOrganizationRoleMutation, RegisterUserOrganizationRoleMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: Int!, $active: Boolean!, $firstName: String!, $email: String!, $lastName: String!, $phoneNumber: String) {
  update_user_by_pk(
    pk_columns: {id: $id}
    _set: {active: $active, email: $email, first_name: $firstName, last_name: $lastName, phone_number: $phoneNumber}
  ) {
    id
    active
    email
    first_name
    last_name
    metadata
    phone_number
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *      firstName: // value for 'firstName'
 *      email: // value for 'email'
 *      lastName: // value for 'lastName'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserPasswordByEmailDocument = gql`
    mutation updateUserPasswordByEmail($email: String!, $password: String!) {
  update_user(where: {email: {_eq: $email}}, _set: {password: $password}) {
    returning {
      email
      password
    }
  }
}
    `;
export type UpdateUserPasswordByEmailMutationFn = Apollo.MutationFunction<UpdateUserPasswordByEmailMutation, UpdateUserPasswordByEmailMutationVariables>;

/**
 * __useUpdateUserPasswordByEmailMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordByEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordByEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordByEmailMutation, { data, loading, error }] = useUpdateUserPasswordByEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateUserPasswordByEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPasswordByEmailMutation, UpdateUserPasswordByEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPasswordByEmailMutation, UpdateUserPasswordByEmailMutationVariables>(UpdateUserPasswordByEmailDocument, options);
      }
export type UpdateUserPasswordByEmailMutationHookResult = ReturnType<typeof useUpdateUserPasswordByEmailMutation>;
export type UpdateUserPasswordByEmailMutationResult = Apollo.MutationResult<UpdateUserPasswordByEmailMutation>;
export type UpdateUserPasswordByEmailMutationOptions = Apollo.BaseMutationOptions<UpdateUserPasswordByEmailMutation, UpdateUserPasswordByEmailMutationVariables>;
export const UpdateUserMetadataDocument = gql`
    mutation updateUserMetadata($id: Int!, $metadata: jsonb!) {
  update_user_by_pk(pk_columns: {id: $id}, _set: {metadata: $metadata}) {
    metadata
  }
}
    `;
export type UpdateUserMetadataMutationFn = Apollo.MutationFunction<UpdateUserMetadataMutation, UpdateUserMetadataMutationVariables>;

/**
 * __useUpdateUserMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateUserMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMetadataMutation, { data, loading, error }] = useUpdateUserMetadataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useUpdateUserMetadataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMetadataMutation, UpdateUserMetadataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMetadataMutation, UpdateUserMetadataMutationVariables>(UpdateUserMetadataDocument, options);
      }
export type UpdateUserMetadataMutationHookResult = ReturnType<typeof useUpdateUserMetadataMutation>;
export type UpdateUserMetadataMutationResult = Apollo.MutationResult<UpdateUserMetadataMutation>;
export type UpdateUserMetadataMutationOptions = Apollo.BaseMutationOptions<UpdateUserMetadataMutation, UpdateUserMetadataMutationVariables>;
export const GetZonesByOrganizationCodeDocument = gql`
    query getZonesByOrganizationCode($code: String!, $hideInactiveZones: Boolean!) {
  zone(
    where: {location: {organization: {enumeration: {code: {_eq: $code}}}}, is_active: {_in: [true, $hideInactiveZones]}}
  ) {
    id
    uid
    enumeration {
      code
      description
    }
    location_id
    metadata
    name_id
    size_x
    size_y
    size_z
    location {
      time_zone_name
      enumeration {
        description
      }
    }
    is_active
  }
}
    `;

/**
 * __useGetZonesByOrganizationCodeQuery__
 *
 * To run a query within a React component, call `useGetZonesByOrganizationCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetZonesByOrganizationCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZonesByOrganizationCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *      hideInactiveZones: // value for 'hideInactiveZones'
 *   },
 * });
 */
export function useGetZonesByOrganizationCodeQuery(baseOptions: Apollo.QueryHookOptions<GetZonesByOrganizationCodeQuery, GetZonesByOrganizationCodeQueryVariables> & ({ variables: GetZonesByOrganizationCodeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetZonesByOrganizationCodeQuery, GetZonesByOrganizationCodeQueryVariables>(GetZonesByOrganizationCodeDocument, options);
      }
export function useGetZonesByOrganizationCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetZonesByOrganizationCodeQuery, GetZonesByOrganizationCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetZonesByOrganizationCodeQuery, GetZonesByOrganizationCodeQueryVariables>(GetZonesByOrganizationCodeDocument, options);
        }
export function useGetZonesByOrganizationCodeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetZonesByOrganizationCodeQuery, GetZonesByOrganizationCodeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetZonesByOrganizationCodeQuery, GetZonesByOrganizationCodeQueryVariables>(GetZonesByOrganizationCodeDocument, options);
        }
export type GetZonesByOrganizationCodeQueryHookResult = ReturnType<typeof useGetZonesByOrganizationCodeQuery>;
export type GetZonesByOrganizationCodeLazyQueryHookResult = ReturnType<typeof useGetZonesByOrganizationCodeLazyQuery>;
export type GetZonesByOrganizationCodeSuspenseQueryHookResult = ReturnType<typeof useGetZonesByOrganizationCodeSuspenseQuery>;
export type GetZonesByOrganizationCodeQueryResult = Apollo.QueryResult<GetZonesByOrganizationCodeQuery, GetZonesByOrganizationCodeQueryVariables>;