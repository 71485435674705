import { Button } from 'components/common/Button/Button';
import { Typography } from 'components/common/Typography';
import { useAuth } from 'contexts/AuthProvider';
import { useURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { LogoFullIcon } from 'icons/LogoFullIcon';
import { FC } from 'react';
import { ErrorOccuredImg } from './ErrorOccuredImg';

interface INotFoundPageProps {
  message?: string;
}

export const NotFoundPage: FC<INotFoundPageProps> = ({ message }) => {
  const { navigateToLogin, navigateToHome } = useURL();
  const { signOut } = useAuth();

  const handleBackToLogin = async () => {
    await signOut();
    navigateToLogin();
  };

  const errorMessage =
    message ??
    'It seems like something went wrong. Please go back to the homepage or contact us at support@neatleaf.com';

  return (
    <div className="relative flex w-screen flex-col gap-6 p-6 py-[14px] align-middle md:h-screen md:p-8 md:py-[14px]">
      <LogoFullIcon className="mr-2 cursor-pointer" onClick={navigateToHome} />
      <div className="flex flex-1 flex-col flex-wrap gap-6 md:flex-row">
        <div className="flex flex-1">
          <ErrorOccuredImg className="w-full"></ErrorOccuredImg>
        </div>

        <div className="flex flex-1 flex-col gap-4 md:justify-center">
          <Typography variant="h2" fontWeight="bold">
            An error occurred
          </Typography>
          <Typography variant="h6" fontWeight="light">
            {errorMessage}
          </Typography>
          <Button onClick={handleBackToLogin} className="w-full">
            Back to login
          </Button>
        </div>
      </div>
    </div>
  );
};
