import type { SVGProps } from 'react';
import { cn } from 'shared/utils/cn';

const LightOnIcon = ({
  fill = 'currentColor',
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    aria-hidden="true"
    fill="none"
    className={cn('h-6 w-6', className)}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 17.8358C10.8458 17.8358 9.71748 17.4935 8.75779 16.8523C7.79809 16.211 7.05011 15.2996 6.60841 14.2333C6.16671 13.1669 6.05115 11.9935 6.27632 10.8615C6.5015 9.72946 7.0573 8.68962 7.87345 7.87347C8.6896 7.05732 9.72944 6.50152 10.8615 6.27634C11.9935 6.05117 13.1669 6.16674 14.2332 6.60843C15.2996 7.05013 16.211 7.79812 16.8523 8.75781C17.4935 9.7175 17.8358 10.8458 17.8358 12C17.8358 13.5477 17.2209 15.0321 16.1265 16.1265C15.0321 17.2209 13.5477 17.8358 12 17.8358ZM12 7.83158C11.1755 7.83158 10.3696 8.07605 9.68413 8.53408C8.99863 8.99212 8.46436 9.64313 8.14886 10.4048C7.83336 11.1665 7.75081 12.0046 7.91165 12.8132C8.07249 13.6218 8.46949 14.3646 9.05246 14.9475C9.63542 15.5305 10.3782 15.9275 11.1868 16.0883C11.9954 16.2492 12.8335 16.1666 13.5952 15.8511C14.3568 15.5356 15.0079 15.0013 15.4659 14.3159C15.9239 13.6304 16.1684 12.8244 16.1684 12C16.1684 10.8945 15.7292 9.83421 14.9475 9.05248C14.1658 8.27075 13.1055 7.83158 12 7.83158Z"
      fill={fill}
    />
    <path
      d="M12.0001 23.509C11.779 23.509 11.5669 23.4212 11.4106 23.2648C11.2542 23.1085 11.1664 22.8964 11.1664 22.6753V21.1705C11.1664 20.9494 11.2542 20.7374 11.4106 20.581C11.5669 20.4247 11.779 20.3368 12.0001 20.3368C12.2212 20.3368 12.4332 20.4247 12.5896 20.581C12.7459 20.7374 12.8338 20.9494 12.8338 21.1705V22.6628C12.8354 22.7734 12.8151 22.8831 12.7739 22.9857C12.7328 23.0883 12.6716 23.1817 12.594 23.2604C12.5165 23.3392 12.424 23.4017 12.322 23.4444C12.22 23.4871 12.1106 23.509 12.0001 23.509Z"
      fill={fill}
    />
    <path
      d="M12.0001 3.66316C11.779 3.66316 11.5669 3.57532 11.4106 3.41898C11.2542 3.26263 11.1664 3.05058 11.1664 2.82947V1.1621C11.1664 0.940998 11.2542 0.728947 11.4106 0.572601C11.5669 0.416255 11.779 0.328421 12.0001 0.328421C12.2212 0.328421 12.4332 0.416255 12.5896 0.572601C12.7459 0.728947 12.8338 0.940998 12.8338 1.1621V2.82947C12.8338 3.05058 12.7459 3.26263 12.5896 3.41898C12.4332 3.57532 12.2212 3.66316 12.0001 3.66316Z"
      fill={fill}
    />
    <path
      d="M22.7545 12.8337H21.1705C20.9494 12.8337 20.7373 12.7458 20.581 12.5895C20.4246 12.4332 20.3368 12.2211 20.3368 12C20.3368 11.7789 20.4246 11.5668 20.581 11.4105C20.7373 11.2541 20.9494 11.1663 21.1705 11.1663H22.7545C22.9756 11.1663 23.1876 11.2541 23.344 11.4105C23.5003 11.5668 23.5882 11.7789 23.5882 12C23.5882 12.2211 23.5003 12.4332 23.344 12.5895C23.1876 12.7458 22.9756 12.8337 22.7545 12.8337Z"
      fill={fill}
    />
    <path
      d="M2.82951 12.8337H1.25385C1.03274 12.8337 0.820693 12.7458 0.664347 12.5895C0.508 12.4332 0.420166 12.2211 0.420166 12C0.420166 11.7789 0.508 11.5668 0.664347 11.4105C0.820693 11.2541 1.03274 11.1663 1.25385 11.1663H2.82951C3.05062 11.1663 3.26267 11.2541 3.41902 11.4105C3.57536 11.5668 3.6632 11.7789 3.6632 12C3.6632 12.2211 3.57536 12.4332 3.41902 12.5895C3.26267 12.7458 3.05062 12.8337 2.82951 12.8337Z"
      fill={fill}
    />
    <path
      d="M4.28429 20.4661C4.11991 20.4654 3.9594 20.4161 3.82295 20.3245C3.68649 20.2328 3.58018 20.1029 3.51737 19.9509C3.45455 19.799 3.43805 19.632 3.46993 19.4707C3.50181 19.3094 3.58065 19.1612 3.69654 19.0446L4.84702 17.8941C5.00511 17.7383 5.21865 17.6516 5.44067 17.6531C5.66268 17.6547 5.87498 17.7444 6.03086 17.9025C6.18674 18.0606 6.27343 18.2741 6.27187 18.4961C6.2703 18.7181 6.18061 18.9304 6.02252 19.0863L4.87203 20.2201C4.71641 20.3767 4.50504 20.4651 4.28429 20.4661Z"
      fill={fill}
    />
    <path
      d="M18.3902 6.41848C18.2259 6.41779 18.0654 6.36853 17.9289 6.27687C17.7924 6.18522 17.6861 6.05527 17.6233 5.90337C17.5605 5.75146 17.544 5.58438 17.5759 5.42312C17.6078 5.26187 17.6866 5.11363 17.8025 4.99705L18.9572 3.8424C19.1136 3.68597 19.3258 3.59808 19.547 3.59808C19.7682 3.59808 19.9804 3.68597 20.1368 3.8424C20.2932 3.99883 20.3811 4.211 20.3811 4.43223C20.3811 4.65346 20.2932 4.86563 20.1368 5.02206L18.978 6.16421C18.9016 6.24324 18.8104 6.3064 18.7095 6.35003C18.6086 6.39367 18.5001 6.41693 18.3902 6.41848Z"
      fill={fill}
    />
    <path
      d="M5.60988 6.43099C5.38913 6.43006 5.17776 6.34162 5.02213 6.18505L3.89666 5.05958C3.74023 4.90315 3.65234 4.69098 3.65234 4.46975C3.65234 4.24852 3.74023 4.03635 3.89666 3.87992C4.05309 3.72348 4.26526 3.6356 4.48649 3.6356C4.70772 3.6356 4.91989 3.72348 5.07632 3.87992L6.2018 5.00539C6.31937 5.122 6.39956 5.27098 6.43213 5.43334C6.4647 5.5957 6.44819 5.76408 6.38469 5.91701C6.32119 6.06994 6.21358 6.2005 6.07559 6.29204C5.9376 6.38358 5.77547 6.43195 5.60988 6.43099Z"
      fill={fill}
    />
    <path
      d="M19.5448 20.4035C19.324 20.4026 19.1126 20.3142 18.957 20.1576L17.8732 19.0863C17.7958 19.0089 17.7343 18.9169 17.6924 18.8157C17.6505 18.7145 17.6289 18.606 17.6289 18.4965C17.6289 18.3869 17.6505 18.2785 17.6924 18.1773C17.7343 18.0761 17.7958 17.9841 17.8732 17.9066C17.9507 17.8292 18.0426 17.7677 18.1438 17.7258C18.245 17.6839 18.3535 17.6623 18.4631 17.6623C18.5726 17.6623 18.6811 17.6839 18.7823 17.7258C18.8835 17.7677 18.9754 17.8292 19.0529 17.9066L20.1367 18.9904C20.25 19.1077 20.3265 19.2556 20.3566 19.4158C20.3867 19.576 20.3692 19.7416 20.3062 19.892C20.2432 20.0424 20.1375 20.171 20.0022 20.2619C19.8668 20.3528 19.7078 20.4021 19.5448 20.4035Z"
      fill={fill}
    />
  </svg>
);

export { LightOnIcon };
