import { useAuth } from 'contexts/AuthProvider';
import Cookies from 'js-cookie';
import memoize from 'lodash.memoize';
import { useCallback, useMemo } from 'react';
import { FEATURE_ENABLED } from 'shared/constants/general';
import { NEATLEAF_ORGANIZATION_CODE } from 'shared/interfaces/organization';
import { BetaFlag } from 'shared/models/beta-flag';
import { DebugFlag } from 'shared/models/debug-flag';
import { Permission } from 'shared/models/permission';
import { useCurrentZone } from './useCurrentZone';
import { useFeatureFlags } from './useFeatureFlags';
import { useUserPermissions } from './useUserPermissions';

export const usePermissions = () => {
  const { getBetaFlagValue, getDebugFlagValue } = useFeatureFlags();
  const { permissionContainer } = useUserPermissions();
  const { currentZone } = useCurrentZone();
  const { currentlySelectedOrganization } = useAuth();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getPermissionValue = useCallback(
    memoize((permission: Permission): boolean => {
      if (!permissionContainer) {
        return false;
      }

      if (
        currentZone &&
        permissionContainer.hasZoneLocationOrOrganizationPermission(
          permission,
          {
            locationId: currentZone.locationId,
            organizationCode: currentZone.organizationCode,
            zoneUid: currentZone.uid,
          }
        )
      ) {
        return true;
      }

      if (
        currentlySelectedOrganization &&
        permissionContainer.hasOrganizationPermission(
          permission,
          currentlySelectedOrganization.code
        )
      ) {
        return true;
      }

      // TODO: Discuss if we can remove this. It feels wrong to be able to add permissions as a cookie !
      const cookiePermission = Cookies.get(permission) === FEATURE_ENABLED;

      // eslint-disable-next-line sonarjs/prefer-single-boolean-return
      if (cookiePermission) {
        return true;
      }

      return false;
    }),
    [permissionContainer, currentZone]
  );

  const getNeatleafPermission = useCallback(
    (permission: Permission) => {
      if (!permissionContainer) {
        return false;
      }
      return permissionContainer.hasOrganizationPermission(
        permission,
        NEATLEAF_ORGANIZATION_CODE
      );
    },
    [permissionContainer]
  );

  const canEditGrowthCycles = getPermissionValue(Permission.GrowthCycleUpdate);
  const canEditCriticalEnvSettings = getPermissionValue(
    Permission.LocationUpdate
  );
  const canEditCultivarAssignment = getPermissionValue(
    Permission.GrowthCycleUpdate
  );
  const canEditUsers = getPermissionValue(Permission.UserUpdate);
  const canAccessUserSettings = getPermissionValue(Permission.UserSettingsView);
  const canViewNdvi = getBetaFlagValue(BetaFlag.NDVI);
  const canViewHomepage = getPermissionValue(Permission.HomepageView);
  const canDebugImageFeed = getDebugFlagValue(DebugFlag.IMAGE_FEED);
  const canDebugImageSizeIndex = getDebugFlagValue(DebugFlag.IMAGE_SIZE_INDEX);
  const canViewSMSNotificationSettings = getBetaFlagValue(
    BetaFlag.SMS_NOTIFICATIONS
  );

  const discussions = useMemo(
    () => ({
      canView: getPermissionValue(Permission.DiscussionView),
      canCreate: getPermissionValue(Permission.DiscussionCreate),
      canUpdate: getPermissionValue(Permission.DiscussionUpdate),
      canDelete: getPermissionValue(Permission.DiscussionDelete),
    }),
    [getPermissionValue]
  );

  const comments = useMemo(
    () => ({
      canView: getPermissionValue(Permission.CommentThreadView),
      canCreate: getPermissionValue(Permission.CommentThreadCreate),
      canUpdate: getPermissionValue(Permission.CommentThreadUpdate),
      canDelete: getPermissionValue(Permission.CommentThreadDelete),
    }),
    [getPermissionValue]
  );

  const insights = useMemo(
    () => ({
      canCreate: getNeatleafPermission(Permission.InsightCreate),
      canViewDraft: getNeatleafPermission(Permission.InsightDraftView),
      canPublish: getNeatleafPermission(Permission.InsightPublish),
    }),
    [getNeatleafPermission]
  );

  return {
    canAccessUserSettings,
    canDebugImageFeed,
    canDebugImageSizeIndex,
    canEditGrowthCycles,
    canEditUsers,
    canEditCultivarAssignment,
    canEditCriticalEnvSettings,
    canViewSMSNotificationSettings,
    canViewHomepage,
    canViewNdvi,
    discussions,
    comments,
    insights,
  };
};
