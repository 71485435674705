import { utcToZonedTime } from 'date-fns-tz';
import { useGetDailyHealthLabelsByZoneIdTypeCodeQuery } from 'graphql/generated/react_apollo';
import { useMemo } from 'react';
import {
  MeasurementTypeConfig,
  SignalMeasurements,
  SignalMeasurementsType,
} from 'shared/interfaces/measurement';
import { MeasurementRequestParams } from './measurement';

export const useGetDailyHealthLabelsByZoneIdTypeCode = ({
  zoneId,
  zoneTimeZone,
  start,
  end,
  signals,
}: MeasurementRequestParams) => {
  const requestSignals = useMemo(
    () => signals.filter(({ apis }) => apis.includes('gql-labels-count')),
    [signals]
  );

  const {
    previousData: previousRawData,
    data: rawData = previousRawData,
    ...result
  } = useGetDailyHealthLabelsByZoneIdTypeCodeQuery({
    variables: {
      zoneId: zoneId,
      start: start,
      end: end,
      typeCodes: requestSignals.map(({ statisticsKeyV2 }) => statisticsKeyV2),
    },
    skip: requestSignals.length === 0,
  });

  const data = useMemo<SignalMeasurementsType>(() => {
    const extractValues = (signal: MeasurementTypeConfig) => {
      if (!rawData) {
        return [];
      }

      return rawData.computed_measurement
        .filter(({ type }) => type?.code === signal.statisticsKeyV2)
        .map<
          [number, number]
        >(({ time, data }) => [utcToZonedTime(time, zoneTimeZone).valueOf(), Number(data[signal.statisticsKey]) * 100]);
    };

    const allValues = new SignalMeasurements();
    for (const signal of requestSignals) {
      allValues.set(signal, extractValues(signal));
    }

    return allValues;
  }, [rawData, requestSignals, zoneTimeZone]);

  return { data, ...result };
};
